import React from 'react';
import PropTypes from 'prop-types';
import {Button, DialogTitle, DialogContent, DialogActions, Dialog} from '@material-ui/core';
import * as marketplace from '../store/ducks/marketplace.duck';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Col, Form} from "react-bootstrap";
import Select from "react-select";

const selectStyles = {
    menu: styles => ({ ...styles, zIndex: 999 }),
    control: (provided, state) => ({
        ...provided,
        minHeight: '41px',
    }),
};

class MarketplaceFilterDialog extends React.Component {
    render() {
        const {open, onClose, guestPosts, filter} = this.props;
        const categoryOptions = [];
        let defaultCategories = [];

        if(filter && filter["keyword"] === "[empty]") {
            filter["keyword"] = "";
        }

        let mcItem = {};
        if (guestPosts && guestPosts.data && guestPosts.meta) {
            if(guestPosts.marketplace_category) {
                mcItem = {value: this.props.guestPosts.marketplace_category, label: this.props.guestPosts.marketplace_category};
                categoryOptions.push(mcItem);
                defaultCategories.push(mcItem);
            }
            if(guestPosts.all_categories && guestPosts.all_categories.length) {
                for (const [index] of guestPosts.all_categories.entries()) {
                    let item = {value: guestPosts.all_categories[index], label: guestPosts.all_categories[index]};
                    if(item.value !== mcItem.value) {
                        categoryOptions.push(item);
                        if(filter["keyword"] && filter["keyword"].indexOf(item.value.toString()) !== -1) {
                            defaultCategories.push(item);
                        }
                    }

                }
            }
        }

        const handleInputChange = (event) => {
            filter[event.target.name] = event.target.value;
            this.props.initMarketplace(1, filter);
        };
        const handleCategoryChange = (value) => {
            if(value && value.length !== 0) {
                let selectedText = [];
                defaultCategories = [];
                for(let i = 0; i < value.length; i++) {
                    selectedText.push(value[i].value);
                }
                filter["keyword"] = selectedText;
                defaultCategories = value;
            }
            else {
                filter["keyword"] = ["[empty]"];
                defaultCategories = [];
            }
            this.props.initMarketplace(1, filter);
        };

        const handleClose = () => {
            onClose();
        };
        const handleSubmit = () => {
            onClose();
        };

        return (<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle>Guest Posts Filter</DialogTitle>
            <DialogContent dividers>
                {filter && <Form>
                    <Form.Row>
                        <Form.Group as={Col} className="col-sm-4 col-6" controlId="formGridCategory">
                            <Form.Label>Site Category</Form.Label>
                            <Select
                                isMulti
                                options={categoryOptions}
                                value={defaultCategories}
                                onChange={handleCategoryChange}
                                noOptionsMessage={()=>{return "No options";}}
                                styles={selectStyles}
                            />
                        </Form.Group>
                        <Form.Group as={Col} className="col-sm-4 col-6" controlId="formGridTLD">
                            <Form.Label>TLD</Form.Label>
                            <Form.Control type="text" name="tld" placeholder="TLD" defaultValue={filter && filter.tld ? filter.tld : ''} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group as={Col} className="col-sm-4 col-6" controlId="formGridDA">
                            <Form.Label>DA</Form.Label>
                            <Form.Control type="number" name="da" placeholder="MIN" min="0" defaultValue={filter && filter.da ? filter.da : ''} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group as={Col} className="col-sm-4 col-6" controlId="formGridDR">
                            <Form.Label>DR</Form.Label>
                            <Form.Control type="number" name="dr" placeholder="MIN" min="0" defaultValue={filter && filter.dr ? filter.dr : ''} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group as={Col} className="col-sm-4 col-6" controlId="formGridRD">
                            <Form.Label>RD</Form.Label>
                            <Form.Control type="number" name="rd" placeholder="MIN" min="0" defaultValue={filter && filter.rd ? filter.rd : ''} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group as={Col} className="col-sm-4 col-6" controlId="formGridOT">
                            <Form.Label>Organic Traffic</Form.Label>
                            <Form.Control type="number" name="organic_traffic" placeholder="MIN" min="0" defaultValue={filter && filter.organic_traffic ? filter.organic_traffic : ''} onChange={handleInputChange} />
                        </Form.Group>
                        <Form.Group as={Col} className="col-sm-4 col-6" controlId="formGridPrice">
                            <Form.Label>Price</Form.Label>
                            <Form.Control type="number" name="price" placeholder="MAX" min="0" defaultValue={filter && filter.price ? filter.price : ''} onChange={handleInputChange} />
                        </Form.Group>
                    </Form.Row>
                </Form>}
            </DialogContent>
            <DialogActions>
                {/*<Button onClick={handleClose} color="primary">
                    Cancel
                </Button>*/}
                <Button onClick={handleSubmit} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>);
    }
}

MarketplaceFilterDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ marketplace: { guestPosts, filter } }) => ({
    guestPosts, filter
});

export default injectIntl(connect(
    mapStateToProps,
    marketplace.actions,
)(MarketplaceFilterDialog));
