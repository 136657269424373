import React, { Component } from "react";
import Deals from "./Deals";
import AuditPerPageInfo from "./faq/AuditPerPageInfo";

export default class AuditPerPage extends Component {
    render() {
        const faq = <AuditPerPageInfo />;

        return (<>
            <Deals type="audit_per_page_service_package" key="app" description="On-Page SEO Services" title="Choose On-Page SEO Package to Get Started" faq={faq} />
        </>);
    }
}
