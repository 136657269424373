import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField, FormControl } from "@material-ui/core";
import { injectIntl } from "react-intl";
import * as profile from "../../../store/ducks/profile.duck";
import { editBillingInfo } from "../../../crud/user.crud";
import {Spinner} from "reactstrap";
import Select from "react-select";

const countryList = {
    'AF':'Afghanistan',
    'AX':'Åland Islands',
    'AL':'Albania',
    'DZ':'Algeria',
    'AS':'American Samoa',
    'AD':'Andorra',
    'AO':'Angola',
    'AI':'Anguilla',
    'AQ':'Antarctica',
    'AG':'Antigua and Barbuda',
    'AR':'Argentina',
    'AM':'Armenia',
    'AW':'Aruba',
    'AU':'Australia',
    'AT':'Austria',
    'AZ':'Azerbaijan',
    'BS':'Bahamas',
    'BH':'Bahrain',
    'BD':'Bangladesh',
    'BB':'Barbados',
    'BY':'Belarus',
    'BE':'Belgium',
    'PW':'Belau',
    'BZ':'Belize',
    'BJ':'Benin',
    'BM':'Bermuda',
    'BT':'Bhutan',
    'BO':'Bolivia',
    'BQ':'Bonaire, Saint Eustatius and Saba',
    'BA':'Bosnia and Herzegovina',
    'BW':'Botswana',
    'BV':'Bouvet Island',
    'BR':'Brazil',
    'IO':'British Indian Ocean Territory',
    'BN':'Brunei',
    'BG':'Bulgaria',
    'BF':'Burkina Faso',
    'BI':'Burundi',
    'KH':'Cambodia',
    'CM':'Cameroon',
    'CA':'Canada',
    'CV':'Cape Verde',
    'KY':'Cayman Islands',
    'CF':'Central African Republic',
    'TD':'Chad',
    'CL':'Chile',
    'CN':'China',
    'CX':'Christmas Island',
    'CC':'Cocos (Keeling) Islands',
    'CO':'Colombia',
    'KM':'Comoros',
    'CG':'Congo (Brazzaville)',
    'CD':'Congo (Kinshasa)',
    'CK':'Cook Islands',
    'CR':'Costa Rica',
    'HR':'Croatia',
    'CU':'Cuba',
    'CW':'Cura&ccedil;ao',
    'CY':'Cyprus',
    'CZ':'Czech Republic',
    'DK':'Denmark',
    'DJ':'Djibouti',
    'DM':'Dominica',
    'DO':'Dominican Republic',
    'EC':'Ecuador',
    'EG':'Egypt',
    'SV':'El Salvador',
    'GQ':'Equatorial Guinea',
    'ER':'Eritrea',
    'EE':'Estonia',
    'ET':'Ethiopia',
    'FK':'Falkland Islands',
    'FO':'Faroe Islands',
    'FJ':'Fiji',
    'FI':'Finland',
    'FR':'France',
    'GF':'French Guiana',
    'PF':'French Polynesia',
    'TF':'French Southern Territories',
    'GA':'Gabon',
    'GM':'Gambia',
    'GE':'Georgia',
    'DE':'Germany',
    'GH':'Ghana',
    'GI':'Gibraltar',
    'GR':'Greece',
    'GL':'Greenland',
    'GD':'Grenada',
    'GP':'Guadeloupe',
    'GU':'Guam',
    'GT':'Guatemala',
    'GG':'Guernsey',
    'GN':'Guinea',
    'GW':'Guinea-Bissau',
    'GY':'Guyana',
    'HT':'Haiti',
    'HM':'Heard Island and McDonald Islands',
    'HN':'Honduras',
    'HK':'Hong Kong',
    'HU':'Hungary',
    'IS':'Iceland',
    'IN':'India',
    'ID':'Indonesia',
    'IR':'Iran',
    'IQ':'Iraq',
    'IE':'Ireland',
    'IM':'Isle of Man',
    'IL':'Israel',
    'IT':'Italy',
    'CI':'Ivory Coast',
    'JM':'Jamaica',
    'JP':'Japan',
    'JE':'Jersey',
    'JO':'Jordan',
    'KZ':'Kazakhstan',
    'KE':'Kenya',
    'KI':'Kiribati',
    'KW':'Kuwait',
    'KG':'Kyrgyzstan',
    'LA':'Laos',
    'LV':'Latvia',
    'LB':'Lebanon',
    'LS':'Lesotho',
    'LR':'Liberia',
    'LY':'Libya',
    'LI':'Liechtenstein',
    'LT':'Lithuania',
    'LU':'Luxembourg',
    'MO':'Macao',
    'MK':'North Macedonia',
    'MG':'Madagascar',
    'MW':'Malawi',
    'MY':'Malaysia',
    'MV':'Maldives',
    'ML':'Mali',
    'MT':'Malta',
    'MH':'Marshall Islands',
    'MQ':'Martinique',
    'MR':'Mauritania',
    'MU':'Mauritius',
    'YT':'Mayotte',
    'MX':'Mexico',
    'FM':'Micronesia',
    'MD':'Moldova',
    'MC':'Monaco',
    'MN':'Mongolia',
    'ME':'Montenegro',
    'MS':'Montserrat',
    'MA':'Morocco',
    'MZ':'Mozambique',
    'MM':'Myanmar',
    'NA':'Namibia',
    'NR':'Nauru',
    'NP':'Nepal',
    'NL':'Netherlands',
    'NC':'New Caledonia',
    'NZ':'New Zealand',
    'NI':'Nicaragua',
    'NE':'Niger',
    'NG':'Nigeria',
    'NU':'Niue',
    'NF':'Norfolk Island',
    'MP':'Northern Mariana Islands',
    'KP':'North Korea',
    'NO':'Norway',
    'OM':'Oman',
    'PK':'Pakistan',
    'PS':'Palestinian Territory',
    'PA':'Panama',
    'PG':'Papua New Guinea',
    'PY':'Paraguay',
    'PE':'Peru',
    'PH':'Philippines',
    'PN':'Pitcairn',
    'PL':'Poland',
    'PT':'Portugal',
    'PR':'Puerto Rico',
    'QA':'Qatar',
    'RE':'Reunion',
    'RO':'Romania',
    'RU':'Russia',
    'RW':'Rwanda',
    'BL':'Saint Barth&eacute;lemy',
    'SH':'Saint Helena',
    'KN':'Saint Kitts and Nevis',
    'LC':'Saint Lucia',
    'MF':'Saint Martin (French part)',
    'SX':'Saint Martin (Dutch part)',
    'PM':'Saint Pierre and Miquelon',
    'VC':'Saint Vincent and the Grenadines',
    'SM':'San Marino',
    'ST':'S&atilde;o Tom&eacute; and Pr&iacute;ncipe',
    'SA':'Saudi Arabia',
    'SN':'Senegal',
    'RS':'Serbia',
    'SC':'Seychelles',
    'SL':'Sierra Leone',
    'SG':'Singapore',
    'SK':'Slovakia',
    'SI':'Slovenia',
    'SB':'Solomon Islands',
    'SO':'Somalia',
    'ZA':'South Africa',
    'GS':'South Georgia/Sandwich Islands',
    'KR':'South Korea',
    'SS':'South Sudan',
    'ES':'Spain',
    'LK':'Sri Lanka',
    'SD':'Sudan',
    'SR':'Suriname',
    'SJ':'Svalbard and Jan Mayen',
    'SZ':'Swaziland',
    'SE':'Sweden',
    'CH':'Switzerland',
    'SY':'Syria',
    'TW':'Taiwan',
    'TJ':'Tajikistan',
    'TZ':'Tanzania',
    'TH':'Thailand',
    'TL':'Timor-Leste',
    'TG':'Togo',
    'TK':'Tokelau',
    'TO':'Tonga',
    'TT':'Trinidad and Tobago',
    'TN':'Tunisia',
    'TR':'Turkey',
    'TM':'Turkmenistan',
    'TC':'Turks and Caicos Islands',
    'TV':'Tuvalu',
    'UG':'Uganda',
    'UA':'Ukraine',
    'AE':'United Arab Emirates',
    'GB':'United Kingdom (UK)',
    'US':'United States (US)',
    'UM':'United States (US) Minor Outlying Islands',
    'UY':'Uruguay',
    'UZ':'Uzbekistan',
    'VU':'Vanuatu',
    'VA':'Vatican',
    'VE':'Venezuela',
    'VN':'Vietnam',
    'VG':'Virgin Islands (British)',
    'VI':'Virgin Islands (US)',
    'WF':'Wallis and Futuna',
    'EH':'Western Sahara',
    'WS':'Samoa',
    'YE':'Yemen',
    'ZM':'Zambia',
    'ZW':'Zimbabwe',
};

const selectStyles = { menu: styles => ({ ...styles, zIndex: 999, position: "relative" }) };

class BillingProfile extends Component {
    componentDidMount() {
        this.props.initBillingInfo();
    }
    render() {
        const {intl, billingInfo} = this.props;

        if (!billingInfo)
        {
            return <Spinner />;
        }

        const countries = [{value:"", label:"Country*"}];
        for (const [index] of Object.entries(countryList)) {
            countries.push({value:countryList[index], label:countryList[index]});
        }

        return (
            <Formik
                initialValues={{
                    firstname: billingInfo.firstname ? billingInfo.firstname : "",
                    lastname: billingInfo.lastname ? billingInfo.lastname : "",
                    company: billingInfo.company ? billingInfo.company : "",
                    paypal_email: billingInfo.paypal_email ? billingInfo.paypal_email : "",
                    address: billingInfo.address ? billingInfo.address : "",
                    country: billingInfo.country ? billingInfo.country : "",
                    city: billingInfo.city ? billingInfo.city : "",
                    postal_code: billingInfo.postal_code ? billingInfo.postal_code : "",
                    taxid: billingInfo.taxid ? billingInfo.taxid : ""
                }}
                validate={values => {
                    const errors = {};

                    if (!values.firstname) {
                        errors.firstname = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }

                    if (!values.lastname) {
                        errors.lastname = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }

                    if (!values.address) {
                        errors.address = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }

                    if (!values.city) {
                        errors.city = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }

                    if (!values.postal_code) {
                        errors.postal_code = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }

                    if (!values.country) {
                        errors.country = intl.formatMessage({
                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                    }

                    return errors;
                }}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    const data = {
                        firstname: values.firstname,
                        lastname: values.lastname,
                        company: values.company,
                        paypal_email: values.paypal_email,
                        address: values.address,
                        country: values.country,
                        taxid: values.taxid,
                        city: values.city,
                        postal_code: values.postal_code
                    };
                    editBillingInfo(data)
                        .then(() => {
                            setSubmitting(false);
                            setStatus(
                                "Saved."
                            );
                            // this.props.fulfillBillingInfo(data);
                        })
                        .catch(() => {
                            setSubmitting(false);
                            setStatus(
                                "Error! Data is not updated."
                            );
                        });
                }}
            >
                {({
                      values,
                      status,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                  }) => (
                    <form onSubmit={handleSubmit} >
                        {status && !isSubmitting && (
                            <div role="alert" className={'alert ' + (status === 'Saved.' ? 'alert-success' : 'alert-danger')}>
                                <div className="alert-text">{status}</div>
                            </div>
                        )}

                        <div className="form-group mb-0">
                            <TextField
                                margin="normal"
                                label="Firstname"
                                className="kt-width-full"
                                name="firstname"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.firstname}
                                helperText={touched.firstname && errors.firstname}
                                error={Boolean(touched.firstname && errors.firstname)}
                            />
                        </div>

                        <div className="form-group mb-0">
                            <TextField
                                margin="normal"
                                label="Lastname"
                                className="kt-width-full"
                                name="lastname"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.lastname}
                                helperText={touched.lastname && errors.lastname}
                                error={Boolean(touched.lastname && errors.lastname)}
                            />
                        </div>

                        <div className="form-group mb-0">
                            <TextField
                                margin="normal"
                                label="Company Name"
                                className="kt-width-full"
                                name="company"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.company}
                                helperText={touched.company && errors.company}
                                error={Boolean(touched.company && errors.company)}
                            />
                        </div>

                        <div className="form-group mb-0">
                            <TextField
                                type="email"
                                margin="normal"
                                label="Paypal Email"
                                className="kt-width-full"
                                name="paypal_email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.paypal_email}
                                helperText={touched.paypal_email && errors.paypal_email}
                                error={Boolean(touched.paypal_email && errors.paypal_email)}
                            />
                        </div>

                        <div className="form-group mb-0">
                            <TextField
                                margin="normal"
                                label="Company Address"
                                className="kt-width-full"
                                name="address"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.address}
                                helperText={touched.address && errors.address}
                                error={Boolean(touched.address && errors.address)}
                            />
                        </div>

                        <div className="form-group mb-0">
                            <TextField
                                margin="normal"
                                label="Postal Code"
                                className="kt-width-full"
                                name="postal_code"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.postal_code}
                                helperText={touched.postal_code && errors.postal_code}
                                error={Boolean(touched.postal_code && errors.postal_code)}
                            />
                        </div>

                        <div className="form-group mb-0">
                            <TextField
                                margin="normal"
                                label="City"
                                className="kt-width-full"
                                name="city"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.city}
                                helperText={touched.city && errors.city}
                                error={Boolean(touched.city && errors.city)}
                            />
                        </div>

                        <div className="form-group mb-0">
                            <FormControl fullWidth>
                                <label
                                    className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled"
                                    data-shrink="true">Country</label>
                                <div
                                    className="MuiInputBase-root MuiInput-root jss5 MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">
                                    <Select
                                        options={countries}
                                        defaultValue={{value:values.country, label:values.country}}
                                        onChange={selectedOption => {
                                            handleChange("country")(selectedOption.value);
                                        }}
                                        noOptionsMessage={()=>{return !countries ? "Loading..." : "No options";}}
                                        styles={selectStyles}
                                        className="w-100"
                                    />
                                </div>
                                <div className="invalid-feedback" style={{display:!!errors.country ? "block" : "none"}}>{errors.country}</div>
                            </FormControl>
                        </div>

                        <div className="form-group">
                            <TextField
                                margin="normal"
                                label="Tax ID"
                                className="kt-width-full"
                                name="taxid"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.taxid}
                                helperText={touched.taxid && errors.taxid}
                                error={Boolean(touched.taxid && errors.taxid)}
                            />
                        </div>

                        <div className="kt-login__actions">
                            <button
                                type="submit"
                                className="btn btn-primary btn-elevate kt-login__btn-primary"
                                disabled={isSubmitting}
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        );
    }
}

const mapStateToProps = ({ profile: { billingInfo } }) => ({
    billingInfo
});

export default injectIntl(connect(
    mapStateToProps,
    profile.actions
)(BillingProfile));
