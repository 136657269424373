import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../../../store/ducks/auth.duck";
import * as profile from "../../../store/ducks/profile.duck";
import {Spinner} from "reactstrap";
import {Table, Badge} from "react-bootstrap";
import {Portlet, PortletBody, PortletHeader} from "../../../partials/content/Portlet";
import {Link} from "react-router-dom";
import {Icon} from "@material-ui/core";
import UPagination from "../../../partials/content/Pagination";
import { Button, Alert } from "react-bootstrap";
import TopUpBalanceModal from "./TopUpBalanceModal";
import WithdrawBalanceModal from "./WithdrawBalanceModal";

class AccountBalance extends Component {
     constructor(props) {
        super(props);
        let sending = false;
        let openTopUpBalanceModal = false;
        if (window.location.hash == "#deposit") {
            openTopUpBalanceModal = true;
        }
        let openWithdrawBalanceModal = false;
        if (window.location.hash == "#withdraw") {
            openWithdrawBalanceModal = true;
        }
        this.state = {
            sending: sending,
            openTopUpBalanceModal: openTopUpBalanceModal,
            openWithdrawBalanceModal: openWithdrawBalanceModal,
        }
    }

    componentDidMount() {
        this.props.requestBalanceHistory();
        this.props.initBillingInfo();
        this.props.requestUser();
    }

    render() {

        const { user, balanceHistory, billingInfo } = this.props;
        let sending = this.props.sending;

        const topUpBalanceClose = (e) => {
            this.setState({...this.state, openTopUpBalanceModal: false});
        }

        this.openTopUpBalance = (e) => {
            this.setState({...this.state, openTopUpBalanceModal: true});
        }

        const withdrawBalanceClose = (e) => {
            this.setState({...this.state, openWithdrawBalanceModal: false});
        }

        this.openWithdrawBalanceModal = (e) => {
            this.setState({...this.state, openWithdrawBalanceModal: true});
        }

        if (!balanceHistory) {
            return <Spinner/>;
        }
        
        const moneyFormatter = (num) => {
            if (typeof num !== 'undefined') {
                try {
                    var calc = num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('.00','');
                    // var calc = num.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    return calc;
                } catch (e) {
                    console.log('moneyFormatter',e);
                    return 0.00;
                }
            }
            return num;
        }

        // if (billingInfo) {
        //     if (billingInfo.promotion_minimum_deposit && billingInfo.promotional_amount) {

        //     }
        // }

        const rows = [];

        if (balanceHistory.data) {
            if (balanceHistory.data.length) {
                for (const [index] of balanceHistory.data.entries()) {
                    const date = new Date(balanceHistory.data[index].created_at);
                    let status = "Complete";
                    if (balanceHistory.data[index].status != "") {
                        status = balanceHistory.data[index].status;
                    }
                    rows.push(<tr key={index}>
                        <td>{date.toLocaleString()}</td>
                        <td>{balanceHistory.data[index].type}</td>
                        <td>$&nbsp;{moneyFormatter(balanceHistory.data[index].amount)}</td>
                        <td>{balanceHistory.data[index].description}</td>
                        <td>{status}</td>
                    </tr>)
                }
            }
        }

        let topDeposit = "-55px";
        let withdrawableBalance = 0;
        let paypalEmail = "";
        if (billingInfo) {
            if (typeof billingInfo.withdrawable_balance !== 'undefined') {
                withdrawableBalance = billingInfo.withdrawable_balance;
                if (withdrawableBalance > 0) {
                    topDeposit = "-55px";
                }
            }
            if (typeof billingInfo.paypal_email !== 'undefined') {
                paypalEmail = billingInfo.paypal_email;
            }
        }

        const handleNextPage = (number) => {
            this.props.requestBalanceHistory(number);
        };

        let promotion_rows = [];
        if (billingInfo) {
            if (billingInfo.deposit_promotions) {
                for (const [index] of billingInfo.deposit_promotions.entries()) {
                    let this_promotion = billingInfo.deposit_promotions[index];
                    let promotional_amount = "$"+this_promotion.promotional_amount;
                    if (this_promotion.percentage_promotion == 1) {
                        let promotional_readable_percentage = this_promotion.promotional_amount*100;
                        promotional_amount = promotional_readable_percentage+"%";
                    }
                    promotion_rows.push(<Alert variant="primary">Deposit Promotion: Deposit ${this_promotion.minimum_deposit} or more and receive a {promotional_amount} deposit bonus!</Alert>);
                }
            }
        }

        return (
            <>
                <Portlet>
                    {promotion_rows}
                    <TopUpBalanceModal open={this.state.openTopUpBalanceModal} onClose={topUpBalanceClose} billingInfo={this.props.billingInfo} />
                     {withdrawableBalance > 0 ? 
                        <>
                            <div class="">
                                <h1 className="custom-header kt-ml-25 kt-mb-25 kt-mt-25 d-inilne-block">
                                <Button disabled={this.state.sending} className="btn-sm" onClick={(e) => this.openWithdrawBalanceModal(e)}>Withdraw</Button> ${withdrawableBalance.toFixed(2)} from sales pending withdraw. Get paid every Monday via PayPal.
                                </h1>
                            </div>
                            <WithdrawBalanceModal paypalEmail={paypalEmail} withdrawableBalance={withdrawableBalance} open={this.state.openWithdrawBalanceModal} onClose={withdrawBalanceClose} />
                        </>
                    : ""}
                    <PortletHeader title="Balance History" />
                    <Button disabled={this.state.sending} style={{top:topDeposit,position:"relative"}} className="ml-auto btn" onClick={(e) => this.openTopUpBalance(e)}>Top Up Balance</Button>
                    <PortletBody className="pt-0" style={{top:"-25px",position:"relative"}}>
                        <Table striped bordered hover responsive>
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Type</th>
                                <th>Amount</th>
                                <th>Description</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {rows}
                            </tbody>
                        </Table>
                        {balanceHistory.meta && balanceHistory.meta.last_page > 1 && <UPagination currentPage={balanceHistory.meta.current_page} totalPages={balanceHistory.meta.last_page} onChange={handleNextPage} />}
                    </PortletBody>
                </Portlet>
            </>
        );
    }
}

const mapStateToProps = ({ auth: { user, balanceHistory }, profile: { billingInfo } }) => ({
    balanceHistory, billingInfo, user
});

export default injectIntl(connect(
    mapStateToProps,
    { ...auth.actions, ...profile.actions}
)(AccountBalance));
