import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as marketplace from "../../../store/ducks/marketplace.duck";
import {Form, Button} from "react-bootstrap";
import {Spinner} from "reactstrap";
import {makeOffer} from "../../../crud/offer.crud";

class MakeAnOfferModal extends React.Component {
    constructor(props) {
        super(props);

        const {makeOfferItem, currentOfferFilter} = this.props;
        let userOffer = 0.00;
        let sending = false;
        this.state = {
            makeOfferItem: makeOfferItem,
            currentOfferFilter: currentOfferFilter,
            sending: sending,
            userOffer: userOffer
        };
    }

    render() {
        const {open, onClose, filter, makeOfferItem, currentOfferFilter} = this.props;

        const handleClose = () => {
        	setOffer(0);
            onClose();
        };

        const setOffer = (offer) => {
        	this.setState({
                ...this.state,
        		userOffer: offer
        	});
        };

        const handleSendOffer = () => {
        	this.setState({
                ...this.state,
        		sending: true
        	});

        	makeOffer(
                makeOfferItem.key,
                this.state.userOffer
            )
            .then((res) => {
                this.setState({
	                ...this.state,
	        		sending: false
	        	});
	        	setOffer(0);
	        	let newOfferFilter = currentOfferFilter;
	        	newOfferFilter.push(makeOfferItem.key);
	        	filter['offer_id_keys'] = newOfferFilter;
	        	this.props.initMarketplace(1, filter);
	        	onClose();

            })
            .catch(e => {
            	this.setState({
	                ...this.state,
	        		sending: false
	        	});
                if(e && e.response && e.response.data) {
                    // setStatus(
                    //     e.response.data.message
                    // );
                }
            });
        }

        return (<Dialog open={open} onClose={handleClose} fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle>Make an Offer</DialogTitle>
            <DialogContent dividers>
                <h5>{makeOfferItem.name}</h5>
                <p>{makeOfferItem.description}</p>
	            <div className="input-group">
				  	<div className="input-group-prepend">
				    	<span className="input-group-text" id="basic-addon1">$</span>
				  	</div>
		            <input
		                autoFocus
		                onChange={(e) => setOffer(e.target.value)}
		                placeholder="Your offer"
		                type="number"
		                step="0.01"
		                className="form-control"
		                defaultValue={this.state.userOffer}
		               	/>
	            </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
                 <Button
                    variant="success"
                    onClick={handleSendOffer}
                    disabled={! this.state.userOffer || this.state.userOffer == "" || this.state.sending}
                >
                    {this.state.sending ? "Sending.." : "Make Offer"}
                </Button>
            </DialogActions>
        </Dialog>);
    }
}

MakeAnOfferModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ marketplace: { filter, guestPosts } }) => ({
    filter, guestPosts
});

export default injectIntl(connect(
    mapStateToProps,
    marketplace.actions,
)(MakeAnOfferModal));
