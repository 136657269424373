import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getGuestPost, getGuestPosts, getBlogs, getWriters } from "../../../crud/admin/orders.crud";

export const actionTypes = {
    GuestPostsInit: "[Init Guest Posts Admin] Action",
    GuestPostsRequested: "[Request Guest Posts Admin] Action",
    GuestPostsLoaded: "[Load Guest Posts Admin] Orders API",
    GuestPostRequested: "[Request Guest Post Admin] Action",
    GuestPostLoaded: "[Load Guest Post Admin] Orders API",
    BlogsInit: "[Init Blogs Admin] Action",
    BlogsRequested: "[Request My Blogs] Action",
    BlogsLoaded: "[Load My Blogs] Marketplace API",

    WritersInit: "[Init Writers Admin] Action",
    WritersRequested: "[Request My Writers] Action",
    WritersLoaded: "[Load My Writers] Marketplace API",
};

const initialGuestPostsState = {
    guestPosts: undefined,
    currentGuestPost: undefined,
    writers: undefined,
};

export const reducer = persistReducer(
    { storage, key: "admin-orders", whitelist: ["guestPosts", "currentGuestPost"] },
    (state = initialGuestPostsState, action) => {
        switch (action.type) {

            case actionTypes.BlogsInit: {
                return { guestPosts: undefined };
            }

            case actionTypes.GuestPostsInit: {
                return { guestPosts: undefined, currentGuestPost: undefined };
            }

            case actionTypes.GuestPostsLoaded: {
                const { guestPosts } = action.payload;

                return { ...state, guestPosts };
            }

            case actionTypes.GuestPostRequested: {
                return { ...state, currentGuestPost: undefined };
            }

            case actionTypes.GuestPostLoaded: {
                const { currentGuestPost } = action.payload;

                return { ...state, currentGuestPost };
            }

            case actionTypes.BlogsRequested: {
                return { ...state, guestPosts: undefined };
            }

            case actionTypes.BlogsLoaded: {
                const { guestPosts } = action.payload;

                return { ...state, guestPosts };
            }

            case actionTypes.WritersInit: {
                return { writers: undefined };
            }

            case actionTypes.WritersRequested: {
                return { ...state, writers: undefined };
            }

            case actionTypes.WritersLoaded: {
                const { writers } = action.payload;

                return { ...state, writers };
            }

            default:
                return state;
        }
    }
);

export const actions = {
    initGuestPosts: (orderId, page, params) => ({ type: actionTypes.GuestPostsInit, payload: { orderId, page, params } }),
    requestGuestPosts: (orderId, page, params) => ({ type: actionTypes.GuestPostsRequested, payload: { orderId, page, params } }),
    fulfillGuestPosts: guestPosts => ({ type: actionTypes.GuestPostsLoaded, payload: { guestPosts } }),
    requestGuestPost: id => ({ type: actionTypes.GuestPostRequested, payload: { id } }),
    fulfillGuestPost: currentGuestPost => ({ type: actionTypes.GuestPostLoaded, payload: { currentGuestPost } }),

    initBlogs: page => ({ type: actionTypes.BlogsInit, payload: { page } }),
    requestBlogs: page => ({ type: actionTypes.BlogsRequested, payload: { page } }),
    fulfillBlogs: (guestPosts) => ({ type: actionTypes.BlogsLoaded, payload: { guestPosts } }),

    initWriters: page => ({ type: actionTypes.WritersInit, payload: { page } }),
    requestWriters: page => ({ type: actionTypes.WritersRequested, payload: { page } }),
    fulfillWriters: (writers) => ({ type: actionTypes.WritersLoaded, payload: { writers } }),
};

export function* saga() {
    yield takeLatest(actionTypes.GuestPostsInit, function* initGuestPostsSaga(action) {
        const { orderId, page, params } = action.payload;
        yield put(actions.requestGuestPosts(orderId, page, params));
    });

    yield takeLatest(actionTypes.GuestPostsRequested, function* guestPostsRequested(action) {
        try {
            const { orderId, page, params } = action.payload;
            const {data: guestPosts} = yield getGuestPosts(orderId, page, params);

            yield put(actions.fulfillGuestPosts(guestPosts));
        }
        catch (e) {
            yield put(actions.fulfillGuestPosts({data: undefined}));
        }
    });

    yield takeLatest(actionTypes.GuestPostRequested, function* guestPostRequested(action) {
        try {
            const { id } = action.payload;
            const {data: currentGuestPost} = yield getGuestPost(id);

            yield put(actions.fulfillGuestPost(currentGuestPost));
        }
        catch (e) {
            yield put(actions.fulfillGuestPost({data: undefined}));
        }
    });

    yield takeLatest(actionTypes.BlogsInit, function* initBlogsSaga(action) {
        try {
            const { page } = action.payload;
            yield put(actions.requestBlogs(page));
        } catch (e) {
             // yield put(actions.requestBlogs(0));
        }
    });

    yield takeLatest(actionTypes.BlogsRequested, function* blogsRequesed(action) {
        try {
            const { page } = action.payload;
            const { data: guestPosts } = yield getBlogs(page);
            yield put(actions.fulfillBlogs(guestPosts));

        } catch (e) {
            // const { page } = 0;
            // const { data: guestPosts } = yield getBlogs(page);
            // yield put(actions.fulfillBlogs(guestPosts));

        }
    });

    yield takeLatest(actionTypes.WritersInit, function* initWritersSaga(action) {
        try {
            const { page } = action.payload;
            yield put(actions.requestWriters(page));
        } catch (e) {
             // yield put(actions.requestWriters(0));
        }
    });

    yield takeLatest(actionTypes.WritersRequested, function* WritersRequesed(action) {
        try {
            const { page } = action.payload;
            const { data: writers } = yield getWriters(page);
            yield put(actions.fulfillWriters(writers));

        } catch (e) {
            // const { page } = 0;
            // const { data: writers } = yield getWriters(page);
            // yield put(actions.fulfillWriters(writers));

        }
    });
}
