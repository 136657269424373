import axios from "axios";

export const base = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL : "";
export const API_ANALYTICS_URL = base + "/api/analytics";
export const API_PROJECT_URL = base + "/api/projects";

export function getProjects() {
    return axios.get(API_PROJECT_URL);
}

export function getUser(id) {
    return axios.get(API_ANALYTICS_URL + "/user/" + id);
}

export function addUser(id) {
    return axios.post(API_ANALYTICS_URL + "/user", { user_id: id, action: 'add' });
}

export function getSite(id) {
    return axios.get(API_ANALYTICS_URL + "/site/" + id);
}

export function addSite(id) {
    return axios.post(API_ANALYTICS_URL + "/site", { project_id: id, action: 'add' });
}

export function delSite(id) {
    return axios.post(API_ANALYTICS_URL + "/site", { project_id: id, action: 'delete' });
}

export function getAnalytic(id, params={}) {
    return axios.post(API_ANALYTICS_URL + "/data/" + id, { project_id: id, params: params });
}

export function verifyTrackingCode(id) {
    return axios.get(API_ANALYTICS_URL + "/verify/" + id);
}

//Notes


export function getAllNotes() {
    return axios.get(API_ANALYTICS_URL + "/notes");
}

export function getNotes(project_id) {
    return axios.get(API_ANALYTICS_URL + "/notes/project/" + project_id);
}

export function getCategorys(project_id) {
    return axios.get(API_ANALYTICS_URL + "/notes/categorys/project/" + project_id);
}

export function getNote(id) {
    return axios.get(API_ANALYTICS_URL + "/notes/" + id);
}

export function addNote(params) {
    return axios.post(API_ANALYTICS_URL + "/notes", params);
}

export function updateNote(id, params) {
    return axios.put(API_ANALYTICS_URL + "/notes/" + id, params);
}

export function delNote(id) {
    return axios.delete(API_ANALYTICS_URL + "/notes/" + id);
}


//Google Updates


export function getGoogleUpdates(paginate=0, page=1) {
    return axios.get(API_ANALYTICS_URL + "/google_update?" +
        "paginate=" + paginate + '&page=' + page);
}

export function getGoogleUpdate(id) {
    return axios.get(API_ANALYTICS_URL + "/google_update/" + id);
}

export function addGoogleUpdate(params) {
    return axios.post(API_ANALYTICS_URL + "/google_update", params);
}

export function updateGoogleUpdate(id, params) {
    return axios.put(API_ANALYTICS_URL + "/google_update/" + id, params);
}

export function delGoogleUpdate(id) {
    return axios.delete(API_ANALYTICS_URL + "/google_update/" + id);
}

export function parseAlgoroo() {
    return axios.get(API_ANALYTICS_URL + "/parse");
}

export function getSiteData(projectId, type = "", page = 1 ) {
    return axios.get(API_ANALYTICS_URL + "/site_data?project_id=" + projectId + (type ? "&type=" + type : "") + (page > 1 ? "&page=" + page : ""));
}
