import React, { Component } from "react";
import {Modal, Table} from "react-bootstrap";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as onpage from "../../../store/ducks/onpage.duck";
import {Spinner} from "reactstrap";
import UPagination from "../../../partials/content/Pagination";

class AnchorsModal extends Component {
    componentDidMount() {
        const {projectId} = this.props;
        const page = 1;
        if(projectId > 0) {
            this.props.requestAnchors(projectId, page);
        }
    }

    render() {
        const {anchors} = this.props;
        const anchorsRows = [];
        if(anchors && anchors.data) {
            const anchorsData = anchors.data;
            const linksNumber = anchors.links_number;
            if(anchorsData.data.length !== 0) {
                for(let i = 0; i < anchorsData.data.length; i++) {
                    anchorsRows.push(<tr key={"anchor" + i}>
                        <td>{anchorsData.data[i].anchor ? anchorsData.data[i].anchor : "empty anchor"}</td>
                        <td className="text-center">{anchorsData.data[i].count}</td>
                        <td className="text-center">{(anchorsData.data[i].count / linksNumber * 100).toFixed(2) + "%"}</td>
                    </tr>);
                }
            }
        }
        const anchorsPageChanged = (activePage) => {
            const {projectId} = this.props;
            this.props.requestAnchors(projectId, activePage);
        };

        return (
            <>
                <Modal
                    show={this.props.show}
                    onHide={this.props.onHide}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Internal Links - Anchor Text Ratio
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {!anchors && <Spinner />}
                        {(anchors && !anchors.data) && <h1>Anchors data not found!</h1>}
                        {anchorsRows.length > 0 && <Table responsive>
                            <thead>
                                <tr><th>Anchor Text</th><th className="text-center">Quantity</th><th className="text-center">Ratio</th></tr>
                            </thead>
                            <tbody>
                                {anchorsRows}
                            </tbody>
                        </Table>}
                        {(anchors && anchors.data) && <UPagination currentPage={anchors.data.current_page} totalPages={anchors.data.last_page} onChange={anchorsPageChanged} />}
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = ({onpage: { anchors } }) => ({
    anchors
});

export default injectIntl(
    connect(
        mapStateToProps,
        onpage.actions
    )(AnchorsModal)
);
