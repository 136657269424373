import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import * as cart from "../../../app/store/ducks/cart.duck";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

class MenuItemText extends React.Component {
    render() {
    const { item, parentItem, userDiscountPercent } = this.props;

    return (
      <>
        {item.icon && <i className={`kt-menu__link-icon ${item.icon}`} />}

        {parentItem && parentItem.bullet === "dot" && (
          <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
            <span />
          </i>
        )}

        {parentItem && parentItem.bullet === "line" && (
          <i className="kt-menu__link-bullet kt-menu__link-bullet--line">
            <span />
          </i>
        )}

        <span className="kt-menu__link-text" style={{ whiteSpace: (!item.wrap ? "nowrap" : "")}}>
          {!item.translate ? (
            item.title
          ) : (
            <FormattedMessage id={item.translate} defaultMessage={item.title} />
          )}
        </span>

        {item.soon && <span className="kt-menu__link-text kt-font-sm" style={{ whiteSpace: "nowrap"}}>(Coming soon)</span>}

        {item.badge && (!item.badge.condition || (userDiscountPercent && userDiscountPercent[item.badge.condition] > 0)) && (
          <span className="kt-menu__link-badge">
            <span className={`kt-badge ${item.badge.type}`}>
              {item.badge.value}
            </span>
          </span>
        )}

        {/*{item.submenu && <i className="kt-menu__ver-arrow la la-angle-right" />}*/}
      </>
    );
  }
}

const mapStateToProps = ({ cart: { userDiscountPercent } }) => ({
    userDiscountPercent
});

export default withRouter((injectIntl(connect(
    mapStateToProps,
    null
)(MenuItemText))));
