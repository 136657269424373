import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import EditProfile from "../profile-components/EditProfile";
import ProfileSettings from "../profile-components/ProfileSettings";
import ChangePassword from "../profile-components/ChangePassword";
import DeleteAccount from "../profile-components/DeleteAccount";

class CombinedProfileSettings extends React.Component {
    constructor(props) {
        super(props);


    }

    componentDidMount() {


    }

    render() {

    	return (
    		<div>
            	<EditProfile />
            	<div style={{ height:'30px', display: 'block' }}> &nbsp; </div>
            	<ProfileSettings />
            	<div style={{ height:'30px', display: 'block' }}> &nbsp; </div>
            	<ChangePassword />
            	<div style={{ height:'30px', display: 'block' }}> &nbsp; </div>
            	<DeleteAccount />
            </div>
        );

    }

}

const mapStateToProps = ({  }) => ({
   
});

export default injectIntl(connect(
    mapStateToProps
)(CombinedProfileSettings));