import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, Button, DialogTitle, DialogContent, DialogActions, Dialog, RadioGroup, Radio, FormControlLabel} from '@material-ui/core';
import * as marketplace from '../store/ducks/marketplace.duck';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {useState} from "react";
import { Container } from "@material-ui/core";

const options = [
    {key:'', value:'Default'},
    {key:'id-asc', value:'Site Id (↑)'},
    {key:'id-desc', value:'Site Id (↓)'},
    {key:'category_id-asc', value:'Category (↑)'},
    {key:'category_id-desc', value:'Category (↓)'},
    {key:'da-asc', value:'DA (↑)'},
    {key:'da-desc', value:'DA (↓)'},
    {key:'dr-asc', value:'DR (↑)'},
    {key:'dr-desc', value:'DR (↓)'},
    {key:'rd-asc', value:'RD (↑)'},
    {key:'rd-desc', value:'RD (↓)'},
    {key:'organic_traffic-asc', value:'Organic Traffic (↑)'},
    {key:'organic_traffic-desc', value:'Organic Traffic (↓)'},
    {key:'price-asc', value:'Price (↑)'},
    {key:'price-desc', value:'Price (↓)'},
];
//disableBackdropClick & onenter workaround (deprecated)
function dialogmigrate({children, disableBackdropClick,
  disableEscapeKeyDown, onClose, onEntering, ...rest}) {
	  
	const handleEnter = (event, reason) => {
		if (disableBackdropClick && reason === "backdropClick") {
		  return false;
		}

		if (disableEscapeKeyDown && reason === "escapeKeyDown") {
		  return false;
		}

		if (typeof onEntering === "function") {
		  onEntering();
		}
	  };
	  
  const handleClose = (event, reason) => {
		if (disableBackdropClick && reason === "backdropClick") {
		  return false;
		}

		if (disableEscapeKeyDown && reason === "escapeKeyDown") {
		  return false;
		}

		if (typeof onClose === "function") {
		  onClose();
		}   
		
	  };
  

  return (
    <Dialog onClose={handleClose} onEntering={handleEnter} {...rest}>
      {children}
    </Dialog>
  );
}

function MarketplaceSortDialog(props) {
    const { filter, onClose, open, className } = props;
    const valueProp = filter && filter.sort && filter.order ? filter.sort + '-' + filter.order : '';
    const [value, setValue] = React.useState(valueProp);
    const radioGroupRef = React.useRef(null);

    React.useEffect(() => {
        if (!open) {
            setValue(valueProp);
        }
    }, [valueProp, open]);

    function handleEntering() {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
        setValue(valueProp);
    }

    function handleCancel() {
        onClose();
    }

    function handleOk() {
        const sort = value ? value.split('-')[0] : "";
        const order = value && value.split('-')[1] ? value.split('-')[1] : "";
        filter["sort"] = sort;
        filter["order"] = order;
        props.initMarketplace(1, filter);

        onClose(value);
    }

    function handleChange(event, newValue) {
        setValue(newValue);
    }
	

    return (
        <Dialog
            dialogmigrate
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={open}
            keepMounted
			onClose={() => {
			handleCancel()
			}}
            className={className}
        >
            <DialogTitle id="confirmation-dialog-title">Sort Guest Posts</DialogTitle>
            <DialogContent dividers>
                <RadioGroup
                    ref={radioGroupRef}
                    aria-label="Sort"
                    name="sort"
                    value={value}
                    onChange={handleChange}
                >
                    {options.map(option => (
                        <FormControlLabel value={option.key} key={option.key} control={<Radio />} label={option.value} />
                    ))}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleOk} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}

MarketplaceSortDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onEntering: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default injectIntl(
    connect(
        ({ marketplace }) => ({ filter: marketplace.filter }),
        marketplace.actions
    )(MarketplaceSortDialog)
);
