import React, {useState} from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import { changePassword } from "../../../crud/user.crud";

function ChangePassword(props) {
    const { intl } = props;
    const [loading, setLoading] = useState(false);
    const [setLoadingButtonStyle] = useState({
        paddingRight: "2.5rem"
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    return (
        <div className="kt-login__body">
            <label className="mb-0">Change Password</label>
            <div className="kt-login__form">

                <Formik
                    initialValues={{
                        password: "",
                        confirmPassword: "",
                    }}
                    validate={values => {
                        const errors = {};

                        if (!values.password) {
                            errors.password = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }
                        else if(values.password.length < 6) {
                            errors.password = "Password must be at least 6 characters.";
                        }
                        else if(!/[a-zA-z]/.test(values.password)) {
                            errors.password = "Password must contain at least one letter.";
                        }
                        else if(!/[0-9]/.test(values.password)) {
                            errors.password = "Password must contain at least one digit.";
                        }

                        if (!values.confirmPassword) {
                            errors.confirmPassword = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        } else if (values.password !== values.confirmPassword) {
                            errors.confirmPassword =
                                "Password and Confirm Password didn't match.";
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        enableLoading();
                        changePassword(
                            values.password,
                            values.confirmPassword
                        )
                            .then(({ data: { access_token } }) => {
                                disableLoading();
                                setSubmitting(false);
                                setStatus("Updated.");
                                values.password = "";
                                values.confirmPassword = "";
                            })
                            .catch(() => {
                                disableLoading();
                                setSubmitting(false);
                                setStatus(
                                    intl.formatMessage({
                                        id: "AUTH.VALIDATION.INVALID_LOGIN"
                                    })
                                );
                            });
                    }}
                >
                    {({
                          values,
                          status,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                      }) => (
                        <form onSubmit={handleSubmit} noValidate autoComplete="off">
                            {status && !isSubmitting && (
                                <div role="alert" className={'alert ' + (status === 'Updated.' ? 'alert-success' : 'alert-danger')}>
                                    <div className="alert-text">{status}</div>
                                </div>
                            )}

                            <div className="form-group mb-0 mt-0">
                                <TextField
                                    type="password"
                                    margin="normal"
                                    label="Password"
                                    className="kt-width-full mt-0"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                    helperText={touched.password && errors.password}
                                    error={Boolean(touched.password && errors.password && !isSubmitting)}
                                />
                            </div>

                            <div className="form-group mb-2">
                                <TextField
                                    type="password"
                                    margin="normal"
                                    label="Confirm Password"
                                    className="kt-width-full"
                                    name="confirmPassword"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.confirmPassword}
                                    helperText={touched.confirmPassword && errors.confirmPassword}
                                    error={Boolean(
                                        touched.confirmPassword && errors.confirmPassword && !isSubmitting
                                    )}
                                />
                            </div>

                            <div className="kt-login__actions">
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                                        {
                                            "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                                        }
                                    )}`}
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

const mapStateToProps = ({ auth: { user } }) => ({
    user
});

export default injectIntl(connect(mapStateToProps)(ChangePassword));
