import React, {Component} from "react";
import {Modal, Button} from "react-bootstrap";
import * as analytics from "../../../store/ducks/analytics.duck";
import { getNote, addNote, delNote, updateNote, getCategorys } from "../../../crud/analytics.crud";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Spinner} from "reactstrap";
import {TextField} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns';
import { Alert } from '@material-ui/lab';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

class NotesModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noteCategorys: [],
            dateNote: new Date(),
            categoryNote: '',
            costNote: '',
            textNote: '',
            load: false,
            active: false,
            error: false
        };
    }


    getAnalyticsNote = (id) => {

        if (!id) {
            return false;
        }

        this.setState({load: true});

        getNote(
            id
        )
            .then((res) => {

                //console.log('res', res);

                let result = res.data;

                //console.log('typeof result', typeof result);
                //console.log('result', result);

                if (!result) {
                    this.setState({active: false, load: false, error: res.toString()});
                    return false;
                }

                this.setState({
                    dateNote: result.date,
                    categoryNote: (result.category) ? result.category : '',
                    textNote: result.note,
                    costNote: (result.cost) ? result.cost : '',
                    active: true,
                    load: false,
                    error: false
                });

            })
            .catch((e) => {
                this.setState({active: false, load: false, error: e.toString()});
                console.log('Error - getNotes')
            });

    };



    getNoteCategorys = (id) => {


        const categorys = [
            'Citations',
            'Competitor Analysis',
            'Keyword Research',
            'Linkbuilding',
            'Link Audit & Disavow',
            'Niche Research',
            'On-Page SEO',
            'Technical SEO'
        ];

        this.setState({
            noteCategorys: categorys,
            active: true,
            load: false
        });

        return;


        if (!id) {
            return false;
        }

        this.setState({load: true});

        getCategorys(
            id
        )
            .then((res) => {

                //console.log('res', res);

                let result = res.data;

                if (!result) {
                    this.setState({active: false, load: false});
                    return false;
                }

                let categorys = [];

                result.forEach((obj) => {
                    categorys.push(obj.category)
                });

                this.setState({
                    noteCategorys: categorys,
                    active: true,
                    load: false
                });


            })
            .catch(() => {
                this.setState({active: false, load: false});
                console.log('Error - getCategorys')
            });

    };





    componentDidUpdate(prevProps, prevState) {

        //console.log('prevState', prevState);        console.log('this.state', this.state);




        if (this.props.show && this.state == prevState) {
            this.getAnalyticsNote(this.props.noteId);
        }



        if (this.props.noteId !== prevProps.noteId) {
            if (this.props.noteId == false) {
                this.setState({categoryNote: '', textNote: '', costNote: ''});
            }
            if (this.props.show) {
                this.getAnalyticsNote(this.props.noteId);
                this.setState({error: false});
            }
        }


        if (this.props.noteDate !== prevProps.noteDate) {
            if(this.props.noteDate)
                this.setState({dateNote: this.props.noteDate});
            else
                this.setState({dateNote: new Date()});
            }

    }

    componentDidMount() {
        this.getNoteCategorys(this.props.currentProject.id);
    }



    render() {

        const {show, onHide, action, noteId, noteDate, currentProject} = this.props;
        const {dateNote, categoryNote, textNote, costNote, noteCategorys, error, load} = this.state

        //console.log('this.state', this.state);
        //console.log('this.props', this.props);

        const handleAddNote = () => {

            if(!currentProject){
                this.setState({error: 'Select Project.'});
                return;
            }
            if(!dateNote){
                this.setState({error: 'Date field is empty.'});
                return;
            }
            if(!textNote){
                this.setState({error: 'Note field is empty.'});
                return;
            }


            addNote({
                date: dateNote,
                note: textNote,
                cost: costNote,
                category: categoryNote,
                project_id: currentProject.id
            })
                .then((res) => {
                    //console.log('res', res);
                    if(res.data.id){
                        onHide();
                        console.log('Success - addNote');
                        this.props.requestAnalyticsNotes(currentProject.id);
                    } else {
                        this.setState({error: res.toString()});
                    }

                })
                .catch((e) => {
                    //console.log('e', e);
                    this.setState({error: e.toString()});
                    console.log('Error - addNote');
                });
        };

        const handleDeleteNote = () => {

            if(!noteId){
                this.setState({error: 'Note id is empty.'});
                return;
            }

            delNote(noteId)
                .then((res) => {

                    if(res){
                        onHide();
                        console.log('Success - delNote');
                        this.props.requestAnalyticsNotes(currentProject.id);
                    } else {
                        this.setState({error: res.toString()});
                    }

                })
                .catch((e) => {
                    console.log('Error - delNote');
                    this.setState({error: e.toString()});
                });
        };


        const handleUpdateNote = () => {

            if(!noteId){
                this.setState({error: 'Note id is empty.'});
                return;
            }
            if(!currentProject){
                this.setState({error: 'Select Project.'});
                return;
            }
            if(!dateNote){
                this.setState({error: 'Date field is empty.'});
                return;
            }
            if(!textNote){
                this.setState({error: 'Note field is empty.'});
                return;
            }


            updateNote(noteId, {
                id: noteId,
                date: dateNote,
                note: textNote,
                cost: costNote,
                category: categoryNote,
                project_id: currentProject.id
            })
                .then((res) => {
                    //console.log('res', res);

                    if(res.data.id){
                        onHide();
                        console.log('Success - updateNote');
                        this.props.requestAnalyticsNotes(currentProject.id);
                    } else {
                        this.setState({error: res.toString()});
                    }

                })
                .catch((e) => {
                    console.log('Error - updateNote');
                    this.setState({error: e.toString()});
                });

        };


        const handleDateChange = (dateInput) => {
            const date = new Date(dateInput);
            const formated_date = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
            this.setState({dateNote: formated_date});
        };


        const handleCost = (e) => {
            //const re = /^[0-9\b]+$/;
            //if (e.target.value === '' || re.test(e.target.value))
                this.setState({costNote: e.target.value})

        }


        const Capitalize = (str) => {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }


        return (
            <Modal
                show={show}
                onHide={this.props.onHide}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {Capitalize(action)} task {load && ( <Spinner size="sm" /> )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                     <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            className="mb-3"
                            disabled={(action == 'delete')}
                            disableToolbar
                            required={true}
                            variant="outlined"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Date"
                            value={dateNote}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                     </MuiPickersUtilsProvider>

                    <Autocomplete
                        id="combo-category"
                        value={categoryNote}
                        onChange={(event, newValue) => { this.setState({categoryNote: newValue})} }
                        onInputChange={(event, newValue) => { this.setState({categoryNote: newValue})} }
                        disabled={(action == 'delete')}
                        freeSolo={true}
                        options={noteCategorys}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => <TextField {...params} label="Category" variant="outlined" />}
                    />
                    <TextField
                        placeholder="Description task ..."
                        disabled={(action == 'delete')}
                        fullWidth
                        required={true}
                        margin="normal"
                        id="outlined-multiline-static"
                        label="Description"
                        multiline
                        rows={3}
                        value={textNote}
                        onChange={(event) => { this.setState({textNote: event.target.value})} }
                        variant="outlined"
                    />
                    <TextField
                        disabled={(action == 'delete')}
                        fullWidth
                        margin="normal"
                        label="Cost"
                        value={costNote}
                        onChange={ handleCost }
                        variant="outlined"
                    />
                    {error && (<Alert severity="error">Error : {error}</Alert>)}
                 </Modal.Body>
                <Modal.Footer>
                    {action == 'add' && (
                    <Button size="sm" variant="primary" onClick={()=>{handleAddNote()}}>
                        Add
                    </Button>)}
                    {(action == 'edit' || action == 'manage') && (
                    <Button size="sm" variant="primary" onClick={handleUpdateNote}>
                        Save
                    </Button>)}
                    {(action == 'delete' || action == 'manage') && (
                    <Button size="sm" variant={(action == 'manage') ? 'outline-primary' : 'primary' } onClick={handleDeleteNote}>
                        Delete
                    </Button>)}
                    <Button size="sm" variant="secondary" color="primary" onClick={this.props.onHide}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}



export default injectIntl(connect(
    null,
    analytics.actions
)(NotesModal));
