import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as orders from "../../store/ducks/admin/orders.duck";
import {Spinner} from "reactstrap";
import {Table, Badge} from "react-bootstrap";
import {Portlet, PortletBody, PortletHeader} from "../../partials/content/Portlet";
import {Link} from "react-router-dom";
import {Icon} from "@material-ui/core";
import {downloadPdfInvoice} from "../../crud/admin/orders.crud";
import UPagination from "../../partials/content/Pagination";

class OrdersAdmin extends Component {
    componentDidMount() {
        // const getPage = new URLSearchParams(window.location.search).get('page');
        const getPage = new URLSearchParams(this.props.location.search).get("page");
        const getMonth = new URLSearchParams(this.props.location.search).get("month");
        const getYear = new URLSearchParams(this.props.location.search).get("year");

        if (getPage != null && getMonth != null && getYear != null) {
            this.props.initOrdersWithArgs(getPage, getMonth, getYear);
        }
        else if (getPage != null) {
            this.props.initOrders(getPage);
        } else {
            this.props.initOrders();
        }
    }

    render() {
        const {orders} = this.props;

        if (!orders) {
            return <Spinner/>;
        }

        let the_current_page = null;

        const rows = [];

        if (orders.meta && orders.data) {
            if (orders.data.length) {

                // const getPage = new URLSearchParams(window.location.search).get('page');
                const getPage = new URLSearchParams(this.props.location.search).get("page");
                if (getPage != null) {
                    the_current_page = parseInt(getPage);
                } else {
                    the_current_page = orders.meta.current_page;
                }

                for (const [index] of orders.data.entries()) {
                    const date = new Date(orders.data[index].created_at);
                    let statusVariant = "secondary";
                    if(parseInt(orders.data[index].status) === 1)
                    {
                        statusVariant = "info";
                    }
                    else if(parseInt(orders.data[index].status) === 2) {
                        statusVariant = "success";
                    }
                    let customer = "";
                    if(orders.data[index].billing_firstname) {
                        customer += orders.data[index].billing_firstname + " ";
                    }
                    if(orders.data[index].billing_lastname) {
                        customer += orders.data[index].billing_lastname;
                    }

                    rows.push(<tr key={index}>
                        <td>{orders.data[index].order_number}</td>
                        <td><Badge pill variant={statusVariant}>{orders.data[index].status_label}</Badge></td>
                        <td>{customer}</td>
                        <td>{date.toLocaleString()}</td>
                        <td><Link to={"/admin/orders/" + orders.data[index].id + "/progress?page="+the_current_page}><Icon className="fas fa-eye" style={{fontSize: "11px", width: "auto"}} /> View</Link></td>
                        <td>$&nbsp;{orders.data[index].total.toFixed(2)}</td>
                        <td><Link to={"/admin/orders/" + orders.data[index].id + "/invoice?page="+the_current_page}><Icon className="fas fa-eye" style={{fontSize: "11px", width: "auto"}} /> View</Link> / <a href="#" onClick={(e) => {
                            e.preventDefault();
                            downloadPdfInvoice(orders.data[index].id).then(response => {
                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', orders.data[index].id + '.pdf');
                                document.body.appendChild(link);
                                link.click();
                            });
                        }}><Icon className="fa fa-download" style={{fontSize: "11px", width: "auto"}} /> PDF</a></td>
                    </tr>)
                }
            }
        }

        const handleNextPage = (number) => {
            this.props.history.push("?page="+number);
            this.props.initOrders(number);
        };

        // <div class="row">
        //     <div class="col-md-3">
        //         <Link to={"/admin/offers"}><div className="btn btn-primary mb-4">Go to Backlink Marketplace Offers</div></Link>
        //     </div>
        // </div>
        return (
            <Portlet>
                <PortletHeader title="Orders" />
                <PortletBody>
                    
                    <Table striped bordered hover responsive>
                        <thead>
                        <tr>
                            <th>Order Number</th>
                            <th>Status</th>
                            <th>Customer</th>
                            <th>Date Order</th>
                            <th>Order Progress</th>
                            <th>Total</th>
                            <th>Invoice</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </Table>
                    {the_current_page != null && orders.meta && orders.meta.last_page > 1 && <UPagination currentPage={the_current_page} totalPages={orders.meta.last_page} onChange={handleNextPage} />}
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = ({ ordersAdmin: { orders } }) => ({
    orders
});

export default injectIntl(connect(
    mapStateToProps,
    orders.actions
)(OrdersAdmin));
