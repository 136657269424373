import React, {Component} from "react";
import {Button, Card} from "react-bootstrap";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Spinner} from "reactstrap";
import * as analytics from "../../../store/ducks/analytics.duck";
import {verifyTrackingCode} from "../../../crud/analytics.crud";
import { CodeBlock } from "../../../partials/content/CodeExample";
import Alert from '@material-ui/lab/Alert';
import * as packages from "../../../store/ducks/packages.duck";
import {Link} from "react-router-dom";
import * as projects from "../../../store/ducks/projects.duck";

class InnerModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            verify: false,
            load: false
        };
    }

    componentDidMount() {
        const type = "analytics_package";
        this.props.requestPackage(type);
    }

    AddSiteHandler = () => {this.props.requestAnalyticsAddSite(this.props.currentProject.id)};

    TrackinCodeVerify = () => {
        this.setState({load: true});
        verifyTrackingCode(
            this.props.currentProject.id
        )
        .then((result) => {
            this.props.fulfillProject({data: {...this.props.currentProject, tracking_code_added: true}});
            this.setState({verify: result.data});
            this.setState({load: false});
        })
        .catch((error) => {
            this.setState({verify: {error: error.toString()}});
            }
        );
    };


    render() {
        const {currentPackage} = this.props;
        const {verify, load} = this.state;
        //console.log('inner-props', this.props); console.log('inner-state', this.state);

        if(!currentPackage) {
            return <Spinner />;
        }
        else if(currentPackage && !currentPackage.type) {
            return <h1>Package not found!</h1>;
        }

        if(!this.props.site){
            if(!currentPackage.activePackage) {
                return (
                    <>
                        <p>Upgrade your account to unlock traffic analytics and start tracking your site.</p>
                    </>
                );
            }
            else if(currentPackage.activePackage &&
                currentPackage.activePackage.user_limits && currentPackage.activePackage.user_limits.length &&
                currentPackage.activePackage.user_limits[0].usage >= currentPackage.activePackage.user_limits[0].value) {
                return (
                    <>
                        <p>Click <Link to={"/packages/marketplace-access"}>here</Link> to upgrade your limit.</p>
                    </>
                );
            }

            return (
                <>
                    <p>Add the tracking code to enable traffic analytics for your site.</p>
                    <br/>
                    <div className="text-center">
                        <Button variant="primary" onClick={this.AddSiteHandler}>
                            Add Site
                        </Button>
                    </div>
                </>
            );

        }else{

            let trackingCode = '<script>var SerpChampStats_site_ids = SerpChampStats_site_ids || []; \n'+
                'SerpChampStats_site_ids.push(' + this.props.site.wh_site_id + ');</script>\n'+
                '<script async src=\"//hello.staticstuff.net/w/SerpChampStats.js\"></script>';

            return (

                <div>
                    <Card>
                        <Card.Body>
                            <Card.Title>Ok! Your tracking code here.</Card.Title>
                            <Card.Text>
                                Copy this code in your header to start tracking your traffic:
                            </Card.Text>
                            <hr/>
                            <CodeBlock
                                language="javascript"
                                code={trackingCode}
                            />
                            {/*<textarea
                             readOnly
                             className="form-control z-depth-1"
                             rows="3"
                             width = '100%'
                             defaultValue = {trackingCode}
                             />*/}
                        </Card.Body>
                    </Card>
                    <br/>
                    <div className="text-center">
                        <Button variant="success" disabled={load ? 'disabled' : ''} onClick={this.TrackinCodeVerify} >
                            Verify
                        </Button>

                        {load && ( <div><br/><Spinner/></div> )}

                        {!load  && verify &&
                            <Alert className='mt-3' severity={Object.keys(verify)[0]}>
                                {verify[Object.keys(verify)[0]]}
                            </Alert>
                        }
                    </div>

                </div>

            );
        }
    }
}

const mapStateToProps = ({ packages: {currentPackage} }) => ({
    currentPackage
});

export default injectIntl(connect(
    mapStateToProps,
    {...analytics.actions, ...packages.actions, ...projects.actions}
)(InnerModal));
