import React from "react";
import {Portlet, PortletBody, PortletFooter, PortletHeader} from "../../../partials/content/Portlet";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as projects from "../../../store/ducks/projects.duck";
import {Button, ButtonToolbar, Form} from "react-bootstrap";
import {Formik} from "formik";
import {addProject} from "../../../crud/projects.crud";
import {Spinner} from "reactstrap";
import {Link} from "react-router-dom";
//import { Steps } from "intro.js-react";
import introJs from 'intro.js';
import "intro.js/introjs.css";
import * as profile from "../../../store/ducks/profile.duck";
import {saveUserOptions} from "../../../crud/user.crud";
import Select from "react-select";

class CreateProjectStep extends React.Component {
    constructor(props) {
        super(props);

        let blogCategoryId = 0;

        this.state = {
            stepsEnabled: false,
            blogCategoryId: blogCategoryId,
            steps: [
                {
                    selector: '#create-project-form',
                    element: document.querySelectorAll('#create-project-form')[0],
                    intro: "Create a campaign by adding your website URL and niche to get started. We'll also ask you to add our tracking code to enable Traffic Analytics, and start auditing your technical SEO."
                },
            ],
            //initialStep: 0,
        };
        //this.steps = null;
    }
    componentDidMount() {
        this.props.requestProjectCategories();
        this.props.requestUserOptions();
        /*if(this.steps && this.steps.introJs && this.state.steps && this.state.steps.length !== 0) {
            console.log(this.steps.introJs);
            this.steps.introJs.setOption("steps", this.state.steps);
            console.log(this.steps.introJs._options.steps);
            for(let i = 0; i < this.state.steps.length; i++) {
                this.steps.introJs._introItems[i] = {};
                this.steps.updateStepElement(i);
            }
        }*/
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        try {
            const {userOptions} = this.props;
            const intro = introJs();
            //intro.exit();
            if (!this.state.stepsEnabled && prevProps.userOptions !== userOptions && userOptions && userOptions["show_intro_project"] && parseInt(userOptions["show_intro_project"]) === 1) {
                window.setTimeout(()=> {
                    intro.onbeforechange(function (targetElement) {
                        if (intro._options.steps.length !== 0) {
                            for (let i = 0; i < intro._options.steps.length; i++) {
                                const selector = intro._options.steps[i].selector;
                                if(selector) {
                                    const element = document.querySelectorAll(selector)[0];

                                    if (element) {
                                        intro._introItems[i].element = element;
                                        intro._introItems[i].position = intro._options.steps[i].position || 'auto';
                                    }
                                }
                            }
                        }
                    }).oncomplete(function () {
                        const data = {
                            options: [
                                {option: "show_intro_project", value: 0}
                            ],
                        };
                        saveUserOptions(data)
                            .then(() => {
                                this.props.fulfillUserOptions(data);
                            })
                            .catch(() => {
                                //
                            });
                    }).setOptions({
                        'showBullets': false,
                        'skipLabel': "Skip",
                    }).addSteps(this.state.steps);
                    intro.start();
                }, 1000);
                this.setState({...this.state, stepsEnabled: true});
            }
        } catch (e) {
            console.log(e);
        }
    }
    /*componentWillUnmount() {
        introJs().exit();
    }*/

    render() {
        /*const onExit = () => {
            this.setState({...this.state, stepsEnabled: false});
        };*/

        const selectStyles = {
	        menu: styles => ({ ...styles, zIndex: 999 }),
	        control: (provided, state) => ({
	            ...provided,
	            minHeight: '47px',
	        }),
	    };

        const {projectCategories} = this.props;
        const categoryOptions = [];
        let item = {value: 0, label: 'Select'};
        categoryOptions.push(item);

        if (!projectCategories) {
            return <Spinner />;

        } else if (projectCategories.data && projectCategories.data.length !== 0) {
            for (let index = 0; index < projectCategories.data.length; index++) {
                let item = {value: projectCategories.data[index].id, label: projectCategories.data[index].name};
                categoryOptions.push(item);
            }
        }

        const setBlogCategoryId = (categoryId) => {
            if (categoryId.value > 0) {
                var el_niche_error = document.querySelector('#create_project_niche_group .invalid-feedback');
                if (el_niche_error != null) el_niche_error.style.display = 'none';
                var el_niche_select = document.getElementById('create_project_niche_select');
                if (el_niche_select != null) {
                    var el_niche_select_error = el_niche_select.getElementsByTagName('div')[0];
                    if (el_niche_select_error != null) {
                        el_niche_select_error.style.borderColor = '#cccccc';
                    }
                }
            }
        	this.setState({
                ...this.state,
        		blogCategoryId: categoryId.value
        	});
        };

        return (<>
            {/*<Steps
                enabled={this.state.stepsEnabled}
                steps={this.state.steps}
                initialStep={this.state.initialStep}
                onExit={onExit}
                options={{
                    skipLabel:"Skip",
                    //hidePrev: true,
                    //showStepNumbers: false,
                    //showBullets: false,
                }}
                ref={steps => (this.steps = steps)}
                onBeforeChange = {nextStepIndex => {
                    if (this.steps && nextStepIndex >= 0) {
                        this.steps.updateStepElement(nextStepIndex);
                    }
                }}
                onComplete={()=>{
                    const data = {
                        options: [
                            {option: "show_intro_project", value: 0}
                        ],
                    };
                    saveUserOptions(data)
                        .then(() => {
                            this.props.fulfillUserOptions(data);
                        })
                        .catch(() => {
                            //
                        });
                }}
            />*/}
            <div id="create-project-form">
                <Formik
                    initialValues={{
                        url: "",
                        niche: "",
                        keywords: "",
                        related_keywords: "",
                    }}
                    validate={values => {
                        const errors = {};

                        /*var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
                            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // ip (v4) address
                            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ //port
                            '(\\?[;&amp;a-z\\d%_.~+=-]*)?'+ // query string
                            '(\\#[-a-z\\d_]*)?$','i');*/

                        if (!values.url/* || !pattern.test(values.url)*/) {
                            errors.url = "Please provide valid url.";
                        }

                        if (this.state.blogCategoryId == 0) {
                            errors.niche = "Please select a niche.";
                            var el_niche_error = document.querySelector('#create_project_niche_group .invalid-feedback');
                            if (el_niche_error != null) el_niche_error.style.display = 'block';
                            var el_niche_select = document.getElementById('create_project_niche_select');
                            if (el_niche_select != null) {
                                var el_niche_select_error = el_niche_select.getElementsByTagName('div')[0];
                                if (el_niche_select_error != null) {
                                    el_niche_select_error.style.borderColor = '#fd397a';
                                }
                            }
                        }

                        /*if (!values.niche) {
                            errors.niche = "Please select niche.";
                        }*/

                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        addProject(
                            values.url,
                            values.keywords,
                            this.state.blogCategoryId,
                            values.related_keywords
                        )
                            .then((res) => {
                                setSubmitting(false);
                                setStatus(
                                    "Saved."
                                );
                                values.url = "";
                                values.keywords = "";
                                values.niche = "";
                                values.related_keywords = "";
                                this.props.reloadProjects();
                                this.props.nextStep();
                            })
                            .catch(e => {
                                setSubmitting(false);
                                if(e && e.response && e.response.data) {
                                    setStatus(
                                        e.response.data.message
                                    );
                                }
                            });
                    }}
                >
                    {({
                        values,
                        status,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                    }) => (
                        <Portlet>
                            <PortletHeader title="Create Project" />
                            <PortletBody>
                                <Form noValidate onSubmit={handleSubmit}>
                                    {status && isSubmitting && (
                                        <div role="alert" className={'alert ' + (status === 'Saved.' ? 'alert-success' : 'alert-danger')}>
                                            <div className="alert-text">{status}</div>
                                        </div>
                                    )}
                                    <div className="row">
                                        <Form.Group className="col-lg-6" controlId="validationFormik01">
                                            <Form.Label>Website URL</Form.Label>
                                            <Form.Control
                                                type="url"
                                                name="url"
                                                placeholder=""
                                                value={values.url}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isInvalid={!!errors.url}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.url}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="col-lg-6" id="create_project_niche_group" controlId="validationFormik02">
                                            <Form.Label>Niche</Form.Label>
                                            {/* <Form.Control as="select" name="niche" value={values.niche} onChange={handleChange} isInvalid={!!errors.niche}>
                                                {categoryOptions}
                                            </Form.Control> */}
                                            <Select
                                                id="create_project_niche_select"
                                                name="niche"
                                                className="mb-0"
                                                // defaultValue={{ label: categoryLabel, value: guestPostItem.category_id }}
                                                options={categoryOptions}
                                                onChange={setBlogCategoryId}
                                                styles={selectStyles}
                                                isInvalid={!!errors.niche}
                                            />
                                            {/* <Form.Control
                                                type="text"
                                                name="niche"
                                                placeholder=""
                                                value={values.niche}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isInvalid={!!errors.niche}
                                            /> */}
                                            <Form.Control.Feedback type="invalid">
                                                {errors.niche}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="col-lg-6" controlId="validationFormik03">
                                            <Form.Label>Main Keyword</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="keywords"
                                                placeholder=""
                                                value={values.keywords}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isInvalid={!!errors.keywords}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.keywords}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="col-lg-6" controlId="validationFormik04">
                                            <Form.Label>Related Keywords</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows="3"
                                                name="related_keywords"
                                                placeholder="Enter one keyword per line"
                                                value={values.related_keywords}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isInvalid={!!errors.related_keywords}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.related_keywords}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </Form>
                            </PortletBody>
                            <PortletFooter>
                                <ButtonToolbar className="justify-content-end">
                                    {!isSubmitting && <Link className="btn btn-secondary kt-mr-10" to="/">
                                        Cancel
                                    </Link>}
                                    <Button variant="primary" onClick={handleSubmit} disabled={isSubmitting}>
                                        {isSubmitting ? (<Spinner />) : 'Create Project'}
                                    </Button>
                                </ButtonToolbar>
                            </PortletFooter>
                        </Portlet>
                    )
                    }
                </Formik>
            </div>
        </>);
    }
}

const mapStateToProps = ({ projects: { projectCategories }, profile: { userOptions } }) => ({
    projectCategories, userOptions
});

export default injectIntl(connect(
    mapStateToProps,
    {...projects.actions,  ...profile.actions}
)(CreateProjectStep));