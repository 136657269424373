import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getBacklinks, getOrders, getOrder, getInvoice, getSaleInvoice } from "../../crud/orders.crud";

export const actionTypes = {
    BacklinksInit: "[Init Backlinks] Action",
    BacklinksRequested: "[Request Backlinks] Action",
    BacklinksLoaded: "[Load Backlinks] Orders API",

    OrdersInit: "[Init Orders] Action",
    OrdersRequested: "[Request Orders] Action",
    OrdersLoaded: "[Load Orders] Orders API",
    OrderRequested: "[Request Order] Action",
    OrderLoaded: "[Load Order] Orders API",
    InvoiceRequested: "[Request Invoice] Action",
    InvoiceLoaded: "[Load Invoice] Action",
    SaleInvoiceRequested: "[Request Sale Invoice] Action",
    SaleInvoiceLoaded: "[Load Sale Invoice] Action"
};

const initialOrdersState = {
    orders: undefined,
    currentOrder: undefined,
    invoice: undefined,
    backlinks: undefined
};

export const reducer = persistReducer(
    { storage, key: "demo3-orders", whitelist: ["orders", "currentOrder", "invoice", "backlinks"] },
    (state = initialOrdersState, action) => {
        switch (action.type) {

            case actionTypes.BacklinksInit: {
                return { orders: undefined };
            }
            case actionTypes.BacklinksRequested: {
                return { ...state, orders: undefined };
            }
            case actionTypes.BacklinksLoaded: {
                const { orders } = action.payload;

                return { ...state, orders };
            }

            case actionTypes.OrdersInit: {
                return { orders: undefined, currentOrder: undefined, invoice: undefined };
            }
            case actionTypes.OrdersLoaded: {
                const { orders } = action.payload;

                return { ...state, orders };
            }

            case actionTypes.OrderRequested: {
                return { ...state, currentOrder: undefined };
            }

            case actionTypes.OrderLoaded: {
                const { currentOrder } = action.payload;

                return { ...state, currentOrder };
            }

            case actionTypes.InvoiceRequested: {
                return { ...state, invoice: undefined };
            }

            case actionTypes.InvoiceLoaded: {
                const { invoice } = action.payload;

                return { ...state, invoice };
            }

            case actionTypes.SaleInvoiceRequested: {
                return { ...state, invoice: undefined };
            }

            case actionTypes.SaleInvoiceLoaded: {
                const { invoice } = action.payload;

                return { ...state, invoice };
            }

            default:
                return state;
        }
    }
);

export const actions = {
    initOrders: (projectId, page) => ({ type: actionTypes.OrdersInit, payload: { projectId, page } }),
    requestOrders: (projectId, page) => ({ type: actionTypes.OrdersRequested, payload: { projectId, page } }),
    fulfillOrders: orders => ({ type: actionTypes.OrdersLoaded, payload: { orders } }),
    
    requestOrder: id => ({ type: actionTypes.OrderRequested, payload: { id } }),
    fulfillOrder: currentOrder => ({ type: actionTypes.OrderLoaded, payload: { currentOrder } }),
    requestInvoice: id => ({ type: actionTypes.InvoiceRequested, payload: { id } }),
    fulfillInvoice: invoice => ({ type: actionTypes.InvoiceLoaded, payload: { invoice } }),
    requestSaleInvoice: order_line_number => ({ type: actionTypes.SaleInvoiceRequested, payload: { order_line_number } }),
    fulfillSaleInvoice: invoice => ({ type: actionTypes.SaleInvoiceLoaded, payload: { invoice } }),

    initBacklinks: (projectId, page, search_query) => ({ type: actionTypes.BacklinksInit, payload: { projectId, page, search_query } }),
    requestBacklinks: (projectId, page, search_query) => ({ type: actionTypes.BacklinksRequested, payload: { projectId, page, search_query } }),
    fulfillBacklinks: orders => ({ type: actionTypes.BacklinksLoaded, payload: { orders } }),
};

export function* saga() {

    yield takeLatest(actionTypes.BacklinksInit, function* initBacklinksSaga(action) {
        const { projectId, page, search_query } = action.payload;
        yield put(actions.requestBacklinks(projectId, page, search_query));
    });
    yield takeLatest(actionTypes.BacklinksRequested, function* backlinksRequested(action) {
        const { projectId, page, search_query } = action.payload;
        const {data: orders} = yield getBacklinks(projectId, page, search_query);
        // try {
            yield put(actions.fulfillBacklinks(orders));
        // }
        // catch (e) {
        //     yield put(actions.fulfillBacklinks({data: undefined}));
        // }
    });


    yield takeLatest(actionTypes.OrdersInit, function* initOrdersSaga(action) {
        const { projectId, page } = action.payload;
        yield put(actions.requestOrders(projectId, page));
    });

    yield takeLatest(actionTypes.OrdersRequested, function* ordersRequested(action) {
        const { projectId, page } = action.payload;
        const {data: orders} = yield getOrders(projectId, page);

        yield put(actions.fulfillOrders(orders));
    });

    yield takeLatest(actionTypes.OrderRequested, function* orderRequested(action) {
        try {
            const { id } = action.payload;
            const {data: currentOrder} = yield getOrder(id);

            yield put(actions.fulfillOrder(currentOrder));
        }
        catch (e) {
            yield put(actions.fulfillOrder({data: undefined}));
        }
    });

    yield takeLatest(actionTypes.InvoiceRequested, function* invoiceRequested(action) {
        try {
            const { id } = action.payload;
            const {data: invoice} = yield getInvoice(id);

            yield put(actions.fulfillInvoice(invoice));
        }
        catch (e) {
            yield put(actions.fulfillInvoice({data: undefined}));
        }
    });

    yield takeLatest(actionTypes.SaleInvoiceRequested, function* saleInvoiceRequested(action) {
        try {
            const { order_line_number } = action.payload;
            const {data: invoice} = yield getSaleInvoice(order_line_number);

            yield put(actions.fulfillSaleInvoice(invoice));
        }
        catch (e) {
            yield put(actions.fulfillSaleInvoice({data: undefined}));
        }
    });
}
