import React from "react";
import {Modal} from "react-bootstrap";
import CheckoutSubscription from "./CheckoutSubscription";
import { useHistory } from 'react-router-dom';

export default function CheckoutSubscriptionModal(props) {
    const history = useHistory();

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="md"
            aria-labelledby="contained-modal-subscription"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-subscription">
                    Checkout
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CheckoutSubscription
                    history={history}
                    item={props.item}
                    paypalPlanId={props.paypalPlanId}
                    stripePriceId={props.stripePriceId}
                />
            </Modal.Body>
        </Modal>
    );
}
