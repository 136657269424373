import React from "react";
import {Portlet, PortletBody} from "../../../partials/content/Portlet";
import * as pagedata from "../../../store/ducks/page.duck";
import "../../../../_metronic/_assets/sass/custom/projects.scss";
// import {Link} from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

class SeoAgreementTemplate extends React.Component {

    componentDidMount () {
        // const script = document.createElement("script");
        // script.src = "https://checkli.com/js/checkli-embed.js";
        // script.async = true;
        // document.body.appendChild(script);

        // const pageName = 'ai_tools_top';
        // this.props.requestPageData(pageName);
    }

    render() {
        const { user, showHi, showAvatar, showBadge, pageData } = this.props;

        let tmo_requirement = 500; // SEARCH ALL - CHANGE UNLOCK TIERS
        let tmo_requirement_currency = '$'+tmo_requirement.toLocaleString(undefined, {maximumFractionDigits:2});

        let tmo_amount = 0;
        if (user.tmo_amount && user.tmo_amount > 0) tmo_amount = user.tmo_amount;

        let page_name = "SEO Agreement Template";

        return (
            <>
                {/* <Portlet className="kt-mt-15" style={{background:"#f2f3f8"}}>
                    <div className="row" style={{marginLeft:0,marginRight:0}}>
                        <div className="col-lg-12 col-12" style={{paddingLeft:0,paddingRight:0}}>
                            <Portlet className="h-100 kt-mb-0" style={{background:"#e7eff3"}}>
                                <PortletBody dangerouslySetInnerHTML={{ __html: pageData ? pageData.top_text : "" }} />
                            </Portlet>
                        </div>
                    </div>
                </Portlet> */}
                <div className="row kt-mt-30">
                    <div className="col-12">
                        <h1 className="custom-header kt-mb-25">{page_name}</h1>
                        {tmo_amount >= tmo_requirement ? <iframe src="https://docs.google.com/document/d/e/2PACX-1vRPmfTo2auVfN-inrQcTLF5k7l8OPYVNSLeXxVnANZFiZKybSRFl65djOifnWbAFA/pub?embedded=true" style={{border:"0px #FFFFFF none", minWidth:"1200px"}} name="myiFrame" scrolling="no" frameborder="1" marginheight="0px" marginwidth="0px" height="4400px" width="100%" allowfullscreen></iframe> : <div class="alert alert-danger">{page_name} requires at least {tmo_requirement_currency} total month's orders.</div>}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ auth: { user }, pagedata: {pageData} }) => ({
  user, pageData
});

export default injectIntl(connect(
    mapStateToProps,
    {...pagedata.actions}
)(SeoAgreementTemplate));
// export default connect(mapStateToProps)(SeoAgreementTemplate);