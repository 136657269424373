import React, { Component } from "react";
import {Portlet, PortletHeader, PortletBody, PortletFooter} from "../../../partials/content/Portlet";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as onpage from "../../../store/ducks/onpage.duck";
import {Spinner} from "reactstrap";
import {ListGroup, ProgressBar} from "react-bootstrap";

const criticalMetrics = [
    "time_load_high",
    "duplicate_pages",
    "duplicate_titles",
    "duplicate_meta_descriptions",
    "links_broken",
    "duplicate_h1",
];
const warningMetrics = [
    "absent_h1_tags",
    "title_empty",
    "meta_description_empty",
    "content_readability_bad",
    "title_long",
    "title_short",
    "meta_description_long",
    "meta_description_short",
];
const otherWarnings = [
    "absent_doctype",
    "absent_encoding_meta_tag",
    "images_invalid_alt",
    "images_invalid_title",
];

class AuditProgress extends Component {
    componentDidMount() {
        this.getStatus();
        this.timer = setInterval(() => this.getStatus(), 7000);
    }
    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
    }
    getStatus = () => {
        const {currentProjectId} = this.props;
        this.props.requestSummary(currentProjectId);
    };
    render() {
        const audit = this.props.summary;
        if(!audit) {
            return <Spinner/>;
        }

        const projectUrl = audit.info ? audit.info.url : "";
        const crawlStart = audit.info ? audit.info.crawl_start : "";
        const summary = audit.data ? audit.data[0] : null;
        const now = audit.progress ? audit.progress : 3;
        let criticalErrorsCnt = 0;
        let warningsCnt = 0;
        let passedCnt = 0;

        if(summary && summary.length !== 0) {
            for (let [key, value] of Object.entries(summary)) {
                if (criticalMetrics.indexOf(key) !== -1) {
                    if(value > 0) {
                        criticalErrorsCnt++;
                    }
                    else {
                        passedCnt++;
                    }
                }
                else if(warningMetrics.indexOf(key) !== -1) {
                    if(value > 0) {
                        warningsCnt++;
                    }
                    else {
                        passedCnt++;
                    }
                }
                else if(otherWarnings.indexOf(key) !== -1) {
                    if(value > 0) {
                        warningsCnt++;
                    }
                    else {
                        passedCnt++;
                    }
                }
            }
        }

        return (
            <div className={!this.props.compact ? "row" : ""}>
                <div className={!this.props.compact ? "col-lg-6" : ""}>
                    <Portlet>
                        <PortletHeader
                            title="OnPage Audit is in progress"
                        />
                        <PortletBody>
                            <div className="kt-mb-10">
                                <strong>Domain:</strong> {summary && summary.domain ? summary.domain : projectUrl}<br/>
                                <strong>Audit Started:</strong> {(new Date(summary && summary.crawl_start ? summary.crawl_start : crawlStart)).toLocaleString()}
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-12">
                                    <ListGroup>
                                        <ListGroup.Item variant="danger">
                                            Critical errors: <strong>{criticalErrorsCnt}</strong>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <ListGroup>
                                        <ListGroup.Item variant="warning">
                                            Warnings: <strong>{warningsCnt}</strong>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <ListGroup>
                                        <ListGroup.Item variant="info">
                                            Passed: <strong>{passedCnt}</strong>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                            </div>
                            <div className="kt-mb-15" />
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-12">
                                    <ListGroup>
                                        <ListGroup.Item variant="secondary">
                                            Pages Crawled: <strong>{summary && summary.pages_total ? summary.pages_total : 0}</strong>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                            </div>
                        </PortletBody>
                        <PortletFooter>
                            <ProgressBar animated now={now} label={`${now}%`} />
                        </PortletFooter>
                    </Portlet>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ projects: { currentProjectId }, onpage: { summary } }) => ({
    currentProjectId, summary
});

export default injectIntl(
    connect(
        mapStateToProps,
        onpage.actions
    )(AuditProgress)
);
