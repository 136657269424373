import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as financials from "../../store/ducks/admin/financials.duck";
import { getFinancials, exportFinancials, getMonthlyExpenseDetails } from "../../crud/admin/financials.crud";
import {Spinner} from "reactstrap";
import {Table, Badge} from "react-bootstrap";
import {Portlet, PortletBody, PortletHeader} from "../../partials/content/Portlet";
import UPagination from "../../partials/content/Pagination";
import {Button} from "react-bootstrap";

export default class FinancialsMonthlyExpenseDetails extends Component {
     constructor(props) {
        super(props);

        // let sending = false;

        this.state = {
            // sending: sending
        };

    }

    componentDidMount() {

    }

    render() {
        const { expense_line_items } = this.props;

        const rows = [];

        var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });

        if (expense_line_items) {
            if (expense_line_items.length) {
                for (const [index] of expense_line_items.entries()) {

                    rows.push(<tr key={index} style={{ height: "10px", fontSize: "10pt", padding: 1, margin: 1 }}>
                        <td>{expense_line_items[index].created_at != null && expense_line_items[index].created_at != "null" ? 
                            expense_line_items[index].created_at.slice(0, 10) : "N/A"}</td>
                        <td>{formatter.format(expense_line_items[index].amount)}</td>
                        <td>{expense_line_items[index].description}</td>
                    </tr>)
                }
            }
        }

        return (
            <Portlet style={{height:"300px", overflowY: "scroll", marginTop: 10}}>
                <PortletHeader title="Expense Line Items" style={{ height: "12px", fontSize: "10pt" }} />
                <PortletBody className="pt-0">
                    <Table striped bordered hover responsive>
                        <thead>
                        <tr style={{ height: "10px", fontSize: "12pt" }}>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </Table>
                    <p>Total Expenses for Month (Excluding MGP): {formatter.format(expense_line_items.reduce((total, obj) => obj.amount + total,0))}</p>
                </PortletBody>
            </Portlet>
        );
    }
}
