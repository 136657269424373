import React from "react";
import {Form, ButtonToolbar, Button, Table, Alert} from "react-bootstrap";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as cart from "../../store/ducks/cart.duck";
import * as profile from "../../store/ducks/profile.duck";
import * as notifications from "../../store/ducks/notifications.duck";
import * as projects from "../../store/ducks/projects.duck";
import * as auth from "../../store/ducks/auth.duck";
import * as marketplace from "../../store/ducks/marketplace.duck";
import { Formik } from "formik";
import Select from "react-select";
import {Spinner} from "reactstrap";
import {addOrder, calculateVat, getUserDiscount, stripePay, validateVat} from "../../crud/orders.crud";
import { PayPalButton } from "react-paypal-button-v2";
import {Radio, RadioGroup, FormControl, FormControlLabel} from "@material-ui/core";
import {loadStripe} from '@stripe/stripe-js';
//import {CardElement, Elements, ElementsConsumer} from "@stripe/react-stripe-js";
import {Link} from "react-router-dom";
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import StripeSubscriptionForm from "./StripeSubscriptionForm";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(window.stripePublicKey ? window.stripePublicKey : 'pk_live_nO3uxbbzQCnrcERWxIS5i43t');

const countryList = {
    'AF':'Afghanistan',
    'AX':'Åland Islands',
    'AL':'Albania',
    'DZ':'Algeria',
    'AS':'American Samoa',
    'AD':'Andorra',
    'AO':'Angola',
    'AI':'Anguilla',
    'AQ':'Antarctica',
    'AG':'Antigua and Barbuda',
    'AR':'Argentina',
    'AM':'Armenia',
    'AW':'Aruba',
    'AU':'Australia',
    'AT':'Austria',
    'AZ':'Azerbaijan',
    'BS':'Bahamas',
    'BH':'Bahrain',
    'BD':'Bangladesh',
    'BB':'Barbados',
    'BY':'Belarus',
    'BE':'Belgium',
    'PW':'Belau',
    'BZ':'Belize',
    'BJ':'Benin',
    'BM':'Bermuda',
    'BT':'Bhutan',
    'BO':'Bolivia',
    'BQ':'Bonaire, Saint Eustatius and Saba',
    'BA':'Bosnia and Herzegovina',
    'BW':'Botswana',
    'BV':'Bouvet Island',
    'BR':'Brazil',
    'IO':'British Indian Ocean Territory',
    'BN':'Brunei',
    'BG':'Bulgaria',
    'BF':'Burkina Faso',
    'BI':'Burundi',
    'KH':'Cambodia',
    'CM':'Cameroon',
    'CA':'Canada',
    'CV':'Cape Verde',
    'KY':'Cayman Islands',
    'CF':'Central African Republic',
    'TD':'Chad',
    'CL':'Chile',
    'CN':'China',
    'CX':'Christmas Island',
    'CC':'Cocos (Keeling) Islands',
    'CO':'Colombia',
    'KM':'Comoros',
    'CG':'Congo (Brazzaville)',
    'CD':'Congo (Kinshasa)',
    'CK':'Cook Islands',
    'CR':'Costa Rica',
    'HR':'Croatia',
    'CU':'Cuba',
    'CW':'Cura&ccedil;ao',
    'CY':'Cyprus',
    'CZ':'Czech Republic',
    'DK':'Denmark',
    'DJ':'Djibouti',
    'DM':'Dominica',
    'DO':'Dominican Republic',
    'EC':'Ecuador',
    'EG':'Egypt',
    'SV':'El Salvador',
    'GQ':'Equatorial Guinea',
    'ER':'Eritrea',
    'EE':'Estonia',
    'ET':'Ethiopia',
    'FK':'Falkland Islands',
    'FO':'Faroe Islands',
    'FJ':'Fiji',
    'FI':'Finland',
    'FR':'France',
    'GF':'French Guiana',
    'PF':'French Polynesia',
    'TF':'French Southern Territories',
    'GA':'Gabon',
    'GM':'Gambia',
    'GE':'Georgia',
    'DE':'Germany',
    'GH':'Ghana',
    'GI':'Gibraltar',
    'GR':'Greece',
    'GL':'Greenland',
    'GD':'Grenada',
    'GP':'Guadeloupe',
    'GU':'Guam',
    'GT':'Guatemala',
    'GG':'Guernsey',
    'GN':'Guinea',
    'GW':'Guinea-Bissau',
    'GY':'Guyana',
    'HT':'Haiti',
    'HM':'Heard Island and McDonald Islands',
    'HN':'Honduras',
    'HK':'Hong Kong',
    'HU':'Hungary',
    'IS':'Iceland',
    'IN':'India',
    'ID':'Indonesia',
    'IR':'Iran',
    'IQ':'Iraq',
    'IE':'Ireland',
    'IM':'Isle of Man',
    'IL':'Israel',
    'IT':'Italy',
    'CI':'Ivory Coast',
    'JM':'Jamaica',
    'JP':'Japan',
    'JE':'Jersey',
    'JO':'Jordan',
    'KZ':'Kazakhstan',
    'KE':'Kenya',
    'KI':'Kiribati',
    'KW':'Kuwait',
    'KG':'Kyrgyzstan',
    'LA':'Laos',
    'LV':'Latvia',
    'LB':'Lebanon',
    'LS':'Lesotho',
    'LR':'Liberia',
    'LY':'Libya',
    'LI':'Liechtenstein',
    'LT':'Lithuania',
    'LU':'Luxembourg',
    'MO':'Macao',
    'MK':'North Macedonia',
    'MG':'Madagascar',
    'MW':'Malawi',
    'MY':'Malaysia',
    'MV':'Maldives',
    'ML':'Mali',
    'MT':'Malta',
    'MH':'Marshall Islands',
    'MQ':'Martinique',
    'MR':'Mauritania',
    'MU':'Mauritius',
    'YT':'Mayotte',
    'MX':'Mexico',
    'FM':'Micronesia',
    'MD':'Moldova',
    'MC':'Monaco',
    'MN':'Mongolia',
    'ME':'Montenegro',
    'MS':'Montserrat',
    'MA':'Morocco',
    'MZ':'Mozambique',
    'MM':'Myanmar',
    'NA':'Namibia',
    'NR':'Nauru',
    'NP':'Nepal',
    'NL':'Netherlands',
    'NC':'New Caledonia',
    'NZ':'New Zealand',
    'NI':'Nicaragua',
    'NE':'Niger',
    'NG':'Nigeria',
    'NU':'Niue',
    'NF':'Norfolk Island',
    'MP':'Northern Mariana Islands',
    'KP':'North Korea',
    'NO':'Norway',
    'OM':'Oman',
    'PK':'Pakistan',
    'PS':'Palestinian Territory',
    'PA':'Panama',
    'PG':'Papua New Guinea',
    'PY':'Paraguay',
    'PE':'Peru',
    'PH':'Philippines',
    'PN':'Pitcairn',
    'PL':'Poland',
    'PT':'Portugal',
    'PR':'Puerto Rico',
    'QA':'Qatar',
    'RE':'Reunion',
    'RO':'Romania',
    'RU':'Russia',
    'RW':'Rwanda',
    'BL':'Saint Barth&eacute;lemy',
    'SH':'Saint Helena',
    'KN':'Saint Kitts and Nevis',
    'LC':'Saint Lucia',
    'MF':'Saint Martin (French part)',
    'SX':'Saint Martin (Dutch part)',
    'PM':'Saint Pierre and Miquelon',
    'VC':'Saint Vincent and the Grenadines',
    'SM':'San Marino',
    'ST':'S&atilde;o Tom&eacute; and Pr&iacute;ncipe',
    'SA':'Saudi Arabia',
    'SN':'Senegal',
    'RS':'Serbia',
    'SC':'Seychelles',
    'SL':'Sierra Leone',
    'SG':'Singapore',
    'SK':'Slovakia',
    'SI':'Slovenia',
    'SB':'Solomon Islands',
    'SO':'Somalia',
    'ZA':'South Africa',
    'GS':'South Georgia/Sandwich Islands',
    'KR':'South Korea',
    'SS':'South Sudan',
    'ES':'Spain',
    'LK':'Sri Lanka',
    'SD':'Sudan',
    'SR':'Suriname',
    'SJ':'Svalbard and Jan Mayen',
    'SZ':'Swaziland',
    'SE':'Sweden',
    'CH':'Switzerland',
    'SY':'Syria',
    'TW':'Taiwan',
    'TJ':'Tajikistan',
    'TZ':'Tanzania',
    'TH':'Thailand',
    'TL':'Timor-Leste',
    'TG':'Togo',
    'TK':'Tokelau',
    'TO':'Tonga',
    'TT':'Trinidad and Tobago',
    'TN':'Tunisia',
    'TR':'Turkey',
    'TM':'Turkmenistan',
    'TC':'Turks and Caicos Islands',
    'TV':'Tuvalu',
    'UG':'Uganda',
    'UA':'Ukraine',
    'AE':'United Arab Emirates',
    'GB':'United Kingdom (UK)',
    'US':'United States (US)',
    'UM':'United States (US) Minor Outlying Islands',
    'UY':'Uruguay',
    'UZ':'Uzbekistan',
    'VU':'Vanuatu',
    'VA':'Vatican',
    'VE':'Venezuela',
    'VN':'Vietnam',
    'VG':'Virgin Islands (British)',
    'VI':'Virgin Islands (US)',
    'WF':'Wallis and Futuna',
    'EH':'Western Sahara',
    'WS':'Samoa',
    'YE':'Yemen',
    'ZM':'Zambia',
    'ZW':'Zimbabwe',
};

const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

class CheckoutSubscription extends React.Component {
    constructor(props) {
        super(props);
        const cartItems = [props.item];
        const cartSubtotal = Number(props.item.price);
        this.state = {
            showPaymentBtn: false,
            paymentCompleted: false,
            paypalBtnReady: false,
            paypalProcessing: false,
            cartItems:cartItems,
            cartSubtotal:cartSubtotal,
            taxTotal:0,
            cartTotal:cartSubtotal,
            cartDiscount:0,
            userDiscountPercent:undefined,
            taxRate:undefined,
        };
        this.initialValues = null;
        this.paypalProcessing = React.createRef();
        this.paypalButtonToolbar = React.createRef();
    }

    updateTotals = (cartSubtotal, taxRate = 0, discount = 0) => {
        const cartDiscount = discount > 0 ? Number(cartSubtotal * discount) : 0;
        cartSubtotal = cartSubtotal - cartDiscount;
        const taxTotal = Number(cartSubtotal * taxRate / 100);
        const cartTotal = cartSubtotal + taxTotal;
        this.setState({
            ...this.state,
            cartSubtotal:cartSubtotal,
            taxTotal:taxTotal,
            cartTotal:cartTotal,
            cartDiscount:cartDiscount,
            userDiscountPercent:discount,
        });
    };

    loadUserDiscount = () => {
        const taxRate = this.state.taxRate;
        const cartSubtotal = Number(this.state.cartItems[0].price);
        getUserDiscount().then((res) => {
            const discount = 0;//res && res.data ? res.data.discount : 0;
            this.updateTotals(cartSubtotal, taxRate, discount);
        }).then((res) => {
            //
        });
    };

    updateTaxes = (taxRate) => {
        const cartSubtotal = Number(this.state.cartItems[0].price);
        const discount = this.state.userDiscountPercent;

        taxRate = 0;//don't use taxes for subscriptions

        this.updateTotals(cartSubtotal, taxRate, discount);
    };

    componentDidMount() {
        const projectId = this.props.currentProjectId;

        this.props.initBillingInfo();
        //this.props.requestUserDiscount();
        this.loadUserDiscount();

        if(projectId > 0) {
            this.props.requestProject(projectId);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.currentProjectId !== prevProps.currentProjectId) {
            this.props.requestProject(this.props.currentProjectId);
        }
    }

    render() {
        const {intl, billingInfo, currentProjectId, paypalPlanId, stripePriceId, user} = this.props;
        const {cartItems, cartSubtotal, taxTotal, cartTotal, cartDiscount, userDiscountPercent} = this.state;
        const project = this.props.currentProject;

        if(!currentProjectId || (project && !project.data)) {
            return (
                <p>Before making orders choose a project here: <Link to="/projects" onClick={()=>{this.props.hideCheckout();}}>Projects Page</Link></p>
            );
        }
        else if(!project) {
            return <Spinner/>;
        }

        const countries = [{value:"", label:"Country*"}];
        for (const [index] of Object.entries(countryList)) {
            countries.push({value:countryList[index], label:countryList[index]});
        }

        const getCountryCode = (country) => {
            let countryCode = "";
            for (const [index] of Object.entries(countryList)) {
                if(countryList[index] === country) {
                    countryCode = index;
                }
            }
            return countryCode;
        };

        if(this.state.paymentCompleted) {
            return (
                <>
                    <Alert variant="success">
                        Payment completed.
                    </Alert>
                    <p>Redirecting to the order page...</p>
                </>
            );
        }

        if(cartItems && cartItems.length === 0) {
            return (
                <h1>Cart is empty!</h1>
            );
        }

        if(cartItems === undefined || userDiscountPercent === undefined || billingInfo === undefined) {
            return (<Spinner/>);
        }

        if(!this.initialValues) {
            this.initialValues = {
                firstname: billingInfo && billingInfo.firstname ? billingInfo.firstname :"",
                lastname: billingInfo && billingInfo.lastname ? billingInfo.lastname :"",
                company_name: billingInfo && billingInfo.company ? billingInfo.company :"",
                street_address: billingInfo && billingInfo.address ? billingInfo.address :"",
                postal_code: billingInfo && billingInfo.postal_code ? billingInfo.postal_code :"",
                city: billingInfo && billingInfo.city ? billingInfo.city :"",
                country: billingInfo && billingInfo.country ? billingInfo.country :"",
                tax_id: billingInfo && billingInfo.taxid ? billingInfo.taxid :"",
                payment_method: "paypal",
                payment_order_id: ""
            }
        }

        return (
            <>
                        <Formik
                            initialValues={this.initialValues}
                            validateOnMount
                            validate={async values => {
                                const errors = {};

                                if (!values.firstname) {
                                    errors.firstname = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }

                                if (!values.lastname) {
                                    errors.lastname = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }

                                if (!values.street_address) {
                                    errors.street_address = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }

                                if (!values.city) {
                                    errors.city = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }

                                if (!values.postal_code) {
                                    errors.postal_code = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }

                                if (!values.country) {
                                    errors.country = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }

                                if(values.country) {
                                    const countryCode = getCountryCode(values.country);
                                    if (values.tax_id) {
                                        await validateVat({
                                            vat_number_field: values.tax_id.replace(" ", ""),
                                            country_code_field: countryCode
                                        })
                                            .then((res) => {
                                                if (res.data && (!res.data.exist || !res.data.formatted)) {
                                                    errors.tax_id = "Incorrect VAT number";
                                                }
                                                if (res.data && res.data.taxRate >= 0) {
                                                    this.updateTaxes(res.data.taxRate);
                                                }
                                            })
                                            .catch((e) => {
                                                errors.tax_id = "Incorrect VAT number";
                                                calculateVat({
                                                    vat_number_field: values.tax_id.replace(" ", ""),
                                                    country_code_field: countryCode
                                                })
                                                    .then((res) => {
                                                        if (res.data && res.data.taxRate >= 0) {
                                                            this.updateTaxes(res.data.taxRate);
                                                        }
                                                    });
                                            });
                                    } else {
                                        calculateVat({country_code_field: countryCode})
                                            .then((res) => {
                                                if (res.data && res.data.taxRate >= 0) {
                                                    this.updateTaxes(res.data.taxRate);
                                                }
                                            });
                                    }
                                }

                                return errors;
                            }}
                            onSubmit={(values, { setStatus, setSubmitting }) => {
                                const countryCode = getCountryCode(values.country);

                                let billingInfo = {
                                    firstname: values.firstname,
                                    lastname: values.lastname,
                                    company_name: values.company_name,
                                    address: values.street_address,
                                    postal_code: values.postal_code,
                                    city: values.city,
                                    country: values.country,
                                    tax_id: values.tax_id.replace(" ", ""),
                                    payment_method: values.payment_method,
                                    payment_order_id: values.payment_order_id ? values.payment_order_id : (values.payment_method === "stripe" ? localStorage.getItem("stripeSubscriptionId") : ""),
                                    country_code: countryCode
                                };
                                let orderData = {};
                                if(cartItems && cartItems.length !== 0) {
                                    for (let i = 0; i < cartItems.length; i++) {
                                        orderData[i] = cartItems[i].orderData;
                                    }

                                    addOrder(orderData, billingInfo, currentProjectId)
                                        .then((res) => {
                                            const {id, order_number} = res.data.data;

                                            // ReactGA.plugin.require('ec');
                                            // for (let i = 0; i < cartItems.length; i++) {
                                            //     let itemPrice = Number(cartItems[i].price) * Number(cartItems[i].orderData.quantity);
                                            //     ReactGA.plugin.execute('ec', 'addProduct', {
                                            //         id: cartItems[i].key,
                                            //         name: cartItems[i].name,
                                            //         category: cartItems[i].orderData.type,
                                            //         variant: cartItems[i].description,
                                            //         price: itemPrice,
                                            //         quantity: Number(cartItems[i].orderData.quantity)
                                            //     });
                                            // }
                                            // ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
                                            //     id: order_number,
                                            //     revenue: cartTotal.toFixed(2)
                                            // });
                                            // ReactGA.ga('send', 'event', 'Checkout', 'Purchase');
                                            // ReactPixel.track("Purchase", {value: cartTotal.toFixed(2), currency: 'USD'});

                                            //this.props.emptyCart();
                                            this.props.hideCheckout();
                                            this.props.initBillingInfo();
                                            this.props.requestNotifications();
                                            this.props.initMarketplace(1, {});
                                            this.props.history.push({
                                                pathname: "/orders/" + id + "/progress"
                                            });
                                        })
                                        .catch(e => {
                                            setSubmitting(false);
                                            if (e.response) {
                                                alert(e.response.data.message);
                                            } else {
                                                console.log(e);
                                                alert("Error! Try again later.");
                                            }
                                        });

                                    if(this.paypalProcessing.current) {
                                        this.paypalProcessing.current.style.display = "none";
                                        this.paypalButtonToolbar.current.style.display = "flex";
                                    }
                                    //show payment completed message
                                    this.setState({
                                        ...this.state,
                                        paymentCompleted: true,
                                        showPaymentBtn: false,
                                        paypalProcessing: false
                                    });
                                }
                            }}
                        >
                            {({
                                  values,
                                  status,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue,
                                  validateForm
                              }) => (
                                <div className="row">
                                    {!this.state.showPaymentBtn && <div className="col-xl-12">
                                        <Form noValidate onSubmit={handleSubmit}>
                                            <div className="row">
                                                <Form.Group className="col-md-6 kt-mb-10" controlId="formFirstName">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="First Name*"
                                                        name="firstname"
                                                        value={values.firstname}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.firstname}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.firstname}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="col-md-6 kt-mb-10" controlId="formLastName">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Last Name*"
                                                        name="lastname"
                                                        value={values.lastname}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.lastname}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.lastname}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="row">
                                                <Form.Group className="col-md-6 kt-mb-10" controlId="formCompanyName">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Company Name"
                                                        name="company_name"
                                                        value={values.company_name}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.company_name}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.company_name}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="col-md-6 kt-mb-10" controlId="formStreetAddress">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Street Address*"
                                                        name="street_address"
                                                        value={values.street_address}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.street_address}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.street_address}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="row">
                                                <Form.Group className="col-md-6 kt-mb-10" controlId="formPostalCode">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Postal Code*"
                                                        name="postal_code"
                                                        value={values.postal_code}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.postal_code}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.postal_code}
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className="col-md-6 kt-mb-10" controlId="formCity">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="City*"
                                                        name="city"
                                                        value={values.city}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.city}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.city}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="row">
                                                <Form.Group className="col-md-6 kt-mb-10" controlId="formCountry">
                                                    <Select
                                                        options={countries}
                                                        defaultValue={{value:values.country, label:values.country}}
                                                        onChange={selectedOption => {
                                                            handleChange("country")(selectedOption.value);
                                                        }}
                                                        noOptionsMessage={()=>{return !countries ? "Loading..." : "No options";}}
                                                        styles={selectStyles}
                                                    />
                                                    <div className="invalid-feedback" style={{display:!!errors.country ? "block" : "none"}}>{errors.country}</div>
                                                </Form.Group>

                                                <Form.Group className="col-md-6 kt-mb-10" controlId="formTaxId">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="VAT Number (Only for EU Customers)"
                                                        name="tax_id"
                                                        value={values.tax_id}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.tax_id}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.tax_id}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </Form>
                                    </div>}
                                    <div className="col-xl-12">
                                        {!this.state.showPaymentBtn && <Table bordered responsive>
                                            <tbody>
                                            <tr><td><strong>Subtotal</strong></td><td><strong>$ {cartSubtotal ? cartSubtotal.toFixed(2) : 0}</strong></td></tr>
                                            {cartDiscount > 0 ? <tr><td><strong>Discount</strong></td><td><strong>- $ {cartDiscount ? cartDiscount.toFixed(2) : 0}</strong></td></tr> : <tr/>}
                                            <tr><td><strong>Taxes</strong></td><td><strong>$ {taxTotal ? taxTotal.toFixed(2) : 0}</strong></td></tr>
                                            <tr><td><strong>Total</strong></td><td><strong className="kt-font-brand">$ {cartTotal ? cartTotal.toFixed(2) : 0}</strong></td></tr>
                                            </tbody>
                                        </Table>}
                                        {!this.state.showPaymentBtn && <FormControl component="fieldset" className="kt-mb-10">
                                            <RadioGroup
                                                name="payment_method"
                                                value={values.payment_method}
                                                onChange={handleChange}
                                            >
                                                <div className="row">
                                                    <div className="col-6"><FormControlLabel value="paypal" control={<Radio />} label="PayPal" /></div>
                                                    <div className="col-6"><FormControlLabel value="stripe" control={<Radio />} label="Stripe" /></div>
                                                </div>
                                            </RadioGroup>
                                        </FormControl>}
                                        {/*this.state.showPaymentBtn && values.payment_method === "stripe" && <Elements stripe={stripePromise}>
                                            <InjectedCheckoutForm cartTotal={cartTotal} formikSubmit={handleSubmit} setFieldValue={setFieldValue} />
                                        </Elements>*/}
                                        {this.state.showPaymentBtn && values.payment_method === "stripe" && <StripeSubscriptionForm
                                            stripe={stripePromise}
                                            productSelected={{
                                                name:stripePriceId,
                                                quantity: cartItems[0].orderData && cartItems[0].orderData.options && cartItems[0].orderData.options.package_quantity > 0 ? cartItems[0].orderData.options.package_quantity : 1,
                                            }}
                                            customer={{id:user.stripe_customer_id}}
                                            submitFormData={(subscriptionId) => {
                                                setFieldValue('payment_order_id', subscriptionId, false);
                                                handleSubmit();
                                            }}
                                            //formikSubmit={handleSubmit}
                                            //setFieldValue={setFieldValue}
                                        />}
                                        <div className="justify-content-center text-center" style={{display:"none"}} ref={this.paypalProcessing}>
                                            <p className="text-bold">Payment is processing, please wait...</p>
                                            <Spinner size="lg" />
                                        </div>
                                        <ButtonToolbar className="justify-content-center" ref={this.paypalButtonToolbar}>
                                            {!this.state.paypalProcessing && this.state.showPaymentBtn && values.payment_method === "paypal" && !this.state.paypalBtnReady && <Spinner />}
                                            {!this.state.paypalProcessing && this.state.showPaymentBtn && values.payment_method === "paypal" && <PayPalButton
                                                options={{
                                                    vault: true,
                                                    clientId: window.paypalClientId ? window.paypalClientId : "AZZBIPijonJxCr2RRK1S-rEMATsT3RkSXifg3JENAoQ4n9PJuQXG84vhz98B6wv6slUP29sKYgAxiASg",
                                                    disableFunding: "card,credit,bancontact,venmo,sepa,eps,giropay,ideal,mybank,p24,sofort",
                                                }}
                                                style={{
                                                    label:"subscribe"
                                                }}
                                                createSubscription={(data, actions) => {
                                                    // ReactGA.event({
                                                    //     category: 'Payment',
                                                    //     action: 'PaypalButtonClick'
                                                    // });

                                                    this.paypalButtonToolbar.current.style.display = "none";
                                                    this.paypalProcessing.current.style.display = "block";

                                                    return actions.subscription.create({
                                                        plan_id: paypalPlanId,
                                                        quantity: cartItems[0].orderData && cartItems[0].orderData.options && cartItems[0].orderData.options.package_quantity > 0 ? cartItems[0].orderData.options.package_quantity : 1,
                                                    });
                                                }}
                                                onApprove={(data, actions) => {
                                                    // ReactGA.event({
                                                    //     category: 'Payment',
                                                    //     action: 'PaypalApprovedAction'
                                                    // });

                                                    let orderData = {};
                                                    if(cartItems && cartItems.length !== 0) {
                                                        for (let i = 0; i < cartItems.length; i++) {
                                                            orderData[i] = cartItems[i].orderData;
                                                        }
                                                    }
                                                    try {
                                                        const countryCode = getCountryCode(values.country);
                                                        let formBillingInfo = {
                                                            firstname: values.firstname,
                                                            lastname: values.lastname,
                                                            company_name: values.company_name,
                                                            address: values.street_address,
                                                            postal_code: values.postal_code,
                                                            city: values.city,
                                                            country: values.country,
                                                            tax_id: values.tax_id.replace(" ", ""),
                                                            payment_method: values.payment_method,
                                                            payment_order_id: values.payment_order_id,
                                                            country_code: countryCode
                                                        };
                                                        fetch("/api/paypal/save_draft", {
                                                            method: "post",
                                                            body: JSON.stringify({
                                                                "order_data":orderData,
                                                                "payment_method":"paypal",
                                                                "payment_id":data.subscriptionID,
                                                                "project_id":currentProjectId,
                                                                "billing_info":formBillingInfo
                                                            })
                                                        });
                                                    }
                                                    catch (e) {
                                                        //
                                                    }

                                                    // Capture the funds from the transaction
                                                    return actions.subscription.get().then(function(details) {
                                                        //add payment data to the request
                                                        setFieldValue('payment_order_id', data.subscriptionID, false);
                                                        handleSubmit();
                                                    });
                                                }}
                                                onButtonReady={() => {this.setState({ ...this.state, paypalBtnReady: true });}}
                                                onCancel={(object)=>{
                                                    this.paypalProcessing.current.style.display = "none";
                                                    this.paypalButtonToolbar.current.style.display = "flex";
                                                }}
                                            />}
                                            {!this.state.showPaymentBtn && <Button variant="primary" className="btn-block" size="lg" onClick={() => validateForm().then((errors) => {
                                                if(Object.keys(errors).length === 0 && errors.constructor === Object) {
                                                    this.setState({...this.state, showPaymentBtn: true});
                                                }
                                            })} disabled={isSubmitting}>
                                                Subscribe Now
                                            </Button>}
                                        </ButtonToolbar>
                                    </div>
                                </div>
                            )}
                        </Formik>
            </>
        );
    }
}

/*const InjectedCheckoutForm = (props) => {
    const {cartTotal, formikSubmit, setFieldValue} = props;
    return (
        <ElementsConsumer>
            {({elements, stripe}) => (
                <StripeCheckoutForm elements={elements} stripe={stripe} cartTotal={cartTotal} formikSubmit={formikSubmit} setFieldValue={setFieldValue} />
            )}
        </ElementsConsumer>
    );
};

class StripeCheckoutForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false
        };
    }
    render() {
        const {stripe, elements, cartTotal, formikSubmit, setFieldValue} = this.props;

        const handleStripeJsResult = (result) => {
            if (result.error) {
                // Show error in payment form
                console.log(result.error);
                this.setState({isSubmitting: false});
            } else {
                // The card action has been handled
                // The PaymentIntent can be confirmed again on the server
                stripePay({ payment_intent_id: result.paymentIntent.id }).then(function(confirmResult) {
                    return confirmResult;
                }).then(handleServerResponse);
            }
        };

        const handleServerResponse = (response) => {
            if (response.data.error) {
                // Show error from server on payment form
                console.log(response.data.error);
                this.setState({isSubmitting: false});
            } else if (response.data.requires_action) {
                // Use Stripe.js to handle required card action
                stripe.handleCardAction(
                    response.data.payment_intent_client_secret
                ).then(handleStripeJsResult);
            } else {
                // Show success message
                console.log(response.data);
                //add payment data to the request
                setFieldValue('payment_order_id', response.data.id, false);
                formikSubmit();
            }
        };

        const handleSubmit = async (event) => {
            // Block native form submission.
            event.preventDefault();

            ReactGA.event({
                category: 'Payment',
                action: 'StripeButtonClick'
            });

            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return;
            }

            this.setState({isSubmitting: true});

            // Get a reference to a mounted CardElement. Elements knows how
            // to find your CardElement because there can only ever be one of
            // each type of element.
            const cardElement = elements.getElement(CardElement);

            const {error, paymentMethod} = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (error) {
                console.log('[error]', error);
                this.setState({isSubmitting: false});
            } else {
                console.log('[PaymentMethod]', paymentMethod);

                stripePay({ payment_method_id: paymentMethod.id, amount: cartTotal ? cartTotal.toFixed(2) * 100 : 0 })
                .then(handleServerResponse);
            }
        };

        return (
            <form onSubmit={handleSubmit}>
                <CardElement
                    options={{
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }}
                />
                <ButtonToolbar className="justify-content-center">
                    <Button type="submit" disabled={!stripe || this.state.isSubmitting}>Pay</Button>
                </ButtonToolbar>
            </form>
        );
    }
}*/

const mapStateToProps = ({ auth: { user }, profile: { billingInfo }, projects: {currentProjectId, currentProject} }) => ({
    billingInfo, currentProjectId, currentProject, user
});

export default injectIntl(connect(
    mapStateToProps,
    {...cart.actions, ...profile.actions, ...notifications.actions, ...projects.actions, ...marketplace.actions}
)(CheckoutSubscription));
