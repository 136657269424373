import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import { injectIntl } from "react-intl";
import { editUser } from "../../../crud/user.crud";
import * as auth from "../../../store/ducks/auth.duck";

class EditProfile extends Component {
    render() {
        const { intl, user } = this.props;

        return (
                        <Formik
                            initialValues={{
                                email: user.email,
                                firstname: user.firstname,
                                lastname: user.lastname,
                            }}
                            validate={values => {
                                const errors = {};

                                if (!values.email) {
                                    errors.email = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                } else if (
                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                ) {
                                    errors.email = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }

                                if (!values.firstname) {
                                    errors.firstname = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }

                                if (!values.lastname) {
                                    errors.lastname = intl.formatMessage({
                                        id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                    });
                                }

                                return errors;
                            }}
                            onSubmit={(values, { setStatus, setSubmitting }) => {
                                editUser(
                                    values.firstname,
                                    values.lastname
                                )
                                    .then(() => {
                                        setSubmitting(false);
                                        setStatus(
                                            "Saved."
                                        );
                                        this.props.requestUser();
                                    })
                                    .catch(() => {
                                        setSubmitting(false);
                                        setStatus(
                                            intl.formatMessage({
                                                id: "AUTH.VALIDATION.INVALID_LOGIN"
                                            })
                                        );
                                    });
                            }}
                        >
                            {({
                                  values,
                                  status,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting
                              }) => (
                                <form onSubmit={handleSubmit} >
                                    {status && !isSubmitting && (
                                        <div role="alert" className={'alert ' + (status === 'Saved.' ? 'alert-success' : 'alert-danger')}>
                                            <div className="alert-text">{status}</div>
                                        </div>
                                    )}

                                    <div className="form-group mb-0">
                                        <TextField
                                            type="email"
                                            label="Email"
                                            margin="normal"
                                            fullWidth={true}
                                            name="email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                            helperText={touched.email && errors.email}
                                            error={Boolean(touched.email && errors.email)}
                                            disabled={true}
                                        />
                                    </div>

                                    <div className="form-group mb-0">
                                        <TextField
                                            margin="normal"
                                            label="Firstname"
                                            className="kt-width-full"
                                            name="firstname"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.firstname}
                                            helperText={touched.firstname && errors.firstname}
                                            error={Boolean(touched.firstname && errors.firstname)}
                                        />
                                    </div>

                                    <div className="form-group mb-2">
                                        <TextField
                                            margin="normal"
                                            label="Lastname"
                                            className="kt-width-full"
                                            name="lastname"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.lastname}
                                            helperText={touched.lastname && errors.lastname}
                                            error={Boolean(touched.lastname && errors.lastname)}
                                        />
                                    </div>

                                    <div className="kt-login__actions">
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-elevate kt-login__btn-primary"
                                            disabled={isSubmitting}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            )}
                        </Formik>
        );
    }
}

const mapStateToProps = ({ auth: { user } }) => ({
    user
});

export default injectIntl(connect(mapStateToProps, auth.actions)(EditProfile));
