import React, { Component } from "react";
import {getAnchorsChartData} from "../../../crud/onpage.crud";
import {Spinner} from "reactstrap";
import {Portlet, PortletBody, PortletHeader} from "../../../partials/content/Portlet";
import DoughnutChart from "../../../widgets/DoughnutChart";

export default class AnchorsChart extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            data: undefined,
            loading: false,
        }
    }
    componentDidMount() {
        this._isMounted = true;

        const {projectId} = this.props;
        if(projectId > 0) {
            this.loadData(projectId);
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    loadData = (projectId) => {
        this.setState({...this.state, loading: true});
        getAnchorsChartData(projectId)
            .then((res) => {
                if (this._isMounted) {
                    this.setState({data: res ? res.data : [], loading: false});
                }
            })
            .catch(() => {
                this.setState({...this.state, loading: false});
            });
    };
    render() {
        const {openAnchorsDetails} = this.props;
        const anchorsData = this.state.data ? this.state.data.data : null;
        const linksNumber = this.state.data ? this.state.data.links_number : 0;
        let anchorLabels = [];
        let anchorCounts = [];

        if(anchorsData && linksNumber > 0) {
            if(anchorsData.length !== 0) {
                const maxItems = anchorsData.length <= 5 ? anchorsData.length : 5;
                let sum = 0;
                for(let i = 0; i < maxItems - 1; i++) {
                    const ratio = (anchorsData[i].count / linksNumber).toFixed(4);
                    if(anchorsData[i].anchor === "") {
                        anchorLabels.push("empty anchor");
                    }
                    /*else if(!anchorsData[i].anchor) {
                        anchorLabels.push("without anchor");
                    }*/
                    else {
                        anchorLabels.push(anchorsData[i].anchor);
                    }
                    anchorCounts.push((ratio * 100).toFixed(2));
                    sum += anchorsData[i].count;
                }
                const otherRatio = ((linksNumber - sum) / linksNumber).toFixed(4);
                anchorLabels.push("Other");
                anchorCounts.push((otherRatio * 100).toFixed(2));
            }
        }

        return (
            <>
                <Portlet className="kt-portlet--height-fluid kt-portlet--border-bottom-brand" style={{cursor:"pointer"}} onClick={openAnchorsDetails}>
                    <PortletHeader
                        title="Internal Links - Anchor Text Ratio"
                    />
                    {this.state.loading ? <PortletBody><Spinner /></PortletBody> :
                        <DoughnutChart
                            value="100%"
                            desc={false}
                            data={anchorCounts}
                            labels={anchorLabels}
                            colorNames={[5, 3, 1, 0, 2]}
                            hideLink={true}
                            showPercentage={true}
                        />}
                </Portlet>
            </>
        );
    }
}
