import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Form, Button} from "react-bootstrap";
import {Spinner} from "reactstrap";
import * as projects from "../../../store/ducks/projects.duck";
import { addNewBlog } from "../../../crud/marketplace.crud";
import Select from "react-select";
import * as marketplace from "../../../store/ducks/marketplace.duck";
import {displayError} from "../../../widgets/AlertError";

class AddBlogModal extends React.Component {
    constructor(props) {
        super(props);

        let sending = false;
        let blogPrice = 0.00;
        let blogUrl = "";
        let blogCategoryId = 0;
        let blogDr = null;
        let blogOrganicTraffic = null;

        let metaVerificationString = undefined;
        var calc_length           = 10;
        var calc_result           = '';
        var calc_characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var calc_charactersLength = calc_characters.length;
        for ( var i = 0; i < calc_length; i++ ) {
            calc_result += calc_characters.charAt(Math.floor(Math.random() * calc_charactersLength));
        }
        metaVerificationString = calc_result;

        this.state = {
            sending: sending,
            blogPrice: blogPrice,
            blogUrl: blogUrl,
            blogCategoryId: blogCategoryId,
            metaVerificationString: metaVerificationString,
            blogDr: blogDr,
            blogOrganicTraffic: blogOrganicTraffic
        };
    }

    componentDidMount() {
    	const { projects } = this.props;
    	this.props.requestProjectCategories();
  	}

    render() {
        const { open, onClose, projectCategories, guestPosts, metaVerificationString } = this.props;

        const selectStyles = {
	        menu: styles => ({ ...styles, zIndex: 999 }),
	        control: (provided, state) => ({
	            ...provided,
	            minHeight: '47px',
	        }),
	    };

        // let random_verification_with_html = `<meta name="serppro-verification" content="`+this.state.metaVerificationString+`">`;

	    const categoryOptions = [];

	    if (typeof projectCategories !== 'undefined') {
      		if (projectCategories.data && projectCategories.data.length !== 0) {
		        for (let index = 0; index < projectCategories.data.length; index++) {
		            let item = {value: projectCategories.data[index].id, label: projectCategories.data[index].name};
		            categoryOptions.push(item);
		        }
      		}
    	}

        const handleClose = () => {
        	setBlogPrice(0);
        	setBlogUrl("");
        	setBlogCategoryIdRaw(0);
            setBlogDr(null);
            setBlogOrganicTraffic(null);

            onClose();
        };

        const setBlogPrice = (price) => {
        	this.setState({
                ...this.state,
        		blogPrice: price
        	});
        };

        const setBlogUrl = (url) => {
        	this.setState({
                ...this.state,
        		blogUrl: url
        	});
        };

        const setBlogCategoryIdRaw = (categoryId) => {
        	this.setState({
                ...this.state,
        		blogCategoryId: categoryId
        	});
        };

        const setBlogCategoryId = (categoryId) => {
        	this.setState({
                ...this.state,
        		blogCategoryId: categoryId.value
        	});
        };

        const setBlogDr = (dr) => {
            this.setState({
                ...this.state,
                blogDr: dr
            });
        };

        const setBlogOrganicTraffic = (organicTraffic) => {
            this.setState({
                ...this.state,
                blogOrganicTraffic: organicTraffic
            });
        };

        const handleAddBlog = () => {
        	this.setState({
                ...this.state,
        		sending: true
        	});

        	addNewBlog(
                this.state.blogPrice,
                this.state.blogUrl,
                this.state.blogCategoryId,
                this.state.metaVerificationString,
                this.state.blogDr,
                this.state.blogOrganicTraffic
            )
            .then((res) => {
            	// console.log("then = " + res);
                this.setState({
	                ...this.state,
	        		sending: false
	        	});
	        	setBlogPrice(0);
        		setBlogUrl("");
        		setBlogCategoryIdRaw(0);
                setBlogDr(null);
                setBlogOrganicTraffic(null);
	        	this.props.requestMyBlogs();
	        	onClose();
            })
            .catch(e => {
            	this.setState({
	                ...this.state,
	        		sending: false
	        	});

                if(e.response && e.response.data && e.response.data.errors) {
                    let firstErrorKey = Object.keys(e.response.data.errors)[0];
                    let errorMessage = Array.isArray(e.response.data.errors[firstErrorKey]) ? e.response.data.errors[firstErrorKey][0] : 'Unexpected validation error';

                    displayError(errorMessage);
                }
            });
        }

        // {<meta name="serppro-verification" content="d62589086a8469d043053d1574f3d7f3">}
        return (<Dialog open={open} onClose={handleClose} fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle style={{textAlign:'center'}}>List Your Blog In The Marketplace</DialogTitle>
            <DialogContent dividers style={{borderTop:'0',paddingTop:'4px'}}>

                <div className="form-group mb-3">
                    <div style={{fontWeight:'500'}}>Step #1: Subscribe to one of <a href="https://www.serppro.io/become-a-publisher#how" target="_blank">our monetization plans here</a></div>
                </div>
                <div className="form-group mb-3" style={{paddingBottom:'6px'}}>
                    <div style={{fontWeight:'500'}}>Step #2: Add your blog's information</div>
                </div>

                <div className="form-group mb-3">
                  	<label className="MuiFormLabel-root">Blog Category</label>
                  	<Select
	                    className="mb-0"
	                    options={categoryOptions}
	                    onChange={setBlogCategoryId}
	                    styles={selectStyles}
                 	 />
                </div>
                {/* <div className="form-group mb-3">
                    <label className="MuiFormLabel-root">Copy & Paste this code between the {`<HEAD></HEAD>`} tags of your blog</label>
                    <input
                        type="text"
                        className="form-control"
                        defaultValue={random_verification_with_html}
                        disabled
                        />
                </div> */}
                <div className="input-group mb-3">
				  	<div className="input-group-prepend">
				    	<span className="input-group-text" id="basic-addon1" style={{width:"53px"}}>
				    		<i className="fa fa-link"></i>
				    	</span>
				  	</div>
		            <input
		                autoFocus
		                onChange={(e) => setBlogUrl(e.target.value)}
		                placeholder="What is your blogs URL?"
		                type="url"
		                className="form-control"
		                defaultValue={this.state.blogUrl}
		                required
		               	/>
	            </div>
                <div className="input-group">
				  	<div className="input-group-prepend">
				    	<span className="input-group-text" id="basic-addon1" style={{width:"53px"}}>
				    		<i className="fa fa-dollar-sign" style={{margin:"0 auto"}}></i>
				    	</span>
				  	</div>
		            <input
		                onChange={(e) => setBlogPrice(e.target.value)}
		                placeholder="What is the price for a sponsored blog post?"
		                type="number"
		                step="0.01"
		                className="form-control"
		                defaultValue={this.state.blogPrice}
		                required
		               	/>
	            </div>
                <div>
                    <small>SERPpro earns 20% commission per sale for processing orders.</small>
                </div>
                <div className="input-group mb-3 mt-3">
                    <input
                        autoFocus
                        onChange={(e) => setBlogDr(e.target.value)}
                        placeholder="What is your blogs domain rating?"
                        type="number"
                        step="1"
                        className="form-control"
                        defaultValue={this.state.blogDr}
                        required
                        />
                </div>
                <div className="input-group">
                    <input
                        onChange={(e) => setBlogOrganicTraffic(e.target.value)}
                        placeholder="How much organic traffic does your blog receive each month?"
                        type="number"
                        step="1"
                        className="form-control"
                        defaultValue={this.state.blogOrganicTraffic}
                        required
                        />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
                 <Button
                    variant="success"
                    onClick={handleAddBlog}
                    disabled={this.state.blogUrl == "" || this.state.blogPrice == 0 || this.state.blogCategoryId == 0 || !Number.isInteger(parseInt(this.state.blogDr)) || !Number.isInteger(parseInt(this.state.blogOrganicTraffic)) || this.state.sending}
                >
                    {this.state.sending ? "Sending.." : "Add Blog"}
                </Button>
            </DialogActions>
        </Dialog>);
    }
}

AddBlogModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ projects: { projectCategories }, marketplace: { guestPosts } }) => ({
    projectCategories, guestPosts
});

export default injectIntl(connect(
    mapStateToProps,
    {...marketplace.actions, ...projects.actions}
)(AddBlogModal));
