import React, { Component } from "react";
import {ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Portlet, PortletBody, PortletHeader} from "../partials/content/Portlet";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    'faq-title': {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: '600',
    },
});

class GuestPostsInfo extends Component {
    render() {
        const { classes } = this.props;

        return (
            <>
                <div className="row" style={{marginLeft:0,marginRight:0,paddingLeft:0,paddingRight:0}}>
                    <div className="col-lg-6 kt-mb-15" style={{marginLeft:0,marginRight:0,paddingLeft:0,paddingRight:0}}>
                        <Portlet>
                            <PortletHeader title="Frequently Asked Questions" />
                            <PortletBody className="p-0">
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes["faq-title"]}>What are high-quality guest posts?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>Guest posts are editorial blog articles with backlinks that are published on someone else’s website or blog. To get a guest post, we have to collaborate with a blogger or webmaster.</p>
                                            <p>Bloggers are always looking to add informational content to their blog. They understand having a content-rich site gives their blog an organic traffic boost.</p>
                                            <p>It’s our job to write informational, valuable content, and get it published on a blogger’s Website.</p>
                                            <p>Writing valuable content is one of the fundamentals of content marketing. We don’t post articles on any site. We carefully have to select the bloggers we work closely with.</p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography className={classes["faq-title"]}>How do I get started with this service?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                            <p>It’s easy.&nbsp;<strong><a href="https://dashboard.serppro.io/?utm_source=serpchampiongppage&amp;utm_medium=spsite&amp;utm_campaign=serpchampiongppage" rel="nofollow noopener" target="_blank">You can sign up here first</a></strong>, and then you have the options to choose between two marketplaces.</p>
                                            <p>1. Done-for-you guest post packages (You choose the specs, we choose the sites)</p>
                                            <p>2. Guest Post Marketplace (You choose the websites)</p>
                                            <p>After placing your order, you will need to submit your website and anchor text details so we can get started with your order.</p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3a-content"
                                        id="panel3a-header"
                                    >
                                        <Typography className={classes["faq-title"]}>Are all of the guest post links do-follow, and will they remain permanently?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>Yes, all of the guest post links are do-follow. If the owner of the site changes it in no-follow, we will contact them to change it.</p>
                                            <p>We can’t guarantee that your link will stay do-follow permanently, since the blogger can decide to change it in the future.</p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4a-content"
                                        id="panel4a-header"
                                    >
                                        <Typography className={classes["faq-title"]}>Do you have any guest post samples?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>Yes! Check out these 2 live samples of our guest posts. You can view the quality of our content and the sites for due diligence.</p>
                                            <p>
                                                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                                <a href="https://www.relevance.com/all-about-google-penalties/" target="_blank" rel="noopener nofollow">Sample 1 – DR72 / DA53 Blog</a><br/>
                                                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                                <a href="https://smartdatacollective.com/google-uses-machine-learning-to-combat-low-quality-link-spam/" target="_blank" rel="noopener nofollow">Sample 2 – DR72 / DA60 Blog</a>
                                            </p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel5a-content"
                                        id="panel5a-header"
                                    >
                                        <Typography className={classes["faq-title"]}>What is the turnaround time?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>We aim to get your guest post link(s) placed within 30 business days of purchase. If you’re looking for a shorter turnaround time, you can provide your own articles to be placed and we can have them published in less than 10 days.</p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel6a-content"
                                        id="panel6a-header"
                                    >
                                        <Typography className={classes["faq-title"]}>How long does it take to see results?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>Guest Posts and links results depend on your on-page optimization, competition and link building patterns. Typically, we see rankings in as little as 30 days.</p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel7a-content"
                                        id="panel7a-header"
                                    >
                                        <Typography className={classes["faq-title"]}>I want to make sure I'm doing this right. Do you offer any strategic advice?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>Always! Contact us at&nbsp;<a href="mailto:support@serpchampion.com">support@serpchampion.com</a>&nbsp;with your questions regarding our guest posting services, and we’ll reply with our recommendations and strategic advice.</p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel8a-content"
                                        id="panel8a-header"
                                    >
                                        <Typography className={classes["faq-title"]}>I don't know the right anchor texts to build high-quality links. Do you offer advice on that?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>Yes, we do. Contact us at&nbsp;<a href="mailto:support@serpchampion.com">support@serpchampion.com</a>, and we’ll take a look at your site and prepare a strategy.</p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel9a-content"
                                        id="panel9a-header"
                                    >
                                        <Typography className={classes["faq-title"]}>What's the difference of outsourcing and doing it myself?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>You have to be extremely careful whenever you buy links. It’s no surprise that many websites get hit by a link penalty.</p>
                                            <p><strong>Here’s why.</strong></p>
                                            <p>Guest posting has been popular for years. It’s so popular that many bloggers “fake” their way into having an authority website to get a guest post from.&nbsp;</p>
                                            <p>The fakers often buy expired domains with SEO value and launch a website on them like they are a real company. Then they accept all types of articles to their site and link out to low-quality websites.</p>
                                            <p>Many SEOs fall into the trap and end up with a guest post on a low-quality website. Getting a link from 1 low-quality site will not necessarily penalize you.&nbsp;</p>
                                            <p>But it will be a waste of your money because your link will get ignored by Google. However, when you receive a significant amount of low-quality links, you will eventually get penalized.</p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel10a-content"
                                        id="panel10a-header"
                                    >
                                        <Typography className={classes["faq-title"]}>How do I get high-quality links and what factors should I consider?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>Time is the most important asset that we have in life and especially in business. Guest posting is not complicated, but it can get tedious and very time-consuming.</p>
                                            <p>You’re constantly looking for quality sites, negotiating with bloggers, and writing content (that they might not even accept).</p>
                                            <p>A tremendous amount of work awaits you when you get started with guest posts.</p>
                                            <p>As a business owner, you don’t want to fall into the trap of becoming a “guest poster.”</p>
                                            <p>You have two options:</p><p>Option 1: Hire employees and train them to do the work.</p>
                                            <p>Option 2: Hire a service that specializes in guest posts.</p>
                                            <p>Training people will take a significant amount of time. If you’re an SEO agency, it might make sense to do it since you offer SEO as a service.</p>
                                            <p>However, most business owners don’t want their employees to mess around with links.</p>
                                            <p>That’s why it’s a better idea to hire a company that does guest posts for a living. Also, by hiring a guest post agency, you get access to their network and relationships that will allow you to acquire way better links that what you could potentially do.</p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel11a-content"
                                        id="panel11a-header"
                                    >
                                        <Typography className={classes["faq-title"]}>Why should I include blog outreach in my marketing budget?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>There are many ways to market your business to the world. You can create ads, write content, or become active on social media to push your message. Many people forget that SEO is far more powerful than any of the above.</p>
                                            <p>Advertising = Push Marketing</p>
                                            <p>SEO = Pull Marketing</p>
                                            <p>Getting traffic from SEO is so powerful because people are already searching for your service. While advertising tries to interrupt the prospect’s attention and push the message.</p>
                                            <p>Once you’re getting website visitors from SEO, you will reap the benefits long-term.</p>
                                            <p>With advertising, the traffic stops as soon as you stop putting money in your ads.</p>
                                            <p>That’s why you should always include SEO in your marketing budget as an&nbsp;<b>additional traffic source.</b></p>
                                            <p>Be sure to review any SEO company before you pay them to do SEO work. Ask them for case studies, examples, and ask questions about their process.</p>
                                            <p>Getting to know the SEO company will give you a good feel about the SEO company you might consider.</p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </PortletBody>
                        </Portlet>
                    </div>
                    {/* <div className="col-lg-6 kt-mb-15 text-center">
                        <img className="w-100" style={{maxWidth:"648px"}} src="/media/trust-pilot/testimonial_guest_posts.png" alt="reviews" />
                    </div> */}
                </div>
            </>
        );
    }
}

export default withStyles(styles)(GuestPostsInfo);
