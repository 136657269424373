import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Dropdown, Badge } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { ReactComponent as CartNum3Icon } from "../../../_metronic/layout/assets/layout-svg-icons/CartNum3.svg";
import * as cart from "../../store/ducks/cart.duck";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import CheckoutModal from "../../pages/home/CheckoutModal";
import {calculateVat} from "../../crud/orders.crud";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
};

class MyCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCart: false
    };
  }

  componentDidMount() {
      /*calculateVat({})
          .then((res) => {
              if(res.data && res.data.taxRate >= 0) {
                  this.props.initCart(res.data.taxRate);
              }
          });*/
      this.props.initCart();
  }

  render() {
    const { bgImage, useSVG, icon, iconType, skin } = this.props;
    const { cartItems, cartSubtotal, cartDiscount, taxTotal, cartTotal, showCart, cartQuantity, showCheckout } = this.props;

    const onToggle = (open) => {
      if(open) {
          this.setState({
              ...this.state,
              showCart: true
          });
      }
      else {
          this.setState({
              ...this.state,
              showCart: false
          });
          this.props.hideCart();
      }
    };

    const cartItemPlus = (key) => {
        if(key) {
            const item = cartItems.find(item => {
                if (item.key === key) {
                    return item;
                }
            });
            if(item) {
                this.props.updateCartItem(key, item.orderData.quantity + 1);
            }
        }
    };

    const cartItemMinus = (key) => {
        if(key) {
            const item = cartItems.find(item => {
                if (item.key === key) {
                    return item;
                }
            });
            if(item) {
                // if (item.orderData.type == "guest_posts" || item.orderData.type == "guest_post" || item.orderData.type == "link_insertion" || item.orderData.type == "link_insertions") {
                //     if (item.orderData.quantity > 3) {
                //       this.props.updateCartItem(key, item.orderData.quantity - 1);
                //     } else {
                //       this.props.removeCartItem(key);
                //     }
                // } else 
                if (item.orderData.quantity > 1) {
                    this.props.updateCartItem(key, item.orderData.quantity - 1);
                } else {
                    this.props.removeCartItem(key);
                }
            }
        }
    };

    const moneyFormatter = (num) => {
      if (typeof num !== 'undefined') {
          try {
              var calc = num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('.00','');
              // var calc = num.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              return '$'+calc;
          } catch (e) {
              console.log('moneyFormatter',e);
              return '$0.00';
          }
      }
      return '$'+num;
  }

    let calcDisplayPrice = 0;
    const cartRows = [];
    if(cartItems && cartItems.length !== 0) {
        // console.log('cartItems',cartItems);
        for(let i = 0; i < cartItems.length; i++) {
            let addOnRows = [];
            calcDisplayPrice = 0;
            if (cartItems[i].price != null) {
              calcDisplayPrice = parseFloat(cartItems[i].price);
            }
            if (cartItems[i].orderData.add_ons != null && cartItems[i].orderData.add_ons.length > 0) {
              for(let ao = 0; ao < cartItems[i].orderData.add_ons.length; ao++) {
                calcDisplayPrice += parseFloat(cartItems[i].orderData.add_ons[ao].add_on_price);
                addOnRows.push(
                  <li>
                    {cartItems[i].orderData.add_ons[ao].add_on_name} (${cartItems[i].orderData.add_ons[ao].add_on_price.toFixed(2)})
                  </li>
                );
              }
            }
            cartRows.push(
                <div key={"ci" + i} className="kt-mycart__item">
                    <div className="kt-mycart__container">
                        <div className="kt-mycart__info">
                            <Link to="#" className="kt-mycart__title">
                                {cartItems[i].name}
                            </Link>
                            {cartItems[i].key == 'wlo_mpgs' ? 
                                <>
                                    <span className="kt-mycart__desc" style={{paddingBottom:'0px'}}>
                                        {cartItems[i].description}
                                    </span>
                                    <span className="kt-mycart__desc kt-font-danger" style={{paddingTop:'2px'}}>{moneyFormatter(cartItems[i].discount_amount)} off</span>
                                </>
                            :
                            <span className="kt-mycart__desc">
                                {cartItems[i].description}
                            </span>}
                            {cartItems[i].orderData.add_ons != null && cartItems[i].orderData.add_ons.length > 0 ?
                              <span className="kt-mycart__desc pt-0 pl-4 pb-0">
                                Add Ons
                              </span>
                            : ""}
                            {cartItems[i].orderData.add_ons != null && cartItems[i].orderData.add_ons.length > 0 ?
                              <ul className="ml-2" style={{color:"#393b46"}}>{addOnRows}</ul>  
                            : ""}                           
                            <div className="kt-mycart__action">
                                <span className="kt-mycart__price">{cartItems[i].orderData.quantity + " × "} {moneyFormatter(parseFloat(calcDisplayPrice))}</span>
                                <span className="kt-mycart__text"></span>
                                <span className="btn btn-label-success btn-icon" onClick={() => cartItemMinus(cartItems[i].key)}>−</span>
                                {cartItems[i].key == 'wlo_mpgs' ? '' :
                                <span className="btn btn-label-success btn-icon" onClick={() => cartItemPlus(cartItems[i].key)}>+</span>}
                            </div>
                        </div>

                        {/*<Link to="cart-item" className="kt-mycart__pic">
                            <img
                                src={toAbsoluteUrl("/media/products/product16.jpg")}
                                alt="product"
                            />
                        </Link>*/}
                    </div>
                </div>
            );
        }
    }
    else {
        //
    }

    const checkoutClose = () => {
        this.props.hideCheckout();
    };

    return (
      <>
        <Dropdown className="kt-header__topbar-item" drop="down" alignRight show={this.state.showCart || showCart} onToggle={onToggle}>
          <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-my-cart">

          {skin == null || skin != "mobile" ?
            <>            
              <span
                className={clsx("kt-header__topbar-icon", {
                  "kt-header__topbar-icon--brand": iconType === "brand"
                })}
                style={{width:'70px',marginLeft: "10px",paddingTop:"1px"}}
              >
                {!useSVG && <i className={icon} style={{paddingRight:"4px"}} />}

                {useSVG && (
                  <span
                    className={clsx("kt-svg-icon", {
                      "kt-svg-icon-brand": iconType === "brand"
                    })}
                  >
                    <CartNum3Icon className="kt-svg-icon kt-svg-icon--primary" />
                  </span>
                )}

                {cartQuantity > 0 && (<span className="kt-badge kt-badge--notify kt-badge--success" style={{left:"20px"}}>{cartQuantity}</span>)}
                  <span style={{ color: "#000", fontSize: "17px"}}>Cart</span>
              </span>
            </>
          :
            <>            
              <span
                className={clsx("kt-header__topbar-icon", {
                  "kt-header__topbar-icon--brand": iconType === "brand"
                })}
                style={{width:'70px',marginRight: "10px",top:"2px", position:"relative"}}
              >
                {!useSVG && <i className={icon} style={{paddingRight:"4px", color:"white", fontSize:"20px"}} />}

                {useSVG && (
                  <span
                    className={clsx("kt-svg-icon", {
                      "kt-svg-icon-brand": iconType === "brand"
                    })}
                  >
                    <CartNum3Icon className="kt-svg-icon kt-svg-icon--primary" />
                  </span>
                )}

                {cartQuantity > 0 && (<span className="kt-badge kt-badge--notify kt-badge--success" style={{left:"-12px", top:"-9px", position:"absolute"}}>{cartQuantity}</span>)}
              </span>
            </>}
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl" style={{top:"65px"}}>
            <form className="flex-column p-0">
              <div className="kt-mycart">
                <div
                  className="kt-mycart__head kt-head"
                  style={{ backgroundImage: `url(${bgImage})` }}
                >
                  <div className="kt-mycart__info">
                    <span className="kt-mycart__icon">
                      <i className="flaticon2-shopping-cart-1 kt-font-success" />
                    </span>
                    <h3 className="kt-mycart__title">My Cart</h3>
                  </div>
                  <div className="kt-mycart__button">
                    <button type="button" className="btn btn-success btn-sm">
                      {cartQuantity} Items
                    </button>
                  </div>
                </div>

                <PerfectScrollbar
                  options={perfectScrollbarOptions}
                  style={{ maxHeight: "35vh", position: "relative" }}
                >
                  <div className="kt-mycart__body">
                    {cartRows}
                  </div>
                </PerfectScrollbar>

                <div className="kt-mycart__footer">
                  <div className="kt-mycart__section">
                    <div className="kt-mycart__subtitel">
                      <span>Sub Total</span>
                      {cartDiscount > 0 ? <span>Discount</span> : ""}
                      <span>Taxes</span>
                      <span>Total</span>
                    </div>

                    <div className="kt-mycart__prices">
                      <span>{cartSubtotal && moneyFormatter(cartSubtotal)}</span>
                      {cartDiscount > 0 ? <span>-  {moneyFormatter(cartDiscount)}</span> : ""}
                      <span>{taxTotal && moneyFormatter(taxTotal)}</span>
                      <span className="kt-font-brand">{cartTotal && moneyFormatter(cartTotal)}</span>
                    </div>
                  </div>
                  <div className="kt-mycart__button kt-align-right">

                    {cartItems && cartItems.length === 0 ?
                      <Link className="btn btn-primary btn-lg disabled" to="/checkout" onClick={ (event) => event.preventDefault() } disabled={cartItems && cartItems.length === 0}>Continue to Checkout</Link>
                    :
                      <Link className="btn btn-primary btn-lg" to="/checkout" onClick={() => {this.props.openCheckout(); this.setState({ showCart: false }); this.props.hideCart();}} disabled={cartItems && cartItems.length === 0}>Continue to Checkout</Link>
                    }

                    {/* <button type="button" className="btn btn-primary btn-lg" onClick={() => {this.props.openCheckout(); this.setState({ showCart: false }); this.props.hideCart();}} disabled={cartItems && cartItems.length === 0}>
                      Continue to Checkout
                    </button> */}
                    {/* <CheckoutModal
                      show={showCheckout}
                      onHide={checkoutClose}
                    /> */}
                    
                  </div>
                </div>
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
        {(skin == null || skin != "mobile") && cartQuantity > 0 ?
          <Link className="btn btn-primary topbar-my-orders" style={{ fontSize: "17px", paddingTop: "18px", marginLeft: "8px", marginRight:"6px", paddingRight:"15px", paddingLeft:"15px", border: "0px", borderRadius:"0px"}} to="/checkout">Checkout</Link>
        : ''}
      </>
    );
  }
}

const mapStateToProps = ({ cart: { cartItems, cartSubtotal, cartDiscount, taxTotal, cartTotal, showCart, cartQuantity, showCheckout } }) => ({
    cartItems, cartSubtotal, cartDiscount, taxTotal, cartTotal, showCart, cartQuantity, showCheckout
});

export default injectIntl(connect(
    mapStateToProps,
    cart.actions
)(MyCart));
