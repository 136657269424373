import axios from "axios";

export const base = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL : "";
export const EDIT_USER_URL = base + "/api/user/edit";
export const NEW_PASSWORD_URL = base + "/api/user/newpassword";
export const BILLING_INFO_URL = base + "/api/billing/info";
export const EDIT_BILLING_INFO_URL = base + "/api/billing/edit";
export const NOTIFICATIONS_URL = base + "/api/notifications";
export const DELETE_ACCOUNT_URL = base + "/api/user/delete";
export const USER_OPTIONS_URL = base + "/api/user-options";
export const BILLING_HISTORY_URL = base + "/api/billing/history";
export const USER_WATCHLIST = base + "/api/user-watchlist";

export function editUser(firstname, lastname) {
    return axios.post(EDIT_USER_URL, { firstname, lastname });
}

export function changePassword(password, password_confirmation) {
    return axios.post(NEW_PASSWORD_URL, { password, password_confirmation });
}

export function getBillingInfo() {
    return axios.get(BILLING_INFO_URL);
}

export function getBalanceHistory(page) {
    let params = "";
    if(page) {
        params += "?page=" + page;
    }
    return axios.get(BILLING_HISTORY_URL + params);
}

export function editBillingInfo(data) {
    return axios.post(EDIT_BILLING_INFO_URL, data);
}

export function getNotifications() {
    return axios.get(NOTIFICATIONS_URL);
}

export function readNotification(id) {
    return axios.patch(NOTIFICATIONS_URL + "/" + id);
}

export function deleteAccount() {
    return axios.delete(DELETE_ACCOUNT_URL);
}

export function getUserOptions() {
    return axios.post(USER_OPTIONS_URL + "/get");
}

export function saveUserOptions(data) {
    return axios.post(USER_OPTIONS_URL + "/save", data);
}

export function saveWatchlistItem(data) {
    return axios.post(USER_WATCHLIST + "/save", data);
}
export function removeWatchlistItem(data) {
    return axios.post(USER_WATCHLIST + "/remove", data);
}
