import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as orders from "../../store/ducks/admin/orders.duck";
import { createDynamicCost, deleteDynamicCost } from "../../crud/admin/financials.crud";
import {Spinner} from "reactstrap";
import {Table, Badge, Button, Form, Col} from "react-bootstrap";
import {Portlet, PortletBody, PortletHeader} from "../../partials/content/Portlet";
import {Link} from "react-router-dom";
import {Icon} from "@material-ui/core";
import {downloadPdfInvoice} from "../../crud/admin/orders.crud";
import UPagination from "../../partials/content/Pagination";

class OrdersFilteredAdmin extends Component {
    componentDidMount() {
        const self = this;
        // const getPage = new URLSearchParams(window.location.search).get('page');
        const getPage = new URLSearchParams(this.props.location.search).get("page");
        const getMonth = new URLSearchParams(this.props.location.search).get("month");
        const getYear = new URLSearchParams(this.props.location.search).get("year");

        this.getMonth = getMonth;
        this.getYear = getYear;

        const monthNames = ["January", "February", "March", "April", "May","June","July", "August", "September", "October", "November","December"];

        this.getHumanReadableDate = monthNames[getMonth-1] + " " + getYear;

        if (getPage != null && getMonth != null && getYear != null) {
            this.props.initOrdersWithArgs(getPage, getMonth, getYear);   

            var dynamicCosts = {};
            console.log(this.props)
            this.props.orders.data.forEach((order, i) => {
                dynamicCosts[order.id] = order.dynamicCosts;
            })
        }
        else if (getPage != null) {
            this.props.initOrders(getPage);
        } else {
            this.props.initOrders();
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            dynamicCostFormStates: {},
            errorMsgs: {},
            dynamicCosts: {},
            totalRevenues: null,
            totalKnownExpenses: null,
            totalDynamicExpenses: null,
            totalProfits: null,
            totalProfitsDynamic: null,
        }
    }

    render() {
        console.log(this.state);
        const {orders} = this.props;

        if (!orders) {
            return <Spinner/>;
        }

        let the_current_page = null;

        const rows = [];

        var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });

        if (orders.meta && orders.data) {
            if (orders.data.length) {

                // testing sum of all expenses in all orders
                var grandTotal = 0;
                var grandTotalDynamicCosts = 0;
                for (const [index] of orders.data.entries()) {
                    if (orders.data[index].expenses.length > 0) {
                        orders.data[index].expenses.forEach((expense, j) => {
                            grandTotal += expense.amount;
                        });
                    }

                    if (orders.data[index].dynamicCosts.length > 0) {
                        orders.data[index].dynamicCosts.forEach((dynamicCost, j) => {
                            grandTotalDynamicCosts += dynamicCost.amount;
                        });
                    }
                }
                console.log("Total expenses for month for this page: " + grandTotal);
                console.log("Total dynamic costs for month for this page: " + grandTotalDynamicCosts);

                // initial values
                var totalRevenues = orders.data[0].totalRevenuesForMonth;
                var totalKnownExpenses = orders.data[0].totalKnownExpensesForMonth;
                var totalDynamicExpenses = orders.data[0].totalDynamicExpensesForMonth;
                var totalProfits = totalRevenues - totalKnownExpenses;
                var totalProfitsDynamic = totalRevenues - totalKnownExpenses - totalDynamicExpenses;

                var dynamicCosts = {};
                orders.data.forEach((order, i) => {
                    dynamicCosts[order.id] = order.dynamicCosts;
                })

                const getPage = new URLSearchParams(this.props.location.search).get("page");
                if (getPage != null) {
                    the_current_page = parseInt(getPage);
                } else {
                    the_current_page = orders.meta.current_page;
                }

                for (const [index] of orders.data.entries()) {
                    const date = new Date(orders.data[index].created_at);
                    let statusVariant = "secondary";
                    if(parseInt(orders.data[index].status) === 1)
                    {
                        statusVariant = "info";
                    }
                    else if(parseInt(orders.data[index].status) === 2) {
                        statusVariant = "success";
                    }
                    let customer = "";
                    if(orders.data[index].billing_firstname) {
                        customer += orders.data[index].billing_firstname + " ";
                    }
                    if(orders.data[index].billing_lastname) {
                        customer += orders.data[index].billing_lastname;
                    }

                    rows.push(<tr key={index}>
                        <td>{orders.data[index].order_number}</td>
                        <td><Badge pill variant={statusVariant}>{orders.data[index].status_label}</Badge></td>
                        <td>{customer}</td>
                        <td>{date.toLocaleString()}</td>
                        <td><Link to={"/admin/orders/" + orders.data[index].id + "/progress?page="+the_current_page}><Icon className="fas fa-eye" style={{fontSize: "11px", width: "auto"}} /> View</Link></td>
                        <td>$&nbsp;{orders.data[index].total.toFixed(2)}</td>
                        <td style={{display: 'table-cell', width: '500px', minWidth: '300px'}}>
                            <div style={{display: 'table-cell', width: '500px'}}> 
                                <div style={{display: 'table-cell'}}>
                                    { orders.data[index].expenses.length > 0 ?
                                    <ul style={{ margin: 0, paddingLeft: 8, height:"300px", width:"200px", overflowY: "scroll", marginTop: 10}}>
                                        <b>Known Costs</b>
                                        {orders.data[index].expenses.map(function(expense, j){
                                            return <li>{expense.description } - {formatter.format(expense.amount)}</li>;
                                        })}
                                    </ul>
                                    : "No Known Expenses" } 
                                </div>
                                <div style={{display: 'table-cell', width: '20px'}}>
                                </div>
                                <div style={{display: 'table-cell'}}>
                                    { Object.keys(this.state.dynamicCosts).length == 0 && dynamicCosts[orders.data[index].id].length > 0
                                        || 
                                    this.state.dynamicCosts.hasOwnProperty(orders.data[index].id) && this.state.dynamicCosts[orders.data[index].id].length > 0 ?
                                    <ul style={{ 
                                            margin: 0, 
                                            paddingLeft: 20, 
                                            // height: (this.state.dynamicCosts[orders.data[index].id].length * 30).toString()+"px", 
                                            height: "100px",
                                            overflowY: 'scroll'
                                        }}>
                                        <b>Dynamic Costs</b>
                                        { this.state.dynamicCosts.hasOwnProperty(orders.data[index].id) && this.state.dynamicCosts[orders.data[index].id].length > 0 ? this.state.dynamicCosts[orders.data[index].id].map(function(expense, j){
                                            return <li>{expense.description} - {formatter.format(expense.amount)}&nbsp;&nbsp;<Icon onClick={(e) => handleDynamicCostDelete(e, orders.data[index].id, expense, totalRevenues, totalKnownExpenses)} className="fas fa-trash" style={{fontSize: "11px", width: "auto"}} /></li>;
                                        }) : dynamicCosts[orders.data[index].id].map(function(expense, j){
                                            return <li>{expense.description} - {formatter.format(expense.amount)}&nbsp;&nbsp;<Icon onClick={(e) => handleDynamicCostDelete(e, orders.data[index].id, expense, totalRevenues, totalKnownExpenses)} className="fas fa-trash" style={{fontSize: "11px", width: "auto"}} /></li>;
                                        })}
                                    </ul>
                                    : "No Dynamic Expenses" }  
                                    <div style={{ marginTop: 20, paddingLeft: 8}}>
                                        <strong style={{ marginBottom: 10 }}>Create New</strong>
                                        <Form>
                                            <Form.Row>
                                                <Form.Group as={Col} style={{marginBottom: 0}} className={"col-lg-6 col-md-6 col-6"} controlId="formGridProject">
                                                    <Form.Label>Type</Form.Label>
                                                    <Form.Control as="select" style={{ width: '150px' }}
                                                        value={this.state.dynamicCostFormStates.hasOwnProperty(orders.data[index].id) && this.state.dynamicCostFormStates[orders.data[index].id].hasOwnProperty('type') ? this.state.dynamicCostFormStates[orders.data[index].id].type : ''} 
                                                        onChange={e => handleDynamicCostTypeChange(e, orders.data[index].id)}
                                                    >
                                                        {/*<option>Choose current project:</option>*/}
                                                        <option value="">Select Type</option>
                                                        <option value="link-insertion-sale">Link Insertion</option>
                                                        <option value="guest-post-sale">Guest Post</option>
                                                        <option value="writing-guest-post-article">Writing Guest Post Article</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col}  style={{marginBottom: 10}} className="col-sm-4 col-6" controlId="formGridTLD">
                                                    <Form.Label>Amount</Form.Label>
                                                    <Form.Control type="number" name="tld" placeholder="" 
                                                        value={this.state.dynamicCostFormStates.hasOwnProperty(orders.data[index].id) && this.state.dynamicCostFormStates[orders.data[index].id].hasOwnProperty('amount') ? this.state.dynamicCostFormStates[orders.data[index].id].amount : ''} 
                                                        onChange={e => handleDynamicCostAmountChange(e, orders.data[index].id)}
                                                        style={{ width: '90px' }}
                                                    />
                                                </Form.Group>      
                                            </Form.Row>
                                            { this.state.errorMsgs.hasOwnProperty(orders.data[index].id) && this.state.errorMsgs[orders.data[index].id] != null ? <Form.Row>
                                                <span className="text-danger">{this.state.errorMsgs[orders.data[index].id]}</span>
                                            </Form.Row>
                                            : null }  
                                            <Form.Row>
                                                <Form.Group as={Col} className="col-sm-4 col-6" controlId="formGridTLD">
                                                    <Button className="btn-sm kt-mr-15" onClick={(e) => handleDynamicCostSubmit(e, orders.data[index].id, totalRevenues, totalKnownExpenses) }>Add</Button>
                                                </Form.Group> 
                                            </Form.Row>  
                                        </Form>
                                    </div> 
                                </div>
                            </div>
                        </td>
                        <td><Link to={"/admin/orders/" + orders.data[index].id + "/invoice?page="+the_current_page}><Icon className="fas fa-eye" style={{fontSize: "11px", width: "auto"}} /> View</Link> / <a href="#" onClick={(e) => {
                            e.preventDefault();
                            downloadPdfInvoice(orders.data[index].id).then(response => {
                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', orders.data[index].id + '.pdf');
                                document.body.appendChild(link);
                                link.click();
                            });
                        }}><Icon className="fa fa-download" style={{fontSize: "11px", width: "auto"}} /> PDF</a></td>
                    </tr>)
                }
            }
        }

        const handleDynamicCostAmountChange = (event, orderId) => {
            const { value } = event.target;

            this.setState(prevState => ({
                dynamicCostFormStates: {...prevState.dynamicCostFormStates, [orderId]: { ...prevState.dynamicCostFormStates[orderId], amount: value} }
            }))
        }

        const handleDynamicCostTypeChange = (event, orderId) => {
            const { value } = event.target;

            this.setState(prevState => ({
                dynamicCostFormStates: {...prevState.dynamicCostFormStates, [orderId]: { ...prevState.dynamicCostFormStates[orderId], type: value} }
            }))
        }

        const handleDynamicCostSubmit = (event, orderId, initialTotalRevenuesForMonth, initialTotalKnownExpensesForMonth) => {
            let amount = this.state.dynamicCostFormStates.hasOwnProperty(orderId) ? this.state.dynamicCostFormStates[orderId].amount : null,
                type = this.state.dynamicCostFormStates.hasOwnProperty(orderId) ? this.state.dynamicCostFormStates[orderId].type : null;

            if (!amount) {
                this.setState(prevState => ({
                    errorMsgs: {...prevState.errorMsgs, [orderId]: "Error: Amount must be a number." }
                }))
            } else if (!type) {
                this.setState(prevState => ({
                    errorMsgs: {...prevState.errorMsgs, [orderId]: "Error: Please choose a type." }
                }))
            } else {
                // console.log(orderId);
                // console.log(type);
                // console.log(amount);
                // console.log(this.getMonth);
                // console.log(this.getYear);
                createDynamicCost(orderId, type, amount, this.getMonth, this.getYear).then((res) => {
                    // console.log(res.data);
                    // console.log(res.data.dynamicCostsForThisMonth[0].total);

                    const newTotalProfitsDynamic = (this.state.totalRevenues == null && this.state.totalKnownExpenses == null) ?
                        initialTotalRevenuesForMonth - initialTotalKnownExpensesForMonth - res.data.dynamicCostsForThisMonth[0].total
                            :
                        this.state.totalRevenues - this.state.totalKnownExpenses - res.data.dynamicCostsForThisMonth[0].total;

                    this.setState(prevState => ({
                        errorMsgs: {...prevState.errorMsgs, [orderId]: null },
                        dynamicCostFormStates: {...prevState.dynamicCostFormStates, [orderId]: { ...prevState.dynamicCostFormStates[orderId], type: "", value: ""} },
                        dynamicCosts: {...prevState.dynamicCosts, [orderId] : res.data.dynamicCostsForThisOrder},
                        totalDynamicExpenses: res.data.dynamicCostsForThisMonth[0].total,
                        totalProfitsDynamic: newTotalProfitsDynamic,
                    }))
                });
            }
        }

        const handleDynamicCostDelete = (event, orderId, dynamicCost, initialTotalRevenuesForMonth, initialTotalKnownExpensesForMonth) => {

            deleteDynamicCost(orderId, dynamicCost.id, this.getMonth, this.getYear).then((res) => {
                console.log(res.data);


                const newTotalProfitsDynamic = (this.state.totalRevenues == null && this.state.totalKnownExpenses == null) ?
                    initialTotalRevenuesForMonth - initialTotalKnownExpensesForMonth - res.data.dynamicCostsForThisMonth[0].total
                        :
                    this.state.totalRevenues - this.state.totalKnownExpenses - res.data.dynamicCostsForThisMonth[0].total;

                this.setState(prevState => ({
                    dynamicCosts: {...prevState.dynamicCosts, [orderId] : res.data.dynamicCostsForThisOrder},
                    totalDynamicExpenses: res.data.dynamicCostsForThisMonth[0].total,
                    totalProfitsDynamic: newTotalProfitsDynamic,
                }))
            });
        }

        const handleNextPage = (number) => {
            this.props.history.push("?month="+this.getMonth+"&year="+this.getYear+"&page="+number);
            this.props.initOrdersWithArgs(number, this.getMonth, this.getYear);
        };

        // <div class="row">
        //     <div class="col-md-3">
        //         <Link to={"/admin/offers"}><div className="btn btn-primary mb-4">Go to Backlink Marketplace Offers</div></Link>
        //     </div>
        // </div>
        return (
            <Portlet>
                <PortletHeader title={"Orders for " + this.getHumanReadableDate} />
                <PortletBody>
                    <div>
                        <p>Total Revenues: {formatter.format(this.state.totalRevenues != null ? this.state.totalRevenues : totalRevenues)}</p>
                        <p>Total Known Expenses: {formatter.format(this.state.totalKnownExpenses != null ? this.state.totalKnownExpenses : totalKnownExpenses)}</p>
                        <p>Total Dynamic Expenses: {formatter.format(this.state.totalDynamicExpenses != null ? this.state.totalDynamicExpenses : totalDynamicExpenses)}</p>
                        <p>Total Profits: {formatter.format(this.state.totalProfits != null ? this.state.totalProfits : totalProfits )}</p>
                        <p>Total Profits (Dynamic): {formatter.format(this.state.totalProfitsDynamic != null ? this.state.totalProfitsDynamic : totalProfitsDynamic)}</p>
                    </div>
                    <Table striped bordered hover responsive>
                        <thead>
                        <tr>
                            <th>Order Number</th>
                            <th>Status</th>
                            <th>Customer</th>
                            <th>Date Order</th>
                            <th>Order Progress</th>
                            <th>Total</th>
                            <th>Expenses</th>
                            <th>Invoice</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </Table>
                    {the_current_page != null && orders.meta && orders.meta.last_page > 1 && <UPagination currentPage={the_current_page} totalPages={orders.meta.last_page} onChange={handleNextPage} />}
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = ({ ordersAdmin: { orders } }) => ({
    orders
});

export default injectIntl(connect(
    mapStateToProps,
    orders.actions
)(OrdersFilteredAdmin));
