export function calculateDiscount(item) {
    let discount = 0;

    if(item && item.orderData && item.orderData.options) {
        if(item.orderData.type === "guest_post") {
            const valueDA = item.orderData.options.da;
            const valueQ = item.orderData.quantity;

            if (valueDA !== 30) {
                if (valueQ >= 5 && valueQ <= 9) {
                    discount = 0.05;
                } else if (valueQ >= 10) {
                    discount = 0.10;
                }
            }
        }
    }

    return discount;
}

export function calculateCartDiscountAmount(cartItems, userDiscount = []) {
    let gpDiscount = 0;
    let discountAmount = {};
    let gpQuantity = 0;
    let totals = {guest_post: 0};

    // console.log(userDiscount);

    if(cartItems && cartItems.length !== 0) {
        for (let i = 0; i < cartItems.length; i++) {
            const typeDiscount = userDiscount[cartItems[i].orderData.type] ? userDiscount[cartItems[i].orderData.type] : 0;
            if (cartItems[i].orderData.type === "guest_post") {
                const valueDA = cartItems[i].orderData.options.da;
                if (valueDA !== 30 || typeDiscount !== 0) {
                    let itemPrice = Number(cartItems[i].price) * Number(cartItems[i].orderData.quantity);
                    // add ons
                    if (cartItems[i].orderData.add_ons != null && cartItems[i].orderData.add_ons.length > 0) {
                        for(let ao = 0; ao < cartItems[i].orderData.add_ons.length; ao++) {
                            itemPrice += cartItems[i].orderData.add_ons[ao].add_on_price * Number(cartItems[i].orderData.quantity);
                            // itemPrice += cartItems[i].orderData.add_ons[ao].add_on_price;
                        }
                    }
                    // console.log('itemPrice top = ', itemPrice);
                    //
                    if(!totals["guest_post"]) {
                        totals["guest_post"] = 0;
                    }
                    totals["guest_post"] += itemPrice;
                    gpQuantity += Number(cartItems[i].orderData.quantity);
                }
            }
            else {
                if(typeDiscount) {
                    let itemPrice = Number(cartItems[i].price) * Number(cartItems[i].orderData.quantity);
                    // add ons
                    if (cartItems[i].orderData.add_ons != null && cartItems[i].orderData.add_ons.length > 0) {
                        for(let ao = 0; ao < cartItems[i].orderData.add_ons.length; ao++) {
                            itemPrice += cartItems[i].orderData.add_ons[ao].add_on_price * Number(cartItems[i].orderData.quantity);
                            // itemPrice += cartItems[i].orderData.add_ons[ao].add_on_price;
                        }
                    }
                    // console.log('itemPrice bottom = ', itemPrice);
                    //
                    if(!totals[cartItems[i].orderData.type]) {
                        totals[cartItems[i].orderData.type] = 0;
                    }
                    totals[cartItems[i].orderData.type] += itemPrice;
                }
            }
        }
    }

    if(totals) {
        for (const [type] of Object.entries(totals)) {
            // console.log(type);
            if (type === "guest_post") {
                if (userDiscount[type] && userDiscount[type] > 0) {
                    gpDiscount = userDiscount[type];
                } else {
                    if (gpQuantity >= 5 && gpQuantity <= 9) {
                        gpDiscount = 0.05;
                    } else if (gpQuantity >= 10) {
                        gpDiscount = 0.10;
                    }
                }
                // gpDiscount = 0.50;
                discountAmount[type] = totals[type] * gpDiscount;
            } else {
                if (userDiscount[type] && totals[type] > 0) {
                    discountAmount[type] = totals[type] * userDiscount[type];
                }
            }
        }
    }

    return Object.values(discountAmount).reduce((a, b) => a + b, 0);
}
