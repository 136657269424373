import React, { useState,useEffect } from "react";
import ReactDOM from 'react-dom';
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
//import SearchDropdown from "../../../app/partials/layout/SearchDropdown";
import UserNotifications from "../../../app/partials/layout/UserNotifications";
import MyCart from "../../../app/partials/layout/MyCart";
//import QuickActionsPanel from "../../../app/partials/layout/QuickActionsPanel";
//import QuickPanelToggler from "./QuickPanelToggle";
//import LanguageSelector from "../../../app/partials/layout/LanguageSelector";
import UserProfile from "../../../app/partials/layout/UserProfile";
import { toAbsoluteUrl } from "../../utils/utils";
import {Link} from "react-router-dom";
import TrialTimer from "../../../app/partials/layout/TrialTimer";
import SaleTimer from "../../../app/partials/layout/SaleTimer";
import { Button, Dropdown } from "react-bootstrap";
import StartTutorial from "../../../app/partials/layout/StartTutorial";
import UpgradeToPremium from "../../../app/partials/layout/UpgradeToPremium";
import * as packages from "../../../app/store/ducks/packages.duck";
import * as auth from "../../../app/store/ducks/auth.duck";

function FinanceDropDownMenu({ userBalance, tmoAmount, isBlogger, isContentWriter }) {
    // const {userBalance} = this.props;
    // console.log("userBalance ABOVE", userBalance)
    const [showDropdown, setShowDropdown] = useState(false);

    const moneyFormatter = (num) => {
        if (typeof num !== 'undefined') {
            try {
                var calc = num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('.00','');
                // var calc = num.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return '$'+calc;
            } catch (e) {
                console.log('moneyFormatter',e);
                return '-';
            }
        }
        return '$'+num;
    }

    function close_tools_area() {
        if (document.getElementById('tools-checklist-button') != null) {
            document.getElementById('tools-checklist-button').dataset.open = 'n';
            var dropdownElements = document.querySelectorAll('.tools-checklist-dropdown');
            dropdownElements.forEach((element, index, array) => {
                element.style.display = 'none';
            });
            if (typeof document.getElementById('tools_checklist_lock') !== 'undefined' && document.getElementById('tools_checklist_lock') != null) {
                document.getElementById('tools_checklist_lock').style.display = 'none';
            }
        }
    }

    function close_gmc_area() {
        if (document.getElementById('gmc-checklist-button') != null) {
            document.getElementById('gmc-checklist-button').dataset.open = 'n';
            var dropdownElements = document.querySelectorAll('.gmc-checklist-dropdown');
            dropdownElements.forEach((element, index, array) => {
                element.style.display = 'none';
            });
            if (typeof document.getElementById('gmc_checklist_lock') !== 'undefined' && document.getElementById('gmc_checklist_lock') != null) {
                document.getElementById('gmc_checklist_lock').style.display = 'none';
            }
        }
    }

    function close_sop_area() {
        if (document.getElementById('sop-checklist-button') != null) {
            document.getElementById('sop-checklist-button').dataset.open = 'n';
            var dropdownElements = document.querySelectorAll('.sop-checklist-dropdown');
            dropdownElements.forEach((element, index, array) => {
                element.style.display = 'none';
            });
            if (typeof document.getElementById('sop_checklist_lock') !== 'undefined' && document.getElementById('sop_checklist_lock') != null) {
                document.getElementById('sop_checklist_lock').style.display = 'none';
            }
        }
    }

    if (true == true) {
        var sections = document.querySelectorAll('.kt-menu__section');
        sections.forEach((element, index, array) => {
            // console.log('looping element=', element);
            var childElement = element.querySelector('.kt-menu__section-text');
            if (childElement != null) {
                var childText = childElement.innerText || childElement.textContent;
                // console.log('childElement childText', childText);

                var section_row = 1;
                var section_row_height = 40;
                var menu_row_height = 44;

                var top_offset_variable = 44 - (menu_row_height*2); // removed 3 menu rows
                if (isBlogger == true || isContentWriter == true) {
                    // top_offset_variable = (menu_row_height*2) - (menu_row_height*3); // removed 3 menu rows
                    top_offset_variable = 44 - (menu_row_height*1);
                }
                // console.log('top_offset_variable',top_offset_variable);
                // console.log('isBlogger',isBlogger);
                // console.log('isContentWriter',isContentWriter);

                // ADDED -> BIZLOCAL POWER LINKS & BACKLINKCLEANUP
                top_offset_variable = top_offset_variable + (menu_row_height * 2);

                // SEARCH ALL - CHANGE UNLOCK TIERS
                var checklists_requirement = 500;
                var more_requirement = 500;
                var tools_requirement = 500;

                // var checklists_requirement = 100000;
                // var more_requirement = 100000;
                // var tools_requirement = 100000;
                // var checklists_requirement = 0;
                // var more_requirement = 0;
                // var tools_requirement = 0;

                if (childText == 'Coming Soon') {
                    var menu_rows = 1;
                    let custom_element = document.createElement("div");
                    custom_element.id="sop_checklist_lock";
                    var height = ((menu_rows * menu_row_height) + (section_row * section_row_height))+(section_row_height/6) - 40;
                    var position = 'absolute'; // relative
                    var top_offset = 590 + top_offset_variable;

                    // ADD NEW OFFSET WITH DYNAMIC DROPDOWN
                    top_offset = top_offset + 44;

                    custom_element.setAttribute('style', `height:${height}px; position:${position}; top:${top_offset}px;background:rgb(255 255 255 / 60%);z-index:9999;width:245px;display:none;`);
                    custom_element.innerHTML = '<div style="display:flex;height:100%;align-items:center;justify-content:center;"><img width="50" height="50" src="https://serppro.nyc3.cdn.digitaloceanspaces.com/public%2FLOCK.png" style="opacity:0.5;"></div>';

                    if (document.getElementById('kt-aside') != null
                        && document.getElementById('sop_checklist_lock') == null
                    ) {
                        custom_element.width=document.getElementById('kt-aside').width;
                        document.getElementById('kt-aside').appendChild(custom_element);
                    }
                }
                if (childText == 'Get More Clients') {
                    if (tmoAmount < more_requirement) {
                        // element.classList.add("locked-list");
                        var menu_rows = 1;
                        let custom_element = document.createElement("div");
                        custom_element.id="gmc_checklist_lock";
                        // var height = ((menu_rows * menu_row_height) + (section_row * section_row_height))+(section_row_height/3);
                        var height = (menu_rows * menu_row_height);
                        var position = 'absolute'; // relative
                        // var top_offset = 780 + top_offset_variable + (menu_row_height*3);

                        // ADD NEW OFFSET WITH DYNAMIC DROPDOWN
                        var top_offset = 600 + top_offset_variable;
                        top_offset = top_offset + 46 + 48;

                        custom_element.setAttribute('style', `height:${height}px; position:${position}; top:${top_offset}px;background:rgb(255 255 255 / 60%);z-index:9999;width:245px;display:none;`);
                        custom_element.innerHTML = '<div style="display:flex;height:100%;align-items:center;justify-content:center;"><img width="40" height="40" src="https://serppro.nyc3.cdn.digitaloceanspaces.com/public%2FLOCK.png" style="opacity:0.5;"></div>';
                        if (document.getElementById('kt-aside') != null
                            && document.getElementById('gmc_checklist_lock') == null
                        ) {
                            custom_element.width=document.getElementById('kt-aside').width;
                            document.getElementById('kt-aside').appendChild(custom_element);
                        }
                    } else if (document.getElementById('gmc_checklist_lock') != null) {
                        document.getElementById('gmc_checklist_lock').remove();
                    }
                }
                if (childText == 'Tools') {
                    if (tmoAmount < tools_requirement) {
                        // element.classList.add("locked-list");
                        var menu_rows = 2;
                        let custom_element = document.createElement("div");
                        custom_element.id="tools_checklist_lock";
                        // var height = ((menu_rows * menu_row_height) + (section_row * section_row_height))+(section_row_height/3);
                        var height = (menu_rows * menu_row_height);
                        var position = 'absolute'; // relative

                        // var top_offset = -(((menu_rows-1) * menu_row_height) + (section_row * section_row_height));
                        // var top_offset = 875 + top_offset_variable + (menu_row_height*3);

                        // ADD NEW OFFSET WITH DYNAMIC DROPDOWN
                        var top_offset = 600 + top_offset_variable;
                        top_offset = top_offset + 48 + 48 + 48;

                        custom_element.setAttribute('style', `height:${height}px; position:${position}; top:${top_offset}px;background:rgb(255 255 255 / 60%);z-index:9999;width:245px;display:none;`);
                        custom_element.innerHTML = '<div style="display:flex;height:100%;align-items:center;justify-content:center;"><img width="50" height="50" src="https://serppro.nyc3.cdn.digitaloceanspaces.com/public%2FLOCK.png" style="opacity:0.5;"></div>';
                        if (document.getElementById('kt-aside') != null
                            && document.getElementById('tools_checklist_lock') == null
                        ) {
                            custom_element.width=document.getElementById('kt-aside').width;
                            document.getElementById('kt-aside').appendChild(custom_element);
                        }
                    } else if (document.getElementById('tools_checklist_lock') != null) {
                        document.getElementById('tools_checklist_lock').remove();
                    }
                }
            }
        });
    }

    try {

        if (document.getElementById('sop-checklist-button') != null) {
            document.getElementById('sop-checklist-button').onclick = function() {
                if (document.getElementById('sop-checklist-button').getAttribute("data-open") == 'n') {

                    document.getElementById('sop-checklist-button').dataset.open = 'y';

                    // START -> close others
                    close_tools_area();
                    close_gmc_area();
                    // END -> close eothers

                    var dropdownElements = document.querySelectorAll('.sop-checklist-dropdown');
                    dropdownElements.forEach((element, index, array) => {
                        element.style.display = 'block';
                    });

                    if (typeof document.getElementById('sop_checklist_lock') !== 'undefined' && document.getElementById('sop_checklist_lock') != null) {
                        document.getElementById('sop_checklist_lock').style.display = 'block';
                    }

                } else {
                    close_sop_area();
                }
            };

            if (document.getElementById('sop-checklist-button').getAttribute("data-open") == 'n') {
                document.getElementById('sop-checklist-button').click();
            }
        }

        if (document.getElementById('gmc-checklist-button') != null) {
            document.getElementById('gmc-checklist-button').onclick = function() {
                if (document.getElementById('gmc-checklist-button').getAttribute("data-open") == 'n') {

                    document.getElementById('gmc-checklist-button').dataset.open = 'y';

                    // START -> close others
                    close_sop_area();
                    close_tools_area();
                    // END -> close eothers

                    var dropdownElements = document.querySelectorAll('.gmc-checklist-dropdown');
                    dropdownElements.forEach((element, index, array) => {
                        element.style.display = 'block';
                    });

                    if (typeof document.getElementById('gmc_checklist_lock') !== 'undefined' && document.getElementById('gmc_checklist_lock') != null) {
                        document.getElementById('gmc_checklist_lock').style.display = 'block';
                    }

                } else {
                    close_gmc_area();
                }
            };
        }

        if (document.getElementById('tools-checklist-button') != null) {
            document.getElementById('tools-checklist-button').onclick = function() {
                if (document.getElementById('tools-checklist-button').getAttribute("data-open") == 'n') {

                    document.getElementById('tools-checklist-button').dataset.open = 'y';

                    // START -> close others
                    close_sop_area();
                    close_gmc_area();
                    // END -> close eothers

                    var dropdownElements = document.querySelectorAll('.tools-checklist-dropdown');
                    dropdownElements.forEach((element, index, array) => {
                        element.style.display = 'block';
                    });

                    if (typeof document.getElementById('tools_checklist_lock') !== 'undefined' && document.getElementById('tools_checklist_lock') != null) {
                        document.getElementById('tools_checklist_lock').style.display = 'block';
                    }

                } else {
                    close_tools_area();
                }
            };
        }

    } catch (e) {
        console.log('error',e);
    }

    // var sections = document.querySelectorAll('.kt-menu__section-text');
    // sections.forEach((element, index, array) => {
    //     var text = element.innerText || element.textContent;
    //     console.log('looping', text);
    //     if (text == 'SOP Checklists') {

    //     } else if (text == 'Get More Clients') {

    //     } else if (text == 'Tools') {

    //     }
    // });


    return (
        <Dropdown
            onMouseLeave={() => setShowDropdown(false)}
            onMouseOver={() => setShowDropdown(true)}
            style={{ color: "#000", fontSize: "17px", marginLeft: "12px", marginLeft: "12px", backgroundColor: "transparent",border: "0px",paddingLeft: "0px",paddingRight: "0px"}}>
                <Dropdown.Toggle className="main-style" id="dropdown-basic"
                    style={{ color: "#000", fontSize: "18px", backgroundColor: "transparent", border: "0px",paddingLeft: "0px",paddingRight: "0px" }}>
                    <span style={{paddingTop: "2px", paddingRight: "5px"}}>Balance:</span>
                    {moneyFormatter(userBalance)}
                </Dropdown.Toggle>
                <Dropdown.Menu show={showDropdown}>
                    <Dropdown.Item href="/profile#deposit">Add Funds</Dropdown.Item>
                    {/*<Dropdown.Item href="/profile#withdraw">Withdraw Funds</Dropdown.Item>*/}
                </Dropdown.Menu>
        </Dropdown>
    );
}

function CountdownTimer({ dateTo, dateNow }) {
    // console.log('dateTo',dateTo);
    // console.log('dateNow',dateNow);
    const [seconds, setTimer] = useState(((dateTo - dateNow) / 1000));
    useEffect(() => {
      const timeout = setTimeout(() => setTimer(seconds - 1), 1000);
      if (seconds <= 0) clearTimeout(timeout);
      return () => {
        clearTimeout(timeout);
      };
    }, [seconds]);
    // console.log('seconds',seconds);
    const h = Math.floor(seconds / 3600).toString(); //.padStart(2, "0");
    const m = Math.floor((seconds % 3600) / 60).toString(); //.padStart(2, "0");
    const s = Math.floor(seconds % 60).toString(); //.padStart(2, "0");
    if (seconds > 0) {
        return (
            <div style={{color:"#FF0000",fontStyle:"italic",fontSize:"12px",position:"absolute",top:"31px",left:"10px"}}>EXPIRES IN {h}H {m}M {s}S</div>
        )
    } else {
        return (
            <div style={{color:"#FF0000",fontStyle:"italic",fontSize:"12px",position:"absolute",top:"31px",left:"10px"}}>EXPIRED</div>
        )
    };
}

class Topbar extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.requestPackagePreimumMembership("marketplace_guest_post_access");
        this.props.requestUser();
        // const {user} = this.props;
        // if(user) {
        //     this.props.requestUser();
        // }
    }

    render() {
        const {packages, currentPackage, premiumMembershipPackage, user} = this.props;

        let isPremiumMember = false;
        let currentSitewidePremiumLevel = "Free";
        let userBalance = 0.00;
        let isBlogger = false;
        let isContentWriter = false;
        let userWithdrawableBalance = 0.00;
        let tmoAmount = 0;
        if (user.tmo_amount) tmoAmount = user.tmo_amount;

        if (typeof user !== 'undefined') {
            userBalance = user.balance;
            // premium_membership_balance
            userBalance = userBalance + user.premium_membership_balance;
        }

        if (typeof currentPackage !== 'undefined') {
            if (typeof currentPackage.activePackage !== 'undefined') {
                // console.log(currentPackage.activePackage);
                // console.log(currentPackage.activePackage.name);
                if (currentPackage.activePackage.name != "Free") {
                    isPremiumMember = true;
                    currentSitewidePremiumLevel = currentPackage.activePackage.name;
                }
                // console.log(currentPackage.activePackage.type);
            }

            // if (typeof currentPackage.user_balance !== 'undefined') {
            //     userBalance = currentPackage.user_balance;
            // }

            if (typeof currentPackage.blogger !== 'undefined') {
                isBlogger = currentPackage.blogger;
            }

            if (typeof currentPackage.is_content_writer !== 'undefined') {
                isContentWriter = currentPackage.is_content_writer;
            }

            // if (typeof currentPackage.withdrawable_balance !== 'undefined') {
                // userBalance = currentPackage.userWithdrawableBalance;
                // userBalance = userBalance - currentPackage.withdrawable_balance;
            // }
        }
        if (typeof premiumMembershipPackage !== 'undefined') {
            if (typeof premiumMembershipPackage.activePackage !== 'undefined') {
                // console.log(premiumMembershipPackage.activePackage);
                // console.log(premiumMembershipPackage.activePackage.name);
                if (premiumMembershipPackage.activePackage.type == "marketplace_guest_post_access") {
                    if (premiumMembershipPackage.activePackage.name != "Free") {
                        isPremiumMember = true;
                        currentSitewidePremiumLevel = premiumMembershipPackage.activePackage.name;
                    }
                }
            }
        }

        if (user.blogger == true) isBlogger = true;
        if (user.is_content_writer == true) isContentWriter = true;

        // console.log(this.props);

        // console.log("currentSitewidePremiumLevel = " + currentSitewidePremiumLevel);

        // console.log("userBalance below", userBalance)

        // console.log('location.href',window.location.href);
        let on_marketplace = false;
        if (typeof window.location.href !== 'undefined') {
            let current_url = window.location.href;
            if (current_url.indexOf('/marketplace') !== -1) {
                on_marketplace = true;
            }
        }
        // console.log('on_marketplace',on_marketplace);
        var dynamic_button = '';
        if (on_marketplace) {
            // if (typeof premiumMembershipPackage !== 'undefined' && (! isPremiumMember || isPremiumMember && currentSitewidePremiumLevel == "Free")) {
            //     dynamic_button = (<Link className="btn btn-brand" to="/packages/marketplace-access" style={{ marginRight: "12px" }}>Unlock Wholesale Pricing</Link>);

            // } else if (typeof premiumMembershipPackage !== 'undefined' && (isPremiumMember && currentSitewidePremiumLevel == "Pro")) {
            //     dynamic_button = (<Link className="btn btn-brand" to="/packages/marketplace-access" style={{ marginRight: "12px" }}>Unlock Wholesale Pricing</Link>);
            // }
        } else {
            // dynamic_button = (<Link className="btn btn-success" to="/marketplace" style={{ marginRight: "12px" }}>Browse Backlink Marketplace</Link>);
        }

        return (
            <div className="kt-header__topbar" style={{"background":"white"}}>
                <div className="kt-header__topbar-item align-self-center kt-header__upgrade-link d-none d-sm-block">
                    {/* <Link className="btn btn-success" to="/marketplace">Browse Marketplace</Link> */}
                    {/* <Link className="btn btn-outline-primary" to="/schedule-call" style={{ marginRight: "12px" }}>Schedule Strategy Call</Link> */}
                    {/*<a href="https://calendly.com/serppro" target="_blank" className="btn btn-outline-primary" style={{ marginRight: "12px" }}>Schedule Strategy Call</a>*/}
                    {/* <a href="https://www.serppro.io/help-center" target="_blank" className="topbar-my-orders" style={{ color:"#000",fontSize:"17px",backgroundColor:"transparent",border:"0px",position:"relative",top:"2px",left:"2px" }}>Help Center</a> */}

                    {dynamic_button}

                    {/* <Link className="btn btn-success" to="/marketplace" style={{ marginLeft: "12px", marginRight: "12px" }}>Browse Backlink Marketplace</Link> */}

                    {/* {typeof premiumMembershipPackage !== 'undefined' && (! isPremiumMember || isPremiumMember && currentSitewidePremiumLevel == "Free") ?
                        <Link className="kt-ml-10 btn btn-brand" to="/packages/marketplace-access" style={{ marginLeft: "12px", marginRight: "12px" }}>Unlock Wholesale Pricing</Link>
                    : typeof premiumMembershipPackage !== 'undefined' && (isPremiumMember && currentSitewidePremiumLevel == "Pro") ?
                        <Link className="kt-ml-10 btn btn-brand" to="/packages/marketplace-access" style={{ marginLeft: "12px", marginRight: "12px" }}>Unlock Wholesale Pricing</Link>
                    : ""} */}

                    {/* <StartTutorial /> */}
                </div>
                <div className="kt-header__topbar-item align-self-center kt-hidden-desktop kt-header-mobile__logo">
                  <Link to="/">
                    <img alt="logo" src={toAbsoluteUrl("/media/logos/logo-2-sm.png")} />
                  </Link>
                </div>
                {/*<SearchDropdown icon="flaticon2-search-1" />*/}

                <SaleTimer />

                {/*<TrialTimer />
                <Link style={{ color: "#000", fontSize: "18px", marginLeft: "8px"}} to="/profile">${userBalance.toFixed(2)}</Link>
                <Link to="/profile">
                */}

                <div className="kt-header__topbar-item align-self-center">

                    {/* {! isPremiumMember || isPremiumMember && currentSitewidePremiumLevel == "Free" ?
                        <Link className="kt-ml-10 btn btn-brand" to="/packages/marketplace-access" style={{ marginLeft: "12px", marginRight: "12px" }}>Upgrade to Premium</Link>
                    : isPremiumMember && currentSitewidePremiumLevel == "Pro" ?
                        <Link className="kt-ml-10 btn btn-brand" to="/packages/marketplace-access" style={{ marginLeft: "12px", marginRight: "12px" }}>Upgrade Plan</Link>
                    : ""} */}

                    {/* { (isBlogger || isContentWriter) ?
                        <Link className="topbar-my-sales" style={{ color: "#000", fontSize: "17px", paddingTop: "10px", marginLeft: "12px", marginRight: "12px", backgroundColor: "transparent",border: "0px",paddingLeft: "0px",paddingRight: "0px" }} to="/sales">My Sales</Link>
                    : ""} */}

                    {/* <Link className="topbar-my-orders" style={{ color: "#000", fontSize: "17px", paddingTop: "10px", marginLeft: "12px", marginRight: "12px", backgroundColor: "transparent",border: "0px",paddingLeft: "0px",paddingRight: "0px" }} to="/orders">My Orders</Link> */}

                    {/*<a href="https://www.serppro.io/earn-credits" target="_blank" className="topbar-my-orders" style={{ color: "#000", fontSize: "17px", paddingTop: "10px", marginLeft: "0px", marginRight: "8px", backgroundColor: "transparent",border: "0px",paddingLeft: "0px",paddingRight: "0px" }}>Earn Credits</a>*/}

                    {/* <Link className="topbar-my-orders" style={{ color: "#000", fontSize: "17px", paddingTop: "10px", marginLeft: "12px", marginRight: "12px", backgroundColor: "transparent",border: "0px",paddingLeft: "0px",paddingRight: "0px" }} to={{ pathname: "https://serppro.io/help-center" }} target="_blank">Help Center</Link> */}

                    <div style={{position:"relative"}}>
                        <FinanceDropDownMenu userBalance={userBalance} tmoAmount={tmoAmount} isBlogger={isBlogger} isContentWriter={isContentWriter} />

                        {user.sign_up_bonus_status == 1 && userBalance > 0 && user.sign_up_bonus_expiration_date != null ?
                            <CountdownTimer dateTo={new Date(user.sign_up_bonus_expiration_date).getTime()} dateNow={Date.now()} />
                            : ''
                        }
                    </div>
                </div>

                <UserNotifications
                  skin="light"
                  iconType=""
                  icon="flaticon2-bell-alarm-symbol"
                  type="primary"
                  dot="false"
                />

                  {/*<QuickActionsPanel
                  bgImage={toAbsoluteUrl("/media/misc/bg-2.jpg")}
                  skin="light"
                  iconType=""
                  gridNavSkin="light"
                  icon="flaticon2-gear"
                />*/}

                <MyCart
                  iconType=""
                  skin="light"
                  icon="flaticon2-shopping-cart-1"
                  bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}
                />

                {/*<QuickPanelToggler />*/}

                {/*<LanguageSelector iconType="" />*/}

                <UserProfile showBadge={true} />
            </div>
        );
    }
}

const mapStateToProps = ({ packages: {packages, currentPackage, premiumMembershipPackage}, auth:{user} }) => ({
    packages, currentPackage, premiumMembershipPackage, user
});

export default injectIntl(connect(
    mapStateToProps,
    {...packages.actions, ...auth.actions}
)(Topbar));
