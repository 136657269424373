import React from "react";
import {withStyles} from "@material-ui/core";
import * as packages from "../../store/ducks/packages.duck";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";

const styles = theme => ({
    'trial-timer': {
        //
    },
});

class TrialTimer extends React.Component {
    componentDidMount() {
        const type = "marketplace_guest_post_access";
        this.props.requestPackages(type);
    }

    render() {
        const type = "marketplace_guest_post_access";
        const { classes, packages } = this.props;

        if(!packages || !packages[type] || !packages[type].data) {
            return "";
        }

        const activePackage = packages[type].data.active_package;

        if(!activePackage || activePackage.price !== 0 || !activePackage.days_left) {
            return "";
        }

        const daysLeft = activePackage.days_left;

        return (<div className={"kt-header__topbar-item d-none d-md-flex kt-ml-5 kt-mr-5 " + classes['trial-timer']}>
            {daysLeft > 0 && <div className="kt-header__topbar-wrapper align-self-center text-primary" style={{cursor:"default"}}>
                Trial Ends in {daysLeft} days
            </div>}
        </div>);
    }
}

const mapStateToProps = ({ packages: { packages } }) => ({
    packages
});

export default withStyles(styles)(injectIntl(connect(
    mapStateToProps,
    packages.actions
)(TrialTimer)));
