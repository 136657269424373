import React, { Component } from "react";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as orders from "../../store/ducks/admin/orders.duck";
import * as guestposts from "../../store/ducks/admin/guestPosts.duck";
import * as seoservices from "../../store/ducks/admin/seoServices.duck"
import * as services from "../../store/ducks/admin/services.duck"
import {Spinner} from "reactstrap";
import {Button, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Portlet, PortletBody, PortletHeader} from "../../partials/content/Portlet";
import UPagination from "../../partials/content/Pagination";

class OrderProgressAdmin extends Component {
    componentDidMount() {
        const { orderId } = this.props.match.params;
        this.props.requestOrder(orderId);
        this.props.initGuestPosts(orderId, 1, {build: 1});
        this.props.initSeoServices(orderId, 1, {/*done: 1*/});
        this.props.requestServices(orderId, 1, "link_clean_up", {/*done: 1*/});
        this.props.requestServices(orderId, 1, "press-release", {/*done: 1*/});
        this.props.requestServices(orderId, 1, "gpb", {/*done: 1*/});
        this.props.requestServices(orderId, 1, "link_insertion", {/*done: 1*/});
        this.props.requestServices(orderId, 1, "citation", {/*done: 1*/});
        this.props.requestServices(orderId, 1, "article", {/*build: 1*/});
    }

    render() {
        const order = this.props.currentOrder;
        const {guestPosts, seoServices, services} = this.props;

        if (!order) {
            return <Spinner/>;
        }
        else if(!order.data) {
            return (
                <>
                    <h1>Order not found!</h1>
                </>
            );
        }

        let the_current_page = null;
        const getPage = new URLSearchParams(this.props.location.search).get("page");
        if (getPage != null) {
            the_current_page = getPage;
        }

        const guestPostsSubmitted = parseInt(order.data.status) !== 0;
        const orderCompleted = parseInt(order.data.status) === 2;
        const guestPostsRows = [];

        if(guestPosts && guestPosts.data) {
            if (guestPosts.data.length) {
                for (const [index] of guestPosts.data.entries()) {
                    guestPostsRows.push(<tr key={index}>
                        <td className="align-middle">{guestPosts.data[index].website}</td>
                        <td className="align-middle">{guestPosts.data[index].anchor}</td>
                        <td className="align-middle">{guestPosts.data[index].url}</td>
                        <td className="align-middle">{guestPosts.data[index].da}</td>
                    </tr>);
                }
            }
        }

        const handleNextPage = (number) => {
            this.props.initGuestPosts(order.data.id, number, {build: 1});
        };

        const seoServicesSubmitted = parseInt(order.data.status) !== 0;
        const seoServicesRows = [];

        if(seoServices && seoServices.data) {
            if (seoServices.data.length) {
                for (const [index] of seoServices.data.entries()) {
                    seoServicesRows.push(<tr key={index}>
                        <td className="align-middle">{seoServices.data[index].url}</td>
                        <td className="align-middle text-center">{seoServices.data[index].done === 1 ?
                            "Completed"
                            : the_current_page != null ?
                            <Link to={"/admin/orders/seo-services/" + seoServices.data[index].id+"&page="+the_current_page}>
                                <Button variant={!seoServices.data[index].url ? "secondary" : "primary"}>{!seoServices.data[index].url ? "Details" : "Add Order Delivery"}</Button>
                            </Link>
                            :
                            <Link to={"/admin/orders/seo-services/" + seoServices.data[index].id}>
                                <Button variant={!seoServices.data[index].url ? "secondary" : "primary"}>{!seoServices.data[index].url ? "Details" : "Add Order Delivery"}</Button>
                            </Link>}
                        </td>
                    </tr>);
                }
            }
        }

        const handleNextServicesPage = (number) => {
            this.props.initSeoServices(order.data.id, number, {/*done: 1*/});
        };

        const servicesSubmitted = parseInt(order.data.status) !== 0;
        const liServicesSubmitted = parseInt(order.data.status) !== 0;
        const cServicesSubmitted = parseInt(order.data.status) !== 0;
        const aServicesSubmitted = parseInt(order.data.status) !== 0;
        const servicesRows = [];
        const prServicesRows = [];
        const gpbServicesRows = [];
        const liServicesRows = [];
        const cServicesRows = [];
        const aServicesRows = [];

        if(services) {
            if (services["link_clean_up"] && services["link_clean_up"].data && services["link_clean_up"].data.length) {
                for (const [index] of services["link_clean_up"].data.entries()) {
                    servicesRows.push(<tr key={index}>
                        <td className="align-middle">{services["link_clean_up"].data[index].url}</td>
                        <td className="align-middle text-center">
                            {services["link_clean_up"].data[index].done === 1 ?
                            "Completed"
                            : the_current_page != null ?
                            <Link to={"/admin/orders/link-clean-up/" + services["link_clean_up"].data[index].id + "?page="+the_current_page}>
                                <Button variant={!services["link_clean_up"].data[index].url ? "secondary" : "primary"}>{!services["link_clean_up"].data[index].url ? "Details" : "Add Order Delivery"}</Button>
                            </Link>
                            :
                            <Link to={"/admin/orders/link-clean-up/" + services["link_clean_up"].data[index].id}>
                                <Button variant={!services["link_clean_up"].data[index].url ? "secondary" : "primary"}>{!services["link_clean_up"].data[index].url ? "Details" : "Add Order Delivery"}</Button>
                            </Link>}
                        </td>
                    </tr>);
                }
            }
            if (services["link_insertion"] && services["link_insertion"].data && services["link_insertion"].data.length) {
                for (const [index] of services["link_insertion"].data.entries()) {
                    liServicesRows.push(<tr key={index}>
                        <td className="align-middle">{services["link_insertion"].data[index].url}</td>
                        <td className="align-middle text-center">
                            {services["link_insertion"].data[index].build === 1 && the_current_page != null ?
                            <Link to={"/admin/orders/link-insertion/" + services["link_insertion"].data[index].id+"&page="+the_current_page}>
                                <Button variant="secondary">View Order Details</Button>
                            </Link>
                            : services["link_insertion"].data[index].build === 1 ?
                            <Link to={"/admin/orders/link-insertion/" + services["link_insertion"].data[index].id}>
                                <Button variant="secondary">View Order Details</Button>
                            </Link>
                            : the_current_page != null ?
                            <Link to={"/admin/orders/link-insertion/" + services["link_insertion"].data[index].id+"&page="+the_current_page}>
                                <Button variant={!services["link_insertion"].data[index].url ? "secondary" : "primary"}>{!services["link_insertion"].data[index].url ? "Details" : "Add Order Delivery"}</Button>
                            </Link>
                            :
                            <Link to={"/admin/orders/link-insertion/" + services["link_insertion"].data[index].id}>
                                <Button variant={!services["link_insertion"].data[index].url ? "secondary" : "primary"}>{!services["link_insertion"].data[index].url ? "Details" : "Add Order Delivery"}</Button>
                            </Link>}
                        </td>
                    </tr>);
                }
            }
            if (services["press-release"] && services["press-release"].data && services["press-release"].data.length) {
                for (const [index] of services["press-release"].data.entries()) {
                    prServicesRows.push(<tr key={index}>
                        <td className="align-middle">{services["press-release"].data[index]?.package}</td>
                        <td className="align-middle">{services["press-release"].data[index].url}</td>
                    </tr>);
                }
            }
            if (services["gpb"] && services["gpb"].data && services["gpb"].data.length) {
                for (const [index] of services["gpb"].data.entries()) {
                    gpbServicesRows.push(<tr key={index}>
                        <td className="align-middle">{services["gpb"].data[index]?.package}</td>
                        <td className="align-middle">{services["gpb"].data[index].url}</td>
                    </tr>);
                }
            }
            if (services["citation"] && services["citation"].data && services["citation"].data.length) {
                for (const [index] of services["citation"].data.entries()) {
                    cServicesRows.push(<tr key={index}>
                        <td className="align-middle">{services["citation"].data[index].amount} citations</td>
                        <td className="align-middle text-center">
                            {the_current_page != null ?
                            <Link to={"/admin/orders/citation/" + services["citation"].data[index].id+"?page"+the_current_page}>
                                <Button variant={services["citation"].data[index].build === 1 ? "secondary" : "primary"}>{services["citation"].data[index].build === 1 ? "Details" : "Add Order Delivery"}</Button>
                            </Link>
                            :
                            <Link to={"/admin/orders/citation/" + services["citation"].data[index].id}>
                                <Button variant={services["citation"].data[index].build === 1 ? "secondary" : "primary"}>{services["citation"].data[index].build === 1 ? "Details" : "Add Order Delivery"}</Button>
                            </Link>}
                        </td>
                    </tr>);
                }
            }
            if (services["article"] && services["article"].data && services["article"].data.length) {
                for (const [index] of services["article"].data.entries()) {
                    aServicesRows.push(<tr key={index}>
                        <td className="align-middle text-center">
                            {the_current_page != null ?
                            <Link to={"/admin/orders/article/" + services["article"].data[index].id+"&page="+the_current_page}>
                                <Button variant={services["article"].data[index].build === 1 ? "secondary" : "primary"}>{services["article"].data[index].build === 1 ? "Details" : "Add Order Delivery"}</Button>
                            </Link>
                            :
                            <Link to={"/admin/orders/article/" + services["article"].data[index].id}>
                                <Button variant={services["article"].data[index].build === 1 ? "secondary" : "primary"}>{services["article"].data[index].build === 1 ? "Details" : "Add Order Delivery"}</Button>
                            </Link>}
                        </td>
                    </tr>);
                }
            }
        }

        const handleNextLCServicesPage = (number) => {
            this.props.requestServices(order.data.id, number, "link_clean_up",{/*done: 1*/});
        };
        const handleNextLIServicesPage = (number) => {
            this.props.requestServices(order.data.id, number, "link_insertion",{/*done: 1*/});
        };
        const handleNextCServicesPage = (number) => {
            this.props.requestServices(order.data.id, number, "citation",{/*done: 1*/});
        };
        const handleNextAServicesPage = (number) => {
            this.props.requestServices(order.data.id, number, "article",{/*build: 1*/});
        };


        return (
            <>
                {the_current_page != null ?
                    <Link to={"/admin/orders?page="+the_current_page}>
                        <Button variant="dark" className="kt-margin-b-15">Back</Button>
                    </Link>
                : <Link to="/admin/orders">
                    <Button variant="dark" className="kt-margin-b-15">Back</Button>
                </Link>}

                {order.data.hasGuestPosts && <Portlet>
                    <PortletHeader title={"Order Progress #" + order.data.order_number + " - Guest Posts"} />
                    <PortletBody>
                        <Table striped bordered hover responsive>
                            <thead>
                            <tr>
                                <th>Website</th>
                                <th>Anchor Text</th>
                                <th>Link</th>
                                <th>Domain Authority</th>
                            </tr>
                            </thead>
                            <tbody>
                            {guestPostsRows}
                            </tbody>
                        </Table>
                        {guestPosts && guestPosts.last_page > 1 && <UPagination currentPage={guestPosts.current_page} totalPages={guestPosts.last_page} onChange={handleNextPage} />}
                        {guestPostsRows.length === 0 && <p>Guest Posts order doesn't have any links build yet.</p>}

                        {!guestPostsSubmitted &&
                        <div role="alert" className="alert">
                            <div className="alert-icon alert-icon-top"><i className="flaticon-warning kt-font-primary"/></div>
                            <div className="alert-text">
                                Order status will be automatically changed to "In Process" when customer submit order
                                details for Guest Posts.
                            </div>
                        </div>
                        }
                        {guestPostsSubmitted && the_current_page != null ?
                            <Link to={"/admin/orders/" + order.data.id + "/guest-posts?page="+the_current_page}>
                                <Button variant={orderCompleted ? "secondary" : "primary"}>{orderCompleted ? "Order Details" : "Add Order Delivery"}</Button>
                            </Link>
                            : guestPostsSubmitted &&
                            <Link to={"/admin/orders/" + order.data.id + "/guest-posts"}>
                                <Button variant={orderCompleted ? "secondary" : "primary"}>{orderCompleted ? "Order Details" : "Add Order Delivery"}</Button>
                            </Link>
                        }
                    </PortletBody>
                </Portlet>}

                {order.data.hasSeoServices && <Portlet>
                    <PortletHeader title={"Order Progress #" + order.data.order_number + " - On-Page Optimization (per page)"} />
                    <PortletBody>
                        <div className="row">
                            <div className="col-xl-8">
                                <Table striped bordered hover responsive>
                                    <thead>
                                    <tr>
                                        <th>Url</th>
                                        <th className="text-center" style={{width:"240px"}}>Audit Details</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {seoServicesRows}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        {seoServices && seoServices.last_page > 1 && <UPagination currentPage={seoServices.current_page} totalPages={seoServices.last_page} onChange={handleNextServicesPage} />}

                        {seoServicesRows.length === 0 && <p>Customer didn't add any page urls.</p>}

                        {!seoServicesSubmitted &&
                        <div role="alert" className="alert">
                            <div className="alert-icon alert-icon-top"><i className="flaticon-warning kt-font-primary"/></div>
                            <div className="alert-text">
                                Order status will be automatically changed to "In Process" when customer submit order
                                details for the requested Seo Services.
                            </div>
                        </div>
                        }
                    </PortletBody>
                </Portlet>}

                {order.data.hasLCServices && <Portlet>
                    <PortletHeader title={"Order Progress #" + order.data.order_number + " - Link Clean-Up"} />
                    <PortletBody>
                        <div className="row">
                            <div className="col-xl-8">
                                <Table striped bordered hover responsive>
                                    <thead>
                                    <tr>
                                        <th>Url</th>
                                        <th className="text-center" style={{width:"240px"}}>Details</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {servicesRows}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        {services && services["link_clean_up"] && services["link_clean_up"].last_page > 1 && <UPagination currentPage={services["link_clean_up"].current_page} totalPages={services["link_clean_up"].last_page} onChange={handleNextLCServicesPage} />}

                        {servicesRows.length === 0 && <p>Customer didn't add any page urls.</p>}

                        {!servicesSubmitted &&
                        <div role="alert" className="alert">
                            <div className="alert-icon alert-icon-top"><i className="flaticon-warning kt-font-primary"/></div>
                            <div className="alert-text">
                                Order status will be automatically changed to "In Process" when customer submit order
                                details for the requested Seo Services.
                            </div>
                        </div>
                        }
                    </PortletBody>
                </Portlet>}

                {order.data.hasLIServices && <Portlet>
                    <PortletHeader title={"Order Progress #" + order.data.order_number + " - Link Insertions"} />
                    <PortletBody>
                        <div className="row">
                            <div className="col-xl-8">
                                <Table striped bordered hover responsive>
                                    <thead>
                                    <tr>
                                        <th>Url</th>
                                        <th className="text-center" style={{width:"240px"}}>Details</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {liServicesRows}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        {services && services["link_insertion"] && services["link_insertion"].last_page > 1 && <UPagination currentPage={services["link_insertion"].current_page} totalPages={services["link_insertion"].last_page} onChange={handleNextLIServicesPage} />}

                        {liServicesRows.length === 0 && <p>Customer didn't add any page urls.</p>}

                        {!liServicesSubmitted &&
                        <div role="alert" className="alert">
                            <div className="alert-icon alert-icon-top"><i className="flaticon-warning kt-font-primary"/></div>
                            <div className="alert-text">
                                Order status will be automatically changed to "In Process" when customer submit order
                                details for the requested Seo Services.
                            </div>
                        </div>
                        }
                    </PortletBody>
                </Portlet>}

                {order.data.hasCServices && <Portlet>
                    <PortletHeader title={"Order Progress #" + order.data.order_number + " - Citations"} />
                    <PortletBody>
                        <div className="row">
                            <div className="col-xl-8">
                                <Table striped bordered hover responsive>
                                    <tbody>
                                    {cServicesRows}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        {services && services["citation"] && services["citation"].last_page > 1 && <UPagination currentPage={services["citation"].current_page} totalPages={services["citation"].last_page} onChange={handleNextCServicesPage} />}

                        {cServicesRows.length === 0 && <p>Customer didn't add any order details.</p>}

                        {!cServicesSubmitted &&
                        <div role="alert" className="alert">
                            <div className="alert-icon alert-icon-top"><i className="flaticon-warning kt-font-primary"/></div>
                            <div className="alert-text">
                                Order status will be automatically changed to "In Process" when customer submit order
                                details for the requested Seo Services.
                            </div>
                        </div>
                        }
                    </PortletBody>
                </Portlet>}

                {order.data.hasPRServices && <Portlet>
                    <PortletHeader title={"Order Progress #" + order.data.order_number + " - Press Release"} />
                    <PortletBody>
                        <div className="row">
                            <div className="col-xl-8">
                                <Table striped bordered hover responsive>
                                    <thead>
                                    <tr>
                                        <th className="w-25">Package</th>
                                        <th>Url</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {prServicesRows}
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        {!servicesSubmitted &&
                            <div role="alert" className="alert">
                                <div className="alert-icon alert-icon-top"><i className="flaticon-warning kt-font-primary"/></div>
                                <div className="alert-text">
                                    Order status will be automatically changed to "In Process" when you submit order
                                    details for the requested Seo Services.
                                </div>
                            </div>
                        }
                        <Link to={"/admin/orders/" + order.data.id + "/service-page/press-release"}>
                            <Button variant={servicesSubmitted ? "secondary" : "primary"}>{servicesSubmitted ? "Order Details" : "Submit Order Details"}</Button>
                        </Link>

                    </PortletBody>
                </Portlet>}

                {order.data.hasGPBServices && <Portlet>
                    <PortletHeader title={"Order Progress #" + order.data.order_number + " - GPB Optimization"} />
                    <PortletBody>
                        <div className="row">
                            <div className="col-xl-8">
                                <Table striped bordered hover responsive>
                                    <thead>
                                    <tr>
                                        <th className="w-25">Package</th>
                                        <th>Url</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {gpbServicesRows}
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        {!servicesSubmitted &&
                            <div role="alert" className="alert">
                                <div className="alert-icon alert-icon-top"><i className="flaticon-warning kt-font-primary"/></div>
                                <div className="alert-text">
                                    Order status will be automatically changed to "In Process" when you submit order
                                    details for the requested Seo Services.
                                </div>
                            </div>
                        }
                        <Link to={"/admin/orders/" + order.data.id + "/service-page/gpb"}>
                            <Button variant={servicesSubmitted ? "secondary" : "primary"}>{servicesSubmitted ? "Order Details" : "Submit Order Details"}</Button>
                        </Link>

                    </PortletBody>
                </Portlet>}

                {order.data.hasAServices && <Portlet>
                    <PortletHeader title={"Order Progress #" + order.data.order_number + " - Articles"} />
                    <PortletBody>
                        <div className="row">
                            <div className="col-xl-8">
                                <Table striped bordered hover responsive>
                                    <tbody>
                                    {aServicesRows}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        {services && services["article"] && services["article"].last_page > 1 && <UPagination currentPage={services["article"].current_page} totalPages={services["article"].last_page} onChange={handleNextAServicesPage} />}

                        {aServicesRows.length === 0 && <p>Customer didn't add any order details.</p>}

                        {!aServicesSubmitted &&
                        <div role="alert" className="alert">
                            <div className="alert-icon alert-icon-top"><i className="flaticon-warning kt-font-primary"/></div>
                            <div className="alert-text">
                                Order status will be automatically changed to "In Process" when customer submit order
                                details for the requested Seo Services.
                            </div>
                        </div>
                        }
                    </PortletBody>
                </Portlet>}
            </>
        );
    }
}

const mapStateToProps = ({ ordersAdmin: { currentOrder }, guestpostsAdmin: { guestPosts }, seoservicesAdmin: { seoServices }, servicesAdmin: { services } }) => ({
    currentOrder, guestPosts, seoServices, services
});

export default injectIntl(connect(
    mapStateToProps,
    {...orders.actions, ...guestposts.actions, ...seoservices.actions, ...services.actions}
)(OrderProgressAdmin));
