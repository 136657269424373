import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getNotifications } from "../../crud/user.crud";

export const actionTypes = {
    NotificationsInit: "[Init Notifications] Action",
    NotificationsRequested: "[Request Notifications] Action",
    NotificationsLoaded: "[Load Notifications] Notifications API",
};

const initialNotificationsState = {
    notifications: undefined,
};

export const reducer = persistReducer(
    { storage, key: "demo3-notifications", whitelist: ["notifications"] },
    (state = initialNotificationsState, action) => {
        switch (action.type) {
            case actionTypes.NotificationsInit: {
                return { notifications: undefined };
            }

            case actionTypes.NotificationsLoaded: {
                const { notifications } = action.payload;

                return { ...state, notifications };
            }

            default:
                return state;
        }
    }
);

export const actions = {
    initNotifications: () => ({ type: actionTypes.NotificationsInit }),
    requestNotifications: () => ({ type: actionTypes.NotificationsRequested }),
    fulfillNotifications: notifications => ({ type: actionTypes.NotificationsLoaded, payload: { notifications } }),
};

export function* saga() {
    yield takeLatest(actionTypes.NotificationsInit, function* initNotificationsSaga(action) {
        yield put(actions.requestNotifications());
    });

    yield takeLatest(actionTypes.NotificationsRequested, function* notificationsRequested(action) {
        try {
            const {data: notifications} = yield getNotifications();

            yield put(actions.fulfillNotifications(notifications));
        }
        catch (e) {
            yield put(actions.fulfillNotifications({ data: undefined }));
        }
    });
}
