import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { getGoogleUpdates, parseAlgoroo } from "../../crud/analytics.crud";
import {Spinner} from "reactstrap";
import {Pagination, Table} from "react-bootstrap";
import {Portlet, PortletBody, PortletHeader} from "../../partials/content/Portlet";
import {Link} from "react-router-dom";
import {IconButton, Button} from '@material-ui/core';
import {Delete, Edit} from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import {Autorenew} from '@material-ui/icons';
import AnalyticsGUModal from "./AnalyticsGUModal";
import Skeleton from '@material-ui/lab/Skeleton';
import HyperText from "../home/analytics-components/HyperText";

class AnalyticsAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notesModalShow: false,
            notesModalAction: 'add',
            notes: [],
            paginatePage: 1,
            noteId: null,
            load: false,
            active: false,
        };
    }


    getGoogleUpdates = (paginate=0, page=1) => {

        this.setState({load: true});

        getGoogleUpdates(paginate, page).then((res) => {

            let result = res.data;

            if (!result) {
                this.setState({active: false, load: false, error: res.toString()});
                return false;
            }

            this.setState({
                notes: result,
                active: true,
                load: false,
                paginatePage: page
            });

        })
            .catch((e) => {
                this.setState({active: false, load: false, error: e.toString()});
                console.log('Error - GoogleUpdateList')
            });

    };



    componentDidUpdate(prevProps, prevState) {
        //
    }

    componentDidMount() {
        this.getGoogleUpdates(10, this.state.paginatePage);
    }

    render() {

        const {notesModalShow, notesModalAction, noteId, noteDate, load, active, paginatePage, notes } = this.state;

        const notesModalClose = () => {
            this.setState({ notesModalShow: false });
        };

        const notesModalOpen = (action, noteId=false) => {
            this.setState({
                notesModalShow: true,
                notesModalAction: action,
                noteId: noteId
            });
        };

        const handleDataOnModalChange = (value) => {
            this.getGoogleUpdates(10, paginatePage);
        };

        const RefreshData = () => {
            this.getGoogleUpdates(10, paginatePage);
        };


        if (load) {
            return (
                <>
                <Skeleton variant="rect" height={400}><Spinner mx="auto"/></Skeleton>
                </>
            );
        }

        if (!notes) {
            return (
                <>
                <Skeleton variant="rect" height={400}>
                    <span className="no-data">No data</span>&nbsp;
                    <IconButton onClick={RefreshData}>
                        <Autorenew />
                    </IconButton>
                </Skeleton>
                </>
            );
        }

        const rows = [];
        const paginationItems = [];


        if (notes.data) {

            if (notes.data.length) {
                for (const [index] of notes.data.entries()) {

                    rows.push(
                        <tr key={index}>
                            <td>{notes.data[index].date}</td>
                            <td>{notes.data[index].name}</td>
                            <td><HyperText>{notes.data[index].description}</HyperText></td>
                            <td>{notes.data[index].volatility}</td>
                            <td className="text-right">
                                <IconButton size='small' onClick={() => notesModalOpen('edit', notes.data[index].id)}><Edit /></IconButton>
                                <IconButton size='small' onClick={() => notesModalOpen('delete',notes.data[index].id)}><Delete /></IconButton>
                            </td>
                        </tr>
                    )
                }
            }


            for (let number = 1; number <= notes.last_page; number++) {
                paginationItems.push(
                    <Pagination.Item key={number} active={number === paginatePage} onClick={() => {
                        this.getGoogleUpdates(10, number);
                    }}>
                        {number}
                    </Pagination.Item>,
                );
            }

        }
        return (
            <Portlet>
                <PortletHeader title="Google Updates" />
                <PortletBody>
                    <div className="mb-3">
                        <Button className="pull-left invisible" variant="outlined" color="secondary" onClick={() => this.parseAlgoroo()}>
                            <GetAppIcon  fontSize="small" /> &nbsp; Parse Algoroo
                        </Button>
                        <Button className="pull-right" variant="outlined" color="primary" onClick={() => notesModalOpen('add')}>
                            <AddIcon  fontSize="small" /> &nbsp; Add GU
                        </Button>
                    </div>
                    <Table striped bordered hover responsive>
                        <thead>
                        <tr>
                            <th style={{width: '20%'}}>Date</th>
                            <th style={{width: '20%'}}>Name</th>
                            <th style={{width: '40%'}}>Update Mention</th>
                            <th style={{width: '10%'}}>Volatility</th>
                            <th style={{width: '10%'}} className="text-right">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </Table>
                    <Pagination>{paginationItems}</Pagination>
                </PortletBody>
                <AnalyticsGUModal
                    noteId = {noteId}
                    action = {notesModalAction}
                    show = {notesModalShow}
                    onHide = {notesModalClose}
                    handleDataOnModalChange = {handleDataOnModalChange}
                />
            </Portlet>

        );
    }
}


export default injectIntl(connect(
    null,
    null
)(AnalyticsAdmin));
