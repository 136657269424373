import React, { Component } from "react";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as services from "../../store/ducks/admin/services.duck";
import {Spinner} from "reactstrap";
import {Portlet, PortletBody} from "../../partials/content/Portlet";
import {Link} from "react-router-dom";
import {Breadcrumbs, Checkbox, FormControlLabel, Typography} from "@material-ui/core";
import {Button, Form} from "react-bootstrap";
import {Formik} from "formik";
import {editArticleService} from "../../crud/admin/orders.crud";

class ArticleAdmin extends Component {
    componentDidMount() {
        const { id } = this.props.match.params;
        const key = "article";
        this.props.requestService(id, key);
    }

    render() {
        const seoService = this.props.currentService ? this.props.currentService["article"] : null;
        const orderNumber = seoService ? seoService.order_number : "";

        if (!seoService) {
            return <Spinner/>;
        }
        else if(!seoService.data) {
            return (
                <>
                    <h1>Seo Service not found!</h1>
                </>
            );
        }

        return (<>
            <Portlet>
                <PortletBody>
                    <Breadcrumbs aria-label="Breadcrumb" className="kt-margin-b-15">
                        <Link color="inherit" to="/admin/orders">
                            Orders
                        </Link>
                        <Link color="inherit" to={"/admin/orders/" + seoService.data.order_id + "/progress"}>
                            Order #{orderNumber}
                        </Link>
                        <Typography color="textPrimary">Article #{seoService.data.id}</Typography>
                    </Breadcrumbs>
                    <Typography variant="h5">Article #{seoService.data.id} (Order #{orderNumber})</Typography>
                    <ul className="kt-margin-t-10 kt-margin-b-30">
                        {seoService.data.topic ? <li><strong>Topic:</strong> {seoService.data.topic}</li> : ""}
                        {seoService.data.keywords ? <li><strong>Keywords:</strong><br/>{seoService.data.keywords.replace("\n", ", ")}</li> : ""}
                        {seoService.data.notes && seoService.data.notes !== "..." ? <li><strong>Special Requests / Notes:</strong><br/>{seoService.data.notes}</li> : ""}
                        {seoService.data.logins ? <li><strong>Wordpress Logins:</strong><br/>{seoService.data.logins}</li> : ""}
                    </ul>
                    <Formik
                        initialValues={{
                            build: parseInt(seoService.data.build) === 1
                        }}
                        validate={values => {
                            const errors = {};

                            if(!seoService.data.article && !values.article) {
                                errors.article = "Please provide article file.";
                            }

                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            let formData = new FormData();
                            let aData = {
                                build: values.build ? 1 : 0
                            };
                            for (const [key] of Object.entries(aData)) {
                                formData.append(key, aData[key]);
                            }
                            if(values.article) {
                                formData.append("article", values.article);
                            }
                            editArticleService(seoService.data.id, formData)
                                .then((res) => {
                                    setStatus(
                                        "Saved."
                                    );
                                    setSubmitting(false);
                                })
                                .catch((e) => {
                                    setSubmitting(false);
                                    let errorMsg = "Error! Data is not updated.";
                                    if(e.response.data) {
                                        if(e.response.data.error) {
                                            errorMsg = e.response.data.error;
                                        }
                                    }
                                    setStatus(
                                        errorMsg
                                    );
                                });
                        }}
                    >
                        {({
                              values,
                              status,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              setFieldValue,
                          }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                {status && !isSubmitting && (
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <div role="alert" className={'alert ' + (status === 'Saved.' ? 'alert-success' : 'alert-danger')}>
                                                <div className="alert-text">{status}</div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-xl-6">
                                        <Typography>Article</Typography>
                                        <div className="kt-mt-10 kt-mb-15">
                                            {errors.article && <div className="text-danger">
                                                {errors.article}
                                            </div>}
                                            <div>{seoService.data && seoService.data.article ? seoService.data.article : ""}</div>
                                            <input type="file" name="article" onChange={(event) => {setFieldValue("article", event.currentTarget.files[0]);}} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-6">
                                        <Typography>Done</Typography>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.build}
                                                    onChange={handleChange}
                                                    name="build"
                                                    value={values.build}
                                                    color="primary"
                                                />
                                            }
                                            label=""
                                        />
                                    </div>
                                </div>
                                <Button className="kt-mr-5" type="submit" variant="primary" onClick={handleSubmit} disabled={isSubmitting}>
                                    {!isSubmitting ? 'Submit' : (<Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />)}
                                </Button>
                                <Link to={"/admin/orders/" + seoService.data.order_id + "/progress"}>
                                    <Button variant="secondary">Back</Button>
                                </Link>
                            </Form>
                        )}
                    </Formik>
                </PortletBody>
            </Portlet>
        </>);
    }
}

const mapStateToProps = ({ servicesAdmin: { currentService } }) => ({
    currentService
});

export default injectIntl(connect(
    mapStateToProps,
    services.actions
)(ArticleAdmin));
