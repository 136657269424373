import React, {Component} from "react";
import { Col, Row, } from "react-bootstrap";
import {Paper} from '@material-ui/core';

export default class VisitorsNumsDummy extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: 1431,
            data2: 1010
        };

    }

    render() {
        const {data, data2} = this.state;
        const data2Percents = (data2>0 && data>0) ?  Math.round((data-data2)/data2*100) : false;
        const resClass = (data2 < data) ? 'up' : 'down';

        return (
            <Paper elevation={0} >
                <div className="metric follower-growth">
                    <div className="metric-wrap">
                        <div className="metric-head">
                            <div className="metric-title">
                            </div>
                        </div>
                        <div className="metric-body">
                            <div className="metric-data">
                                <div className="metric-label">{data}</div>
                                <div className="metric-info"></div>
                            </div>
                            <div className="metric-description">
                                Gained visitors (last {this.props.days} days)
                            </div>
                            <Row className="growth-prev-period">
                                <Col xs={2} md={1}>
                                    {data2Percents &&
                                    <div className={resClass + " arrow"}>&#10095;</div>
                                    }
                                </Col>
                                <Col xs={10} md={11}>
                                    <div className="result-text">
                                        {data2Percents &&
                                        <>You have a <span className={resClass}>{data2Percents}% growth</span> in comparison with previous month.</>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="metric-footer">
                        </div>
                    </div>
                </div>
            </Paper>
        );
    }
}
