import React, {Component} from "react";
import {Modal, Button} from "react-bootstrap";
//import * as analytics from "../../store/ducks/analytics.duck";
import { getGoogleUpdate, addGoogleUpdate, delGoogleUpdate, updateGoogleUpdate } from "../../crud/analytics.crud";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Spinner} from "reactstrap";
import {TextField} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { Alert } from '@material-ui/lab';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

class AnalyticsGUModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateNote: new Date(),
            descriptionNote: '',
            nameNote: '',
            volatilityNote: '',
            load: false,
            active: false,
            error: false
        };
    }


    getAnalyticsNote = (id) => {

        if (!id) {
            return false;
        }

        this.setState({load: true});

        getGoogleUpdate(
            id
        )
            .then((res) => {

                let result = res.data;


                if (!result) {
                    this.setState({active: false, load: false, error: res.toString()});
                    return false;
                }

                this.setState({
                    dateNote: result.date,
                    descriptionNote: result.description,
                    nameNote: result.name,
                    volatilityNote: result.volatility,
                    active: true,
                    load: false,
                    error: false
                });

            })
            .catch((e) => {
                this.setState({active: false, load: false, error: e.toString()});
                console.log('Error - getNotes')
            });

    };



    componentDidUpdate(prevProps, prevState) {


        if (this.props.show && this.state == prevState) {
            this.getAnalyticsNote(this.props.noteId);
        }


        if (this.props.noteDate !== prevProps.noteDate) {
            if(this.props.noteDate)
                this.setState({dateNote: this.props.noteDate});
            else
                this.setState({dateNote: new Date()});
            }

    }

    componentDidMount() {

    }



    render() {

        const {show, onHide, action, noteId, noteDate} = this.props;
        const {dateNote, nameNote, descriptionNote, volatilityNote, error, load} = this.state

        const handleAddNote = () => {

             if(!dateNote){
                this.setState({error: 'Date field is empty.'});
                return;
            }
            if(!nameNote){
                this.setState({error: 'Name field is empty.'});
                return;
            }


            addGoogleUpdate({
                date: dateNote,
                name: nameNote,
                description: descriptionNote,
                volatility: volatilityNote,
            })
                .then((res) => {
                    //console.log('res', res);
                    if(res.data.id){
                        onHide();
                        console.log('Success - addNote');
                        this.props.handleDataOnModalChange(res.data.id);
                    } else {
                        this.setState({error: res.toString()});
                    }

                })
                .catch((e) => {
                    //console.log('e', e);
                    this.setState({error: e.toString()});
                    console.log('Error - addNote');
                });
        };

        const handleDeleteNote = () => {

            if(!noteId){
                this.setState({error: 'Note id is empty.'});
                return;
            }

            delGoogleUpdate(noteId)
                .then((res) => {

                    if(res){
                        onHide();
                        console.log('Success - delNote');
                        this.props.handleDataOnModalChange(noteId);
                    } else {
                        this.setState({error: res.toString()});
                    }

                })
                .catch((e) => {
                    console.log('Error - delNote');
                    this.setState({error: e.toString()});
                });
        };


        const handleUpdateNote = () => {

            if(!noteId){
                this.setState({error: 'Note id is empty.'});
                return;
            }

            if(!dateNote){
                this.setState({error: 'Date field is empty.'});
                return;
            }
            if(!nameNote){
                this.setState({error: 'Name field is empty.'});
                return;
            }


            updateGoogleUpdate(noteId, {
                id: noteId,
                date: dateNote,
                name: nameNote,
                description: descriptionNote,
                volatility: volatilityNote,
            })
                .then((res) => {
                    //console.log('res', res);

                    if(res.data.id){
                        onHide();
                        console.log('Success - updateNote');
                        this.props.handleDataOnModalChange(res.data.id);
                    } else {
                        this.setState({error: res.toString()});
                    }

                })
                .catch((e) => {
                    console.log('Error - updateNote');
                    this.setState({error: e.toString()});
                });

        };


        const handleDateChange = (dateInput) => {
            const date = new Date(dateInput);
            const formated_date = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
            this.setState({dateNote: formated_date});
        };

        const Capitalize = (str) => {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }


        return (
            <Modal
                show={show}
                onHide={this.props.onHide}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {Capitalize(action)} GU {load && ( <Spinner size="sm" /> )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                     <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            className="mb-3"
                            disabled={(action == 'delete')}
                            disableToolbar
                            required={true}
                            variant="outlined"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Date"
                            value={dateNote}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                     </MuiPickersUtilsProvider>

                    <TextField
                        disabled={(action == 'delete')}
                        fullWidth
                        required={true}
                        margin="normal"
                        label="Name"
                        value={nameNote}
                        onChange={(event) => { this.setState({nameNote: event.target.value})} }
                        variant="outlined"
                    />
                    <TextField
                        disabled={(action == 'delete')}
                        fullWidth
                        margin="normal"
                        label="Description"
                        multiline
                        rows={3}
                        value={descriptionNote}
                        onChange={(event) => { this.setState({descriptionNote: event.target.value})} }
                        variant="outlined"
                    />
                    <TextField
                        disabled={(action == 'delete')}
                        fullWidth
                        margin="normal"
                        label="Volatility"
                        value={volatilityNote}
                        onChange={(event) => { this.setState({volatilityNote: event.target.value})} }
                        variant="outlined"
                    />

                    {error && (<Alert severity="error">Error : {error}</Alert>)}
                 </Modal.Body>
                <Modal.Footer>
                    {action == 'add' && (
                    <Button size="sm" variant="primary" onClick={()=>{handleAddNote()}}>
                        Add
                    </Button>)}
                    {(action == 'edit' || action == 'manage') && (
                    <Button size="sm" variant="primary" onClick={handleUpdateNote}>
                        Save
                    </Button>)}
                    {(action == 'delete' || action == 'manage') && (
                    <Button size="sm" variant={(action == 'manage') ? 'outline-primary' : 'primary' } onClick={handleDeleteNote}>
                        Delete
                    </Button>)}
                    <Button size="sm" variant="secondary" color="primary" onClick={this.props.onHide}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}



export default injectIntl(connect(
    null,
    null
)(AnalyticsGUModal));
