import React from "react";
import {Route, Switch} from "react-router-dom";
import OnPageAudit from "./OnPageAudit";
import LinkCleanUp from "./LinkCleanUp";
import LinkInsertion from "./LinkInsertion";
import LocalLinkInsertion from "./LocalLinkInsertion";
import ForeignLinkInsertion from "./ForeignLinkInsertion";
import Citation from "./Citation";
import SeoServices from "./SeoServices";
import PressRelease from "./PressRelease";
import GPBOptimizations from "./GPBOptimizations";

export default function SeoServicesPage() {
    return (
        <Switch>
            <Route
                path="/seo-services/on-page-optimization"
                component={OnPageAudit}
            />
            <Route
                path="/seo-services/press-release"
                component={PressRelease}
            />
            <Route
                path="/seo-services/gpb-optimization"
                component={GPBOptimizations}
            />
            {/*<Route
                path="/seo-services/link-clean-up"
                component={LinkCleanUp}
            />*/}
            <Route
                path="/seo-services/link-insertion"
                component={LinkInsertion}
            />
            {/*<Route
                path="/seo-services/local-links"
                component={LocalLinkInsertion}
            />*/}
            <Route
                path="/seo-services/foreign-link-insertion"
                component={ForeignLinkInsertion}
            />
            <Route
                path="/seo-services/citation"
                component={Citation}
            />
            <Route
                path="/seo-services"
                component={SeoServices}
            />
        </Switch>
    );
}
