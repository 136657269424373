import 'date-fns';
import React from 'react';
import {Grid} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';


export default function DateRangePickers(props) {

    if(props.dateRange){
        var fields = props.dateRange.split(',');

        var dateFrom = fields[0].trim();
        var dateTo = fields[1].trim();
    } else {
        var dateFrom = false;
        var dateTo = false;
    }


    const [selectedDateFrom, setSelectedDateFrom] = React.useState( (dateFrom) ? new Date(dateFrom) : new Date());
    const [selectedDateTo, setSelectedDateTo] = React.useState((dateTo) ? new Date(dateTo) : new Date());

    function handleDateChangeFrom(date) {
        setSelectedDateFrom(date);
        onRangeChange(date, selectedDateTo);
    }
    function handleDateChangeTo(date) {
        setSelectedDateTo(date);
        onRangeChange(selectedDateFrom, date);
    }

    function onRangeChange(dateFrom, dateTo) {

        var date = new Date(dateFrom);
        var formated_dateFrom = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        date = new Date(dateTo);
        var formated_dateTo = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);

        props.handleDateRangeChange(formated_dateFrom + ',' + formated_dateTo);
    }


    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container className="pl-3 pr-3" justify="space-around">
                <KeyboardDatePicker
                    margin="normal"
                    id="mui-pickers-dateFrom"
                    label="From"
                    minDate={new Date().setYear(new Date().getFullYear()-2)}
                    disableFuture="true"
                    value={selectedDateFrom}
                    onChange={handleDateChangeFrom}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                <KeyboardDatePicker
                    margin="normal"
                    id="mui-pickers-dateTo"
                    label="To"
                    disableFuture="true"
                    minDate={new Date().setYear(new Date().getFullYear()-2)}
                    value={selectedDateTo}
                    onChange={handleDateChangeTo}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </Grid>
        </MuiPickersUtilsProvider>
    );
}