import React, {useRef} from "react";
import OrdersList from "./orders/OrdersList";
import AuditSummary from "./onpage-audit/AuditSummary";
import MarketplaceWidget from "../../widgets/MarketplaceWidget";
import {Portlet, PortletBody} from "../../partials/content/Portlet";
import {Alert, Col, Row, Tab, TabPane, Tabs} from "react-bootstrap";
import VisitorsNums from "./analytics-components/metrics/VisitorsNums";
import VisitorsCharts from "./analytics-components/metrics/VisitorsCharts";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as projects from "../../store/ducks/projects.duck";
import * as analytics from "../../store/ducks/analytics.duck";
import {Spinner} from "reactstrap";
import NotesList from "./analytics-components/NotesList";
import GoogleUpdateList from "./analytics-components/GoogleUpdateList";
import VisitorsNumsDummy from "./analytics-components/dummy-data/VisitorsNumsDummy";
import VisitorsChartsDummy from "./analytics-components/dummy-data/VisitorsChartsDummy";
import {Link, Redirect} from "react-router-dom";
import { useHistory } from 'react-router-dom';
import "../../../_metronic/_assets/sass/custom/dashboard.scss";
import { useEffect } from 'react';
import * as profile from "../../store/ducks/profile.duck";
//import introJs from 'intro.js';
//import "intro.js/introjs.css";
//import {saveUserOptions} from "../../crud/user.crud";

function Dashboard(props) {
  const {currentProject, currentProjectId, projects, userOptions} = props;
  //const showIntro = !!(userOptions && userOptions["show_intro_dashboard"] && parseInt(userOptions["show_intro_dashboard"]) === 1);

  //const [stepsEnabled, setStepsEnabled] = React.useState(false);
  //const [initialStep] = React.useState(0);
  /*const [steps] = React.useState([
      {
          intro: "Welcome to the SERP Pro. Get a quick tour of our platform by clicking next or skip it and do the tour at a later time."
      },
      {
          selector: "#kt-aside",
          title: "Menu",
          element: document.querySelectorAll('#kt-aside')[0],
          intro: "This is your navigation menu. You can view your orders, use our tools and SEO services by clicking on any of the menu items."
      },
      {
          selector: "#traffic-analytics-widget",
          title: "Traffic Analytics",
          element: document.querySelectorAll('#traffic-analytics-widget')[0],
          intro: "Track your organic growth with our traffic analytics module. Use our filters to monitor Google Updates or impact of your SEO tasks."
      },
      {
          selector: "#my-orders-widget",
          title: "My Orders",
          element: document.querySelectorAll('#my-orders-widget')[0],
          intro: "Quickly view your orders, invoices and keep track of your order progress."
      },
      {
          selector: "#tm-gu-widget",
          element: document.querySelectorAll('#tm-gu-widget')[0],
          intro: "Keep a log of your tasks to gain oversight on your campaigns. You can also monitor for upcoming Google updates and algorithm changes."
      },
      {
          selector: "#onpage-audit-widget",
          title: "Technical SEO Audit",
          element: document.querySelectorAll('#onpage-audit-widget')[0],
          intro: "Start scanning your website for technical SEO errors."
      },
  ]);*/

  /*const redirectToAnalyticsPage = (e) => {
        props.history.push({
            pathname: "/analytics"
        });
  };*/

  useEffect(() => {
      if (currentProject && currentProject.data){
          if(props.currentProject.data.id) {
              //props.requestAnalyticsNotes(props.currentProject.data.id);
              //props.requestAnalyticsGoogleUpdates(props.currentProject.data.id);
          }
      }
      else {
          if(currentProjectId > 0) {
              props.requestProject(currentProjectId);
          }
      }
  });
  /*useEffect(() => {
      props.requestUserOptions();
  }, []);*/
  /*useEffect(() => {
      if(showIntro && !stepsEnabled) {
          try {
              const intro = introJs();
              //intro.exit();
              window.setTimeout(()=> {
                  intro.onbeforechange(function (targetElement) {
                      if (intro._options.steps.length !== 0) {
                          for (let i = 0; i < intro._options.steps.length; i++) {
                              const selector = intro._options.steps[i].selector;
                              if(selector) {
                                  const element = document.querySelectorAll(selector)[0];

                                  if (element) {
                                      intro._introItems[i].element = element;
                                      intro._introItems[i].position = intro._options.steps[i].position || 'auto';
                                  }
                              }
                          }
                      }
                  }).oncomplete(function () {
                      const data = {
                          options: [
                              {option: "show_intro_dashboard", value: 0}
                          ],
                      };
                      saveUserOptions(data)
                          .then(() => {
                              props.fulfillUserOptions(data);
                          })
                          .catch(() => {
                              //
                          });
                  }).setOptions({
                      'showBullets': false,
                      'skipLabel': "Skip",
                  }).addSteps(steps);
                  intro.start();
              }, 1000);
          } catch (e) {
              console.log(e);
          }
          setStepsEnabled(showIntro);
      }
  }, [showIntro]);*/

  if(projects && projects.meta && projects.meta.total === 0) {
      return <Redirect to="/project/add" />;
  }

  /*const onExit = () => {
      setStepsEnabled(false);
  };*/

  return (
    <>
        {/*<Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={initialStep}
          onExit={onExit}
          options={{
              skipLabel:"Skip",
              //hidePrev: true,
              //showStepNumbers: false,
              //showBullets: false,
          }}
          onComplete={()=>{
              const data = {
                  options: [
                      {option: "show_intro_dashboard", value: 0}
                  ],
              };
              saveUserOptions(data)
                  .then(() => {
                      props.fulfillUserOptions(data);
                  })
                  .catch(() => {
                      //
                  });
          }}
      />*/}

      <div className="row row-full-height">
          {/*<div className="col-xxl-8 col-xl-7 col-lg-12">
            <Portlet id="traffic-analytics-widget" className="kt-portlet--height-fluid kt-portlet--border-bottom-brand">
                <PortletBody>
                    {currentProject && currentProject.data && !currentProject.data.tracking_code_added && <Alert variant={"warning"}>This is demo data.{' '}
                    <Link style={{display:"contents"}} to={"/project/create?step=2"}>Add the tracking code</Link> to enable traffic analytics.</Alert>}
                    {!currentProjectId && <Alert variant={"warning"}>This is demo data.{' '}
                        <Link style={{display:"contents"}} to={"/project/create"}>Add new project</Link> and the tracking code to enable traffic analytics.</Alert>}
                    <Row>
                        <Col xs={12} md={3}>
                            <h3 style={{fontSize:"1.2rem"}}>Organic Visitors</h3><br />
                            {!currentProject && currentProjectId > 0 && <Spinner />}
                            {!currentProjectId || (currentProject && currentProject.data && !currentProject.data.tracking_code_added) ? <VisitorsNumsDummy days='20' /> : ""}
                            {currentProjectId > 0 && currentProject && currentProject.data && currentProject.data.tracking_code_added && <VisitorsNums
                                currentProject={currentProject.data}
                                days='20'
                                showMoreDataLink={true}
                            />}
                        </Col>
                        <Col xs={12} md={9}>
                            {!currentProject && currentProjectId > 0 && <Spinner />}
                            {!currentProjectId || (currentProject && currentProject.data && !currentProject.data.tracking_code_added) ? <VisitorsChartsDummy
                                date='last-7-days'
                                width="100%"
                                height="350"
                            /> : ""}
                            {currentProjectId > 0 &&currentProject && currentProject.data && currentProject.data.tracking_code_added && <VisitorsCharts
                                date='last-7-days'
                                width="100%"
                                height="350"
                            />}
                        </Col>
                    </Row>
                </PortletBody>
            </Portlet>
        </div>*/}
          {/*<div className="col-xxl-4 col-xl-5 col-lg-12">
            <Portlet id="tm-gu-widget" className="kt-portlet--height-fluid kt-portlet--border-bottom-brand">
                <PortletBody>
                    <Tabs defaultActiveKey="taskManager" id="da-tabs">
                        <Tab eventKey="taskManager" title="Task Manager"/>
                        <Tab eventKey="googleUpdates" title="Google Updates"/>

                        <TabPane eventKey="taskManager" className="notes-list">
                            {!currentProject && currentProjectId > 0 && <Spinner />}
                            {currentProject && currentProject.data && <NotesList />}
                        </TabPane>
                        <TabPane eventKey="googleUpdates">
                            <GoogleUpdateList />
                        </TabPane>
                    </Tabs>
                </PortletBody>
            </Portlet>
        </div>*/}
        <div className="col-xl-10 col-lg-10">
            <div id="my-orders-widget"><OrdersList showProjectButtons={true} /></div>
        </div>
          {/*<div className="col-xxl-4 col-xl-5 col-lg-12">
            <div id="onpage-audit-widget"><AuditSummary loadMainWidgets={true} /></div>
        </div>*/}
      </div>
    </>
  );
}

const mapStateToProps = ({ projects: { currentProjectId, currentProject, projects }, profile: { userOptions } }) => ({
    currentProjectId, currentProject, projects, userOptions
});

export default injectIntl(
    connect(
        mapStateToProps,
        {...projects.actions, ...analytics.actions, ...profile.actions}
    )(Dashboard)
);
