import React from "react";
import {Button} from "react-bootstrap";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as projects from "../../../store/ducks/projects.duck";
import {deleteProject} from "../../../crud/projects.crud";

class DeleteProject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {disabled:false};
    }

    render() {
        const {currentProjectId} = this.props;

        const handleDelete = () => {
            const conf = window.confirm("Do you really want to delete this project?");
            if(currentProjectId > 0 && conf) {
                this.setState({disabled:true});
                deleteProject(currentProjectId).then(() => {
                    this.props.reloadProjects();
                }).catch(e => {
                    this.setState({disabled:false});
                });
            }
        };

        return (currentProjectId > 0 ?
                <Button
                    className="kt-mb-5 kt-ml-5 kt-mr-5"
                    variant="danger"
                    onClick={handleDelete}
                    disabled={this.state.disabled}
                >
                    Delete Project
                </Button> :
                <></>
        );
    }
}

const mapStateToProps = ({ projects: { currentProjectId } }) => ({
    currentProjectId
});

export default injectIntl(connect(
    mapStateToProps,
    projects.actions
)(DeleteProject));
