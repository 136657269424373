import React, {Component} from "react";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as auth from "../../store/ducks/auth.duck";
import {Spinner} from "reactstrap";
import {instant} from "../../crud/auth.crud";
import {Redirect} from "react-router-dom";
import {getQueryParam} from "../../custom_functions/get_query_param";

import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';

class Instant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false
        };
    }

    componentDidMount() {
        let email = this.props.match && this.props.match.params && this.props.match.params.email ? decodeURIComponent(this.props.match.params.email) : "";
        if(!email) {
            email = getQueryParam(window.location.search,"email");
        }
        if(email) {
            instant(email).then((res) => {

                ReactGA.event({
                    category: 'User',
                    action: 'sign_up',
                    label: 'New Sign Up',
                    nonInteraction: false
                });
                ReactPixel.track("sign_up", {value: email});
                // ReactPixel.track("sign_up", email);
                // ReactPixel.track("Lead");

                if(res && res.data) {
                    this.props.login(res.data.access_token, res.data.roles);
                }
                else {
                    this.setState({error:"empty response"});
                }
            }).catch((e) => {
                this.setState({error:"bad request"});
            });
        }
    }

    render() {
        if(this.state.error) {
            return <Redirect to="/auth/login" />;
        }

        return (<>
            <div className="kt-login__body">
                <div className="kt-login__form">
                    <div className="kt-login__title">
                        <h3>Instant Registration</h3>
                    </div>
                    <div className="kt-mb-30 text-center">
                        <p>Please wait...</p>
                        <Spinner/>
                    </div>
                </div>
            </div>
        </>);
    }
}

export default injectIntl(
    connect(
        null,
        auth.actions
    )(Instant)
);
