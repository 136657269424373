import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import GuestPosts from "./GuestPosts";

export default function MarketplacePage() {
    return (
        <Switch>
            <Route
                path="/marketplace"
                component={GuestPosts}
            />
            <Redirect to="/marketplace" />
        </Switch>
    );
}
