import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as projects from "../../../store/ducks/projects.duck";
import {Form, Button} from "react-bootstrap";
import {Spinner} from "reactstrap";
import { updateOrderProject } from "../../../crud/orders.crud";
// import Select from "react-select";
// import * as marketplace from "../../../store/ducks/marketplace.duck";

class EditOrderProject extends React.Component {
    constructor(props) {
        super(props);

        const {orderNumber,orderId,orderProject} = this.props;
        this.state = {
            orderNumber: orderNumber,
            orderId: orderId,
            orderProjectInitial: orderProject,
            orderProject: orderProject,
            selectTopOffset: 0,
            initial_load: true,
            show_error:false,
            error_message: 'Something went wrong.',
            show_success:false
        }
    }

    componentDidMount() {
        this.props.initProjects();
  	}

    showModal() {
        this.setState({
            ...this.state,
            initial_load: true,
            show_error:false,
            show_success:false,
            selectTopOffset: document.getElementById("editOrderProjectModalDialogContent").offsetTop+300+80-10
        });
    }

    render() {
    	const { open, onClose, projects, orderProject, orderNumber, orderId, selectTopOffset } = this.props;

        if (!projects) {
            return <Spinner/>;
        }

        let selectStyles = {
	        menu: styles => ({ 
                ...styles, 
                zIndex:10000,
                overflow:'visible',
                position:'fixed',
                maxWidth:'500px',
                height:'300px',
                top: selectTopOffset
            }),
	        control: (provided, state) => ({
	            ...provided,
	            minHeight: '47px',
	        }),
	    };

        const rows = [];
        // rows.push(<option key='all' value={'all'}>All Projects</option>);
        rows.push(<option key={'null'} value={'null'}>Unassigned</option>);
        if (projects.data) {
            if (projects.data.length) {
                for (const [index] of projects.data.entries()) {
                    rows.push(<option key={index} value={projects.data[index].id}>{projects.data[index].url}</option>)
                }
            }
        }

        const handleProjectChange = (e) => {
            const projectId = e.target.value;
            this.setState({
                ...this.state,
                orderProject: projectId,
                initial_load:false,
                show_error:false,
                show_success:false,
            });
        };

        const handleClose = () => {
        	// setBlogCategoryIdRaw(0);
            this.setState({
                ...this.state,
                initial_load: true,
                show_error:false,
                show_success:false,
            });
            onClose();
        };

        const handleEditOrder = () => {
        	this.setState({
                ...this.state,
        		sending: true
        	});

        	updateOrderProject(
                'project_update',
                orderId,
                orderNumber,
                this.state.orderProject
            )
            .then((res) => {
                this.setState({
	                ...this.state,
	        		sending: false,
                    show_success:true
	        	});
                document.getElementById('order_project_text_'+res.data.orderId).innerHTML = res.data.projectText;
	        	// onClose();
            })
            .catch(e => {
            	// console.log("catch = " + e);
                let error_message = 'Something went wrong.';
                if(e && e.response && e.response.data) {
                    error_message = e.response.data.message
                }
            	this.setState({
	                ...this.state,
	        		sending: false,
                    show_error:true,
                    error_message:error_message
	        	});
                
            });
        }

        let selected_project_holder = '';
        if (this.state.initial_load == true) {
            selected_project_holder = orderProject;
        } else {
            selected_project_holder = this.state.orderProject;
        }

        return (<Dialog open={open} onClose={handleClose} fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle>Edit Order Project</DialogTitle>
            <DialogContent dividers id="editOrderProjectModalDialogContent">
                <div className="form-group mb-3">
                  	<label className="MuiFormLabel-root">Select Project</label>
                    <Form.Control as="select" value={selected_project_holder} onChange={handleProjectChange}>
                        {rows}
                    </Form.Control>
                </div>
                {this.state.show_success ?
                    <div class="alert alert-success mb-0">Successfully saved.</div>
                    :
                    ''
                }
                {this.state.show_error ?
                    <div class="alert alert-danger mb-0">{this.state.error_message}</div>
                    :
                    ''
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
                 <Button
                    variant="success"
                    onClick={handleEditOrder}
                    disabled={this.state.initial_load == true}
                >
                    {this.state.sending ? "Sending.." : "Update Order"}
                </Button>
            </DialogActions>
        </Dialog>);
    }
}

const mapStateToProps = ({ projects: { projects } }) => ({
    projects
});

export default injectIntl(connect(
    mapStateToProps,
    {...projects.actions}
)(EditOrderProject));
