export default {
  header: {
    self: {},
    items: [

    ]
  },
  aside: {
    self: {},
    items: [
        {
            title: "Orders",
            root: true,
            page: "orders",
            bullet: "dot"
        },
        {
            title: "Offers",
            root: true,
            page: "admin/offers",
            bullet: "dot"
        },
        {
            title: "Blogs",
            root: true,
            page: "admin/blogs",
            bullet: "dot"
        },
        {
            title: "Writers",
            root: true,
            page: "admin/writers",
            bullet: "dot"
        },
        {
            title: "Financial Overview",
            root: true,
            page: "admin/financials",
            bullet: "dot"
        }
    ]
  }
};
