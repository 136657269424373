import React, { Component } from "react";
import Deals from "./Deals";
import ContentCreationInfo from "./faq/ContentCreationInfo";

export default class Article extends Component {
    render() {
        const faq = <ContentCreationInfo />;

        return (<>
            <Deals type="article_service_package" key="as" description="Content Creation" title="Choose A Content Creation Package to Get Started" faq={faq} />
        </>);
    }
}
