import React, { Component } from "react";
import {Portlet, PortletBody, PortletHeader} from "../../../partials/content/Portlet";
import {
    Checkbox,
    FormControl,
    FormControlLabel, FormLabel,
    List,
    ListItem,
    ListItemText, Radio, RadioGroup,
    Slider, Step, StepLabel, Stepper,
    Typography
} from "@material-ui/core";
import {Button, Card} from "react-bootstrap";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as cart from "../../../store/ducks/cart.duck";
import ReactGA from "react-ga4";
import * as pagedata from "../../../store/ducks/page.duck";
import * as packages from "../../../store/ducks/packages.duck";
import {Spinner} from "reactstrap";
import {calculateCartDiscountAmount} from "../../../custom_functions/guest_posts";
import { Link } from 'react-router-dom';
import GuestPostsInfo from "../../../includes/GuestPostsInfo";

//const auditPrice = 150;
//const implementationPrice = 50;

const minQ = 1;
const maxQ = 100;
const stepQ = 1;
const marksQ = [
    {
        value: minQ,
        label: minQ.toString(),
    },
    {
        value: maxQ,
        label: maxQ.toString(),
    },
];
function valuetextQ(value) {
    return `${value} page${value !== 1 ? 's' : ''}`;
}

class OnPageAudit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            implementation: false,
            valueQ: 1,
            valueP: "purchase"
        };
    }

    componentDidMount() {
        const pageName = 'on-page';
        const type = 'price_package_audit';
        this.props.requestPageData(pageName);
        this.props.requestUserDiscount();
        this.props.requestPackage(type);
        this.props.requestPackagePreimumMembership("marketplace_guest_post_access");
    }

    render() {
        const {implementation, valueQ, valueP} = this.state;
        const {pageData, userDiscountPercent: userDiscount, currentPackage} = this.props;

        if(!currentPackage) {
            return <Spinner />;
        }
        else if(currentPackage.currentPackage && !currentPackage.currentPackage.type) {
            return <h1>Package not found!</h1>;
        }

        let premiumMembershipPackage = undefined;
        if (currentPackage.premiumMembershipPackage) {
            premiumMembershipPackage = currentPackage.premiumMembershipPackage;
        }

        let isPremiumMember = false;
        let currentPremiumLevel = "Free";

        if (typeof premiumMembershipPackage !== 'undefined') {
            // console.log(premiumMembershipPackage);
            if (typeof premiumMembershipPackage.activePackage !== 'undefined') {
                // console.log(premiumMembershipPackage.activePackage);
                // console.log(premiumMembershipPackage.activePackage.name);
                if (premiumMembershipPackage.activePackage.name != "Free") {
                    isPremiumMember = true;
                    currentPremiumLevel = premiumMembershipPackage.activePackage.name;
                }
            }
        }

        const auditPrice = currentPackage.currentPackage && currentPackage.currentPackage.per_page ? currentPackage.currentPackage.per_page : 0;
        const implementationPrice = currentPackage.currentPackage && currentPackage.currentPackage.implementation ? currentPackage.currentPackage.implementation : 0;

        const handleImplementationChange = (event) => {
            this.setState({implementation: event.target.checked});
        };

        const handleSliderChangeQ = (event, newValue) => {
            this.setState({valueQ: newValue});
        };

        const handleChangeP = (event) => {
            this.setState({valueP: event.target.value});
        };

        const calcPrice = () => {
            let price = 0;
            let itemPrice = auditPrice;
            let discountAmount = 0;

            if(this.state.implementation) {
                itemPrice += implementationPrice;
            }

            price = itemPrice * valueQ;

            const item = {
                orderData: {
                    subscription: 0,
                    quantity: valueQ,
                    type: "audit_per_page",
                    options: {
                        audit_implementation: implementation ? "yes" : "no"
                    },
                },
                price: itemPrice
            };

            discountAmount = calculateCartDiscountAmount([item], userDiscount);
            price -= discountAmount;

            return {itemPrice: itemPrice, calculatedPrice: price, discountAmount: discountAmount};
        };

        const {itemPrice, calculatedPrice, discountAmount} = calcPrice();

        let maxDiscountPercent = 0.15;
        let maxDiscountAmount = maxDiscountPercent * calculatedPrice;
        let maxPremiumPrice = calculatedPrice - maxDiscountAmount;

        const addToCart = (orderNow = false) => {
            let orderData = {};
            orderData[0] = {
                subscription: 0,
                quantity: valueQ,
                type: "audit_per_page",
                options: {
                    audit_implementation: implementation ? "yes" : "no"
                },
            };

            const key = (implementation ? "i" : "") + (valueP === "subscription" ? "s" : "");
            const item = {
                key: "a_" + key,
                price: itemPrice.toFixed(2),
                name: "On-Page Optimization (per page)",
                description: implementation ? "Implementation" : "",
                orderData: orderData[0]
            };

            this.props.addToCart(item);

            ReactGA.event({
                category: 'Cart',
                action: 'add_to_cart',
                label: 'Add to Cart',
                nonInteraction: false
            });

            if(orderNow) {
                //this.props.hideCart();
                this.props.openCheckout();
            }
        };

        return (
            <>
                <div className="row pt-2" style={{marginLeft:0,marginRight:0}}>
                    <div className="col-lg-6 col-md-9" style={{paddingLeft:0,paddingRight:0}}>
                        <Portlet>
                            <PortletBody className="mw-position-orders-area-wide" style={{padding:"40px"}}>
                                <h4 className="mb-3">Get Started with On-Page Optimization</h4>
                                <p dangerouslySetInnerHTML={{ __html: pageData ? pageData.top_text : "" }} />
                                <h5 className="mb-0 mt-2">How it works</h5>
                                <Stepper orientation="vertical">
                                    <Step active={true} key="step1">
                                        <StepLabel>
                                            <h6 className="mt-2 ml-2">Place Order to Get Started</h6>
                                        </StepLabel>
                                    </Step>
                                    <Step active={true} key="step2">
                                        <StepLabel>
                                            <h6 className="mt-2 ml-2">Submit URL, Keyword & Login After Purchase</h6>
                                        </StepLabel>
                                    </Step>
                                    <Step active={true} key="step3">
                                        <StepLabel>
                                            <h6 className="mt-2 ml-2">Start On-Page Audit & Optimization</h6>
                                        </StepLabel>
                                    </Step>
                                    <Step active={true} key="step4">
                                        <StepLabel>
                                            <h6 className="mt-2 ml-2">Get Optimized Within 15 Days</h6>
                                        </StepLabel>
                                    </Step>
                                </Stepper>
                                <div className="row mt-2">
                                    <div className="col-12 text-center mt-4">
                                        <FormControl component="fieldset" className="kt-margin-b-15">
                                            <Typography className="font-weight-bold" gutterBottom>Choose Your Plan</Typography>
                                            <RadioGroup
                                                aria-label="plan"
                                                name="payment_type"
                                                value={valueP}
                                                onChange={handleChangeP}
                                            >
                                                <FormControlLabel
                                                    value="purchase"
                                                    control={<Radio color="primary" />}
                                                    label="One-time purchase"
                                                    className="kt-margin-b-0"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center mt-4 mw-position-orders-area">
                                        <Typography className="font-weight-bold">Implementation</Typography>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={implementation}
                                                    onChange={handleImplementationChange}
                                                    value="audit_implementation"
                                                    color="primary"
                                                />
                                            }
                                            label={"I want done-for-you implementation of all guidelines. (+$" + implementationPrice.toFixed(2) + ")"}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center mt-4 mw-position-orders-area">
                                        <Typography className="font-weight-bold" id="post-quantity-label" gutterBottom>
                                            Quantity
                                        </Typography>
                                        {/* <p>
                                            Choose the number of pages.
                                        </p> */}
                                        <div className="text-right"><strong style={{fontSize:"15px"}}>{valueQ}</strong> page{valueQ !== 1 ? 's' : ''}</div>
                                        <Slider
                                            defaultValue={minQ}
                                            getAriaValueText={valuetextQ}
                                            aria-labelledby="page-quantity-label"
                                            valueLabelDisplay="auto"
                                            step={stepQ}
                                            marks={marksQ}
                                            min={minQ}
                                            max={maxQ}
                                            onChange={handleSliderChangeQ}
                                        />
                                    </div>
                                </div>
                            </PortletBody>
                        </Portlet>
                    </div>
                    <div class="col-lg-3 col-md-3 pl-4">
                        <Card style={{maxWidth:"250px",padding:"10px"}}>
                            <Card.Body>
                                <Card.Title className="mb-4">You've selected</Card.Title>
                                <strong>On-Page Optimization (per page)</strong>
                                <List>
                                    {implementation && <ListItem>
                                        <ListItemText
                                            primary={"Implementation - Yes, I want done-for-you implementation of all guidelines."}
                                        />
                                    </ListItem>}
                                    <ListItem>
                                        <ListItemText
                                            primary={valuetextQ(valueQ)}
                                        />
                                    </ListItem>
                                </List>
                                <hr/>
                                {/* {discountAmount > 0 && (<div><Typography>Normal Price</Typography>
                                    <Typography variant="h5">${(itemPrice * valueQ).toFixed(2)}</Typography></div>)} */}
                                {/*<div style={{display:(discountAmount > 0 ? "block" : "none")}}>
                                        <Typography>Discount</Typography>
                                        <Typography variant="h6">-${discountAmount.toFixed(2)}</Typography>
                                    </div>*/}
                                {/* {discountAmount > 0 ? <Typography className="kt-font-danger">Premium Member Price</Typography> : <Typography>Total Cost</Typography>}
                                <Typography variant="h5" className={discountAmount > 0 ? "kt-font-danger" : ""}>${calculatedPrice.toFixed(2)}</Typography> */}

                                {/* {! isPremiumMember && discountAmount == 0 ? <Typography className="kt-font-danger">Premium Member Price</Typography> : ""}
                                {! isPremiumMember && discountAmount == 0 ? <Typography variant="h5" className={maxPremiumPrice > 0 ? "kt-font-danger" : ""}>${maxPremiumPrice.toFixed(2)}</Typography> : ""}
                                {! isPremiumMember && discountAmount == 0 ? <Link className="d-block" to="/packages/marketplace-access">Upgrade to Premium</Link> : ""} */}

                                {isPremiumMember && discountAmount > 0 ? <Typography>Your Investment</Typography> : <Typography>Your Investment</Typography>}
                                <Typography variant="h5">${calculatedPrice.toFixed(2)}</Typography>

                                <div className="kt-margin-b-15" />
                                {/* <Button className="btn-success kt-mb-10" style={{minWidth:"170px"}} size="lg" variant="primary" onClick={() => {
                                    addToCart(true);
                                }} disabled={calculatedPrice === 0}>Checkout</Button> */}
                                <Button onClick={() => {
                                    addToCart();
                                }} style={{minWidth:"170px"}} size="lg" disabled={calculatedPrice === 0}>Add to Cart</Button>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                
                {/* <GuestPostsInfo /> */}
            </>
        );
    }
}

const mapStateToProps = ({ pagedata: {pageData}, cart: {userDiscountPercent}, packages: currentPackage }) => ({
    pageData, currentPackage, userDiscountPercent
});

export default injectIntl(connect(
    mapStateToProps,
    {...cart.actions, ...pagedata.actions, ...packages.actions}
)(OnPageAudit));
