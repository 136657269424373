import React from "react";
import { connect } from "react-redux";
import * as builder from "../../ducks/builder";

class Footer extends React.Component {
  render() {
    const today = new Date().getFullYear();
    return (
      <div
        className={`kt-footer ${this.props.footerClasses} kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop`}
        id="kt_footer"
      >
        <div className={`kt-container ${this.props.footerContainerClasses}`}>
          <div className="kt-footer__copyright">
            {today.toString()}&nbsp;&copy;&nbsp;
            SERP&nbsp;Pro
          </div>
          <div className="ml-auto" style={{position:"relative", top:"-11px"}}>
            <img className="mr-3" src="https://serppro.nyc3.cdn.digitaloceanspaces.com/public/stripe-blue.png" alt="" style={{maxWidth:"120px"}} />
            <img className="" src="https://serppro.nyc3.cdn.digitaloceanspaces.com/public/paypal-blue.png" alt="" style={{maxWidth:"120px"}} />
          </div>
          <div className="kt-footer__menu pl-0">
            <a
              href="https://serppro.io/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="kt-footer__menu-link kt-link"
            >
              Privacy Policy
            </a>
            <a
              href="https://www.serppro.io/terms-conditions"
              target="_blank"
              rel="noopener noreferrer"
              className="kt-footer__menu-link kt-link"
            >
              Terms and Conditions
            </a>
            <a
              href="https://www.serppro.io/cookie-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="kt-footer__menu-link kt-link"
            >
              Cookie Policy
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  footerClasses: builder.selectors.getClasses(store, {
    path: "footer",
    toString: true
  }),
  footerContainerClasses: builder.selectors.getClasses(store, {
    path: "footer_container",
    toString: true
  })
});

export default connect(mapStateToProps)(Footer);
