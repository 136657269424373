import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup, Typography, Checkbox} from "@material-ui/core";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import * as profile from "../../../store/ducks/profile.duck";
import * as marketplace from "../../../store/ducks/marketplace.duck";
import {connect} from "react-redux";
import {Form, Button, ButtonToolbar} from "react-bootstrap";
import {Spinner} from "reactstrap";
import {addOrder, calculateVat, stripePay, validateVat, addDeposit, stripeInitCheckoutSession, paypalInitCheckout} from "../../../crud/orders.crud";
import {editBillingInfo} from "../../../crud/user.crud";
import Select from "react-select";
import { useState } from 'react'; 

import { Formik } from "formik";
import { PayPalButton } from "react-paypal-button-v2";
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, ElementsConsumer} from "@stripe/react-stripe-js";

// import ReactGA from 'react-ga4';
// import ReactPixel from 'react-facebook-pixel';

const stripePromise = loadStripe(window.stripePublicKey ? window.stripePublicKey : 'pk_live_nO3uxbbzQCnrcERWxIS5i43t');
// Country list
const countryList = {
    'AF':'Afghanistan',
    'AX':'Åland Islands',
    'AL':'Albania',
    'DZ':'Algeria',
    'AS':'American Samoa',
    'AD':'Andorra',
    'AO':'Angola',
    'AI':'Anguilla',
    'AQ':'Antarctica',
    'AG':'Antigua and Barbuda',
    'AR':'Argentina',
    'AM':'Armenia',
    'AW':'Aruba',
    'AU':'Australia',
    'AT':'Austria',
    'AZ':'Azerbaijan',
    'BS':'Bahamas',
    'BH':'Bahrain',
    'BD':'Bangladesh',
    'BB':'Barbados',
    'BY':'Belarus',
    'BE':'Belgium',
    'PW':'Belau',
    'BZ':'Belize',
    'BJ':'Benin',
    'BM':'Bermuda',
    'BT':'Bhutan',
    'BO':'Bolivia',
    'BQ':'Bonaire, Saint Eustatius and Saba',
    'BA':'Bosnia and Herzegovina',
    'BW':'Botswana',
    'BV':'Bouvet Island',
    'BR':'Brazil',
    'IO':'British Indian Ocean Territory',
    'BN':'Brunei',
    'BG':'Bulgaria',
    'BF':'Burkina Faso',
    'BI':'Burundi',
    'KH':'Cambodia',
    'CM':'Cameroon',
    'CA':'Canada',
    'CV':'Cape Verde',
    'KY':'Cayman Islands',
    'CF':'Central African Republic',
    'TD':'Chad',
    'CL':'Chile',
    'CN':'China',
    'CX':'Christmas Island',
    'CC':'Cocos (Keeling) Islands',
    'CO':'Colombia',
    'KM':'Comoros',
    'CG':'Congo (Brazzaville)',
    'CD':'Congo (Kinshasa)',
    'CK':'Cook Islands',
    'CR':'Costa Rica',
    'HR':'Croatia',
    'CU':'Cuba',
    'CW':'Cura&ccedil;ao',
    'CY':'Cyprus',
    'CZ':'Czech Republic',
    'DK':'Denmark',
    'DJ':'Djibouti',
    'DM':'Dominica',
    'DO':'Dominican Republic',
    'EC':'Ecuador',
    'EG':'Egypt',
    'SV':'El Salvador',
    'GQ':'Equatorial Guinea',
    'ER':'Eritrea',
    'EE':'Estonia',
    'ET':'Ethiopia',
    'FK':'Falkland Islands',
    'FO':'Faroe Islands',
    'FJ':'Fiji',
    'FI':'Finland',
    'FR':'France',
    'GF':'French Guiana',
    'PF':'French Polynesia',
    'TF':'French Southern Territories',
    'GA':'Gabon',
    'GM':'Gambia',
    'GE':'Georgia',
    'DE':'Germany',
    'GH':'Ghana',
    'GI':'Gibraltar',
    'GR':'Greece',
    'GL':'Greenland',
    'GD':'Grenada',
    'GP':'Guadeloupe',
    'GU':'Guam',
    'GT':'Guatemala',
    'GG':'Guernsey',
    'GN':'Guinea',
    'GW':'Guinea-Bissau',
    'GY':'Guyana',
    'HT':'Haiti',
    'HM':'Heard Island and McDonald Islands',
    'HN':'Honduras',
    'HK':'Hong Kong',
    'HU':'Hungary',
    'IS':'Iceland',
    'IN':'India',
    'ID':'Indonesia',
    'IR':'Iran',
    'IQ':'Iraq',
    'IE':'Ireland',
    'IM':'Isle of Man',
    'IL':'Israel',
    'IT':'Italy',
    'CI':'Ivory Coast',
    'JM':'Jamaica',
    'JP':'Japan',
    'JE':'Jersey',
    'JO':'Jordan',
    'KZ':'Kazakhstan',
    'KE':'Kenya',
    'KI':'Kiribati',
    'KW':'Kuwait',
    'KG':'Kyrgyzstan',
    'LA':'Laos',
    'LV':'Latvia',
    'LB':'Lebanon',
    'LS':'Lesotho',
    'LR':'Liberia',
    'LY':'Libya',
    'LI':'Liechtenstein',
    'LT':'Lithuania',
    'LU':'Luxembourg',
    'MO':'Macao',
    'MK':'North Macedonia',
    'MG':'Madagascar',
    'MW':'Malawi',
    'MY':'Malaysia',
    'MV':'Maldives',
    'ML':'Mali',
    'MT':'Malta',
    'MH':'Marshall Islands',
    'MQ':'Martinique',
    'MR':'Mauritania',
    'MU':'Mauritius',
    'YT':'Mayotte',
    'MX':'Mexico',
    'FM':'Micronesia',
    'MD':'Moldova',
    'MC':'Monaco',
    'MN':'Mongolia',
    'ME':'Montenegro',
    'MS':'Montserrat',
    'MA':'Morocco',
    'MZ':'Mozambique',
    'MM':'Myanmar',
    'NA':'Namibia',
    'NR':'Nauru',
    'NP':'Nepal',
    'NL':'Netherlands',
    'NC':'New Caledonia',
    'NZ':'New Zealand',
    'NI':'Nicaragua',
    'NE':'Niger',
    'NG':'Nigeria',
    'NU':'Niue',
    'NF':'Norfolk Island',
    'MP':'Northern Mariana Islands',
    'KP':'North Korea',
    'NO':'Norway',
    'OM':'Oman',
    'PK':'Pakistan',
    'PS':'Palestinian Territory',
    'PA':'Panama',
    'PG':'Papua New Guinea',
    'PY':'Paraguay',
    'PE':'Peru',
    'PH':'Philippines',
    'PN':'Pitcairn',
    'PL':'Poland',
    'PT':'Portugal',
    'PR':'Puerto Rico',
    'QA':'Qatar',
    'RE':'Reunion',
    'RO':'Romania',
    'RU':'Russia',
    'RW':'Rwanda',
    'BL':'Saint Barth&eacute;lemy',
    'SH':'Saint Helena',
    'KN':'Saint Kitts and Nevis',
    'LC':'Saint Lucia',
    'MF':'Saint Martin (French part)',
    'SX':'Saint Martin (Dutch part)',
    'PM':'Saint Pierre and Miquelon',
    'VC':'Saint Vincent and the Grenadines',
    'SM':'San Marino',
    'ST':'S&atilde;o Tom&eacute; and Pr&iacute;ncipe',
    'SA':'Saudi Arabia',
    'SN':'Senegal',
    'RS':'Serbia',
    'SC':'Seychelles',
    'SL':'Sierra Leone',
    'SG':'Singapore',
    'SK':'Slovakia',
    'SI':'Slovenia',
    'SB':'Solomon Islands',
    'SO':'Somalia',
    'ZA':'South Africa',
    'GS':'South Georgia/Sandwich Islands',
    'KR':'South Korea',
    'SS':'South Sudan',
    'ES':'Spain',
    'LK':'Sri Lanka',
    'SD':'Sudan',
    'SR':'Suriname',
    'SJ':'Svalbard and Jan Mayen',
    'SZ':'Swaziland',
    'SE':'Sweden',
    'CH':'Switzerland',
    'SY':'Syria',
    'TW':'Taiwan',
    'TJ':'Tajikistan',
    'TZ':'Tanzania',
    'TH':'Thailand',
    'TL':'Timor-Leste',
    'TG':'Togo',
    'TK':'Tokelau',
    'TO':'Tonga',
    'TT':'Trinidad and Tobago',
    'TN':'Tunisia',
    'TR':'Turkey',
    'TM':'Turkmenistan',
    'TC':'Turks and Caicos Islands',
    'TV':'Tuvalu',
    'UG':'Uganda',
    'UA':'Ukraine',
    'AE':'United Arab Emirates',
    'GB':'United Kingdom (UK)',
    'US':'United States (US)',
    'UM':'United States (US) Minor Outlying Islands',
    'UY':'Uruguay',
    'UZ':'Uzbekistan',
    'VU':'Vanuatu',
    'VA':'Vatican',
    'VE':'Venezuela',
    'VN':'Vietnam',
    'VG':'Virgin Islands (British)',
    'VI':'Virgin Islands (US)',
    'WF':'Wallis and Futuna',
    'EH':'Western Sahara',
    'WS':'Samoa',
    'YE':'Yemen',
    'ZM':'Zambia',
    'ZW':'Zimbabwe',
};

const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
var billingInfoChanged = false;
var billingInfoVarChange = {};
class TopUpBalanceModal extends React.Component {
    constructor(props) {
        super(props);

        let depositAmount = 0.00;
        let sending = false;
        this.state = {
			errorPayment: '',
            errorForm: '',
            sending: sending,
            depositAmount: depositAmount,
            showPaymentBtn: false,
            payWithStripe: false,
            payWithPaypal: false,
            paypalBtnReady: false,
            paypalProcessing: false,
            payment_method: "",
            isProcessing: false,
        };
     	this.initialValues = null;
        this.paypalProcessing = React.createRef();
        this.paypalButtonToolbar = React.createRef();
    }
	//Billing Information from profile
		componentDidMount() {
		this.props.initBillingInfo();
		}
    render() {
        const {open, onClose, depositAmount, formikSubmit, setFieldValue, intl, billingInfo} = this.props;
         this.initialValues = {
                firstname: billingInfo && billingInfo.firstname ? billingInfo.firstname :"",
                lastname: billingInfo && billingInfo.lastname ? billingInfo.lastname :"",
                company_name: billingInfo && billingInfo.company ? billingInfo.company :"",
                street_address: billingInfo && billingInfo.address ? billingInfo.address :"",
                postal_code: billingInfo && billingInfo.postal_code ? billingInfo.postal_code :"",
                city: billingInfo && billingInfo.city ? billingInfo.city :"",
                country: billingInfo && billingInfo.country ? billingInfo.country :"",
                taxid: billingInfo && billingInfo.taxid ? billingInfo.taxid :"",
                payment_method: "paypal",
                payment_order_id: "",
                project_id: null,
            }
        // const getCountryCode = (country) => {
        //     let countryCode = "";
        //     for (const [index] of Object.entries(countryList)) {
        //         if(countryList[index] === country) {
        //             countryCode = index;
        //         }
        //     }
        //     return countryCode;
        // };

        const moneyFormatter = (num) => {
            if (typeof num !== 'undefined') {
                try {
                    var calc = num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('.00','');
                    // var calc = num.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    return '$'+calc;
                } catch (e) {
                    console.log('moneyFormatter',e);
                    return '-';
                }
            }
            return '$'+num;
        }

        let modal_title = "Deposit";
        let promotion_rows = [];
        if (this.props.billingInfo) {
            if (this.props.billingInfo.deposit_promotions) {
                modal_title = "Deposit funds and get additional spending credits";
                for (const [index] of billingInfo.deposit_promotions.entries()) {
                    let this_promotion = billingInfo.deposit_promotions[index];
                    let promotional_amount = "$"+this_promotion.promotional_amount;
                    if (this_promotion.percentage_promotion == 1) {
                        let promotional_readable_percentage = this_promotion.promotional_amount*100;
                        promotional_amount = promotional_readable_percentage+"%";
                    }
                    promotion_rows.push(<div className="input-group mb-2">Deposit {moneyFormatter(this_promotion.minimum_deposit)} or more and receive a {promotional_amount} deposit bonus!</div>);
                }
            }
        }

        const handlePaymentTypeRadioStatusChange = (event) => {
            if (event.target.value == "stripe") {
                this.setState({
                    ...this.state,
                    payWithStripe: true,
                    payWithPaypal: false,
                    payment_method: "stripe",
                });

            } else if (event.target.value == "paypal") {
                this.setState({
                    ...this.state,
                    payWithStripe: false,
                    payWithPaypal: true,
                    payment_method: "paypal",
                });
            }
        };

        const handleClose = () => {
            this.setState({
                ...this.state,
                errorPayment: '',
                errorForm: '',
                depositAmount: 0,
                showPaymentBtn: false,
                payWithStripe: false,
                payWithPaypal: false,
                paypalBtnReady: false,
                paypalProcessing: false,
                payment_method: "",
            });
            onClose();
        };

        const setDepositAmount = (amount) => {
        	this.setState({
                ...this.state,
        		depositAmount: amount
        	});
        };
        const countries = [{value:"", label:"Country*"}];
        for (const [index] of Object.entries(countryList)) {
            countries.push({value:countryList[index], label:countryList[index]});
        }

        const getCountryCode = (country) => {
            let countryCode = "";
            for (const [index] of Object.entries(countryList)) {
                if(countryList[index] === country) {
                    countryCode = index;
                }
            }
            return countryCode;
        };

        const payPalClientKey = window.paypalClientId ? window.paypalClientId : "AZZBIPijonJxCr2RRK1S-rEMATsT3RkSXifg3JENAoQ4n9PJuQXG84vhz98B6wv6slUP29sKYgAxiASg";
        const payPalCheckoutURL = window.paypalClientId != 'AZZBIPijonJxCr2RRK1S-rEMATsT3RkSXifg3JENAoQ4n9PJuQXG84vhz98B6wv6slUP29sKYgAxiASg' ? 'https://www.sandbox.paypal.com/cgi-bin/webscr' : 'https://www.paypal.com/cgi-bin/webscr';
        const paypalEmail = window.paypalClientId != 'AZZBIPijonJxCr2RRK1S-rEMATsT3RkSXifg3JENAoQ4n9PJuQXG84vhz98B6wv6slUP29sKYgAxiASg' ? 'sb-0spps16767155@business.example.com' : 'payments@serpchampion.com';


        return (<Dialog open={open} onClose={handleClose} fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle>{modal_title}<p className="mb-0" style={{fontSize: "14px", fontWeight: "normal"}}>Note: This is a non-refundable deposit</p></DialogTitle>
            <DialogContent dividers>
            	<Formik
            		initialValues={this.initialValues}
                    validate={async values => {
                        const errors = {};
                        if (! this.state.depositAmount || this.state.depositAmount < 20) {
                            errors.depositAmount = "Minimum deposit is $20.";
                        }
						if (!this.state.payment_method) {
							errors.paymentType = "Please check a payment method before clicking deposit."
							this.setState({errorPayment: errors.paymentType});

						}
                        if (!values.firstname) {
                            errors.firstname = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                            // errors.errorForm = "First name required."
                            // this.setState({errorForm: errors.paymentType});
                        }

                        if (!values.lastname) {
                            errors.lastname = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                            // errors.errorForm = "Last name required."
                            // this.setState({errorForm: errors.paymentType});
                        }

                        if (!values.street_address) {
                            errors.street_address = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                            // errors.errorForm = "Street address required."
                            // this.setState({errorForm: errors.paymentType});
                        }

                        if (!values.city) {
                            errors.city = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                            // errors.errorForm = "City required."
                            // this.setState({errorForm: errors.paymentType});
                        }

                        if (!values.postal_code) {
                            errors.postal_code = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                            // errors.errorForm = "Postal code required."
                            // this.setState({errorForm: errors.paymentType});
                        }

                        if (!values.country) {
                            errors.country = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                            // errors.errorForm = "First name required."
                            // this.setState({errorForm: errors.paymentType});
                        }
                       return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting, validate }) => {
						const countryCode = getCountryCode(values.country);
						//Setting a billing information object for the form after submission.  Any information changed or added will now be reflected as opposed to constantly carry the profile billing info over
						 let billingInfo = {
                            firstname: values.firstname,
                            lastname: values.lastname,
                            company_name: values.company_name,
                            address: values.street_address,
                            postal_code: values.postal_code,
                            city: values.city,
                            country: values.country,
                            taxid: values.taxid
                        };

						//Checking if the user changed values. This is done instead of changed. Some could change it and then change it back.  
						let billingKeys = Object.keys(billingInfo);
						
						//String variable updating the changes to the variable
						let strBillingInfoChange = 'Billing information updated for: ';
						let strBillingInfoRemoved = 'Billing information removed for: ';
						
							//Going through the current billing information and comparing to the form for changes
							for (let i=0; i<(billingKeys.length); i++)
							{
								let changeCounter=0; 
								let removeCounter=0; 
									//Going through and comparing the arrays with the same keys. 
								  if(this.props.billingInfo[(billingKeys[i])] !== billingInfo[(billingKeys[i])])
								  {
									 //If there is more than one change, append a & to the string to display to the user more clearly
									  if(changeCounter)
									  {
										  this.strBillingInfoChange += ' and ';
									  }
									  
									 //Appending the alert string to all the changes, old and new values
									strBillingInfoChange += billingKeys[i] + ' from: ' + this.props.billingInfo[(billingKeys[i])] + ' to: ' + billingInfo[(billingKeys[i]) + ' \n'];
									
									  if(removeCounter) {
										  strBillingInfoRemoved += ', and ';
										}
							
									  if(billingInfo[(billingKeys[i])] == '') {
										  strBillingInfoRemoved += billingKeys[i];
										  removeCounter = removeCounter + 1;
									    }
									  
									  //Marking the counter for changes
									  changeCounter = changeCounter + 1;
									//Marking the variable as true to prompt the change variable
									this.billingInfoChanged = true;
								  }
							}
							
						//Sending the information to the API.
						
						//Updating billinginfo for the user if data is changed
						if(this.billingInfoChanged)
						{
							editBillingInfo(billingInfo)
								.then((res) => {
									//  alert(strBillingInfoChange);
									//  window.location.reload();
								})
								.catch(e => {
									let errorMessage = "";
									if (e.response) {
										// alert(e.response.data.message);
										// errorMessage = e.response.data.message;
									} else {
										// alert("Error! Try again later.");
										// errorMessage = "Something went wrong. Please contact support or refresh and try again.";
									}
								});
						}
						
						//Adding the deposit information
                        // values.payment_type_radio
                    	addDeposit(this.state.depositAmount, values.payment_order_id, this.state.payment_method, values.street_address, values.country, values.city, values.postal_code, values.state, values.firstname, values.lastname, values.taxid)
                            .then((res) => {
                            	alert("Deposit Successful.");
                            	window.location.reload();
	                      	})
	                        .catch(e => {
	                        	let errorMessage = "";
                                if (e.response) {
                                    console.log(e.response.data.message);
                                    errorMessage = e.response.data.message;
                                } else {
                                    console.log("Error! Try again later.");
                                    errorMessage = "Something went wrong. Please contact support or refresh and try again.";
                                }
	                       	});
		         	}}
                        >
                    {({
                          values,
                          status,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                          validateForm
                      }) => (
                  		<Form noValidate>
                  			<Form.Group className="col-md-12 kt-mb-10" controlId="formDepositAmount">
                                {modal_title != "Deposit" ? 
                                <>
                                    <div className="input-group mb-2">
                                        <span style={{fontWeight:"600"}}>This month's deposit promotions</span>
                                    </div>
                                    {promotion_rows}
                                    <div className="input-group mb-0" style={{height:"8px"}}>&nbsp;</div>
                                </> : ""}

	                          	<div className="input-group mb-3">
								  	<div className="input-group-prepend">
								    	<span className="input-group-text" id="basic-addon1">$</span>
								  	</div>
						            <Form.Control
						                autoFocus
						                onChange={(e) => setDepositAmount(e.target.value)}
						                placeholder="Deposit amount"
						                type="number"
						                step="0.01"
						                className="form-control"
						                name="depositAmount"
						                isInvalid={!!errors.depositAmount}
						                disabled={this.state.showPaymentBtn}
						               	/>
						            <Form.Control.Feedback type="invalid">
	                                    {errors.depositAmount}
	                                </Form.Control.Feedback>
					            </div>

                                {! this.state.showPaymentBtn ?
                                <>
                                    <div className="row">
                                        <Form.Group className="col-md-6 kt-mb-10" controlId="formFirstName">
                                            <Form.Control
                                                type="text"
                                                placeholder="First Name*"
                                                name="firstname"
                                                value={values.firstname}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isInvalid={!!errors.firstname}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.firstname}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                            <Form.Group className="col-md-6 kt-mb-10" controlId="formLastName">
                                            <Form.Control
                                                type="text"
                                                placeholder="Last Name*"
                                                name="lastname"
                                                value={values.lastname}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isInvalid={!!errors.lastname}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.lastname}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="row">
                                        <Form.Group className="col-md-6 kt-mb-10" controlId="formCompanyName">
                                            <Form.Control
                                                type="text"
                                                placeholder="Company Name"
                                                name="company_name"
                                                value={values.company_name}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isInvalid={!!errors.company_name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.company_name}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="col-md-6 kt-mb-10" controlId="formStreetAddress">
                                            <Form.Control
                                                type="text"
                                                placeholder="Street Address*"
                                                name="street_address"
                                                value={values.street_address}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isInvalid={!!errors.street_address}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.street_address}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="row">
                                        <Form.Group className="col-md-6 kt-mb-10" controlId="formPostalCode">
                                            <Form.Control
                                                type="text"
                                                placeholder="Postal Code*"
                                                name="postal_code"
                                                value={values.postal_code}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isInvalid={!!errors.postal_code}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.postal_code}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="col-md-6 kt-mb-10" controlId="formCity">
                                            <Form.Control
                                                type="text"
                                                placeholder="City*"
                                                name="city"
                                                value={values.city}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isInvalid={!!errors.city}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.city}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="row">
                                        <Form.Group className="col-md-6 kt-mb-10" style={{height: 'calc(1.5em + 1.3rem + 2px)'}}  controlId="formCountry">
                                            {values.country == "" ? 
                                                <Select
                                                    options={countries}
                                                    defaultValue={{value:values.country, label:"Select Country:"}}
                                                    onChange={selectedOption => {
                                                        handleChange("country")(selectedOption.value);
                                                    }}
                                                    noOptionsMessage={()=>{return !countries ? "Loading..." : "No options";}}
                                                    styles={selectStyles}
                                                />
                                                :
                                                <Select
                                                    options={countries}
                                                    defaultValue={{value:values.country, label:values.country}}
                                                    onChange={selectedOption => {
                                                        handleChange("country")(selectedOption.value);
                                                    }}
                                                    noOptionsMessage={()=>{return !countries ? "Loading..." : "No options";}}
                                                    styles={selectStyles}
                                                />}
                                            <div className="invalid-feedback" style={{display:!!errors.country ? "block" : "none"}}>{errors.country}
                                            </div>
                                        </Form.Group>

                                        <Form.Group className="col-md-6 kt-mb-10" controlId="formTaxId">
                                            <Form.Control
                                                type="text"
                                                placeholder="VAT Number (Only for EU Customers)"
                                                name="taxid"
                                                value={values.taxid}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isInvalid={!!errors.taxid}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.taxid}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                
                                    {errors.paymentType ? <div className="payment_error mt-4" style={{'justifyContent': "right"}}><textarea readOnly style={{'textAlign': "right", border: "none", width: "100%", color: "red", font: "-webkit-small-control", "textAlign": "left", "border": "none", "backgroundColor": "transparent", "resize": "none", "outline": "none"}} value={errors.paymentType} /></div> : null}

                                    <div className="input-group mb-2">
                                        Choose your payment method
                                    </div>
                                    <RadioGroup
                                    name="payment_type_radio_group">
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    onChange={handlePaymentTypeRadioStatusChange}
                                                    name="payment_type_radio"
                                                    id="payment_type_radio"
                                                    value="stripe"
                                                    color="primary"
                                                    isInvalid={!!errors.paymentType}
                                                    disabled={this.state.showPaymentBtn}
                                                />
                                            }
                                            label="Stripe"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    onChange={handlePaymentTypeRadioStatusChange}
                                                    name="payment_type_radio"
                                                    id="payment_type_radio"
                                                    value="paypal"
                                                    color="primary"
                                                    isInvalid={!!errors.paymentType}
                                                    disabled={this.state.showPaymentBtn}
                                                />
                                            }
                                            label="PayPal"
                                        />
                                    </RadioGroup>
										
                                </> : ''}
								
					        </Form.Group>

                            <ButtonToolbar className="mt-2">
                                {! this.state.showPaymentBtn ?
                                    <Button onClick={handleClose} color="primary">
                                        Close
                                    </Button>
                                : ""}
    					        {! this.state.showPaymentBtn && 
    				        		<Button className="ml-auto" variant="primary" onClick={() => validateForm().then((errors) => {
                                        // console.log('error_length', Object.keys(errors).length)
    	                                if (this.state.depositAmount && this.state.depositAmount >= 20 && this.state.payment_method != "" && Object.keys(errors).length === 0) {
    	                                 	this.setState({...this.state, showPaymentBtn: true});	
    	                                }
                                        // console.log(errors);
    	                            })} disabled={isSubmitting}>
                                    	Continue
                                	</Button>
    	                        }
                            </ButtonToolbar>

					        {this.state.payWithStripe && this.state.showPaymentBtn && <Elements stripe={stripePromise}>
				            	{/* <InjectedCheckoutForm depositAmount={this.state.depositAmount} formikSubmit={handleSubmit} handleClose={handleClose} setFieldValue={setFieldValue} /> */}
                                {! this.state.isProcessing && <InjectedCheckoutFormRedirect values={values} depositAmount={this.state.depositAmount} />}
                                {this.state.isProcessing =true}
			            	</Elements>}

                            {this.state.payWithPaypal && this.state.showPaymentBtn && <div>
                                {! this.state.isProcessing && <InjectedPayPackCheckoutFormRedirect paypalEmail={paypalEmail} payPalClientKey={payPalClientKey} payPalCheckoutURL={payPalCheckoutURL} values={values} depositAmount={this.state.depositAmount} />}
                                {this.state.isProcessing =true}
                            </div>}

                            <div className="justify-content-center text-center" style={{display:"none"}} ref={this.paypalProcessing}>
                                <p className="text-bold">Payment is processing, please wait...</p>
                                <Spinner size="lg" />
                            </div>
                            {/* <ButtonToolbar className="justify-content-center" ref={this.paypalButtonToolbar}>
                                {!this.state.paypalProcessing && this.state.showPaymentBtn && this.state.payment_method === "paypal" && !this.state.paypalBtnReady && <Spinner />}
                                {!this.state.paypalProcessing && this.state.showPaymentBtn && this.state.payment_method === "paypal" && <PayPalButton
                                    options={{
                                        clientId: window.paypalClientId ? window.paypalClientId : "AZZBIPijonJxCr2RRK1S-rEMATsT3RkSXifg3JENAoQ4n9PJuQXG84vhz98B6wv6slUP29sKYgAxiASg",
                                        disableFunding: "card,credit,bancontact,venmo,sepa,eps,giropay,ideal,mybank,p24,sofort",
                                    }}
                                    createOrder={(data, actions) => {
                                        ReactGA.event({
                                            category: 'Payment',
                                            action: 'PaypalApprovedAction'
                                        });

                                       
                                        return actions.order.create({
                                            purchase_units: [{
                                                amount: {
                                                    currency_code: "USD",
                                                    value: this.state.depositAmount
                                                },
                                                //items : items,
                                                description: "SerpPro Deposit"
                                            }],
                                            application_context: {
                                                shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
                                            }
                                        });
                                    }}
                                    onApprove={(data, actions) => {
                                        ReactGA.event({
                                            category: 'Payment',
                                            action: 'PaypalApprovedAction'
                                        });

                                        // Capture the funds from the transaction
                                        return actions.order.capture().then(function(details) {
                                            
											//add payment data to the request
                                            setFieldValue('payment_order_id', data.orderID, false);
                                            handleSubmit();
                                        });
                                    }}
                                    onButtonReady={() => {this.setState({ ...this.state, paypalBtnReady: true });}}
                                    onCancel={(object)=>{
										
                                        //this.setState({...this.state, paypalProcessing: false});
                                        this.paypalProcessing.current.style.display = "none";
                                        this.paypalButtonToolbar.current.style.display = "flex";
                                    }}
                                />}
                            </ButtonToolbar> */}
			        	</Form>   	
                    )}
                </Formik>
            </DialogContent>
        </Dialog>);

    }
}


////////////////////////////////////
// START -> PayPal checkout redirect
let paypal_counter = 0;
let paypal_redirect_started = false;

const InjectedPayPackCheckoutFormRedirect = (props) => {
    const { paypalEmail, payPalClientKey, payPalCheckoutURL, depositAmount, values, formikSubmit } = props;

    return (
        <PayPalCheckoutRedirect paypalEmail={paypalEmail} payPalClientKey={payPalClientKey} payPalCheckoutURL={payPalCheckoutURL} depositAmount={depositAmount}  values={values} />
    );
}

class PayPalCheckoutRedirect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // fetchCountryCode: true,
            isSubmitting: true,
            errorPayment: false,
            site_url: 'https://dashboard.serppro.io',
            return_url: '',
            cancel_url: '',
            notify_url: '',
            item_number: '',
        };
        this.form = null;
        paypal_counter = 0;
        paypal_redirect_started = false;
    }

    componentDidMount() {
        // this.form.submit();

    }

    render() {
        const { paypalEmail, payPalClientKey, payPalCheckoutURL, depositAmount, values} = this.props;

        paypal_counter++;
        // console.log('TOP deposit paypal_counter',paypal_counter);
        // if (paypal_counter > 2) return false;
        if (paypal_redirect_started == true) return false;

        const getCountryCode = (country) => {
            let countryCode = "";
            for (const [index] of Object.entries(countryList)) {
                if(countryList[index] === country) {
                    countryCode = index;
                }
            }
            return countryCode;
        };
        
        const countryCode = getCountryCode(values.country);
        let projectId = values.projectId;
        let billingInfo = {
            firstname: values.firstname,
            lastname: values.lastname,
            company_name: values.company_name,
            address: values.street_address,
            postal_code: values.postal_code,
            city: values.city,
            country: values.country,
            tax_id: (typeof values.tax_id !== 'undefined' ? values.tax_id.replace(" ", "") : values.tax_id),
            payment_method: values.payment_method,
            payment_order_id: values.payment_order_id,
            country_code: countryCode
        };

        const paypalStartCheckoutProcess = (depositAmount) => {           
            paypalInitCheckout({ billing_info: billingInfo, deposit_amount: depositAmount })
            .then(function(confirmResult) {
                return confirmResult;
            }).then(handleServerResponse);

            // this.setState({isSubmitting: true});
        }
    
        const handleServerResponse = (response) => {
            // paypal_counter = 0;
            // console.log('paypalStartCheckoutProcess handleServerResponse response=',response);
            if (response.data.error) {
                // Show error from server on payment form
                // console.log('paypalStartCheckoutProcess ERROR response=', response);
                this.setState({isSubmitting: false, errorPayment: response.data.error});
    
            } else {
                // Show success message
                // console.log(response.data);
                //add payment data to the request
                // console.log('response.data.id', response.data.id);
    
                // setFieldValue('payment_order_id', response.data.id, false);
                // formikSubmit();
    
                // stripe.redirectToCheckout({
                //     sessionId: response.data.id
                // }).then(handleStripeCheckoutResult); 

                this.setState({
                    site_url: response.data.site_url, 
                    return_url: response.data.return_url, 
                    cancel_url: response.data.cancel_url, 
                    notify_url: response.data.notify_url,
                    item_number: response.data.item_number
                });

                // console.log('RETURN deposit paypal_counter',paypal_counter);
                // if (paypal_counter == 2) {
                if (paypal_redirect_started == false) {
                    paypal_redirect_started = true;
                    paypal_counter++;
                    this.form.submit();
                }
                
            }
        };

        if (! this.state.errorPayment 
            && this.state.isSubmitting == true 
            && this.state.item_number == ''
        ) {
            console.log('INIT deposit paypal_counter',paypal_counter);
            paypalStartCheckoutProcess(depositAmount);
        }

        return (
            <>
                <form action={payPalCheckoutURL} method="post" accept-charset="utf-8" ref={item => this.form = item}>
                    <input type="hidden" name="cmd" value="_xclick" />
                    <input type="hidden" name="charset" value="utf-8" />
                    <input type="hidden" name="no_note" value="1" />
                    <input type="hidden" name="no_shipping" value="1" />
                    <input type="hidden" name="quantity" value="1" />
                    <input type="hidden" name="business" value={paypalEmail} />
                    <input type="hidden" name="item_name" value="SERPpro Checkout" />
                    <input type="hidden" name="item_number" value={this.state.item_number} />
                    <input type="hidden" name="amount" id="paypal_amount" value={depositAmount} />
                    <input type="hidden" name="currency_code" value="USD" />
                    {/* <input type="hidden" name="return" value="http://localhost:3000/invoice/invoice_uuid?s=return" />
                    <input type="hidden" name="cancel_return" value="http://localhost:3000/checkout?s=cancel" />
                    <input type="hidden" name="notify_url" value="http://localhost:3000/paypal/client/invoices/invoice_uuid/g" /> */}
                    <input type="hidden" name="return" value={this.state.return_url} />
                    <input type="hidden" name="cancel_return" value={this.state.cancel_url} />
                    <input type="hidden" name="notify_url" value={this.state.notify_url} />
                    <input type="hidden" name="client_key" value={payPalClientKey} />
                </form>

                <div>
                    {this.state.errorPayment ? <div className="alert alert-error alert-danger"><div className="payment_error" style={{'justify-content': "right"}}>{this.state.errorPayment}</div></div> : null}
                    {! this.state.errorPayment ? <div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div> : null}
                </div>
            </>
        );
    }
}
// END -> PayPal checkout redirect
////////////////////////////////////


////////////////////////////////////
// START -> stripe checkout redirect
let stripe_counter = 0;

const InjectedCheckoutFormRedirect = (props) => {
    const { depositAmount, values, formikSubmit } = props;
    // console.log('InjectedCheckoutFormRedirect 111 stripe_counter='+stripe_counter);
    // console.log('InjectedCheckoutFormRedirect billingInfo=',billingInfo);
    // console.log('InjectedCheckoutFormRedirect values=',values);
    return (
        <ElementsConsumer>
            {({elements, stripe}) => (
                <StripeCheckoutRedirect elements={elements} stripe={stripe} values={values} depositAmount={depositAmount} />
            )}
        </ElementsConsumer>
    );
}

class StripeCheckoutRedirect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // fetchCountryCode: true,
            isSubmitting: true,
            errorPayment: false
        };
    }
    render() {
        const { stripe, elements, depositAmount, values} = this.props;

        stripe_counter++;
        if (stripe_counter == 1) return false;

        // console.log('StripeCheckoutRedirect 222 stripe_counter='+stripe_counter);
        // console.log('StripeCheckoutRedirect cartItems=',cartItems);
        // console.log('StripeCheckoutRedirect billingInfo=',billingInfo);
        // console.log('StripeCheckoutRedirect projectId=',projectId);
        // console.log('StripeCheckoutRedirect useRemainingBalance=',useRemainingBalance);

        const getCountryCode = (country) => {
            let countryCode = "";
            for (const [index] of Object.entries(countryList)) {
                if(countryList[index] === country) {
                    countryCode = index;
                }
            }
            return countryCode;
        };
        
        const countryCode = getCountryCode(values.country);
        let projectId = values.projectId;
        let billingInfo = {
            firstname: values.firstname,
            lastname: values.lastname,
            company_name: values.company_name,
            address: values.street_address,
            postal_code: values.postal_code,
            city: values.city,
            country: values.country,
            tax_id: (typeof values.tax_id !== 'undefined' ? values.tax_id.replace(" ", "") : values.tax_id),
            payment_method: 'stripe',
            payment_order_id: values.payment_order_id,
            country_code: countryCode
        };

        const stripeStartCheckoutProcess = (depositAmount) => {
            stripeInitCheckoutSession({ billing_info: billingInfo, deposit_amount: depositAmount })
            .then(function(confirmResult) {
                return confirmResult;
            }).then(handleServerResponse);

            // this.setState({isSubmitting: true});
        }
    
        const handleServerResponse = (response) => {
            stripe_counter = 0;
            // console.log('stripeStartCheckoutProcess handleServerResponse response=',response);
            if (response.data.error) {
                // Show error from server on payment form
                // console.log('stripeStartCheckoutProcess ERROR response=', response);
                this.setState({isSubmitting: false, errorPayment: response.data.error});
    
            } else if (response.data.requires_action) {
                // Use Stripe.js to handle required card action
    
                // stripe.handleCardAction(
                //     response.data.payment_intent_client_secret
                // ).then(handleStripeJsResult);
                // stripe.handleCardAction(
                //     response.data.payment_intent_client_secret
                // ).then(handleStripeJsResult);
    
            } else {
                // Show success message
                // console.log(response.data);
                //add payment data to the request
                // console.log('response.data.id', response.data.id);
    
                // setFieldValue('payment_order_id', response.data.id, false);
                // formikSubmit();
    
                stripe.redirectToCheckout({
                    sessionId: response.data.id
                }).then(handleStripeCheckoutResult);  
            }
        };
    
        const handleStripeCheckoutResult = (response) => {
            // console.log('handleStripeCheckoutResult handleResult='+response)
            // setFieldValue('payment_order_id', response.data.id, false);
            // formikSubmit();
        }

        if (! this.state.errorPayment && this.state.isSubmitting == true) {
            stripeStartCheckoutProcess(depositAmount);
        }

        return (<div>
            {this.state.errorPayment ? <div className="alert alert-error alert-danger"><div className="payment_error" style={{'justify-content': "right"}}>{this.state.errorPayment}</div></div> : null}
            {! this.state.errorPayment ? <div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div> : null}
        </div>);
    }
}
// END -> stripe checkout redirect
////////////////////////////////////

/*
// DEPRECIATED -> stripe on page payment form (search this page for this)
const InjectedCheckoutForm = (props) => {
    const {depositAmount, formikSubmit, setFieldValue, handleClose} = props;
    return (
        <ElementsConsumer>
            {({elements, stripe}) => (
                <StripeCheckoutForm elements={elements} stripe={stripe} depositAmount={depositAmount} handleClose={handleClose} formikSubmit={formikSubmit} setFieldValue={setFieldValue} />
            )}
        </ElementsConsumer>
    );
};

class StripeCheckoutForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false,
        };
    }

    
    render() {
        const {stripe, elements, depositAmount, formikSubmit, handleClose, setFieldValue} = this.props;

        const handleStripeJsResult = (result) => {
            if (result.error) {
                // Show error in payment form
                console.log(result.error);
                this.setState({isSubmitting: false});
            } else {
                // The card action has been handled
                // The PaymentIntent can be confirmed again on the server
                stripePay({ payment_intent_id: result.paymentIntent.id }).then(function(confirmResult) {
                    return confirmResult;
                }).then(handleServerResponse);
            }
        };

        const handleServerResponse = (response) => {
        	
            if (response.data.error) {
				 this.setState({isSubmitting: false, errorPayment: response.data.error});
            } else if (response.data.requires_action) {
                // Use Stripe.js to handle required card action
                stripe.handleCardAction(
                    response.data.payment_intent_client_secret
                ).then(handleStripeJsResult);
            } else {
                // Show success message
                console.log(response.data);
                //add payment data to the request
                setFieldValue('payment_order_id', response.data.id, false);
                formikSubmit();
            }
        };

        const handleSubmit = async (event) => {

            // Block native form submission.
            event.preventDefault();

            ReactGA.event({
                category: 'Payment',
                action: 'StripeButtonClick'
            });

            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return;
            }

            this.setState({isSubmitting: true});

            // Get a reference to a mounted CardElement. Elements knows how
            // to find your CardElement because there can only ever be one of
            // each type of element.
            const cardElement = elements.getElement(CardElement);

            const {error, paymentMethod} = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });
			
            if (error) {
                
				//Allocating the error payment message to a variable to then display on the modal
                this.setState({isSubmitting: false, errorPayment: error['message']});
            } else {
                stripePay({ payment_method_id: paymentMethod.id, amount: depositAmount ? parseFloat(depositAmount).toFixed(2) * 100 : 0 })
                .then(handleServerResponse);
            }
			//Setting the height of the input field dynamically
			
        };
		
		
        if(this.state.errorPayment) {
			const newLine = 40;
			let rowHeight = this.state.errorPayment.length;
			var rows = 1;
			const remainderNewLine = (rowHeight % newLine);
			
			//rows = remainder of character length /40. If it goes in evenly then rows is set to that value.  If it needs to add another row to adjust for the extra characters then remainderNewLine/remainderNewLine * rows will go to 1 as opposed to 0.
			rows = (remainderNewLine/remainderNewLine)*rows + ((rowHeight - remainderNewLine) / newLine );
        }

        return (
            <form onSubmit={handleSubmit}>
                <CardElement
                    options={{
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }}
                />
				{this.state.errorPayment ? <div className="payment_error" style={{'justifyContent': "right"}}><textarea readOnly rows={rows} style={{'textAlign': "right", border: "none", width: "100%", color: "red", font: "-webkit-small-control", "textAlign": "left", "border": "none", "backgroundColor": "transparent", "resize": "none", "outline": "none"}} value={this.state.errorPayment} /></div> : null}
			    <ButtonToolbar className=" mt-3">
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                    <Button className="ml-auto" type="submit" disabled={!stripe || this.state.isSubmitting}>Deposit</Button>
                </ButtonToolbar>
            </form>
        );
    }
}
*/

TopUpBalanceModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ marketplace: { filter, guestPosts }, profile: { billingInfo } }) => ({
    filter, guestPosts, billingInfo
});

export default injectIntl(connect(
    mapStateToProps,
    {...profile.actions}
)(TopUpBalanceModal));
