import React from "react";
import {Portlet, PortletBody} from "../../partials/content/Portlet";
import ProjectSelect from "./projects-components/ProjectSelect";
import ProjectDetails from "./projects-components/ProjectDetails";
import "../../../_metronic/_assets/sass/custom/projects.scss";
import {Link} from "react-router-dom";
import { connect } from "react-redux";

class ScheduleCall extends React.Component {

    componentDidMount () {
        const script = document.createElement("script");
        script.src = "https://assets.calendly.com/assets/external/widget.js";
        script.async = true;
        document.body.appendChild(script);
    }

    render() {
        const { user, showHi, showAvatar, showBadge } = this.props;

        return (
            <>
                <div className="row kt-mt-10">
                    <div className="col-12">
                        <h1 className="custom-header kt-mt-25 text-center">Schedule Strategy Call</h1>
                        {/* <h2 className="custom-header kt-mb-25 text-center">Schedule Strategy Call</h2> */}
                        <div class="calendly-inline-widget" data-url="https://calendly.com/serpchampion/introduction-call?hide_event_type_details=1&hide_gdpr_banner=1" style={{position:'relative', top:'-30px',minWidth:'320px',height:'700px'}}></div>
                        {/* <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script> */}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(ScheduleCall);