import React from "react";
import {Route, Switch} from "react-router-dom";
//import OnPageAudit from "./OnPageAudit";
import MarketplaceAccess from "./MarketplaceAccess";
import ContentCreation from "./ContentCreation";
import ManagedSeoService from "./ManagedSeoService";
import GuestPost from "./GuestPost";
import LinkInsertion from "./LinkInsertion";
import Article from "./Article";
import AuditPerPage from "./AuditPerPage";

export default function PackagesPage() {
    return (
        <Switch>
            {/*<Route
                path="/packages/on-page-audit"
                component={OnPageAudit}
            />*/}
            <Route
                path="/packages/marketplace-access"
                component={MarketplaceAccess}
            />
            <Route
                path="/packages/content-creation"
                component={ContentCreation}
            />
            <Route
                path="/packages/managed-seo-service"
                component={ManagedSeoService}
            />
            <Route
                path="/packages/guest-post-services"
                component={GuestPost}
            />
            <Route
                path="/packages/niche-edits-service"
                component={LinkInsertion}
            />
            <Route
                path="/packages/content-creation-services"
                component={Article}
            />
            <Route
                path="/packages/on-page-seo-services"
                component={AuditPerPage}
            />
        </Switch>
    );
}
