import React, { Component } from "react";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Spinner} from "reactstrap";
import * as orders from "../../store/ducks/admin/orders.duck";
import {Portlet, PortletBody, PortletHeader} from "../../partials/content/Portlet";
import "../../../_metronic/_assets/sass/custom/invoice.scss";

class OrderInvoiceAdmin extends Component {
    componentDidMount() {
        const { orderId } = this.props.match.params;
        this.props.requestInvoice(orderId);
    }

    render() {
        const invoice = this.props.invoice;
        let itemsTotal = 0;
        let discountAmount = 0;

        if (!invoice) {
            return <Spinner/>;
        }
        else if(!invoice.data) {
            return (
                <>
                    <h1>Invoice not found!</h1>
                </>
            );
        }

        const invoiceItems = [];
        if (invoice.data.items.length !== 0) {
            for (const [index] of Object.entries(invoice.data.items)) {
                let name = "";
                if(invoice.data.items[index].type === "tech_audit_package" ||
                    invoice.data.items[index].type === "analytics_package" ||
                    invoice.data.items[index].type === "custom_service" ||
                    invoice.data.items[index].type === "content_package" ||
                    invoice.data.items[index].type === "managed_seo_package" ||
                    invoice.data.items[index].type === "guest_post_service_package" ||
                    invoice.data.items[index].type === "marketplace_guest_post_access"
                ) {
                    name = invoice.data.items[index].name;
                }
                else if(invoice.data.items[index].type === "guest_post") {
                    name = (invoice.data.items[index].site_id ? "Marketplace " : "") + "Guest Post" + " [DR: " + invoice.data.items[index].da + "+]";
                }
                else if(invoice.data.items[index].type === "audit_per_page") {
                    name = "On-Page Optimization (per page)";
                }
                else if(invoice.data.items[index].type === "link_clean_up") {
                    name = "Link Clean-Up";
                }
                else if(invoice.data.items[index].type === "press_release") {
                    name = "Press Release " + invoice.data.items[index].package;
                }
                else if(invoice.data.items[index].type === "gpb") {
                    name = "GPB Optimization " + invoice.data.items[index].package;
                }
                else if(invoice.data.items[index].type === "link_insertion") {
                    name = "Link Insertion";
                }
                else if(invoice.data.items[index].type === "citation") {
                    name = "Citation";
                }
                else if(invoice.data.items[index].type === "article") {
                    name = "Article";
                }
                else if(invoice.data.items[index].type === "domain_sale") {
                    name = "Domain Name - "+invoice.data.items[index].order_item_value;
                }
                invoiceItems.push(<tr key={index}>
                    <td>{name}</td>
                    <td>{invoice.data.items[index].quantity}</td>
                    <td className="m--font-danger">{(invoice.data.items[index].quantity * invoice.data.items[index].price).toFixed(2)} {invoice.data.currency}</td>
                </tr>);

                if (invoice.data.items[index].addOns != null) {
                    if (invoice.data.items[index].addOns.length > 0) {
                        for(let ao = 0; ao < invoice.data.items[index].addOns.length; ao++) {
                            let xAddOn = invoice.data.items[index].addOns[ao];
                            let add_on_row_price = (invoice.data.items[index].quantity * xAddOn.add_on_price).toFixed(2) + ' ' + invoice.data.currency;
                            invoiceItems.push(<tr key={index}>
                                <td>{name} - Add On - {xAddOn.add_on_name}</td>
                                <td>{invoice.data.items[index].quantity}</td>
                                <td className="m--font-danger">{add_on_row_price}</td>
                            </tr>);
                        }
                    }
                }

                if(invoice.data.items[index].type === "citation") {
                    itemsTotal += invoice.data.items[index].price;
                }
                else {
                    itemsTotal += invoice.data.items[index].price * invoice.data.items[index].quantity;
                }
            }
        }

        if(invoice.data.total && invoice.data.total >= 0) {
            discountAmount = itemsTotal - invoice.data.total;
        }

        return (
            <>
                <Portlet>
                    <PortletHeader title={"Order Invoice #" + invoice.data.id} />
                    <PortletBody>
                        <div className="m-invoice-2">
                            <div className="m-invoice__wrapper">
                                <div className="m-invoice__head">
                                    <div className="m-invoice__container m-invoice__container--centered">
                                        <div className="m-invoice__logo">
                                            <a href="#">
                                                <h1>INVOICE</h1>
                                            </a>
                                            <a href="#">
                                                <img src="/media/logos/blue_logo.png" />
                                            </a>
                                        </div>
                                        <span className="m-invoice__desc">
                                            <span>{invoice.data.invoice_data && invoice.data.invoice_data.company}</span>
                                            <span>{invoice.data.invoice_data && invoice.data.invoice_data.address}</span>
                                            <span>{invoice.data.invoice_data && invoice.data.invoice_data.postal_code} {invoice.data.invoice_data && invoice.data.invoice_data.city}</span>
                                            <span>{invoice.data.invoice_data && invoice.data.invoice_data.country}</span>
                                            <br/>
                                            <span>BTW: {invoice.data.invoice_data && invoice.data.invoice_data.vat}</span>
                                            <span>KvK: {invoice.data.invoice_data && invoice.data.invoice_data.kvk}</span>
                                        </span>
                                        <div className="m-invoice__items">
                                            <div className="m-invoice__item">
                                                <span className="m-invoice__subtitle">INVOICE TO.</span>
                                                <span className="m-invoice__text">
                                                     {invoice.data.billing_firstname} {invoice.data.billing_lastname}<br/>
                                                     {invoice.data.company_name}<br/>
                                                     {invoice.data.address}<br/>
                                                     {invoice.data.city}<br/>
                                                     {invoice.data.postal_code}<br/>
                                                     {invoice.data.country}<br/>
                                                     {invoice.data.tax_id && 'VAT #: ' + invoice.data.tax_id}
                                                </span>
                                            </div>
                                            <div className="m-invoice__item">
                                                <span className="m-invoice__subtitle">DATE</span>
                                                <span
                                                    className="m-invoice__text">{(new Date(invoice.data.created_at)).toLocaleString()}</span>
                                            </div>
                                            <div className="m-invoice__item">
                                                <span className="m-invoice__subtitle">INVOICE NO.</span>
                                                <span className="m-invoice__text">{invoice.data.order_number}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="m-invoice__body m-invoice__body--centered">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th>PRODUCT</th>
                                                <th>QUANTITY</th>
                                                <th>PRICE</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {invoiceItems.length == 0 && invoice.data.order_text_data == "Deposit" ?
                                                    <>
                                                        <tr><td>Deposit</td><td>1</td><td>{invoice.data.total.toFixed(2)}</td></tr>
                                                    </>
                                                : invoiceItems}
                                                {invoice.data.extra_details ?
                                                    <>
                                                        <tr>
                                                            <td>{invoice.data.extra_details}</td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </>
                                                : ""}
                                            </tbody>
                                        </table>
                                        {discountAmount > 0 && <table className="table">
                                            <thead>
                                            <tr>
                                                <th className="text-right kt-padding-r-0">DISCOUNT</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td className="text-right kt-padding-r-0">{"-" + discountAmount.toFixed(2)} {invoice.data.currency}</td>
                                            </tr>
                                            </tbody>
                                        </table>}
                                    </div>
                                </div>
                                <div className="m-invoice__footer">
                                    <div className="m-invoice__table  m-invoice__table--centered table-responsive">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th>PAYMENT METHOD</th>
                                                <th>ORDER DATE</th>
                                                <th>INVOICE DATE</th>
                                                <th>TOTAL</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>{invoice.data.payment_method}</td>
                                                <td>{(new Date(invoice.data.created_at)).toLocaleString()}</td>
                                                <td>{(new Date(invoice.data.created_at)).toLocaleString()}</td>
                                                <td className="m--font-danger">
                                                    {invoice.data.used_remainting_balance > 0 ?
                                                        (invoice.data.total - invoice.data.used_remainting_balance).toFixed(2)+' '
                                                    : invoice.data.total.toFixed(2)+' ' }
                                                    {invoice.data.currency}
                                                </td>
                                            </tr>
                                            {invoice.data.used_remainting_balance > 0 ?
                                                <>
                                                    <tr>
                                                        <td>Balance</td>
                                                        <td>{(new Date(invoice.data.created_at)).toLocaleString()}</td>
                                                        <td>{(new Date(invoice.data.created_at)).toLocaleString()}</td>
                                                        <td className="m--font-danger">{invoice.data.used_remainting_balance.toFixed(2)} {invoice.data.currency}</td>
                                                    </tr>
                                                </>
                                            : ""}
                                            </tbody>
                                        </table>
                                    </div>
                                    <br/><br/>
                                        <div className="text-center">
                                            <b>
                                                {invoice.data.reverse_vat !== 0 && "VAT Reverse Charge: " + invoice.data.tax_id}
                                            </b>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </PortletBody>
                </Portlet>
            </>
        );
    }
}

const mapStateToProps = ({ ordersAdmin: { invoice } }) => ({
    invoice
});

export default injectIntl(connect(
    mapStateToProps,
    orders.actions
)(OrderInvoiceAdmin));
