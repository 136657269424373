import React, { Component } from "react";
import {Button, ProgressBar} from "react-bootstrap";
import {setAudit} from "../../../crud/onpage.crud";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as onpage from "../../../store/ducks/onpage.duck";
import * as projects from "../../../store/ducks/projects.duck";
import {Portlet, PortletBody, PortletHeader} from "../../../partials/content/Portlet";
import {Spinner} from "reactstrap";
import {Link} from "react-router-dom";
import { withRouter } from 'react-router-dom';
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {saveProjectOptions} from "../../../crud/projects.crud";

class AuditStart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disableStartAudit: false,
            error: "",
        };
    }

    componentDidMount() {
        const projectId = this.props.match && this.props.match.params && this.props.match.params.projectId ? this.props.match.params.projectId : this.props.projectId;

        if(projectId) {
            if(!this.props.currentProject || !this.props.currentProject.data) {
                this.props.requestProject(projectId);
            }
            this.props.requestPageLimits();
            this.props.requestProjectOptions(projectId);
        }
    }

    startAudit(projectId) {
        if(projectId) {
            this.setState({...this.state, disableStartAudit: true});
            setAudit(projectId).then(res => {
                this.props.initSummary(projectId);
                if(!this.props.compact) {
                    this.props.history.push({
                        pathname: "/onpage-audit"
                    });
                }
            }).catch((e) => {
                let error = "";
                if(e.response && e.response.data) {
                    error = e.response.data.error;
                }
                this.setState({...this.state, error: error, disableStartAudit: false});
            });
        }
    }

    render() {
        const {pageLimits, currentProject, currentProjectId, projectOptions} = this.props;
        const isLimit = (pageLimits && pageLimits.pageLimit - pageLimits.pageLimitUsage < pageLimits.requestPagesNum);
        let scheduled = false;

        if(!currentProject || !projectOptions || !projectOptions[currentProjectId]) {
            return <Spinner />;
        }
        else if(!currentProject.data) {
            return <h1>Project not found!</h1>;
        }

        if(!currentProject) {
            return <Spinner />;
        }

        if(projectOptions[currentProjectId] && projectOptions[currentProjectId].length) {
            for (const [index] of projectOptions[currentProjectId].entries()) {
                if(projectOptions[currentProjectId][index].option === "schedule_audit") {
                    scheduled = parseInt(projectOptions[currentProjectId][index].value) === 1;
                }
            }
        }

        const handleScheduleAudit = (e) => {
            const data = {
                project_id: currentProject.data.id,
                options: [
                    {
                        option: "schedule_audit",
                        value: e.target.checked ? 1 : 0
                    }
                ]
            };

            this.setState({...this.state, disableStartAudit: true});

            saveProjectOptions(data).then(res => {
                this.props.requestProjectOptions(currentProject.data.id);
                this.setState({disableStartAudit: false});
            }).catch((e) => {
                let error = "";
                if(e.response && e.response.data) {
                    error = e.response.data.error;
                }
                this.setState({...this.state, error: error, disableStartAudit: false});
            });
        };

        return (
            <div className={!this.props.compact ? "row" : ""}>
                <div className={!this.props.compact ? "col-xl-6" : ""}>
                    <Portlet>
                        {!this.props.compact && <PortletHeader title="OnPage Audit" />}
                        <PortletBody>
                            {this.state.error && <div role="alert" className="alert alert-danger">
                                <div className="alert-text">{this.state.error}</div>
                            </div>}
                            {pageLimits && isLimit && pageLimits.pageLimit !== 0 && <div role="alert" className="alert alert-warning">
                                <div className="alert-text">You have reached page limit for OnPage Audit. New scanning requires {pageLimits.requestPagesNum} pages.</div>
                            </div>}
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            defaultChecked={scheduled}
                                            onChange={handleScheduleAudit}
                                            value="1"
                                            color="primary"
                                        />
                                    }
                                    label={"Schedule audit for every month"}
                                />
                            </div>
                            {pageLimits && <div className="kt-mb-20">
                                {pageLimits.siteLimit > 0 ? <div>Project limit: {pageLimits.siteLimitUsage}/{pageLimits.siteLimit}</div> : ""}
                                <div>Page limit: {pageLimits.pageLimit}{/* (<Link to="/packages/on-page-audit">Buy new package</Link>)*/}</div>
                                <div>Pages scanned: {pageLimits.pageLimitUsage}</div>
                                <ProgressBar className="kt-mt-10" now={pageLimits.pageLimitUsage/pageLimits.pageLimit * 100} label={`${pageLimits.pageLimitUsage ? pageLimits.pageLimitUsage : ''}`} />
                            </div>}

                            {pageLimits ?
                            <Button className="mx-auto" onClick={()=>{this.startAudit(currentProject.data.id)}} disabled={this.state.disableStartAudit || isLimit } style={{maxWidth:"300px"}}>Start OnPage Audit Now</Button> :
                            <Spinner />}
                        </PortletBody>
                    </Portlet>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ onpage: {pageLimits}, projects: {currentProject, currentProjectId, projectOptions} }) => ({
    pageLimits, currentProject, currentProjectId, projectOptions
});

export default injectIntl(connect(
    mapStateToProps,
    {...onpage.actions, ...projects.actions}
)(AuditStart));
