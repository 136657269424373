import React from "react";
import {Step, StepLabel, Stepper} from "@material-ui/core";
import CreateProjectStep from "./CreateProjectStep";
import AddTrackingCodeStep from "./AddTrackingCodeStep";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as projects from "../../../store/ducks/projects.duck";
import {Spinner} from "reactstrap";
import SEOAuditStep from "./SEOAuditStep";
import {Redirect} from "react-router-dom";
import {getQueryParam} from "../../../custom_functions/get_query_param";

class CreateProjectProcess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
        };
    }

    componentDidMount() {
        const step = getQueryParam(window.location.search, "step");
        if(step && step > 1 && step <= 4) {
            this.setState({...this.state, step: parseInt(step)});
            const {currentProjectId} = this.props;
            if(currentProjectId > 0) {
                this.props.requestProject(currentProjectId);
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.currentProjectId !== prevProps.currentProjectId && this.props.currentProjectId > 0) {
            this.props.requestProject(this.props.currentProjectId);
        }
    }

    render() {
        const {currentProject} = this.props;
        const redirect = getQueryParam(window.location.search, "redirect");

        const handleNextStep = () => {
            this.setState({...this.state, step: 4/*this.state.step + 1*/});
        };

        if(this.state.step >= 4) {
            if(!currentProject) {
                return <Spinner />;
            }
            else if(currentProject && !currentProject.data) {
                return <Redirect to="/" />;
            }
            return <Redirect to={redirect ? decodeURIComponent(redirect) : "/"} />;
        }

        return (<>
            <div className="col-xl-6 col-lg-8 col-12 mx-auto">
                {/*<Stepper alternativeLabel className="my-auto overflow-auto" style={{backgroundColor:"transparent"}}>
                    <Step active={this.state.step >= 1} key="step1">
                        <StepLabel>Create a project</StepLabel>
                    </Step>
                    <Step active={this.state.step >= 2} key="step2">
                        <StepLabel>Add Tracking Code</StepLabel>
                    </Step>
                    <Step active={this.state.step >= 3} key="step3">
                        <StepLabel>SEO Audit</StepLabel>
                    </Step>
                </Stepper>*/}
                {this.state.step === 1 && <CreateProjectStep nextStep={handleNextStep} />}
                {this.state.step === 2 && !currentProject && <Spinner />}
                {this.state.step === 2 && currentProject && currentProject.data && <AddTrackingCodeStep currentProject={currentProject} nextStep={handleNextStep} />}
                {this.state.step === 3 && !currentProject && <Spinner />}
                {this.state.step === 3 && currentProject && currentProject.data && <SEOAuditStep currentProject={currentProject} nextStep={handleNextStep} />}
            </div>
        </>);
    }
}

const mapStateToProps = ({ projects: { currentProject, currentProjectId } }) => ({
    currentProject, currentProjectId
});

export default injectIntl(connect(
    mapStateToProps,
    projects.actions
)(CreateProjectProcess));
