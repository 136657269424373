import React from "react";
import {useRef,useEffect} from 'react'
import {Portlet, PortletBody, PortletHeader} from "../../partials/content/Portlet";
import {Form, ButtonToolbar, Button, Table, Alert, Col, Card} from "react-bootstrap";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as cart from "../../store/ducks/cart.duck";
import * as profile from "../../store/ducks/profile.duck";
import * as notifications from "../../store/ducks/notifications.duck";
import * as projects from "../../store/ducks/projects.duck";
import { Formik } from "formik";
import Select from "react-select";
import {Spinner} from "reactstrap";
import {addOrder, calculateVat, stripePay, validateVat, stripeInitCheckoutSession, paypalInitCheckout} from "../../crud/orders.crud";
import { PayPalButton } from "react-paypal-button-v2";
import {Radio, RadioGroup, FormControl, FormControlLabel, Typography, Checkbox} from "@material-ui/core";
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, ElementsConsumer} from "@stripe/react-stripe-js";
import {Link, Redirect} from "react-router-dom";
import Countdown from "react-countdown";
import * as marketplace from "../../store/ducks/marketplace.duck";
import * as pagedata from "../../store/ducks/page.duck";

import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

//Stripe test key
const stripePromise = loadStripe(window.stripePublicKey ? window.stripePublicKey : 'pk_test_51KG62pBsq5esrSnfPPrQkrw3wkKLbNBTuAKU9LtZ6ENh4iA3NTD26H7eeZm0JM0g1HcMMxPLpzTLsCIGvry41mEq00pKAtVjEO');

const countryList = {
    'AF':'Afghanistan',
    'AX':'Åland Islands',
    'AL':'Albania',
    'DZ':'Algeria',
    'AS':'American Samoa',
    'AD':'Andorra',
    'AO':'Angola',
    'AI':'Anguilla',
    'AQ':'Antarctica',
    'AG':'Antigua and Barbuda',
    'AR':'Argentina',
    'AM':'Armenia',
    'AW':'Aruba',
    'AU':'Australia',
    'AT':'Austria',
    'AZ':'Azerbaijan',
    'BS':'Bahamas',
    'BH':'Bahrain',
    'BD':'Bangladesh',
    'BB':'Barbados',
    'BY':'Belarus',
    'BE':'Belgium',
    'PW':'Belau',
    'BZ':'Belize',
    'BJ':'Benin',
    'BM':'Bermuda',
    'BT':'Bhutan',
    'BO':'Bolivia',
    'BQ':'Bonaire, Saint Eustatius and Saba',
    'BA':'Bosnia and Herzegovina',
    'BW':'Botswana',
    'BV':'Bouvet Island',
    'BR':'Brazil',
    'IO':'British Indian Ocean Territory',
    'BN':'Brunei',
    'BG':'Bulgaria',
    'BF':'Burkina Faso',
    'BI':'Burundi',
    'KH':'Cambodia',
    'CM':'Cameroon',
    'CA':'Canada',
    'CV':'Cape Verde',
    'KY':'Cayman Islands',
    'CF':'Central African Republic',
    'TD':'Chad',
    'CL':'Chile',
    'CN':'China',
    'CX':'Christmas Island',
    'CC':'Cocos (Keeling) Islands',
    'CO':'Colombia',
    'KM':'Comoros',
    'CG':'Congo (Brazzaville)',
    'CD':'Congo (Kinshasa)',
    'CK':'Cook Islands',
    'CR':'Costa Rica',
    'HR':'Croatia',
    'CU':'Cuba',
    'CW':'Cura&ccedil;ao',
    'CY':'Cyprus',
    'CZ':'Czech Republic',
    'DK':'Denmark',
    'DJ':'Djibouti',
    'DM':'Dominica',
    'DO':'Dominican Republic',
    'EC':'Ecuador',
    'EG':'Egypt',
    'SV':'El Salvador',
    'GQ':'Equatorial Guinea',
    'ER':'Eritrea',
    'EE':'Estonia',
    'ET':'Ethiopia',
    'FK':'Falkland Islands',
    'FO':'Faroe Islands',
    'FJ':'Fiji',
    'FI':'Finland',
    'FR':'France',
    'GF':'French Guiana',
    'PF':'French Polynesia',
    'TF':'French Southern Territories',
    'GA':'Gabon',
    'GM':'Gambia',
    'GE':'Georgia',
    'DE':'Germany',
    'GH':'Ghana',
    'GI':'Gibraltar',
    'GR':'Greece',
    'GL':'Greenland',
    'GD':'Grenada',
    'GP':'Guadeloupe',
    'GU':'Guam',
    'GT':'Guatemala',
    'GG':'Guernsey',
    'GN':'Guinea',
    'GW':'Guinea-Bissau',
    'GY':'Guyana',
    'HT':'Haiti',
    'HM':'Heard Island and McDonald Islands',
    'HN':'Honduras',
    'HK':'Hong Kong',
    'HU':'Hungary',
    'IS':'Iceland',
    'IN':'India',
    'ID':'Indonesia',
    'IR':'Iran',
    'IQ':'Iraq',
    'IE':'Ireland',
    'IM':'Isle of Man',
    'IL':'Israel',
    'IT':'Italy',
    'CI':'Ivory Coast',
    'JM':'Jamaica',
    'JP':'Japan',
    'JE':'Jersey',
    'JO':'Jordan',
    'KZ':'Kazakhstan',
    'KE':'Kenya',
    'KI':'Kiribati',
    'KW':'Kuwait',
    'KG':'Kyrgyzstan',
    'LA':'Laos',
    'LV':'Latvia',
    'LB':'Lebanon',
    'LS':'Lesotho',
    'LR':'Liberia',
    'LY':'Libya',
    'LI':'Liechtenstein',
    'LT':'Lithuania',
    'LU':'Luxembourg',
    'MO':'Macao',
    'MK':'North Macedonia',
    'MG':'Madagascar',
    'MW':'Malawi',
    'MY':'Malaysia',
    'MV':'Maldives',
    'ML':'Mali',
    'MT':'Malta',
    'MH':'Marshall Islands',
    'MQ':'Martinique',
    'MR':'Mauritania',
    'MU':'Mauritius',
    'YT':'Mayotte',
    'MX':'Mexico',
    'FM':'Micronesia',
    'MD':'Moldova',
    'MC':'Monaco',
    'MN':'Mongolia',
    'ME':'Montenegro',
    'MS':'Montserrat',
    'MA':'Morocco',
    'MZ':'Mozambique',
    'MM':'Myanmar',
    'NA':'Namibia',
    'NR':'Nauru',
    'NP':'Nepal',
    'NL':'Netherlands',
    'NC':'New Caledonia',
    'NZ':'New Zealand',
    'NI':'Nicaragua',
    'NE':'Niger',
    'NG':'Nigeria',
    'NU':'Niue',
    'NF':'Norfolk Island',
    'MP':'Northern Mariana Islands',
    'KP':'North Korea',
    'NO':'Norway',
    'OM':'Oman',
    'PK':'Pakistan',
    'PS':'Palestinian Territory',
    'PA':'Panama',
    'PG':'Papua New Guinea',
    'PY':'Paraguay',
    'PE':'Peru',
    'PH':'Philippines',
    'PN':'Pitcairn',
    'PL':'Poland',
    'PT':'Portugal',
    'PR':'Puerto Rico',
    'QA':'Qatar',
    'RE':'Reunion',
    'RO':'Romania',
    'RU':'Russia',
    'RW':'Rwanda',
    'BL':'Saint Barth&eacute;lemy',
    'SH':'Saint Helena',
    'KN':'Saint Kitts and Nevis',
    'LC':'Saint Lucia',
    'MF':'Saint Martin (French part)',
    'SX':'Saint Martin (Dutch part)',
    'PM':'Saint Pierre and Miquelon',
    'VC':'Saint Vincent and the Grenadines',
    'SM':'San Marino',
    'ST':'S&atilde;o Tom&eacute; and Pr&iacute;ncipe',
    'SA':'Saudi Arabia',
    'SN':'Senegal',
    'RS':'Serbia',
    'SC':'Seychelles',
    'SL':'Sierra Leone',
    'SG':'Singapore',
    'SK':'Slovakia',
    'SI':'Slovenia',
    'SB':'Solomon Islands',
    'SO':'Somalia',
    'ZA':'South Africa',
    'GS':'South Georgia/Sandwich Islands',
    'KR':'South Korea',
    'SS':'South Sudan',
    'ES':'Spain',
    'LK':'Sri Lanka',
    'SD':'Sudan',
    'SR':'Suriname',
    'SJ':'Svalbard and Jan Mayen',
    'SZ':'Swaziland',
    'SE':'Sweden',
    'CH':'Switzerland',
    'SY':'Syria',
    'TW':'Taiwan',
    'TJ':'Tajikistan',
    'TZ':'Tanzania',
    'TH':'Thailand',
    'TL':'Timor-Leste',
    'TG':'Togo',
    'TK':'Tokelau',
    'TO':'Tonga',
    'TT':'Trinidad and Tobago',
    'TN':'Tunisia',
    'TR':'Turkey',
    'TM':'Turkmenistan',
    'TC':'Turks and Caicos Islands',
    'TV':'Tuvalu',
    'UG':'Uganda',
    'UA':'Ukraine',
    'AE':'United Arab Emirates',
    'GB':'United Kingdom (UK)',
    'US':'United States (US)',
    'UM':'United States (US) Minor Outlying Islands',
    'UY':'Uruguay',
    'UZ':'Uzbekistan',
    'VU':'Vanuatu',
    'VA':'Vatican',
    'VE':'Venezuela',
    'VN':'Vietnam',
    'VG':'Virgin Islands (British)',
    'VI':'Virgin Islands (US)',
    'WF':'Wallis and Futuna',
    'EH':'Western Sahara',
    'WS':'Samoa',
    'YE':'Yemen',
    'ZM':'Zambia',
    'ZW':'Zimbabwe',
};

const pageName = 'checkout';

const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 })};
class Checkout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showError: false,
            errorMessage: "",
			errorPayment: '',
            showPaymentBtn: false,
            rightHeight: "790px",
            paymentCompleted: false,
            paypalBtnReady: false,
            paypalProcessing: false,
            payment_method: "paypal",
            userArticle:true,
            isProcessing: false,
            useRemainingBalance: null,
            userBalance: 0,
            addOnItems: "",
            projectId: null,
            firstLoad: true,
            alreadySentGAEvent: false
        };
        
        this.initialValues = null;
        this.paypalProcessing = React.createRef();
        this.paypalButtonToolbar = React.createRef();
    }

    componentDidMount() {
        const projectId = this.props.currentProjectId;
        this.state.projectId = projectId;

        this.props.initProjects();
        this.props.initBillingInfo();
        this.props.initCart();
        this.props.requestUserDiscount();
        this.props.initMarketplace(1, {});
        this.props.requestPageData(pageName);

        if(projectId > 0) {
            this.props.requestProject(this.props.currentProjectId);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.currentProjectId > 0 && this.props.currentProjectId !== prevProps.currentProjectId) {
            this.props.requestProject(this.props.currentProjectId);
            this.state.projectId = this.props.currentProjectId;
        }
    }

    render() {
        const { intl, cartItems, cartSubtotal, cartDiscount, taxTotal, cartTotal, userDiscountPercent, discountDate, currentDate, billingInfo, currentProjectId, projects, guestPosts, accessDenied, pageData} = this.props;
        const project = this.props.currentProject;
        let mgpCount = 0;
        const discountDateObj = discountDate && discountDate["marketplace_guest_post"] ? Date.parse(discountDate["marketplace_guest_post"]) : "";
        const currentDateObj = currentDate ? Date.parse(currentDate) : "";
        let dateDiff = 0;
        if(discountDateObj > currentDateObj) {
            dateDiff = discountDateObj - currentDateObj;
        }

        // console.log('@@@@@@, currentProjectId = '+this.props.currentProjectId);
        // console.log('AAAAAA, projectId = '+this.state.projectId);
        // console.log('BBBBBB, projectId = '+this.state.projectId);

        if (typeof this.props.currentProjectId !== 'undefined') {
            this.state.projectId = this.props.currentProjectId;
        }

        // const stripe = useStripe();

        // if(!currentProjectId || (project && !project.data)) {
        //     return (
        //         <p>Before making orders choose a project here: <Link to="/projects" onClick={()=>{this.props.hideCheckout();}}>Projects Page</Link></p>
        //     );
        // }
        // else if(!project || !projects) {
        //     return <Spinner/>;
        // }

        // if (pageData != null) {
        //     if (pageData.add_ons != null) {
        //         // console.log(pageData.add_ons);
        //         this.addOnItems = pageData.add_ons;
        //     }
        // }

        const moneyFormatter = (num) => {
            if (typeof num !== 'undefined') {
                try {
                    var calc = num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('.00','');
                    // var calc = num.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    return '$'+calc;
                } catch (e) {
                    console.log('moneyFormatter',e);
                    return '$0.00';
                }
            }
            return '$'+num;
        }

        const countries = [{value:"", label:"Country*"}];
        for (const [index] of Object.entries(countryList)) {
            countries.push({value:countryList[index], label:countryList[index]});
        }

        const getCountryCode = (country) => {
            let countryCode = "";
            for (const [index] of Object.entries(countryList)) {
                if(countryList[index] === country) {
                    countryCode = index;
                }
            }
            return countryCode;
        };

        if(this.state.paymentCompleted) {
            return (
                <>
                    <Alert variant="success">
                        Payment completed.
                    </Alert>
                    <p>Redirecting to the order page...</p>
                </>
            );
        }

        if(this.state.showError) {
            if (this.state.errorMessage == "You don't have enough account balance.") {
                return (
                    <>
                        <Alert variant="danger">
                            {this.state.errorMessage}
                        </Alert>
                        <Link className="btn btn-brand" onClick={()=>{this.props.hideCheckout();}} to="/profile">Deposit Funds</Link>
                    </>
                );
            } else {
                return (
                    <>
                        <Alert variant="danger">
                            {this.state.errorMessage}
                        </Alert>
                    </>
                );
            }
        }

        if(cartItems && cartItems.length === 0) {
            return (
                <>
                    <h1>Cart is empty.</h1>
                    <p>
                    </p>
                </>
            );
        }

        if(cartItems === undefined || userDiscountPercent === undefined || billingInfo === undefined) {
            return (<Spinner/>);
            
        } else
        if (typeof cartItems !== undefined && cartItems && cartItems.length > 0 && this.state.firstLoad == true && this.state.alreadySentGAEvent == false) {
            // console.log('HERE AT ReactGA event ************** alreadySentGAEvent='+this.state.alreadySentGAEvent+' && firstLoad='+this.state.firstLoad);
            this.state.firstLoad = false;
            this.state.alreadySentGAEvent = true;

            ReactGA.event({
                category: 'Checkout',
                action: 'began-checkout',
                label: 'Visit checkout with items in cart',
                nonInteraction: false
            });
            ReactPixel.track("began-checkout", {value: cartItems.length});

            this.setState({
                ...this.state,
                firstLoad: false,
                alreadySentGAEvent: true
            });
        }

        const cartItemPlus = (key) => {
            if(key) {
                const item = cartItems.find(item => {
                    if (item.key === key) {
                        return item;
                    }
                });
                if(item) {
                    this.props.updateCartItem(key, item.orderData.quantity + 1);
                }
            }
        };
    
        const cartItemMinus = (key) => {
            if(key) {
                const item = cartItems.find(item => {
                    if (item.key === key) {
                        return item;
                    }
                });
                if(item) {
                    if (item.orderData.quantity > 1) {
                        this.props.updateCartItem(key, item.orderData.quantity - 1);
                    } else {
                        this.props.removeCartItem(key);
                    }
                }
            }
        };

        // (event, cartItems[i], i, cartItems[i].key, addOnItem);}}
        const handleAddOnCheckboxChange = (event, item_to_add, cart_item_index, key, addOnItem) => {
            let cartItem = item_to_add;
            if (cartItem.orderData.add_ons == null) {
                cartItem.orderData.add_ons = [];
            }
            if (event.target.checked) {
                cartItem.orderData.add_ons.push(addOnItem);
            } else {
                let remove_add_on = cartItem.orderData.add_ons.filter((item) => item.id !== addOnItem.id);
                cartItem.orderData.add_ons = remove_add_on;
            }
            this.props.updateCartItemData(key, cartItem);
        }

        if(cartItems.length !== 0) {
            for (let i = 0; i < cartItems.length; i++) {
                if (cartItems[i].orderData.type === "marketplace_guest_post") {
                    mgpCount += (cartItems[i].orderData.quantity > 0 ? cartItems[i].orderData.quantity : 1);
                }
            }
            if(mgpCount > 0) {
                if(!guestPosts) {
                    return (<Spinner/>);
                }
                if(accessDenied) {
                    return <Redirect to="/packages/marketplace-access" />;
                }
                //check limits
                const limitInfo = guestPosts && guestPosts.limit ? guestPosts.limit : {};
                if(limitInfo.value < limitInfo.usage + mgpCount) {
                    return (<>
                        <p>You have reached your monthly order limit for Guest Posts Marketplace. You can upgrade your plan by buying new subscription <Link to="/packages/marketplace-access" onClick={()=>{this.props.hideCheckout();}}>here</Link>.</p>
                        <Link className="btn btn-primary" to="/packages/marketplace-access" onClick={()=>{this.props.hideCheckout();}}>Upgrade Plan</Link>
                    </>);
                }
            }
        }

        const cartRows = [];
        if(cartItems && cartItems.length !== 0) {
            for(let i = 0; i < cartItems.length; i++) {
                let addOnRows = [];
                let last_section_name = '';
                let calcDisplayPrice = parseFloat(cartItems[i].price);

                if (cartItems[i].orderData.add_ons != null && cartItems[i].orderData.add_ons.length > 0) {
                    for(let ao = 0; ao < cartItems[i].orderData.add_ons.length; ao++) {
                        calcDisplayPrice += parseFloat(cartItems[i].orderData.add_ons[ao].add_on_price);
                    }
                }

                if (pageData != null && pageData.add_ons != null && pageData.add_ons.length > 0) {
                    for(let ao = 0; ao < pageData.add_ons.length; ao++) {
                        let addOnItem = pageData.add_ons[ao];

                        if (
                            (cartItems[i].name == "Link Insertion" && addOnItem.item_type == "link_insertion")
                            ||
                            (cartItems[i].name == "Guest Post" && addOnItem.item_type == "guest_post")
                            ||
                            (cartItems[i].name == "Marketplace Guest Post" && addOnItem.item_type == "marketplace_guest_post")
                        ) {

                            let isChecked = false;
                            if (cartItems[i].orderData.add_ons != null) {
                                for(let aoc = 0; aoc < cartItems[i].orderData.add_ons.length; aoc++) {
                                    if (addOnItem.id == cartItems[i].orderData.add_ons[aoc].id) {
                                        isChecked = true;
                                    }
                                }
                            }

                            if (addOnItem.section_name == last_section_name) {

                            } else {
                                last_section_name = addOnItem.section_name;
                                addOnRows.push(
                                    <div className="row">
                                        <div className="col-12 pl-3 mt-2">
                                            <div className="kt-mycart__title font-15">{last_section_name}</div>
                                        </div>
                                    </div>
                                );
                            }

                            addOnRows.push(
                                <div className="row">
                                    <div className="col-12 pl-3 mt-0">
                                        <FormControlLabel
                                            className="mb-0"
                                            checked={isChecked}
                                            control={
                                                <Checkbox
                                                    checked={isChecked}
                                                    onChange={(event)=>{handleAddOnCheckboxChange(event, cartItems[i], i, cartItems[i].key, addOnItem);}}
                                                    value={addOnItem.id}
                                                    color="primary"
                                                />
                                            }
                                            label={addOnItem.add_on_name+" (+" + (addOnItem.add_on_price ? moneyFormatter(addOnItem.add_on_price) : '$0.00') + ")"}
                                        />
                                    </div>
                                </div>
                            );

                        }
                    }
                }

                // console.log(cartItems[i]);
                cartRows.push(
                    <div key={"ci" + i} className={i == 0 ? "kt-mycart__item pb-4" : "kt-mycart__item py-4"}>
                        <div className="kt-mycart__container p-0">
                            <div className="kt-mycart__info">
                                <Typography className="item_type_style">
                                    {cartItems[i].name}
                                </Typography>
                                {cartItems[i].key == 'wlo_mpgs' ? 
                                    <>
                                        <span className="kt-mycart__desc" style={{paddingBottom:'0px'}}>
                                            {cartItems[i].description}
                                        </span>
                                        <span className="kt-mycart__desc kt-font-danger" style={{paddingTop:'2px'}}>{moneyFormatter(cartItems[i].discount_amount)} off</span>
                                    </>
                                :
                                <span className="kt-mycart__desc pt-0 pb-0">
                                    {cartItems[i].description}
                                </span>}
                                <div className="kt-mycart__action">
                                    <span className="kt-mycart__price">{cartItems[i].orderData.quantity + " × "} {moneyFormatter(calcDisplayPrice)}</span>
                                    {/* <span className="kt-mycart__text"></span>
                                    <span className="btn btn-label-success btn-icon" onClick={() => cartItemMinus(cartItems[i].key)}>
                                    −
                                    </span>
                                        <span className="btn btn-label-success btn-icon" onClick={() => cartItemPlus(cartItems[i].key)}>
                                        +
                                    </span> */}
                                </div>
                            </div>
                        </div>
                        {/* {addOnRows} */}
                    </div>
                );
            }
        }
        else {
            //
        }

        if (mgpCount == 0) {
            this.state.rightHeight = "740px";
        }

        const articlePrice = guestPosts && guestPosts.article_price ? guestPosts.article_price : 0;

        if(!this.initialValues) {
            this.initialValues = {
                firstname: billingInfo && billingInfo.firstname ? billingInfo.firstname :"",
                lastname: billingInfo && billingInfo.lastname ? billingInfo.lastname :"",
                company_name: billingInfo && billingInfo.company ? billingInfo.company :"",
                street_address: billingInfo && billingInfo.address ? billingInfo.address :"",
                postal_code: billingInfo && billingInfo.postal_code ? billingInfo.postal_code :"",
                city: billingInfo && billingInfo.city ? billingInfo.city :"",
                country: billingInfo && billingInfo.country ? billingInfo.country :"",
                tax_id: billingInfo && billingInfo.taxid ? billingInfo.taxid :"",
                payment_method: "paypal",
                payment_order_id: "",
                project_id: typeof projects !== 'undefined' && projects.data && projects.data.length && projects.data.length > 0 ? projects.data[0].id : null,
            }
        }
        if(this.state.payment_method ) {
            this.initialValues.payment_method = this.state.payment_method;
        }
        let user_balance = 0;
        if (billingInfo) {
            if (typeof billingInfo.user_balance !== 'undefined') {
                user_balance = billingInfo.user_balance;
                this.state.userBalance = billingInfo.user_balance;
                // this.setState({
                //     ...this.state,
                //     userBalance: user_balance,
                // });
            }
        }

        /*const handleProjectChange = (e) => {
            const projectId = e.target.value;
            if(projectId > 0) {
                this.props.requestProject(projectId);
            }
        };*/

        const projectRows = [];
        projectRows.push(<option key={"empty"} value={null} />);
        let project_counter = 0;
        if (typeof projects !== 'undefined') {
            if (projects.data) {
                if (projects.data.length) {
                    for (const [index] of projects.data.entries()) {
                        project_counter++;
                        if (project_counter == 1) {
                            projectRows.push(<option selected="selected" key={index} value={projects.data[index].id}>{projects.data[index].url}</option>)
                        } else {
                            projectRows.push(<option key={index} value={projects.data[index].id}>{projects.data[index].url}</option>)
                        }
                    }
                }
            }
        }

        const updateMarketplaceCart = (userArticle) => {
            if(cartItems.length !== 0) {
                for (let i = 0; i < cartItems.length; i++) {
                    if (cartItems[i].orderData.type === "marketplace_guest_post") {
                        //update cart item
                        let itemData = cartItems[i];

                        var updateCartItemData = false;

                        if(userArticle) {

                            if (cartItems[i].orderData.options.marketplace_article == 1) {
                                itemData.orderData.options.marketplace_article = null;
                                itemData.orderData.options.toggle_user_article_from_cart = null;
                                updateCartItemData = true;
                            }

                        } else if(!userArticle) {

                            if (! cartItems[i].orderData.options.marketplace_article || cartItems[i].orderData.options.marketplace_article == 0 ) {
                                itemData.orderData.options.marketplace_article = 1;
                                itemData.orderData.options.toggle_user_article_from_cart = 1;
                                updateCartItemData = true;
                            }
                        }

                        if (updateCartItemData) {
                            this.props.updateCartItemData(itemData.key, itemData);
                        }
                    }
                }
            }
        };


        updateMarketplaceCart(this.state.userArticle);

        const payPalClientKey = window.paypalClientId ? window.paypalClientId : "AZZBIPijonJxCr2RRK1S-rEMATsT3RkSXifg3JENAoQ4n9PJuQXG84vhz98B6wv6slUP29sKYgAxiASg";
        const payPalCheckoutURL = window.paypalClientId != 'AZZBIPijonJxCr2RRK1S-rEMATsT3RkSXifg3JENAoQ4n9PJuQXG84vhz98B6wv6slUP29sKYgAxiASg' ? 'https://www.sandbox.paypal.com/cgi-bin/webscr' : 'https://www.paypal.com/cgi-bin/webscr';
        const paypalEmail = window.paypalClientId != 'AZZBIPijonJxCr2RRK1S-rEMATsT3RkSXifg3JENAoQ4n9PJuQXG84vhz98B6wv6slUP29sKYgAxiASg' ? 'sb-0spps16767155@business.example.com' : 'payments@serpchampion.com';


        if ((this.state.payment_method == 'stripe' || this.state.payment_method == 'paypal') 
            && user_balance > 0 && user_balance < cartTotal
        ) {
            if (this.state.useRemainingBalance == null) {
                if (typeof localStorage.getItem('useRemainingBalance') !== 'undefined' 
                    && (localStorage.getItem('useRemainingBalance') == true || localStorage.getItem('useRemainingBalance') == 'true')
                ) {
                    this.setState({
                        ...this.state,
                        useRemainingBalance: true,
                    });
                } else {
                    this.setState({
                        ...this.state,
                        useRemainingBalance: false,
                    });
                }
            }
        } else if (this.state.useRemainingBalance == null) {
            this.setState({
                ...this.state,
                useRemainingBalance: false,
            });
        }
        // console.log('this.state.useRemainingBalance',this.state.useRemainingBalance);
        // console.log('localStorage useRemainingBalance',localStorage.getItem('useRemainingBalance'));


        return (
            <>
                <Formik
                    initialValues={this.initialValues}
                    // validateOnMount
                    validate={async values => {
                        const errors = {};

                        if (!values.firstname) {
                            errors.firstname = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        if (!values.lastname) {
                            errors.lastname = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        if (!values.street_address) {
                            errors.street_address = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        if (!values.city) {
                            errors.city = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        if (!values.postal_code) {
                            errors.postal_code = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        // if (!values.project_id) {
                        //     errors.project_id = intl.formatMessage({
                        //         id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        //     });
                        // }

                        if (!values.country) {
                            errors.country = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }

                        if(values.country) {
                            const countryCode = getCountryCode(values.country);
                            if (values.tax_id) {
                                await validateVat({
                                    vat_number_field: values.tax_id.replace(" ", ""),
                                    country_code_field: countryCode
                                })
                                    .then((res) => {
                                        if (res.data && (!res.data.exist || !res.data.formatted)) {
                                            errors.tax_id = "Incorrect VAT number";
                                        }
                                        if (res.data && res.data.taxRate >= 0) {
                                            this.props.updateTaxes(res.data.taxRate);
                                        }
                                    })
                                    .catch((e) => {
                                        errors.tax_id = "Incorrect VAT number";
                                        calculateVat({
                                            vat_number_field: values.tax_id.replace(" ", ""),
                                            country_code_field: countryCode
                                        })
                                            .then((res) => {
                                                if (res.data && res.data.taxRate >= 0) {
                                                    this.props.updateTaxes(res.data.taxRate);
                                                }
                                            });
                                    });
                            } else {
                                calculateVat({country_code_field: countryCode})
                                    .then((res) => {
                                        if (res.data && res.data.taxRate >= 0) {
                                            this.props.updateTaxes(res.data.taxRate);
                                        }
                                    });
                            }
                        }
                    if (!this.state.payment_method) {
                    errors.paymentType = "Please check a payment method before clicking deposit."
                    this.setState({errorPayment: errors.paymentType});

                }
                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting, validate }) => {

                        const countryCode = getCountryCode(values.country);

                        let use_remaining_balance = 0;
                        if (this.state.userBalance > 0 && this.state.userBalance < cartTotal && this.state.useRemainingBalance) {
                            use_remaining_balance = 1;
                            localStorage.setItem('useRemainingBalance', true);
                        } else {
                            localStorage.setItem('useRemainingBalance', false);
                        }
                        // console.log("onSubmitonSubmitonSubmitonSubmitonSubmitonSubmitonSubmitonSubmitonSubmit");
                        // console.log("use_remaining_balance = " + use_remaining_balance);
                        // console.log("user_balance = " + user_balance);
                        // console.log("cartTotal = " + cartTotal);
                        // console.log("this.state.userBalance = " + this.state.userBalance);
                        // console.log("this.state.useRemainingBalance = " + this.state.useRemainingBalance);
                        // console.log("====================================================");
                        // return false;

                        let billingInfo = {
                            firstname: values.firstname,
                            lastname: values.lastname,
                            company_name: values.company_name,
                            address: values.street_address,
                            postal_code: values.postal_code,
                            city: values.city,
                            country: values.country,
                            tax_id: values.tax_id.replace(" ", ""),
                            payment_method: values.payment_method,
                            payment_order_id: values.payment_order_id,
                            country_code: countryCode
                        };
                        let orderData = {};
                        if(cartItems && cartItems.length !== 0) {
                            // console.log("payment_method", values.payment_method);
                            // console.log("payment_order_id",values.payment_order_id);
                            if (values.payment_method == "stripe" && (values.payment_order_id == null || values.payment_order_id == '')) {

                            } else {
                                for (let i = 0; i < cartItems.length; i++) {
                                    // onsole.log('AAA cartItems',cartItems[i]);
                                    orderData[i] = cartItems[i].orderData;
                                }

                                addOrder(orderData, billingInfo, use_remaining_balance, values.project_id)
                                    .then((res) => {
                                        const {id, order_number} = res.data.data;

                                        // ReactGA.plugin.require('ec');
                                        // for (let i = 0; i < cartItems.length; i++) {
                                        //     let itemPrice = Number(cartItems[i].price) * Number(cartItems[i].orderData.quantity);
                                        //     ReactGA.plugin.execute('ec', 'addProduct', {
                                        //         id: cartItems[i].key,
                                        //         name: cartItems[i].name,
                                        //         category: cartItems[i].orderData.type,
                                        //         variant: cartItems[i].description,
                                        //         price: itemPrice,
                                        //         quantity: Number(cartItems[i].orderData.quantity)
                                        //     });
                                        // }
                                        // ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
                                        //     id: order_number,
                                        //     revenue: cartTotal.toFixed(2)
                                        // });
                                        // ReactGA.ga('send', 'event', 'Checkout', 'Purchase');
                                        // ReactPixel.track("Purchase", {value: cartTotal.toFixed(2), currency: 'USD'});

                                        ReactGA.event({
                                            category: 'Checkout',
                                            action: 'Purchase',
                                            label: 'Successful Checkout',
                                            nonInteraction: false,
                                            value: Number(cartTotal)
                                        });
                                        ReactPixel.track("Purchase", {value: cartTotal.toFixed(2), currency: 'USD'});

                                        // this.paypalProcessing.current.style.display = "none";
                                        // this.paypalButtonToolbar.current.style.display = "flex";

                                        //show payment completed message
                                        this.setState({
                                            ...this.state,
                                            paymentCompleted: true,
                                            showPaymentBtn: false,
                                            paypalProcessing: false,
                                            isProcessing: false,
                                        });

                                        this.props.emptyCart();
                                        this.props.hideCheckout();
                                        this.props.initBillingInfo();
                                        this.props.requestNotifications();
                                        this.props.history.push({
                                            pathname: "/orders/" + id + "/progress"
                                        });
                                    })
                                    .catch(e => {
                                        setSubmitting(false);
                                        let errorMessage = "";
                                        if (e.response) {
                                            
                                            errorMessage = e.response.data.message;
                                        } else {
                                            // alert("Error! Try again later.");
                                            errorMessage = "Something went wrong. Please contact support or refresh and try again.";
                                        }

                                        // this.paypalProcessing.current.style.display = "none";
                                        // this.paypalButtonToolbar.current.style.display = "flex";

                                        //show payment completed message
                                        this.setState({
                                            ...this.state,
                                            showError: true,
                                            errorMessage: errorMessage,
                                            paymentCompleted: false,
                                            showPaymentBtn: false,
                                            paypalProcessing: false,
                                            isProcessing: false,
                                        });
                                    });

                                // this.paypalProcessing.current.style.display = "none";
                                // this.paypalButtonToolbar.current.style.display = "flex";
                                // //show payment completed message
                                // this.setState({
                                //     ...this.state,
                                //     paymentCompleted: true,
                                //     showPaymentBtn: false,
                                //     paypalProcessing: false,
                                //     isProcessing: false,
                                // });
                            }
                        }
                    }}
                >
                    {({
                            values,
                            status,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                            validateForm
                        }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <div className="row pt-2" style={{marginLeft:0,marginRight:0}}>
                                <div className="col-lg-5 col-md-9" style={{paddingLeft:0,paddingRight:0}}>
                                    <div className="progress mb-4 mt-4" style={{background:"#ccd1f4"}}>
                                        <div className="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width:"70%"}}></div>
                                    </div>
                                    <Portlet>
                                        <PortletBody className="mw-position-orders-area-xl-wide">
                                            <h3 className="mt-2" style={{marginBottom:"30px",fontSize:"1.6rem"}}>Fill in your billing details to place your order</h3>

                                            <div className="row">
                                                <Form.Group className="col-12" as={Col} controlId="formProject">
                                                    <Typography variant="h6">Assign this order to project</Typography>
                                                    <Form.Control as="select" name="project_id" 
                                                        onChange={(e)=>{
                                                            this.setState({...this.state, projectId: e.target.value});
                                                            handleChange("project_id")(e.target.value);
                                                        }}
                                                        isInvalid={!!errors.project_id}>
                                                        {projectRows}
                                                    </Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.project_id}
                                                    </Form.Control.Feedback>
                                                    <Link to="/project/add" onClick={()=>{this.props.hideCheckout();}}>Add new project</Link>
                                                </Form.Group>
                                            </div>
                                            {!this.state.showPaymentBtn && <div className="col-xl-12 mb-2 px-0">       
                                                <div className="row">
                                                    <Form.Group className="col-md-6 kt-mb-10" controlId="formFirstName">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="First Name*"
                                                            name="firstname"
                                                            value={values.firstname}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.firstname}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.firstname}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group className="col-md-6 kt-mb-10" controlId="formLastName">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Last Name*"
                                                            name="lastname"
                                                            value={values.lastname}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.lastname}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.lastname}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                <div className="row">
                                                    <Form.Group className="col-md-6 kt-mb-10" controlId="formCompanyName">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Company Name"
                                                            name="company_name"
                                                            value={values.company_name}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.company_name}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.company_name}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group className="col-md-6 kt-mb-10" controlId="formStreetAddress">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Street Address*"
                                                            name="street_address"
                                                            value={values.street_address}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.street_address}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.street_address}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                <div className="row">
                                                    <Form.Group className="col-md-6 kt-mb-10" controlId="formPostalCode">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Postal Code*"
                                                            name="postal_code"
                                                            value={values.postal_code}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.postal_code}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.postal_code}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>

                                                    <Form.Group className="col-md-6 kt-mb-10" controlId="formCity">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="City*"
                                                            name="city"
                                                            value={values.city}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.city}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.city}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                <div className="row">
                                                    <Form.Group className="col-md-6 kt-mb-10" controlId="formCountry">
                                                        <Select
                                                            options={countries}
                                                            defaultValue={{value:values.country, label:values.country}}
                                                            onChange={selectedOption => {
                                                                handleChange("country")(selectedOption.value);
                                                            }}
                                                            noOptionsMessage={()=>{return !countries ? "Loading..." : "No options";}}
                                                            styles={selectStyles}
                                                        />
                                                        <div className="invalid-feedback" style={{display:!!errors.country ? "block" : "none"}}>{errors.country}</div>
                                                    </Form.Group>

                                                    <Form.Group className="col-md-6 kt-mb-10" controlId="formTaxId">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="VAT Number (Only for EU Customers)"
                                                            name="tax_id"
                                                            value={values.tax_id}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.tax_id}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.tax_id}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>}
                                            
                                            <div className="col-xl-12">
                                                {/* {!this.state.showPaymentBtn && <Table bordered responsive>
                                                    <tbody>
                                                    <tr><td><strong>Subtotal</strong></td><td><strong>$ {cartSubtotal ? cartSubtotal.toFixed(2) : 0}</strong></td></tr>
                                                    {cartDiscount > 0 ? <tr><td className="w-50"><strong>Discount</strong>{dateDiff > 0 && <span> (<Countdown date={Date.now() + dateDiff} onComplete={()=>{
                                                        this.props.initCart();
                                                    }} />)</span>}</td><td><strong>- $ {cartDiscount ? cartDiscount.toFixed(2) : 0}</strong></td></tr> : <tr/>}
                                                    <tr><td><strong>Taxes</strong></td><td><strong>$ {taxTotal ? taxTotal.toFixed(2) : 0}</strong></td></tr>
                                                    <tr><td><strong>Total</strong></td><td><strong className="kt-font-brand">$ {cartTotal ? cartTotal.toFixed(2) : 0}</strong></td></tr>
                                                    </tbody>
                                                </Table>} */}
                                                {!this.state.showPaymentBtn && mgpCount > 0 && <div>
                                                    <FormControlLabel
                                                        style={{display:"none"}}
                                                        control={<Checkbox
                                                            checked={this.state.userArticle}
                                                            onChange={(e)=>{
                                                                this.setState({...this.state, userArticle: e.target.checked});
                                                            }}
                                                            value="user_article"
                                                            color="primary"
                                                        />}
                                                        // label={"I want to provide content ($" + articlePrice + " per post will be subtracted from the total)."}
                                                        label={"I need content written ($" + articlePrice + " per post will be added to the total)."}
                                                    />
                                                </div>}
                                                {(this.state.payment_method == 'stripe' || this.state.payment_method == 'paypal') && !this.state.showPaymentBtn && user_balance > 0 && user_balance < cartTotal ? 
                                                    <>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <FormControlLabel value="remaining_balance" control={<Checkbox checked={this.state.useRemainingBalance}
                                                                    color="primary"
                                                                    onChange={(e)=>{
                                                                        if (e.target.checked) {
                                                                            localStorage.setItem('useRemainingBalance', true);
                                                                        } else {
                                                                            localStorage.setItem('useRemainingBalance', false);
                                                                        }
                                                                        this.setState({...this.state, useRemainingBalance: e.target.checked});
                                                                    }} />} label="Use your remaining balance" />
                                                            </div>
                                                        </div>
                                                    </>
                                                : ""}
                                                {!this.state.showPaymentBtn && <FormControl component="fieldset" className="kt-mb-10">
                                                {errors.paymentType ? <div className="payment_error" style={{'justifyContent': "right"}}><textarea readOnly style={{'textAlign': "right", border: "none", width: "100%", color: "red", font: "-webkit-small-control", "textAlign": "left", "border": "none", "backgroundColor": "transparent", "resize": "none", "outline": "none"}} value={errors.paymentType} /></div> : null}
                                                    <div className="input-group mb-2">
                                                        Choose your payment method
                                                    </div>
                                                    <RadioGroup
                                                        name="payment_method"
                                                        value={values.payment_method}
                                                        // onClick={() => validateForm()}
                                                        onChange={(e)=> {
                                                            handleChange("payment_method")(e.target.value);
                                                            this.setState({...this.state, payment_method: e.target.value});
                                                        }}
                                                    >
                                                        <div className="row">
                                                            {user_balance >= cartTotal ? 
                                                                <>
                                                                    <div className="col-3"><FormControlLabel value="paypal" control={<Radio color="primary" />} label="PayPal" /></div>
                                                                    <div className="col-3"><FormControlLabel value="stripe" control={<Radio color="primary" />} label="Stripe" /></div>
                                                                    <div className="col-6">
                                                                        <FormControlLabel value="balance" control={<Radio color="primary" />} label="Pay with Balance" />
                                                                    </div>
                                                                </>
                                                            :
                                                                <>
                                                                    <div className="col-6"><FormControlLabel value="paypal" control={<Radio color="primary" />} label="PayPal" /></div>
                                                                    <div className="col-6"><FormControlLabel value="stripe" control={<Radio color="primary" />} label="Stripe" /></div>
                                                                </>
                                                            }
                                                        </div>
                                                    </RadioGroup>
                                                </FormControl>}

                                                {this.state.showPaymentBtn && ! this.state.isProcessing && values.payment_method === "balance" ? handleSubmit() : ""}
                                                {this.state.showPaymentBtn && ! this.state.isProcessing && values.payment_method === "balance" ? this.state.isProcessing = true : ""}


                                                {this.state.showPaymentBtn && values.payment_method === "stripe" && <Elements stripe={stripePromise}>
                                                    {/* 
                                                    // DEPRECIATED -> stripe on page payment form (search this page for this)
                                                    <InjectedCheckoutForm cartTotal={cartTotal} cartItems={cartItems} userBalance={user_balance} useRemainingBalance={this.state.useRemainingBalance} formikSubmit={handleSubmit} setFieldValue={setFieldValue} /> */}
                                                    
                                                    { !this.state.isProcessing  && ! this.state.errorPayment && (<InjectedCheckoutFormRedirect projectId={typeof values.project_id !== 'undefined' ? values.project_id : typeof this.state.projectId !== 'undefined' ? this.state.projectId : typeof projects !== 'undefined' && projects.data && projects.data.length && projects.data.length > 0 ? projects.data[0].id : null} cartTotal={cartTotal} cartItems={cartItems} values={values} useRemainingBalance={user_balance > 0 && user_balance < cartTotal && this.state.useRemainingBalance ? true : false} userBalance={user_balance} />)}
                                                    {this.state.isProcessing =true}
                                                </Elements>}


                                                <div className="justify-content-center text-center" style={{display:"none"}} ref={this.paypalProcessing}>
                                                    <p className="text-bold">Payment is processing, please wait...</p>
                                                    <Spinner size="lg" />
                                                </div>
                                                <ButtonToolbar className="justify-content-center" ref={this.paypalButtonToolbar}>
                                                    {/*this.state.paypalProcessing && <div className="text-center">
                                                        <p className="text-bold">Payment is processing, please wait...</p>
                                                        <Spinner size="lg" />
                                                    </div>*/}

                                                    {/* project_id: typeof projects !== 'undefined' && projects.data && projects.data.length && projects.data.length > 0 ? projects.data[0].id : null, */}

                                                    {!this.state.paypalProcessing && this.state.showPaymentBtn && values.payment_method === "paypal" &&
                                                        <InjectedPayPackCheckoutFormRedirect projectId={typeof values.project_id !== 'undefined' ? values.project_id : typeof this.state.projectId !== 'undefined' ? this.state.projectId : typeof projects !== 'undefined' && projects.data && projects.data.length && projects.data.length > 0 ? projects.data[0].id : null} paypalEmail={paypalEmail} payPalClientKey={payPalClientKey} payPalCheckoutURL={payPalCheckoutURL} cartTotal={cartTotal} cartItems={cartItems} values={values} useRemainingBalance={user_balance > 0 && user_balance < cartTotal && this.state.useRemainingBalance ? true : false} userBalance={user_balance} />}


                                                    {/* {!this.state.paypalProcessing && this.state.showPaymentBtn && values.payment_method === "paypal" && !this.state.paypalBtnReady && <Spinner />} */}

                                                    {/*!this.state.paypalProcessing && this.state.showPaymentBtn && values.payment_method === "paypal" && <PayPalButton
                                                        options={{
                                                            clientId: window.paypalClientId ? window.paypalClientId : "AZZBIPijonJxCr2RRK1S-rEMATsT3RkSXifg3JENAoQ4n9PJuQXG84vhz98B6wv6slUP29sKYgAxiASg",
                                                            disableFunding: "card,credit,bancontact,venmo,sepa,eps,giropay,ideal,mybank,p24,sofort",
                                                        }}
                                                        createOrder={(data, actions) => {
                                                            ReactGA.event({
                                                                category: 'Payment',
                                                                action: 'PaypalButtonClick'
                                                            });

                                                            this.paypalButtonToolbar.current.style.display = "none";
                                                            this.paypalProcessing.current.style.display = "block";
                                                            //this.setState({...this.state, paypalProcessing: true});
                                                            //const items = [];
                                                            //

                                                            let cartTotalHolder = cartTotal ? cartTotal.toFixed(2) : 0;
                                                            let use_remaining_balance = false;
                                                            if (user_balance > 0 && user_balance < cartTotal && this.state.useRemainingBalance) {
                                                                use_remaining_balance = true;
                                                                cartTotalHolder = cartTotalHolder - user_balance;
                                                            }
                                                            // console.log("PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP");
                                                            // console.log("cartTotalHolder (cartTotal - availableBalance) = " + cartTotalHolder);
                                                            // console.log("use_remaining_balance = " + use_remaining_balance);
                                                            // console.log("====================================================");
                                                            // return false;

                                                            return actions.order.create({
                                                                purchase_units: [{
                                                                    amount: {
                                                                        currency_code: "USD",
                                                                        // value: cartTotal ? cartTotal.toFixed(2) : 0
                                                                        value: cartTotalHolder
                                                                    },
                                                                    //items : items,
                                                                    //description: "test payment for guest posts"
                                                                }],
                                                                application_context: {
                                                                    shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
                                                                }
                                                            });
                                                        }}
                                                        onApprove={(data, actions) => {
                                                            ReactGA.event({
                                                                category: 'Payment',
                                                                action: 'PaypalApprovedAction'
                                                            });

                                                        

                                                            // Capture the funds from the transaction
                                                            return actions.order.capture().then(function(details) {
                                                                //add payment data to the request
                                                                // console.log('capture data', data);
                                                                setFieldValue('payment_order_id', data.orderID, false);
                                                                handleSubmit();
                                                                //this.setState({...this.state, paypalProcessing: true});
                                                            });
                                                        }}
                                                        onButtonReady={() => {this.setState({ ...this.state, paypalBtnReady: true });}}
                                                        onCancel={(object)=>{
                                                            //this.setState({...this.state, paypalProcessing: false});
                                                            this.paypalProcessing.current.style.display = "none";
                                                            this.paypalButtonToolbar.current.style.display = "flex";
                                                        }}
                                                        //onClick={()=>{this.setState({...this.state, paypalProcessing: true});}}
                                                    />*/}
                                                    {!this.state.showPaymentBtn && <Button variant="primary" className="btn-block" size="lg" onClick={() => validateForm().then((errors) => {
                                                        if(Object.keys(errors).length === 0 && errors.constructor === Object) {
                                                            let rightHeight = "740px";
                                                            if(mgpCount > 0) {
                                                                rightHeight = "790px";
                                                                this.props.requestMarketplace(1, {});
                                                            }
                                                            this.setState({...this.state, showPaymentBtn: true, rightHeight: rightHeight});
                                                        }
                                                    })} disabled={isSubmitting}>
                                                        Order Now
                                                    </Button>}
                                                </ButtonToolbar>
                                                {/* {!this.state.paypalProcessing && this.state.showPaymentBtn && values.payment_method === "paypal" ?
                                                <div className="text-center">Click on the PayPal button to complete your transaction.</div>
                                                : ''} */}
                                            </div>
                                            <div class="row">
                                                <div class="col-12 mt-3 text-center">
                                                    <img className="mt-4 mb-3 pt-2" src="https://serppro.nyc3.cdn.digitaloceanspaces.com/public/secure%20badge.png" alt="" style={{maxWidth:"300px"}} />
                                                </div>
                                            </div>

                                        </PortletBody>
                                    </Portlet>
                                </div>

                                <div class="col-lg-3 col-md-3 col-sm-4 youve_selected_right_menu">
                                    <Card style={{maxHeight:this.state.rightHeight}}>
                                        <Card.Body style={{paddingRight:"0px"}}>
                                            <Card.Title className="mb-4 mt-1">Order Overview</Card.Title>
                                                <div className="kt-mycart">
                                                    <div className="kt-mycart__body">
                                                        <div className="cart-container-scroll">
                                                            {cartRows}
                                                        </div>

                                                        <Typography className="pt-4">Subtotal</Typography>
                                                        <Typography variant="h7">{cartSubtotal ? moneyFormatter(cartSubtotal) : '$0.00'}</Typography>

                                                        {cartDiscount && cartDiscount > 0 ?
                                                            <>
                                                                <Typography className="pt-4">Discount</Typography>
                                                                <Typography variant="h7">{cartDiscount ? moneyFormatter(cartDiscount) : '$0.00'}</Typography>
                                                            </>
                                                        : ''}

                                                        {taxTotal && taxTotal > 0 ?
                                                            <>
                                                                <Typography className="pt-4">Taxes</Typography>
                                                                <Typography variant="h7">{taxTotal ? moneyFormatter(taxTotal) : '$0.00'}</Typography>
                                                            </>
                                                        : ''}

                                                        {this.state.useRemainingBalance == true && user_balance < cartTotal && user_balance > 0 ? 
                                                            <>
                                                                <Typography className="pt-4">Using balance</Typography>
                                                                <Typography variant="h7">-{user_balance ? moneyFormatter(user_balance) : '$0.00'}</Typography>
                                                                <Typography className="pt-4">Your Investment</Typography>
                                                                <Typography variant="h7" className="pb-3">{cartTotal ? moneyFormatter(cartTotal-user_balance) : '$0.00'}</Typography>
                                                            </>
                                                        : 
                                                            <>
                                                                <Typography className="pt-4">Your Investment</Typography>
                                                                <Typography variant="h7" className="pb-3">{cartTotal ? moneyFormatter(cartTotal) : '$0.00'}</Typography>
                                                            </>
                                                        }

                                                        {/* <Typography className="pt-4">Your Investment</Typography>
                                                        <Typography variant="h7" className="pb-3">${cartTotal ? cartTotal.toFixed(2) : 0}</Typography> */}

                                                        {/* <Table bordered responsive>
                                                            <tbody>
                                                                <tr>
                                                                    <td><strong>Subtotal</strong></td>
                                                                    <td>
                                                                        <strong>$ {cartSubtotal ? cartSubtotal.toFixed(2) : 0}</strong>
                                                                    </td>
                                                                </tr>
                                                            {cartDiscount > 0 ? <tr><td className="w-50"><strong>Discount</strong>{dateDiff > 0 && <span> (<Countdown date={Date.now() + dateDiff} onComplete={()=>{
                                                                this.props.initCart();
                                                            }} />)</span>}</td><td><strong>- $ {cartDiscount ? cartDiscount.toFixed(2) : 0}</strong></td></tr> : <tr/>}
                                                            <tr><td><strong>Taxes</strong></td><td><strong>$ {taxTotal ? taxTotal.toFixed(2) : 0}</strong></td></tr>
                                                            <tr><td><strong>Total</strong></td><td><strong className="kt-font-brand">$ {cartTotal ? cartTotal.toFixed(2) : 0}</strong></td></tr>
                                                            </tbody>
                                                        </Table> */}
                                                    </div>
                                                </div>
                                        </Card.Body>
                                    </Card>
                                    <div style={{maxWidth:"255px"}}>
                                        <h5 className="mt-3" style={{fontSize:"15px"}}>Client Reviews</h5>

                                        <div class="mt-4 row">
                                            <div class="col-12">
                                                <div style={{fontSize:"14px"}}>
                                                    "<span className="font-weight-bold">Massive ranking</span> improvements in a super competitive niche"
                                                </div>
                                                <div class="d-flex mt-1">
                                                    <div>
                                                        <img className="" src="https://serppro.nyc3.cdn.digitaloceanspaces.com/public/Matt%20W.png" alt="" style={{maxWidth:"40px", borderRadius:"20px"}} />
                                                    </div>
                                                    <div style={{fontSize:"13px", paddingLeft:"10px"}}>
                                                        <div class="d-block">
                                                            Matthew Woodley
                                                        </div>
                                                        <div class="d-block">
                                                            SEO Specialist
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-4 row">
                                            <div class="col-12">
                                                <div style={{fontSize:"14px"}}>
                                                    "Delivers high end campaigns for our clients!"
                                                </div>
                                                <div class="d-flex mt-1">
                                                    <div>
                                                        <img className="" src="https://serppro.nyc3.cdn.digitaloceanspaces.com/public/Therrol%202.png" alt="" style={{maxWidth:"40px", borderRadius:"20px"}} />
                                                    </div>
                                                    <div style={{fontSize:"13px", paddingLeft:"10px"}}>
                                                        <div class="d-block">
                                                            Therrol Jezierski
                                                        </div>
                                                        <div class="d-block">
                                                            SEO Marketer
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div class="mt-4 row">
                                            <div class="col-12">
                                                <div style={{fontSize:"14px"}}>
                                                    "<span className="font-weight-bold">Noticed improvement</span> in our Google ranking"
                                                </div>
                                                <div class="d-flex mt-1">
                                                    <div>
                                                        <img className="" src="https://serppro.nyc3.cdn.digitaloceanspaces.com/public/Simon%20S.jpg" alt="" style={{maxWidth:"40px", borderRadius:"20px"}} />
                                                    </div>
                                                    <div style={{fontSize:"13px", paddingLeft:"10px"}}>
                                                        <div class="d-block">
                                                            Simon Schmid
                                                        </div>
                                                        <div class="d-block">
                                                            SEO Specialist
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-4 row">
                                            <div class="col-12">
                                                <div style={{fontSize:"14px"}}>
                                                    "<span className="font-weight-bold">Excellent</span> communication from the start"
                                                </div>
                                                <div class="d-flex mt-1">
                                                    <div>
                                                        <img className="" src="https://serppro.nyc3.cdn.digitaloceanspaces.com/public/Clare.jpg" alt="" style={{maxWidth:"40px", borderRadius:"20px"}} />
                                                    </div>
                                                    <div style={{fontSize:"13px", paddingLeft:"10px"}}>
                                                        <div class="d-block">
                                                            Clare Nicholas
                                                        </div>
                                                        <div class="d-block">
                                                            Blogger
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div class="mt-4 row">
                                            <div class="col-12">
                                                <Link className="" to={{ pathname: "https://www.serppro.io/testimonials" }} target="_blank">See more reviews</Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </>
        );
    }
}


////////////////////////////////////
// START -> PayPal checkout redirect
let paypal_counter = 0;
let paypal_redirect_started = false;

const InjectedPayPackCheckoutFormRedirect = (props) => {
    const { paypalEmail, payPalClientKey, payPalCheckoutURL, cartTotal, cartItems, useRemainingBalance, userBalance, values, projectId, formikSubmit } = props;

    return (
        <PayPalCheckoutRedirect paypalEmail={paypalEmail} payPalClientKey={payPalClientKey} payPalCheckoutURL={payPalCheckoutURL} cartTotal={cartTotal} cartItems={cartItems} useRemainingBalance={useRemainingBalance} userBalance={userBalance} values={values} projectId={projectId} />
    );
}

class PayPalCheckoutRedirect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // fetchCountryCode: true,
            isSubmitting: true,
            errorPayment: false,
            site_url: 'https://dashboard.serppro.io',
            return_url: '',
            cancel_url: '',
            notify_url: '',
            item_number: '',
        };
        this.form = null;
        paypal_counter = 0;
        paypal_redirect_started = false;
    }

    componentDidMount() {
        // this.form.submit();

    }

    render() {
        const { paypalEmail, payPalClientKey, payPalCheckoutURL, cartTotal, cartItems, useRemainingBalance, userBalance, values, projectId} = this.props;

        paypal_counter++;
        // console.log('TOP paypal_counter',paypal_counter);
        // if (paypal_counter > 2) return false;
        if (paypal_redirect_started == true) return false;

        // console.log('PayPalCheckoutRedirect 222 stripe_counter='+stripe_counter);
        // console.log('PayPalCheckoutRedirect cartItems=',cartItems);
        // // console.log('PayPalCheckoutRedirect billingInfo=',billingInfo);
        // console.log('PayPalCheckoutRedirect projectId=',projectId);
        // console.log('PayPalCheckoutRedirect useRemainingBalance=',useRemainingBalance);
        // console.log('PayPalCheckoutRedirect userBalance=',userBalance);
        // console.log('PayPalCheckoutRedirect cartTotal AAA=',cartTotal);
        var cartTotalHolder = cartTotal;
        if (useRemainingBalance == true && userBalance > 0 && userBalance < cartTotal) {
            cartTotalHolder = (cartTotalHolder - userBalance).toFixed(2);
        }
        // console.log('PayPalCheckoutRedirect cartTotalHolder BBB=',cartTotalHolder);
        // return false;
        

        const getCountryCode = (country) => {
            let countryCode = "";
            for (const [index] of Object.entries(countryList)) {
                if(countryList[index] === country) {
                    countryCode = index;
                }
            }
            return countryCode;
        };

        const countryCode = getCountryCode(values.country);
        // let projectId = values.projectId;
        let billingInfo = {
            firstname: values.firstname,
            lastname: values.lastname,
            company_name: values.company_name,
            address: values.street_address,
            postal_code: values.postal_code,
            city: values.city,
            country: values.country,
            tax_id: (typeof values.tax_id !== 'undefined' ? values.tax_id.replace(" ", "") : values.tax_id),
            payment_method: values.payment_method,
            payment_order_id: values.payment_order_id,
            country_code: countryCode
        };

        const paypalStartCheckoutProcess = (cartItems) => {      
            // console.log('paypalStartCheckoutProcess ****** projectId='+projectId);
            // return false;
            paypalInitCheckout({ cartItems: cartItems, billing_info: billingInfo, project_id: projectId, use_remaining_balance: useRemainingBalance })
            .then(function(confirmResult) {
                return confirmResult;
            }).then(handleServerResponse);

            // this.setState({isSubmitting: true});
        }
    
        const handleServerResponse = (response) => {
            // paypal_counter = 0;
            // console.log('paypalStartCheckoutProcess handleServerResponse response=',response);
            if (response.data.error) {
                // Show error from server on payment form
                // console.log('paypalStartCheckoutProcess ERROR response=', response);
                this.setState({isSubmitting: false, errorPayment: response.data.error});
    
            } else {
                // Show success message
                // console.log(response.data);
                //add payment data to the request
                // console.log('response.data.id', response.data.id);
    
                // setFieldValue('payment_order_id', response.data.id, false);
                // formikSubmit();

                this.setState({
                    site_url: response.data.site_url, 
                    return_url: response.data.return_url, 
                    cancel_url: response.data.cancel_url, 
                    notify_url: response.data.notify_url,
                    item_number: response.data.item_number
                });

                // console.log('RETURN paypal_counter',paypal_counter);
                // if (paypal_counter == 2) {
                if (paypal_redirect_started == false) {
                    paypal_redirect_started = true;
                    paypal_counter++;
                    this.form.submit();
                }
                
            }
        };

        if (! this.state.errorPayment 
            && this.state.isSubmitting == true 
            && this.state.item_number == ''
        ) {
            // console.log('INIT paypal_counter',paypal_counter);
            paypalStartCheckoutProcess(cartItems);
        }

        return (
            <>
                <form action={payPalCheckoutURL} method="post" accept-charset="utf-8" ref={item => this.form = item}>
                    <input type="hidden" name="cmd" value="_xclick" />
                    <input type="hidden" name="charset" value="utf-8" />
                    <input type="hidden" name="no_note" value="1" />
                    <input type="hidden" name="no_shipping" value="1" />
                    <input type="hidden" name="quantity" value="1" />
                    <input type="hidden" name="business" value={paypalEmail} />
                    <input type="hidden" name="item_name" value="SERPpro Checkout" />
                    <input type="hidden" name="item_number" value={this.state.item_number} />
                    <input type="hidden" name="amount" id="paypal_amount" value={cartTotalHolder} />
                    <input type="hidden" name="currency_code" value="USD" />
                    {/* <input type="hidden" name="return" value="http://localhost:3000/invoice/invoice_uuid?s=return" />
                    <input type="hidden" name="cancel_return" value="http://localhost:3000/checkout?s=cancel" />
                    <input type="hidden" name="notify_url" value="http://localhost:3000/paypal/client/invoices/invoice_uuid/g" /> */}
                    <input type="hidden" name="return" value={this.state.return_url} />
                    <input type="hidden" name="cancel_return" value={this.state.cancel_url} />
                    <input type="hidden" name="notify_url" value={this.state.notify_url} />
                    <input type="hidden" name="client_key" value={payPalClientKey} />
                </form>

                <div>
                    {this.state.errorPayment ? <div className="alert alert-error alert-danger"><div className="payment_error" style={{'justify-content': "right"}}>{this.state.errorPayment}</div></div> : null}
                    {! this.state.errorPayment ? <div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div> : null}
                </div>
            </>
        );
    }
}
// END -> PayPal checkout redirect
////////////////////////////////////


////////////////////////////////////
// START -> stripe checkout redirect
let stripe_counter = 0;

const InjectedCheckoutFormRedirect = (props) => {
    const { cartItems, cartTotal, useRemainingBalance, userBalance, values, projectId, formikSubmit } = props;
    // console.log('InjectedCheckoutFormRedirect 111 stripe_counter='+stripe_counter);
    // console.log('InjectedCheckoutFormRedirect billingInfo=',billingInfo);
    // console.log('InjectedCheckoutFormRedirect values=',values);
    return (
        <ElementsConsumer>
            {({elements, stripe}) => (
                <StripeCheckoutRedirect elements={elements} stripe={stripe} cartItems={cartItems} cartTotal={cartTotal} useRemainingBalance={useRemainingBalance} userBalance={userBalance} values={values} projectId={projectId} />
            )}
        </ElementsConsumer>
    );
}

class StripeCheckoutRedirect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // fetchCountryCode: true,
            isSubmitting: true,
            errorPayment: false
        };
    }
    
    render() {
        const { stripe, elements, cartItems, cartTotal, useRemainingBalance, userBalance, values, projectId} = this.props;

        stripe_counter++;
        if (stripe_counter == 1) return false;

        // console.log('StripeCheckoutRedirect 222 stripe_counter='+stripe_counter);
        // console.log('StripeCheckoutRedirect cartItems=',cartItems);
        // // console.log('StripeCheckoutRedirect billingInfo=',billingInfo);
        // console.log('StripeCheckoutRedirect projectId=',projectId);
        // console.log('StripeCheckoutRedirect useRemainingBalance=',useRemainingBalance);
        // console.log('StripeCheckoutRedirect userBalance=',userBalance);
        // console.log('StripeCheckoutRedirect cartTotal AAA=',cartTotal);
        var cartTotalHolder = cartTotal;
        if (useRemainingBalance == true && userBalance > 0 && userBalance < cartTotal) {
            cartTotalHolder = (cartTotalHolder - userBalance).toFixed(2);
        }
        // console.log('StripeCheckoutRedirect cartTotalHolder BBB=',cartTotalHolder);
        // return false;

        const getCountryCode = (country) => {
            let countryCode = "";
            for (const [index] of Object.entries(countryList)) {
                if(countryList[index] === country) {
                    countryCode = index;
                }
            }
            return countryCode;
        };
        
        const countryCode = getCountryCode(values.country);
        // let projectId = values.projectId;
        let billingInfo = {
            firstname: values.firstname,
            lastname: values.lastname,
            company_name: values.company_name,
            address: values.street_address,
            postal_code: values.postal_code,
            city: values.city,
            country: values.country,
            tax_id: (typeof values.tax_id !== 'undefined' ? values.tax_id.replace(" ", "") : values.tax_id),
            payment_method: values.payment_method,
            payment_order_id: values.payment_order_id,
            country_code: countryCode
        };

        const stripeStartCheckoutProcess = (cartItems) => {
            // console.log('stripeStartCheckoutProcess ****** projectId='+projectId);
            // return false;
            stripeInitCheckoutSession({ cartItems: cartItems, billing_info: billingInfo, project_id: projectId, use_remaining_balance: useRemainingBalance })
            .then(function(confirmResult) {
                return confirmResult;
            }).then(handleServerResponse);

            // this.setState({isSubmitting: true});
        }
    
        const handleServerResponse = (response) => {
            stripe_counter = 0;
            // console.log('stripeStartCheckoutProcess handleServerResponse response=',response);
            if (response.data.error) {
                // Show error from server on payment form
                // console.log('stripeStartCheckoutProcess ERROR response=', response);
                this.setState({isSubmitting: false, errorPayment: response.data.error});
    
            } else if (response.data.requires_action) {
                // Use Stripe.js to handle required card action
    
                // stripe.handleCardAction(
                //     response.data.payment_intent_client_secret
                // ).then(handleStripeJsResult);
                // stripe.handleCardAction(
                //     response.data.payment_intent_client_secret
                // ).then(handleStripeJsResult);
    
            } else {
                // Show success message
                // console.log(response.data);
                //add payment data to the request
                // console.log('response.data.id', response.data.id);
    
                // setFieldValue('payment_order_id', response.data.id, false);
                // formikSubmit();
    
                stripe.redirectToCheckout({
                    sessionId: response.data.id
                }).then(handleStripeCheckoutResult);  
            }
        };
    
        const handleStripeCheckoutResult = (response) => {
            // console.log('handleStripeCheckoutResult handleResult='+response)
            // setFieldValue('payment_order_id', response.data.id, false);
            // formikSubmit();
        }

        if (! this.state.errorPayment && this.state.isSubmitting == true) {
            stripeStartCheckoutProcess(cartItems);
        }

        return (<div>
            {this.state.errorPayment ? <div className="alert alert-error alert-danger"><div className="payment_error" style={{'justify-content': "right"}}>{this.state.errorPayment}</div></div> : null}
            {! this.state.errorPayment ? <div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div> : null}
        </div>);
    }
}
// END -> stripe checkout redirect
////////////////////////////////////


/*
// DEPRECIATED -> stripe on page payment form (search this page for this)
const InjectedCheckoutForm = (props) => {
    const { cartTotal, formikSubmit, setFieldValue, useRemainingBalance, userBalance } = props;

    return (
        <ElementsConsumer>
            {({elements, stripe}) => (
                <StripeCheckoutForm elements={elements} stripe={stripe} cartTotal={cartTotal} useRemainingBalance={useRemainingBalance} userBalance={userBalance} formikSubmit={formikSubmit} setFieldValue={setFieldValue} />
            )}
        </ElementsConsumer>
    );
};
class StripeCheckoutFormOld extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false
        };
    }
    render() {
        const { stripe, elements, cartTotal, formikSubmit, setFieldValue, useRemainingBalance, userBalance } = this.props;

        const handleStripeJsResult = (result) => {
            if (result.error) {
                // Show error in payment form
                console.log(result.error);
                this.setState({isSubmitting: false});
            } else {
                // The card action has been handled
                // The PaymentIntent can be confirmed again on the server
                stripePay({ payment_intent_id: result.paymentIntent.id }).then(function(confirmResult) {
                    return confirmResult;
                }).then(handleServerResponse);
            }
        };

        const handleServerResponse = (response) => {
            if (response.data.error) {
				  // Show error from server on payment form
				 this.setState({isSubmitting: false, errorPayment: response.data.error});
            } else if (response.data.requires_action) {
                // Use Stripe.js to handle required card action
                stripe.handleCardAction(
                    response.data.payment_intent_client_secret
                ).then(handleStripeJsResult);
            } else {
                // Show success message
                // console.log(response.data);
                //add payment data to the request
                // console.log('response.data.id', response.data.id);
                setFieldValue('payment_order_id', response.data.id, false);
                formikSubmit();
            }
        };

        const handleSubmit = async (event) => {
            // Block native form submission.
            event.preventDefault();

            ReactGA.event({
                category: 'Payment',
                action: 'StripeButtonClick'
            });

            if (!stripe || !elements) {
                // Stripe.js has not yet loaded.
                // form submission until Stripe.js has loaded.
                return;
            }

            this.setState({isSubmitting: true});

            // Get a reference to a mounted CardElement. Elements knows how
            // to find your CardElement because there can only ever be one of
            // each type of element.
            const cardElement = elements.getElement(CardElement);

            const {error, paymentMethod} = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (error) {
				 this.setState({isSubmitting: false, errorPayment: error['message']});
            } else {
                // console.log('[PaymentMethod]', paymentMethod);

                let cartTotalHolder = cartTotal ? cartTotal : 0;
                let use_remaining_balance = false;
                if (userBalance > 0 && userBalance < cartTotal && useRemainingBalance) {
                    use_remaining_balance = true;
                    cartTotalHolder = cartTotalHolder - userBalance;
                }
                // console.log("SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS");
                // console.log("userBalance = " + userBalance);
                // console.log("cartTotalHolder (cartTotal - availableBalance) = " + cartTotalHolder);
                // // console.log("use_remaining_balance = " + use_remaining_balance);
                // console.log("====================================================");
                // return false;

                // stripePay({ payment_method_id: paymentMethod.id, amount: cartTotal ? cartTotal.toFixed(2) * 100 : 0 })
                stripePay({ payment_method_id: paymentMethod.id, amount: cartTotalHolder ? cartTotalHolder.toFixed(2) * 100 : 0 })
                .then(handleServerResponse);
            }
			
		
        };

		if (this.state.errorPayment) {
			const newLine = 40;
			let rowHeight = this.state.errorPayment.length;
			var rows = 1;
			const remainderNewLine = (rowHeight % newLine);
			
			//rows = remainder of character length /40. If it goes in evenly then rows is set to that value.  If it needs to add another row to adjust for the extra characters then remainderNewLine/remainderNewLine * rows will go to 1 as opposed to 0.
			rows = (remainderNewLine/remainderNewLine)*rows + ((rowHeight - remainderNewLine) / newLine );
        }

        return (
            <form onSubmit={handleSubmit}>
                <CardElement
                    options={{
                        style: {
                            base: {
                                fontSize: '16px',
                                color: '#424770',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#9e2146',
                            },
                        },
                    }}
                />
				{this.state.errorPayment ? <div class="payment_error" style={{'justify-content': "right"}}><textarea readOnly rows={rows} style={{'text-align': "right", border: "none", width: "100%", color: "red", "word-break": "break-all", font: "-webkit-small-control", "text-align": "left", "border": "none", "background-color": "transparent", "resize": "none", "outline": "none"}} value={this.state.errorPayment} /></div> : null}
                <ButtonToolbar className="justify-content-center">
                    <Button type="submit" disabled={!stripe || this.state.isSubmitting}>Pay</Button>
                </ButtonToolbar>
            </form>
        );
    }
}
*/

const mapStateToProps = ({ cart: { cartItems, cartSubtotal, cartDiscount, taxTotal, cartTotal, userDiscountPercent, discountDate, currentDate }, profile: { billingInfo }, projects: {projects, currentProjectId, currentProject}, marketplace: {guestPosts, accessDenied}, pagedata: {pageData} }) => ({
    cartItems, cartSubtotal, cartDiscount, taxTotal, cartTotal, userDiscountPercent, discountDate, currentDate, billingInfo,
	projects, currentProjectId, currentProject, guestPosts, accessDenied, pageData
});

export default injectIntl(connect(
    mapStateToProps,
    {...cart.actions, ...profile.actions, ...notifications.actions, ...projects.actions, ...marketplace.actions, ...pagedata.actions}
)(Checkout));
