import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as projects from "../../../store/ducks/projects.duck";
import {Form, Button} from "react-bootstrap";
import {Spinner} from "reactstrap";
import { updateBlog } from "../../../crud/marketplace.crud";
import Select from "react-select";
import * as marketplace from "../../../store/ducks/marketplace.duck";

class EditBlogModal extends React.Component {
    constructor(props) {
        super(props);

        const {guestPostItem} = this.props;
        let sending = false;
        let blogPrice = guestPostItem.price;
        let blogUrl = guestPostItem.url;
        let blogCategoryId =guestPostItem.category_id;
        let blogDr =guestPostItem.dr;
        let blogOrganicTraffic =guestPostItem.organic_traffic;
        this.state = {
            sending: sending,
            blogPrice: blogPrice,
            blogUrl: blogUrl,
            blogCategoryId: blogCategoryId,
            guestPostItem: guestPostItem,
            blogDr: blogDr,
            blogOrganicTraffic: blogOrganicTraffic
        };
    }

    componentDidMount() {
    	const { projects } = this.props;
    	this.props.requestProjectCategories();
  	}

    render() {

    	const { open, onClose, projectCategories, guestPostItem, guestPosts } = this.props;

        const selectStyles = {
	        menu: styles => ({ ...styles, zIndex: 999 }),
	        control: (provided, state) => ({
	            ...provided,
	            minHeight: '47px',
	        }),
	    };

	    const categoryOptions = [];
	    let categoryLabel = "";
	    if (typeof projectCategories !== 'undefined') {
      		if (projectCategories.data && projectCategories.data.length !== 0) {
		        for (let index = 0; index < projectCategories.data.length; index++) {
		            let item = {value: projectCategories.data[index].id, label: projectCategories.data[index].name};
		            categoryOptions.push(item);
		            if (guestPostItem.category_id == projectCategories.data[index].id) {
		            	categoryLabel = projectCategories.data[index].name;
		            }
		        }
      		}
    	}
    	let random_verification_with_html = ``;
    	if (guestPostItem.meta_verification_code == "Y") {
    		random_verification_with_html = `Verified!`;
    	} else {
    		random_verification_with_html = `<meta name="serppro-verification" content="`+guestPostItem.meta_verification_code+`">`;
    	}

        const handleClose = () => {
        	setBlogPrice(0);
        	setBlogUrl("");
        	setBlogCategoryIdRaw(0);
            setBlogDr(null);
            setBlogOrganicTraffic(null);
            onClose();
        };

        const setBlogPrice = (price) => {
        	this.setState({
                ...this.state,
        		blogPrice: price
        	});
        };

        const setBlogUrl = (url) => {
        	this.setState({
                ...this.state,
        		blogUrl: url
        	});
        };

        const setBlogCategoryIdRaw = (categoryId) => {
        	this.setState({
                ...this.state,
        		blogCategoryId: categoryId
        	});
        };

        const setBlogCategoryId = (categoryId) => {
        	this.setState({
                ...this.state,
        		blogCategoryId: categoryId.value
        	});
        };

        const setBlogDr = (dr) => {
            this.setState({
                ...this.state,
                blogDr: dr
            });
        };

        const setBlogOrganicTraffic = (organicTraffic) => {
            this.setState({
                ...this.state,
                blogOrganicTraffic: organicTraffic
            });
        };

        const handleAddBlog = () => {
        	this.setState({
                ...this.state,
        		sending: true
        	});

        	let blogUrl = guestPostItem.url;
        	if (typeof this.state.blogUrl !== 'undefined') {
        		blogUrl = this.state.blogUrl;
        	}

        	let blogCategoryId = guestPostItem.category_id;
        	if (typeof this.state.blogCategoryId !== 'undefined') {
        		blogCategoryId = this.state.blogCategoryId;
        	}

        	let blogPrice = guestPostItem.price;
        	if (typeof this.state.blogPrice !== 'undefined') {
        		blogPrice = this.state.blogPrice;
        	}

            let blogDr = guestPostItem.dr;
            if (typeof this.state.blogDr !== 'undefined') {
                blogDr = this.state.blogDr;
            }

            let blogOrganicTraffic = guestPostItem.organic_traffic;
            if (typeof this.state.blogOrganicTraffic !== 'undefined') {
                blogOrganicTraffic = this.state.blogOrganicTraffic;
            }

        	updateBlog(
                blogPrice,
                blogUrl,
                blogCategoryId,
                blogDr,
                blogOrganicTraffic
            )
            .then((res) => {
            	// console.log("then = " + res);
                this.setState({
	                ...this.state,
	        		sending: false
	        	});
	        	setBlogPrice(0);
        		setBlogUrl("");
        		setBlogCategoryIdRaw(0);
                setBlogDr(null);
                setBlogOrganicTraffic(null);
	        	this.props.requestMyBlogs();
	        	onClose();
            })
            .catch(e => {
            	// console.log("catch = " + e);
            	this.setState({
	                ...this.state,
	        		sending: false
	        	});
                if(e && e.response && e.response.data) {
                    // setStatus(
                    //     e.response.data.message
                    // );
                }
            });
        }

        return (<Dialog open={open} onClose={handleClose} fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle>Edit Your Blog</DialogTitle>
            <DialogContent dividers>
                <div className="form-group mb-3">
                  	<label className="MuiFormLabel-root">Blog Category</label>
                  	<Select
	                    className="mb-0"
	                    defaultValue={{ label: categoryLabel, value: guestPostItem.category_id }}
	                    options={categoryOptions}
	                    onChange={setBlogCategoryId}
	                    styles={selectStyles}
                 	 />
                </div>

                <div className="form-group mb-3">
                    <label className="MuiFormLabel-root">Copy & Paste this code between the {`<HEAD></HEAD>`} tags of your blog</label>
                    <input
                        type="text"
                        className="form-control"
                        defaultValue={random_verification_with_html}
                        disabled
                        />
                </div>

                <div className="input-group mb-3">
				  	<div className="input-group-prepend">
				    	<span className="input-group-text" id="basic-addon1" style={{width:"53px"}}>
				    		<i className="fa fa-link"></i>
				    	</span>
				  	</div>
		            <input
		                autoFocus
		                onChange={(e) => setBlogUrl(e.target.value)}
		                placeholder="What is your blogs URL?"
		                type="url"
		                className="form-control"
		                defaultValue={guestPostItem.url}
		                disabled
		                required
		               	/>
	            </div>

                <div className="input-group">
				  	<div className="input-group-prepend">
				    	<span className="input-group-text" id="basic-addon1" style={{width:"53px"}}>
				    		<i className="fa fa-dollar-sign" style={{margin:"0 auto"}}></i>
				    	</span>
				  	</div>
		            <input
		                onChange={(e) => setBlogPrice(e.target.value)}
		                placeholder="What is the price for a sponsored blog post?"
		                type="number"
		                step="0.01"
		                className="form-control"
		                defaultValue={guestPostItem.price}
		                required
		               	/>
	            </div>
				<div>
                    <small>SERPpro earns 20% commission per sale for processing orders.</small>
                </div>
                <div className="input-group mb-3 mt-3">
                    <input
                        onChange={(e) => setBlogDr(e.target.value)}
                        placeholder="What is your blogs domain rating?"
                        type="number"
                        step="1"
                        className="form-control"
                        defaultValue={guestPostItem.dr}
                        required
                        />
                </div>

                <div className="input-group">
                    <input
                        onChange={(e) => setBlogOrganicTraffic(e.target.value)}
                        placeholder="How much organic traffic does your blog receive each month?"
                        type="number"
                        step="1"
                        className="form-control"
                        defaultValue={guestPostItem.organic_traffic}
                        required
                        />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
                 <Button
                    variant="success"
                    onClick={handleAddBlog}
                    disabled={this.state.blogUrl == "" || this.state.blogPrice == 0 || this.state.blogCategoryId == 0 || this.state.blogDr == "" || this.state.blogOrganicTraffic == "" || this.state.sending}
                >
                    {this.state.sending ? "Sending.." : "Update Blog"}
                </Button>
            </DialogActions>
        </Dialog>);
    }
}

EditBlogModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ projects: { projectCategories }, marketplace: { guestPosts } }) => ({
    projectCategories, guestPosts
});

export default injectIntl(connect(
    mapStateToProps,
    {...marketplace.actions, ...projects.actions}
)(EditBlogModal));
