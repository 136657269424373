/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import AuthPage from "../pages/auth/AuthPage";
import AdminPage from "../pages/admin/AdminPage";
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import {getQueryParam} from "../custom_functions/get_query_param";

export const Routes = withRouter(({ history }) => {
    const lastLocation = useLastLocation();
    routerHelpers.saveLastLocation(lastLocation);
    const { isAuthorized, menuConfig, menuConfigAdmin, menuConfigBlogger, userLastLocation, isAdmin, isBlogger, isContentWriter } = useSelector(
        ({ auth, urls, builder: { menuConfig, menuConfigAdmin, menuConfigBlogger } }) => ({
            menuConfig,
            menuConfigAdmin,
            menuConfigBlogger,
            isAuthorized: auth.user != null,
            isBlogger: auth.user ? auth.user.blogger : false,
            isContentWriter: auth.user ? auth.user.is_content_writer : false,
            userLastLocation: routerHelpers.getLastLocation(),
            isAdmin: auth.roles ? auth.roles.split(",").indexOf("admin") !== -1 : false
        }),
        shallowEqual
    );

    const page = window.location.pathname + window.location.search;
    if(window.location.search) {
        const utmSource = getQueryParam(window.location.search, "utm_source");
        const utmCampaign = getQueryParam(window.location.search, "utm_campaign");
        const utmMedium = getQueryParam(window.location.search, "utm_medium");
        if(utmSource) {
            ReactGA.ga('set', 'campaignSource', utmSource);
        }
        if(utmCampaign) {
            ReactGA.ga('set', 'campaignName', utmCampaign);
        }
        if(utmMedium) {
            ReactGA.ga('set', 'campaignMedium', utmMedium);
        }
    }
    ReactGA.ga('set', 'page', page);
    ReactGA.ga('send', 'pageview');
    ReactPixel.pageView();
    history.listen(location => {
        const page = location.pathname + location.search;
        ReactGA.ga('set', 'page', page); // Update the user's current page
        ReactGA.ga('send', 'pageview');
    });

    if (! isAuthorized) {
        return (
            /* Create `LayoutContext` from current `history` and `menuConfig`. */
            <LayoutContextProvider history={history} menuConfig={menuConfig}>
                <Switch>
                    /* Render auth page when user at `/auth` and not authorized. */
                    <AuthPage />

                    <Route path="/error" component={ErrorsPage} />
                    <Route path="/logout" component={LogoutPage} />

                    /* Redirect to `/auth` when user is not authorized */
                    <Redirect to="/auth/login" />
                </Switch>
            </LayoutContextProvider>
        );

    } else if (isAdmin) {
        return (
            /* Create `LayoutContext` from current `history` and `menuConfig`. */
            <LayoutContextProvider isAdmin={isAdmin} history={history} menuConfigAdmin={menuConfigAdmin}>
                <Switch>
                    // <Redirect from="/auth" to={userLastLocation} />

                    <Route path="/error" component={ErrorsPage} />
                    <Route path="/logout" component={LogoutPage} />

                    <Layout isAdmin={isAdmin}>
                        <AdminPage />
                    </Layout>

                </Switch>
            </LayoutContextProvider>
        );  

    } else if (isAuthorized && ! isAdmin && (isBlogger || isContentWriter)) {
        return (
            /* Create `LayoutContext` from current `history` and `menuConfig`. */
            <LayoutContextProvider isBlogger={isBlogger} isContentWriter={isContentWriter} history={history} menuConfigBlogger={menuConfigBlogger}>
                <Switch>
                    <Redirect from="/auth" to={userLastLocation} />

                    <Route path="/error" component={ErrorsPage} />
                    <Route path="/logout" component={LogoutPage} />

                    <Layout isBlogger={isBlogger} isContentWriter={isContentWriter}>
                        <HomePage userLastLocation={userLastLocation} />
                    </Layout>
                </Switch>
            </LayoutContextProvider>
        );

    } else if (isAuthorized && ! isAdmin && ! isBlogger && ! isContentWriter) {
        return (
            /* Create `LayoutContext` from current `history` and `menuConfig`. */
            <LayoutContextProvider history={history} menuConfig={menuConfig}>
                <Switch>
                    <Redirect from="/auth" to={userLastLocation} />

                    <Route path="/error" component={ErrorsPage} />
                    <Route path="/logout" component={LogoutPage} />

                    <Layout>
                        <HomePage userLastLocation={userLastLocation} />
                    </Layout>
                </Switch>
            </LayoutContextProvider>
        );
    }

});
