import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {calculateCartDiscountAmount} from "../../custom_functions/guest_posts";
import {getUserDiscount} from "../../crud/orders.crud";
//import {getDiscountAmount} from "../../crud/orders.crud";

export const actionTypes = {
    CartInit: "[Init Cart] Action",
    CartAddItem: "[Add to Cart] Action",
    CartUpdateItem: "[Update Cart Item] Action",
    CartUpdateItemData: "[Update Cart Item Data] Action",
    CartRemoveItem: "[Remove Cart Item] Action",
    CartUpdate: "[Update Cart] Action",
    CartEmpty: "[Empty Cart] Action",
    CartHide: "[Hide Cart] Action",
    CartShow: "[Show Cart] Action",
    CheckoutShow: "[Show Checkout] Action",
    CheckoutHide: "[Hide Checkout] Action",
    TaxUpdate: "[Update Taxes] Action",
    UserDiscountRequest: "[Request User Discount] Action",
    UserDiscountUpdate: "[Update User Discount] Action",
    DiscountDateUpdate: "[Update Discount Date] Action",
    CurrentDateUpdate: "[Update Current Date] Action",
    /*DiscountRequest: "[Request Discount] Action",
    DiscountUpdate: "[Update Discount] Action",*/
};

const initialCartState = {
    cartItems: undefined,
    cartTotal: undefined,
    cartSubtotal: undefined,
    cartDiscount: undefined,
    taxRate: undefined,
    taxTotal: undefined,
    showCart: false,
    cartQuantity: undefined,
    showCheckout: false,
    userDiscountPercent: undefined,
    discountDate: undefined,
    currentDate: undefined,
};

export const reducer = persistReducer(
    { storage, key: "cart", whitelist: ["cartItems", "cartTotal", "cartSubtotal", "taxTotal", "showCart", "cartQuantity", "cartDiscount", "userDiscountPercent", "discountDate", "currentDate"] },
    (state = initialCartState, action) => {
        switch (action.type) {
            case actionTypes.CartInit: {
                const cartItems = state.cartItems ? state.cartItems : [];
                const cartSubtotal = state.cartSubtotal ? state.cartSubtotal : 0;
                const taxRate = state.taxRate ? state.taxRate : 0;
                const taxTotal = state.taxTotal ? state.taxTotal : 0;
                const cartTotal = state.cartTotal ? state.cartTotal : 0;
                const cartQuantity = state.cartQuantity ? state.cartQuantity : 0;
                const cartDiscount = state.cartDiscount ? state.cartDiscount : 0;
                const userDiscountPercent = state.userDiscountPercent ? state.userDiscountPercent : undefined;
                const discountDate = state.discountDate ? state.discountDate : undefined;
                const currentDate = state.currentDate ? state.currentDate : undefined;
                return {
                    cartItems: cartItems,
                    cartSubtotal: cartSubtotal,
                    taxRate: taxRate,
                    taxTotal: taxTotal,
                    cartTotal: cartTotal,
                    showCart: false,
                    cartQuantity: cartQuantity,
                    showCheckout: state.showCheckout,
                    cartDiscount: cartDiscount,
                    userDiscountPercent: userDiscountPercent,
                    discountDate: discountDate,
                    currentDate: currentDate,
                };
            }

            case actionTypes.CartAddItem: {
                const { item: newItem } = action.payload;
                let article_price = 0; // 50
                // console.log("CartAddItem Cart.duck NNNN newItem.marketplace_article_price_included", newItem.marketplace_article_price_included);
                // console.log("CartAddItem Cart.duck NNNN newItem.orderData.options.marketplace_article", newItem.marketplace_article_price_included);
                // console.log('CartAddItem Cart.duck NNNN newItem.price FIRST', newItem.price);
                // if (newItem.orderData.type == "marketplace_guest_post" && newItem.orderData.options.marketplace_article == 1 && newItem.marketplace_article_price_included != 1) {
                // if (newItem.orderData.type == "marketplace_guest_post" && newItem.orderData.options.marketplace_article == 1) {
                //     newItem.price = newItem.price + article_price;
                //     newItem.marketplace_article_price_included = 1;
                //     console.log('CartAddItem newItem.price ADD article_price = ', newItem.price);
                // }
                // console.log('CartAddItem Cart.duck NNNN newItem.price SECOND', newItem.price);
                let itemExist = false;
                for (let i = 0; i < state.cartItems.length; i++) {
                    if(state.cartItems[i].key === newItem.key) {
                        itemExist = true;
                    }
                }
                if(itemExist) {
                    return { ...state, cartItems: [...state.cartItems.map(item => {
                        if(item.key === newItem.key) {
                            return {...item, orderData: { ...item.orderData, quantity: item.orderData.quantity + newItem.orderData.quantity } };
                        }
                        return item;
                    } )], showCart: false };
                }

                return { ...state, cartItems: [...state.cartItems, newItem], showCart: false };
            }

            case actionTypes.CartUpdate: {
                let cartSubtotal = 0;
                let cartQuantity = 0;
                let cartDiscount = 0;
                let article_price = 0; // 50
                if(state.cartItems && state.cartItems.length !== 0) {
                    for (let i = 0; i < state.cartItems.length; i++) {
                        // console.log("CartUpdate Cart.duck 000000 state.cartItems[i].orderData.type", state.cartItems[i].orderData.type);
                        if (state.cartItems[i].orderData.type == "marketplace_guest_post") {

                            // console.log("CartUpdate Cart.duck 000000 A", state.cartItems[i].marketplace_article_price_included);
                            // console.log("CartUpdate Cart.duck 000000 B", state.cartItems[i].orderData.options.marketplace_article);

                            // if (typeof state.cartItems[i].marketplace_article_price_included === 'undefined') {
                            //     state.cartItems[i].marketplace_article_price_included = 1;

                            // } else 
                            if (state.cartItems[i].orderData.options.marketplace_article == null && state.cartItems[i].marketplace_article_price_included != 1) {
                                state.cartItems[i].price = state.cartItems[i].price + article_price;
                                state.cartItems[i].marketplace_article_price_included = 1;
                                // console.log('CartUpdate state.cartItems[i].price 1111', state.cartItems[i].price);

                            } else if (state.cartItems[i].orderData.options.marketplace_article == 1 
                                && state.cartItems[i].marketplace_article_price_included == 1
                                && state.cartItems[i].orderData.options.toggle_user_article_from_cart == 1) {
                                state.cartItems[i].price = state.cartItems[i].price - article_price;
                                state.cartItems[i].marketplace_article_price_included = 0;
                                state.cartItems[i].marketplace_article_price_included = 0;
                                // console.log('CartUpdate state.cartItems[i].price 2222', state.cartItems[i].price);

                            }

                            // console.log("CartUpdate cartSubtotal 111", cartSubtotal);
                        // } else if (state.cartItems[i].orderData.type == "domain_name_sale") {
                        //     state.cartItems[i].price = state.cartItems[i].price
                        }
                        
                        let itemPrice = Number(state.cartItems[i].price) * Number(state.cartItems[i].orderData.quantity);
                        // console.log('CartUpdate itemPrice', itemPrice);
                        cartSubtotal += itemPrice;
                        // console.log("CartUpdate cartSubtotal 333", cartSubtotal);
                        cartQuantity += Number(state.cartItems[i].orderData.quantity);
                        if (state.cartItems[i].orderData.add_ons != null && state.cartItems[i].orderData.add_ons.length > 0) {
                            for(let ao = 0; ao < state.cartItems[i].orderData.add_ons.length; ao++) {
                                cartSubtotal += (state.cartItems[i].orderData.add_ons[ao].add_on_price * state.cartItems[i].orderData.quantity);
                                // console.log("add on area type = " + state.cartItems[i].orderData.type);
                                // console.log("Cart update quanitty = " + state.cartItems[i].orderData.quantity);
                                // console.log("CartUpdate add_on_price = " + state.cartItems[i].orderData.add_ons[ao].add_on_price);
                                // console.log("CartUpdate cartSubtotal 444", cartSubtotal);
                            }
                        }
                        // console.log("============================================================");
                    }
                    cartDiscount = calculateCartDiscountAmount(state.cartItems, state.userDiscountPercent);
                }
                
                const totalInclDiscount = cartSubtotal - cartDiscount;
                const taxTotal = totalInclDiscount * (state.taxRate / 100);
                const cartTotal = totalInclDiscount + taxTotal;

                return { ...state, cartSubtotal: cartSubtotal, cartDiscount: cartDiscount, taxTotal: taxTotal, cartTotal: cartTotal, cartQuantity: cartQuantity };
            }

            case actionTypes.CartUpdateItem: {
                const { key, quantity } = action.payload;
                return { ...state, cartItems: [...state.cartItems.map(item => {
                    if(item.key === key) {
                        return {...item, orderData: { ...item.orderData, quantity: quantity } };
                    }
                    return item;
                } )] };
            }

            case actionTypes.CartUpdateItemData: {
                const { key, itemData } = action.payload;
                return { ...state, cartItems: [...state.cartItems.map(item => {
                        if(item.key === key) {
                            return itemData;
                        }
                        return item;
                    } )] };
            }

            case actionTypes.CartRemoveItem: {
                const { key } = action.payload;
                return { ...state, cartItems: [...state.cartItems.filter(item => item.key !== key)] };
            }

            case actionTypes.CartEmpty: {
                return { ...state, cartItems: [], cartSubtotal: 0, taxTotal: 0, cartTotal: 0, cartQuantity: 0, cartDiscount: 0 };
                // return { ...state, cartItems: [], cartSubtotal: 0, taxTotal: 0, cartTotal: 0, cartQuantity: 0 };
            }

            case actionTypes.CartHide: {
                return { ...state, showCart: false };
            }

            case actionTypes.CartShow: {
                return { ...state, showCart: true };
            }

            case actionTypes.CheckoutShow: {
                return { ...state, showCheckout: true };
            }

            case actionTypes.CheckoutHide: {
                return { ...state, showCheckout: false };
            }

            case actionTypes.TaxUpdate: {
                const { taxRate } = action.payload;
                return { ...state, taxRate: taxRate };
            }

            case actionTypes.UserDiscountUpdate: {
                const { discount } = action.payload;
                return { ...state, userDiscountPercent: discount };
            }

            case actionTypes.DiscountDateUpdate: {
                const { discountDate } = action.payload;
                return { ...state, discountDate: discountDate };
            }

            case actionTypes.CurrentDateUpdate: {
                const { currentDate } = action.payload;
                return { ...state, currentDate: currentDate };
            }

            /*case actionTypes.DiscountUpdate: {
                const { discountAmount } = action.payload;
                return { ...state, cartDiscount: discountAmount };
            }*/

            default:
                return state;
        }
    }
);

export const actions = {
    initCart: taxRate => ({ type: actionTypes.CartInit, payload: { taxRate } }),
    addToCart: item => ({ type: actionTypes.CartAddItem, payload: { item } }),
    updateCartItem: (key, quantity) => ({ type: actionTypes.CartUpdateItem, payload: { key, quantity } }),
    updateCartItemData: (key, itemData) => ({ type: actionTypes.CartUpdateItemData, payload: { key, itemData } }),
    removeCartItem: key => ({ type: actionTypes.CartRemoveItem, payload: { key } }),
    updateCart: () => ({ type: actionTypes.CartUpdate }),
    emptyCart: () => ({ type: actionTypes.CartEmpty }),
    hideCart: () => ({ type: actionTypes.CartHide }),
    showCartOnClick: () => ({ type: actionTypes.CartShow }),
    openCheckout: () => ({ type: actionTypes.CheckoutShow }),
    hideCheckout: () => ({ type: actionTypes.CheckoutHide }),
    updateTaxes: taxRate => ({ type: actionTypes.TaxUpdate, payload: { taxRate } }),
    requestUserDiscount: discount => ({ type: actionTypes.UserDiscountRequest, payload: {discount} }),
    updateUserDiscount: discount => ({ type: actionTypes.UserDiscountUpdate, payload: {discount} }),
    updateDiscountDate: discountDate => ({ type: actionTypes.DiscountDateUpdate, payload: {discountDate} }),
    updateCurrentDate: currentDate => ({ type: actionTypes.CurrentDateUpdate, payload: {currentDate} }),
    /*requestDiscount: discountAmount => ({ type: actionTypes.DiscountRequest, payload: { discountAmount } }),
    updateDiscount: discountAmount => ({ type: actionTypes.DiscountUpdate, payload: { discountAmount } }),*/
};

export function* saga() {
    yield takeLatest(actionTypes.CartInit, function* initCartSaga(action) {
        const { taxRate } = action.payload;
        if(taxRate >= 0) {
            yield put(actions.updateTaxes(taxRate));
        }
        else {
            //yield put(actions.updateCart());
            yield put(actions.requestUserDiscount());
        }
    });

    yield takeLatest(actionTypes.CartAddItem, function* addItemCartSaga(action) {
        yield put(actions.updateCart());
    });

    yield takeLatest(actionTypes.CartRemoveItem, function* removeItemCartSaga(action) {
        yield put(actions.updateCart());
    });

    yield takeLatest(actionTypes.CartUpdateItem, function* updateItemCartSaga(action) {
        yield put(actions.updateCart());
    });

    yield takeLatest(actionTypes.CartUpdateItemData, function* updateItemDataCartSaga(action) {
        yield put(actions.updateCart());
    });

    yield takeLatest(actionTypes.TaxUpdate, function* updateTaxesSaga(action) {
        yield put(actions.updateCart());
    });

    yield takeLatest(actionTypes.UserDiscountRequest, function* requestUserDiscountSaga(action) {
        try {
            const {data: {discount, discountDateEnd, currentDate}} = yield getUserDiscount();
            yield put(actions.updateUserDiscount(discount));
            yield put(actions.updateDiscountDate(discountDateEnd ? discountDateEnd : {}));
            yield put(actions.updateCurrentDate(currentDate));
        }
        catch (e) {
            yield put(actions.updateCart());
        }
    });

    yield takeLatest(actionTypes.UserDiscountUpdate, function* updateUserDiscountSaga(action) {
        yield put(actions.updateCart());
    });

    /*yield takeLatest(actionTypes.DiscountRequest, function* requestDiscountSaga(action) {
        const {cartItems} = null;
        try {
            const {discountAmount} = yield getDiscountAmount(cartItems);
            yield put(actions.updateDiscount(discountAmount));
        }
        catch (e) {
            yield put(actions.updateCart());
        }
    });

    yield takeLatest(actionTypes.DiscountUpdate, function* updateDiscountSaga(action) {
        yield put(actions.updateCart());
    });*/
}
