import React from "react";
import {Button} from "react-bootstrap";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as auth from "../../../store/ducks/auth.duck";
import {deleteAccount} from "../../../crud/user.crud";

class DeleteAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {disabled:false};
    }

    render() {
        const {user} = this.props;

        const handleDelete = () => {
            const conf = window.confirm("Do you really want to delete your account?");
            if(user.id > 0 && conf) {
                this.setState({disabled:true});
                deleteAccount().then(() => {
                    this.props.logout();
                }).catch(e => {
                    this.setState({disabled:false});
                });
            }
        };

        return (
            <Button
                variant="danger"
                onClick={handleDelete}
                disabled={this.state.disabled}
            >
                Delete Account
            </Button>
        );
    }
}

const mapStateToProps = ({ auth: { user } }) => ({
    user
});

export default injectIntl(connect(mapStateToProps, auth.actions)(DeleteAccount));
