import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as offers from "../../store/ducks/admin/offers.duck";
import {Spinner} from "reactstrap";
import {Table, Badge} from "react-bootstrap";
import {Portlet, PortletBody, PortletHeader} from "../../partials/content/Portlet";
import {Link} from "react-router-dom";
import {Icon} from "@material-ui/core";
import UPagination from "../../partials/content/Pagination";
import {Button} from "react-bootstrap";
import {acceptOffer, rejectOffer, counterOffer} from "../../crud/admin/offers.crud";

class OffersAdmin extends Component {
    componentDidMount() {
        this.props.initOffers();
        let sending = false;
        let counterOfferRowId = 0;
        let counterOfferRowAmount = 0;
        this.state = {
            sending: sending,
            counterOfferRowId: counterOfferRowId,
            counterOfferRowAmount: counterOfferRowAmount
        };
    }

    constructor(props) {
        super(props);

        let sending = false;
        let counterOfferRowId = 0;
        let counterOfferRowAmount = 0;

        this.state = {
            sending: sending,
            counterOfferRowId: counterOfferRowId,
            counterOfferRowAmount: counterOfferRowAmount
        };

        this.handleRejectOffer = (e) => {
	    	e.preventDefault();
	    	let offer_id = e.target.name;
	    	this.setState({
                ...this.state,
        		sending: true,
        		counterOfferRowId: 0,
        		counterOfferRowAmount: 0
        	});
	    	rejectOffer(
                offer_id
            )
            .then((res) => {
                this.setState({
	                ...this.state,
	        		sending: false
	        	});
	        	this.props.initOffers();
            })
            .catch(e => {
            	this.setState({
	                ...this.state,
	        		sending: false
	        	});
                if(e && e.response && e.response.data) {
                    // setStatus(
                    //     e.response.data.message
                    // );
                }
            });
	    };

	    this.handleAcceptOffer = (e) => {
	    	e.preventDefault();
	    	let offer_id = e.target.name;
	    	this.setState({
                ...this.state,
        		sending: true,
        		counterOfferRowId: 0,
        		counterOfferRowAmount: 0
        	});
	    	acceptOffer(
                offer_id
            )
            .then((res) => {
                this.setState({
	                ...this.state,
	        		sending: false
	        	});
	        	this.props.initOffers();
            })
            .catch(e => {
            	this.setState({
	                ...this.state,
	        		sending: false
	        	});
                if(e && e.response && e.response.data) {
                    // setStatus(
                    //     e.response.data.message
                    // );
                }
            });
	    };

	    this.setCounterOfferAmount = (offer) => {
        	this.setState({
                ...this.state,
        		counterOfferRowAmount: offer
        	});
        };

	    this.handleCounterOffer = (e) => {
	    	e.preventDefault();
	    	let offer_id = e.target.name;
	    	this.setState({
                ...this.state,
        		sending: false,
        		counterOfferRowId: offer_id
        	});
	    };

	    this.handleCounterOfferSubmit = (e) => {
	    	e.preventDefault();
	    	let offer_id = e.target.name;
	    	this.setState({
                ...this.state,
        		sending: true
        	});
	    	counterOffer(
                offer_id,
                counterOfferRowAmount
            )
            .then((res) => {
                this.setState({
	                ...this.state,
	        		sending: false,
	        		counterOfferRowId: 0,
	        		counterOfferRowAmount: 0
	        	});
	        	this.props.initOffers();
            })
            .catch(e => {
            	this.setState({
	                ...this.state,
	        		sending: false
	        	});
                if(e && e.response && e.response.data) {
                    // setStatus(
                    //     e.response.data.message
                    // );
                }
            });
	    };
    }

    render() {

    	const {offers} = this.props;
    	let sending = this.props.sending;

        if (!offers) {
            return <Spinner/>;
        }

        const rows = [];

        if (offers.meta && offers.data) {
            if (offers.data.length) {
                for (const [index] of offers.data.entries()) {
                    const date = new Date(offers.data[index].created_at);
                    let statusVariant = "secondary";
                    if(offers.data[index].status == "Sent")
                    {
                        statusVariant = "info";
                    }
                    else if(offers.data[index].status == "Counter Offer") {
                    	statusVariant = "warning";
                    }
                    else if(offers.data[index].status == "Rejected") {
                    	statusVariant = "danger";
                    }
                    else if(offers.data[index].status == "Accepted") {
                    	statusVariant = "primary";
                    }
                    else if(offers.data[index].status == "Paid") {
                        statusVariant = "success";
                    }
                    let customer = "";
                    if(offers.data[index].firstname) {
                        customer += offers.data[index].firstname + " ";
                    }
                    if(offers.data[index].lastname) {
                        customer += offers.data[index].lastname;
                    }

                   	if(offers.data[index].status == "Sent") {
                   		if (this.state.counterOfferRowId == offers.data[index].id) {
                   			rows.push(<tr key={index}>
	                        <td>{offers.data[index].id}</td>
	                        <td>{customer}</td>
	                        <td>{offers.data[index].email}</td>
	                        <td>{date.toLocaleString()}</td>
	                        <td>{offers.data[index].url}</td>
	                        <td>${offers.data[index].price.toFixed(2)}</td>
	                        <td><Badge pill variant={statusVariant}>{offers.data[index].status}</Badge></td>
	                        <td>${offers.data[index].offer_amount.toFixed(2)}</td>
	                        <td>
								<div className="input-group">
								  	<div className="input-group-prepend">
								    	<span className="input-group-text" id="basic-addon1">$</span>
								  	</div>
						            <input
						                autoFocus
						                onChange={(e) => this.setCounterOfferAmount(e.target.value)}
						                placeholder="Your counter offer"
						                type="number"
						                step="0.01"
						                className="form-control"
						                defaultValue={offers.data[index].offer_amount.toFixed(2)}
						               	/>
					            </div>
								<Button disabled={this.state.sending} className="btn-warning btn-sm kt-mr-15" name={offers.data[index].id} onClick={(e) => this.handleCounterOfferSubmit(e)}>Send Counter Offer</Button>
	                        </td>
	                    </tr>)
                   		} else {
		                    rows.push(<tr key={index}>
		                        <td>{offers.data[index].id}</td>
		                        <td>{customer}</td>
		                        <td>{offers.data[index].email}</td>
		                        <td>{date.toLocaleString()}</td>
		                        <td>{offers.data[index].url}</td>
		                        <td>${offers.data[index].price.toFixed(2)}</td>
		                        <td><Badge pill variant={statusVariant}>{offers.data[index].status}</Badge></td>
		                        <td>${offers.data[index].offer_amount.toFixed(2)}</td>
		                        <td>
									<Button disabled={this.state.sending} className="btn-sm kt-mr-15" name={offers.data[index].id} onClick={(e) => this.handleAcceptOffer(e)}>Accept</Button>
									<Button disabled={this.state.sending} className="btn-warning btn-sm kt-mr-15" name={offers.data[index].id} onClick={(e) => this.handleCounterOffer(e)}>Counter</Button>
									<Button disabled={this.state.sending} className="btn-danger btn-sm" name={offers.data[index].id} onClick={(e) => this.handleRejectOffer(e)}>Reject</Button>
		                        </td>
		                    </tr>)
		                }
	                } else {
	                	rows.push(<tr key={index}>
                        <td>{offers.data[index].id}</td>
                        <td>{customer}</td>
                        <td>{offers.data[index].email}</td>
                        <td>{date.toLocaleString()}</td>
                        <td>{offers.data[index].url}</td>
                        <td>${offers.data[index].price.toFixed(2)}</td>
                        <td><Badge pill variant={statusVariant}>{offers.data[index].status}</Badge></td>
                        <td>${offers.data[index].offer_amount.toFixed(2)}</td>
                        <td>
							
                        </td>
                    </tr>)
	                }
                }
            }
        }

        const handleNextPage = (number) => {
            this.props.initOffers(number);
        };

        return (
            <Portlet>
                <PortletHeader title="Offers" />
                <PortletBody>
                    <Table striped bordered hover responsive>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Customer</th>
                            <th>Customer Email</th>
                            <th>Date Offered</th>
                            <th>Site</th>
                            <th>Price</th>
                            <th>Status</th>
                            <th>Offer</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </Table>
                    {offers.meta && offers.meta.last_page > 1 && <UPagination currentPage={offers.meta.current_page} totalPages={offers.meta.last_page} onChange={handleNextPage} />}
                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = ({ offersAdmin: { offers } }) => ({
    offers
});

export default injectIntl(connect(
    mapStateToProps,
    offers.actions
)(OffersAdmin));
