import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import {addOrder, addDeposit, stripeCheckoutConfirm, paypalCheckoutConfirm} from "../../crud/orders.crud";
import * as notifications from "../../store/ducks/notifications.duck";
import * as cart from "../../store/ducks/cart.duck";
import * as profile from "../../store/ducks/profile.duck";
import * as auth from "../../store/ducks/auth.duck";

import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

class CheckoutSuccess extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showError: false,
            errorMessage: "",
			// errorPayment: '',
            // showPaymentBtn: false,
            // rightHeight: "790px",
            paymentCompleted: false,
            // paypalBtnReady: false,
            // paypalProcessing: false,
            // payment_method: "paypal",
            // userArticle:true,
            isProcessing: true,
            // useRemainingBalance: false,
            // userBalance: 0,
            // addOnItems: "",
            confirmingOrder: false,
            successMessage: 'Payment complete.',
            showSuccessMessage: false,
        };
        // this.initialValues = null;
        // this.paypalProcessing = React.createRef();
        // this.paypalButtonToolbar = React.createRef();
    }

    componentDidMount () {
        this.props.requestUser();
    }

    render() {
        const { user, showHi, showAvatar, showBadge, pageData } = this.props;

        const item_number = new URLSearchParams(this.props.location.search).get('item_number');
        const session_id = new URLSearchParams(this.props.location.search).get('session_id');
        const action = new URLSearchParams(this.props.location.search).get('action');

        const confirmStripeOrder = (session_id) => {
            stripeCheckoutConfirm({ session_id: session_id })
            .then(function(confirmResult) {
                return confirmResult;
            }).then(handleConfirmOrderResponse);
        }

        const confirmPayPalOrder = (item_number) => {
            paypalCheckoutConfirm({ item_number: item_number })
            .then(function(confirmResult) {
                return confirmResult;
            }).then(handleConfirmOrderResponse);
        }

        const handleConfirmOrderResponse = (resp) => {
            let response = resp.data;
            // console.log('response',response);
            let cartItems = response.cart_items;
            // let orderData = response.order_data;
            let billingInfo = response.billing_info;
            let use_remaining_balance = response.use_remaining_balance;
            let project_id = response.project_id;
            let cartTotal = response.cart_total;

            let orderData = {};
            if (typeof cartItems !== 'undefined') {
                for (let i = 0; i < cartItems.length; i++) {
                    // onsole.log('AAA cartItems',cartItems[i]);
                    orderData[i] = cartItems[i].orderData;
                }
            }

            // console.log('errorMessage=',response.error);
            // console.log('success=',response.success);
            if (response.success == false && response.error) {
                let completed = response?.order_created !== undefined && response.order_created;

                this.setState({
                    ...this.state,
                    showError: true,
                    errorMessage: response.error,
                    paymentCompleted: completed,
                    // showPaymentBtn: false,
                    // paypalProcessing: false,
                    isProcessing: false,
                    confirmingOrder: true
                });

                if (completed) {
                    this.props.emptyCart();
                    this.props.hideCheckout();
                }
            } else {

                // console.log('orderData',orderData);
                // console.log('billingInfo',billingInfo);
                // console.log('use_remaining_balance',use_remaining_balance);
                // console.log('project_id',project_id);
                // console.log('cartTotal',cartTotal);

                if (response.type == 'deposit') {
                    addDeposit(
                        response.cart_total,
                        response.payment_order_id,
                        response.payment_method,
                        response.billing_info.street_address,
                        response.billing_info.country,
                        response.billing_info.city,
                        response.billing_info.postal_code,
                        response.billing_info.state,
                        response.billing_info.firstname,
                        response.billing_info.lastname,
                        response.billing_info.taxid
                    )
                    .then((res) => {
                        // alert("Deposit Successful.");
                        // window.location.reload();
                        this.setState({
                            ...this.state,
                            showError: false,
                            paymentCompleted: true,
                            // showPaymentBtn: false,
                            // paypalProcessing: false,
                            isProcessing: false,
                            showSuccessMessage: true
                        });

                        // console.log("/checkout-success?session_id=0&action=sdeposit");
                        // console.log(res);
                        // this.props.history.push({
                        //     pathname: "checkout-success?session_id=0&action=sdeposit"
                        // });
                    })
                    .catch(e => {
                        let errorMessage = "";
                        if (e.response) {
                            console.log(e.response.data.message);
                            errorMessage = e.response.data.message;
                        } else {
                            console.log("Error! Try again later.");
                            errorMessage = "Something went wrong. Please contact support or refresh and try again.";
                        }
                        //show payment completed message
                        this.setState({
                            ...this.state,
                            showError: true,
                            errorMessage: errorMessage,
                            // paymentCompleted: false,
                            // showPaymentBtn: false,
                            // paypalProcessing: false,
                            isProcessing: false,
                        });
                    });
                } else {
                    addOrder(orderData, billingInfo, use_remaining_balance, project_id)
                    .then((res) => {
                        const {id, order_number} = res.data.data;

                        // ReactGA.plugin.require('ec');
                        // if (typeof cartItems !== 'undefined') {
                        //     for (let i = 0; i < cartItems.length; i++) {
                        //         let itemPrice = Number(cartItems[i].price) * Number(cartItems[i].orderData.quantity);
                        //         ReactGA.plugin.execute('ec', 'addProduct', {
                        //             id: cartItems[i].key,
                        //             name: cartItems[i].name,
                        //             category: cartItems[i].orderData.type,
                        //             variant: cartItems[i].description,
                        //             price: itemPrice,
                        //             quantity: Number(cartItems[i].orderData.quantity)
                        //         });
                        //     }
                        //     ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
                        //         id: order_number,
                        //         revenue: cartTotal.toFixed(2)
                        //     });
                        //     ReactGA.ga('send', 'event', 'Checkout', 'Purchase');
                            // ReactPixel.track("Purchase", {value: cartTotal.toFixed(2), currency: 'USD'});
                        // }

                        // this.paypalProcessing.current.style.display = "none";
                        // this.paypalButtonToolbar.current.style.display = "flex";

                        //show payment completed message
                        this.setState({
                            ...this.state,
                            showError: false,
                            paymentCompleted: true,
                            // showPaymentBtn: false,
                            // paypalProcessing: false,
                            isProcessing: false,
                        });


                        ReactGA.event({
                            category: 'Checkout',
                            action: 'Purchase',
                            label: 'Successful Checkout',
                            nonInteraction: false,
                            value: Number(cartTotal)
                        });
                        ReactPixel.track("Purchase", {value: cartTotal.toFixed(2), currency: 'USD'});

                        this.props.emptyCart();
                        this.props.hideCheckout();
                        this.props.initBillingInfo();
                        this.props.requestNotifications();
                        this.props.history.push({
                            pathname: "/orders/" + id + "/progress"
                        });
                    })
                    .catch(e => {
                        // setSubmitting(false);
                        let errorMessage = "";
                        if (e.response) {

                            errorMessage = e.response.data.message;
                        } else {
                            // alert("Error! Try again later.");
                            errorMessage = "Something went wrong. Please contact support or refresh and try again.";
                        }

                        // this.paypalProcessing.current.style.display = "none";
                        // this.paypalButtonToolbar.current.style.display = "flex";

                        //show payment completed message
                        this.setState({
                            ...this.state,
                            showError: true,
                            errorMessage: errorMessage,
                            // paymentCompleted: false,
                            // showPaymentBtn: false,
                            // paypalProcessing: false,
                            isProcessing: false,
                        });
                    });
                }
            }
        }

        if (action == 'sdeposit' && session_id == '0') {
            // this.setState({
            //     ...this.state,
            //     // confirmingOrder: false,
            //     // isProcessing: false,
            //     // paymentCompleted: true,
            //     showError: false,
            //     errorMessage: '',
            //     successMessage: 'Deposit complete.'
            // });
        } else
        if (this.state.confirmingOrder == false && this.state.isProcessing == true && typeof session_id !== 'undefined' && session_id != '' && session_id != '0' && session_id != null) {
            confirmStripeOrder(session_id);
            this.setState({
                ...this.state,
                confirmingOrder: true
            });
        } else if (this.state.confirmingOrder == false && this.state.isProcessing == true && typeof item_number !== 'undefined' && item_number != '' && item_number != '0' && item_number != null) {
            confirmPayPalOrder(item_number);
            this.setState({
                ...this.state,
                confirmingOrder: true
            });
        }

        localStorage.setItem('useRemainingBalance',false);

        return (
            <>
                <div className="row kt-mt-30">
                    <div className="col-12">
                    {(action == 'sdeposit' && session_id == '0') || this.state.showSuccessMessage == true ?
                        <div>
                            <h1 className="custom-header kt-mb-25 kt-ml-30">Deposit</h1>
                            <div className="alert alert-error alert-success kt-ml-30" style={{maxWidth:"500px"}}><div className="payment_error" style={{'justify-content': "right"}}>Deposit complete</div></div>
                        </div>
                    : null}
                    {this.state.showError == true && this.state.errorMessage && this.state.paymentCompleted ?
                        <div>
                            <h1 className="custom-header kt-mb-25 kt-ml-30">Order</h1>
                            <div className="alert alert-error alert-success kt-ml-30" style={{maxWidth:"500px"}}><div className="payment_error" style={{'justify-content': "right"}}>{this.state.errorMessage}</div></div>
                        </div>
                    : this.state.showError == true && this.state.errorMessage ?
                        <div>
                            <h1 className="custom-header kt-mb-25 kt-ml-30">Order failed</h1>
                            <div className="alert alert-error alert-danger kt-ml-30" style={{maxWidth: "500px"}}><div className="payment_error" style={{'justify-content': "right"}}>{this.state.errorMessage}</div></div>
                        </div>
                    : null}
                    {this.state.showError == false && this.state.errorMessage ?
                        <div>
                            <h1 className="custom-header kt-mb-25 kt-ml-30">Finishing order..</h1>
                            <div className="spinner-border kt-ml-30" role="status"><span className="sr-only">Loading...</span></div>
                        </div>
                    : null}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default injectIntl(connect(
    mapStateToProps,
    {...cart.actions, ...profile.actions, ...notifications.actions, ...auth.actions}
)(CheckoutSuccess));
// export default connect(mapStateToProps)(CheckoutSuccess);
