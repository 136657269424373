import axios from "axios";

export const base = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL : "";
export const API_MARKETPLACE_URL = base + "/api/marketplace";
export const MY_DOMAIN_WATCHLIST = base + "/api/user-watchlist";

export function marketplaceGuestPosts(page, filter = null) {
    if (
        filter != null
        &&
        typeof filter['type'] !== 'undefined'
        &&
        filter['type'] === 'weekly_link_opportunities'
    ) {
        return axios.post(API_MARKETPLACE_URL + "/weekly_link_opportunities", filter);
    }

    let params = "";
    if(page) {
        params += "?page=" + page;
    }
    // if (filter) {
    //     for (let [key, value] of Object.entries(filter)) {
    //         if(value !== "") params += (params === "" ? "?" : "&") + key + "=" + value;
    //     }
    // }
    return axios.post(API_MARKETPLACE_URL + "/guestposts" + params, filter);
}

export function getDomainInfo(id) {
    return axios.get(API_MARKETPLACE_URL + "/domain/" + id);
}

export function getMarketplaceGuestPost(id) {
    return axios.get(API_MARKETPLACE_URL + "/guestposts/" + id);
}

export function getByLoggedInUser() {
    return axios.get(API_MARKETPLACE_URL + "/myblogs");
}

export function addNewBlog(blogPrice, blogUrl, blogCategoryId, metaVerificationString, blogDr, blogOrganicTraffic) {
    return axios.post(API_MARKETPLACE_URL + "/myblogs", { blogPrice, blogUrl, blogCategoryId, metaVerificationString, blogDr, blogOrganicTraffic });
}

export function updateBlog(blogPrice, blogUrl, blogCategoryId, blogDr, blogOrganicTraffic) {
    return axios.put(API_MARKETPLACE_URL + "/myblogs", { blogPrice, blogUrl, blogCategoryId, blogDr, blogOrganicTraffic });
}

export function getDomains(page, filter = null) {
    let params = "";
    if(page) {
        params += "&page=" + page;
    }
    if(filter) {
        for (let [key, value] of Object.entries(filter)) {
            if(value !== "") {
                // params += (params === "" ? "?" : "&") + key + "=" + value;
                params += "&"+key+"="+value;
            }
        }
    }
    // let config = {
    //     headers: {
    //         "Yourdomain-Api-Key": "eLfzWBHrYC6QLQMrevnaF",
    //         "Content-Type": "application/json"
    //     }
    // }
    return axios.get("https://api-yd.serppro.io/api/get.php?method=get"+params, filter); //, config);
}

export function getDomainInfoYourDomain(id) {
    return axios.get("https://api-yd.serppro.io/api/get.php?method=getInfo&did="+id); //, filter); //, config);
}

export function getWatchlistDomains() {
    return axios.get(MY_DOMAIN_WATCHLIST + "/domains");
}
export function getWatchlist(page) {
    let params = "";
    if(page) {
        params += "?page=" + page;
    }

    return axios.get(MY_DOMAIN_WATCHLIST + "/all" + params);
}
