import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {getBillingInfo, getUserOptions} from "../../crud/user.crud";

export const actionTypes = {
    BillingInfoInit: "[Init Billing Info] Action",
    BillingInfoRequested: "[Request Billing Info] Action",
    BillingInfoLoaded: "[Load Billing Info] User Billing API",
    UserOptionsRequested: "[Request User Options] Action",
    UserOptionsLoaded: "[Load User Options] User API",
};

const initialBillingState = {
    billingInfo: undefined,
    userOptions: undefined,
};

export const reducer = persistReducer(
    { storage, key: "demo3-billing", whitelist: ["billingInfo", "userOptions"] },
    (state = initialBillingState, action) => {
        switch (action.type) {
            case actionTypes.BillingInfoInit: {
                return { ...state, billingInfo: undefined };
            }

            case actionTypes.BillingInfoLoaded: {
                const { billingInfo } = action.payload;

                return { ...state, billingInfo };
            }

            case actionTypes.UserOptionsRequested: {
                return { ...state, userOptions: undefined };
            }

            case actionTypes.UserOptionsLoaded: {
                const { userOptions } = action.payload;

                return { ...state, userOptions: userOptions };
            }

            default:
                return state;
        }
    }
);

export const actions = {
    initBillingInfo: () => ({ type: actionTypes.BillingInfoInit }),
    requestBillingInfo: billingInfo => ({ type: actionTypes.BillingInfoRequested, payload: { billingInfo } }),
    fulfillBillingInfo: billingInfo => ({ type: actionTypes.BillingInfoLoaded, payload: { billingInfo } }),
    requestUserOptions: () => ({ type: actionTypes.UserOptionsRequested }),
    fulfillUserOptions: (userOptions) => ({ type: actionTypes.UserOptionsLoaded, payload: { userOptions } }),
};

export function* saga() {
    yield takeLatest(actionTypes.BillingInfoInit, function* initBillingInfoSaga() {
        yield put(actions.requestBillingInfo());
    });

    yield takeLatest(actionTypes.BillingInfoRequested, function* billingInfoRequested() {
        const { data: billingInfo } = yield getBillingInfo();

        yield put(actions.fulfillBillingInfo(billingInfo));
    });

    yield takeLatest(actionTypes.UserOptionsRequested, function* userOptionsRequested(action) {
        try {
            const {data: userOptions} = yield getUserOptions();

            yield put(actions.fulfillUserOptions(userOptions));
        }
        catch (e) {
            yield put(actions.fulfillUserOptions({data: undefined}));
        }
    });
}
