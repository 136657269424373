import React, {Component} from "react";
import {Modal, Button} from "react-bootstrap";
import * as analytics from "../../../store/ducks/analytics.duck";
import {getSite} from "../../../crud/analytics.crud";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import InnerModal from "./InnerModal";


class ManageProjectModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            site: null,
        };

    }


    componentDidMount() {

        const currentProjectId = this.props.currentProject.data.id;
        if (currentProjectId > 0) {
            //this.props.requestAnalyticsUser(this.props.auth.id);
            this.props.requestAnalyticsSite(currentProjectId);
        }
    }




    render() {

        //console.log('modal-state', this.state); console.log('modal-props', this.props);

        const {currentProject, show, site} = this.props;


        if(!currentProject.data)
            return null;



        return (
            <Modal
                show={show}
                onHide={this.props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Manage {currentProject.data.url}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InnerModal
                        site={site}
                        /*user={user}*/
                        currentProject={currentProject.data}
                    />
                 </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onHide}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = ({ analytics: {site} }) => ({
    site
});

/*

const mapStateToProps = ({ auth: { user: auth }, analytics: {site,  user} }) => ({
    auth, site, user
});
*/


export default injectIntl(connect(
    mapStateToProps, //null, //
    analytics.actions
)(ManageProjectModal));
