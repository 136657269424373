import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {
    getProjects,
    getUser,
    addUser,
    getSite,
    addSite,
    delSite,
    getAnalytic,
    getNotes,
    getGoogleUpdates,
    getSiteData
} from "../../crud/analytics.crud";

export const actionTypes = {
    AnalyticsInit: "[Init Analytics] Action",

    ProjectsAnalyticsRequested: "[Request ProjectsAnalytics] Action",
    ProjectsAnalyticsLoaded: "[Load ProjectsAnalytics] Projects API",

    SiteRequested: "[Request SiteAnalytics] Action",
    SiteLoaded: "[Load SiteAnalytics] Projects API",
    AddSiteRequested: "[Request AddSiteAnalytics] Action",
    AddSiteLoaded: "[Load AddSiteAnalytics] Analytics API",
    DelSiteRequested: "[Request DelSiteAnalytics] Action",
    DelSiteLoaded: "[Load DelSiteAnalytics] Analytics API",

    UserRequested: "[Request UserAnalytics] Action",
    UserLoaded: "[Load UserAnalytics] Analytics API",
    AddUserRequested: "[Request AddUserAnalytics] Action",
    AddUserLoaded: "[Load AddUserAnalytics] Analytics API",

    DataAnalyticRequested: "[Request DataAnalytics] Action",
    DataAnalyticLoaded: "[Load DataAnalytics] Projects API",

    NotesAnalyticRequested: "[Request NotesAnalytics] Action",
    NotesAnalyticLoaded: "[Load NotesAnalytics] Projects API",

    GoogleUpdatesAnalyticRequested: "[Request GoogleUpdatesAnalytics] Action",
    GoogleUpdatesAnalyticLoaded: "[Load GoogleUpdatesAnalytics] Projects API",

    SiteDataRequested: "[Request SiteData] Action",
    SiteDataLoaded: "[Load SiteData] Analytics API",
};

const initialAnalyticsState = {
    user: undefined,
    site: undefined,
    projects: undefined,
    analytic: undefined,
    notes: undefined,
    googleupdates: undefined,
    siteData: undefined,
};

export const reducer = persistReducer(
    { storage, key: "analytics", whitelist: ["user", "site", "projects", "analytic", "notes", "googleupdates", "siteData"] },
    (state = initialAnalyticsState, action) => {
        switch (action.type) {

            case actionTypes.AnalyticsInit: {
                return {
                    user: undefined,
                    site: undefined,
                    projects: undefined,
                    analytic: undefined,
                    notes: undefined,
                    googleupdates: undefined
                };
            }


            case actionTypes.ProjectsAnalyticsLoaded: {
                const { projects } = action.payload;

                return { ...state, projects };
            }

            case actionTypes.SiteRequested: {
                return { ...state, site: undefined };
            }

            case actionTypes.SiteLoaded: {
                const { site } = action.payload;
                return { ...state, site };
            }

            case actionTypes.UserRequested: {
                return { ...state, user: undefined };
            }

            case actionTypes.UserLoaded: {
                const { user } = action.payload;
                return { ...state, user };
            }

            case actionTypes.AddUserRequested: {
                return { ...state, user: undefined };
            }

            case actionTypes.AddUserLoaded: {
                const { user } = action.payload;
                return { ...state, user };
            }

            case actionTypes.AddSiteRequested: {
                return { ...state, site: undefined };
            }

            case actionTypes.AddSiteLoaded: {
                const { site } = action.payload;
                return { ...state, site };
            }

            case actionTypes.DataAnalyticRequested: {
                return { ...state, analytic: undefined };
            }

            case actionTypes.DataAnalyticLoaded: {
                const { analytic } = action.payload;
                return { ...state, analytic };
            }

            case actionTypes.NotesAnalyticRequested: {
                return { ...state, notes: undefined };
            }

            case actionTypes.NotesAnalyticLoaded: {
                const { notes } = action.payload;
                return { ...state, notes };
            }

            case actionTypes.GoogleUpdatesAnalyticRequested: {
                return { ...state, googleupdates: undefined };
            }

            case actionTypes.GoogleUpdatesAnalyticLoaded: {
                const { googleupdates } = action.payload;
                return { ...state, googleupdates };
            }

            case actionTypes.SiteDataRequested: {
                const { type } = action.payload;
                if(!type) {
                    return {...state, siteData: undefined};
                }
                else {
                    return {...state, siteData: {data: {...state.siteData.data, [type]: undefined}}};
                }
            }

            case actionTypes.SiteDataLoaded: {
                const { siteData, type } = action.payload;
                if(!type) {
                    return {...state, siteData};
                }
                else {
                    return {...state, siteData: {data: {...state.siteData.data, [type]: siteData && siteData.data && siteData.data[type] ? siteData.data[type] : {}}}};
                }
            }

            default:
                return state;
        }
    }
);

export const actions = {
    initAnalytics: proj_id => ({ type: actionTypes.AnalyticsInit, payload: { proj_id } }),

    requestAnalyticsProjects: page => ({ type: actionTypes.ProjectsAnalyticsRequested, payload: { page } }),
    fulfillAnalyticsProjects: projects => ({ type: actionTypes.ProjectsAnalyticsLoaded, payload: { projects } }),
    requestAnalyticsSite: id => ({ type: actionTypes.SiteRequested, payload: { id } }),
    fulfillAnalyticsSite: site => ({ type: actionTypes.SiteLoaded, payload: { site } }),
    requestAnalyticsUser: id => ({ type: actionTypes.UserRequested, payload: { id } }),
    fulfillAnalyticsUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),

    requestAnalyticsAddUser: id => ({ type: actionTypes.AddUserRequested, payload: { id } }),
    fulfillAnalyticsAddUser: user => ({ type: actionTypes.AddUserLoaded, payload: { user } }),
    requestAnalyticsAddSite: id => ({ type: actionTypes.AddSiteRequested, payload: { id } }),
    fulfillAnalyticsAddSite: site => ({ type: actionTypes.AddSiteLoaded, payload: { site } }),

    requestAnalyticsData: (id, params) => ({ type: actionTypes.DataAnalyticRequested, payload: { id, params } }),
    fulfillAnalyticsData: analytic => ({ type: actionTypes.DataAnalyticLoaded, payload: { analytic } }),

    requestAnalyticsNotes: (proj_id) => ({ type: actionTypes.NotesAnalyticRequested, payload: { proj_id} }),
    fulfillAnalyticsNotes: notes => ({ type: actionTypes.NotesAnalyticLoaded, payload: { notes } }),

    requestAnalyticsGoogleUpdates: () => ({ type: actionTypes.GoogleUpdatesAnalyticRequested, payload: {} }),
    fulfillAnalyticsGoogleUpdates: googleupdates => ({ type: actionTypes.GoogleUpdatesAnalyticLoaded, payload: { googleupdates } }),

    requestSiteData: (projectId, type, page) => ({ type: actionTypes.SiteDataRequested, payload: { projectId, type, page } }),
    fulfillSiteData: (siteData, type) => ({ type: actionTypes.SiteDataLoaded, payload: { siteData, type } }),
};

export function* saga() {
    yield takeLatest(actionTypes.AnalyticsInit, function* initAnalyticsSaga(action) {
        const { proj_id } = action.payload;

        yield put(actions.requestAnalyticsSite(proj_id));
        yield put(actions.requestAnalyticsNotes(proj_id));
        yield put(actions.requestAnalyticsGoogleUpdates());

    });


    yield takeLatest(actionTypes.ProjectsAnalyticsRequested, function* projectsAnalyticsRequested(action) {
        try {
            const { page } = action.payload;
            const {data: projects} = yield getProjects(page);

            yield put(actions.fulfillAnalyticsProjects(projects.data));
        }
        catch (e) {
            yield put(actions.fulfillAnalyticsProjects(false));
        }
    });


    yield takeLatest(actionTypes.NotesAnalyticRequested, function* notesAnalyticsRequested(action) {
        try {
            const { proj_id } = action.payload;
            const notes = yield getNotes(proj_id);
            yield put(actions.fulfillAnalyticsNotes(notes.data));

        }
        catch (e) {
            yield put(actions.fulfillAnalyticsNotes(false));
        }
    });


    yield takeLatest(actionTypes.GoogleUpdatesAnalyticRequested, function* googleUpdatesAnalyticsRequested(action) {
        try {

            const googleupdates = yield getGoogleUpdates();
            yield put(actions.fulfillAnalyticsGoogleUpdates(googleupdates));

        }
        catch (e) {
            yield put(actions.fulfillAnalyticsGoogleUpdates(false));
        }
    });


    yield takeLatest(actionTypes.DataAnalyticRequested, function* dataAnalyticsRequested(action) {
        try {

            const { id, params } = action.payload;
            const analytic = yield getAnalytic(id, params);
            yield put(actions.fulfillAnalyticsData(analytic.data));
        }
        catch (e) {
            yield put(actions.fulfillAnalyticsData(false));
        }
    });


    yield takeLatest(actionTypes.SiteRequested, function* siteRequested(action) {
        try {
            const { id } = action.payload;
            const site = yield getSite(id);
            yield put(actions.fulfillAnalyticsSite(site.data));
        }
        catch (e) {
            yield put(actions.fulfillAnalyticsSite(false));
        }
    });

    yield takeLatest(actionTypes.UserRequested, function* userRequested(action) {
        try {
            const { id } = action.payload;
            const user = yield getUser(id);
            yield put(actions.fulfillAnalyticsUser(user.data));
        }
        catch (e) {
            yield put(actions.fulfillAnalyticsUser(false));
        }
    });

    yield takeLatest(actionTypes.AddUserRequested, function* userAddRequested(action) {
        try {
            const { id } = action.payload;
            const user = yield addUser(id);
            yield put(actions.fulfillAnalyticsAddUser(user.data));
        }
        catch (e) {
            yield put(actions.fulfillAnalyticsAddUser(false));
        }
    });

    yield takeLatest(actionTypes.AddSiteRequested, function* siteAddRequested(action) {
        try {
            const { id } = action.payload;
            const site = yield addSite(id);
            yield put(actions.fulfillAnalyticsAddSite(site.data));
        }
        catch (e) {
            yield put(actions.fulfillAnalyticsAddSite(false));
        }
    });

    yield takeLatest(actionTypes.SiteDataRequested, function* siteDataRequested(action) {
        const { projectId, type, page } = action.payload;
        try {
            const siteData = yield getSiteData(projectId, type, page);
            yield put(actions.fulfillSiteData(siteData, type));
        }
        catch (e) {
            yield put(actions.fulfillSiteData({data:undefined}, type));
        }
    });
}
