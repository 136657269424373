import React from 'react';
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as marketplace from "../../../store/ducks/marketplace.duck";
import {Spinner} from "reactstrap";

const metricLabels = {
    mozLinks: "Moz External Backlinks",
    mozDA: "Moz Domain Authority",
    mozPA: "Moz Page Authority",
    mozRank: "Moz Rank",
    majesticLinks: "Majestic External Backlinks",
    majesticRefDomains: "Majestic Referring Domains",
    majesticCF: "Majestic Citation Flow",
    majesticTF: "Majestic Trust Flow",
    majesticTTF0Name: "Majestic Topical Trust Flow 1",
    majesticTTF1Name: "Majestic Topical Trust Flow 2",
    majesticTTF2Name: "Majestic Topical Trust Flow 3",
    FB_shares: "Facebook Shares",
    FB_comments: "Facebook Comments",
    pinterest_pins: "Pinterest Pins",
};

class DomainInfo extends React.Component {
    componentDidMount() {
        const {domainInfo, id} = this.props;
        if(!domainInfo["di" + id]) {
            this.props.requestDomain(id);
        }
    }

    render() {
        const {domainInfo, id} = this.props;

        if(!domainInfo || !domainInfo["di" + id])
        {
            return <div className="di-popup-inner"><Spinner /></div>;
        }

        if(domainInfo["di" + id].data === "error") {
            return "Error. Data not loaded.";
        }

        const metricsData = [];
        const metrics = [];
        for(const [key, value] of Object.entries(domainInfo["di" + id])) {
            if(key !== "_id" && key !== "api_provider") {
                metrics[key] = value;
            }
        }
        for(const [key, value] of Object.entries(metricLabels)) {
            if(metrics[key]) {
                metricsData.push(<div key={key}>
                    <strong>{value}:</strong> {metrics[key]}
                </div>);
            }
        }

        return (<div className="di-popup-inner">
            {metricsData && metricsData.length !== 0 ? metricsData : "No data. Try again later."}
        </div>);
    }
}

const mapStateToProps = ({ marketplace: { domainInfo } }) => ({
    domainInfo
});

export default injectIntl(connect(
    mapStateToProps,
    marketplace.actions,
)(DomainInfo));
