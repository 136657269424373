import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {getDomainInfo, getDomainInfoYourDomain, marketplaceGuestPosts, getMarketplaceGuestPost, getByLoggedInUser, getWatchlistDomains, getDomains, getWatchlist} from "../../crud/marketplace.crud";

export const actionTypes = {
    MarketplaceInit: "[Init Marketplace] Action",
    MarketplaceRequested: "[Request Marketplace] Action",
    MarketplaceLoaded: "[Load Marketplace] Marketplace API",
    DomainRequested: "[Request Domain] Action",
    DomainLoaded: "[Load Domain] Marketplace API",
    GuestPostRequested: "[Request GuestPost] Action",
    GuestPostLoaded: "[Load GuestPost] Marketplace API",

    MyBlogsRequested: "[Request My Blogs] Action",
    MyBlogsLoaded: "[Load My Blogs] Marketplace API",

    MyWatchlistDomainsRequested: "[Request My Watchlist Domains] Action",
    MyWatchlistDomainsLoaded: "[Load My Watchlist Domains] Marketplace API",

    MyWatchlistRequested: "[Request My Watchlist] Action",
    MyWatchlistLoaded: "[Load My Watchlist] Marketplace API",


    DomainMarketplaceInit: "[Init Domain Marketplace] Action",
    DomainMarketplaceRequested: "[Request Domain Marketplace] Action",
    DomainMarketplaceLoaded: "[Load Domain Marketplace] Marketplace API",

    DomainRequestedYourDomain: "[Request YD Domain] Action",
    DomainLoadedYourDomain: "[Load YD Domain] Marketplace API",
};

const initialMarketplaceState = {
    guestPosts: undefined,
    watchlistArray: undefined,
    filter: {},
    accessDenied: undefined,
    guestPostItem: undefined,
    domainInfo: undefined,
};

export const reducer = persistReducer(
    { storage, key: "marketplace", whitelist: ["guestPosts", "watchlistArray", "filter", "accessDenied", "domainInfo", "guestPostItem"] },
    (state = initialMarketplaceState, action) => {
        switch (action.type) {
            case actionTypes.MarketplaceInit: {
                const { filter } = action.payload;

                return { guestPosts: undefined, filter, accessDenied: undefined, domainInfo: undefined, guestPostItem: undefined };
            }

            case actionTypes.MarketplaceLoaded: {
                const { guestPosts, filter, accessDenied } = action.payload;

                return { ...state, guestPosts, filter, accessDenied: accessDenied ? true : false, domainInfo: {} };
            }

            case actionTypes.DomainMarketplaceLoaded: {
                const { guestPosts, filter } = action.payload;

                return { ...state, guestPosts, filter, domainInfo: {} };
            }

            case actionTypes.DomainLoaded: {
                const { domainInfo, id } = action.payload;

                return { ...state, domainInfo: {...state.domainInfo, ["di" + id]: domainInfo} };
            }

            case actionTypes.DomainLoadedYourDomain: {
                const { domainInfo, id } = action.payload;

                return { ...state, domainInfo: {...state.domainInfo, ["di" + id]: domainInfo} };
            }

            case actionTypes.GuestPostLoaded: {
                const { guestPost, id } = action.payload;

                return { ...state, guestPostItem: {["mgp" + id]: guestPost} };
            }

            case actionTypes.MyBlogsRequested: {
                return { ...state, guestPosts: undefined };
            }
            case actionTypes.MyBlogsLoaded: {
                const { guestPosts } = action.payload;

                return { ...state, guestPosts };
            }

            case actionTypes.MyWatchlistDomainsRequested: {
                return { ...state, watchlistArray: undefined };
            }
            case actionTypes.MyWatchlistDomainsLoaded: {
                const { watchlistArray } = action.payload;

                return { ...state, watchlistArray };
            }

            case actionTypes.MyWatchlistRequested: {
                return { ...state, watchlistArray: undefined };
            }
            case actionTypes.MyWatchlistLoaded: {
                const { watchlistArray } = action.payload;

                return { ...state, watchlistArray };
            }

            default:
                return state;
        }
    }
);

export const actions = {
    initMarketplace: (page, filter) => ({ type: actionTypes.MarketplaceInit, payload: { page, filter } }),
    requestMarketplace: (page, filter) => ({ type: actionTypes.MarketplaceRequested, payload: { page, filter } }),
    fulfillMarketplace: (guestPosts, filter, accessDenied) => ({ type: actionTypes.MarketplaceLoaded, payload: { guestPosts, filter, accessDenied } }),
    requestDomain: (id) => ({ type: actionTypes.DomainRequested, payload: { id } }),
    fulfillDomain: (domainInfo, id) => ({ type: actionTypes.DomainLoaded, payload: { domainInfo, id } }),
    requestGuestPost: (id) => ({ type: actionTypes.GuestPostRequested, payload: { id } }),
    fulfillGuestPost: (guestPost, id) => ({ type: actionTypes.GuestPostLoaded, payload: { guestPost, id } }),

    requestMyBlogs: () => ({ type: actionTypes.MyBlogsRequested }),
    fulfillMyBlogs: (guestPosts) => ({ type: actionTypes.MyBlogsLoaded, payload: { guestPosts } }),

    initDomainMarketplace: (page, filter) => ({ type: actionTypes.DomainMarketplaceInit, payload: { page, filter } }),
    requestDomainMarketplace: (page, filter) => ({ type: actionTypes.DomainMarketplaceRequested, payload: { page, filter } }),
    fulfillDomainMarketplace: (guestPosts, filter) => ({ type: actionTypes.DomainMarketplaceLoaded, payload: { guestPosts, filter } }),

    requestDomainYourDomain: (id) => ({ type: actionTypes.DomainRequestedYourDomain, payload: { id } }),
    fulfillDomainYourDomain: (domainInfo, id) => ({ type: actionTypes.DomainLoadedYourDomain, payload: { domainInfo, id } }),

    requestMyWatchlistDomains: () => ({ type: actionTypes.MyWatchlistDomainsRequested }),
    fulfillMyWatchlistDomains: (watchlistArray) => ({ type: actionTypes.MyWatchlistDomainsLoaded, payload: { watchlistArray } }),

    requestMyWatchlist: (page) => ({ type: actionTypes.MyWatchlistRequested, payload: { page } }),
    fulfillMyWatchlist: (watchlistArray) => ({ type: actionTypes.MyWatchlistLoaded, payload: { watchlistArray } }),
};

export function* saga() {
    yield takeLatest(actionTypes.DomainMarketplaceInit, function* initDomainMarketplaceSaga(action) {
        const { page, filter } = action.payload;
        yield put(actions.requestDomainMarketplace(page, filter));
    });
    yield takeLatest(actionTypes.DomainMarketplaceRequested, function* domainMarketplaceRequested(action) {
        const { page, filter } = action.payload;
        const {data: guestPosts} = yield getDomains(page, filter);
        yield put(actions.fulfillDomainMarketplace(guestPosts, filter));
    });

    yield takeLatest(actionTypes.DomainRequestedYourDomain, function* domainRequestedYourDomain(action) {
        const { id } = action.payload;
        try {
            const {data: domainInfo} = yield getDomainInfoYourDomain(id);

            yield put(actions.fulfillDomainYourDomain(domainInfo, id));
        }
        catch (e) {
            yield put(actions.fulfillDomainYourDomain({data: "error"}, id));
        }
    });



    yield takeLatest(actionTypes.MarketplaceInit, function* initMarketplaceSaga(action) {
        const { page, filter } = action.payload;
        yield put(actions.requestMarketplace(page, filter));
    });

    yield takeLatest(actionTypes.MyBlogsRequested, function* myBlogsRequested(action) {
        const { data: guestPosts } = yield getByLoggedInUser();
        yield put(actions.fulfillMyBlogs(guestPosts));
    });


    yield takeLatest(actionTypes.MyWatchlistDomainsRequested, function* myWatchlistDomainsRequested(action) {
        const { data: watchlistArray } = yield getWatchlistDomains();
        yield put(actions.fulfillMyWatchlistDomains(watchlistArray));
    });
    yield takeLatest(actionTypes.MyWatchlistRequested, function* myWatchlistRequested(action) {
        const { page } = action.payload;
        const { data: watchlistArray } = yield getWatchlist(page);
        yield put(actions.fulfillMyWatchlist(watchlistArray));
    });

    yield takeLatest(actionTypes.MarketplaceRequested, function* marketplaceRequested(action) {
        const { page, filter } = action.payload;
        let accessDenied = false;
        try {
            const {data: guestPosts} = yield marketplaceGuestPosts(page, filter);

            yield put(actions.fulfillMarketplace(guestPosts, filter, accessDenied));
        }
        catch (e) {
            if(e && e.response && e.response.data && e.response.data.error_code === "access_denied") {
                accessDenied = true;
            }

            yield put(actions.fulfillMarketplace({data: undefined}, filter, accessDenied));
        }
    });

    yield takeLatest(actionTypes.DomainRequested, function* domainRequested(action) {
        const { id } = action.payload;
        try {
            const {data: domainInfo} = yield getDomainInfo(id);

            yield put(actions.fulfillDomain(domainInfo, id));
        }
        catch (e) {
            yield put(actions.fulfillDomain({data: "error"}, id));
        }
    });

    yield takeLatest(actionTypes.GuestPostRequested, function* guestPostRequested(action) {
        const { id } = action.payload;
        try {
            const {data: guestPost} = yield getMarketplaceGuestPost(id);

            yield put(actions.fulfillGuestPost(guestPost, id));
        }
        catch (e) {
            yield put(actions.fulfillGuestPost({data: undefined}, id));
        }
    });
}
