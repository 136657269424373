import React, { Component } from "react";
import "../../../../_metronic/_assets/sass/pages/todo/todo.scss";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as projects from "../../../store/ducks/projects.duck";
import {Spinner} from "reactstrap";
import {Portlet, PortletBody} from "../../../partials/content/Portlet";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import ManageProjectModal from "../analytics-components/ManageProjectModal";
import * as auth from "../../../store/ducks/auth.duck";
import VideoTutorialModal from "./VideoTutorialModal";
import { userIntoTasks } from "../../../crud/auth.crud";

class ProjectDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalShow: false,
            openVideoTutorialModal: false,
            watchedIntoVideoHolder: false,
        };
    }
    componentDidMount() {
        this.props.requestUser();

        if(this.props.match && this.props.match.params) {
            const {id} = this.props.match.params;
            this.props.requestProject(id);
        }
        else {
            const currentProjectId = this.props.currentProjectId;
            if(currentProjectId > 0) {
                this.props.requestProject(currentProjectId);
            }
        }
        
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const currentProjectId = this.props.currentProjectId;
        if(currentProjectId > 0 && currentProjectId !== prevProps.currentProjectId) {
            this.props.requestProject(currentProjectId);
        }
    }

    render() {
        const project = this.props.currentProject;
        const currentProjectId = this.props.currentProjectId;
        const {user} = this.props;

        this.openVideoTutorialModal = (e) => {
            let postData = {
                watched_intro_video: true,
            }
            userIntoTasks(
                postData
            )
            .then((res) => {

            })
            .catch((e) => {
                
            });
            this.setState({...this.state, openVideoTutorialModal: true, watchedIntoVideoHolder: true});
        }

        const videoTutorialModalClose = (e) => {
            this.setState({...this.state, openVideoTutorialModal: false, watchedIntoVideoHolder: true});
        }

        if(!currentProjectId) {
            return <></>;
        }
        else if (!project) {
            // return <Spinner/>;
            return <></>;
        }
        else if(!project.data) {
            return <></>;
            return (
                <>
                <h1>Project not found!</h1>
                <Button onClick={()=>{this.props.reloadProjects();}}>Refresh</Button>
                </>
            );
        }

        let watched_intro_video = 0;
        if (user) {
            watched_intro_video = user.watched_intro_video;
        }
        if (this.state.watchedIntoVideoHolder) {
            watched_intro_video = true;
        }

        let is_blogger = false;
        if (user) {
            is_blogger = user.blogger;
        }

        let used_deposit_promotion = false;
        if (user) {
            used_deposit_promotion = user.used_deposit_promotion;
        }

        let has_ordered = false;
        if (user) {
            has_ordered = user.has_ordered;
        }

        // let keywords = project.data.keywords.split('\n').map((item, i) => <span key={i}>{(i > 0 ? ", " : " ") + item}</span>);
        const modalClose = () => {this.setState({ modalShow: false })};
        const modalOpen = () => {this.setState({ modalShow: true })};

        return (
            <>
                {/* <Portlet>
                    <PortletBody>
                        <h2>Project URL: {project.data.url}</h2>
                        <div>
                            <strong>Keywords:</strong>
                            {keywords}
                        </div>
                    </PortletBody>
                </Portlet> */}
                {/* <VideoTutorialModal open={this.state.openVideoTutorialModal} onClose={videoTutorialModalClose} /> */}
                {/* <div className="kt-grid kt-grid--desktop kt-grid--ver-desktop  kt-todo" id="kt_todo">
                    <div className="kt-grid__item kt-grid__item--fluid  kt-portlet kt-portlet--height-fluid kt-todo__list" id="kt_todo_list">
                        <div className="kt-portlet__body kt-portlet__body--fit-x">
                            <div className="kt-todo__header">
                                <h3 className="kt-todo__title">Your Personal To-Do List</h3>
                            </div>
                            <div className="kt-todo__body kt-padding-t-0">
                                <div className="kt-todo__items">

                                    <div className="kt-todo__item" style={{minWidth:"auto"}} onClick={(e) => this.openVideoTutorialModal(e)}>
                                        <div className="kt-todo__info">
                                            <div className="kt-todo__actions">
                                                <label
                                                    className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand">
                                                    <input type="checkbox" readOnly={true} checked={watched_intro_video} />
                                                    <span/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="kt-todo__details" data-toggle="view">
                                            <div className="kt-todo__message">
                                                <span className="kt-todo__subject" style={watched_intro_video ? {textDecoration:"line-through"} : {}}>Watch Video Tutorial</span><br/>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div className="kt-todo__item" style={{minWidth:"auto"}}>
                                        <div className="kt-todo__info">
                                            <div className="kt-todo__actions">
                                                <label
                                                    className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand">
                                                    <input type="checkbox" readOnly={true} checked={true} />
                                                    <span/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="kt-todo__details" data-toggle="view">
                                            <div className="kt-todo__message">
                                                <span className="kt-todo__subject" style={{textDecoration:"line-through"}}>Create a project</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="kt-todo__item" style={{minWidth:"auto"}}>
                                        <div className="kt-todo__info">
                                            <div className="kt-todo__actions">
                                                <label
                                                    className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand">
                                                    <input type="checkbox" readOnly={true} checked={has_ordered} />
                                                    <span/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="kt-todo__details" data-toggle="view">
                                            <div className="kt-todo__message">
                                                <span className="kt-todo__subject" style={has_ordered ? {textDecoration:"line-through"} : {}}>Connect with Bloggers</span><br/>
                                                <span className="kt-todo__text" style={has_ordered ? {textDecoration:"line-through"} : {}}>
                                                    Visit Our <Link to="/marketplace">Marketplace</Link>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="kt-todo__item" style={{minWidth:"auto"}}>
                                        <div className="kt-todo__info">
                                            <div className="kt-todo__actions">
                                                <label
                                                    className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand">
                                                    <input type="checkbox" readOnly={true} checked={used_deposit_promotion} />
                                                    <span/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="kt-todo__details" data-toggle="view">
                                            <div className="kt-todo__message">
                                                <span className="kt-todo__subject" style={used_deposit_promotion ? {textDecoration:"line-through"} : {}}>Deposit $1,000 And Earn +10% Credits</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="kt-todo__item" style={{minWidth:"auto"}}>
                                        <div className="kt-todo__info">
                                            <div className="kt-todo__actions">
                                                <label
                                                    className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand">
                                                    <input type="checkbox" readOnly={true} checked={project.data.seo_service_campaign} />
                                                    <span/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="kt-todo__details" data-toggle="view">
                                            <div className="kt-todo__message">
                                                <span className="kt-todo__subject" style={project.data.seo_service_campaign ? {textDecoration:"line-through"} : {}}>Start Campaign Wizard & Order SEO Services</span><br/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="kt-todo__item" style={{minWidth:"auto"}}>
                                        <div className="kt-todo__info">
                                            <div className="kt-todo__actions">
                                                <label
                                                    className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand">
                                                    <input type="checkbox" readOnly={true} checked={is_blogger} />
                                                    <span/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="kt-todo__details" data-toggle="view">
                                            <div className="kt-todo__message">
                                                <span className="kt-todo__subject" style={is_blogger ? {textDecoration:"line-through"} : {}}>Become a Blogger & Earn Money</span><br/>
                                                <span className="kt-todo__text" style={is_blogger ? {textDecoration:"line-through"} : {}}>
                                                    Visit your <Link to="/profile">My Profile to apply</Link>
                                                </span>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="kt-todo__item" style={{minWidth:"auto"}}>
                                        <div className="kt-todo__info">
                                            <div className="kt-todo__actions">
                                                <label
                                                    className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand">
                                                    <input type="checkbox" readOnly={true} checked={project.data.seo_service_campaign} />
                                                    <span/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="kt-todo__details" data-toggle="view">
                                            <div className="kt-todo__message">
                                                <span className="kt-todo__subject" style={project.data.seo_service_campaign ? {textDecoration:"line-through"} : {}}>Launch On-Page SEO Campaign</span><br/>
                                                <span className="kt-todo__text">
                                                    Visit Our <Link to="/seo-services/on-page-optimization">On-Page Optimization</Link>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="kt-todo__item" style={{minWidth:"auto"}}>
                                        <div className="kt-todo__info">
                                            <div className="kt-todo__actions">
                                                <label
                                                    className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand">
                                                    <input type="checkbox" readOnly={true} checked={project.data.guest_post_campaign} />
                                                    <span/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="kt-todo__details" data-toggle="view">
                                            <div className="kt-todo__message">
                                                <span className="kt-todo__subject" style={project.data.guest_post_campaign ? {textDecoration:"line-through"} : {}}>Launch Guest Post Packages</span><br/>
                                                <span className="kt-todo__text">
                                                    Visit Our <Link to="/orders/guest-post-dfy">Guest Post Packages</Link> or <Link to="/marketplace">Guest Post Marketplace</Link>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="kt-todo__item">
                                        <div className="kt-todo__info">
                                            <div className="kt-todo__actions">
                                                <label
                                                    className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand">
                                                    <input type="checkbox" readOnly={true} checked={project.data.tracking_code_added} />
                                                    <span/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="kt-todo__details" data-toggle="view">
                                            <div className="kt-todo__message">
                                                <span className="kt-todo__subject" style={project.data.tracking_code_added ? {textDecoration:"line-through"} : {}}>Add Tracking Code</span><br/>
                                                <span className="kt-todo__text">
                                                    <Button variant="success" size="sm" onClick={modalOpen}>Get Tracking Code</Button>
                                                    <ManageProjectModal
                                                        show={this.state.modalShow}
                                                        currentProject={project}
                                                        onHide={modalClose}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="kt-todo__item" style={{minWidth:"auto", display:"none"}}>
                                        <div className="kt-todo__info">
                                            <div className="kt-todo__actions">
                                                <label
                                                    className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand">
                                                    <input type="checkbox" readOnly={true} checked={false} />
                                                    <span/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="kt-todo__details" data-toggle="view">
                                            <div className="kt-todo__message">
                                                <span className="kt-todo__subject">Start tracking your rankings</span><br/>
                                                <span className="kt-todo__text">See how your pages are performing in the SERP's</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="kt-todo__item" style={{minWidth:"auto", display:"none"}}>
                                        <div className="kt-todo__info">
                                            <div className="kt-todo__actions">
                                                <label
                                                    className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand">
                                                    <input type="checkbox" readOnly={true} checked={project.data.audit_started} />
                                                    <span/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="kt-todo__details" data-toggle="view">
                                            <div className="kt-todo__message">
                                                <span className="kt-todo__subject" style={project.data.audit_started ? {textDecoration:"line-through"} : {}}>Launch On-Page Audit</span><br/>
                                                <span className="kt-todo__text">Analyze your on-page SEO performance - <Link to={"/onpage-audit/" + project.data.id}>{!project.data.audit_started ? "Start " : ""}OnPage SEO Audit</Link></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="kt-todo__item" style={{minWidth:"auto", display:"none"}}>
                                        <div className="kt-todo__info">
                                            <div className="kt-todo__actions">
                                                <label
                                                    className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand">
                                                    <input type="checkbox" readOnly={true} checked={false} />
                                                    <span/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="kt-todo__details" data-toggle="view">
                                            <div className="kt-todo__message">
                                                <span className="kt-todo__subject">Integrate Google Analytics</span><br/>
                                                <span className="kt-todo__text">Integrate your Google Analytics with our traffic analytics module</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="kt-todo__item" style={{minWidth:"auto", display:"none"}}>
                                        <div className="kt-todo__info">
                                            <div className="kt-todo__actions">
                                                <label
                                                    className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand">
                                                    <input type="checkbox" readOnly={true} checked={false} />
                                                    <span/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="kt-todo__details" data-toggle="view">
                                            <div className="kt-todo__message">
                                                <span className="kt-todo__subject">Launch Linkbuilding Campaign</span><br/>
                                                <span className="kt-todo__text">Start building quality backlinks to your website</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="kt-todo__item" style={{minWidth:"auto", display:"none"}}>
                                        <div className="kt-todo__info">
                                            <div className="kt-todo__actions">
                                                <label
                                                    className="kt-checkbox kt-checkbox--single kt-checkbox--tick kt-checkbox--brand">
                                                    <input type="checkbox" readOnly={true} checked={false} />
                                                    <span/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="kt-todo__details" data-toggle="view">
                                            <div className="kt-todo__message">
                                                <span className="kt-todo__subject">Launch On-Page SEO Campaign</span><br/>
                                                <span className="kt-todo__text">Optimize your website and add news posts to your site every month</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </>
        );
    }
}

const mapStateToProps = ({ projects: { currentProject, currentProjectId }, auth: {user} }) => ({
    currentProject, currentProjectId, user
});

export default injectIntl(connect(
    mapStateToProps,
    {...projects.actions, ...auth.actions}
)(ProjectDetails));
