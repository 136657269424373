import React, { Component } from "react";
import {Portlet, PortletBody, PortletFooter, PortletHeader} from "../../../partials/content/Portlet";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Button, ButtonToolbar, Form} from "react-bootstrap";
import {Formik} from "formik";
import {Spinner} from "reactstrap";
import {Link} from "react-router-dom";
import Select from "react-select";

import * as auth from "../../../store/ducks/auth.duck";
import * as projects from "../../../store/ducks/projects.duck";
import {editProject} from "../../../crud/projects.crud";

class ProjectEdit extends Component {
    constructor(props) {
        super(props);

        let blogCategoryId = 0;
        // const {currentProjectId} = this.props;
        this.state = {
            blogCategoryId: blogCategoryId
        };
    }
    componentDidMount() {
        this.props.requestUser();
        this.props.requestProjectCategories();

        if(this.props.match && this.props.match.params) {
            const {id} = this.props.match.params;
            const currentProjectId = id;
            this.props.requestProject(id);
        }
        else {
            const currentProjectId = this.props.currentProjectId;
            if(currentProjectId > 0) {
                this.props.requestProject(currentProjectId);
            }
        }
        
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const currentProjectId = this.props.currentProjectId;
        if(currentProjectId > 0 && currentProjectId !== prevProps.currentProjectId) {
            this.props.requestProject(currentProjectId);
        }
    }

    render() {
        const project = this.props.currentProject;
        const {user,currentProjectId,projectCategories} = this.props;
        const selectStyles = {
	        menu: styles => ({ ...styles, zIndex: 999 }),
	        control: (provided, state) => ({
	            ...provided,
	            minHeight: '47px',
	        }),
	    };

        if (! currentProjectId || ! project || ! project.data || ! project.data || ! projectCategories) {
            return <Spinner/>;
        }

        var xProject = project.data;
        const categoryOptions = [];
        let item = {value: 0, label: 'Select'};
        categoryOptions.push(item);
        let categoryLabel = "";
        if (projectCategories && projectCategories.data && projectCategories.data.length !== 0) {
            for (let index = 0; index < projectCategories.data.length; index++) {
                let item = {value: projectCategories.data[index].id, label: projectCategories.data[index].name};
                categoryOptions.push(item);

                if (xProject.category_id == projectCategories.data[index].id) {
                    categoryLabel = projectCategories.data[index].name;
                    if (this.state.blogCategoryId == 0) {
                        this.setState({
                            ...this.state,
                            blogCategoryId: projectCategories.data[index].id
                        });
                    }
                }
            }
        }
        const setBlogCategoryId = (categoryId) => {
            if (categoryId.value > 0) {
                var el_niche_error = document.querySelector('#create_project_niche_group .invalid-feedback');
                if (el_niche_error != null) el_niche_error.style.display = 'none';
                var el_niche_select = document.getElementById('create_project_niche_select');
                if (el_niche_select != null) {
                    var el_niche_select_error = el_niche_select.getElementsByTagName('div')[0];
                    if (el_niche_select_error != null) {
                        el_niche_select_error.style.borderColor = '#cccccc';
                    }
                }
            }
            this.setState({
                ...this.state,
                blogCategoryId: categoryId.value
            });
        };


        return (
            <div class="row">
                <div class="col-xl-6 col-lg-8 col-12 mx-auto">
                    <div id="edit-project-form">
                        <Formik
                            initialValues={{
                                url: xProject != null && xProject.url ? xProject.url : '',
                                niche: xProject != null && xProject.category_id ? xProject.category_id : 0,
                                keywords: xProject != null && xProject.related_keywords ? xProject.related_keywords : '',
                                related_keywords: xProject != null && xProject.keywords ? xProject.keywords : '',
                            }}
                            validate={values => {
                                const errors = {};

                                if (! values.url) {
                                    errors.url = "Please provide valid url.";
                                }

                                if (this.state.blogCategoryId == 0) {
                                    errors.niche = "Please select a niche.";
                                    var el_niche_error = document.querySelector('#create_project_niche_group .invalid-feedback');
                                    if (el_niche_error != null) el_niche_error.style.display = 'block';
                                    var el_niche_select = document.getElementById('create_project_niche_select');
                                    if (el_niche_select != null) {
                                        var el_niche_select_error = el_niche_select.getElementsByTagName('div')[0];
                                        if (el_niche_select_error != null) {
                                            el_niche_select_error.style.borderColor = '#fd397a';
                                        }
                                    }
                                }

                                return errors;
                            }}
                            onSubmit={(values, { setStatus, setSubmitting }) => {
                                let el_success = document.getElementById('edit_project_success');
                                if (el_success != null) el_success.style.display = 'none';
                                editProject(
                                    currentProjectId,
                                    values.url,
                                    values.keywords,
                                    this.state.blogCategoryId,
                                    values.related_keywords
                                )
                                    .then((res) => {
                                        setSubmitting(false);
                                        setStatus(
                                            "Saved."
                                        );

                                        let el_success = document.getElementById('edit_project_success');
                                        if (el_success != null) el_success.style.display = 'block';
                                        setTimeout(function() {
                                            let el_success = document.getElementById('edit_project_success');
                                            if (el_success != null) el_success.style.display = 'none';
                                        },2000);
                                        // values.url = "";
                                        // values.keywords = "";
                                        // values.niche = "";
                                        // values.related_keywords = "";
                                        // this.props.reloadProjects();
                                        // this.props.nextStep();
                                    })
                                    .catch(e => {
                                        setSubmitting(false);
                                        if(e && e.response && e.response.data) {
                                            setStatus(
                                                e.response.data.message
                                            );
                                        }
                                    });
                            }}
                        >
                            {({
                                values,
                                status,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting
                            }) => (
                                <Portlet>
                                    <PortletHeader title="Edit Project" />
                                    <PortletBody>
                                        <Form noValidate onSubmit={handleSubmit}>
                                            {status && isSubmitting && (
                                                <div role="alert" className={'alert ' + (status === 'Saved.' ? 'alert-success' : 'alert-danger')}>
                                                    <div className="alert-text">{status}</div>
                                                </div>
                                            )}
                                            <div className="row">
                                                <Form.Group className="col-lg-6" controlId="validationFormik01">
                                                    <Form.Label>Website URL</Form.Label>
                                                    <Form.Control
                                                        type="url"
                                                        name="url"
                                                        placeholder=""
                                                        value={values.url}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.url}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.url}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className="col-lg-6" id="create_project_niche_group" controlId="validationFormik02">
                                                    <Form.Label>Niche</Form.Label>
                                                    <Select
                                                        id="create_project_niche_select"
                                                        name="niche"
                                                        className="mb-0"
                                                        defaultValue={{ label: categoryLabel, value: this.state.blogCategoryId }}
                                                        options={categoryOptions}
                                                        onChange={setBlogCategoryId}
                                                        styles={selectStyles}
                                                        isInvalid={!!errors.niche}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.niche}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className="col-lg-6" controlId="validationFormik03">
                                                    <Form.Label>Main Keyword</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="keywords"
                                                        placeholder=""
                                                        value={values.keywords}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.keywords}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.keywords}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className="col-lg-6" controlId="validationFormik04">
                                                    <Form.Label>Related Keywords</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows="3"
                                                        name="related_keywords"
                                                        placeholder="Enter one keyword per line"
                                                        value={values.related_keywords}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.related_keywords}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.related_keywords}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </Form>
                                        <div class="alert alert-success" id="edit_project_success" style={{marginBottom:'0px',display:'none'}}>Successfully saved project information.</div>
                                    </PortletBody>
                                    <PortletFooter>
                                        <ButtonToolbar className="justify-content-end">
                                            {!isSubmitting && <Link className="btn btn-secondary kt-mr-10" to="/">
                                                Cancel
                                            </Link>}
                                            <Button variant="primary" onClick={handleSubmit} disabled={isSubmitting}>
                                                {isSubmitting ? (<Spinner />) : 'Edit Project'}
                                            </Button>
                                        </ButtonToolbar>
                                    </PortletFooter>
                                </Portlet>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        );

    }

}
const mapStateToProps = ({ projects: { projectCategories, currentProject, currentProjectId }, auth: {user} }) => ({
    projectCategories, currentProject, currentProjectId, user
});

export default injectIntl(connect(
    mapStateToProps,
    {...projects.actions, ...auth.actions}
)(ProjectEdit));
