import React from "react";
import {Modal, Button, Form} from "react-bootstrap";
import { useFormik } from "formik";
import { addProject } from "../../../crud/projects.crud";
import * as projects from "../../../store/ducks/projects.duck";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";

function CreateProjectModal(props) {
        const formik = useFormik({
            initialValues: {
                url: "",
                //keywords: "",
            },
            validate: values => {
                const errors = {};

                /*const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // ip (v4) address
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ //port
                    '(\\?[;&amp;a-z\\d%_.~+=-]*)?'+ // query string
                    '(\\#[-a-z\\d_]*)?$','i');*/

                if (!values.url/* || !pattern.test(values.url)*/) {
                    errors.url = "Please provide valid url.";
                }

                /*if (!values.keywords) {
                    errors.keywords = "Please provide valid keywords.";
                }*/

                return errors;
            },
            onSubmit: (values, { setStatus, setSubmitting }) => {
                addProject(
                    values.url,
                    ""//values.keywords
                )
                    .then((res) => {
                        setSubmitting(false);
                        setStatus(
                            "Saved."
                        );
                        values.url = "";
                        //values.keywords = "";
                        props.reloadProjects();
                        if(props.action === "add") {
                            props.history.push({
                                pathname: "/projects"
                            });
                        }
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setStatus(
                            e.response.data.message
                        );
                    });
            }
        });

        return (
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Create Project
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate onSubmit={formik.handleSubmit}>
                        {formik.status && !formik.isSubmitting && (
                            <div role="alert" className={'alert ' + (formik.status === 'Saved.' ? 'alert-success' : 'alert-danger')}>
                                <div className="alert-text">{formik.status}</div>
                            </div>
                        )}

                        <Form.Group controlId="validationFormik01">
                            <Form.Label>Website URL</Form.Label>
                            <Form.Control
                                type="url"
                                name="url"
                                placeholder=""
                                value={formik.values.url}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                isInvalid={!!formik.errors.url}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.url}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/*<Form.Group controlId="validationFormik02">
                            <Form.Label>Main Keywords</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="keywords"
                                placeholder="One keyword per line"
                                value={formik.values.keywords}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                isInvalid={!!formik.errors.keywords}
                            />
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.keywords}
                            </Form.Control.Feedback>
                        </Form.Group>*/}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onHide}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={formik.handleSubmit} disabled={formik.isSubmitting}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        );
}

export default injectIntl(connect(
    null,
    projects.actions
)(CreateProjectModal));
