import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Checkbox} from "@material-ui/core";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Button} from "react-bootstrap";
import * as cart from "../../store/ducks/cart.duck";
import { Link } from 'react-router-dom';
// import ReactGA from "react-ga4";

class AddOnModal extends React.Component {
    constructor(props) {
        super(props);

        const {addOnItems, item} = this.props;
        let sending = false;
        let checkout_total = 0.00;
        let firstLoad = true;
        let closingModal = false;
        this.state = {
            addOnItems: addOnItems,
            sending: sending,
            checkout_total:checkout_total,
            item: item,
            firstLoad: firstLoad,
            closingModal: closingModal
        };
    }

    componentDidMount() {
        this.props.initCart();
    }

    render() {
        const {open, onClose, addOnItems, item,
            cartItems, cartSubtotal, cartDiscount, taxTotal, cartTotal, cartQuantity, userDiscountPercent, showCart} = this.props;

        let item_to_add = item;
        let checked_count = 0;
        var article_price = 0; //50
        let cartItemCount = 1;
        let temporary_price = 0;
        let temporary_quantity = 0;
        let hit_temp_price = false;

        // if (this.state.firstLoad == true) {
        //     this.state.closingModal = false;
        // }

        if (this.state.closingModal == false) {

            if (item != null && this.state.firstLoad && cartSubtotal != null) {
                if (item.orderData != null) {
                    if (item.orderData.type == "marketplace_guest_post" && item.orderData.options.marketplace_article == 1 && item.marketplace_article_price_included != 1) {
                        item.price = parseFloat(item.price) + article_price;
                        item.marketplace_article_price_included = 1;
                        // item.coming_from_modal = 1;
                    } else {
                        temporary_quantity = item.orderData.quantity;
                        temporary_price = parseFloat(item.price) * temporary_quantity;
                        cartItemCount = temporary_quantity;
                        hit_temp_price = true;



                        this.state.checkout_total = temporary_price + cartSubtotal;

                        // this.state.checkout_total =
                    }
                }
                if (hit_temp_price == false) {
                    this.state.checkout_total = parseFloat(item.price) + cartSubtotal;
                }

                hit_temp_price = false;
            }

            if (cartItems != null) {
                for (let i = 0; i < cartItems.length; i++) {
                    // if (cartItems[i].orderData.type == "marketplace_guest_post") {
                        cartItemCount += (cartItems[i].orderData.quantity > 0 ? cartItems[i].orderData.quantity : 1);
                    // }
                }
            }
        }

        if (this.state.firstLoad == true) {
            this.state.closingModal = false;
        }

        const handleViewCard = () => {
            handleClose();
            this.props.showCartOnClick();
        }

        const handleClose = () => {
            this.state.closingModal = true;

            if (item_to_add) {
                this.props.addToCart(item_to_add);
            }

            // ReactGA.event({
            //     category: 'Cart',
            //     action: 'AddToCart'
            // });
            this.state.firstLoad = true;
            // this.state.closingModal = false;
            onClose();
        };

        const moneyFormatter = (num) => {
            if (typeof num !== 'undefined') {
                try {
                    var calc = num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('.00','');
                    // var calc = num.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    return '$'+calc;
                } catch (e) {
                    console.log('moneyFormatter',e);
                    return '-';
                }
            }
            return '$'+num;
        }

        const handleAddOnCheckboxChange = (event, item_to_add, addOnItem) => {
            if (event.target.checked) {
                checked_count++;
                let new_total = this.state.checkout_total + (parseFloat(addOnItem.add_on_price) * item_to_add.orderData.quantity);
                // console.log('new_total addition', new_total);
                item_to_add.orderData.add_ons.push(addOnItem);

                this.setState({
                    ...this.state,
                    checkout_total: new_total,
                    item: item,
                    firstLoad: false
                });

            } else {
                checked_count--;
                let new_total = this.state.checkout_total - (parseFloat(addOnItem.add_on_price) * item_to_add.orderData.quantity);
                // console.log('new_total subtraction', new_total);
                let remove_add_on = item_to_add.orderData.add_ons.filter((item) => item.id !== addOnItem.id);
                item_to_add.orderData.add_ons = remove_add_on;

                this.setState({
                    ...this.state,
                    checkout_total: new_total,
                    item: item,
                    firstLoad: false
                });
            }
            // console.log('checkout_total', this.state.checkout_total);
        }

        const addOnRows = [];
        let last_section_name = '';
        if (addOnItems != null) {
            for(let i = 0; i < addOnItems.length; i++) {
                let addOnItem = addOnItems[i];
                if (addOnItem.section_name == last_section_name) {

                } else {
                    last_section_name = addOnItem.section_name;
                    addOnRows.push(
                        <h6>{last_section_name}</h6>
                    );
                }
                addOnRows.push(
                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(event)=>{handleAddOnCheckboxChange(event, item_to_add, addOnItem);}}
                                    value={addOnItem.id}
                                    color="primary"
                                />
                            }
                            label={addOnItem.add_on_name+" (+$" + (addOnItem.add_on_price ? addOnItem.add_on_price.toFixed(2) : 0) + ")"}
                        />
                    </div>
                );
            }
        }

        return (<Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"md"} aria-labelledby="form-dialog-title">
            <DialogTitle style={{background:"#f3f3f7"}}>
                <div className="pt-2 -b-2 row">
                    <div className="col-md-6 col-sm-12">
                        <i className="fa fa-check text-success pr-2"></i> You just added <div className="pl-4" style={{display:"inline", fontSize: "13px", fontWeight:"400"}}><div style={{color:"#5867dd", textDecoration:"none", backgroundColor:"transparent",cursor:"pointer",display:"inline-block"}} onClick={handleViewCard}>View cart</div></div>
                        <h6 className="mb-2 mt-3" style={{fontWeight:"400",paddingLeft:"34px",fontSize:"18px"}}>{item ? item.name : ''}</h6>
                        <h6 className=" mb-0" style={{fontWeight:"400",paddingLeft:"34px",fontSize:"18px"}}>{item ? item.description : ''}</h6>
                    </div>
                    {this.state.closingModal == false && (
                    <div className="col-md-3 col-sm-6 add_on_modal_header_middle">
                        <div className="add_on_modal_header_middle_inner">
                            <div style={{fontSize:"14px",fontWeight:"400",paddingLeft:"10px"}}>
                                Cart Items: {cartItemCount}
                            </div>
                            <div style={{fontSize:"14px",fontWeight:"400",paddingLeft:"10px"}}>
                                Cart Subtotal: {this.state.checkout_total ? moneyFormatter(this.state.checkout_total) : '$0.00'}
                            </div>
                        </div>
                    </div>)}
                    <div className="col-md-3 col-sm-6">
                        <Button variant="primary" onClick={handleClose} style={{display:"block", width:"210px",marginTop:"3px",marginBottom:"10px"}}>
                            Continue Shopping
                        </Button>
                        <Link variant="success" className="btn btn-success" to="/checkout" onClick={handleClose} style={{display:"block", width:"210px"}}>
                            Continue to Checkout
                        </Link>
                    </div>
                </div>
            </DialogTitle>
            {(addOnRows.length > 0 ?
                <DialogContent dividers>
                    <h5 className="mt-3 mb-4 pb-2 text-center">Boost the quality of your links with powerful add-ons:</h5>
                    <div className="mb-3" style={{maxWidth: "420px", margin: "0px auto"}}>
                        {addOnRows}
                    </div>
                </DialogContent>
            : '')}
            {/* <DialogActions>
                <Button variant="primary" onClick={handleClose}>
                    Continue Shopping
                </Button>
                 <Link variant="success" className="btn btn-success" to="/checkout" onClick={handleClose}>
                    Continue to Checkout
                </Link>
            </DialogActions> */}
        </Dialog>);
    }
}

AddOnModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ cart: { cartItems, cartSubtotal, cartDiscount, taxTotal, cartTotal, cartQuantity, userDiscountPercent, showCart } }) => ({
    cartItems, cartSubtotal, cartDiscount, taxTotal, cartTotal, cartQuantity, userDiscountPercent, showCart
});

export default injectIntl(connect(
    mapStateToProps,
    cart.actions
)(AddOnModal));
