import React, {Component} from "react";
import Chart from "react-apexcharts";
import { ButtonToolbar, Dropdown, ButtonGroup} from "react-bootstrap";
import DateRangePickers from "../DateRangePickers";
import {Button, Popover, Checkbox, FormControlLabel } from '@material-ui/core';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import NotesModal from "../NotesModal";

export default class VisitorsChartsDummy extends Component {
    constructor(props) {
        super(props);
        const self = this;
        this.state = {
            active: false,
            load: true,
            dateRange: undefined,
            dates: this.props.date,
            notesPopover: null,
            popoverContent: '--empty--',
            notesModalShow: false,
            notesModalAction: 'add',
            noteId: null,
            noteDate: null,
            notesVisible: {
                notesCustomShow: true,
                notesGoogleUpdateShow: true,
            },
            pointsNotes: [],
            pointsGoogleUpdates: [],
            xaxisGoogleUpdates: [],
            options: {
                chart: {
                    id: 'visitors',
                    zoom: {
                        enabled: false,
                    },
                    events: {
                        dataPointSelection:  (event, chartContext, config) => { self.addNote(config) },
                    }
                },

                tooltip: {
                    enabled: true,
                    intersect: true,
                    shared: false,
                    custom: function({series, seriesIndex, dataPointIndex, w}) { return self.addNoteToTooltip( dataPointIndex )},
                    followCursor: false,
                    fixed: {
                        enabled: false,
                        position: 'topLeft'
                    }
                },

                annotations: {
                    position: 'back' ,
                    points:[]
                },

                colors: ['#008FFB'],
                stroke: {
                    curve: 'smooth',
                },
                markers: {
                    size: 5,
                },
                xaxis: {
                    categories: [],
                    type: 'datetime'
                }
            },
            series: [
                {
                    name: "",
                    data: []
                }
            ]
        };
    }

    getAnalyticsData = (date = false) => {
        let params = {
            type: 'traffic-sources',
            date: (date) ? date : this.props.date,
            daily: 1,
            item: 'Searches',
            limit: 'all'
        };

        //static dummy res
        const res = {
            data:{
                "traffic-sources": {}
            }
        };

        if(!date || date === "custom") {
            date = "7";
        }
        if(date === "ALL") {
            date = "365";
        }

        const daysRange = date && parseInt(date) > 0 && parseInt(date) <= 365 ? parseInt(date) : -1;
        if(daysRange === -1) {
            const dateRange = date.split(",");
            const date1 = dateRange[0];
            const date2 = dateRange[1];
            if(date1 && date2) {
                const dateObj = new Date(date2);
                const dateItem = dateObj.getFullYear() + '-' + ('0' + (dateObj.getMonth() + 1)).slice(-2) + '-' + ('0' + dateObj.getDate()).slice(-2);
                const min = 1500;
                const max = 1700;
                res.data["traffic-sources"][dateItem] = [{"value": Math.floor(Math.random() * (max - min + 1)) + min}];

                let t2 = dateObj.getTime();
                let t1 = (new Date(date1)).getTime();
                const dateDiff = parseInt((t2 - t1)/(24 * 3600 * 1000));
                for (let i = 1; i < dateDiff + 1; i++) {
                    const dateObj2 = new Date(date2);
                    dateObj2.setDate(dateObj2.getDate() - i);
                    const dateItem = dateObj2.getFullYear() + '-' + ('0' + (dateObj2.getMonth() + 1)).slice(-2) + '-' + ('0' + dateObj2.getDate()).slice(-2);
                    const min = 1500;
                    const max = 1700;
                    res.data["traffic-sources"][dateItem] = [{"value": Math.floor(Math.random() * (max - min + 1)) + min}];
                }
            }
        }
        else {
            for (let i = 0; i < daysRange; i++) {
                const dateObj = new Date();
                dateObj.setDate(dateObj.getDate() - i);
                const dateItem = dateObj.getFullYear() + '-' + ('0' + (dateObj.getMonth() + 1)).slice(-2) + '-' + ('0' + dateObj.getDate()).slice(-2);
                const min = 1500;
                const max = 1700;
                res.data["traffic-sources"][dateItem] = [{"value": Math.floor(Math.random() * (max - min + 1)) + min}];
            }
        }

        let result = res.data[params.type];

        const xArray = [];
        const yArray = [];

        for (var index in result) {
            var attr = result[index];
            xArray.unshift(index);
            yArray.unshift(attr[0].value);
        }

        const newYData = [];
        newYData.push({data: yArray, name: 'visitors'});

        this.setState(prevState => ({
            ...prevState,
            series: newYData,
            options: {
                ...prevState.options,
                xaxis: {
                    ...prevState.options.xaxis,
                    categories: xArray
                }
            },
        }));
    };

    prepareAnalyticsNotes = (notes) => {
        if (!notes) {
            return false;
        }

        const x_markers = this.state.options.xaxis.categories;
        const y_markers = this.state.series[0].data;

        let points = [];

        x_markers.forEach((item, key) => {
            notes.data.filter(obj => {
                if (obj.date === item) {
                    const point = {
                        x: new Date(obj.date).getTime(),
                        y: y_markers[key],
                        marker: {
                            size: 8,
                            fillColor: '#fff',
                            strokeColor: 'red',
                            radius: 2,
                            cssClass: 'apexcharts-custom-class'
                        },
                        label: {
                            borderColor: '#FF4560',
                            offsetY: 0,
                            style: {
                                color: '#fff',
                                background: '#FF4560',
                            },
                            text: 'Task',
                        },
                        pointData: obj,
                    };
                    points.push(point);
                }
            })
        });

        this.setState(prevState => ({
                ...prevState,
                pointsNotes: points,
                options: {
                    ...prevState.options,
                    annotations: {
                        ...prevState.options.annotations,
                        points: (this.state.notesVisible.notesCustomShow) ? points : []
                    }
                }
            }), () => {
                this.prepareAnalyticsGoogleUpdates(this.props.googleupdates)
            }
        );
    };

    prepareAnalyticsGoogleUpdates = (googleupdates) => {
        let points = [];
        let xaxis = [];
        let notesPoints = this.state.pointsNotes;

        if (googleupdates) {
            const x_markers = this.state.options.xaxis.categories;

            x_markers.forEach((item, key) => {
                googleupdates.data.filter(obj => {
                    if (obj.date === item) {
                        const point = {
                            x: new Date(obj.date).getTime(),
                            y: 0,
                            marker: {
                                size: 1,
                                fillColor: '#fff',
                                strokeColor: '775DD0',
                                radius: 1,
                                cssClass: 'apexcharts-custom-class'
                            },
                            pointData: obj,
                        };

                        const gu = {
                            x: new Date(obj.date).getTime(),
                            strokeDashArray: 0,
                            borderColor: '#775DD0',
                            label: {
                                borderColor: '#775DD0',
                                style: {
                                    color: '#fff',
                                    background: '#775DD0',
                                },
                                text: 'Google Update',
                            },
                            pointData: obj,
                        };

                        xaxis.push(gu);
                        points.push(point);
                    }
                })

            });
        }

        let visiblePoints = [];

        if(this.state.notesVisible.notesGoogleUpdateShow && this.state.notesVisible.notesCustomShow)
            visiblePoints = [...points, ...notesPoints];
        if(this.state.notesVisible.notesGoogleUpdateShow && !this.state.notesVisible.notesCustomShow)
            visiblePoints = points;
        if(!this.state.notesVisible.notesGoogleUpdateShow && this.state.notesVisible.notesCustomShow)
            visiblePoints = notesPoints;
        if(!this.state.notesVisible.notesGoogleUpdateShow && !this.state.notesVisible.notesCustomShow)
            visiblePoints = [];

        this.setState(prevState => ({
            ...prevState,
            pointsGoogleUpdates: points,
            xaxisGoogleUpdates: xaxis,
            options: {
                ...prevState.options,
                annotations: {
                    ...prevState.options.annotations,
                    points:  visiblePoints,
                    xaxis: (this.state.notesVisible.notesGoogleUpdateShow) ? xaxis : []
                }
            }
        }));
    };

    componentDidMount() {
        this.getAnalyticsData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.notes !== prevProps.notes) {
            this.prepareAnalyticsNotes(this.props.notes);
        }
        if (this.props.googleupdates !== prevProps.googleupdates) {
            this.prepareAnalyticsGoogleUpdates(this.props.googleupdates);
        }

        if (this.state.options.xaxis.categories !== prevState.options.xaxis.categories) {
            if(this.props.notes)
                this.prepareAnalyticsNotes(this.props.notes);
        }

        if (this.state.notesVisible.notesCustomShow !== prevState.notesVisible.notesCustomShow) {
            if(this.state.notesVisible.notesCustomShow){
                this.setState(prevState => ({
                    ...prevState,
                    options: {
                        ...prevState.options,
                        annotations: {
                            ...prevState.options.annotations,
                            points: (this.state.notesVisible.notesGoogleUpdateShow) ? [...this.state.pointsNotes, ...this.state.pointsGoogleUpdates] : this.state.pointsNotes
                        }
                    },
                }));
            } else {
                this.setState(prevState => ({
                    ...prevState,
                    options: {
                        ...prevState.options,
                        annotations: {
                            ...prevState.options.annotations,
                            points: (this.state.notesVisible.notesGoogleUpdateShow) ? this.state.pointsGoogleUpdates : []
                        }
                    },
                }));
            }
        }

        if (this.state.notesVisible.notesGoogleUpdateShow !== prevState.notesVisible.notesGoogleUpdateShow) {
            if(this.state.notesVisible.notesGoogleUpdateShow){
                this.setState(prevState => ({
                    ...prevState,
                    options: {
                        ...prevState.options,
                        annotations: {
                            ...prevState.options.annotations,
                            xaxis: this.state.xaxisGoogleUpdates,
                            points: (this.state.notesVisible.notesCustomShow) ? [...this.state.pointsNotes, ...this.state.pointsGoogleUpdates] : this.state.pointsGoogleUpdates
                        }
                    },
                }));
            } else {
                this.setState(prevState => ({
                    ...prevState,
                    options: {
                        ...prevState.options,
                        annotations: {
                            ...prevState.options.annotations,
                            xaxis: [],
                            points: (this.state.notesVisible.notesCustomShow) ? this.state.pointsNotes : []
                        }
                    },
                }));
            }
        }
    }

    handleNotesPopoverShow = (event, config) => {
        const pop = this.addNoteToTooltip(config.dataPointIndex);
        this.setState({notesPopover: event.currentTarget, popoverContent: pop});
    };

    addNote = (config) =>  {
        const curDate = this.state.options.xaxis.categories[config.dataPointIndex];
        const points = this.state.options.annotations.points;
        const date = new Date(curDate);
        const dateTimestamp = date.getTime();
        const result = points.filter(obj => {
            return obj.x === dateTimestamp
        });

        let point = result.pop();

        if (!point) {
            this.notesModalOpen('add', false, curDate);
            return;
        } else {
            if (typeof point.pointData.note !== "undefined")
                this.notesModalOpen('manage', point.pointData.id, curDate);
            else
                this.notesModalOpen('add', false, curDate);
        }
    };

    notesModalOpen = (action, noteId=false, date=false) => {
        this.setState({
            notesPopover: null,
            notesModalShow: true,
            notesModalAction: action,
            noteId: noteId,
            noteDate: date || new Date()
        });
    };

    addLineBreaks = (string) => {
        let content = '';
        string.split('\n').map((text, index) => ( content += text + '<br/>' ));
        return content;
    };

    addNoteToTooltip = (dataPointIndex) => {
        const yValue = this.state.series[0].data[dataPointIndex];
        const curDate = this.state.options.xaxis.categories[dataPointIndex];
        const points = this.state.options.annotations.points;
        const date = new Date(curDate);
        const dateTimestamp = date.getTime();
        const result = points.filter(obj => {
            return obj.x === dateTimestamp
        });

        let point;
        var pointsHtml = '';

        while (point = result.pop()) {
            if(typeof point.pointData.note !== "undefined"){
                const Category = point.pointData.category;
                const Note = point.pointData.note;
                const Cost = point.pointData.cost;

                let formatedNote = this.addLineBreaks(Note);

                pointsHtml +=
                    '<div class="apexcharts-tooltip-z-group custome-note"><b>Task</b>' +
                    '<div class="apexcharts-tooltip-text-z-label"><u>'+Category+'</u></div>' +
                    ((Cost) ? '<div class="apexcharts-tooltip-text">Cost: '+Cost+'</div>' : '') +
                    '<div class="apexcharts-tooltip-text-note-value">'+formatedNote+'</div>' +
                    '</div>';
            }

            if(typeof point.pointData.name !== "undefined"){
                const Description = point.pointData.description;
                const Name = point.pointData.name;
                const Volatility = point.pointData.volatility;

                let formatedDescription = this.addLineBreaks(Description);

                pointsHtml +=
                    '<div class="apexcharts-tooltip-z-group google-update-note"><b>Google Update</b>' +
                    '<div class="apexcharts-tooltip-text-z-label"><u>'+Name+' ('+Volatility+')</u></div>' +
                    '<div class="apexcharts-tooltip-text-note-value">'+formatedDescription+'</div>' +
                    '</div>';
            }
        }

        let html =
            '<div class="apexcharts-tooltip-title">' +
            curDate +
            '</div>' +
            '<div class="apexcharts-tooltip-series-group apexcharts-active" style="display: block">' +
            '<div class="apexcharts-tooltip-text">' +
            '<div class="apexcharts-tooltip-y-group">' +
            '<span class="apexcharts-tooltip-text-label">Visitors: </span>' +
            '<span class="apexcharts-tooltip-text-value">' +
            yValue +
            '</span>' +
            '</div>';

        if(pointsHtml){
            html += pointsHtml;
        }

        html += '</div>' +
            '</div>';

        return html;
    };

    render() {
        const {width, height} = this.props;
        const {dateRange, dates, notesModalShow, notesModalAction, noteId, noteDate, notesPopover} = this.state;
        const {notesCustomShow, notesGoogleUpdateShow} = this.state.notesVisible;

        const handleNotesPopoverClose = () => {
            this.setState({notesPopover: null});
        };

        const notesModalClose = () => {
            this.setState({notesModalShow: false});
        };

        const handleNotesViewAll = (isOn) => {
            const self = this;
            let itemsObj = this.state.notesVisible;
            Object.keys(itemsObj).forEach(function(key){
                self.setState(prevState => ({
                    ...prevState,
                    notesVisible: {
                        ...prevState.notesVisible,
                        [key]: Boolean(isOn)
                    }
                }));
            });
        };

        const GetDefaultDataHandler = (event, value) => {
            this.setState({dates: value});
            if (value == 'custom' && dateRange) {
                this.getAnalyticsData(dateRange);
            } else {
                this.getAnalyticsData(value);
            }
        };

        const handleDateRangeChange = (value) => {
            this.setState({dateRange: value});
        };

        const getAllPeriodToSite = () => {

            if(typeof this.props.site == 'undefined')
                return '365';

            var date = new Date(this.props.site.created_at);
            const formated_dateFrom = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
            date = new Date();
            const formated_dateTo = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);

            return formated_dateFrom + ',' + formated_dateTo;

        };

        const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
            <a
                href=""
                ref={ref}
                onClick={(e) => {
                    e.preventDefault();
                    onClick(e);
                }}
            >
                <AssignmentOutlinedIcon />
                &nbsp;
                {children}
                &nbsp;
                &#x25BE;
            </a>
        ));

        return (
            <div className="metric">
                <ButtonToolbar as={ButtonGroup} className="justify-content-between" size="sm">
                    <ToggleButtonGroup
                        size="medium"
                        value={dates}
                        exclusive
                        onChange={GetDefaultDataHandler}
                        aria-label="First group"
                    >
                        <ToggleButton value='7' aria-label="Week">1W</ToggleButton>
                        <ToggleButton value='30' aria-label="Month">1M</ToggleButton>
                        <ToggleButton value='90' aria-label="Quarter">3M</ToggleButton>
                        <ToggleButton value='180' aria-label="Half a year">6M</ToggleButton>
                        <ToggleButton value='360' aria-label="Year">1Y</ToggleButton>
                        <ToggleButton value={getAllPeriodToSite()} aria-label="All">All</ToggleButton>
                        <ToggleButton value='custom' className="pl-2 pr-2">Range</ToggleButton>

                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Toggle split variant="secondary" id="dropdown-date-range"/>
                            <Dropdown.Menu>
                                <DateRangePickers
                                    dateRange={dateRange}
                                    handleDateRangeChange={handleDateRangeChange}
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                    </ToggleButtonGroup>

                    <Dropdown alignRight >
                        <Dropdown.Toggle as={CustomToggle} variant="outline-primary" id="dropdown-notes-managment">
                            Filters
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <div className="p-3">
                                <h6>Task Management</h6>
                                <hr />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={notesCustomShow}
                                            onChange={(event) => {
                                                const isCheck = event.target.checked;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    notesVisible: {
                                                        ...prevState.notesVisible,
                                                        notesCustomShow: isCheck
                                                    }
                                                }));
                                            }}
                                            name="checkeNotesCustomShow"
                                            color="primary"
                                        />
                                    }
                                    label="Tasks"
                                /><br/>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={notesGoogleUpdateShow}
                                            onChange={(event) => {
                                                const isCheck = event.target.checked;
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    notesVisible: {
                                                        ...prevState.notesVisible,
                                                        notesGoogleUpdateShow: isCheck
                                                    }
                                                }));
                                            }}
                                            name="checkeNotesGoogleUpdateShow"
                                            color="primary"
                                        />
                                    }
                                    label="Google Updates"
                                /><br/><br/>
                                <ButtonToolbar as={ButtonGroup} className="justify-content-between" size="sm">
                                    <Button variant="outlined" onClick={() => {handleNotesViewAll(true)}}><VisibilityIcon/></Button>
                                    <Button variant="outlined" onClick={() => {handleNotesViewAll(false)}}><VisibilityOffIcon/></Button>
                                </ButtonToolbar>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>

                    <Popover
                        open={Boolean(notesPopover)}
                        anchorEl={notesPopover}
                        onClose={handleNotesPopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <div className="popover-tooltip apexcharts-theme-light" dangerouslySetInnerHTML={{__html: this.state.popoverContent}} />
                    </Popover>

                    {/*<NotesModalDummy
                        noteId={noteId}
                        noteDate={noteDate}
                        action={notesModalAction}
                        show={notesModalShow}
                        onHide={notesModalClose}
                    />*/}

                </ButtonToolbar>

                <div className="chart" id="visitorsChart">
                    <div className="mixed-chart">
                        <Chart
                            options={this.state.options}
                            series={this.state.series}
                            type="line"
                            width={width}
                            height={height}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
