import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import objectPath from "object-path";
import * as builder from "../../ducks/builder";
import KTToggle from "../../_assets/js/toggle";
import { toAbsoluteUrl } from "../../utils/utils";
import * as cart from "../../../app/store/ducks/cart.duck";
import MyCart from "../../../app/partials/layout/MyCart";

class HeaderMobile extends React.Component {
  toggleButtonRef = React.createRef();
  headerMobileCssClasses = "";
  layoutConfig = this.props.layoutConfig;
  componentDidMount() {
    const toggle = new KTToggle(this.toggleButtonRef.current, this.props.toggleOptions);
    toggle.toggleOn();
    this.props.initCart();
  }

  render() {
    const {
      asideDisplay,
      headerMobileCssClasses,
      headerMobileAttributes,
    } = this.props;
    const { cartItems, cartSubtotal, cartDiscount, taxTotal, cartTotal, showCart, cartQuantity, showCheckout } = this.props;
    return (
      <div
        id="kt_header_mobile"
        className={`kt-header-mobile ${headerMobileCssClasses}`}
        {...headerMobileAttributes}
      >
        <div className="kt-header-mobile__logo">
          <Link to="/">
            <img alt="logo" src={toAbsoluteUrl("/media/logos/logo-2-sm.png")} />
          </Link>
        </div>

        <div className="kt-header-mobile__toolbar">

          <MyCart
            iconType=""
            skin="mobile"
            icon="flaticon2-shopping-cart-1"
            bgImage={toAbsoluteUrl("/media/misc/bg-1.jpg")}
          />

          {cartQuantity != null && cartQuantity > 0 ?
            <Link className="btn btn-primary" style={{ fontSize: "17px", paddingTop: "8px", marginRight:"10px", paddingRight:"15px", paddingLeft:"15px", border: "0px"}} to="/checkout">Checkout</Link>
          : ''}

          {asideDisplay && (
            <button
              className="btn btn-outline-light"
              id="kt_aside_mobile_toggler"
            >
              Menu
            </button>
          )}

          {/*<button
            className="kt-header-mobile__toggler"
            id="kt_header_mobile_toggler"
          >
            <span />
          </button>*/}

          <button
            ref={this.toggleButtonRef}
            className="kt-header-mobile__topbar-toggler"
            id="kt_header_mobile_topbar_toggler"
            style={{display:"none"}}
          >
            <i className="flaticon-more" />
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  headerMobileCssClasses: builder.selectors.getClasses(store, {
    path: "header_mobile",
    toString: true
  }),
  headerMobileAttributes: builder.selectors.getAttributes(store, {
    path: "header_mobile"
  }),
  asideDisplay: objectPath.get(
    store.builder.layoutConfig,
    "aside.self.display"
  ),
  toggleOptions: {
    target: "body",
    targetState: "kt-header__topbar--mobile-on",
    togglerState: "kt-header-mobile__toolbar-topbar-toggler--active"
  },
  // cart: {
  //   cartQuantity: cartQuantity
  // }
});

// const mapStateToPropsTwo = ({ cart: { cartItems, cartSubtotal, cartDiscount, taxTotal, cartTotal, showCart, cartQuantity, showCheckout } }) => ({
//   cartItems, cartSubtotal, cartDiscount, taxTotal, cartTotal, showCart, cartQuantity, showCheckout
// });

export default injectIntl(connect(
  mapStateToProps,
  // mapStateToPropsTwo,
  cart.actions
)(HeaderMobile));
// export default connect(mapStateToProps)(HeaderMobile);
