import React from "react";
import {Route, Switch} from "react-router-dom";
import Onboarding from "./Onboarding";
import AlgoProof from "./AlgoProof";
import OnPage from "./OnPage";
import BloggerChecklist from "./BloggerChecklist";
import UltimateGuestPost from "./UltimateGuestPost";
import OffPageSeoRankingFactors from "./OffPageSeoRankingFactors";

export default function ChecklistsPage() {
    return (
        <Switch>
            <Route
                path="/checklists/onboarding"
                component={Onboarding}
            />
            <Route
                path="/checklists/algoproof"
                component={AlgoProof}
            />
            <Route
                path="/checklists/on-page"
                component={OnPage}
            />
            <Route
                path="/checklists/blogger"
                component={BloggerChecklist}
            />
             <Route
                path="/checklists/ultimate-guest-post"
                component={UltimateGuestPost}
            />
            <Route
                path="/checklists/off-page-seo-ranking-factors"
                component={OffPageSeoRankingFactors}
            />
        </Switch>
    );
}
