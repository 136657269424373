import React, {Component} from "react";
import {Portlet, PortletBody, PortletHeader} from "../../../../partials/content/Portlet";
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default class ContentCreationInfo extends Component {
    render() {
        return (<>
            <Portlet>
                <PortletHeader title="Frequently Asked Questions" />
                <PortletBody className="p-0">
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography style={{fontSize:16,fontWeight:"bold"}}>Do you have samples of your content work?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                <p>Yes, we do. <a href="https://global-uploads.webflow.com/60bc491ea0c7c2b05b6aac94/60c1505da8e0402556b78f5e_Content%20Portfolio.pdf" target="_blank" rel="noopener nofollow">Click here</a> to download our portfolio.</p>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography style={{fontSize:16,fontWeight:"bold"}}>What is the turnaround time?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                <p>Anywhere between 2 - 4 weeks, depending on the size of your order.</p>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography style={{fontSize:16,fontWeight:"bold"}}>Can I approve the topics and content?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                <p>Yes, you can approve the topics and content before we start writing and publishing them on your site.</p>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography style={{fontSize:16,fontWeight:"bold"}}>Are the article SEO-optimized?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                <p>Yes, we optimize each article so it ranks for relevant keywords. Before we write, we research your keywords and create topics that are engaging and informational for your audience.</p>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography style={{fontSize:16,fontWeight:"bold"}}>Do you offer revisions?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                <p>Yes, we offer unlimited revisions for each article.</p>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </PortletBody>
            </Portlet>
        </>);
    }
}
