import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as marketplace from "../../../store/ducks/marketplace.duck";
import {Form, Button} from "react-bootstrap";
import {Spinner} from "reactstrap";

class SiteBlackListModal extends React.Component {
    constructor(props) {
        super(props);
        const {guestPosts} = this.props;
        let blacklist = "";
        if (guestPosts && guestPosts.data && guestPosts.blacklist && guestPosts.blacklist.length !== 0) {
            for (const [index] of guestPosts.blacklist.entries()) {
                blacklist += guestPosts.blacklist[index].domain + "\r\n";
            }
        }
        this.state = {
            blacklist: blacklist,
        };
    }

    render() {
        const {open, onClose, filter, guestPosts} = this.props;

        const handleClose = () => {
            onClose();
        };
        const handleSubmit = () => {
            onClose();
        };

        const handleBlacklistChange = (e) => {
            const val = e.target.value;
            this.setState({
                ...this.state,
                blacklist: val
            });
        };

        const handleRemoveDuplicates = () => {
            filter['blacklist'] = this.state.blacklist ? this.state.blacklist : '[empty]';
            this.props.initMarketplace(1, filter);
        };

        return (<Dialog open={open} onClose={handleClose} fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle>Site Blacklist</DialogTitle>
            {guestPosts ? <DialogContent dividers>
                <Form.Control
                    as="textarea"
                    rows="9"
                    placeholder="Copy and paste a list of your site's backlinks to remove duplicates from our marketplace"
                    value={this.state.blacklist}
                    onChange={handleBlacklistChange}
                />
            </DialogContent> : <Spinner className="kt-margin-30" />}
            <DialogActions>
                <Button
                    variant="danger"
                    onClick={handleRemoveDuplicates}
                    disabled={!guestPosts || !filter}
                >
                    Remove Duplicates
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>);
    }
}

SiteBlackListModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ marketplace: { filter, guestPosts } }) => ({
    filter, guestPosts
});

export default injectIntl(connect(
    mapStateToProps,
    marketplace.actions,
)(SiteBlackListModal));
