import React from "react";
import * as pagedata from "../../../store/ducks/page.duck";
import "../../../../_metronic/_assets/sass/custom/projects.scss";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

class OffPageSeoRankingFactors extends React.Component {

    componentDidMount () {
        if (document.getElementById('checkli_embed') != null) document.getElementById('checkli_embed').remove();
        let script = document.createElement("script");
        script.src = "https://checkli.com/js/checkli-embed.js";
        // script.defer = true;
        script.id = "checkli_embed";
        document.body.appendChild(script);
        script.onload = function() {
            var already_run;
            var elements = document.getElementsByClassName("checkli-embed");
            if (!already_run) {
                for(let key=0; key < elements.length; key++) 
                {
                    var element = document.getElementById(elements[key].id);
                    new window.easyXDM.Socket({
                        remote: element.getAttribute('url'),
                        container: element,
                        props: { 
                            style: {
                                width:"100%"
                            }
                        },
                        onMessage: function(message, origin){
                            this.container.getElementsByTagName("iframe")[0].style.height = message + "px";
                        }
                    });
                }
                already_run = true;
            }
        }

        // const pageName = 'ai_tools_top';
        // this.props.requestPageData(pageName);
    }

    render() {
        const { user, showHi, showAvatar, showBadge, pageData } = this.props;

        let tmo_requirement = 500; // SEARCH ALL - CHANGE UNLOCK TIERS
        let tmo_requirement_currency = '$'+tmo_requirement.toLocaleString(undefined, {maximumFractionDigits:2});

        let tmo_amount = 0;
        if (user.tmo_amount && user.tmo_amount > 0) tmo_amount = user.tmo_amount;

        let page_name = "Off-Page SEO Ranking Factors";

        return (
            <>
                {/* <Portlet className="kt-mt-15" style={{background:"#f2f3f8"}}>
                    <div className="row" style={{marginLeft:0,marginRight:0}}>
                        <div className="col-lg-12 col-12" style={{paddingLeft:0,paddingRight:0}}>
                            <Portlet className="h-100 kt-mb-0" style={{background:"#e7eff3"}}>
                                <PortletBody dangerouslySetInnerHTML={{ __html: pageData ? pageData.top_text : "" }} />
                            </Portlet>
                        </div>
                    </div>
                </Portlet> */}
                <div className="row kt-mt-30">
                    <div className="col-12">
                        <h1 className="custom-header kt-mb-25">{page_name}</h1>
                        {tmo_amount >= tmo_requirement ? <div id="checkli-embed-64e83ab157f2c" class="checkli-embed" url= "https://www.checkli.com/checklists/64e839a87f6bc/embed"></div> : <div class="alert alert-danger">{page_name} requires at least {tmo_requirement_currency} total month's orders.</div>}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ auth: { user }, pagedata: {pageData} }) => ({
  user, pageData
});

export default injectIntl(connect(
    mapStateToProps,
    {...pagedata.actions}
)(OffPageSeoRankingFactors));
