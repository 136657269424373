import React, { Component } from "react";
import Deals from "./Deals";
import GuestPostInfo from "./faq/GuestPostInfo";

export default class GuestPost extends Component {
    render() {
        const faq = <GuestPostInfo />;

        return (<>
            <Deals type="guest_post_service_package" key="gps" description="Guest Post Service" title="Choose Your Package" faq={faq} />
        </>);
    }
}
