import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "../../crud/auth.crud";
import { getBalanceHistory } from "../../crud/user.crud";
import * as routerHelpers from "../../router/RouterHelpers";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  RegisterBlogger: "[RegisterBlogger] Action",
  RegisterWriter: "[RegisterWriter] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  BalanceHistoryRequested: "[Request Balance History] Action",
  BalanceHistoryLoaded: "[Load Balance History] Auth API",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  roles: undefined,
  balanceHistory: undefined,
};

export const reducer = persistReducer(
    { storage, key: "demo3-auth", whitelist: ["user", "authToken", "roles"] },
    (state = initialAuthState, action) => {
      switch (action.type) {
        case actionTypes.Login: {
          const { authToken, roles, redirect_to } = action.payload;

          return { authToken, roles, redirect_to, user: undefined };
        }

        case actionTypes.Register: {
          const { authToken } = action.payload;

          return { authToken, user: undefined, roles: undefined };
        }

        case actionTypes.Logout: {
          routerHelpers.forgotLastLocation();
          return initialAuthState;
        }

        case actionTypes.UserLoaded: {
          const { user } = action.payload;

          return { ...state, user };
        }


        case actionTypes.BalanceHistoryRequested: {
            return { ...state, balanceHistory: undefined };
        }

        case actionTypes.BalanceHistoryLoaded: {
            const { balanceHistory } = action.payload;

            return { ...state, balanceHistory };
        }



        default:
          return state;
      }
    }
);

export const actions = {
  login: (authToken, roles) => ({ type: actionTypes.Login, payload: { authToken, roles } }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  registerBlogger: authToken => ({
    type: actionTypes.RegisterBlogger,
    payload: { authToken }
  }),
  registerWriter: authToken => ({
    type: actionTypes.RegisterWriter,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  requestUserBlogger: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  requestUserWriter: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),

  requestBalanceHistory: (page) => ({ type: actionTypes.BalanceHistoryRequested, payload: { page } }),
  fulfillBalanceHistory: (balanceHistory) => ({ type: actionTypes.BalanceHistoryLoaded, payload: { balanceHistory } }),
};

export function* saga() {

  yield takeLatest(actionTypes.BalanceHistoryRequested, function* balanceHistoryRequested(action) {
        const { page } = action.payload;
        const { data: balanceHistory } = yield getBalanceHistory(page);
        yield put(actions.fulfillBalanceHistory(balanceHistory));
  });

  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.RegisterBlogger, function* registerBloggerSaga() {
    yield put(actions.requestUserBlogger());
  });

  yield takeLatest(actionTypes.RegisterWriter, function* registerWriterSaga() {
    yield put(actions.requestUserWriter());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    // const { data: user } = yield getUserByToken();

    // yield put(actions.fulfillUser(user));
    try {
        const { data: user } = yield getUserByToken();

    yield put(actions.fulfillUser(user));
        yield put(actions.fulfillUser(user));
    } catch (e) {
        yield put(actions.fulfillUser(undefined));
    }
    
  });
}
