import React, { Component } from "react";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as packages from "../../../store/ducks/packages.duck";
import {Portlet, PortletBody, PortletHeader} from "../../../partials/content/Portlet";
import {Spinner} from "reactstrap";
import {cancelSubscription} from "../../../crud/packages.crud";

class UserPackageDetails extends Component {
    componentDidMount() {
        const { id } = this.props.match.params;
        this.props.requestUserPackage(id);
    }

    render() {
        const {currentUserPackage} = this.props;

        if(!currentUserPackage) {
            return <Spinner />;
        }
        else if(!currentUserPackage.id) {
            return <h1>Package not found!</h1>;
        }

        const userLimitsRows = [];
        if(currentUserPackage.user_limits && currentUserPackage.user_limits.length) {
            for (const [index] of currentUserPackage.user_limits.entries()) {
                userLimitsRows.push(<li key={index}>
                    {currentUserPackage.user_limits[index].name}: <strong>{currentUserPackage.user_limits[index].value}</strong>
                </li>);
            }
        }

        const handleCancelSubscription = (id) => {
            if(window.confirm("Do you really want to cancel subscription?")) {
                cancelSubscription(id).then(res => {
                    this.props.requestUserPackage(id);
                });
            }
        };

        return (
            <>
                <Portlet>
                    <PortletHeader title={currentUserPackage.name} />
                    <PortletBody>
                        <ul>
                            <li>Package date start: <strong>{currentUserPackage.date_start.toLocaleString()}</strong></li>
                            {currentUserPackage.date_end && <li>Package date end: <strong>{currentUserPackage.date_end.toLocaleString()}</strong></li>}
                            <li>Price (per month): <strong>${currentUserPackage.price.toFixed(2)}</strong>{currentUserPackage.subscription && currentUserPackage.subscription.active ?
                                <a href="#" onClick={(e)=>{e.preventDefault(); handleCancelSubscription(currentUserPackage.subscription.id)}}>(Cancel Subscription)</a> :
                                (currentUserPackage.subscription ? " (Subscription canceled)" : "")
                            }</li>
                            {currentUserPackage.subscription && currentUserPackage.subscription.payment_system && <li>Payment system: <strong>{currentUserPackage.subscription.payment_system.toUpperCase()}</strong></li>}
                        </ul>
                        <ul>
                            {userLimitsRows}
                        </ul>
                    </PortletBody>
                </Portlet>
            </>
        );
    }
}

const mapStateToProps = ({ packages: {currentUserPackage} }) => ({
    currentUserPackage
});

export default injectIntl(connect(
    mapStateToProps,
    {...packages.actions}
)(UserPackageDetails));
