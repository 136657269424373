import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
//import Builder from "./Builder";
import Dashboard from "./Dashboard";
//import DocsPage from "./docs/DocsPage";
import { LayoutSplashScreen } from "../../../_metronic";
import Profile from "./Profile";
import ProjectDetails from "./projects-components/ProjectDetails";
import ProjectEdit from "./projects-components/ProjectEdit";
import MarketplacePage from "./marketplace/MaketplacePage";
import Checkout from "./Checkout";
import CheckoutSuccess from "./CheckoutSuccess";
import Analytics from "./Analytics";
import Favorites from "./Favorites";
import Projects from "./Projects";
import ScheduleCall from "./ScheduleCall";
import SeoServicesPage from "./seo-services/SeoServicesPage";
import PackagesPage from "./packages/PackagesPage";
import ToolsPage from "./tools/ToolsPage";
import MorePage from "./more/MorePage";
import ChecklistsPage from "./checklists/ChecklistsPage";
import UserPackagesPage from "./user-packages/UserPackagesPage";
import CreateProjectProcess from "./projects-components/CreateProjectProcess";
import DomainMarketplacePage from "./domains/DomainMarketplacePage";
import WeeklyLinkOpportunities from "./WeeklyLinkOpportunities";
import DigitalGuestPosts from "./marketplace/DigitalGuestPosts";

const SalesPage = lazy(() =>
    import("./sales/SalesPage")
);
const OrdersPage = lazy(() =>
    import("./orders/OrdersPage")
);
const BlogsPage = lazy(() =>
    import("./blogs/BlogsPage")
);
const AuditPage = lazy(() =>
    import("./onpage-audit/AuditPage")
);
const OfferCheckout = lazy(() =>
    import("./offers/OfferCheckout")
);

/*const GoogleMaterialPage = lazy(() =>
  import("./google-material/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./react-bootstrap/ReactBootstrapPage")
);*/

export default function HomePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/projects/:id" component={ProjectDetails} />
        <Route path="/projects" component={Projects} />
        <Route path="/project-edit/:id" component={ProjectEdit} />
        <Route path="/project/:action" component={CreateProjectProcess} />
        <Route path="/sales" component={SalesPage} />
        <Route path="/orders" component={OrdersPage} />
        <Route path="/blogs" component={BlogsPage} />
        {<Route path="/offer/:offerId" component={OfferCheckout} />}
        {/*<Route path="/weekly-link-opportunities" component={WeeklyLinkOpportunities} />*/}
        <Route path="/marketplace" component={MarketplacePage} />
        <Route path="/digital" component={DigitalGuestPosts} />
        {/*<Route path="/domains" component={DomainMarketplacePage} />*/}
        {/*<Route path="/builder" component={Builder} />*/}
        <Route path="/dashboard" component={Projects} />
        <Route path="/favorites" component={Favorites} />
        <Route path="/schedule-call" component={ScheduleCall} />
        <Route path="/profile" component={Profile} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/checkout-success" component={CheckoutSuccess} />
        <Route path="/onpage-audit" component={AuditPage} />
        <Route path="/analytics" component={Analytics} />
        <Route path="/seo-services" component={SeoServicesPage} />
        <Route path="/packages" component={PackagesPage} />
        <Route path="/tools" component={ToolsPage} />
        <Route path="/checklists" component={ChecklistsPage} />
        <Route path="/more" component={MorePage} />
        <Route path="/user-packages" component={UserPackagesPage} />
        {/*<Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/docs" component={DocsPage} />*/}
        <Redirect from="/admin" to="/projects" />
        <Route path='/help-center' component={() => {
            window.location.href = 'https://www.serppro.io/help-center';
            return null;
        }}/>
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
