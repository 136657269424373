import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {getPackages, getPackage, getUserPackages, getUserPackage} from "../../crud/packages.crud";

export const actionTypes = {
    PackagesInit: "[Init Packages] Action",
    PackagesRequested: "[Request Packages] Action",
    PackagesLoaded: "[Load Packages] Packages API",
    PackageRequested: "[Request Package] Action",
    PackagePremiumRequested: "[Request Package] Premium Action",
    PackageLoaded: "[Load Package] Packages API",
    PackagePremiumLoaded: "[Load Package] Packages API Premium",
    UserPackagesInit: "[Init User Packages] Action",
    UserPackagesRequested: "[Request User Packages] Action",
    UserPackagesLoaded: "[Load User Packages] Packages API",
    UserPackageRequested: "[Request User Package] Action",
    UserPackageLoaded: "[Load User Package] Packages API",
};

const initialPackagesState = {
    packages: undefined,
    currentPackage: undefined,
    userPackages: undefined,
    currentUserPackage: undefined,
    premiumMembershipPackage: undefined
};

export const reducer = persistReducer(
    { storage, key: "demo3-packages", whitelist: ["packages", "currentPackage", "userPackages", "currentUserPackage", "premiumMembershipPackage"] },
    (state = initialPackagesState, action) => {
        switch (action.type) {
            case actionTypes.PackagesInit: {
                return { packages: undefined, currentPackage: undefined };
            }

            case actionTypes.PackagesLoaded: {
                const { packages, type } = action.payload;

                return { ...state, packages: {[type]: packages} };
            }

            case actionTypes.UserPackagesInit: {
                return { ...state, userPackages: undefined, currentUserPackage: undefined };
            }

            case actionTypes.UserPackagesLoaded: {
                const { userPackages } = action.payload;

                return { ...state, userPackages };
            }

            case actionTypes.PackageRequested: {
                return { ...state, currentPackage: undefined };
            }

            case actionTypes.PackagePremiumRequested: {
                return { ...state, premiumMembershipPackage: undefined };
            }

            case actionTypes.PackageLoaded: {
                const { currentPackage } = action.payload;

                return { ...state, currentPackage };
            }

            case actionTypes.PackagePremiumLoaded: {
                const { premiumMembershipPackage } = action.payload;

                return { ...state, premiumMembershipPackage };
            }

            case actionTypes.UserPackageRequested: {
                return { ...state, currentUserPackage: undefined };
            }

            case actionTypes.UserPackageLoaded: {
                const { currentUserPackage } = action.payload;

                return { ...state, currentUserPackage };
            }

            default:
                return state;
        }
    }
);

export const actions = {
    initPackages: (type) => ({ type: actionTypes.PackagesInit, payload: { type } }),
    requestPackages: (type) => ({ type: actionTypes.PackagesRequested, payload: { type } }),
    fulfillPackages: (packages, type) => ({ type: actionTypes.PackagesLoaded, payload: { packages, type } }),
    initUserPackages: (orderId, page) => ({ type: actionTypes.UserPackagesInit, payload: { orderId, page } }),
    requestUserPackages: (orderId, page) => ({ type: actionTypes.UserPackagesRequested, payload: { orderId, page } }),
    fulfillUserPackages: userPackages => ({ type: actionTypes.UserPackagesLoaded, payload: { userPackages } }),
    requestPackage: type => ({ type: actionTypes.PackageRequested, payload: { type } }),
    requestPackagePreimumMembership: type => ({ type: actionTypes.PackagePremiumRequested, payload: { type } }),
    fulfillPackage: currentPackage => ({ type: actionTypes.PackageLoaded, payload: { currentPackage } }),
    fulfillPremiumPackage: premiumMembershipPackage => ({ type: actionTypes.PackagePremiumLoaded, payload: { premiumMembershipPackage } }),
    requestUserPackage: id => ({ type: actionTypes.UserPackageRequested, payload: { id } }),
    fulfillUserPackage: currentUserPackage => ({ type: actionTypes.UserPackageLoaded, payload: { currentUserPackage } })
};

export function* saga() {
    yield takeLatest(actionTypes.PackagesInit, function* initPackagesSaga(action) {
        const { type } = action.payload;
        yield put(actions.requestPackages(type));
    });

    yield takeLatest(actionTypes.PackagesRequested, function* packagesRequested(action) {
        const { type } = action.payload;
        try {
            const {data:packages} = yield getPackages(type);

            yield put(actions.fulfillPackages({data: packages}, type));
        }
        catch (e) {
            yield put(actions.fulfillPackages({data: undefined}, type));
        }
    });

    yield takeLatest(actionTypes.UserPackagesInit, function* initUserPackagesSaga(action) {
        const { orderId, page } = action.payload;
        yield put(actions.requestUserPackages(orderId, page));
    });

    yield takeLatest(actionTypes.UserPackagesRequested, function* userPackagesRequested(action) {
        try {
            const { orderId, page } = action.payload;
            const {data: userPackages} = yield getUserPackages(orderId, page);

            yield put(actions.fulfillUserPackages(userPackages));
        }
        catch (e) {
            yield put(actions.fulfillUserPackages({data: undefined}));
        }
    });

    yield takeLatest(actionTypes.PackageRequested, function* packageRequested(action) {
        try {
            const { type } = action.payload;
            const {data: currentPackage} = yield getPackage(type);

            yield put(actions.fulfillPackage(currentPackage));
        }
        catch (e) {
            yield put(actions.fulfillPackage({data: undefined}));
        }
    });

    yield takeLatest(actionTypes.PackagePremiumRequested, function* packagePremiumRequested(action) {
        try {
            const { type } = action.payload;
            const {data: premiumMembershipPackage} = yield getPackage(type);

            yield put(actions.fulfillPremiumPackage(premiumMembershipPackage));
        }
        catch (e) {
            yield put(actions.fulfillPremiumPackage({data: undefined}));
        }
    });

    yield takeLatest(actionTypes.UserPackageRequested, function* userPackageRequested(action) {
        try {
            const { id } = action.payload;
            const {data: currentUserPackage} = yield getUserPackage(id);

            yield put(actions.fulfillUserPackage(currentUserPackage));
        }
        catch (e) {
            yield put(actions.fulfillUserPackage({data: undefined}));
        }
    });
}
