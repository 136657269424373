import React, { Component } from "react";
import {Portlet, PortletBody, PortletHeader, PortletHeaderToolbar} from "../partials/content/Portlet";
import {Link} from "react-router-dom";
import {Button, ButtonToolbar} from "react-bootstrap";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as marketplace from "../store/ducks/marketplace.duck";
import * as cart from "../store/ducks/cart.duck";
import * as projects from  "../store/ducks/projects.duck";
import {Spinner} from "reactstrap";
import "../../_metronic/_assets/sass/custom/marketplace.scss";

class MarketplaceWidget extends Component {
    componentDidMount() {
        const page = 1;
        const {currentProjectId} = this.props;
        let filter = {};
        filter['project_id'] = currentProjectId;
        filter['keyword'] = "";
        filter['widget'] = true;
        this.props.initMarketplace(page, filter);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const page = 1;
        const {filter} = this.props;console.log(this.props.guestPosts);console.log(filter);console.log("---");
        if(this.props.guestPosts && this.props.guestPosts.marketplace_category && this.props.guestPosts.marketplace_category !== filter['keyword']) {
            filter['keyword'] = this.props.guestPosts.marketplace_category;
            this.props.initMarketplace(page, filter);
        }
    }

    render() {
        const {guestPosts, accessDenied, cartItems,} = this.props;
        const gpRows = [];
        const categories = [];

        const kFormatter = (num) => {
            return Math.abs(num) > 9999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(0)) + 'k' : Math.sign(num)*Math.abs(num);
        }

        const handleAddToCartMobile = (id) => {
            let guestPost = {};
            for (const [index] of guestPosts.data.entries()) {
                if(guestPosts.data[index].id === parseInt(id)) {
                    guestPost = guestPosts.data[index];
                }
            }

            const categoryName = categories["c" + guestPost.category_id];

            const item = {
                key: "mgp_" + id,
                price: Number(guestPost.price),
                name: "Marketplace Guest Post",
                description: "Category: " + categoryName + ", DA: " + guestPost.da,
                orderData : {
                    subscription: 0,
                    quantity: 1,
                    type: "marketplace_guest_post",
                    options: {
                        site_id: id,
                        marketplace_article: 1,
                    }
                }
            };

            this.props.addToCart(item);
        };

        if(!accessDenied) {
            if(!guestPosts) {
                gpRows.push(<div key="load1" className="text-center"><Spinner/></div>);
            }
            else if(!guestPosts.data) {
                gpRows.push(<div key="err1" className="text-center">Guest Posts not found!</div>);
            }

            if(guestPosts && guestPosts.data) {
                if(guestPosts.categories) {
                    if(guestPosts.categories.length) {
                        for (const [index] of guestPosts.categories.entries()) {
                            categories["c" + guestPosts.categories[index].id] = guestPosts.categories[index].name;
                        }
                    }
                }

                if (guestPosts.data.length) {
                    for (const [index] of guestPosts.data.entries()) {
                        let inCart = false;
                        if(cartItems) {
                            inCart = cartItems.filter(item => item.key === "mgp_" + guestPosts.data[index].id).length !== 0;
                        }

                        gpRows.push(<div key={index} className={"kt-mb-5 col-xl-4 col-md-4 col-sm-6 col-12 mx-xl-auto"}><Portlet>
                            <PortletBody className={"p-0"}>
                                <div className={"ml-img-col d-flex align-items-center"}>
                                    <img className="ml-img" src={guestPosts.data[index].image ? guestPosts.data[index].image : ""} alt="" />
                                    <div className="ml-category-label">
                                        {categories["c" + guestPosts.data[index].category_id]}
                                    </div>
                                </div>
                                <div className="ml-table-col d-flex align-items-center">
                                    <table className="table table-borderless ml-table text-center mb-0">
                                        <tbody>
                                        <tr>
                                            <td>DA</td>
                                            <td>DR</td>
                                            <td>RD</td>
                                            <td>Traffic</td>
                                            <td>Price</td>
                                        </tr>
                                        <tr>
                                            <td>{guestPosts.data[index].da}</td>
                                            <td>{guestPosts.data[index].dr}</td>
                                            <td>{kFormatter(guestPosts.data[index].rd)}</td>
                                            <td>{kFormatter(guestPosts.data[index].organic_traffic)}</td>
                                            <td>${guestPosts.data[index].price.toLocaleString("en")}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="text-center d-flex align-items-center justify-content-center ml-btn-col">
                                    <Button className="ml-add-to-cart kt-font-sm" size={"sm"} onClick={() => {handleAddToCartMobile(guestPosts.data[index].id);}} disabled={inCart}>Add to Cart</Button>
                                </div>
                            </PortletBody>
                        </Portlet></div>);
                    }
                }
            }
        }

        return (<>
            <Portlet>
                <PortletHeader
                    title={"These sites want to link to you"}
                    toolbar={<PortletHeaderToolbar>
                        <ButtonToolbar>
                            <Link className="btn btn-outline-success" to="/marketplace">Go to marketplace</Link>
                        </ButtonToolbar>
                    </PortletHeaderToolbar>}
                />
                <PortletBody className="mv-grid marketplace-widget">
                    <div className="row">
                        {gpRows}
                    </div>
                </PortletBody>
            </Portlet>
        </>);
    }
}

const mapStateToProps = ({ marketplace: { guestPosts, accessDenied, filter }, cart: {cartItems}, projects: {currentProjectId} }) => ({
    guestPosts, cartItems, accessDenied, filter, currentProjectId
});

export default injectIntl(connect(
    mapStateToProps,
    {...marketplace.actions, ...cart.actions}
)(MarketplaceWidget));
