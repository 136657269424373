import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Tab, Tabs } from "react-bootstrap";
import * as orders from "../../../store/ducks/orders.duck";
import {Spinner} from "reactstrap";
import {Table, Badge} from "react-bootstrap";
import {Portlet, PortletBody, PortletHeader} from "../../../partials/content/Portlet";
import { Link, useParams } from "react-router-dom";
import {Icon} from "@material-ui/core";
import {downloadPdfInvoice} from "../../../crud/orders.crud";
import "../../../../_metronic/_assets/sass/custom/datatable.scss";
import UPagination from "../../../partials/content/Pagination";
import ProjectSelect from "../projects-components/ProjectSelect";
import { withRouter } from "react-router-dom";
import EditOrderProject from "./EditOrderProject";

class OrdersList extends Component {
    constructor(props) {
        super(props);

        let sending = false;
        // let orderNumber = null;
        // let counterOfferRowAmount = 0;

        this.state = {
            sending: sending,
            orderNumber: null,
            orderId: null,
            orderProject: null,
            openEditProjectModal: false
        };
    }

    componentDidMount() {
        const {currentProjectId} = this.props;
        const getPage = new URLSearchParams(this.props.location.search).get("page");

        if(currentProjectId > 0 || currentProjectId == 'all') {             
             if (getPage != null) {
                this.props.initOrders(currentProjectId, getPage);
             } else {
                this.props.initOrders(currentProjectId);
             }
        } else {
            if (getPage != null) {
                this.props.initOrders(0, getPage);
            } else {
                this.props.initOrders(0);
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if((this.props.currentProjectId > 0 || this.props.currentProjectId == 'all') && this.props.currentProjectId !== prevProps.currentProjectId) {
            // const getPage = new URLSearchParams(window.location.search).get('page');
            const getPage = new URLSearchParams(this.props.location.search).get("page");
            if (getPage != null) {
                this.props.initOrders(this.props.currentProjectId, getPage);
            } else {
                this.props.initOrders(this.props.currentProjectId);
            }
        } else if (this.props.currentProjectId == 0 && this.props.currentProjectId !== prevProps.currentProjectId) {
            const getPage = new URLSearchParams(this.props.location.search).get("page");
            if (getPage != null) {
                this.props.initOrders(this.props.currentProjectId, getPage);
            } else {
                this.props.initOrders(this.props.currentProjectId);
            }           
        }
    }

    render() {
        const {user, orders, currentProjectId} = this.props;

        let the_current_page = null;

        const rows = [];

        const getPage = new URLSearchParams(this.props.location.search).get("page");

        const moneyFormatter = (num) => {
            if (typeof num !== 'undefined') {
                try {
                    var calc = num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('.00','');
                    // var calc = num.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    return calc;
                } catch (e) {
                    console.log('moneyFormatter',e);
                    return 0.00;
                }
            }
            return num;
        }

        const closeEditProjectModal = (e) => {
        //     if(currentProjectId > 0 || currentProjectId == 'all') {             
        //         if (getPage != null) {
        //            this.props.initOrders(currentProjectId, getPage);
        //         } else {
        //            this.props.initOrders(currentProjectId);
        //         }
        //    } else {
        //        if (getPage != null) {
        //            this.props.initOrders(0, getPage);
        //        } else {
        //            this.props.initOrders(0);
        //        }
        //    }
            this.setState({
                ...this.state, 
                openEditProjectModal: false
            });
        }

        this.openEditProjectModal = (e, order_number, order_project, order_id) => {
            this.setState({
                ...this.state, 
                openEditProjectModal: true,
                orderNumber: order_number,
                orderId: order_id,
                orderProject: order_project
            });
        }

        if (orders && orders.meta && orders.data) {

            // const getPage = new URLSearchParams(window.location.search).get('page');
            const getPage = new URLSearchParams(this.props.location.search).get("page");
            if (getPage != null) {
                the_current_page = parseInt(getPage);
            } else {
                the_current_page = orders.meta.current_page;
            }

            if (orders.data.length) {
                for (const [index] of orders.data.entries()) {
                    // console.log(orders.data[index]);
                    const date = new Date(orders.data[index].created_at);
                    let statusVariant = "secondary";
                    if(parseInt(orders.data[index].status) === 1)
                    {
                        statusVariant = "info";
                    }
                    else if(parseInt(orders.data[index].status) === 2) {
                        statusVariant = "success";
                    }

                    let order_project_element_name = 'order_project_text_'+orders.data[index].id;

                    rows.push(<tr key={index} className="datatable-row">
                        <td className="datatable-cell"><span>{orders.data[index].order_number}</span></td>
                        {currentProjectId == 'all' ? 
                            <td className="datatable-cell" style={{display:"flex"}}>
                                <span id={order_project_element_name} style={{display:"block"}}>{orders.data[index].project_url}</span>
                                <div class="edit_order_project" style={{display:"block",cursor:"pointer",marginLeft:"6px",position:"relative",top:"-1px"}} onClick={(e) => this.openEditProjectModal(e, orders.data[index].order_number, orders.data[index].project_id, orders.data[index].id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>
                                </div>
                                <div class="edit_order_project_container">
                                    
                                </div>
                            </td>
                            : ''
                        }
                        <td className="datatable-cell"><span><Badge pill variant={statusVariant}>{orders.data[index].status_label}</Badge></span></td>
                        <td className="datatable-cell" style={{width:"18%"}}><span>{date.toLocaleString()}</span></td>
                        <td className="datatable-cell" style={{width:"18%"}}><span>{orders.data[index].order_text_data == "Deposit" ? "Deposit" : orders.data[index].hasUserPackages !== true && <Link to={"/orders/" + orders.data[index].id + "/progress?page="+the_current_page}><Icon className="fas fa-eye" style={{fontSize: "11px", width: "auto"}} /> View</Link>}</span></td>
                        <td className="datatable-cell"><span>$&nbsp;{typeof orders.data[index].total !== 'undefined' && orders.data[index].total != null ? moneyFormatter(orders.data[index].total) : ''}</span></td>
                        <td className="datatable-cell"><span><Link to={"/orders/" + orders.data[index].id + "/invoice"}><Icon className="fas fa-eye" style={{fontSize: "11px", width: "auto"}} /> View</Link> / <a href="#" onClick={(e) => {
                            e.preventDefault();
                            downloadPdfInvoice(orders.data[index].id).then(response => {
                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', orders.data[index].id + '.pdf');
                                document.body.appendChild(link);
                                link.click();
                            });
                        }}><Icon className="fa fa-download" style={{fontSize: "11px", width: "auto"}} /> PDF</a></span></td>
                    </tr>)
                }
            }
        }

        const handleNextPage = (number) => {
            this.props.history.push("?page="+number);
            this.props.initOrders(currentProjectId, number);
        };

        const goToTabPage = (key) => {
            if (key == 'orders') {
                this.props.history.push({
                    pathname: "orders"
                });
            } else if (key == 'backlinks') {
                this.props.history.push({
                    pathname: "orders/backlinks"
                });
            }
        }

        return (
            <div>
                <EditOrderProject open={this.state.openEditProjectModal} onClose={closeEditProjectModal} orderNumber={this.state.orderNumber} orderId={this.state.orderId} orderProject={(this.state.orderProject == null ? 'null' : this.state.orderProject)} />
                <div className="row">
                    <div className="col-12">
                        <div className="progress progressBar TmoProgressBar">
                            <div className="progress-bar" role="progressbar" aria-valuenow={(user.tmo_amount ? user.tmo_amount : 0)} aria-valuemin="0" aria-valuemax={(user.tmo_progress_bar_total ? user.tmo_progress_bar_total : 10000)} style={{width:(user.tmo_percentage ? user.tmo_percentage : 0)+'%'}}>
                                <span class="progress-label">{(user.tmo_amount ? '$'+user.tmo_amount.toLocaleString(undefined, {maximumFractionDigits:2}) : '$0.00')}</span>
                            </div>
                            <div class="first_label">This Month's Spend</div>
                            <div class="second_label">
                                <div class="vertical_line">|</div>
                                $1,000
                            </div>
                            <div class="third_label">
                                <div class="vertical_line">|</div>
                                $5,000
                            </div>
                            <div class="fourth_label">
                                <div class="vertical_line">|</div>
                                $10,000
                            </div>
                        </div>
                    </div>
                </div>
                <Portlet>
                    {/* <PortletHeader title="Orders" /> */}
                    <PortletBody>
                        <div className="row">
                            <div className="col-md-10">
                                <div className="kt-section">
                                    <Tabs activeKey="orders" onSelect={(k) => goToTabPage(k)} id="order-tabs">
                                        <Tab eventKey="orders" title="My Orders">
                                            {/* <AccountBalance /> */}
                                        </Tab>
                                        <Tab eventKey="backlinks" title="My Backlinks">
                                            {/* <BillingProfile /> */}
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>

                        <ProjectSelect compact={true} showProjectButtons={this.props.showProjectButtons} orders={orders} />
                        
                        <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded">
                            <Table className="datatable-table pages-table">
                                <thead className="datatable-head">
                                <tr className="datatable-row">
                                    <th className="datatable-cell"><span>Order #</span></th>
                                    {currentProjectId == 'all' ? 
                                        <th className="datatable-cell"><span>Project</span></th>
                                        : ''
                                    }
                                    <th className="datatable-cell"><span>Status</span></th>
                                    <th className="datatable-cell" style={{width:"18%"}}><span>Date Order</span></th>
                                    <th className="datatable-cell" style={{width:"18%"}}><span>Order Progress</span></th>
                                    <th className="datatable-cell"><span>Total</span></th>
                                    <th className="datatable-cell"><span>Invoice</span></th>
                                </tr>
                                </thead>
                                <tbody className="datatable-body">
                                {orders ? rows : <tr><td colSpan={6}>{currentProjectId >= 0 || currentProjectId == 'all' ? <Spinner /> : ""}</td></tr>}
                                </tbody>
                            </Table>
                        </div>
                        {the_current_page != null && orders && orders.meta && orders.meta.last_page > 1 && <UPagination currentPage={the_current_page} totalPages={orders.meta.last_page} onChange={handleNextPage} />}
                    </PortletBody>
                </Portlet>
            </div>
        );
    }
}

const mapStateToProps = ({ auth: { user }, orders: { orders }, projects: { currentProjectId } }) => ({
    orders, currentProjectId, user
});

export default injectIntl(connect(
    mapStateToProps,
    orders.actions
)(OrdersList));
