import React, { Component } from "react";
import * as packages from "../../../store/ducks/packages.duck";
import {Portlet, PortletBody,} from "../../../partials/content/Portlet";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Spinner} from "reactstrap";
import {Card, Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import CheckoutSubscriptionModal from "../CheckoutSubscriptionModal";
import {addFreePackage} from "../../../crud/packages.crud";
import {withStyles, Tooltip} from "@material-ui/core";
import ContentCreationInfo from "./faq/ContentCreationInfo";

const styles = theme => ({
    'ms-title': {
        fontSize: theme.typography.pxToRem(26),
        fontWeight: '600',
        margin: 0,
        padding: '35px 0 15px 0',
    },
    'ms-subtext': {
        margin: "0 auto 32px",
        width: "720px",
        maxWidth: "100%",
        fontSize: "22px",
    },
    'si-title': {
        fontSize: "28px",
        margin: "5px 0 25px",
    },
    'si-footer': {
        marginTop:"20px",
        backgroundColor:"inherit",
        border: "none",
        borderRadius: 0,
        paddingBottom: "20px",
    },
    'si-btn': {
        width:"170px",
        borderRadius:0,
        backgroundColor: "#3061fe",
        borderColor: "#3061fe",
        boxShadow: "0 0px 10px 0 rgb(0 0 0 / 30%)",
        "&:disabled": {
            backgroundColor: "#007cc7",
            borderColor: "#007cc7",
        },
    },
    'si-descr-row': {
        textAlign: "left",
        padding: "0 0 12px 0",
        lineHeight:"24px",
        color: "#54595f",
        '& i': {
            marginRight: "5px",
            color:"#2ecd0c",
        }
    },
    'si-tooltip': {
        cursor:"pointer",
        borderBottom: "2px dashed #d7dee7",
    },
    'variations-select': {
        width: "auto",
        margin: "0 auto",
        display: "block",
        padding: "0.65rem 0",
    },
    'si-card': {
        fontSize: "16px",
        border: "none",
        borderRadius: 0,
        backgroundColor: "transparent",
        '& .card-body': {
            paddingLeft:"30px",
            paddingRight:"30px",
        }
    },
    'si-selected': {
        backgroundColor: "#fff",
        boxShadow: "0 0px 41px 0 rgb(0 0 0 / 10%)",
    },
    'si-trial': {
        fontSize:"14px",
        height:"25px",
        margin:"15px auto 0",
        '& a': {
            color:"#000000",
        }
    }
});

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(16),
        border: '1px solid #dadde9',
        padding: '10px 15px',
    },
}))(Tooltip);

function formatMoney(number, decPlaces, decSep, thouSep) {
    decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
    decSep = typeof decSep === "undefined" ? "." : decSep;
    thouSep = typeof thouSep === "undefined" ? "," : thouSep;
    var sign = number < 0 ? "-" : "";
    var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
    var j = (j = i.length) > 3 ? j % 3 : 0;

    return sign +
        (j ? i.substr(0, j) + thouSep : "") +
        i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
        (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
}

class ContentCreation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selPackageId: 0,
            showCheckout: false,
            freePackageSubmitting: false,
            orderLimit: {},
        };
    }

    componentDidMount() {
        const type = "content_package";
        this.props.requestPackages(type);
    }

    render() {
        const { classes } = this.props;
        const {selPackageId, showCheckout} = this.state;
        const type = "content_package";
        const {packages: packagesObj} = this.props;

        if(!packagesObj || !packagesObj[type]) {
            return <Spinner />;
        }
        else if(!packagesObj[type].data) {
            return <h1>Packages not found!</h1>;
        }

        const getSubscriptionItem = (selPackage) => {
            let orderData = {};
            orderData[0] = {
                subscription: 1,
                quantity: 1,
                type: "content_package",
                options: {
                    package_id: selPackage.id,
                },
            };

            const price = selPackage.price;
            const name = selPackage.name;
            /*let period = "";
            if(selPackage.period === "m") {
                period = "month";
            }
            if(selPackage.period === "y") {
                period = "year";
            }*/

            const key = selPackage.price + "s" + selPackage.id;
            const item = {
                key: "content_" + key,
                price: price.toFixed(2),
                name: name,
                description: "Content Creation",
                orderData: orderData[0]
            };

            return item;
        };

        const handleAddFreePackage = (e) => {
            e.preventDefault();
            //set loading status
            this.setState({...this.state, freePackageSubmitting: true});
            addFreePackage("content_package").then(() => {
                this.props.history.push({
                    pathname: "/"
                });
            }).catch(() => {
                //set loading status
                this.setState({...this.state, freePackageSubmitting: false});
            });
        }

        const {packages, active_package:activePackage} = packagesObj[type].data;
        const packageCols = [];
        if(packages && packages.length !== 0) {
            let i = 0;
            for(let [key, value] of Object.entries(packages)) {
                if(value.price >= 0) {
                    let period = "";
                    if(value.period === "m") {
                        period = "month";
                    }
                    if(value.period === "y") {
                        period = "year";
                    }
                    /*let postsLimit = 0;
                    let isUnlimited = false;
                    if (value.package_options && value.package_options["order_limit"]) {
                        postsLimit = parseInt(value.package_options["order_limit"]);
                        if(value.package_options["order_limit"] === "unlimited") {
                            isUnlimited = true;
                        }
                    }*/
                    const descriptionRows = [];
                    if (value.description) {
                        const description = value.description.split('\n');
                        const tooltips = {};
                        for (let i = 0; i < description.length; i++) {
                            let descriptionLine = description[i];
                            if(descriptionLine.indexOf("**") !== -1) {
                                const tooltipIndex = i - 1 >= 0 ? i - 1 : 0;
                                tooltips["t" + tooltipIndex] = descriptionLine.replace(/[*]/g, "");
                            }
                        }
                        for (let i = 0; i < description.length; i++) {
                            let descriptionLine = description[i];
                            if(descriptionLine.indexOf("**") === -1) {
                                const isBold = descriptionLine.indexOf("*") >= 0 && descriptionLine.indexOf("*") <= 3;;
                                if(isBold) {
                                    descriptionLine = descriptionLine.replace(/[*]/g, "");
                                }
                                if(tooltips && tooltips["t" + i]) {
                                    const tooltipText = tooltips["t" + i];

                                    descriptionRows.push(<HtmlTooltip key={"dt-" + i} disableFocusListener disableTouchListener
                                                                      title={<React.Fragment>
                                                                          <p className="kt-mb-0">{tooltipText}</p>
                                                                      </React.Fragment>}
                                    ><div key={"dr" + i} className={classes["si-descr-row"]}>
                                        <i className="fa fa-check"/> <span className={classes["si-tooltip"]}>{isBold ? <strong>{descriptionLine}</strong> : descriptionLine}</span>
                                    </div></HtmlTooltip>);
                                }
                                else {
                                    descriptionRows.push(<div key={"dr" + i} className={classes["si-descr-row"]}>
                                        <i className="fa fa-check"/> {isBold ? <strong>{descriptionLine}</strong> : descriptionLine}
                                    </div>);
                                }
                            }
                        }
                    }
                    let isCurrentSubscription = activePackage && activePackage.package_id === value.id;
                    /*const packageVariations = value.package_variations;
                    const variationRows = [];
                    if(packageVariations && packageVariations.length !== 0) {
                        if(isCurrentSubscription) {
                            let activeOrderLimit = 0;
                            if(activePackage.user_limits && activePackage.user_limits.length !== 0) {
                                for(let i = 0; i < activePackage.user_limits.length; i++) {
                                    if(activePackage.user_limits[i].type === value.type + "_order_limit") {
                                        activeOrderLimit = activePackage.user_limits[i].value;
                                    }
                                }
                            }
                            if (this.state.orderLimit && this.state.orderLimit["ol_" + value.id]) {
                                if(activeOrderLimit !== this.state.orderLimit["ol_" + value.id]) {
                                    isCurrentSubscription = false;
                                }
                            }
                            else {
                                if(activeOrderLimit !== postsLimit) {
                                    isCurrentSubscription = false;
                                }
                            }
                        }
                        for (let i = 0; i < packageVariations.length; i++) {
                            if(packageVariations[i].key === "order_limit") {
                                variationRows.push(<option key={"ol" + packageVariations[i].value} value={packageVariations[i].value}>{packageVariations[i].value} post limit per {period}</option>);
                            }
                        }
                    }*/

                    packageCols.push(<div key={"cc-" + key} className="col-lg-4 col-md-6 col-12 mb-lg-0 mb-3">
                        <Card className={classes["si-card"] + (i === 1 ? " " + classes["si-selected"] : "") + " h-100 text-center"}>
                            <Card.Body>
                                <Card.Title className={classes["si-title"]}>{value.name}</Card.Title>
                                {/*packageVariations && packageVariations.length !== 0 ?
                                    <Card.Text className="kt-mb-40">
                                        <select name="order_limit" className={classes["variations-select"] + " kt-font-bolder form-control"} onChange={(e) => {
                                            this.setState({
                                                ...this.state,
                                                orderLimit: {...this.state.orderLimit, ["ol_" + value.id]: e.target.value},
                                            });
                                        }}>
                                            {variationRows}
                                        </select>
                                    </Card.Text>
                                    : ""*/}
                                {value.description && <div className="kt-mb-25">
                                    {descriptionRows}
                                </div>}
                                <Card.Text><span className="kt-font-bolder">{value.price > 0 ? "$" + formatMoney(value.price, 0, ".", ",") + " per " + period : "Free access"}</span></Card.Text>
                                {isCurrentSubscription &&
                                <small className="text-muted"><Link to={"/user-packages/" + activePackage.id}>(Your
                                    current subscription)</Link></small>}
                            </Card.Body>
                            {!isCurrentSubscription &&
                            <Card.Footer className={classes["si-footer"]}>
                                {value.price > 0 ? <Button className={classes["si-btn"]} onClick={() => {
                                    this.setState({
                                        ...this.state,
                                        selPackageId: value.id,
                                        showCheckout: true
                                    });
                                }}>Subscribe Now</Button> : (!this.state.freePackageSubmitting ? <Button disabled={activePackage} className={classes["si-btn"]} onClick={handleAddFreePackage}>Subscribe Now</Button> : <Spinner />)}
                                <div className={classes["si-trial"]}>
                                </div>
                            </Card.Footer>}
                        </Card>
                    </div>);
                    i++;
                }
            }
        }

        const checkoutClose = () => {
            this.setState({...this.state, showCheckout:false});
        };

        return (<>
            <div className="row">
                <div className="col-12" style={{maxWidth:"1150px"}}>
                    <div>
                        <div className="text-center">
                            <h1 className={classes["ms-title"]}>Choose A Content Creation Package to Get Started</h1>
                        </div>
                        <Portlet style={{backgroundColor: "transparent",boxShadow: "none"}}>
                            <PortletBody>
                                <div className="row">
                                    {packages ? packageCols : <Spinner />}
                                </div>
                                {packages && packages.length === 0 && <p>Packages not found!</p>}
                                {packages && selPackageId > 0 && packages[selPackageId] && <CheckoutSubscriptionModal
                                    show={showCheckout}
                                    onHide={checkoutClose}
                                    item={
                                        getSubscriptionItem(packages[selPackageId])
                                    }
                                    paypalPlanId={packages[selPackageId].paypal_plan_id}
                                    stripePriceId={packages[selPackageId].stripe_price_id}
                                />}
                            </PortletBody>
                        </Portlet>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={{maxWidth:"1150px"}}>
                    <ContentCreationInfo />
                </div>
            </div>
        </>);
    }
}

const mapStateToProps = ({ packages: {packages} }) => ({
    packages
});

export default withStyles(styles)(injectIntl(connect(
    mapStateToProps,
    {...packages.actions}
)(ContentCreation)));
