import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as services from "../../store/ducks/admin/services.duck";
import {Spinner} from "reactstrap";
import {Portlet, PortletBody} from "../../partials/content/Portlet";
import {Button, Form} from "react-bootstrap";
import { Formik } from "formik";
import {Typography, TextField, Breadcrumbs, FormControlLabel, Checkbox} from "@material-ui/core";
import {Link} from 'react-router-dom';
import {editLinkInsertionService} from "../../crud/admin/orders.crud";

class LinkInsertionAdmin extends Component {
    state = { nextId: null };

    componentDidMount() {
        const { id } = this.props.match.params;
        const key = "link_insertion";
        this.props.requestService(id, key);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const nextId = this.state.nextId;
        const key = "link_insertion";
        if (nextId && (prevProps.currentService && prevProps.currentService[key] && prevProps.currentService[key].id !== nextId)) {
            this.props.requestService(nextId, key);
        }
    }

    render() {
        const seoService = this.props.currentService ? this.props.currentService["link_insertion"] : null;
        const orderNumber = seoService ? seoService.order_number : "";

        if (!seoService) {
            return <Spinner/>;
        }
        else if(!seoService.data) {
            return (
                <>
                    <h1>Seo Service not found!</h1>
                </>
            );
        }

        const addOnRows = [];
        if (seoService != null && seoService.data != null && seoService.data.addOns != null) {
            if (seoService.data.addOns.length > 0) {
                for(let i = 0; i < seoService.data.addOns.length; i++) {
                    let addOnItem = seoService.data.addOns[i];
                    addOnRows.push(
                        <li>
                            {addOnItem.section_name} - {addOnItem.add_on_name}
                        </li>
                    );
                }
            }
        }

        var item_title_type = 'Link Insertion';
        if (seoService.data.local && seoService.data.local == 1) {
            item_title_type = 'BizLocal Power Links';
        } else if (seoService.data.tld || seoService.data.language) {
            item_title_type = 'Foreign Link Insertion';
        }

        return (
            <>
                <Portlet>
                    <PortletBody>
                        <Breadcrumbs aria-label="Breadcrumb" className="kt-margin-b-15">
                            <Link color="inherit" to="/admin/orders">
                                Orders
                            </Link>
                            <Link color="inherit" to={"/admin/orders/" + seoService.data.order_id + "/progress"}>
                                Order #{orderNumber}
                            </Link>
                            <Typography color="textPrimary">{item_title_type} #{seoService.data.id}</Typography>
                        </Breadcrumbs>
                        <Typography variant="h5">{item_title_type} #{seoService.data.id} (Order #{orderNumber})</Typography>
                        <ul className="kt-margin-t-10 kt-margin-b-30">
                            <li className={seoService.data.url ? "" : "d-none"}>User Website: <strong>{seoService.data.url}</strong></li>
                            <li>DA: <strong>{seoService.data.da}</strong></li>
                            <li>Organic Traffic: <strong>{seoService.data.organic_traffic} visitors</strong></li>

                            <li className={seoService.data.language ? "" : "d-none"}>Language: <strong>{seoService.data.language}</strong></li>
                            <li className={seoService.data.tld ? "" : "d-none"}>TLD: <strong>{seoService.data.tld}</strong></li>
                            
                            <li className={seoService.data.add_dfy_anchor_text ? "" : "d-none"}>Add DFY Anchor Text: <strong>{seoService.data.add_dfy_anchor_text ? "✔": "no"}</strong></li>
                            <li className={seoService.data.blog_approval ? "" : "d-none"}>Blog Approval: <strong>{seoService.data.blog_approval ? "✔": "no"}</strong></li>
                            <li className={seoService.data.advanced_niche ? "" : "d-none"}>Advanced Niche: <strong>{seoService.data.advanced_niche ? "✔": "no"}</strong></li>
                            <li className={seoService.data.notes ? "" : "d-none"}>Notes: {seoService.data.notes}</li>
                            {addOnRows.length > 0 ? 
                                <ul><li style={{fontWeight:"bold"}}>Add Ons:</li>{addOnRows}</ul> : ''}
                        </ul>

                        <Formik
                            initialValues={{
                                website: seoService.data.website ? seoService.data.website : "",
                                anchor: seoService.data.anchor ? seoService.data.anchor : "",
                                build: parseInt(seoService.data.build) === 1
                            }}
                            validate={values => {
                                const errors = {};

                                /*var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
                                    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // ip (v4) address
                                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ //port
                                    '(\\?[;&amp;a-z\\d%_.~+=-]*)?'+ // query string
                                    '(\\#[-a-z\\d_]*)?$','i');*/

                                if (!values.website/* || !pattern.test(values.website)*/) {
                                    errors.website = "Please provide valid website url.";
                                }

                                return errors;
                            }}
                            onSubmit={(values, { setStatus, setSubmitting }) => {
                                let linkInsertionData = {
                                    website: values.website,
                                    anchor: values.anchor,
                                    build: values.build ? 1 : 0
                                };
                                editLinkInsertionService(seoService.data.id, linkInsertionData)
                                    .then((res) => {
                                        setStatus(
                                            "Saved."
                                        );
                                        seoService.data = res.data;
                                        setSubmitting(false);
                                    })
                                    .catch((e) => {
                                        setSubmitting(false);
                                        let errorMsg = "Error! Data is not updated.";
                                        if(e.response.data) {
                                            if(e.response.data.error) {
                                                errorMsg = e.response.data.error;
                                            }
                                        }
                                        setStatus(
                                            errorMsg
                                        );
                                    });
                            }}
                        >
                            {({
                                  values,
                                  status,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting
                              }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    {status && !isSubmitting && (
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <div role="alert" className={'alert ' + (status === 'Saved.' ? 'alert-success' : 'alert-danger')}>
                                                    <div className="alert-text">{status}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <Typography>Website URL</Typography>
                                            <TextField
                                                type="url"
                                                name="website"
                                                className="kt-margin-b-20"
                                                value={values.website}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                margin="normal"
                                                variant="outlined"
                                                helperText={touched.website && errors.website}
                                                error={Boolean(touched.website && errors.website)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <Typography>Anchor Text</Typography>
                                            <TextField
                                                name="anchor"
                                                className="kt-margin-b-20"
                                                value={values.anchor}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                margin="normal"
                                                variant="outlined"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <Typography>Done</Typography>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.build}
                                                        onChange={handleChange}
                                                        name="build"
                                                        value={values.build}
                                                        color="primary"
                                                    />
                                                }
                                                label=""
                                            />
                                        </div>
                                    </div>
                                    <Button className="kt-mr-5" type="submit" variant="primary" onClick={handleSubmit} disabled={isSubmitting}>
                                        {!isSubmitting ? 'Submit' : (<Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />)}
                                    </Button>
                                    <Link to={"/admin/orders/" + seoService.data.order_id + "/progress"}>
                                        <Button variant="secondary">Back</Button>
                                    </Link>
                                </Form>
                            )}
                        </Formik>
                    </PortletBody>
                </Portlet>
            </>
        );
    }
}

const mapStateToProps = ({ servicesAdmin: { currentService } }) => ({
    currentService
});

export default injectIntl(connect(
    mapStateToProps,
    services.actions
)(LinkInsertionAdmin));
