import React from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";

export default class MenuList extends React.Component {
  render() {
    const { currentUrl, menuConfig, menuConfigAdmin, menuConfigBlogger, layoutConfig, isAdmin, isBlogger, isContentWriter } = this.props;

    if (typeof menuConfigAdmin !== 'undefined' && isAdmin) {
        return menuConfigAdmin.aside.items.map((child, index) => {
          return (
              <React.Fragment key={`menuList${index}`}>
                {child.section && <MenuSection item={child} />}
                {child.separator && <MenuItemSeparator item={child} />}
                {child.title && (
                    <MenuItem
                        item={child}
                        currentUrl={currentUrl}
                        layoutConfig={layoutConfig}
                    />
                )}
              </React.Fragment>
          );
        });

    } else if (typeof menuConfigBlogger !== 'undefined' && (isBlogger || isContentWriter)) {
        return menuConfigBlogger.aside.items.map((child, index) => {
          return (
              <React.Fragment key={`menuList${index}`}>
                {child.section && <MenuSection item={child} />}
                {child.separator && <MenuItemSeparator item={child} />}
                {child.title && (
                    <MenuItem
                        item={child}
                        currentUrl={currentUrl}
                        layoutConfig={layoutConfig}
                    />
                )}
              </React.Fragment>
          );
        });

    } else if (typeof menuConfig !== 'undefined') {
      return menuConfig.aside.items.map((child, index) => {
        return (
            <React.Fragment key={`menuList${index}`}>
              {child.section && <MenuSection item={child} />}
              {child.separator && <MenuItemSeparator item={child} />}
              {child.title && (
                  <MenuItem
                      item={child}
                      currentUrl={currentUrl}
                      layoutConfig={layoutConfig}
                  />
              )}
            </React.Fragment>
        );
      });
    }
  }
}
