import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as projects from "../../../store/ducks/projects.duck";
import {Spinner} from "reactstrap";
import {Table, Pagination, Form, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Typography} from "@material-ui/core";
import CreateProject from "./CreateProject";

class ProjectSelect extends Component {
    componentDidMount() {
        this.props.initProjects();
        const currentProjectId = this.props.currentProjectId;
        if (currentProjectId == 'all') {
            // this.props.requestProject(currentProjectId);
        } else if (currentProjectId > 0) {
            this.props.requestProject(currentProjectId);
        }
    }

    render() {
        const {projects, currentProjectId, currentProject, action} = this.props;

        /* 
            Also get orders to possibly populate the select with the "Unassigned" option
            If there are any orders at all then the option to show unassigned should be there (?)
        */
        const {orders} = this.props;

        if (!projects) {
            return <Spinner/>;
        }

        const rows = [];
        let paginationItems = [];

        rows.push(<option key='all' value={'all'}>All Projects</option>);
        rows.push(<option key={null} value={0}>Unassigned</option>);

        if (/*projects.meta && */projects.data) {
            let active = projects.meta.current_page;

            if (projects.data.length) {
                for (const [index] of projects.data.entries()) {
                    //let keywords = projects.data[index].keywords.split('\n').map((item, i) => <span className="d-block" key={i}>{item}</span>);
                    rows.push(<option key={index} value={projects.data[index].id}>{projects.data[index].url}</option>)
                }
            }

            /*for (let number = 1; number <= projects.meta.last_page; number++) {
                paginationItems.push(
                    <Pagination.Item key={number} active={number === active} onClick={() => {
                        this.props.initProjects(number);
                    }}>
                        {number}
                    </Pagination.Item>,
                );
            }*/
        }
        // if (orders && orders.data && orders.data.length > 0) {
            // rows.push(<option key={null} value={0}>Unassigned</option>);
        // }

        const handleProjectChange = (e) => {
            const projectId = e.target.value;
            if (projectId == 'all') {
                this.props.requestProject(projectId);
            } else if (projectId > 0) {
                this.props.requestProject(projectId);
            } else {
                this.props.requestProject(0);
            }
        };

        return (
            <>
                <Form>
                    {!this.props.compact && !this.props.hideTitle && <Typography variant="h6">Choose current project:</Typography>}
                    <Form.Row>
                        <Form.Group as={Col} className={!this.props.compact || !this.props.showProjectButtons ? "col-lg-12 col-md-12 col-12" : "col-lg-6 col-md-6 col-12"} controlId="formGridProject">
                            <Form.Control as="select" value={currentProjectId > 0 || currentProjectId == 'all' ? currentProjectId : 0} onChange={handleProjectChange}>
                                {/*<option>Choose current project:</option>*/}
                                {rows}
                            </Form.Control>
                        </Form.Group>
                        {!this.props.compact && !this.props.showProjectButtons && <Form.Group as={Col} className="col-lg-12 col-md-12 col-12" controlId="formGridCreateProject">
                            <CreateProject action={action} history={this.props.history} currentProject={currentProject} />
                        </Form.Group>}
                        {this.props.showProjectButtons && <Form.Group as={Col} className="col-lg-6 col-md-6 col-12" controlId="formGridCreateProject">
                            <CreateProject action={action} history={this.props.history} currentProject={currentProject} hideTrackingCode={this.props.showProjectButtons} newProjectRedirect={this.props.newProjectRedirect} />
                        </Form.Group>}
                    </Form.Row>
                </Form>
            </>
        );
    }
}

const mapStateToProps = ({ projects: { projects, currentProject, currentProjectId } }) => ({
    projects, currentProject, currentProjectId
});

export default injectIntl(connect(
    mapStateToProps,
    projects.actions
)(ProjectSelect));
