import React, { useState } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Tab, Tabs } from "react-bootstrap";
import EditProfile from "./profile-components/EditProfile";
import {Portlet, PortletBody} from "../../partials/content/Portlet";
import CombinedProfileSettings from "./profile-components/CombinedProfileSettings";
import ChangePassword from "./profile-components/ChangePassword";
import BillingProfile from "./profile-components/BillingProfile";
import DeleteAccount from "./profile-components/DeleteAccount";
import ProfileSettings from "./profile-components/ProfileSettings";
import AccountBalance from "./profile-components/AccountBalance";
import AccountBlogger from "./profile-components/AccountBlogger";
import {getQueryParam} from "../../custom_functions/get_query_param";
import ContentWriters from "./profile-components/ContentWriters";

function ControlledTabs() {
    const [key, setKey] = useState('balance');

    if (window.location.hash == "#billing" && key != "billing") {
        setKey("billing");
        window.location.hash = "";
    }

    return (
        <>
            <div className="row">
                <div className="col-md-10">
                    <div className="kt-section">
                        <Portlet>
                            <PortletBody>
                                <Tabs activeKey={key} onSelect={(k) => setKey(k)} id="profile-tabs">
                                    <Tab eventKey="balance" title="Balance">
                                        <AccountBalance />
                                    </Tab>
                                    {/*<Tab eventKey="profile" title="Profile">
                                        <EditProfile />
                                    </Tab>*/}
                                    <Tab eventKey="billing" title="Billing">
                                        <BillingProfile />
                                    </Tab>
                                    {/*
                                    <Tab eventKey="settings" title="Settings">
                                        <ProfileSettings />
                                    </Tab>*/}
                                    <Tab eventKey="settings" title="Settings">
                                        <CombinedProfileSettings />
                                    </Tab>
                                    <Tab eventKey="bloggers" title="Bloggers">
                                        <AccountBlogger />
                                    </Tab>
                                    <Tab eventKey="writers" title="Content Writers">
                                        <ContentWriters />
                                    </Tab>
                                    {/*<Tab eventKey="password" title="Reset Password">
                                        <ChangePassword />
                                    </Tab>
                                    <Tab eventKey="delete" title="Delete Account">
                                        <p>Your account and all information about your projects will be deleted.</p>
                                        <DeleteAccount />
                                    </Tab>*/}
                                </Tabs>
                            </PortletBody>
                        </Portlet>
                    </div>
                </div>
            </div>
        </>
    );
}

// export default class Profile extends React.Component {
//     render() {
//         return(<ControlledTabs />);
//     }
// }

class Profile extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {user} = this.props;

        return(<>
            <div className="row">
                <div className="col-12">
                    <div className="progress progressBar TmoProgressBar">
                        <div className="progress-bar" role="progressbar" aria-valuenow={(user.tmo_amount ? user.tmo_amount : 0)} aria-valuemin="0" aria-valuemax={(user.tmo_progress_bar_total ? user.tmo_progress_bar_total : 10000)} style={{width:(user.tmo_percentage ? user.tmo_percentage : 0)+'%'}}>
                            <span class="progress-label">{(user.tmo_amount ? '$'+user.tmo_amount.toLocaleString(undefined, {maximumFractionDigits:2}) : '$0.00')}</span>
                        </div>
                        <div class="first_label">This Month's Spend</div>
                        <div class="second_label">
                            <div class="vertical_line">|</div>
                            $1,000
                        </div>
                        <div class="third_label">
                            <div class="vertical_line">|</div>
                            $5,000
                        </div>
                        <div class="fourth_label">
                            <div class="vertical_line">|</div>
                            $10,000
                        </div>
                    </div>
                </div>
            </div>
            <ControlledTabs />
        </>);
    }
}

const mapStateToProps = ({ auth: { user }}) => ({
    user
});

export default injectIntl(
    connect(
        mapStateToProps,
        {}
    )(Profile)
);

