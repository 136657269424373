import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as services from "../../store/ducks/admin/services.duck";
import {Spinner} from "reactstrap";
import {Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Portlet, PortletBody} from "../../partials/content/Portlet";
import {Typography, Breadcrumbs} from "@material-ui/core";
import UPagination from "../../partials/content/Pagination";
import * as orders from "../../store/ducks/admin/orders.duck";

class ServicePagesAdmin extends Component {
    componentDidMount() {
        const { orderId, service } = this.props.match.params;
        this.props.requestServices(orderId, 1, service);
        this.props.requestOrder(orderId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { orderId, service } = this.props.match.params;
        if (orderId !== prevProps.match.params.orderId) {
            this.props.requestServices(orderId, 1, service);
        }
    }

    render() {
        const { orderId, service } = this.props.match.params;
        const {services} = this.props;
        const orderNumber = this.props.currentOrder && this.props.currentOrder.data ? this.props.currentOrder.data.order_number : "";
        const pageInfo = services && services[service] ? services[service] : null;

        const pageName = (() => {
            switch (service) {
                case 'gpb':
                    return 'GPB Optimization';
                case 'press-release':
                    return 'Press Release';
                default:
                    return 'Service Page';
            }
        })();

        if (!pageInfo) {
            return <Spinner/>;
        }
        else if(!pageInfo.data) {
            return (
                <>
                    <h1>Services not found!</h1>
                </>
            );
        }

        const rows = [];

        if (pageInfo.data) {
            let active = pageInfo.current_page ;
            let perPage = pageInfo.per_page;

            if (pageInfo.data.length) {
                for (const [index] of pageInfo.data.entries()) {
                    let url = "/admin/orders/service-page/" + service + "/" + pageInfo.data[index].id;
                    rows.push(<tr key={index}>
                        <td>{perPage * (active - 1) + index + 1}</td>
                        <td>{pageInfo.data[index].package}</td>
                        <td>{pageInfo.data[index].url}</td>
                        <td>{pageInfo.data[index].done ? 'Yes' : 'No'}</td>
                        <td className="text-center">
                            {!pageInfo.data[index].url && <Link className="btn btn-success" to={url}>Submit Order Details</Link>}
                            {pageInfo.data[index].url && <Link className="btn btn-primary" to={url}>Show Order Details</Link>}
                        </td>
                    </tr>);
                }
            }
        }

        const handleNextPage = (number) => {
            this.props.requestServices(orderId, number, service);
        };

        return(
            <>
                <Portlet>
                    <PortletBody>
                        <Breadcrumbs aria-label="Breadcrumb" className="kt-margin-b-15">
                            <Link color="inherit" to="/admin/orders">
                                Orders
                            </Link>
                            <Link color="inherit" to={"/admin/orders/" + orderId + "/progress"}>
                                Order #{orderNumber}
                            </Link>
                            <Typography color="textPrimary">{pageName}</Typography>
                        </Breadcrumbs>
                        <Typography variant="h5">{pageName} (Order #{orderNumber})</Typography>
                        <div className="row">
                            <div className="col-xl-6 col-lg-9">
                                <Table striped bordered hover responsive className="kt-mt-10">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Package</th>
                                        <th>URL</th>
                                        <th>Done</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {rows}
                                    </tbody>
                                </Table>
                                {pageInfo.last_page > 1 && <UPagination currentPage={pageInfo.current_page} totalPages={pageInfo.last_page} onChange={handleNextPage} />}
                            </div>
                        </div>
                    </PortletBody>
                </Portlet>
            </>
        );
    }
}


const mapStateToProps = ({ servicesAdmin: { services }, ordersAdmin: { currentOrder } }) => ({
    services, currentOrder
});

export default injectIntl(connect(
    mapStateToProps,
    {...services.actions, ...orders.actions}
)(ServicePagesAdmin));
