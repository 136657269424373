import React, {Component} from "react";
import {Button, Modal, Table} from "react-bootstrap";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as onpage from "../../../store/ducks/onpage.duck";
import {Spinner} from "reactstrap";
import UPagination from "../../../partials/content/Pagination";

class PageDetails extends Component {
    getByErrorKey(errorKey, page) {
        let showRelatedPages = false;
        let metric = "";
        let metricValue = "";

        if (errorKey === "have_page_duplicates") {
            showRelatedPages = true;
        } else if (errorKey === "have_h1_duplicates") {
            metric = "H1 Title:";
            metricValue = page.h1;
            showRelatedPages = true;
        }
        else if (errorKey === "title_short") {
            metric = "Title";
            metricValue = <span><span className="kt-font-bold kt-font-danger">{page.title}</span> [{page.title_length}/30] is too short.</span>;
        }
        else if (errorKey === "title_long") {
            metric = "Title";
            metricValue = <span><span className="kt-font-bold kt-font-danger">{page.title}</span> [{page.title_length}/65] is too long.</span>;
        }
        else if (errorKey === "meta_description_short") {
            metric = "Meta Description";
            metricValue = <span><span className="kt-font-bold kt-font-danger">{page.meta_description}</span> [{page.meta_description_length}/50] is too short.</span>;
        }
        else if (errorKey === "meta_description_long") {
            metric = "Meta Description";
            metricValue = <span><span className="kt-font-bold kt-font-danger">{page.meta_description}</span> [{page.meta_description_length}/160] is too long.</span>;
        }
        else if (errorKey === "have_title_duplicates") {
            metric = "Title:";
            metricValue = page.title;
            showRelatedPages = true;
        }
        else if (errorKey === "time_total_load") {
            metric = "Time Total Load";
            metricValue = <span><span className="kt-font-bold kt-font-danger">{page.time_total_load} ms</span> is too slow (>3s).</span>;
        }
        else if (errorKey === "have_meta_description_duplicates") {
            metric = "Meta Description:";
            metricValue = page.meta_description;
            showRelatedPages = true;
        }
        else if (errorKey === "links_broken") {
            metric = "Broken Links:";
            metricValue = page.links_broken + " links";
            showRelatedPages = true;
        }
        else if (errorKey === "absent_h1_tags") {
            metric = "Page without H1 title";
            metricValue = "";
        }
        else if (errorKey === "title_empty") {
            metric = "Missing Title";
            metricValue = "";
        }
        else if (errorKey === "meta_description_empty") {
            metric = "Missing Description";
            metricValue = "";
        }
        else if (errorKey === "content_readability_flesh_kincaid") {
            metric = "Bad Content Readability:";
            metricValue = page.content_readability_flesh_kincaid;
        }
        else if (errorKey === "have_doctype") {
            metric = "Absent Doctype";
            metricValue = "";
        }
        else if (errorKey === "have_enc_meta_tag") {
            metric = "Absent Encoding Meta Tag";
            metricValue = "";
        }
        else if (errorKey === "images_invalid_alt") {
            metric = "Invalid Image Alt:";
            metricValue = page.images_invalid_alt + " images";
            showRelatedPages = true;
        }
        else if (errorKey === "images_invalid_title") {
            metric = "Invalid Image Title:";
            metricValue = page.images_invalid_title + " images";
            showRelatedPages = true;
        }
        else if (errorKey === "links_from") {
            metric = "Links From Page:";
            metricValue = (page.links_internal + page.links_external) + " links";
            showRelatedPages = true;
        }
        else if (errorKey === "links_internal") {
            metric = "Internal Links:";
            metricValue = page.links_internal + " links";
            showRelatedPages = true;
        }
        else if (errorKey === "links_external") {
            metric = "External Links:";
            metricValue = page.links_external + " links";
            showRelatedPages = true;
        }

        return {metric, metricValue, showRelatedPages};
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {page, errorKey, projectId} = this.props;

        if(prevProps.errorKey !== errorKey || prevProps.page.page_id !== page.page_id || prevProps.projectId !== projectId) {
            const {showRelatedPages} = this.getByErrorKey(errorKey, page);
            const pageNum = 1;

            if (showRelatedPages) {
                this.props.requestRelatedPages(projectId, pageNum, errorKey, page.page_id);
            }
        }
    }

    render() {
        const {page, label, errorKey, relatedPages, projectId} = this.props;
        const {metric, metricValue, showRelatedPages} = this.getByErrorKey(errorKey, page);

        const pagesRows = [];
        let isPagesLoading = false;
        if(showRelatedPages && !relatedPages) {
            isPagesLoading = true;
        }
        else if(showRelatedPages && relatedPages) {
            if(relatedPages.data) {
                if(relatedPages.data.data && relatedPages.data.data.length !== 0) {
                    if(errorKey === "links_broken" || errorKey === "links_internal" || errorKey === "links_external" || errorKey === "links_from") {
                        for (const [index] of relatedPages.data.data.entries()) {
                            const item = relatedPages.data.data[index];
                            pagesRows.push(<tr key={item._id}>
                                <td><a href={item.link_to} target="_blank">{item.link_to}</a></td>
                                <td>{item.anchor}</td>
                            </tr>);
                        }
                    }
                    else if(errorKey === "images_invalid_alt" || errorKey === "images_invalid_title") {
                        for (const [index] of relatedPages.data.data.entries()) {
                            const item = relatedPages.data.data[index];
                            pagesRows.push(<tr key={item._id}>
                                <td>{item.src}</td>
                                <td>{item.title ? item.title : item.alt}</td>
                            </tr>);
                        }
                    }
                    else {
                        pagesRows.push(<tr key={page._id}>
                            <td><a href={page.address_full} target="_blank">{page.address_full}</a></td>
                            <td>{page.title}</td>
                        </tr>);
                        for (const [index] of relatedPages.data.data.entries()) {
                            const item = relatedPages.data.data[index];
                            pagesRows.push(<tr key={item._id}>
                                <td><a href={item.address_full} target="_blank">{item.address_full}</a></td>
                                <td>{item.title}</td>
                            </tr>);
                        }
                    }
                }
            }
        }
        const handleNextRelPage = (activePage) => {
            this.props.requestRelatedPages(projectId, activePage, errorKey, page.page_id);
        };

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {label}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!showRelatedPages && <p><strong>Page URL:</strong> <a href={page.address_full} target="_blank">{page.address_full}</a><br/>
                        <strong>Page Title:</strong> {page.title}</p>}
                    {metricValue && <p>{metric} {metricValue}</p>}
                    {(showRelatedPages && pagesRows.length !== 0) && <div>
                        <Table responsive>
                            <thead>
                            <tr>
                                <th>URL</th><th>Title</th>
                            </tr>
                            </thead>
                            <tbody style={{wordBreak:"break-word"}}>
                            {pagesRows}
                            </tbody>
                        </Table>
                        {(relatedPages && relatedPages.data && relatedPages.data.last_page > 1) && <UPagination currentPage={relatedPages.data.current_page} totalPages={relatedPages.data.last_page} onChange={handleNextRelPage} />}
                    </div>}
                    {isPagesLoading && <Spinner />}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onHide}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = ({onpage: { relatedPages } }) => ({
    relatedPages
});

export default injectIntl(
    connect(
        mapStateToProps,
        onpage.actions
    )(PageDetails)
);
