import React, { Component } from "react";
import {ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Portlet, PortletBody, PortletHeader} from "../partials/content/Portlet";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
    'faq-title': {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: '600',
    },
});

class MarketplaceSubscriptionInfo extends Component {
    render() {
        const { classes } = this.props;

        return (
            <>
                <div className="row">
                    <div className="col-lg-12 kt-mb-15">
                        <Portlet>
                            <PortletHeader title="Frequently Asked Questions" />
                            <PortletBody className="p-0">
                                {/* <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography className={classes["faq-title"]}>How does this service work?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>You sign up to a monthly or annual subscription first; then, you get access to our exclusive guest post marketplace. You'll have to option to place unlimited orders on our platform.</p>
                                            <p>And the best part? You pay affordable rates for the guest post with no high markups. We will update the marketplace with new sites every week.</p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel> */}
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography className={classes["faq-title"]}>What are the placement fees?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>The placement fee is a combination of the blogger's asking price, content creation and the processing fee. The fees range between $130 to $999, depending on the blogger's price. If you provide your own articles, then $50 per post will be subtracted from the total order.</p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                {/* <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panelza-content"
                                        id="panelza-header"
                                    >
                                        <Typography className={classes["faq-title"]}>Why would I pay a subscription to get access to the marketplace?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>Because even with a subscription, you will still save hundreds, if not thousands of dollars in link costs. You will also have exclusive access to high-quality sites that need great content published.</p>
                                            <p>We have rates that range between $130 - $999 per post with content included. You can save even more money when you provide your own content.</p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel> */}
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3a-content"
                                        id="panel3a-header"
                                    >
                                        <Typography className={classes["faq-title"]}>Can I provide my own content?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>Yes, you can provide your own content. Simply choose that option on the Checkout form, and we will subtract a $50 content fee per guest post from your total.</p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel4a-content"
                                        id="panel4a-header"
                                    >
                                        <Typography className={classes["faq-title"]}>What is the turnaround time?</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>We can get your articles placed between 1 to 20+ business days, depending on the size of your order, the blogger's response time and special requests.</p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </PortletBody>
                        </Portlet>
                    </div>
                </div>
            </>
        );
    }
}

export default withStyles(styles)(MarketplaceSubscriptionInfo);
