import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as marketplace from "../../../store/ducks/marketplace.duck";
import {Spinner} from "reactstrap";
import {Table, Badge} from "react-bootstrap";
import {Portlet, PortletBody, PortletHeader} from "../../../partials/content/Portlet";
import {Link} from "react-router-dom";
import {Icon} from "@material-ui/core";
import UPagination from "../../../partials/content/Pagination";
import {Button} from "react-bootstrap";
import EditBlogModal from "./EditBlogModal";
import AddBlogModal from "./AddBlogModal";

class AccountBlogger extends Component {
     constructor(props) {
        super(props);

        let sending = false;
        let guestPostId = 0;
        let counterOfferRowAmount = 0;

        this.state = {
            sending: sending,
            guestPostId: guestPostId,
            counterOfferRowAmount: counterOfferRowAmount,
            openAddBlogModal: false,
            openEditBlogModal: false,
            guestPostItem: "",
        };
    }

    componentDidMount() {
        // this.props.initMarketplace(1, {});
        this.props.requestMyBlogs();
        // let sending = false;
        // let guestPostId = 0;
        // let counterOfferRowAmount = 0;
        // this.state = {
        //     sending: sending,
        //     guestPostId: guestPostId,
        //     counterOfferRowAmount: counterOfferRowAmount
        // };
    }

    render() {

        const { guestPosts } = this.props;
        let sending = this.props.sending;

        if (!guestPosts) {
            return <Spinner/>;
        }

        const moneyFormatter = (num) => {
            if (typeof num !== 'undefined') {
                try {
                    var calc = num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('.00','');
                    // var calc = num.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    return '$'+calc;
                } catch (e) {
                    console.log('moneyFormatter',e);
                    return '-';
                }
            }
            return '$'+num;
        }

        const rows = [];

        const addBlogModalClose = (e) => {
            this.setState({...this.state, openAddBlogModal: false});
        }

        const editBlogModalClose = (e) => {
            this.setState({...this.state, openEditBlogModal: false});
        }

        this.openAddBlogModal = (e) => {
            this.setState({...this.state, openAddBlogModal: true});
        }

        this.handleEditModal = (e) => {
            e.preventDefault();
            let guest_post_id = e.target.name;

            let item;
            for (const [index] of guestPosts.data.entries()) {
                if (guestPosts.data[index].id == guest_post_id) {
                    item = guestPosts.data[index];
                }
            }
            
            this.setState({
                ...this.state,
                sending: false,
                openEditBlogModal: true,
                guestPostId: guest_post_id,
                guestPostItem: item
            });
        };

        if (guestPosts.data) {
            if (guestPosts.data.length) {
                for (const [index] of guestPosts.data.entries()) {
                    let guest_post_price = 0;
                    if (typeof guestPosts.data[index] !== 'undefined' && typeof guestPosts.data[index].price !== 'undefined') {
                        guest_post_price = moneyFormatter(guestPosts.data[index].price);
                    }
                    rows.push(<tr key={index}>
                        <td>{guestPosts.data[index].id}</td>
                        <td>{guestPosts.data[index].url}</td>
                        <td>{guestPosts.data[index].category_name}</td>
                        <td>{guest_post_price}</td>
                        <td>{guestPosts.data[index].dr}</td>
                        <td>{guestPosts.data[index].organic_traffic}</td>
                        {/* <td>{guestPosts.data[index].meta_verification_code == "Y" ? <i className="fa fa-check text-success"></i> : <i className="fa fa-times text-danger"></i>}</td> */}
                        <td><Badge pill variant={guestPosts.data[index].status_color}>{guestPosts.data[index].status_title}</Badge></td>
                        <td>
                            <Button disabled={this.state.sending} className="btn-sm" name={guestPosts.data[index].id} onClick={(e) => this.handleEditModal(e)}>Edit</Button>
                        </td>
                    </tr>)
                }
            }
        }

        // const handleNextPage = (number) => {
        //     this.props.initOffers(number);
        // };
        // {offers.meta && offers.meta.last_page > 1 && <UPagination currentPage={offers.meta.current_page} totalPages={offers.meta.last_page} onChange={handleNextPage} />}

        return (
            <Portlet>
                <AddBlogModal open={this.state.openAddBlogModal} onClose={addBlogModalClose} />
                <EditBlogModal open={this.state.openEditBlogModal} onClose={editBlogModalClose} guestPostItem={this.state.guestPostItem} />
                <PortletHeader title="My Blogs" />
                <Button disabled={this.state.sending} style={{top:"-40px",position:"relative"}} className="ml-auto btn-sm" onClick={(e) => this.openAddBlogModal(e)}>Add a Blog</Button>
                <PortletBody className="pt-0 mt-0">
                    <Table striped bordered hover responsive>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Site</th>
                            <th>Category</th>
                            <th>Price</th>
                            <th>DR</th>
                            <th>Organic Traffic</th>
                            {/* <th>Verified</th> */}
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </Table>



                </PortletBody>
            </Portlet>
        );
    }
}

const mapStateToProps = ({ marketplace: { guestPosts } }) => ({
    guestPosts
});

export default injectIntl(connect(
    mapStateToProps,
    marketplace.actions
)(AccountBlogger));
