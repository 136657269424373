import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {put, takeEvery, takeLatest} from "redux-saga/effects";
import {
    getLinkCleanUpService,
    getLinkCleanUpServices,
    getLinkInsertionServices,
    getLinkInsertionService,
    getCitationServices,
    getCitationService,
    getPressReleaseServices,
    getPressReleaseService,
    getGPBServices,
    getGPBService,
    getArticleServices,
    getArticleService
} from "../../../crud/admin/orders.crud";

export const actionTypes = {
    ServicesInit: "[Init Services Admin] Action",
    ServicesRequested: "[Request Services Admin] Action",
    ServicesLoaded: "[Load Services Admin] Orders API",
    ServiceRequested: "[Request Service Admin] Action",
    ServiceLoaded: "[Load Service Admin] Orders API"
};

const initialServicesState = {
    services: undefined,
    currentService: undefined
};

export const reducer = persistReducer(
    { storage, key: "admin-orders", whitelist: ["services", "currentService"] },
    (state = initialServicesState, action) => {
        switch (action.type) {
            case actionTypes.ServicesInit: {
                return { services: {}, currentService: {} };
            }

            case actionTypes.ServicesRequested: {
                const { key } = action.payload;

                return { ...state, services: {...state.services, [key]: undefined} };
            }

            case actionTypes.ServicesLoaded: {
                const { services, key } = action.payload;

                return { ...state, services: {...state.services, [key]: services} };
            }

            case actionTypes.ServiceRequested: {
                const { key } = action.payload;

                return { ...state, currentService: {...state.currentService, [key]: undefined} };
            }

            case actionTypes.ServiceLoaded: {
                const { currentService, key } = action.payload;

                return { ...state, currentService: {...state.currentService, [key]: currentService} };
            }

            default:
                return state;
        }
    }
);

export const actions = {
    initServices: (orderId, page, key, params) => ({ type: actionTypes.ServicesInit, payload: { orderId, page, key, params } }),
    requestServices: (orderId, page, key, params) => ({ type: actionTypes.ServicesRequested, payload: { orderId, page, key, params } }),
    fulfillServices: (services, key) => ({ type: actionTypes.ServicesLoaded, payload: { services, key } }),
    requestService: (id, key) => ({ type: actionTypes.ServiceRequested, payload: { id, key } }),
    fulfillService: (currentService, key) => ({ type: actionTypes.ServiceLoaded, payload: { currentService, key } })
};

export function* saga() {
    yield takeLatest(actionTypes.ServicesInit, function* initServicesSaga(action) {
        const { orderId, page, key, params } = action.payload;

        yield put(actions.requestServices(orderId, page, key, params));
    });

    yield takeEvery(actionTypes.ServicesRequested, function* servicesRequested(action) {
        const { orderId, page, key, params } = action.payload;
        try {
            if(key === "link_clean_up") {
                const {data: services} = yield getLinkCleanUpServices(orderId, page, params);
                yield put(actions.fulfillServices(services, key));
            }
            else if(key === "link_insertion") {
                const {data: services} = yield getLinkInsertionServices(orderId, page, params);
                yield put(actions.fulfillServices(services, key));
            }
            else if(key === "citation") {
                const {data: services} = yield getCitationServices(orderId, page, params);
                yield put(actions.fulfillServices(services, key));
            }
            else if(key === "press-release") {
                const {data: services} = yield getPressReleaseServices(orderId, page, params);
                yield put(actions.fulfillServices(services, key));
            }
            else if(key === "gpb") {
                const {data: services} = yield getGPBServices(orderId, page, params);
                yield put(actions.fulfillServices(services, key));
            }
            else if(key === "article") {
                const {data: services} = yield getArticleServices(orderId, page, params);
                yield put(actions.fulfillServices(services, key));
            }
            else {
                yield put(actions.fulfillServices({data: undefined}, key));
            }
        }
        catch (e) {
            yield put(actions.fulfillServices({data: undefined}, key));
        }
    });

    yield takeEvery(actionTypes.ServiceRequested, function* serviceRequested(action) {
        const { id, key } = action.payload;
        try {
            if(key === "link_clean_up") {
                const {data: currentService} = yield getLinkCleanUpService(id);
                yield put(actions.fulfillService(currentService, key));
            }
            else if(key === "link_insertion") {
                const {data: currentService} = yield getLinkInsertionService(id);
                yield put(actions.fulfillService(currentService, key));
            }
            else if(key === "citation") {
                const {data: currentService} = yield getCitationService(id);
                yield put(actions.fulfillService(currentService, key));
            }
            else if(key === "press-release") {
                const {data: currentService} = yield getPressReleaseService(id);
                yield put(actions.fulfillService(currentService, key));
            }
            else if(key === "gpb") {
                const {data: currentService} = yield getGPBService(id);
                yield put(actions.fulfillService(currentService, key));
            }
            else if(key === "article") {
                const {data: currentService} = yield getArticleService(id);
                yield put(actions.fulfillService(currentService, key));
            }
            else {
                yield put(actions.fulfillService({data: undefined}, key));
            }
        }
        catch (e) {
            yield put(actions.fulfillService({data: undefined}, key));
        }
    });
}
