import React, {Component} from "react";
import {Portlet, PortletBody, PortletHeader} from "../../../../partials/content/Portlet";
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default class ManagedSeoInfo extends Component {
    render() {
        return (<>
            <Portlet>
                <PortletHeader title="Frequently Asked Questions" />
                <PortletBody className="p-0">
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography style={{fontSize:16,fontWeight:"bold"}}>How long does it take to see results?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                <p>It depends on your competition, previous SEO work, monthly allocated budget and your current sSEO optimization. For established sites that need an extra push, we can get results in as little as 2 weeks. For starter sites, it can take a few months until you see organic traffic from our SEO work.</p>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography style={{fontSize:16,fontWeight:"bold"}}>Do you have case studies of previous projects?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                <p>Yes, <a href="https://www.serppro.io/case-studies" target="_blank" rel="noopener nofollow">click on this page</a> to check out all of our case studies.</p>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography style={{fontSize:16,fontWeight:"bold"}}>What does the process of a managed SEO service look like?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                <p>
                                    Step 1: Fill In Our Form<br/>
                                    Step 2: Full Site Audit<br/>
                                    Step 3: On-Page & Technical Optimization<br/>
                                    Step 4: Monthly Content Creation<br/>
                                    Step 5: Linkbuilding<br/>
                                    Step 6: Reporting
                                </p>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography style={{fontSize:16,fontWeight:"bold"}}>What is the turnaround time of deliverables?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                <p>
                                    Anywhere between 20 to 30 days, depending on your allocated SEO budget.
                                </p>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography style={{fontSize:16,fontWeight:"bold"}}>What kind of backlinks are you going to build?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                <p>
                                    We'll build citations and social profiles to establish you as an authoritative source for your industry. Then we will launch blogger outreach campaigns where they publish content on niche-relevant websites that are relevant to what you do, all while optimizing the articles with SEO so people can find them easily online.
                                </p>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography style={{fontSize:16,fontWeight:"bold"}}>Can I approve the topics and content?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                <p>
                                    Yes, you can approve the topics and content before we start writing and publishing them on your site.
                                </p>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography style={{fontSize:16,fontWeight:"bold"}}>Are the article SEO-optimized?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                <p>
                                    Yes, we optimize each article so it ranks for relevant keywords. Before we write, we research your keywords and create topics that are engaging and informational for your audience.
                                </p>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography style={{fontSize:16,fontWeight:"bold"}}>Do you offer revisions?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                <p>
                                    Yes, we offer unlimited revisions for each article.
                                </p>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </PortletBody>
            </Portlet>
        </>);
    }
}
