import React, { Component,useState,useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import {Portlet, PortletBody} from "../../partials/content/Portlet";
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';

import * as auth from "../../../app/store/ducks/auth.duck";
import * as pagedata from "../../store/ducks/page.duck";
import * as projects from "../../store/ducks/projects.duck";
import * as marketplace from "../../store/ducks/marketplace.duck";
import * as cart from "../../store/ducks/cart.duck";

import {Form,Col,Card,Button} from "react-bootstrap";
import {Typography,List,ListItem,ListItemText} from "@material-ui/core";
import {Spinner} from "reactstrap";

import {toAbsoluteUrl} from "../../../_metronic";
import {Popover, Icon} from "@material-ui/core";
import DomainInfo from "./marketplace/DomainInfo";
import {Link} from "react-router-dom";


var d_hold = null;
var d_prev = null;
var h_hold = null;
var h_prev = null;
var m_hold = null;
var m_prev = null;
var s_hold = null;
var s_prev = null;
function WloCountdownTimer({ dateTo, dateNow }) {
    const [seconds, setTimer] = useState(((dateTo - dateNow) / 1000));
    useEffect(() => {
        const timeout = setTimeout(() => setTimer(seconds - 1), 1000);
        if (seconds <= 0) clearTimeout(timeout);
        return () => {
            clearTimeout(timeout);
        };
    }, [seconds]);

    var seconds_holder = seconds;
    var days_in_seconds = Math.floor(seconds / 86400);
    seconds_holder = seconds_holder-(days_in_seconds*86400);
    var d = days_in_seconds.toString(); //.padStart(2, "0");
    var h = Math.floor(seconds_holder / 3600).toString(); //.padStart(2, "0");
    var m = Math.floor((seconds_holder % 3600) / 60).toString(); //.padStart(2, "0");
    var s = Math.floor(seconds_holder % 60).toString(); //.padStart(2, "0");

    if (d.length == 1) d = '0'+d;
    if (h.length == 1) h = '0'+h;
    if (m.length == 1) m = '0'+m;
    if (s.length == 1) s = '0'+s;

    var text_color = '#333';
    if (seconds < 86400) text_color = '#ff0000';

    ////////////////////////////////////////////////////////
    // START -> days animation
    if (d_hold == null) {
        d_hold = d;
    } else if (d != d_prev) {
        d_prev = d_hold;
        d_hold = d;
    }
    if (d_prev != d_hold) {
        var days_section = document.getElementById('js-days');
        if (days_section != null) {
            var newDigit = document.createElement('span');
            newDigit.className = 'new';
            newDigit.textContent = d;
            days_section.appendChild(newDigit);
        }
        var el_one = document.querySelector('#js-days .current');
        if (el_one != null) {
            el_one.classList.add('old');
            el_one.classList.remove('current');
        }
        setTimeout(function() {
            var el_old = document.querySelector('#js-days .old');
            if (el_old != null) el_old.remove();
            var el_two = document.querySelector('#js-days .new');
            if (el_two != null) {
                el_two.classList.add('current');
                el_two.classList.remove('new');
            }
        },900);
    }
    // END -> days animation
    // START -> hours animation
    if (h_hold == null) {
        h_hold = h;
    } else if (h != h_prev) {
        h_prev = h_hold;
        h_hold = h;
    }
    if (h_prev != h_hold) {
        var hours_section = document.getElementById('js-hours');
        if (hours_section != null) {
            var newDigit = document.createElement('span');
            newDigit.className = 'new';
            newDigit.textContent = h;
            hours_section.appendChild(newDigit);
        }
        var el_one = document.querySelector('#js-hours .current');
        if (el_one != null) {
            el_one.classList.add('old');
            el_one.classList.remove('current');
        }
        setTimeout(function() {
            var el_old = document.querySelector('#js-hours .old');
            if (el_old != null) el_old.remove();
            var el_two = document.querySelector('#js-hours .new');
            if (el_two != null) {
                el_two.classList.add('current');
                el_two.classList.remove('new');
            }
        },900);
    }
    // END -> hours animation
    // START -> minutes animation
    if (m_hold == null) {
        m_hold = m;
    } else if (m != m_prev) {
        m_prev = m_hold;
        m_hold = m;
    }
    if (m_prev != m_hold) {
        var minutes_section = document.getElementById('js-minutes');
        if (minutes_section != null) {
            var newDigit = document.createElement('span');
            newDigit.className = 'new';
            newDigit.textContent = m;
            minutes_section.appendChild(newDigit);
        }
        var el_one = document.querySelector('#js-minutes .current');
        if (el_one != null) {
            el_one.classList.add('old');
            el_one.classList.remove('current');
        }
        setTimeout(function() {
            var el_old = document.querySelector('#js-minutes .old');
            if (el_old != null) el_old.remove();
            var el_two = document.querySelector('#js-minutes .new');
            if (el_two != null) {
                el_two.classList.add('current');
                el_two.classList.remove('new');
            }
        },900);
    }
    // END -> minutes animation
    // START -> seconds animation
    if (s_hold == null) {
        s_hold = s;
    } else {
        s_prev = s_hold;
        s_hold = s;
    }
    if (s_prev != s_hold) {
        var seconds_section = document.getElementById('js-seconds');
        if (seconds_section != null) {
            var newDigit = document.createElement('span');
            newDigit.className = 'new';
            newDigit.textContent = s;
            seconds_section.appendChild(newDigit);
        }
        var el_one = document.querySelector('#js-seconds .current');
        if (el_one != null) {
            el_one.classList.add('old');
            el_one.classList.remove('current');
        }
        setTimeout(function() {
            var el_old = document.querySelector('#js-seconds .old');
            if (el_old != null) el_old.remove();
            var el_two = document.querySelector('#js-seconds .new');
            if (el_two != null) {
                el_two.classList.add('current');
                el_two.classList.remove('new');
            }
        },900);
    }
    // END -> seconds animation
    ////////////////////////////////////////////////////////

    if (seconds > 0) {
        return (
            <section id="countdown-container" className="countdown-container">
                <article id="js-countdown" className="countdown" style={{color:text_color}}>
                    <section id="js-days" className="number"><span class="current">{d}</span></section>
                    <section id="js-separator" className="separator">:</section>
                    <section id="js-hours" className="number"><span class="current">{h}</span></section>
                    <section id="js-separator" className="separator">:</section>
                    <section id="js-minutes" className="number"><span class="current">{m}</span></section>
                    <section id="js-separator" className="separator">:</section>
                    <section id="js-seconds" className="number"><span class="current">{s}</span></section>
                </article>
            </section>
        )
    } else {
        return (
            <div style={{color:"#FF0000",fontSize:"16px"}}>
                <div>Cycle Ended</div>
                <div>Check Back Soon</div>
            </div>
        )
    };
}


class WeeklyLinkOpportunities extends Component {
    constructor(props) {
        super(props);

        this.state = {
            viewType:'grid',
            diPopoverId: undefined,
            domainInfoOpen: false,
            anchorEl: null,
            stepsEnabled: false,
            unselected: [],
        }
    }
    componentDidMount() {
        this.props.requestUser();
        this.props.requestPageData('weekly_link_opportunities');
        this.props.initProjects();
        const currentProjectId = this.props.currentProjectId;
        if (currentProjectId != null && currentProjectId > 0) {
            var filter = {};
            filter['project_id'] = currentProjectId;
            filter['type'] = 'weekly_link_opportunities';
            this.props.initMarketplace(1,filter);
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
       
    }

    render() {
        const {user,pageData,projects,currentProjectId,currentProject,guestPosts,cartItems} = this.props;

        let article_price = 0;
        if (typeof guestPosts !== 'undefined' && typeof guestPosts.article_price !== 'undefined') {
            if (guestPosts.article_price > 0) article_price = guestPosts.article_price;
        }
        if (typeof user !== 'undefined' && typeof user.article_price !== 'undefined') {
            if (user.article_price > 0) article_price = user.article_price;
        }
        // console.log('article_price',article_price);

        const kFormatter = (num) => {
            return Math.abs(num) > 9999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(0)) + 'k' : Math.sign(num)*Math.abs(num);
        }

        const moneyFormatter = (num) => {
            if (typeof num !== 'undefined') {
                try {
                    var calc = num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('.00','');
                    // var calc = num.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    return '$'+calc;
                } catch (e) {
                    console.log('moneyFormatter',e);
                    return '-';
                }
            }
            return '$'+num;
        }

        var wlo_mgps_in_cart = 0;
        if (typeof this.props.cartItems !== 'undefined') {
            wlo_mgps_in_cart = this.props.cartItems.filter(item => item.key === 'wlo_mpgs').length !== 0;
        }


        let s_project_niche = '';
        let s_project_keywords = '';
        let s_project_related_keywords = '';
        const project_options = [];
        if (! projects) {
            return <Spinner/>;
        } else if (projects.data && projects.data.length && currentProjectId != null && currentProjectId > 0) {
            for (const [index] of projects.data.entries()) {
                project_options.push(<option key={index} value={projects.data[index].id}>{projects.data[index].url}</option>)
                if (projects.data[index].id == currentProjectId) {
                    if (typeof projects.data[index].niche !== 'undefined' && projects.data[index].niche != null) {
                        s_project_niche = projects.data[index].niche;
                    }
                    if (typeof projects.data[index].keywords !== 'undefined' && projects.data[index].keywords != null) {
                        s_project_keywords = projects.data[index].keywords;
                    }
                    if (typeof projects.data[index].related_keywords !== 'undefined' && projects.data[index].related_keywords != null) {
                        s_project_related_keywords = projects.data[index].related_keywords;
                    }
                }
            }
        }
        const handleProjectChange = (e) => {
            this.props.removeCartItem('wlo_mpgs');

            const projectId = e.target.value;
            if (projectId > 0) {
                this.props.requestProject(projectId);
                var filter = {};
                filter['project_id'] = projectId;
                filter['type'] = 'weekly_link_opportunities';
                this.props.initMarketplace(1,filter);
                this.setState({
                    ...this.state,
                    unselected: []
                });
            }
        };

        const toggleSelectionCheckbox = (mgpId) => {
            this.props.removeCartItem('wlo_mpgs');

            var elCheckbox = document.getElementById('toggle_selection_checkbox_'+mgpId);
            if (elCheckbox != null) {
                var unselected_holder = this.state.unselected;
                if (elCheckbox.checked) {
                    // uncheck
                    // elCheckbox.checked = false;
                    unselected_holder.push(mgpId);
                } else {
                    // check
                    // elCheckbox.checked = true;
                    var index = unselected_holder.indexOf(mgpId);
                    unselected_holder.splice(index, 1);
                }
                this.setState({
                    ...this.state,
                    unselected: unselected_holder
                });
            }
        }


        const handleDIOpen = (e) => {
            e.preventDefault();
            const id = e.target.id ? e.target.id.substr(2) : null;
            if(id) {
                this.setState({...this.state, domainInfoOpen: true, anchorEl: e.currentTarget, diPopoverId: "di" + id});
            }
        };
        const handleDIClose = (e) => {
            e.preventDefault();
            this.setState({...this.state, domainInfoOpen: false, anchorEl: null, diPopoverId: undefined});
        };


        var checked_bg_color = '#ceeae9'; //'#E0F2F1';
        var checked_icon_color = '#8bc24b';
        var unchecked_bg_color = '#f2e0e1';
        var unchecked_icon_color = '#d5636a'; // '#d78186'; // '#dbaaad';
        var discountPercentage = 0;
        var discountAmount = 0;
        var calculatedPrice = 0;
        var mgp_selected = 0;
        const gp_rows = [];
        const gp_rows_right = [];

        if (user.system_weekly_reset_started == 1) {
            gp_rows.push(
            <div className={"kt-mb-30" + (this.state.viewType === "grid" ? " col-lg-12 col-md-12 col-sm-12" : "")}>
                <div style={{fontSize:'20px',marginBottom:'15px',fontWeight:'500'}}>Weekly cycle ended.</div>
                <div style={{fontSize:'20px',marginBottom:'15px'}}>The new cycle is generating. Check back shortly to see new link opportunities.</div>
            </div>);

        } else if (user.weekly_link_opportunities_used == 1) {
            gp_rows.push(
            <div className={"kt-mb-30" + (this.state.viewType === "grid" ? " col-lg-12 col-md-12 col-sm-12" : "")}>
                <div style={{fontSize:'20px',marginBottom:'15px',fontWeight:'500'}}>Thank you for your order.</div>
                <div style={{fontSize:'20px',marginBottom:'15px'}}>Check back for more link opportunities after this weekly cycle.</div>
            </div>);

        } else if (typeof projects === 'undefined' || typeof projects.data === 'undefined' || projects.data.length == 0) {
            gp_rows.push(
            <div className={"kt-mb-30" + (this.state.viewType === "grid" ? " col-lg-12 col-md-12 col-sm-12" : "")}>
                <div style={{fontSize:'20px',marginBottom:'15px'}}>A project is required to see Weekly Link Opportunities.</div>
                <Link className="btn btn-primary" to="/project/add" style={{width:'fit-content'}}>Create Project</Link>
            </div>);

        } else if (! guestPosts || ! guestPosts.data) {
            gp_rows.push(<div key="load1" className="col-lg-4 col-md-6 col-sm-12"><Spinner/></div>);
            gp_rows_right.push(<div key="load2" className=""><Spinner/></div>);

        } else if (guestPosts && guestPosts.data && guestPosts.data.length == 0) {
            gp_rows.push(
            <div className={"kt-mb-30" + (this.state.viewType === "grid" ? " col-lg-12 col-md-12 col-sm-12" : "")}>
                <div className="alert alert-danger" style={{fontSize:'18px',marginBottom:'15px'}}>No matching guest posts found. Please contact support, this shouldn't happen.</div>
            </div>);

        } else if (guestPosts && guestPosts.data && guestPosts.data.length) {
            for (const [index] of guestPosts.data.entries()) {

                let domain_url = '';
                if (guestPosts.data[index] != "" && guestPosts.data[index]) {
                    if (guestPosts.data[index].url && guestPosts.data[index].url != "") {
                        // let domain_splitter = guestPosts.data[index].url.split('.');
                        // domain_star = domain_splitter[0].replace(/./g, '*');
                        domain_url = guestPosts.data[index].url;
                    }
                }

                var is_checked = true;
                if (this.state.unselected.includes(guestPosts.data[index].id)) is_checked = false;

                var checked_bg_color = '#ceeae9'; //'#E0F2F1';
                var checked_icon_color = '#8bc24b';
                var unchecked_bg_color = '#f2e0e1';
                var unchecked_icon_color = '#d5636a'; // '#d78186'; // '#dbaaad';

                var select_bg_color = checked_bg_color;
                var text_decoration = 'none';
                if (is_checked == false) {
                    select_bg_color = unchecked_bg_color;
                    var text_decoration = 'line-through';
                }

                if (is_checked == true) {
                    calculatedPrice = calculatedPrice + (guestPosts.data[index].price + article_price);
                    mgp_selected++;
                }

                gp_rows_right.push(
                    <div className="d-block" style={{maginBottom:'8px'}}>
                        <div className="d-flex">
                            <div>
                                {(is_checked == true ?
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check-filled" width="26" height="26" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" style={{color:checked_icon_color}}><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" stroke-width="0" fill="currentColor"></path></svg>
                                :
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-x-filled" width="26" height="26" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" style={{color:unchecked_icon_color}}><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" stroke-width="0" fill="currentColor"></path></svg>
                                )}
                            </div>
                            <div style={{marginLeft:'8px',fontSize:'18px',fontWeight:'500',textDecoration:text_decoration}}>{domain_url}</div>
                        </div>
                        <div className="d-flex">
                            <div></div>
                            <div className="ml-auto" style={{fontSize:'18px',textDecoration:text_decoration}}>
                                ${Math.trunc(
                                    (guestPosts.data[index].price + article_price)
                                )}
                            </div>
                        </div>
                    </div>
                );
                
                gp_rows.push(
                <div key={index} className={"kt-mb-30" + (this.state.viewType === "grid" ? " wlo_col col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-12" : "")}>
                    <Portlet id={"ms-item-" + index}>
                        <PortletBody
                            className={(this.state.viewType !== "grid" ? "mkt-list-row-height flex-sm-row justify-content-between" : "") + " p-0"}>
                            <div className={"mkt-list-row-height ml-img-col d-flex align-items-center"}>

                                {/* {(this.state.viewType == "grid" ? 
                                    <div className="ml-category-label" style={{ width:"100%", background:"#ecf3fe", color:"#000" }}>{categories["c" + guestPosts.data[index].category_id]}</div>
                                : "")}  */}
                                <div className="ml-category-label d-flex" style={{ width:"100%",background:select_bg_color,color:"#000",cursor:"pointer" }} onClick={() => {toggleSelectionCheckbox(guestPosts.data[index].id)}}>
                                    <div>
                                        {(is_checked == true ?
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check-filled" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" style={{color:checked_icon_color}}><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" stroke-width="0" fill="currentColor"></path></svg>
                                        :
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-x-filled" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" style={{color:unchecked_icon_color}}><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" stroke-width="0" fill="currentColor"></path></svg>
                                        )}
                                    </div>
                                    <div className="ml-auto">
                                        <div style={{width:'20px',height:'20px',cursor:'pointer',background:'transparent',position:'absolute',top:'8px',right:'10px',zIndex:'5px'}}>
                                            <input type="checkbox" id={'toggle_selection_checkbox_'+guestPosts.data[index].id} style={{width:'20px',height:'20px',position:'relative',zIndex:'1px',pointerEvents:'none'}} checked={is_checked == true ? 'checked' : ''} value={guestPosts.data[index].id ? guestPosts.data[index].id : 0} />
                                        </div>
                                    </div>
                                </div>

                                <img className="ml-img"
                                src={guestPosts.data[index].image ? guestPosts.data[index].image : toAbsoluteUrl("/media/company-logos/placeholder_website.png")} 
                                alt="" />
                            </div>

                            {(this.state.viewType == "grid" ? 
                                <div className="text-center" style={{ fontSize: "120%", marginTop: "20px" }}>
                                    {/* {onWatchlist == true ? <i className="fa fa-heart watchlist_toggle" style={{color:"red"}} onClick={() => {handleUnwatch(guestPosts.data[index].id);}}></i> : <i className="far fa-heart watchlist_toggle" onClick={() => {handleWatch(guestPosts.data[index].id);}}></i>} {domain_url} */}
                                    {domain_url}
                                </div> 
                            : "")} 
                            {(this.state.viewType == "grid" ? 
                                <div className="text-center" style={{marginTop:"10px"}}>
                                    <Icon className="fa fa-info-circle" id={"gp" + guestPosts.data[index].id} aria-describedby={"di" + guestPosts.data[index].id} onClick={handleDIOpen} style={{fontSize: "1rem",cursor: "pointer"}} />
                                </div> 
                            : "")} 

                            <div className="ml-table-col d-flex align-items-center pt-2 pb-2"
                                style={{fontSize: 18}}
                            >
                                <table id="marketplace-table" className="table table-borderless ml-table text-center mb-0" style={{tableLayout: "fixed"}}>
                                    <tbody>
                                        <tr>
                                            {(this.state.viewType !== "grid" ? 
                                                <td style={{ width: this.state.viewType !== "grid" ? '35%': ''}}>URL</td>
                                            : "")}
                                            {/* {(this.state.viewType !== "grid" ? 
                                                <td style={{ width:'20%' }}>Category</td>
                                            : "")}   */}
                                            {/* <td style={{ width: this.state.viewType === "grid" ? '17%': ''}}>TLD</td> */}
                                            <td style={{ width: this.state.viewType !== "grid" ? '60px': '25%'}}>DA</td>
                                            <td style={{ width: this.state.viewType !== "grid" ? '60px': '25%'}}>DR</td>
                                            {/* <td style={{ width: this.state.viewType !== "grid" ? '60px': ''}}>RD</td> */}
                                            <td style={{ width: this.state.viewType === "grid" ? '25%': ''}}>Traffic</td>
                                            <td style={{ width:'25%'
                                                // width: typeof userDiscountPercent !== 'undefined' && typeof userDiscountPercent.marketplace_guest_post !== 'undefined' && userDiscountPercent.marketplace_guest_post > 0 && this.state.viewType === "grid" ? '25%': this.state.viewType === "grid"  ? '14%' : '',
                                            }}>
                                                {/* {typeof userDiscountPercent !== 'undefined' && typeof userDiscountPercent.marketplace_guest_post !== 'undefined' && userDiscountPercent.marketplace_guest_post && userDiscountPercent.marketplace_guest_post > 0 ?
                                                    'Sale Price'
                                                    :
                                                    'Price'
                                                } */}
                                                Price
                                            </td>
                                            {/* <td></td> */}
                                        </tr>
                                        <tr>
                                            {(this.state.viewType !== "grid" ? 
                                                <td>{domain_url}</td>
                                            : "")}   
                                            {/* {(this.state.viewType !== "grid" ? 
                                                <td>{categories["c" + guestPosts.data[index].category_id]}</td>
                                            : "")}    */}
                                            {/* <td>{guestPosts.data[index].tld}</td> */}
                                            <td>{guestPosts.data[index].da}</td>
                                            <td>{guestPosts.data[index].dr}</td>
                                            {/* <td>{kFormatter(guestPosts.data[index].rd)}</td> */}
                                            <td>{kFormatter(guestPosts.data[index].organic_traffic)}</td>
                                            {/* <td>${Math.trunc(
                                                (guestPosts.data[index].price + article_price)
                                            )}</td> */}
                                            <td>{moneyFormatter(guestPosts.data[index].price + article_price)}</td>
                                            {/* {(this.state.viewType !== "grid" ? 
                                                <td>
                                                    <Icon className="fa fa-info-circle" id={"gp" + guestPosts.data[index].id} aria-describedby={"di" + guestPosts.data[index].id} onClick={handleDIOpen} style={{fontSize: "1rem",cursor: "pointer" }} />
                                                </td>
                                            : "")} */}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </PortletBody>
                    </Portlet>
                </div>);
            }
        }

        let user_discount_html = [];
        if (user && user.weekly_link_opportunities_discounts && user.weekly_link_opportunities_discounts.length) {

            var width = 100 / user.weekly_link_opportunities_discounts.length;
            var width_p = width+'%';
            
            for (const [index] of user.weekly_link_opportunities_discounts.entries()) {
                var xWloD = user.weekly_link_opportunities_discounts[index];

                var opacity = 0.25;
                var circle_bg_color = unchecked_icon_color;
                if (mgp_selected >= xWloD.wlo_int_value) {
                    opacity = 1;
                    circle_bg_color = checked_bg_color;
                }

                user_discount_html.push(
                <div style={{width:width_p,textAlign:'center'}}>
                    <div style={{fontSize:'24px',fontWeight:'500'}}>{xWloD.wlo_int_value}+</div>
                    <div style={{padding:'10px',fontSize:'18px;',background:circle_bg_color,borderRadius:'50px',width:'90px',height:'90px',margin:'0 auto',display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',opacity:opacity}}>
                        <div className="d-block" style={{fontSize:'28px',fontWeight:'500',lineHeight:'28px'}}>{xWloD.wlo_str_value}</div>
                        <div style={{lineHeight:'1',position:'relative',top:'6px'}}>off</div>
                    </div>
                </div>);

                if (mgp_selected >= xWloD.wlo_int_value) {
                    discountPercentage = xWloD.wlo_str_value.replace('%','');
                }
            }
        }

        if (discountPercentage > 0) {
            discountAmount = (discountPercentage/100)*calculatedPrice;
            calculatedPrice = calculatedPrice-discountAmount;
        }


        const handleAddToCart = (calculatedPrice,discountAmount,discountPercentage) => {
            var atc_desc = '';
            var a_site_ids = [];
            for (const [index] of guestPosts.data.entries()) {
                if (this.state.unselected.includes(guestPosts.data[index].id)) continue;

                a_site_ids.push(guestPosts.data[index].id);

                if (atc_desc != '') atc_desc += ', ';
                atc_desc += guestPosts.data[index].url;

                // price = price + guestPosts.data[index].price;
                // mgp_selected++;
            }

            const item = {
                // key: "mgp_" + id,
                key: 'wlo_mpgs',
                price: Number(calculatedPrice),
                discount_amount: Number(discountAmount),
                discount_percentage: discountPercentage,
                name: "Weekly Link Opportunities",
                description: atc_desc,
                orderData: {
                    subscription: 0,
                    quantity: 1,
                    type: "wlo_mpgs",
                    options: {
                        // site_id: id,
                        // marketplace_article: 1,
                        site_ids: a_site_ids,
                    },
                    discount_amount: Number(discountAmount),
                    add_ons: [
                    //     {
                    //         add_on_id: add_on_id,
                    //         add_on_name: add_on_name,
                    //         add_on_price: add_on_price
                    //     }
                    ],
                }
            };

            ReactGA.event({
                category: 'Cart',
                action: 'add_to_cart',
                label: 'Add to Cart',
                nonInteraction: false
            });
            ReactPixel.track("Add_to_cart", item);

            this.props.addToCart(item);
            this.props.showCartOnClick();
        };


        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="progress progressBar TmoProgressBar">
                            <div className="progress-bar" role="progressbar" aria-valuenow={(user.tmo_amount ? user.tmo_amount : 0)} aria-valuemin="0" aria-valuemax={(user.tmo_progress_bar_total ? user.tmo_progress_bar_total : 10000)} style={{width:(user.tmo_percentage ? user.tmo_percentage : 0)+'%'}}>
                                <span className="progress-label">{(user.tmo_amount ? '$'+user.tmo_amount.toLocaleString(undefined, {maximumFractionDigits:2}) : '$0.00')}</span>
                            </div>
                            <div className="first_label">This Month's Spend</div>
                            <div className="second_label">
                                <div className="vertical_line">|</div>
                                $1,000
                            </div>
                            <div className="third_label">
                                <div className="vertical_line">|</div>
                                $5,000
                            </div>
                            <div className="fourth_label">
                                <div className="vertical_line">|</div>
                                $10,000
                            </div>
                        </div>
                    </div>
                </div>
                {pageData != null ?
                    <Portlet className="" style={{background:"#f2f3f8", boxShadow:"none"}}>
                        <div className="row" style={{marginLeft:0,marginRight:0}}>
                            <div className="col-lg-8 col-12" style={{paddingLeft:0,paddingRight:0}}>
                                <Portlet className="h-100 kt-mb-0 marketplace_left_blue" style={{background:"#ecf3fe"}}>
                                    <PortletBody style={{paddingBottom:'0px'}} dangerouslySetInnerHTML={{ __html: pageData ? pageData.top_left_text : "" }} />
                                    <PortletBody style={{paddingTop:'0px'}}>
                                        {typeof projects === 'undefined' || typeof projects.data === 'undefined' || projects.data.length == 0 ?
                                        <>
                                            
                                        </>
                                        :
                                        <>
                                            <Form>
                                                <Form.Row>
                                                    <Form.Group as={Col} className="col-lg-12 col-md-12 col-12" controlId="formGridProject" style={{marginBottom:'10px'}}>
                                                        <Form.Control as="select" value={currentProjectId > 0 ? currentProjectId : 0} onChange={handleProjectChange} style={{width:'fit-content',marginBottom:'0px'}}>
                                                            {project_options}
                                                        </Form.Control>
                                                        {currentProjectId > 0 ?
                                                            <Link className="" to={'/project-edit/'+currentProjectId} style={{width:'fit-content',fontSize:'12px'}}>Edit project</Link>
                                                        : ''}
                                                    </Form.Group>
                                                </Form.Row>
                                            </Form>
                                            <div style={{fontSize:'14px',marginLeft:'3px'}}>
                                                <div style={{marginBottom:'10px'}}>
                                                    <label style={{fontWeight:'bold',marginBottom:'0px'}}>Niche</label>
                                                    <div>{s_project_niche}</div>
                                                </div>
                                                <div style={{marginBottom:'10px'}}>
                                                    <label style={{fontWeight:'bold',marginBottom:'0px'}}>Keywords</label>
                                                    <div>{s_project_keywords}</div>
                                                </div>
                                                <div style={{marginBottom:'10px'}}>
                                                    <label style={{fontWeight:'bold',marginBottom:'0px'}}>Related Keywords</label>
                                                    <div>{s_project_related_keywords}</div>
                                                </div>
                                            </div>
                                        </>}
                                    </PortletBody>
                                </Portlet>
                            </div>
                            <div className="col-lg-4 col-12" style={{paddingLeft:0,paddingRight:0}}>
                                <Portlet className="h-100 kt-mb-0 marketplace_right_green" style={{background:"#e7eff3"}}>
                                    <PortletBody style={{paddingBottom:'0px'}} dangerouslySetInnerHTML={{ __html: pageData ? pageData.top_right_text : "" }} />
                                    <PortletBody style={{paddingTop:'0px'}}>
                                        <WloCountdownTimer dateTo={new Date(user.weekly_link_opportunities_cycle_expiration).getTime()} dateNow={new Date(user.weekly_link_opportunities_cycle_time_now).getTime()} />
                                    </PortletBody>
                                </Portlet>
                            </div>
                        </div>
                    </Portlet>
                    :
                ''}
               
                <div className="row wlo_card_row" style={{marginLeft:0,marginRight:0}}>
                    <div className="col-lg-8 col-12" style={{paddingLeft:0,paddingRight:0}}>
                        <div className={"mv-" + this.state.viewType + (this.state.viewType === "grid" ? " row" : "") + " mb-5 wlo_container_mr"}>
                            {gp_rows}
                            <Popover
                                id={this.state.diPopoverId}
                                open={this.state.domainInfoOpen}
                                anchorEl={this.state.anchorEl}
                                onClose={handleDIClose}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                            >
                                <DomainInfo id={this.state.diPopoverId ? this.state.diPopoverId.substr(2) : null} />
                            </Popover>
                        </div>
                    </div>

                    <div className="col-lg-4 col-12" style={{paddingLeft:0,paddingRight:0}}>
                        {user.weekly_link_opportunities_used == 1 ? ''
                        :
                        <div className="wlo_container_ml">
                            <Card>
                                <Card.Body style={{paddingBottom:'0px'}}>

                                    <div className="d-flex" style={{marginBottom:'1.25rem'}}>{user_discount_html}</div>

                                    <Card.Title className="mb-4">Guest Post Package</Card.Title>

                                    {gp_rows_right}

                                </Card.Body>
                                <hr/>
                                <Card.Body style={{paddingTop:'0px'}}>
                                    
                                    {discountAmount > 0 ?
                                        <>
                                            <div className="d-block" style={{paddingTop:'5px'}}>
                                                <Typography className="kt-font-danger" style={{fontSize:'18px',fontWeight:'500',marginBottom:'2px'}}>Discount</Typography>
                                                {/* <Typography className="kt-font-danger" variant="h5" style={{fontSize:'28px',fontWeight:'500'}}>-${discountAmount.toFixed(2)}</Typography> */}
                                                <Typography className="kt-font-danger" variant="h5" style={{fontSize:'28px',fontWeight:'500'}}>-{moneyFormatter(discountAmount)}</Typography>
                                            </div>
                                            <div className="d-block" style={{marginTop:'15px'}}>
                                                <Typography style={{fontSize:'18px',fontWeight:'500',marginBottom:'2px'}}>Your Investment</Typography>
                                            </div>
                                        </>
                                    :
                                        <div className="d-block" style={{marginTop:'5px'}}>
                                            <Typography style={{fontSize:'18px',fontWeight:'500',marginBottom:'2px'}}>Your Investment</Typography>
                                        </div>}
                                    {/* <Typography variant="h5" style={{fontSize:'28px',fontWeight:'500'}}>${calculatedPrice.toFixed(2)}</Typography> */}
                                    <Typography variant="h5" style={{fontSize:'28px',fontWeight:'500'}}>{moneyFormatter(calculatedPrice)}</Typography>

                                    <div className="d-block" style={{marginTop:'16px'}}>
                                        <Button className="btn-block" size="lg" variant="warning" disabled={calculatedPrice === 0 || wlo_mgps_in_cart} onClick={() => {handleAddToCart(calculatedPrice,discountAmount,discountPercentage);}}>
                                            Add to Cart
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                            <img className=" mt-4" src="https://serppro.nyc3.cdn.digitaloceanspaces.com/public/secure%20badge.png" alt="" style={{maxWidth:"255px"}} />
                        </div>}
                    </div>
                </div>
            </>
        );

    }
}
const mapStateToProps = ({ auth:{user}, pagedata:{pageData}, projects:{projects,currentProject,currentProjectId}, marketplace: {guestPosts}, cart:{cartItems} }) => ({
    user,pageData,projects,currentProject,currentProjectId,guestPosts, cartItems
});

export default injectIntl(connect(
    mapStateToProps,
    {...pagedata.actions, ...auth.actions, ...projects.actions, ...marketplace.actions, ...cart.actions}
)(WeeklyLinkOpportunities));