import axios from "axios";

export const base = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL : "";
export const API_PACKAGES_DATA_URL = base + "/api/packages";
export const API_USER_PACKAGES_DATA_URL = base + "/api/user_packages";
export const API_SUBSCRIPTIONS_URL = base + "/api/subscriptions";
export const API_STRIPE_URL = base + "/api/stripe";

export function getPackages(type) {
    return axios.get(API_PACKAGES_DATA_URL + "/get_packages/" + type);
}

export function getPackage(type) {
    return axios.get(API_PACKAGES_DATA_URL + "/get_package/" + type);
}

export function addFreePackage(type) {
    return axios.post(API_PACKAGES_DATA_URL + "/add_free_package/" + type);
}

export function getUserPackages(orderId, page = null) {
    return axios.get(API_USER_PACKAGES_DATA_URL + "?order_id=" + orderId + (page > 1 ? "&page=" + page : ""));
}

export function getUserPackage(id) {
    return axios.get(API_USER_PACKAGES_DATA_URL + "/" + id);
}

export function cancelSubscription(id) {
    return axios.post(API_SUBSCRIPTIONS_URL + "/cancel/" + id);
}

export function stripeSubscribe(data) {
    return axios.post(API_STRIPE_URL + "/subscribe", data);
}

export function stripeRetryInvoice(data) {
    return axios.post(API_STRIPE_URL + "/retry-invoice", data);
}

export function stripeGetPriceId(data) {
    return axios.post(API_STRIPE_URL + "/get-price-id", data);
}
