import React from "react";
import {Button, ButtonToolbar} from "react-bootstrap";
import CreateProjectModal from "./CreateProjectModal";
import DeleteProject from "./DeleteProject";
import ManageProjectModal from "../analytics-components/ManageProjectModal";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as projects from "../../../store/ducks/projects.duck";
import {Link} from "react-router-dom";

class CreateProject extends React.Component {
    constructor(...args) {
        super(...args);
        const {action} = this.props;
        this.state = { modalShow: action === "add", modalTrackingShow: false };
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.action !== this.props.action) {
            const {action} = this.props;
            this.setState({modalShow: action === "add"});
        }
    }

    render() {
        const project = this.props.currentProject;

        let modalClose = () => {
            this.setState({ modalShow: false });
            if(this.props.action === "add") {
                this.props.history.push({
                    pathname: "/projects"
                });
            }
        };

        const modalTrackingClose = () => {this.setState({ modalTrackingShow: false })};
        const modalTrackingOpen = () => {this.setState({ modalTrackingShow: true })};

        return (
            <ButtonToolbar className="justify-content-center">

                {project && project.data && project.data.id ?
                    <Link className="btn btn-success kt-mb-5" to={'/project-edit/'+project.data.id}>Edit Project</Link>
                    :
                ''}

                <DeleteProject />

                <Link className="btn btn-primary kt-mb-5" to={"/project/add" + (this.props.newProjectRedirect ? "?redirect=" + encodeURIComponent(this.props.newProjectRedirect) : "")}>Create Project</Link>
                {/*<Button
                    className="kt-mb-5-mobile kt-ml-5 kt-mr-5"
                    variant="primary"
                    onClick={() => this.setState({ modalShow: true })}
                >
                    Create Project
                </Button>

                <CreateProjectModal
                    show={this.state.modalShow}
                    onHide={modalClose}
                    history={this.props.history}
                    action={this.props.action}
                />*/}

                {/*project && project.data && <Link className="btn btn-success kt-mb-5 kt-ml-5 kt-mr-5" to="/project/add?step=2">Get Tracking Code</Link>*/}
                {/*project && project.data && !this.props.hideTrackingCode && <Button variant="success" className="kt-mb-5 kt-ml-5 kt-mr-5" onClick={modalTrackingOpen}>Get Tracking Code</Button>*/}
                {project && project.data && !this.props.hideTrackingCode && <ManageProjectModal
                    show={this.state.modalTrackingShow}
                    currentProject={project}
                    onHide={modalTrackingClose}
                />}
            </ButtonToolbar>
        );
    }
}

const mapStateToProps = ({ projects: { currentProject, currentProjectId } }) => ({
    currentProject, currentProjectId
});

export default injectIntl(connect(
    mapStateToProps,
    projects.actions
)(CreateProject));
