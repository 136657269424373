import React from "react";
import * as cart from "../../store/ducks/cart.duck";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import { withRouter } from "react-router-dom";

class SaleTimer extends React.Component {
    constructor() {
        super();
        this.state = {
            path: undefined
        };
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.location && this.state.path !== this.props.location.pathname) {
            this.setState({...this.state, path:this.props.location.pathname});
        }
    }

    render() {
        const { discountDate, currentDate } = this.props;

        if(!discountDate || !currentDate) {
            return "";
        }

        let discountType = "";
        const path = this.state.path;
        if(path === "/orders/guest-post-dfy") {
            discountType = "guest_post";
        }
        else if(path === "/seo-services/link-insertion") {
            discountType = "link_insertion";
        }

        if(!discountType) {
            return "";
        }

        const discountDateObj = discountDate && discountDate[discountType] ? Date.parse(discountDate[discountType]) : "";
        const currentDateObj = currentDate ? Date.parse(currentDate) : "";
        let dateDiff = 0;
        if(discountDateObj > currentDateObj) {
            dateDiff = discountDateObj - currentDateObj;
        }
        const daysLeft = dateDiff > 0 ? Math.round(dateDiff / (1000 * 3600 * 24)) : 0;

        return (<div className={"kt-header__topbar-item kt-header__sale-timer align-self-center d-none d-md-flex kt-ml-5 kt-mr-5"}>
            {daysLeft > 0 && <div className="kt-header__topbar-wrapper align-self-center text-danger" style={{cursor:"default"}}>
                Sale Ends in {daysLeft} days
            </div>}
        </div>);
    }
}

const mapStateToProps = ({ cart: { discountDate, currentDate } }) => ({
    discountDate, currentDate
});

export default withRouter((injectIntl(connect(
    mapStateToProps,
    null
)(SaleTimer))));
