import React from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import Instant from "./Instant";
import BloggerRegistration from "./blogger/Registration";
import WriterRegistration from "./writer/Registration";

export default function AuthPage() {
  return (
      <>
        <div className="kt-grid kt-grid--ver kt-grid--root">
          <div id="kt_login" className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1" style={{background:"#2c5dbf"}}>
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile" style={{background:"#2c5dbf"}}>
              <div className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
                style={{background:"#2c5dbf",paddingTop:"20px",paddingBottom:"20px",paddingLeft:"20px",paddingRight:"20px"}}>
                <div className="kt-grid__item" style={{background:"#2c5dbf"}}>
                  <Link to="/" className="kt-login__logo">
                    {/* <img
                        alt="Logo"
                        src={toAbsoluteUrl("/media/logos/logo-4.png")}
                    /> */}
                    <img alt="SERPpro" src="https://serppro.nyc3.cdn.digitaloceanspaces.com/public%2FSERPpro%2520White%2520Logo-p-500.png" width="200" />
                  </Link>
                </div>
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver" style={{background:"#2c5dbf",textAlign:"center"}}>
                  <div className="kt-grid__item kt-grid__item--middle" style={{background:"#2c5dbf",textAlign:"center",color:"#FFF",width:"100%"}}>
                    {/* <h3 className="kt-login__title" style={{color:"#FFF"}}>Welcome to SERP&nbsp;Pro</h3>
                    <h4 className="kt-login__subtitle" style={{color:"#FFF"}}>
                        The campaign management platform for every SEO marketer.<br/><br/>
                        Launch your SEO campaigns quickly and manage your orders with a bird's eye view.
                    </h4> */}
                    <h2 style={{fontWeight:"400",lineHeight:"1.6",fontSize:"2.2rem",maxWidth:"400px",margin:"0 auto"}}>
                      {/* <div>Outsource & Save Time</div>
                      <div>Deliver Results Quickly</div>
                      <div>Get More Clients</div> */}
                      {/*<div>Boost Your Visibility & Get Top-Tier Publications Today</div>*/}
                      <div>The Road To Scaling Your Digital Agency Starts Here</div>
                    </h2>
                  </div>
                </div>
                <div className="kt-grid__item kt-hidden-tablet-and-mobile">
                  <div className="kt-login__info">
                    <div className="kt-login__copyright" style={{color:"#fff"}}>
                      &copy; {(new Date().getFullYear()).toString()} SERPpro
                    </div>
                    {/* <div className="kt-login__menu">
                      <a href="https://www.serpchampion.com/privacy-policy/" className="kt-link" target="_blank" rel="noopener noreferrer">
                        Privacy Policy
                      </a>
                      <a href="https://www.serpchampion.com/terms-and-conditions/" className="kt-link" target="_blank" rel="noopener noreferrer">
                        Terms and Conditions
                      </a>
                      <a href="https://www.serpchampion.com/cookie-policy/" className="kt-link" target="_blank" rel="noopener noreferrer">
                        Cookie Policy
                      </a> */}
                      {/* <Link to="/auth/blogger/registration" className="kt-link kt-ml-5 kt-mr-5">
                          Sign up as a blogger
                      </Link>
                      <Link to="/auth/writer/registration" className="kt-link kt-ml-5 kt-mr-5">
                          Sign up as a content writer
                      </Link> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>

              <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
                <Switch>
                  <Route path="/auth/login" component={Login} />
                  <Route path="/auth/registration" component={Registration} />
                  <Route path="/auth/blogger/registration" component={BloggerRegistration} />
                  <Route path="/auth/writer/registration" component={WriterRegistration} />
                  <Route
                      path="/auth/forgot-password"
                      component={ForgotPassword}
                  />
                  <Route
                      path="/auth/reset-password/:token"
                      component={ResetPassword}
                  />
                  <Route path="/auth/instant/:email" component={Instant} />
                  <Route path="/auth/instant" component={Instant} />
                  <Redirect from="/auth" exact={true} to="/auth/login" />
                  <Redirect to="/auth/login" />
                </Switch>
                  <div className="kt-grid__item kt-visible-tablet-and-mobile">
                      <div className="kt-login__info text-center">
                          <div className="kt-login__copyright">
                              &copy; {(new Date().getFullYear()).toString()} SERPpro
                          </div>
                          {/* <div className="kt-login__menu">
                              <a href="https://www.serpchampion.com/privacy-policy/" className="kt-link kt-ml-5 kt-mr-5" target="_blank" rel="noopener noreferrer">
                                  Privacy Policy
                              </a>
                              <a href="https://www.serpchampion.com/terms-and-conditions/" className="kt-link kt-ml-5 kt-mr-5" target="_blank" rel="noopener noreferrer">
                                  Terms and Conditions
                              </a>
                              <a href="https://www.serpchampion.com/cookie-policy/" className="kt-link kt-ml-5 kt-mr-5" target="_blank" rel="noopener noreferrer">
                                  Cookie Policy
                              </a> */}
                              {/* <Link to="/auth/blogger/registration" className="kt-link kt-ml-5 kt-mr-5">
                                  Sign up as a blogger
                              </Link>
                              <Link to="/auth/writer/registration" className="kt-link kt-ml-5 kt-mr-5">
                                  Sign up as a content writer
                              </Link> */}
                          {/* </div> */}
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}
