import React from "react";

export default class MenuSection extends React.Component {
  render() {
    const { item } = this.props;

    let custom_id = '';
    let custom_class = 'kt-menu__section';
    if (typeof item["custom-class"] !== 'undefined') {
      custom_class += ' '+item["custom-class"];
      custom_id = item["custom-class"];
    }
    // console.log('custom_class',custom_class);
    return (
      <li className={custom_class} id={custom_id} data-open="n">
        <h4 className="kt-menu__section-text">{item.section}</h4>
        <i className="kt-menu__section-icon flaticon-more-v2" />
      </li>
    );
    // return (
    //   <li className="kt-menu__section">
    //     <h4 className="kt-menu__section-text">{item.section}</h4>
    //     <i className="kt-menu__section-icon flaticon-more-v2" />
    //   </li>
    // );
  }
}
