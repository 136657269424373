export default {
  header: {
    self: {},
    items: [
      {
        title: "Place Order",
        root: true,
        alignment: "left",
        toggle: "click",
        page: "orders",
        submenu: [
          {
            title: "Guest Post Packages",
            bullet: "dot",
            icon: "flaticon-cart",
            page: "orders/guest-post-dfy"
          },
          {
            title: "Guest Post Marketplace",
            bullet: "dot",
            icon: "flaticon-cart",
            page: "marketplace"
          },
        ]
      },
      {
        title: "Marketplace",
        root: true,
        alignment: "left",
        page: "marketplace",
      },
    ]
  },
  aside: {
    self: {},
    items: [
        {
            title: "My Projects",
            root: true,
            page: "dashboard",
            bullet: "dot"
        },
        /*
        {
            title: "My Projects",
            root: true,
            page: "projects",
            bullet: "dot"
        },
        */
        {
            title: "My Sales",
            root: true,
            page: "sales",
            bullet: "dot"
        },
        {
            title: "My Orders",
            root: true,
            page: "orders",
            bullet: "dot"
        },
        {
            title: "My Profile",
            root: true,
            page: "profile",
            bullet: "dot",
        },
        {
            title: "My Blogs",
            root: true,
            page: "blogs",
            bullet: "dot"
        },
        {
            title: "My Favorites",
            root: true,
            page: "favorites",
            bullet: "dot"
        },
        /*
        {
            title: "Help Center",
            root: false,
            page: "https://serppro.io/help-center",
            bullet: "dot",
            target: "_blank",
            "external-link": "https://serppro.io/help-center",
        },
        */
        {
            root: true,
            section: "Marketplace"
        },
        // {
        //     title: "Link Discount Packages",
        //     root: true,
        //     page: "weekly-link-opportunities",
        //     bullet: "dot",
        // },
        // {
        //     title: "AI Backlink Matcher",
        //     root: true,
        //     page: "weekly-link-opportunities",
        //     bullet: "dot",
        // },
        /*
        {
            title: "Campaign Wizard",
            root: true,
            page: "orders/new",
            bullet: "dot",
        },
        */
        // {
        //     title: "Business Citations",
        //     root: true,
        //     page: "seo-services/citation",
        //     bullet: "dot",
        // },
        {
            title: "PR Marketplace",
            root: true,
            page: "marketplace",
            bullet: "dot",
        },
        /*{
            title: "Digital PR",
            root: true,
            page: "digital",
            bullet: "dot",
        },*/
        {
            title: "Digital PR",
            root: false,
            page: "https://app.serppro.io/order/DRQXVM",
            bullet: "dot",
            target: "_blank",
            "external-link": "https://app.serppro.io/order/DRQXVM",
        },
        {
            title: "Link Gap Audit",
            root: false,
            page: "https://app.serppro.io/order/0VZYRY",
            bullet: "dot",
            target: "_blank",
            "external-link": "https://app.serppro.io/order/0VZYRY",
        },
        {
            title: "Blogger Outreach",
            root: true,
            page: "orders/guest-post-dfy",
            bullet: "dot",
        },
        {
            title: "Link Insertions",
            root: true,
            page: "seo-services/link-insertion",
            bullet: "dot",
        },
        {
            title: "Press Release",
            root: true,
            page: "seo-services/press-release",
            bullet: "dot",
        },
        {
            title: "GPB Optimization",
            root: true,
            page: "seo-services/gpb-optimization",
            bullet: "dot",
        },
        // {
        //     title: "Backlink Cleanup",
        //     root: true,
        //     bullet: "dot",
        //     page: "seo-services/link-clean-up"
        // },
        // {
        //     title: "Local SEO Links",
        //     root: true,
        //     page: "seo-services/local-links",
        //     bullet: "dot",
        // },
        // {
        //     title: "Foreign Guest Posts",
        //     root: true,
        //     page: "orders/foreign-guest-posts",
        //     bullet: "dot",
        // },
        // {
        //     title: "Foreign Link Inserts",
        //     root: true,
        //     page: "seo-services/foreign-link-insertion",
        //     bullet: "dot",
        // },
        // {
        //     title: "On-Page SEO Service",
        //     root: true,
        //     page: "seo-services/on-page-optimization",
        //     bullet: "dot",
        // },
        // {
        //     title: "Domain Marketplace",
        //     root: true,
        //     page: "domains",
        //     bullet: "dot",
        // },

        {
            root: true,
            section: "Coming Soon",
            'custom-class': "sop-checklist-button",
        },
        {
            title: "PR AI Backlink Matcher",
            root: true,
            page: "",
            bullet: "dot",
            'custom-class': "sop-checklist-dropdown",
        },

        /*{
            root: true,
            section: "SOP Checklists",
            'custom-class': "sop-checklist-button",
        },
        {
            title: "SEO Onboarding Checklist",
            root: true,
            page: "checklists/onboarding",
            bullet: "dot",
            'custom-class': "sop-checklist-dropdown",
        },
        {
            title: "Google AlgoProof Checklist",
            root: true,
            page: "checklists/algoproof",
            bullet: "dot",
            'custom-class': "sop-checklist-dropdown",
        },
        {
            title: "On-Page SEO Checklist",
            root: true,
            page: "checklists/on-page",
            bullet: "dot",
            'custom-class': "sop-checklist-dropdown",
        },
        {
            title: "Blogger Checklist",
            root: true,
            page: "checklists/blogger",
            bullet: "dot",
            'custom-class': "sop-checklist-dropdown",
        },
        {
            title: "Ultimate Guest Post Checklist",
            root: true,
            page: "checklists/ultimate-guest-post",
            bullet: "dot",
            wrap: true,
            'custom-class': "sop-checklist-dropdown",
        },
        {
            title: "Off-Page SEO Ranking Factors",
            root: true,
            page: "checklists/off-page-seo-ranking-factors",
            bullet: "dot",
            wrap: true,
            'custom-class': "sop-checklist-dropdown",
        },*/

        // {
        //     title: "SOP Checklists",
        //     root: true,
        //     bullet: "dot",
        //     'custom-class': "fw-bold",
        //     toggle:"tabs",
        //     submenu: [
        //         {
        //             title: "SEO Onboarding Checklist",
        //             root: true,
        //             page: "checklists/onboarding",
        //             bullet: "dot",
        //         },
        //         {
        //             title: "Google AlgoProof Checklist",
        //             root: true,
        //             page: "checklists/algoproof",
        //             bullet: "dot",
        //         },
        //         {
        //             title: "On-Page SEO Checklist",
        //             root: true,
        //             page: "checklists/on-page",
        //             bullet: "dot",
        //         },
        //         {
        //             title: "Blogger Checklist",
        //             root: true,
        //             page: "checklists/blogger",
        //             bullet: "dot",
        //         },
        //         {
        //             title: "Ultimate Guest Post Checklist",
        //             root: true,
        //             page: "checklists/ultimate-guest-post",
        //             bullet: "dot",
        //             wrap: true,
        //         },
        //         {
        //             title: "Off-Page SEO Ranking Factors",
        //             root: true,
        //             page: "checklists/off-page-seo-ranking-factors",
        //             bullet: "dot",
        //             wrap: true,
        //         },
        //     ]
        // },

        /*{
            root: true,
            section: "Get More Clients",
            'custom-class': "gmc-checklist-button",
        },
        {
            title: "SEO Agreement Template",
            root: true,
            page: "more/seo-agreement-template",
            bullet: "dot",
            'custom-class': "gmc-checklist-dropdown",
        },*/

        // {
        //     title: "Get More Clients",
        //     root: true,
        //     bullet: "dot",
        //     'custom-class': "fw-bold",
        //     toggle:"tabs",
        //     submenu: [
        //         {
        //             title: "SEO Agreement Template",
        //             root: true,
        //             page: "more/seo-agreement-template",
        //             bullet: "dot",
        //         },
        //     ]
        // },

        /*{
            root: true,
            section: "Tools",
            'custom-class': "tools-checklist-button",
        },
        {
            title: "SERPpro AI",
            root: true,
            page: "tools/ai-tools",
            bullet: "dot",
            'custom-class': "tools-checklist-dropdown",
        },
        {
            title: "SEO Auditor Tool",
            root: true,
            page: "tools/seo-auditor-tool",
            bullet: "dot",
            'custom-class': "tools-checklist-dropdown",
        },*/

        // {
        //     title: "Tools",
        //     root: true,
        //     // icon: "flaticon2-browser-2",
        //     // page: "orders/new",
        //     bullet: "dot",
        //     // 'custom-class': "text-center",
        //     'custom-class': "fw-bold",
        //     toggle:"tabs",
        //     // wrap: true,
        //     // badge: {value:"New",type:"kt-badge--square kt-badge--primary"},
        //     submenu: [
        //         {
        //             title: "SERPpro AI",
        //             root: false,
        //             page: "tools/ai-tools",
        //             bullet: "dot",
        //         },
        //         {
        //             title: "SEO Auditor Tool",
        //             root: false,
        //             page: "tools/seo-auditor-tool",
        //             bullet: "dot",
        //         },
        //     ]
        // },



        // {
        //     root: true,
        //     section: "Tools",
        //     submenu: [
        //         {
        //             title: "SERPpro AI",
        //             root: false,
        //             page: "tools/ai-tools",
        //             bullet: "dot",
        //         },
        //         {
        //             title: "SEO Auditor Tool",
        //             root: false,
        //             page: "tools/seo-auditor-tool",
        //             bullet: "dot",
        //         },
        //     ]
        // },


        /*
        {
            title: "Blog",
            root: false,
            page: "https://www.serppro.io/blog?utm=serpdash",
            bullet: "dot",
            target: "_blank",
            "external-link": "https://www.serppro.io/blog?utm=serpdash",
        },
        {
            title: "Case Studies",
            root: false,
            page: "https://www.serppro.io/case-studies?utm=serpdash",
            bullet: "dot",
            target: "_blank",
            "external-link": "https://www.serppro.io/case-studies?utm=serpdash",
        },
        {
            title: "Testimonials",
            root: false,
            page: "https://serppro.io/testimonials?utm=serpdash",
            bullet: "dot",
            target: "_blank",
            "external-link": "https://serppro.io/testimonials?utm=serpdash",
        },
        */


        /*
        {
            title: "Buy Domains",
            root: false,
            page: "https://yourdomain.net/marketplace?utm=serpdash",
            bullet: "dot",
            target: "_blank",
            "external-link": "https://yourdomain.net/marketplace?utm=serpdash",
        },
        {
            title: "Browse Auctions",
            root: false,
            page: "https://domainchamp.com?utm=serpdash",
            bullet: "dot",
            target: "_blank",
            "external-link": "https://domainchamp.com?utm=serpdash",
        },
        {
            title: "Monthly Content",
            root: false,
            page: "https://www.contentpowered.com?utm=serpdash",
            bullet: "dot",
            target: "_blank",
            "external-link": "https://www.contentpowered.com?utm=serpdash",
        },
        */
        /*
        {
            title: "Backlink Packages",
            root: true,
            page: "seo-services/citation",
            bullet: "dot",
        },
        {
            root: true,
            section: "On-Site SEO"
        },
        /*
        {
            title: "Content Marketplace",
            root: true,
            page: "seo-services/content-marketplace",
            bullet: "dot",
        },
        {
            title: "On-Page SEO",
            root: true,
            page: "seo-services/on-page-optimization",
            bullet: "dot",
        },
        {
            title: "Content Creation",
            page: "packages/content-creation-services",
            root: true,
            bullet: "dot",
        },

        {
            root: true,
            section: "Managed SEO"
        },
        {
            title: "Managed SEO",
            page: "packages/managed-seo-service",
            root: true,
            bullet: "dot",
        },
        */

        /*
        {
            title: "Launch Campaign",
            root: true,
            icon: "flaticon2-browser-2",
            page: "orders/new",
            bullet: "dot",
            'custom-class': "text-center",
            wrap: true,
            badge: {value:"New",type:"kt-badge--square kt-badge--primary"},
          },
          {
            title: "SEO Services",
            root: true,
            icon: "flaticon2-analytics-2",
            page: "seo-services",
            submenu: [
                {
                    title: "Guest Post Service",
                    page: "orders/guest-post-dfy",
                    submenu: [
                        {
                            title: "Per link",
                            page: "orders/guest-post-dfy",
                        },
                        {
                            title: "Package",
                            page: "packages/guest-post-services",
                        },
                    ],
                },
                {
                    title: "Link Insert Service",
                    page: "seo-services/link-insertion",
                    submenu: [
                        {
                            title: "Per link",
                            page: "seo-services/link-insertion",
                        },
                        {
                            title: "Package",
                            page: "packages/niche-edits-service",
                        },
                    ],
                },
                {
                    title: "On-Page Optimization",
                    page: "seo-services/on-page-optimization",
                    submenu: [
                        {
                            title: "Per page",
                            page: "seo-services/on-page-optimization",
                        },
                        {
                            title: "Package",
                            page: "packages/on-page-seo-services",
                        },
                    ],
                },
                {
                    title: "Business Citations",
                    page: "seo-services/citation"
                },
                {
                    title: "Backlink Cleanup",
                    page: "seo-services/link-clean-up"
                },
                {
                    title: "Content Creation",
                    page: "packages/content-creation-services"
                },
                {
                    title: "Managed SEO",
                    page: "packages/managed-seo-service"
                },
            ]
        },
        {
        title: "Tools",
        root: true,
        icon: "flaticon2-browser-2",
        page: "/",
        badge: {value:"Premium",type:"kt-badge--square kt-badge--success"},
        submenu: [
            {
                title: "Technical SEO Audit",
                page: "onpage-audit"
            },
            {
                title: "Traffic Analytics",
                page: "analytics"
            },
        ]
        },
        {
            title: "My Profile",
            root: true,
            icon: "flaticon2-user",
            page: "profile",
            bullet: "dot",
        },
        {
            title: "Help Center",
            root: true,
            icon: "flaticon-questions-circular-button",
            "external-link": "https://www.serpchampion.com/help/",
            bullet: "dot",
        }
        */

    ]
  }
};
