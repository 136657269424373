import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import Skeleton from '@material-ui/lab/Skeleton';


class IncomingBacklinks extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }


    componentDidMount() {

    }

    render() {

        return (
            <>
                <Skeleton variant="rect" height={470}>Coming Soon</Skeleton>
            </>
        );

    }
}


export default injectIntl(connect(
    null,
    null
)(IncomingBacklinks));