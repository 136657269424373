import React from "react";
import {Portlet, PortletBody, PortletFooter, PortletHeader} from "../../../partials/content/Portlet";
import AuditStart from "../onpage-audit/AuditStart";
import {Button, ButtonToolbar} from "react-bootstrap";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as onpage from "../../../store/ducks/onpage.duck";

class SEOAuditStep extends React.Component {
    componentDidMount() {
        if(this.props.currentProject.data.audit_started) {
            this.props.nextStep();
        }
        this.props.requestSummary(this.props.currentProject.data.id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.currentProject.data.audit_started || (this.props.summary && this.props.summary.data)) {
            this.props.nextStep();
        }
    }

    render() {
        return (<>
            <Portlet>
                <PortletHeader title={this.props.currentProject.data.url + " - SEO Audit"} />
                <PortletBody>
                    <AuditStart projectId={this.props.currentProject.data.id} compact={true} />
                </PortletBody>
                <PortletFooter>
                    <ButtonToolbar className="justify-content-end">
                        <Button variant="secondary" onClick={this.props.nextStep}>
                            Skip
                        </Button>
                    </ButtonToolbar>
                </PortletFooter>
            </Portlet>
        </>);
    }
}

const mapStateToProps = ({ onpage: {summary} }) => ({
    summary
});

export default injectIntl(connect(
    mapStateToProps,
    onpage.actions
)(SEOAuditStep));
