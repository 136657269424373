import React, { Component } from "react";
import {connect} from "react-redux";
import { injectIntl } from "react-intl";
import * as projects from "../../../store/ducks/projects.duck";
import * as onpage from "../../../store/ducks/onpage.duck";
import {Spinner} from "reactstrap";
import {Portlet, PortletBody, PortletHeader} from "../../../partials/content/Portlet";
import {Badge, Table, Dropdown, Form, Col} from "react-bootstrap";
import "../../../../_metronic/_assets/sass/custom/datatable.scss";
import Select from "react-select";
import PageDetails from "./PageDetails";
import UPagination from "../../../partials/content/Pagination";

const pageErrorLabels = {
    "time_total_load":"Time Total Load",
    "have_page_duplicates":"Duplicate Content",
    "have_title_duplicates":"Duplicate Meta Title",
    "have_meta_description_duplicates":"Duplicate Meta Description",
    "links_broken":"Broken Links",
    "absent_h1_tags":"Page without H1 title",
    "title_empty":"Missing Title",
    "meta_description_empty":"Missing Description",
    "content_readability_flesh_kincaid":"Content Readability",
    "title_long":"Too long title length",
    "title_short":"Too short title length",
    //"links_internal":"Links internal",
    //"links_external":"Links external",
    "have_doctype":"Absent Doctype",
    "have_enc_meta_tag":"Absent Encoding Meta Tag",
    "images_invalid_alt":"Invalid Image Alt",
    "images_invalid_title":"Invalid Image Title",
    "meta_description_long":"Too long meta description",
    "meta_description_short":"Too short meta description",
    "have_h1_duplicates":"Duplicate H1 Titles",
};
const metricGroups = {
    "duplicate_titles":"title",
    "duplicate_meta_descriptions":"meta_description",
    "absent_h1_tags":"h1",
    "title_empty":"title",
    "meta_description_empty":"meta_description",
    "title_long":"title",
    "title_short":"title",
    "meta_description_long":"meta_description",
    "meta_description_short":"meta_description",
    "duplicate_h1":"h1",
    "time_load_high":"time_total_load",
    //"links_broken":"links_broken",
    "content_readability_bad":"title",
    //"have_doctype":"have_doctype",
    //"have_enc_meta_tag":"have_enc_meta_tag",
    //"images_invalid_alt":"images_invalid_alt",
    //"images_invalid_title":"images_invalid_title",
};
const filterMetric = {
    "have_page_duplicates":"duplicate_pages",
    "have_title_duplicates":"duplicate_titles",
    "have_meta_description_duplicates":"duplicate_meta_descriptions",
    "have_h1_duplicates":"duplicate_h1",
    "content_readability_flesh_kincaid":"content_readability_bad",
    "have_doctype":"absent_doctype",
    "have_enc_meta_tag":"absent_encoding_meta_tag",
};

class AuditFilteredPages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDetails: false,
            selectedPage: {},
            detailsKey: null,
            detailsLabel: "",
        }
    }
    componentDidMount() {
        const { projectId, filterKey, filter } = this.props;
        const page = 1;
        if(this.props.match && this.props.match.params) {
            this.props.requestProject(projectId);
        }
        this.props.requestFilteredPages(projectId, page, filterKey, filter);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { projectId, filterKey, filter } = this.props;
        const page = 1;
        if (filterKey !== prevProps.filterKey) {
            this.props.requestFilteredPages(projectId, page, filterKey, filter);
        }
    }

    render() {
        const { filterKey, filter, showLinksForPage } = this.props;
        const pages = this.props.filteredPages;
        const project = this.props.currentProject;

        if (!project) {
            return <Spinner/>;
        }
        else if(!project.data) {
            return (
                <>
                    <h1>Project not found!</h1>
                </>
            );
        }

        if(!pages) {
            return <Spinner/>
        }

        const substring = (text) => {
            return text.length > 15 ? text.substr(0, 15) + "..." : text;
        };

        const showErrorDetails = (key, pageId) => {
            const page = pages[filterKey].data.data.filter(item => item.page_id === pageId)[0];
            this.setState({
                showDetails: true,
                selectedPage: page,
                detailsKey: key,
                detailsLabel: pageErrorLabels[key],
            });
        };
        const onHideDetails = () => {
            this.setState({
                showDetails: false,
                selectedPage: {},
                detailsKey: null,
                detailsLabel: "",
            });
        };

        let headLabels = ["Errors", "Warnings", "Links"/*, "Links Internal", "Links External"*/];
        const headCols = [];
        const visibleMetricsCols = [];

        const metricOptions = [];
        for (let [key, value] of Object.entries(pageErrorLabels)) {
            metricOptions.push({value: key, label: value});
        }

        const selectedMetrics = [];
        if(this.props.filter && this.props.filter.length !== 0) {
            for(let i = 0; i < this.props.filter.length; i++) {
                selectedMetrics.push({value: this.props.filter[i], label: pageErrorLabels[this.props.filter[i]]});

                const metricGroup = metricGroups[this.props.filter[i]];
                if(metricGroup && visibleMetricsCols.indexOf(metricGroup) === -1) {
                    visibleMetricsCols.push(metricGroup);
                }
            }
        }

        if(visibleMetricsCols.length === 1) {
            headLabels = [];
            headLabels.push("Problems");
            for(let i = 0; i < visibleMetricsCols.length; i++) {
                headLabels.push(visibleMetricsCols[i]);
            }
        }

        const firstColWidth = 0.55;
        const colWidth = (1 - firstColWidth) / headLabels.length;
        for(let i = 0; i < headLabels.length; i++) {
            headCols.push(<th key={"hl_" + i} className="datatable-cell datatable-cell-center" style={{width: colWidth * 100 + "%"}}><span>{headLabels[i].replace("_", " ")}</span></th>);
        }

        const pagesRows = [];
        if(pages[filterKey]) {
            if(pages[filterKey].data && pages[filterKey].data.data && pages[filterKey].data.data.length !== 0) {
                for (const [index] of pages[filterKey].data.data.entries()) {
                    const {
                        address_full,
                        address_relative,
                        time_total_load,
                        have_page_duplicates,
                        have_title_duplicates,
                        have_meta_description_duplicates,
                        links_broken,
                        h1_count,
                        title,
                        meta_description,
                        content_readability_flesh_kincaid,
                        title_length,
                        have_doctype,
                        have_enc_meta_tag,
                        images_invalid_alt,
                        images_invalid_title,
                        links_internal,
                        links_external,
                        meta_description_length,
                        page_redirect,
                        have_h1_duplicates,
                        h1,
                        page_id
                    } = pages[filterKey].data.data[index];

                    const pageErrors = [];
                    const pageWarnings = [];
                    let pagePassedCnt = 0;
                    let pageErrorsCnt = 0;
                    let pageWarningsCnt = 0;

                    if(time_total_load > 3000) {
                        pageErrors.push({key:"time_total_load", value:time_total_load + "ms", pageId: page_id});
                        pageErrorsCnt++;
                    }
                    else {
                        pagePassedCnt++;
                    }
                    if(have_page_duplicates) {
                        pageErrors.push({key:"have_page_duplicates", value:"", pageId: page_id});
                        pageErrorsCnt++;
                    }
                    else {
                        pagePassedCnt++;
                    }
                    if(have_title_duplicates) {
                        pageErrors.push({key:"have_title_duplicates", value:substring(title), pageId: page_id});
                        pageErrorsCnt++;
                    }
                    else {
                        pagePassedCnt++;
                    }
                    if(have_meta_description_duplicates) {
                        pageErrors.push({key:"have_meta_description_duplicates", value:substring(meta_description), pageId: page_id});
                        pageErrorsCnt++;
                    }
                    else {
                        pagePassedCnt++;
                    }
                    if(links_broken > 0) {
                        pageErrors.push({key:"links_broken", value:links_broken, pageId: page_id});
                        pageErrorsCnt++;
                    }
                    else {
                        pagePassedCnt++;
                    }
                    if(have_h1_duplicates) {
                        pageErrors.push({key:"have_h1_duplicates", value:substring(h1), pageId: page_id});
                        pageErrorsCnt++;
                    }
                    else {
                        pagePassedCnt++;
                    }

                    if(parseInt(h1_count) === 0) {
                        pageWarnings.push({key:"absent_h1_tags", value:"", pageId: page_id});
                        pageWarningsCnt++;
                    }
                    else {
                        pagePassedCnt++;
                    }
                    if(!title) {
                        pageWarnings.push({key:"title_empty", value:"", pageId: page_id});
                        pageWarningsCnt++;
                    }
                    else {
                        pagePassedCnt++;
                    }
                    if(!meta_description) {
                        pageWarnings.push({key:"meta_description_empty", value:"", pageId: page_id});
                        pageWarningsCnt++;
                    }
                    else {
                        pagePassedCnt++;
                    }
                    if(content_readability_flesh_kincaid < 15) {
                        pageWarnings.push({key:"content_readability_flesh_kincaid", value:content_readability_flesh_kincaid, pageId: page_id});
                        pageWarningsCnt++;
                    }
                    else {
                        pagePassedCnt++;
                    }
                    if(title_length < 30 && title_length !== 0) {
                        pageWarnings.push({key:"title_short", value:title_length, pageId:page_id, recommendedValue:30});
                        pageWarningsCnt++;
                    }
                    else {
                        pagePassedCnt++;
                    }
                    if(title_length > 65) {
                        pageWarnings.push({key:"title_long", value:title_length, pageId: page_id, recommendedValue:65});
                        pageWarningsCnt++;
                    }
                    else {
                        pagePassedCnt++;
                    }
                    if(meta_description_length < 50 && meta_description_length !== 0) {
                        pageWarnings.push({key:"meta_description_short", value:meta_description_length, pageId: page_id, recommendedValue:50});
                        pageWarningsCnt++;
                    }
                    else {
                        pagePassedCnt++;
                    }
                    if(meta_description_length > 160) {
                        pageWarnings.push({key:"meta_description_long", value:meta_description_length, pageId: page_id, recommendedValue:160});
                        pageWarningsCnt++;
                    }
                    else {
                        pagePassedCnt++;
                    }
                    if(!have_doctype) {
                        pageWarnings.push({key:"have_doctype", value:"", pageId: page_id});
                        pageWarningsCnt++;
                    }
                    else {
                        pagePassedCnt++;
                    }
                    if(!have_enc_meta_tag) {
                        pageWarnings.push({key:"have_enc_meta_tag", value:"", pageId: page_id});
                        pageWarningsCnt++;
                    }
                    else {
                        pagePassedCnt++;
                    }
                    if(images_invalid_alt > 0) {
                        pageWarnings.push({key:"images_invalid_alt", value:images_invalid_alt, pageId: page_id});
                        pageWarningsCnt++;
                    }
                    else {
                        pagePassedCnt++;
                    }
                    if(images_invalid_title > 0) {
                        pageWarnings.push({key:"images_invalid_title", value:images_invalid_title, pageId: page_id});
                        pageWarningsCnt++;
                    }
                    else {
                        pagePassedCnt++;
                    }

                    const pageErrorsRows = [];
                    if(pageErrors.length !== 0) {
                        for(let i = 0; i < pageErrors.length; i++) {
                            pageErrorsRows.push(
                                <Dropdown.Item href="#" onClick={()=>{showErrorDetails(pageErrors[i].key, pageErrors[i].pageId);}} key={"err_" + pageErrors[i].key}>• {pageErrorLabels[pageErrors[i].key]}{pageErrors[i].value ? ": " + pageErrors[i].value : ""}</Dropdown.Item>
                            );
                        }
                    }
                    const pageWarningsRows = [];
                    if(pageWarnings.length !== 0) {
                        for(let i = 0; i < pageWarnings.length; i++) {
                            pageWarningsRows.push(
                                <Dropdown.Item href="#" onClick={()=>{showErrorDetails(pageWarnings[i].key, pageWarnings[i].pageId);}} key={"err_" + pageWarnings[i].key}>• {pageErrorLabels[pageWarnings[i].key]}{pageWarnings[i].value ? ": " + pageWarnings[i].value : ""}</Dropdown.Item>
                            );
                        }
                    }

                    if(visibleMetricsCols.length !== 1) {
                        pagesRows.push(<tr key={index} className="datatable-row">
                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                            <td className="datatable-cell"><span><a href={address_full}
                                                                    target="_blank">{address_full}</a></span></td>
                            <td className="datatable-cell datatable-cell-center" style={{width: "15%"}}><span
                                style={{overflow: "visible"}}>
                            {pageErrorsCnt > 0
                                ? <Dropdown>
                                    <Dropdown.Toggle variant="danger" size="sm">
                                        {pageErrorsCnt}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {pageErrorsRows}
                                    </Dropdown.Menu>
                                </Dropdown>
                                : <Badge variant="secondary">{pageErrorsCnt}</Badge>}
                        </span></td>
                            <td className="datatable-cell datatable-cell-center" style={{width: "15%"}}><span
                                style={{overflow: "visible"}}>
                            {pageWarningsCnt > 0
                                ? <Dropdown>
                                    <Dropdown.Toggle variant="warning" size="sm">
                                        {pageWarningsCnt}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {pageWarningsRows}
                                    </Dropdown.Menu>
                                </Dropdown>
                                : <Badge variant="secondary">{pageWarningsCnt}</Badge>}
                        </span></td>
                            <td className="datatable-cell datatable-cell-center" style={{width: "15%"}}><span>{/*<Badge
                                variant="secondary">{pagePassedCnt}</Badge>*/}<a href="#" role="button" onClick={(e)=>{e.preventDefault();showLinksForPage(address_full);}}>{links_internal + links_external}</a></span></td>
                            {/*<td className="datatable-cell datatable-cell-center" style={{width: "15%"}}><span><a
                                href="#">{links_internal}</a></span></td>
                            <td className="datatable-cell datatable-cell-center" style={{width: "15%"}}><span><a
                                href="#">{links_external}</a></span></td>*/}
                        </tr>);
                    }
                    else {
                        const rowMetrics = [];
                        for(let i = 0; i < visibleMetricsCols.length; i++) {
                            rowMetrics.push(<td key={"rm_" + i} className="datatable-cell" style={{width: colWidth * 100 + "%"}}><span>{pages[filterKey].data.data[index][visibleMetricsCols[i]]}</span></td>);
                        }

                        const rowProblems = [];
                        if(pageErrors.length !== 0) {
                            for (let i = 0; i < pageErrors.length; i++) {
                                const metric = filterMetric[pageErrors[i].key] ? filterMetric[pageErrors[i].key] : pageErrors[i].key;
                                if(this.props.filter.indexOf(metric) !== -1) {
                                    const recommendedValue = pageErrors[i].recommendedValue > 0 ? "/" + pageErrors[i].recommendedValue : "";
                                    rowProblems.push(<Badge key={"pe_" + i} variant="danger"
                                                            className="kt-m5">{pageErrorLabels[pageErrors[i].key]}{pageErrors[i].value ? ": " + pageErrors[i].value + recommendedValue : ""}</Badge>);
                                }
                            }
                        }
                        if(pageWarnings.length !== 0) {
                            for (let i = 0; i < pageWarnings.length; i++) {
                                const metric = filterMetric[pageWarnings[i].key] ? filterMetric[pageWarnings[i].key] : pageWarnings[i].key;
                                if(this.props.filter.indexOf(metric) !== -1) {
                                    const recommendedValue = pageWarnings[i].recommendedValue > 0 ? "/" + pageWarnings[i].recommendedValue : "";
                                    rowProblems.push(<Badge key={"pw_" + i} variant="warning"
                                                            className="kt-m5">{pageErrorLabels[pageWarnings[i].key]}{pageWarnings[i].value ? ": " + pageWarnings[i].value + recommendedValue : ""}</Badge>);
                                }
                            }
                        }

                        pagesRows.push(<tr key={index} className="datatable-row">
                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                            <td className="datatable-cell"><span><a href={address_full}
                                                                    target="_blank">{address_full}</a></span></td>
                            <td className="datatable-cell datatable-cell-center" style={{width: colWidth * 100 + "%"}}><span>
                                {rowProblems}
                            </span></td>
                            {rowMetrics}
                        </tr>);
                    }
                }
            }
        }

        const handleNextPage = (activePage) => {
            this.props.requestFilteredPages(project.data.id, activePage, filterKey, filter);
        };

        return (
            <>
                <Portlet>
                    <PortletHeader title="Pages" />
                    <PortletBody>
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col} className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12" controlId="formGridCategory">
                                    <Select
                                        isMulti
                                        options={metricOptions}
                                        value={selectedMetrics}
                                        onChange={this.props.handleMetricChange}
                                        placeholder="Select Metric..."
                                    />
                                </Form.Group>
                            </Form.Row>
                        </Form>
                        <div
                            className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded"
                            id="kt_datatable">
                                <Table className="datatable-table pages-table" style={{overflow:"visible"}}>
                                    <thead className="datatable-head">
                                    <tr className="datatable-row">
                                        <th className="datatable-cell"><span>URL</span></th>
                                        {headCols}
                                    </tr>
                                    </thead>
                                    <tbody className="datatable-body">
                                    {pages[filterKey] ? pagesRows : <tr className="datatable-row"><td colSpan={headCols.length + 1} className="datatable-cell"><Spinner /></td></tr>}
                                    </tbody>
                                </Table>
                        </div>
                        {(pages[filterKey] && pages[filterKey].data && pages[filterKey].data.last_page > 1) && <UPagination currentPage={pages[filterKey].data.current_page} totalPages={pages[filterKey].data.last_page} onChange={handleNextPage} />}
                        <PageDetails
                            show={this.state.showDetails}
                            onHide={onHideDetails}
                            page={this.state.selectedPage}
                            errorKey={this.state.detailsKey}
                            label={this.state.detailsLabel}
                            projectId={project.data.id}
                        />
                    </PortletBody>
                </Portlet>
            </>
        );
    }
}

const mapStateToProps = ({ projects: { currentProject }, onpage: { filteredPages } }) => ({
    currentProject, filteredPages
});

export default injectIntl(connect(
    mapStateToProps,
    {...projects.actions, ...onpage.actions}
)(AuditFilteredPages));
