import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as analytics from "../../../store/ducks/analytics.duck";
import {Spinner} from "reactstrap";
import {Pagination, Table} from "react-bootstrap";
import {IconButton, Button} from '@material-ui/core';
import {Delete, Edit, Add} from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import {Autorenew} from '@material-ui/icons';
import NotesModal from "./NotesModal";
import Skeleton from '@material-ui/lab/Skeleton';

class NotesList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notesModalShow: false,
            notesModalAction: 'add',
            notes: [],
            noteId: null,
            noteDate: false,
            load: false,
            active: false,
        };
    }

    componentDidMount() {

    }

    render() {

        const {currentProject, notes} = this.props;
        const {notesModalShow, notesModalAction, noteId, noteDate, load, active } = this.state;


        const notesModalClose = () => {
            this.setState({ notesModalShow: false });
        };

        const notesModalOpen = (action, noteId=false, date=false) => {
             this.setState({
                 notesModalShow: true,
                 notesModalAction: action,
                 noteDate: date,
                 noteId: noteId
             });
         };

        const RefreshData = () => {
              this.props.requestAnalyticsNotes(currentProject.id);
        };

/*
        const addLineBreaks = (string) => {
            let content = '';
            string.split('\n').map((text, index) => ( content += text + '<br/>' ));
            return content;
        };
*/

        if (load) {
            return (
                <>
                    <Skeleton variant="rect" height={400}><Spinner mx="auto"/></Skeleton>
                </>
            );
        }

        if (!notes) {
            return (
                <>
                    <Skeleton variant="rect" height={400}>
                        <span className="no-data">No data</span>&nbsp;
                        <IconButton onClick={RefreshData}>
                            <Autorenew />
                        </IconButton>
                    </Skeleton>
                </>
            );
        }

        const rows = [];
        const paginationItems = [];


        if (notes.data) {

            if (notes.data.length) {
                for (const [index] of notes.data.entries()) {

                    rows.push(
                        <tr key={index}>
                            <td>{notes.data[index].date}</td>
                            <td>{notes.data[index].note}</td>
                            <td>{notes.data[index].category}</td>
                            <td>{notes.data[index].cost}</td>
                            <td className="text-right">
                                <IconButton size='small' onClick={() => notesModalOpen('add', false, notes.data[index].date)}><Add /></IconButton>
                                <IconButton size='small' onClick={() => notesModalOpen('edit', notes.data[index].id)}><Edit /></IconButton>
                                <IconButton size='small' onClick={() => notesModalOpen('delete',notes.data[index].id)}><Delete /></IconButton>
                            </td>
                        </tr>
                    )
                }
            }

/*
            for (let number = 1; number <= notes.meta.last_page; number++) {
                paginationItems.push(
                    <Pagination.Item key={number} active={number === active} onClick={() => {
                        this.props.initNotes(number);
                    }}>
                        {number}
                    </Pagination.Item>,
                );
            }

            */
        }

        return (
                <>
                <div>
                    <Button className="pull-right" variant="outlined" color="primary" onClick={() => notesModalOpen('add')}>
                        <AddIcon  fontSize="small" /> &nbsp; Add Task
                    </Button>
                    <Table hover responsive size="sm" id="analyticsTaskManager" className="table">
                        <thead className="">
                        <tr>
                            <th className="w-20">Date</th>
                            <th className="w-40">Note</th>
                            <th className="w-20">Category</th>
                            <th className="w-10">Cost</th>
                            <th className="w-10 text-right">Actions</th>
                         </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </Table>
                </div>
                    {/*<Pagination>{paginationItems}</Pagination>*/}
                <NotesModal
                    currentProject={currentProject}
                    noteId={noteId}
                    noteDate={noteDate}
                    action={notesModalAction}
                    show={notesModalShow}
                    onHide={notesModalClose}
                />
                </>
        );
    }
}


const mapStateToProps = ({
    projects: { currentProject: {data: currentProject }},
    analytics: { notes}
}) => ({
    notes, currentProject
});

export default injectIntl(connect(
    mapStateToProps,
    analytics.actions
)(NotesList));