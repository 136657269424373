export function displayError(errorMessage) {
    let notification = document.getElementById('global-error-alert');

    if (notification) {
        let alertMessage = notification.querySelector('.MuiAlert-message');

        notification.style.opacity = '1';
        notification.style.visibility = 'visible';
        notification.style.transform = 'translateY(0)';

        if (alertMessage) {
            alertMessage.textContent = errorMessage;
        }

        setTimeout(() => {
            notification.style.opacity = '0';
            notification.style.visibility = 'hidden';
            notification.style.transform = 'translateY(-100%)';

            if (alertMessage) {
                setTimeout(() => {
                    alertMessage.textContent = '';
                }, 500);
            }

        }, 3000); // Hide after 3 seconds
    }
}
