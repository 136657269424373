import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import GuestPostsAdmin from "./GuestPostsAdmin";
import OrderProgressAdmin from "./OrderProgressAdmin";
import GuestPostAdmin from "./GuestPostAdmin";
import OrdersAdmin from "./OrdersAdmin";
import OrdersFilteredAdmin from "./OrdersFilteredAdmin";
import OffersAdmin from "./OffersAdmin";
import BlogsAdmin from "./BlogsAdmin";
import OrderInvoiceAdmin from "./OrderInvoiceAdmin";
import AnalyticsAdmin from "./AnalyticsAdmin";
import OnPageAuditAdmin from "./OnPageAuditAdmin";
import LinkCleanUpAdmin from "./LinkCleanUpAdmin";
import LinkInsertionAdmin from "./LinkInsertionAdmin";
import CitationAdmin from "./CitationAdmin";
import ArticleAdmin from "./ArticleAdmin";
import WritersAdmin from "./WritersAdmin";
import FinancialsAdmin from "./FinancialsAdmin";
import ContentWriterInvoiceAdmin from "./ContentWriterInvoiceAdmin";
import ServicePagesAdmin from "./ServicePagesAdmin";
import ServicePageAdmin from "./ServicePageAdmin";

export default function AdminPage() {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                <Route path="/admin/orders/guest-post/:id" component={GuestPostAdmin} />
                <Route path="/admin/orders/seo-services/:id" component={OnPageAuditAdmin} />
                <Route path="/admin/orders/service-page/:service/:id" component={ServicePageAdmin} />
                <Route path="/admin/orders/link-clean-up/:id" component={LinkCleanUpAdmin} />
                <Route path="/admin/orders/link-insertion/:id" component={LinkInsertionAdmin} />
                <Route path="/admin/orders/citation/:id" component={CitationAdmin} />
                <Route path="/admin/orders/article/:id" component={ArticleAdmin} />
                <Route path="/admin/orders/:orderLineNumber/salesinvoice" component={ContentWriterInvoiceAdmin} />
                <Route path="/admin/orders/:orderId/progress" component={OrderProgressAdmin} />
                <Route path="/admin/orders/:orderId/guest-posts" component={GuestPostsAdmin} />
                <Route path="/admin/orders/:orderId/service-page/:service" component={ServicePagesAdmin} />
                <Route path="/admin/orders/:orderId/invoice" component={OrderInvoiceAdmin} />
                <Route path="/admin/orders" component={OrdersAdmin} />
                <Route path="/admin/orders-filtered" component={OrdersFilteredAdmin} />
                <Route path="/admin/analytics" component={AnalyticsAdmin} />
                <Route path="/admin/offers" component={OffersAdmin} />
                <Route path="/admin/blogs" component={BlogsAdmin} />
                <Route path="/admin/writers" component={WritersAdmin} />
                <Route path="/admin/financials" component={FinancialsAdmin} />

                // <Redirect to="/admin/orders" />
            </Switch>
        </Suspense>
    );
}
