import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getOffers, getOffer } from "../../../crud/admin/offers.crud";

export const actionTypes = {
    OffersInit: "[Init Offers Admin] Action",
    OffersRequested: "[Request Offers Admin] Action",
    OffersLoaded: "[Load Offers Admin] Offers API",
    OfferRequested: "[Request Offer Admin] Action",
    OfferLoaded: "[Load Offer Admin] Offers API",
};

const initialOffersState = {
    offers: undefined,
    currentOffer: undefined
};

export const reducer = persistReducer(
    { storage, key: "admin-offers", whitelist: ["offers", "currentOffer"] },
    (state = initialOffersState, action) => {
        switch (action.type) {
            case actionTypes.OffersInit: {
                return { offers: undefined };
            }

            case actionTypes.OffersLoaded: {
                const { offers } = action.payload;

                return { ...state, offers };
            }

            case actionTypes.OfferRequested: {
                return { ...state, currentOffer: undefined };
            }

            case actionTypes.OfferLoaded: {
                const { currentOffer } = action.payload;

                return { ...state, currentOffer };
            }
        
            default:
                return state;
        }
    }
);

export const actions = {
    initOffers: page => ({ type: actionTypes.OffersInit, payload: { page } }),
    requestOffers: page => ({ type: actionTypes.OffersRequested, payload: { page } }),
    fulfillOffers: offers => ({ type: actionTypes.OffersLoaded, payload: { offers } }),
    requestOffer: id => ({ type: actionTypes.OfferRequested, payload: { id } }),
    fulfillOffer: currentOffer => ({ type: actionTypes.OfferLoaded, payload: { currentOffer } }),
};

export function* saga() {
    yield takeLatest(actionTypes.OffersInit, function* initOffersSaga(action) {
        const { page } = action.payload;
        yield put(actions.requestOffers(page));
    });

    yield takeLatest(actionTypes.OffersRequested, function* offersRequested(action) {
        const { page } = action.payload;
        const {data: offers} = yield getOffers(page);
        yield put(actions.fulfillOffers(offers));
    });

    yield takeLatest(actionTypes.OfferRequested, function* offerRequested(action) {
        try {
            const { id } = action.payload;
            const {data: currentOffer} = yield getOffer(id);

            yield put(actions.fulfillOffer(currentOffer));
        }
        catch (e) {
            yield put(actions.fulfillOffer({data: undefined}));
        }
    });
}
