import axios from "axios";

export const base = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL : "";
export const API_ORDER_URL = base + "/api/admin/orders";
export const API_ORDER_WITH_ARGS_URL = base + "/api/admin/orders-filtered";
// export const API_CREATE_DYNAMIC_COST = base + "/api/admin/create-dynamic-cost";
// export const API_DELETE_DYNAMIC_COST = base + "/api/admin/delete-dynamic-cost";
export const API_GUEST_POST_URL = base + "/api/admin/guestposts";
export const API_SEO_SERVICE_URL = base + "/api/admin/seoservices";
export const API_LINK_CLEAN_UP_SERVICE_URL = base + "/api/admin/link-clean-up";
export const API_LINK_INSERTION_SERVICE_URL = base + "/api/admin/link-insertion";
export const API_PRESS_RELEASE_SERVICE_URL = base + "/api/admin/press-release";
export const API_GPB_SERVICE_URL = base + "/api/admin/gpb";
export const API_CITATION_SERVICE_URL = base + "/api/admin/citation";
export const API_ARTICLE_SERVICE_URL = base + "/api/admin/article";
export const API_BLOGS_GET_URL = base + "/api/admin/blogs";
export const API_BLOG_ACCEPT_URL = base + "/api/admin/blog/accept";
export const API_BLOG_REJECT_URL = base + "/api/admin/blog/reject";
export const API_WRITERS_GET_URL = base + "/api/admin/writers";
export const API_WRITERS_ACTIVE_GET_URL = base + "/api/admin/writers-approved";
export const API_WRITER_ACCEPT_URL = base + "/api/admin/writer/accept";
export const API_WRITER_REJECT_URL = base + "/api/admin/writer/reject";

export function getOrders(page = null) {
    return axios.get(API_ORDER_URL + (page > 1 ? "?page=" + page : ""));
}

export function getOrdersWithArgs(page = null, month = null, year = null) {
    return axios.get(API_ORDER_WITH_ARGS_URL + "?" + (month ? "month=" + month : "" )+ "&" + (year ? "year=" + year : "") + (page > 1 ? "&page=" + page : "" ));
}

// export function createDynamicCost(order_id = null, type = null, amount = null) {
//     return axios.get(API_CREATE_DYNAMIC_COST + "?" + (order_id ? "order_id=" + order_id : "" )+ "&" + (type ? "type=" + type : "") + (amount ? "&amount=" + amount : "" ));
// }

// export function deleteDynamicCost(order_id = null, type = null, amount = null) {
//     return axios.get(API_DELETE_DYNAMIC_COST + "?" + (order_id ? "order_id=" + order_id : "" )+ "&" + (type ? "type=" + type : "") + (amount ? "&amount=" + amount : "" ));
// }

export function getBlogs(page = null) {
    return axios.get(API_BLOGS_GET_URL + (page > 1 ? "?page=" + page : ""));
}

export function rejectBlog(blog_id) {
    return axios.post(API_BLOG_REJECT_URL, { blog_id });
}

export function approveBlog(blog_id) {
    return axios.post(API_BLOG_ACCEPT_URL, { blog_id });
}


export function getWriters(page = null) {
    return axios.get(API_WRITERS_GET_URL + (page > 1 ? "?page=" + page : ""));
}

export function getWritersActive(page = null) {
    return axios.get(API_WRITERS_ACTIVE_GET_URL + (page > 1 ? "?page=" + page : ""));
}

export function rejectWriter(user_id) {
    return axios.post(API_WRITER_REJECT_URL, { user_id });
}

export function approveWriter(user_id) {
    return axios.post(API_WRITER_ACCEPT_URL, { user_id });
}




export function getOrder(id) {
    return axios.get(API_ORDER_URL + "/" + id);
}

export function getGuestPosts(orderId, page, params) {
    return axios.get(API_GUEST_POST_URL + "?order_id=" + orderId + (page > 1 ? "&page=" + page : "") + (params && params.build ? "&build=" + parseInt(params.build) : ""));
}

export function getGuestPost(id) {
    return axios.get(API_GUEST_POST_URL + "/" + id);
}

export function editGuestPost(id, data) {
    return axios.post(API_GUEST_POST_URL + "/edit/" + id, data);
}

export function editGuestPostWriter(id, data) {
    return axios.post(API_GUEST_POST_URL + "/writer/edit/" + id, data);
}

export function editGuestPostStatus(id, data) {
    return axios.post(API_GUEST_POST_URL + "/status/edit/" + id, data);
}

export function downloadPdfInvoice(orderId) {
    return axios.get(base + "/api/admin/invoice/" + orderId + "/pdf", {responseType: 'blob'});
}

export function getInvoice(orderId) {
    return axios.get(base + "/api/admin/invoice/" + orderId);
}

export function downloadSalePdfInvoice(order_line_number) {
    return axios.get(base + "/api/admin/invoice/" + order_line_number + "/pdf", {responseType: 'blob'});
}

export function getSalesInvoice(order_line_number) {
    return axios.get(base + "/api/admin/saleinvoice/" + order_line_number);
}

export function getSeoServices(orderId, page, params) {
    return axios.get(API_SEO_SERVICE_URL + "?order_id=" + orderId + (page > 1 ? "&page=" + page : "") + (params && params.done ? (page > 1 ? "&" : "?") + "done=" + parseInt(params.done) : ""));
}

export function getSeoService(id) {
    return axios.get(API_SEO_SERVICE_URL + "/" + id);
}

export function editSeoService(id, data) {
    return axios.post(API_SEO_SERVICE_URL + "/edit/" + id, data);
}

export function getLinkCleanUpServices(orderId, page, params) {
    return axios.get(API_LINK_CLEAN_UP_SERVICE_URL + "?order_id=" + orderId + (page > 1 ? "&page=" + page : "") + (params && params.done ? (page > 1 ? "&" : "?") + "done=" + parseInt(params.done) : ""));
}

export function getLinkCleanUpService(id) {
    return axios.get(API_LINK_CLEAN_UP_SERVICE_URL + "/" + id);
}

export function editLinkCleanUpService(id, data) {
    return axios.post(API_LINK_CLEAN_UP_SERVICE_URL + "/edit/" + id, data);
}

export function getLinkInsertionServices(orderId, page, params) {
    return axios.get(API_LINK_INSERTION_SERVICE_URL + "?order_id=" + orderId + (page > 1 ? "&page=" + page : "") + (params && params.done ? (page > 1 ? "&" : "?") + "done=" + parseInt(params.done) : ""));
}

export function getLinkInsertionService(id) {
    return axios.get(API_LINK_INSERTION_SERVICE_URL + "/" + id);
}

export function editLinkInsertionService(id, data) {
    return axios.post(API_LINK_INSERTION_SERVICE_URL + "/edit/" + id, data);
}

export function getCitationServices(orderId, page, params) {
    return axios.get(API_CITATION_SERVICE_URL + "?order_id=" + orderId + (page > 1 ? "&page=" + page : "") + (params && params.done ? (page > 1 ? "&" : "?") + "done=" + parseInt(params.done) : ""));
}

export function getCitationService(id) {
    return axios.get(API_CITATION_SERVICE_URL + "/" + id);
}

export function editCitationService(id, data) {
    return axios.post(API_CITATION_SERVICE_URL + "/edit/" + id, data);
}

export function getPressReleaseServices(orderId, page, params) {
    return axios.get(API_PRESS_RELEASE_SERVICE_URL + "?order_id=" + orderId + (page > 1 ? "&page=" + page : "") + (params && params.done ? (page > 1 ? "&" : "?") + "done=" + parseInt(params.done) : ""));
}

export function getPressReleaseService(id) {
    return axios.get(API_PRESS_RELEASE_SERVICE_URL + "/" + id);
}

export function editPressReleaseService(id, data) {
    return axios.post(API_PRESS_RELEASE_SERVICE_URL + "/edit/" + id, data);
}

export function getGPBServices(orderId, page, params) {
    return axios.get(API_GPB_SERVICE_URL + "?order_id=" + orderId + (page > 1 ? "&page=" + page : "") + (params && params.done ? (page > 1 ? "&" : "?") + "done=" + parseInt(params.done) : ""));
}

export function getGPBService(id) {
    return axios.get(API_GPB_SERVICE_URL + "/" + id);
}

export function editGPBService(id, data) {
    return axios.post(API_GPB_SERVICE_URL + "/edit/" + id, data);
}

export function getArticleServices(orderId, page, params) {
    return axios.get(API_ARTICLE_SERVICE_URL + "?order_id=" + orderId + (page > 1 ? "&page=" + page : "") + (params && params.build ? "&build=" + parseInt(params.build) : ""));
}

export function getArticleService(id) {
    return axios.get(API_ARTICLE_SERVICE_URL + "/" + id);
}

export function editArticleService(id, data) {
    return axios.post(API_ARTICLE_SERVICE_URL + "/edit/" + id, data);
}
