import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import * as profile from "./ducks/profile.duck";
import * as projects from  "./ducks/projects.duck";
import * as offers from  "./ducks/offers.duck";
import * as guestposts from "./ducks/guestPosts.duck";
import * as orders from "./ducks/orders.duck";
import * as marketplace from "./ducks/marketplace.duck";
import * as cart from "./ducks/cart.duck";
import { metronic } from "../../_metronic";
import * as guestpostsAdmin from "../store/ducks/admin/guestPosts.duck";
import * as ordersAdmin from "../store/ducks/admin/orders.duck";
import * as offersAdmin from "../store/ducks/admin/offers.duck";
import * as seoservicesAdmin from "../store/ducks/admin/seoServices.duck";
import * as servicesAdmin from "../store/ducks/admin/services.duck";
import * as financialsAdmin from "../store/ducks/admin/financials.duck";
import * as notifications from "../store/ducks/notifications.duck";
import * as onpage from "../store/ducks/onpage.duck";
import * as analytics from  "./ducks/analytics.duck";
import * as seoservices from "./ducks/seoServices.duck";
import * as pagedata from "./ducks/page.duck";
import * as services from "./ducks/services.duck";
import * as packages from "./ducks/packages.duck";
import storage from 'redux-persist/lib/storage'

const appReducer = combineReducers({
    auth: auth.reducer,
    profile: profile.reducer,
    offers: offers.reducer,
    projects: projects.reducer,
    guestposts: guestposts.reducer,
    orders: orders.reducer,
    marketplace: marketplace.reducer,
    cart: cart.reducer,
    notifications: notifications.reducer,
    onpage: onpage.reducer,
    i18n: metronic.i18n.reducer,
    builder: metronic.builder.reducer,
    guestpostsAdmin: guestpostsAdmin.reducer,
    ordersAdmin: ordersAdmin.reducer,
    offersAdmin: offersAdmin.reducer,
    seoservicesAdmin: seoservicesAdmin.reducer,
    servicesAdmin: servicesAdmin.reducer,
    financialsAdmin: financialsAdmin.reducer,
    analytics: analytics.reducer,
    seoservices: seoservices.reducer,
    pagedata:pagedata.reducer,
    services:services.reducer,
    packages:packages.reducer,
});

export const rootReducer = (state, action) => {
    if (action.type === '[Logout] Action') {
        // for all keys defined in persistConfig(s)
        storage.removeItem('persist:root');
        storage.removeItem('persist:demo3-auth');
        storage.removeItem('persist:demo3-offers');
        storage.removeItem('persist:demo3-projects');
        storage.removeItem('persist:analytics');
        storage.removeItem('persist:cart');
        storage.removeItem('persist:demo3-orders');
        storage.removeItem('persist:marketplace');
        storage.removeItem('persist:demo3-notifications');
        storage.removeItem('persist:demo3-audit');
        storage.removeItem('persist:demo3-page');
        storage.removeItem('persist:demo3-billing');
        storage.removeItem('persist:demo3-packages');
        storage.removeItem('persist:admin-orders');
        storage.removeItem('persist:admin-offers');

        state = undefined;
    }

    return appReducer(state, action)
};

export function* rootSaga() {
  yield all([
      auth.saga(),
      profile.saga(),
      offers.saga(),
      projects.saga(),
      guestposts.saga(),
      orders.saga(),
      marketplace.saga(),
      cart.saga(),
      guestpostsAdmin.saga(),
      ordersAdmin.saga(),
      offersAdmin.saga(),
      seoservicesAdmin.saga(),
      servicesAdmin.saga(),
      financialsAdmin.saga(),
      notifications.saga(),
      onpage.saga(),
      analytics.saga(),
      seoservices.saga(),
      pagedata.saga(),
      services.saga(),
      packages.saga(),
  ]);
}
