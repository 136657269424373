import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Text } from 'react';
import {Link} from "react-router-dom";
import _ from 'lodash';

/**
 * Adds links to text with without HTML tags
 * 'This will turn into a link: http://www.facebook.com'
 * Use HTMLView for actual HTML.
 */
class HyperText extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        var contents = this.props.children || '';

        if (_.isString(this.props.children)) {
            let tokens = this.props.children.split(/\s/);

            contents = _.map(tokens, (token, i) => {
                let hasSpace = i !== (tokens.length - 1);
                let maybeSpace = hasSpace ? ' ' : '';

                if (token.match(/^http(s?)\:\//)) {
                    return (
                        <a key={i} target="_blank" href={token}>{token}{maybeSpace}</a>
                    );
                } else {
                    return token + maybeSpace;
                }
            });
        } else {
            console.warn('Attempted to use <HyperText> with nested components. ' +
                'This component only supports plain text children.');
            return contents;
        }

        return contents;
    }

}

export default injectIntl(connect(
    null,
    null
)(HyperText));