import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as orders from "../../store/ducks/admin/orders.duck";
import {Spinner} from "reactstrap";
import {Table, Pagination, Form, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Typography} from "@material-ui/core";
// import CreateProject from "./CreateProject";

class ActiveUserContentWriterSelect extends Component {
    componentDidMount() {
        this.props.initContentWriters();
        const currentContentWriterId = this.props.currentContentWriterId;
        this.props.requestContentWriters(1);
        // }
    }

    render() {
        const {contentWriters, currentContentWriterId, handleContentWriterChange, action} = this.props;

        if (!contentWriters) {
            return <Spinner/>;
        }

        const rows = [];
        let paginationItems = [];

        if (contentWriters.data) {
            let active = contentWriters.meta.current_page;

            if (contentWriters.data.length) {
                for (const [index] of contentWriters.data.entries()) {
                    //let keywords = projects.data[index].keywords.split('\n').map((item, i) => <span className="d-block" key={i}>{item}</span>);
                    rows.push(<option key={index} value={contentWriters.data[index].user_id}>{contentWriters.data[index].user.firstname + " " + contentWriters.data[index].user.lastname}</option>)
                }
            }

            /*for (let number = 1; number <= projects.meta.last_page; number++) {
                paginationItems.push(
                    <Pagination.Item key={number} active={number === active} onClick={() => {
                        this.props.initProjects(number);
                    }}>
                        {number}
                    </Pagination.Item>,
                );
            }*/
        }

        return (
            <>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} className={"col-lg-6 col-md-6 col-6"} controlId="formGridProject">
                            <Form.Control as="select" value={currentContentWriterId} onChange={e => handleContentWriterChange(e.target.value)}>
                                {/*<option>Choose current project:</option>*/}
                                {rows}
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                </Form>
            </>
        );
    }
}

const mapStateToProps = ({ ordersAdmin: { contentWriters }}) => ({
    contentWriters
});

export default injectIntl(connect(
    mapStateToProps,
    orders.actions
)(ActiveUserContentWriterSelect));
