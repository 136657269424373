import React from "react";
import {Portlet, PortletBody, PortletFooter, PortletHeader} from "../../../partials/content/Portlet";
import InnerModal from "../analytics-components/InnerModal";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as analytics from "../../../store/ducks/analytics.duck";
import {Button, ButtonToolbar} from "react-bootstrap";

class AddTrackingCodeStep extends React.Component {
    componentDidMount() {
        const currentProjectId = this.props.currentProject.data.id;
        if (currentProjectId > 0) {
            this.props.requestAnalyticsSite(currentProjectId);
        }
    }

    render() {
        const {currentProject, site} = this.props;

        return (<>
            <Portlet>
                <PortletHeader title={this.props.currentProject.data.url + " - Add Tracking Code"} />
                <PortletBody>
                    <InnerModal
                        site={site}
                        currentProject={currentProject.data}
                    />
                </PortletBody>
                <PortletFooter>
                    <ButtonToolbar className="justify-content-end">
                        <Button variant="secondary" onClick={this.props.nextStep}>
                            {this.props.currentProject.data.tracking_code_added ? "Next" : "Skip"}
                        </Button>
                    </ButtonToolbar>
                </PortletFooter>
            </Portlet>
        </>);
    }
}

const mapStateToProps = ({ analytics: {site} }) => ({
    site
});

export default injectIntl(connect(
    mapStateToProps,
    analytics.actions
)(AddTrackingCodeStep));
