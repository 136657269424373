import React, {Component} from "react";
import {Portlet, PortletBody, PortletHeader} from "../../../../partials/content/Portlet";
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default class GuestPostInfo extends Component {
    render() {
        return (<>
            <Portlet>
                <PortletHeader title="Frequently Asked Questions" />
                <PortletBody className="p-0">
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography style={{fontSize:16,fontWeight:"bold"}}>Can I see live samples of the guest posts?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                <p>Yes, here are a few of our live samples:<br/>
                                    <a href="https://www.corecommerce.com/blog/8-highly-effective-linkbuilding-strategies-that-will-help-increase-your-traffic/" target="_blank" rel="noopener nofollow">Sample #1</a><br/>
                                    <a href="https://www.sitepronews.com/2021/05/04/discover-what-the-google-page-experience-update-is-all-about/" target="_blank" rel="noopener nofollow">Sample #2</a><br/>
                                    <a href="https://www.themarketingblog.co.uk/2020/04/helpful-tips-on-how-to-make-your-guest-posts-more-seo-friendly-in-2020/" target="_blank" rel="noopener nofollow">Sample #3</a><br/>
                                    <a href="https://thevisualcommunicationguy.com/2021/05/04/top-places-to-find-and-buy-expired-domains/" target="_blank" rel="noopener nofollow">Sample #4</a>
                                </p>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography style={{fontSize:16,fontWeight:"bold"}}>What is the turnaround time?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                <p>Anywhere between 2 - 4 weeks, depending on the size of your order.</p>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography style={{fontSize:16,fontWeight:"bold"}}>Are all of the guest post links do-follow, and will they remain permanently?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                <p>Yes, all of the guest post links are do-follow. If the owner of the site changes it in no-follow, we will contact them to change it. We can’t guarantee that your link will stay do-follow permanently, since the blogger can decide to change it in the future.</p>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography style={{fontSize:16,fontWeight:"bold"}}>When can I see results of this service?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                <p>This is the most common question we get. You can see results between 2 to 5 weeks, after the guest posts are placed. It depends on multiple factors like proper on-site optimization, competition level and the consistency of your linkbuilding.</p>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </PortletBody>
            </Portlet>
        </>);
    }
}
