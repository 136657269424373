import React, { Component } from "react";
import {Row,Col,Nav,Tab,Card} from "react-bootstrap";
import "../../../../_metronic/_assets/sass/custom/service_tabs.scss";
import {Link} from "react-router-dom";

export default class SeoServices extends Component {
    render() {
        return(
            <div className="service-tabs">
                <Tab.Container id="left-tabs" defaultActiveKey="first">
                    <Row>
                        <Col xl={2} sm={3}>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <h1>Seo Services</h1>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="first">On-Page Optimization</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">Linkbuilding</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third">Google Penalty Recovery</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={()=>{window.open('https://www.serpchampion.com/whitelabel-seo-partner/', '_blank');}}>Whitelabel SEO</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col xl={10} sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className="row">
                                        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 kt-mb-10">
                                            <Card className="h-100">
                                                <Card.Body>
                                                    <Card.Title className="text-center">On-Page Optimization</Card.Title>
                                                    <Card.Text>
                                                        On-page optimization is an essential part of your overall SEO campaign.
                                                        Proper optimization maximizes your potential to dominate in the search engines.
                                                    </Card.Text>
                                                    <div className="cl-container">
                                                        <Link className="btn btn-primary" to="/seo-services/on-page-optimization">Get Started</Link>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <div className="row">
                                        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 kt-mb-10">
                                            <Card className="h-100">
                                                <Card.Body>
                                                    <Card.Title className="text-center">Done-For-You Guest Posts</Card.Title>
                                                    <Card.Text>
                                                        Let us take care of your guest posts completely, and we'll get you placed on the best blogs for your site.
                                                        You'll only get placed on websites that generate thousands of visitors per month.
                                                    </Card.Text>
                                                    <div className="cl-container">
                                                        <Link className="btn btn-primary" to="/orders/guest-post-dfy">Get Started</Link>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 kt-mb-10">
                                            <Card className="h-100">
                                                <Card.Body>
                                                    <Card.Title className="text-center">Authority Link Insertions</Card.Title>
                                                    <Card.Text>
                                                        Get link insertions from real sites with organic traffic and high authority.
                                                        These links pack a punch and will move your site up in the SERPs faster than guest posts.
                                                    </Card.Text>
                                                    <div className="cl-container">
                                                        <Link className="btn btn-primary" to="/seo-services/link-insertion">Get Started</Link>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 kt-mb-10">
                                            <Card className="h-100">
                                                <Card.Body>
                                                    <Card.Title className="text-center">Business Citations</Card.Title>
                                                    <Card.Text>
                                                        Citations help users to discover local businesses and can also impact local search engine rankings.
                                                        Citations are a great white-hat strategy for building up your link profile.
                                                    </Card.Text>
                                                    <div className="cl-container">
                                                        <Link className="btn btn-primary" to="/seo-services/citation">Get Started</Link>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <div className="row">
                                        <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 kt-mb-10">
                                            <Card className="h-100">
                                                <Card.Body>
                                                    <Card.Title className="text-center">Link Clean-Up</Card.Title>
                                                    <Card.Text>
                                                        Did your site suffer a significant drop in the organic search results? That is probably because of a Google Algorithm update, an algorithmic penalty, or a Google employee's manual action.
                                                        More than 95% of penalties are usually caused by bad backlinks to your site. You might be wondering what is next?
                                                    </Card.Text>
                                                    <div className="cl-container">
                                                        <Link className="btn btn-primary" to="/seo-services/link-clean-up">Get Started</Link>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        );
    }
}
