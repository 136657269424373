import React from "react";
import {Route, Switch} from "react-router-dom";
import UserPackageDetails from "./UserPackageDetails";

export default function UserPackagesPage() {
    return (
        <Switch>
            <Route
                path="/user-packages/:id"
                component={UserPackageDetails}
            />
        </Switch>
    );
}
