import React, { Component } from "react";
import {Portlet, PortletBody} from "../../../partials/content/Portlet";
import {
    FormControl,
    List,
    ListItem,
    ListItemText,
    Slider, Step, StepLabel, Stepper,
    Typography
} from "@material-ui/core";
import {Button, Card} from "react-bootstrap";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as cart from "../../../store/ducks/cart.duck";
import ReactGA from "react-ga4";
import * as pagedata from "../../../store/ducks/page.duck";
import * as packages from "../../../store/ducks/packages.duck";
import {Spinner} from "reactstrap";
import {calculateCartDiscountAmount} from "../../../custom_functions/guest_posts";
import Select from "react-select";
import TextInfoModal from "../profile-components/TextInfoModal";
import AddOnModal from "../AddOnModal";

const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

const minQ = 1;
const maxQ = 100;
const stepQ = 1;
const marksQ = [
    {
        value: minQ,
        label: minQ.toString(),
    },
    {
        value: maxQ,
        label: maxQ.toString(),
    },
];

const typeOptions = [
    {
        value: 'pro',
        label: 'Pro',
    },
    {
        value: 'agency',
        label: 'Agency',
    },
    {
        value: 'enterprise',
        label: 'Enterprise',
    },
];

function valuetextType(value) {
    return `${value} package`;
}

function valuetextQ(value) {
    return `${value} page${value !== 1 ? 's' : ''}`;
}

class PressRelease extends Component {
    constructor(props) {
        super(props);

        this.state = {
            valueQ: 1,
            valueP: "purchase",
            selectedOption: {
                value: "pro",
                label: "Pro",
            },
            openModal: false,
            openAddOns: false,
            cartItemHolder: null
        };
    }

    componentDidMount() {
        const pageName = 'press-release';
        const type = 'price_package_press_release';
        this.props.requestPageData(pageName);
        this.props.requestUserDiscount();
        this.props.requestPackage(type);
        this.props.requestPackagePreimumMembership("marketplace_guest_post_access");
    }

    render() {
        const {valueQ, selectedOption, openAddOns, cartItemHolder} = this.state;
        const {user, pageData, userDiscountPercent: userDiscount, currentPackage} = this.props;

        let addOnItems = null;

        if(!currentPackage) {
            return <Spinner />;
        }
        else if(currentPackage.currentPackage && !currentPackage.currentPackage.type) {
            return <h1>Package not found!</h1>;
        }

        let premiumMembershipPackage = undefined;
        if (currentPackage.premiumMembershipPackage) {
            premiumMembershipPackage = currentPackage.premiumMembershipPackage;
        }

        let isPremiumMember = false;
        let currentPremiumLevel = "Free";

        if (typeof premiumMembershipPackage !== 'undefined') {
            if (typeof premiumMembershipPackage.activePackage !== 'undefined') {
                if (premiumMembershipPackage.activePackage.name != "Free") {
                    isPremiumMember = true;
                    currentPremiumLevel = premiumMembershipPackage.activePackage.name;
                }
            }
        }

        const auditPrice = currentPackage.currentPackage && currentPackage.currentPackage[this.state.selectedOption.value] ? currentPackage.currentPackage[this.state.selectedOption.value] : 0;

        const addOnsClose = (e) => {
            this.setState({...this.state, openAddOns: false});
        }

        const handleSliderChangeQ = (event, newValue) => {
            this.setState({...this.state, valueQ: newValue});
        };

        const handleChangeType = (option) => {
            this.setState({...this.state, selectedOption: option});
        };

        const calcPrice = () => {
            let price = 0;
            let itemPrice = auditPrice;
            let discountAmount = 0;

            price = itemPrice * valueQ;

            const item = {
                price: itemPrice.toFixed(2),
                orderData: {
                    subscription: 0,
                    quantity: valueQ,
                    type: "press_release",
                    options: {
                        package: selectedOption.value
                    },
                    add_ons: []
                },
            };

            discountAmount = calculateCartDiscountAmount([item], userDiscount);
            price -= discountAmount;

            return {itemPrice: itemPrice, calculatedPrice: price, discountAmount: discountAmount};
        };

        const {itemPrice, calculatedPrice, discountAmount} = calcPrice();

        let maxDiscountPercent = 0.15;
        let maxDiscountAmount = maxDiscountPercent * calculatedPrice;
        let maxPremiumPrice = calculatedPrice - maxDiscountAmount;

        const addToCart = (orderNow = false) => {

            const item = {
                key: "pr_" + selectedOption.value,
                price: itemPrice.toFixed(2),
                name: `Press Release (${selectedOption.label})`,
                description: "",
                orderData: {
                    subscription: 0,
                    quantity: valueQ,
                    type: "press_release",
                    options: {
                        package: selectedOption.value
                    },
                    add_ons: []
                },
            };

            ReactGA.event({
                category: 'Cart',
                action: 'add_to_cart',
                label: 'Add to Cart',
                nonInteraction: false
            });

            this.setState({...this.state, openAddOns: true, cartItemHolder: item});

            if(orderNow) {
                //this.props.hideCart();
                this.props.openCheckout();
            }

            this.setState({...this.state, openAddOns: true, cartItemHolder: item});
        };

        const modalOnClose = (e) => {
            this.setState({...this.state, openModal: false});
        }

        const openTextModal = (e) => {
            this.setState({...this.state, openModal: true});
        }

        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="progress progressBar TmoProgressBar">
                            <div className="progress-bar" role="progressbar"
                                 aria-valuenow={(user.tmo_amount ? user.tmo_amount : 0)} aria-valuemin="0"
                                 aria-valuemax={(user.tmo_progress_bar_total ? user.tmo_progress_bar_total : 10000)}
                                 style={{width: (user.tmo_percentage ? user.tmo_percentage : 0) + '%'}}>
                                <span
                                    className="progress-label">{(user.tmo_amount ? '$' + user.tmo_amount.toLocaleString(undefined, {maximumFractionDigits: 2}) : '$0.00')}</span>
                            </div>
                            <div className="first_label">This Month's Spend</div>
                            <div className="second_label">
                                <div className="vertical_line">|</div>
                                $1,000
                            </div>
                            <div className="third_label">
                                <div className="vertical_line">|</div>
                                $5,000
                            </div>
                            <div className="fourth_label">
                                <div className="vertical_line">|</div>
                                $10,000
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row pt-2" style={{marginLeft: 0, marginRight: 0}}>
                    <div className="col-lg-6 col-md-8" style={{paddingLeft: 0, paddingRight: 0}}>
                        <Portlet>
                            <PortletBody className="mw-position-orders-area-wide" style={{padding: "40px"}}>
                                <h4 className="mb-3">Press Release Service</h4>
                                <p dangerouslySetInnerHTML={{__html: pageData ? pageData.top_text : ""}}/>
                                <h5 className="mb-0 mt-2">How it works</h5>
                                <Stepper orientation="vertical">
                                    <Step active={true} key="step1">
                                        <StepLabel>
                                            <h6 className="mt-2 ml-2">Choose Press Release Package</h6>
                                        </StepLabel>
                                    </Step>
                                    <Step active={true} key="step2">
                                        <StepLabel>
                                            <h6 className="mt-2 ml-2">Provide Details After Checkout</h6>
                                        </StepLabel>
                                    </Step>
                                    <Step active={true} key="step3">
                                        <StepLabel>
                                            <h6 className="mt-2 ml-2">Receive Deliverables Within 15 Days</h6>
                                        </StepLabel>
                                    </Step>
                                </Stepper>
                                <div className="row mt-2">
                                    <div className="col-12 text-center mt-4">
                                        <FormControl component="fieldset" className="kt-margin-b-15 w-75">
                                            <Typography className="font-weight-bold kt-margin-b-0" gutterBottom>Choose Package</Typography>

                                            <span onClick={openTextModal} className="link font-weight-light kt-font-sm kt-margin-b-15">
                                                Learn More
                                            </span>

                                            <TextInfoModal packageType={this.state.selectedOption.label} description={ currentPackage?.currentPackage?.['description_' + this.state.selectedOption.value] ?? ''} open={this.state.openModal} onClose={modalOnClose} />

                                            <Select
                                                options={typeOptions}
                                                defaultValue={ typeOptions.find(option => option.value === 'pro') }
                                                onChange={selectedOption => {
                                                    handleChangeType(selectedOption);
                                                }}
                                                styles={selectStyles}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center mt-4 mw-position-orders-area">
                                        <Typography className="font-weight-bold" id="post-quantity-label" gutterBottom>
                                            Quantity
                                        </Typography>
                                        <div className="text-right"><strong
                                            style={{fontSize: "15px"}}>{valueQ}</strong>
                                        </div>
                                        <Slider
                                            defaultValue={minQ}
                                            getAriaValueText={valuetextQ}
                                            aria-labelledby="page-quantity-label"
                                            valueLabelDisplay="auto"
                                            step={stepQ}
                                            marks={marksQ}
                                            min={minQ}
                                            max={maxQ}
                                            onChange={handleSliderChangeQ}
                                        />
                                    </div>
                                </div>
                            </PortletBody>
                        </Portlet>
                    </div>
                    <div className="col-lg-4 col-md-4 pl-4">
                        <Card style={{padding: "10px"}}>
                            <Card.Body>
                                <Card.Title className="mb-4">You've selected</Card.Title>
                                <strong>Press Release</strong>
                                <List>
                                    <ListItem>
                                        <ListItemText
                                            primary={valuetextType(selectedOption.label)}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary={valuetextQ(valueQ)}
                                        />
                                    </ListItem>
                                </List>
                                <hr/>

                                {isPremiumMember && discountAmount > 0 ? <Typography>Your Investment</Typography> :
                                    <Typography>Your Investment</Typography>}
                                <Typography variant="h5">${calculatedPrice.toFixed(2)}</Typography>

                                <div className="kt-margin-b-15"/>

                                <Button onClick={() => {
                                    addToCart();
                                }} style={{minWidth: "170px"}} size="lg" disabled={calculatedPrice === 0}>Add to
                                    Cart</Button>
                            </Card.Body>
                        </Card>

                        <Portlet style={{padding: "10px", background: "#f2f3f8"}}>
                            <div className="row h-100" style={{marginLeft: 0, marginRight: 0}}>
                                <div className="col-12 h-100" style={{paddingLeft: 0, paddingRight: 0}}>
                                    <Portlet className="h-100 kt-mb-0" style={{background: "#e7eff3"}}>
                                        <PortletBody
                                            dangerouslySetInnerHTML={{__html: pageData ? pageData.top_right_text : ""}}/>
                                    </Portlet>
                                </div>
                            </div>
                        </Portlet>
                    </div>
                </div>
                <AddOnModal open={openAddOns} onClose={addOnsClose} addOnItems={addOnItems} item={cartItemHolder} />
            </>
        );
    }
}

const mapStateToProps = ({ auth: { user }, pagedata: {pageData}, cart: {userDiscountPercent}, packages: currentPackage}) => ({
    user, pageData, currentPackage, userDiscountPercent
});

export default injectIntl(connect(
    mapStateToProps,
    {...cart.actions, ...pagedata.actions, ...packages.actions}
)(PressRelease));
