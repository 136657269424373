import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getOrders, getOrder, getInvoice, getSalesInvoice, getWritersActive, getOrdersWithArgs } from "../../../crud/admin/orders.crud";

export const actionTypes = {
    OrdersInit: "[Init Orders Admin] Action",
    OrdersInitWithArgs: "[Init Orders With Args Admin] Action",
    OrdersRequested: "[Request Orders Admin] Action",
    OrdersRequestedWithArgs: "[Request Orders With Args Admin] Action",
    OrdersLoaded: "[Load Orders Admin] Orders API",
    OrdersLoadedWithArgs: "[Load Orders With Args Admin] Orders API",
    OrderRequested: "[Request Order Admin] Action",
    OrderLoaded: "[Load Order Admin] Orders API",
    InvoiceRequested: "[Request Invoice Admin] Action",
    InvoiceLoaded: "[Load Invoice Admin] Action",
    SalesInvoiceRequested: "[Request Sales Invoice Admin] Action",
    SalesInvoiceLoaded: "[Load Sales Invoice Admin] Action",
    ContentWritersInit: "[Init Content Writers Admin] Action",
    ContentWritersRequested: "[Request Content Writers Admin] Action",
    ContentWritersLoaded: "[Load Content Writers Admin] Action"
};

const initialOrdersState = {
    orders: undefined,
    currentOrder: undefined,
    invoice: undefined,
    salesInvoice: undefined,
    contentWriters: undefined,
};

export const reducer = persistReducer(
    { storage, key: "admin-orders", whitelist: ["orders", "currentOrder", "invoice", "salesInvoice", "contentWriters"] },
    (state = initialOrdersState, action) => {
        switch (action.type) {
            case actionTypes.OrdersInit: {
                return { orders: undefined, currentOrder: undefined, invoice: undefined };
            }

            case actionTypes.OrdersInitWithArgs: {
                return { orders: undefined, currentOrder: undefined, invoice: undefined };
            }

            case actionTypes.OrdersLoaded: {
                const { orders } = action.payload;

                return { ...state, orders };
            }

            case actionTypes.OrdersLoadedWithArgs: {
                const { orders } = action.payload;

                return { ...state, orders };
            }

            case actionTypes.OrderRequested: {
                return { ...state, currentOrder: undefined };
            }

            case actionTypes.OrderLoaded: {
                const { currentOrder } = action.payload;

                return { ...state, currentOrder };
            }

            case actionTypes.InvoiceRequested: {
                return { ...state, invoice: undefined };
            }

            case actionTypes.InvoiceLoaded: {
                const { invoice } = action.payload;

                return { ...state, invoice };
            }

            case actionTypes.SalesInvoiceRequested: {
                return { ...state, salesInvoice: undefined };
            }

            case actionTypes.SalesInvoiceLoaded: {
                const { salesInvoice } = action.payload;
                return { ...state, salesInvoice };
            }

            case actionTypes.ContentWritersRequested: {
                return { ...state, contentWriters: undefined };
            }

            case actionTypes.ContentWritersLoaded: {
                const { contentWriters } = action.payload;
                return { ...state, contentWriters };
            }

            default:
                return state;
        }
    }
);

export const actions = {
    initOrders: page => ({ type: actionTypes.OrdersInit, payload: { page } }),
    initOrdersWithArgs: (page, month, year) => ({ type: actionTypes.OrdersInitWithArgs, payload: { page, month, year } }),
    requestOrders: page => ({ type: actionTypes.OrdersRequested, payload: { page } }),
    requestOrdersWithArgs: (page, month, year) => ({ type: actionTypes.OrdersRequestedWithArgs, payload: { page, month, year } }),
    fulfillOrders: orders => ({ type: actionTypes.OrdersLoaded, payload: { orders } }),
    fulfillOrdersWithArgs: orders => ({ type: actionTypes.OrdersLoadedWithArgs, payload: { orders } }),
    requestOrder: id => ({ type: actionTypes.OrderRequested, payload: { id } }),
    fulfillOrder: currentOrder => ({ type: actionTypes.OrderLoaded, payload: { currentOrder } }),
    requestInvoice: id => ({ type: actionTypes.InvoiceRequested, payload: { id } }),
    requestSalesInvoice: id => ({ type: actionTypes.SalesInvoiceRequested, payload: { id } }),
    fulfillInvoice: invoice => ({ type: actionTypes.InvoiceLoaded, payload: { invoice } }),
    fulfillSalesInvoice: salesInvoice => ({ type: actionTypes.SalesInvoiceLoaded, payload: { salesInvoice } }),
    initContentWriters: page => ({ type: actionTypes.ContentWritersInit, payload: { page } }),
    requestContentWriters: page => ({ type: actionTypes.ContentWritersRequested, payload: { page } }),
    fulfillContentWriters: contentWriters => ({ type: actionTypes.ContentWritersLoaded, payload: { contentWriters } }),
};

export function* saga() {
    yield takeLatest(actionTypes.OrdersInit, function* initOrdersSaga(action) {
        const { page } = action.payload;
        yield put(actions.requestOrders(page));
    });

    yield takeLatest(actionTypes.OrdersInitWithArgs, function* initOrdersWithArgsSaga(action) {
        const { page, month, year } = action.payload;
        yield put(actions.requestOrdersWithArgs(page, month, year));
    });

    yield takeLatest(actionTypes.OrdersRequested, function* ordersRequested(action) {
        const { page } = action.payload;
        const {data: orders} = yield getOrders(page);

        yield put(actions.fulfillOrders(orders));
    });

    yield takeLatest(actionTypes.OrdersRequestedWithArgs, function* ordersRequestedWithArgs(action) {
        const { page, month, year } = action.payload;
        const {data: orders} = yield getOrdersWithArgs(page, month, year);
        yield put(actions.fulfillOrdersWithArgs(orders));
    });

    yield takeLatest(actionTypes.OrderRequested, function* orderRequested(action) {
        try {
            const { id } = action.payload;
            const {data: currentOrder} = yield getOrder(id);

            yield put(actions.fulfillOrder(currentOrder));
        }
        catch (e) {
            yield put(actions.fulfillOrder({data: undefined}));
        }
    });

    yield takeLatest(actionTypes.InvoiceRequested, function* invoiceRequested(action) {
        try {
            const { id } = action.payload;
            const {data: invoice} = yield getInvoice(id);

            yield put(actions.fulfillInvoice(invoice));
        }
        catch (e) {
            yield put(actions.fulfillInvoice({data: undefined}));
        }
    });

    yield takeLatest(actionTypes.SalesInvoiceRequested, function* salesInvoiceRequested(action) {
        try {
            const { id } = action.payload;
            const {data: salesInvoice} = yield getSalesInvoice(id);

            yield put(actions.fulfillSalesInvoice(salesInvoice));
        }
        catch (e) {
            yield put(actions.fulfillSalesInvoice({data: undefined}));
        }
    });

    yield takeLatest(actionTypes.ContentWritersInit, function* initContentWritersSaga(action) {
        const { page } = action.payload;
        yield put(actions.requestContentWriters(page));
    });    

    yield takeLatest(actionTypes.ContentWritersRequested, function* contentWritersRequested(action) {
        try {
            const { id } = action.payload;
            const {data: contentWriters} = yield getWritersActive(id);

            yield put(actions.fulfillContentWriters(contentWriters));
        }
        catch (e) {
            yield put(actions.fulfillContentWriters({data: undefined}));
        }
    });

}
