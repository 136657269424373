import React, { Component } from "react";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Spinner} from "reactstrap";
import * as orders from "../../store/ducks/admin/orders.duck";
import {Portlet, PortletBody, PortletHeader} from "../../partials/content/Portlet";
import "../../../_metronic/_assets/sass/custom/invoice.scss";

class SaleInvoiceAdmin extends Component {
    componentDidMount() {
        const { orderLineNumber } = this.props.match.params;
        // console.log(this.props);
        this.props.requestSalesInvoice(orderLineNumber);
    }

    render() {
        console.log(this.props)
        const invoice = this.props.salesInvoice;
        let itemsTotal = 0;
        let discountAmount = 0;

        if (!invoice) {
            return <Spinner/>;
        }
        else if(!invoice.data) {
            return (
                <>
                    <h1>Invoice not found!</h1>
                </>
            );
        }


// console.log(invoice.data);
        const invoiceItems = [];
        // if (invoice.data.items.length !== 0) {
            // for (const [index] of Object.entries(invoice.data.items)) {
            	let index = 0;

                let name = "";
                // if(invoice.data.type === "tech_audit_package" ||
                //     invoice.data.type === "analytics_package" ||
                //     invoice.data.type === "custom_service" ||
                //     invoice.data.type === "content_package" ||
                //     invoice.data.type === "managed_seo_package" ||
                //     invoice.data.type === "guest_post_service_package" ||
                //     invoice.data.type === "marketplace_guest_post_access"
                // ) {
                //     name = invoice.data.name;
                // }
                // else if(invoice.data.type === "guest_post") {
                    name = invoice.data.website + " Guest Post for " + invoice.data.url;
                // }
                // else if(invoice.data.type === "audit_per_page") {
                //     name = "On-Page Optimization (per page)";
                // }
                // else if(invoice.data.type === "link_clean_up") {
                //     name = "Link Clean-Up";
                // }
                // else if(invoice.data.type === "link_insertion") {
                //     name = "Link Insertion";
                // }
                // else if(invoice.data.type === "citation") {
                //     name = "Citation";
                // }
                // else if(invoice.data.type === "article") {
                //     name = "Article";
                // }
                invoiceItems.push(<tr key={index}>
                    <td>{name}</td>
                    <td className="m--font-danger">{invoice.data.seller_earnings && invoice.data.seller_earnings.toFixed(2)} {invoice.data.currency}</td>
                </tr>);

                // if(invoice.data.type === "citation") {
                //     itemsTotal += invoice.data.seller_earnings;
                // }
                // else {
                    itemsTotal += invoice.data.seller_earnings * invoice.data.quantity;
                // }
            // }
        // }

        if(invoice.data.total && invoice.data.total >= 0) {
            discountAmount = itemsTotal - invoice.data.total;
        }

        // const date = new Date(invoice.data.created_at);
        // console.log(invoice.data.created_at);

        return (
            <>
                <Portlet>
                    <PortletHeader title={"Content Writer Invoice #" + invoice.data.id} />
                    <PortletBody>
                        <div className="m-invoice-2">
                            <div className="m-invoice__wrapper">
                                <div className="m-invoice__head">
                                    <div className="m-invoice__container m-invoice__container--centered">
                                        <div className="m-invoice__logo">
                                            <a href="#">
                                                <h1>INVOICE</h1>
                                            </a>
                                            <a href="#">
                                                <img src="/media/logos/blue_logo.png" />
                                            </a>
                                        </div>
                                        <span className="m-invoice__desc">
                                            <span>{invoice.data.invoice_data && invoice.data.invoice_data.company}</span>
                                            <span>{invoice.data.invoice_data && invoice.data.invoice_data.address}</span>
                                            <span>{invoice.data.invoice_data && invoice.data.invoice_data.postal_code} {invoice.data.invoice_data && invoice.data.invoice_data.city}</span>
                                            <span>{invoice.data.invoice_data && invoice.data.invoice_data.country}</span>
                                            <br/>
                                            <span>BTW: {invoice.data.invoice_data && invoice.data.invoice_data.vat}</span>
                                            <span>KvK: {invoice.data.invoice_data && invoice.data.invoice_data.kvk}</span>
                                        </span>
                                        <div className="m-invoice__items">
                                            <div className="m-invoice__item">
                                                <span className="m-invoice__subtitle">INVOICE TO.</span>
                                                <span className="m-invoice__text">
                                                     {invoice.data.billing_firstname} {invoice.data.billing_lastname}<br/>
                                                     {invoice.data.company_name}<br/>
                                                     {invoice.data.address}<br/>
                                                     {invoice.data.city}<br/>
                                                     {invoice.data.postal_code}<br/>
                                                     {invoice.data.country}<br/>
                                                     {invoice.data.tax_id && 'VAT #: ' + invoice.data.tax_id}
                                                </span>
                                            </div>
                                            <div className="m-invoice__item">
                                                <span className="m-invoice__subtitle">DATE</span>
                                                <span
                                                    className="m-invoice__text">{invoice.data.created_at}</span>
                                            </div>
                                            <div className="m-invoice__item">
                                                <span className="m-invoice__subtitle">INVOICE NO.</span>
                                                <span className="m-invoice__text">{invoice.data.order_line_number}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="m-invoice__body m-invoice__body--centered">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th>PRODUCT</th>
                                                <th>PRICE</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {invoiceItems}
                                            </tbody>
                                        </table>
                                        {discountAmount > 0 && <table className="table">
                                            <thead>
                                            <tr>
                                                <th className="text-right kt-padding-r-0">DISCOUNT</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td className="text-right kt-padding-r-0">{"-" + discountAmount.toFixed(2)} {invoice.data.currency}</td>
                                            </tr>
                                            </tbody>
                                        </table>}
                                    </div>
                                </div>
                                <div className="m-invoice__footer">
                                    <div className="m-invoice__table  m-invoice__table--centered table-responsive">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                {invoice.data.payment_method && <th>PAYMENT METHOD</th>}
                                                <th>SALE DATE</th>
                                                <th>INVOICE DATE</th>
                                                <th>TOTAL</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                {invoice.data.payment_method && <td>{invoice.data.payment_method}</td>}
                                                <td>{invoice.data.created_at}</td>
                                                <td>{invoice.data.created_at}</td>
                                                <td className="m--font-danger">{invoice.data.seller_earnings.toFixed(2)} {invoice.data.currency}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br/><br/>
                                        <div className="text-center">
                                            <b>
                                                {invoice.data.reverse_vat && invoice.data.reverse_vat !== 0 && "VAT Reverse Charge: " + invoice.data.tax_id}
                                            </b>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </PortletBody>
                </Portlet>
            </>
        );
    }
}

const mapStateToProps = ({ ordersAdmin: { salesInvoice } }) => ({
    salesInvoice
});

export default injectIntl(connect(
    mapStateToProps,
    orders.actions
)(SaleInvoiceAdmin));
