import React, {Component} from "react";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as services from "../../store/ducks/admin/services.duck";
import {Portlet, PortletBody} from "../../partials/content/Portlet";
import {Spinner} from "reactstrap";
import {Breadcrumbs, Checkbox, FormControlLabel, TextField, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {Button, Form} from "react-bootstrap";
import {Formik} from "formik";
import {editCitationService} from "../../crud/admin/orders.crud";

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

class CitationAdmin extends Component {
    componentDidMount() {
        const { id } = this.props.match.params;
        const key = "citation";
        this.props.requestService(id, key);
    }

    render() {
        const seoService = this.props.currentService ? this.props.currentService["citation"] : null;
        const orderNumber = seoService ? seoService.order_number : "";

        if (!seoService) {
            return <Spinner/>;
        }
        else if(!seoService.data) {
            return (
                <>
                    <h1>Seo Service not found!</h1>
                </>
            );
        }

        const cRows = [];
        if(seoService.data.fields && seoService.data.fields.length !== 0) {
            for (const [index] of Object.entries(seoService.data.fields)) {
                if(seoService.data.fields[index]) {
                    if(index === "business_logo" || index.substr(0, 5) === "image") {
                        cRows.push(<li
                            key={index}>{capitalizeFirstLetter(index.replace("_", " "))}:<br/><img src={seoService.data.fields[index]} alt="" style={{maxWidth:"350px",maxHeight:"350px"}} />
                        </li>);
                    }
                    else {
                        cRows.push(<li
                            key={index}>{capitalizeFirstLetter(index.replace("_", " "))}: <strong>{seoService.data.fields[index]}</strong>
                        </li>);
                    }
                }
            }
        }

        return(<>
            <Portlet>
                <PortletBody>
                    <Breadcrumbs aria-label="Breadcrumb" className="kt-margin-b-15">
                        <Link color="inherit" to="/admin/orders">
                            Orders
                        </Link>
                        <Link color="inherit" to={"/admin/orders/" + seoService.data.order_id + "/progress"}>
                            Order #{orderNumber}
                        </Link>
                        <Typography color="textPrimary">Citations #{seoService.data.id}</Typography>
                    </Breadcrumbs>
                    <Typography variant="h5">Citations #{seoService.data.id} (Order #{orderNumber})</Typography>
                    <ul className="kt-margin-t-10 kt-margin-b-30">
                        <li>Amount: <strong>{seoService.data.amount}</strong></li>
                        <li className={seoService.data.google_business ? "" : "d-none"}>Google my Business – SEO Optimization: <strong>{seoService.data.google_business ? "✔": "no"}</strong></li>
                        {cRows}
                    </ul>

                    <Formik
                        initialValues={{
                            build: parseInt(seoService.data.build) === 1
                        }}
                        validate={values => {
                            const errors = {};

                            return errors;
                        }}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            let cData = {
                                build: values.build ? 1 : 0
                            };
                            editCitationService(seoService.data.id, cData)
                                .then((res) => {
                                    setStatus(
                                        "Saved."
                                    );
                                    setSubmitting(false);
                                })
                                .catch((e) => {
                                    setSubmitting(false);
                                    let errorMsg = "Error! Data is not updated.";
                                    if(e.response.data) {
                                        if(e.response.data.error) {
                                            errorMsg = e.response.data.error;
                                        }
                                    }
                                    setStatus(
                                        errorMsg
                                    );
                                });
                        }}
                    >
                        {({
                              values,
                              status,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting
                          }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                {status && !isSubmitting && (
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <div role="alert" className={'alert ' + (status === 'Saved.' ? 'alert-success' : 'alert-danger')}>
                                                <div className="alert-text">{status}</div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-xl-6">
                                        <Typography>Done</Typography>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.build}
                                                    onChange={handleChange}
                                                    name="build"
                                                    value={values.build}
                                                    color="primary"
                                                />
                                            }
                                            label=""
                                        />
                                    </div>
                                </div>
                                <Button className="kt-mr-5" type="submit" variant="primary" onClick={handleSubmit} disabled={isSubmitting}>
                                    {!isSubmitting ? 'Submit' : (<Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />)}
                                </Button>
                                <Link to={"/admin/orders/" + seoService.data.order_id + "/progress"}>
                                    <Button variant="secondary">Back</Button>
                                </Link>
                            </Form>
                        )}
                    </Formik>
                </PortletBody>
            </Portlet>
        </>);
    }
}

const mapStateToProps = ({ servicesAdmin: { currentService } }) => ({
    currentService
});

export default injectIntl(connect(
    mapStateToProps,
    services.actions
)(CitationAdmin));
