import React, {Component} from "react";
import "../../../../_metronic/_assets/sass/pages/todo/todo.scss";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {Portlet, PortletBody} from "../../../partials/content/Portlet";
import {Link} from "react-router-dom";
import {Col, Row, Tabs, Tab, TabContent, TabPane} from "react-bootstrap";
import VisitorsCharts from "./metrics/VisitorsCharts";
import VisitorsNums from "./metrics/VisitorsNums";
import BounceRate from "./metrics/BounceRate";
import Tallies from "./metrics/Tallies";
import NotesList from "./NotesList"
import GoogleUpdateList from "./GoogleUpdateList"
import IncomingBacklinks from "./IncomingBacklinks"
import Pages from "./site-data/Pages";
import EntrancePages from "./site-data/EntrancePages";
import ExitPages from "./site-data/ExitPages";
import Links from "./site-data/Links";
import Searches from "./site-data/Searches";
import SearchesKeywords from "./site-data/SearchesKeywords";
import Countries from "./site-data/Countries";
import TrafficSources from "./site-data/TrafficSources";



class AnalyticsDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {

        const {currentProject} = this.props;

        if (typeof(currentProject) == "undefined") {
            return <p>Data is empty</p>;
        }

        return (
            <>

            <Portlet>
                <PortletBody>
                    <Row>
                        <Col xs={12} md={3}>
                            <h3>Organic Visitors</h3><br />
                            <VisitorsNums
                                currentProject={currentProject}
                                days='20'
                            />
                        </Col>
                        <Col xs={12} md={9}>
                            <VisitorsCharts
                                date='last-7-days'
                                width="100%"
                                height="350"
                            />
                        </Col>
                    </Row>
                </PortletBody>
            </Portlet>


            <Portlet>
                <PortletBody>
                    <Row>
                        <Col xs={12} md={3}>
                            <Tallies
                                currentProject={currentProject}
                                days='20'
                                type='actions'
                                title='Number of visitor actions'
                            />

                        </Col>
                        <Col xs={12} md={3}>
                            <Tallies
                                currentProject={currentProject}
                                days='20'
                                type='actions-pageviews'
                                title='Pageviews'
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <BounceRate
                                currentProject={currentProject}
                                days='20'
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <Tallies
                                currentProject={currentProject}
                                days='20'
                                type='time-average'
                                title='Average time on site per visitor'
                            />
                        </Col>
                    </Row>
                </PortletBody>
            </Portlet>

            <Portlet>
                <PortletBody>
                    <Tabs defaultActiveKey="taskManager">
                        <Tab eventKey="taskManager" title="Task Manager"></Tab>
                        <Tab eventKey="googleUpdates" title="Google Updates"></Tab>
                        <Tab eventKey="pages" title="Top Content"></Tab>
                        <Tab eventKey="pages-entrance" title="Entrance/Landing Pages"></Tab>
                        <Tab eventKey="pages-exit" title="Exit Pages"></Tab>
                        <Tab eventKey="links" title="Incoming Links"></Tab>
                        <Tab eventKey="searches" title="Full Search Queries"></Tab>
                        <Tab eventKey="searches-keywords" title="Searches by Keyword"></Tab>
                        <Tab eventKey="countries" title="Countries"></Tab>
                        <Tab eventKey="traffic-sources" title="Traffic Sources"></Tab>

                        <TabPane eventKey="taskManager" >
                            <NotesList />
                        </TabPane>

                        <TabPane eventKey="googleUpdates" >
                            <GoogleUpdateList />
                        </TabPane>

                        <TabPane eventKey="pages">
                            <Pages />
                        </TabPane>

                        <TabPane eventKey="pages-entrance">
                            <EntrancePages />
                        </TabPane>

                        <TabPane eventKey="pages-exit">
                            <ExitPages />
                        </TabPane>

                        <TabPane eventKey="links">
                            <Links />
                        </TabPane>

                        <TabPane eventKey="searches">
                            <Searches />
                        </TabPane>

                        <TabPane eventKey="searches-keywords">
                            <SearchesKeywords />
                        </TabPane>

                        <TabPane eventKey="countries">
                            <Countries />
                        </TabPane>

                        <TabPane eventKey="traffic-sources">
                            <TrafficSources />
                        </TabPane>
                    </Tabs>
                </PortletBody>
            </Portlet>

            </>
        );
    }
}



const mapStateToProps = ({
    projects: { currentProject: {data: currentProject }},
    //analytics: { analytic, site, user, notes, googleupdates}
}) => ({
    //site, user, analytic, notes, currentProject, googleupdates
    currentProject
});

export default injectIntl(connect(
    mapStateToProps,
    null,
)(AnalyticsDetail));
