import React, { Component } from "react";
import {Portlet, PortletBody, PortletHeader} from "../../../partials/content/Portlet";
import {
    Checkbox,
    FormControl,
    FormControlLabel, FormLabel,
    List,
    ListItem,
    ListItemText, Radio, RadioGroup,
    Slider, Step, StepLabel, Stepper,
    Typography
} from "@material-ui/core";
import {Button, Card} from "react-bootstrap";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as cart from "../../../store/ducks/cart.duck";
import ReactGA from "react-ga4";
import * as pagedata from "../../../store/ducks/page.duck";
import * as packages from "../../../store/ducks/packages.duck";
import {Spinner} from "reactstrap";
import {calculateCartDiscountAmount} from "../../../custom_functions/guest_posts";
import { Link } from 'react-router-dom';

const baseQuantity = 1000;

const minQ = 1000;
const maxQ = 100000;
const stepQ = 1000;
const marksQ = [
    {
        value: minQ,
        label: minQ.toString(),
    },
    {
        value: maxQ,
        label: maxQ.toString(),
    },
];
function valuetextQ(value) {
    return `Up to ${value} referring domains`;
}

class LinkCleanUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            manualRemoval: false,
            valueQ: minQ,
            valueP: "purchase"
        };
    }

    componentDidMount() {
        // const pageName = 'link-clean-up';
        const pageName = 'backlink_cleanup';
        const type = 'price_package_link_clean_up';
        this.props.requestPageData(pageName);
        this.props.requestUserDiscount();
        this.props.requestPackage(type);
        this.props.requestPackagePreimumMembership("marketplace_guest_post_access");
    }

    render() {
        const {manualRemoval, valueQ, valueP} = this.state;
        const {user, pageData, userDiscountPercent: userDiscount, currentPackage} = this.props;

        if(!currentPackage) {
            return <Spinner />;
        }
        else if(currentPackage.currentPackage && !currentPackage.currentPackage.type) {
            return <h1>Package not found!</h1>;
        }

        let premiumMembershipPackage = undefined;
        if (currentPackage.premiumMembershipPackage) {
            premiumMembershipPackage = currentPackage.premiumMembershipPackage;
        }

        let isPremiumMember = false;
        let currentPremiumLevel = "Free";

        if (typeof premiumMembershipPackage !== 'undefined') {
            // console.log(premiumMembershipPackage);
            if (typeof premiumMembershipPackage.activePackage !== 'undefined') {
                // console.log(premiumMembershipPackage.activePackage);
                // console.log(premiumMembershipPackage.activePackage.name);
                if (premiumMembershipPackage.activePackage.name != "Free") {
                    isPremiumMember = true;
                    currentPremiumLevel = premiumMembershipPackage.activePackage.name;
                }
            }
        }

        const moneyFormatter = (num) => {
            if (typeof num !== 'undefined') {
                try {
                    var calc = num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('.00','');
                    // var calc = num.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    return '$'+calc;
                } catch (e) {
                    console.log('moneyFormatter',e);
                    return '$0.00';
                }
            }
            return '$'+num;
        }

        const basePrice = currentPackage.currentPackage && currentPackage.currentPackage.base ? currentPackage.currentPackage.base : 0;
        const manualRemovalPrice = currentPackage.currentPackage && currentPackage.currentPackage.manual_removal ? currentPackage.currentPackage.manual_removal : 0;
        const extraDomainsPrice = currentPackage.currentPackage && currentPackage.currentPackage.extra ? currentPackage.currentPackage.extra : 0;

        const handleManualRemovalChange = (event) => {
            this.setState({manualRemoval: event.target.checked});
        };

        const handleSliderChangeQ = (event, newValue) => {
            this.setState({valueQ: newValue});
        };

        const handleChangeP = (event) => {
            this.setState({valueP: event.target.value});
        };

        const calcPrice = () => {
            let price = 0;
            let itemPrice = basePrice;
            let discountAmount = 0;

            if(this.state.manualRemoval) {
                itemPrice += manualRemovalPrice;
            }

            if(this.state.valueQ > baseQuantity) {
                itemPrice += ((this.state.valueQ - baseQuantity) / stepQ) * extraDomainsPrice;
            }
            price = itemPrice;

            const item = {
                orderData: {
                    subscription: 0,
                    quantity: 1,
                    type: "link_clean_up",
                    options: {
                        manual_removal: manualRemoval ? "yes" : "no",
                        extra_domains: (this.state.valueQ - baseQuantity)
                    },
                },
                price: itemPrice
            };

            discountAmount = calculateCartDiscountAmount([item], userDiscount);
            price -= discountAmount;

            return {itemPrice: itemPrice, calculatedPrice: price, discountAmount: discountAmount};
        };

        const {itemPrice, calculatedPrice, discountAmount} = calcPrice();

        let maxDiscountPercent = 0.15;
        let maxDiscountAmount = maxDiscountPercent * calculatedPrice;
        let maxPremiumPrice = calculatedPrice - maxDiscountAmount;

        const addToCart = (orderNow = false) => {
            let orderData = {};
            orderData[0] = {
                subscription: 0,
                quantity: 1,
                type: "link_clean_up",
                options: {
                    manual_removal: manualRemoval ? "yes" : "no",
                    extra_domains: (this.state.valueQ - baseQuantity)
                },
            };

            const key = (manualRemoval ? "m" : "") + (valueP === "subscription" ? "s" : "");
            const item = {
                key: "lcu_" + key,
                price: itemPrice.toFixed(2),
                name: "Link Clean-Up",
                description: "Up to " + this.state.valueQ + " domains" + (manualRemoval ? ", Manual Action Removal" : ""),
                orderData: orderData[0]
            };

            this.props.addToCart(item);

            ReactGA.event({
                category: 'Cart',
                action: 'add_to_cart',
                label: 'Add to Cart',
                nonInteraction: false
            });

            if(orderNow) {
                this.props.openCheckout();
            }
        };

        return (
            <>
                {/* 
                <Portlet>
                    <div className="row" style={{marginLeft:0,marginRight:0}}>
                        <div className="col-lg-4 col-12" style={{paddingLeft:0,paddingRight:0}}>
                            <Portlet className="h-100 kt-mb-0">
                                <PortletHeader title="Get Started with Link Clean-Up" />
                                <PortletBody dangerouslySetInnerHTML={{ __html: pageData ? pageData.top_text : "" }} />
                            </Portlet>
                        </div>
                        <div className="col-lg-5 col-12" style={{paddingLeft:0,paddingRight:0,borderLeft:"1px solid #ebedf2",borderRight:"1px solid #ebedf2"}}>
                            <Portlet className="h-100 kt-mb-0">
                                <PortletHeader title="How It Works" />
                                <PortletBody className="h-100">
                                    <Stepper alternativeLabel className="my-auto overflow-auto">
                                        <Step active={true} key="step1">
                                            <StepLabel>Choose Amount of Referring Domains</StepLabel>
                                        </Step>
                                        <Step active={true} key="step2">
                                            <StepLabel>Submit URL & Grant GSC Access After Purchase</StepLabel>
                                        </Step>
                                        <Step active={true} key="step3">
                                            <StepLabel>Start Link Clean Up</StepLabel>
                                        </Step>
                                        <Step active={true} key="step4">
                                            <StepLabel>Get Toxic Links Removed In 7 days or Less</StepLabel>
                                        </Step>
                                    </Stepper>
                                </PortletBody>
                            </Portlet>
                        </div>
                        <div className="col-lg-3 col-12" style={{paddingLeft:0,paddingRight:0}}>
                            <Portlet className="h-100 kt-mb-0">
                                <PortletHeader title="What’s Included" />
                                <PortletBody className="h-100" dangerouslySetInnerHTML={{ __html: pageData ? pageData.top_right_text : "" }} />
                            </Portlet>
                        </div>
                    </div>
                </Portlet> 
                */}

                <div className="row">
                    <div className="col-12">
                        <div className="progress progressBar TmoProgressBar">
                            <div className="progress-bar" role="progressbar" aria-valuenow={(user.tmo_amount ? user.tmo_amount : 0)} aria-valuemin="0" aria-valuemax={(user.tmo_progress_bar_total ? user.tmo_progress_bar_total : 10000)} style={{width:(user.tmo_percentage ? user.tmo_percentage : 0)+'%'}}>
                                <span class="progress-label">{(user.tmo_amount ? '$'+user.tmo_amount.toLocaleString(undefined, {maximumFractionDigits:2}) : '$0.00')}</span>
                            </div>
                            <div class="first_label">This Month's Spend</div>
                            <div class="second_label">
                                <div class="vertical_line">|</div>
                                $1,000
                            </div>
                            <div class="third_label">
                                <div class="vertical_line">|</div>
                                $5,000
                            </div>
                            <div class="fourth_label">
                                <div class="vertical_line">|</div>
                                $10,000
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row pt-2" style={{marginLeft:0,marginRight:0}}>
                    <div className="col-lg-6 col-md-12 col-sm-12" style={{paddingLeft:0,paddingRight:0}}>
                        <Portlet className="" style={{background:"#f2f3f8"}}>
                            <div className="row" style={{marginLeft:0,marginRight:0}}>
                                <div className="col-12" style={{paddingLeft:0,paddingRight:0}}>
                                    <Portlet className="h-100 kt-mb-0" style={{background:"#ecf3fe"}}>
                                        <PortletBody dangerouslySetInnerHTML={{ __html: pageData ? pageData.top_left_text : "" }} />
                                    </Portlet>
                                </div>
                            </div>
                        </Portlet>
                    </div>
                    <div class="col-lg-4 col-md-12 col-sm-12 youve_selected_right_menu kt-mb-20">
                        <Portlet className="h-100 " style={{background:"#f2f3f8"}}>
                            <div className="row h-100" style={{marginLeft:0,marginRight:0}}>
                                <div className="col-12 h-100" style={{paddingLeft:0,paddingRight:0}}>
                                    <Portlet className="h-100 kt-mb-0" style={{background:"#e7eff3"}}>
                                        <PortletBody dangerouslySetInnerHTML={{ __html: pageData ? pageData.top_right_text : "" }} />
                                    </Portlet>
                                </div>
                            </div>
                        </Portlet>
                    </div>
                </div>
                
                <div className="row" style={{marginLeft:0,marginRight:0}}>
                    <div className="col-lg-6 col-md-9" style={{paddingLeft:0,paddingRight:0}}>
                        <Portlet style={{minHeight:"345px"}}>
                            {/* {isPremiumMember ? 
                                <PortletHeader className={userDiscount && userDiscount["link_clean_up"] > 0 ? "kt-font-danger" : ""} title={"Link Clean-Up" + (userDiscount && userDiscount["link_clean_up"] > 0 ? " - Get " + (userDiscount["link_clean_up"] * 100) + "% off on your link clean up order" : "")} />
                                :
                                <PortletHeader className="kt-font-danger" title={"Link Clean-Up - Get " + (maxDiscountPercent * 100) + "% off on your link clean up order by upgrading to premium"} />
                            } */}
                            <PortletBody>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <FormControl component="fieldset" className="kt-margin-b-15">
                                                    {/* <FormLabel component="legend">Choose Your Plan</FormLabel> */}
                                                    <Typography className="font-weight-bold" gutterBottom>Choose Your Plan</Typography>
                                                    <RadioGroup
                                                        aria-label="plan"
                                                        name="payment_type"
                                                        value={valueP}
                                                        onChange={handleChangeP}
                                                    >
                                                        <FormControlLabel
                                                            value="purchase"
                                                            control={<Radio color="primary" />}
                                                            label="One-time purchase"
                                                            className="kt-margin-b-0"
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <Typography id="p-quantity-label" gutterBottom>
                                                    Referring Domains
                                                </Typography>
                                                <p>
                                                    Choose the number of referring domains.
                                                </p>
                                            </div>
                                            <div className="col-xl-4">
                                                <div className="text-right">Up to <strong style={{fontSize:"15px"}}>{valueQ}</strong> domains</div>
                                                <Slider
                                                    defaultValue={minQ}
                                                    getAriaValueText={valuetextQ}
                                                    aria-labelledby="p-quantity-label"
                                                    //valueLabelDisplay="auto"
                                                    step={stepQ}
                                                    marks={marksQ}
                                                    min={minQ}
                                                    max={maxQ}
                                                    onChange={handleSliderChangeQ}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <Typography>Manual Action Removal</Typography>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={manualRemoval}
                                                            onChange={handleManualRemovalChange}
                                                            value="manual_removal"
                                                            color="primary"
                                                        />
                                                    }
                                                    label={"Manual Action Removal (+$" + manualRemovalPrice.toFixed(2) + ")"}
                                                />
                                                <p>It can take up to 120 days to remove a link penalty.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </PortletBody>
                        </Portlet>
                    </div>

                    <div class="col-lg-4 col-md-3 col-sm-12 youve_selected_right_menu">
                        <Card style={{minHeight:"345px"}}>
                            <Card.Body>
                                <Card.Title>Order Information</Card.Title>
                                <strong>Link Clean-Up</strong>
                                <List>
                                    {manualRemoval && <ListItem>
                                        <ListItemText
                                            primary={"Manual Action Removal"}
                                        />
                                    </ListItem>}
                                    <ListItem>
                                        <ListItemText
                                            primary={valuetextQ(valueQ)}
                                        />
                                    </ListItem>
                                </List>
                                <hr/>
                                {discountAmount > 0 && (<div><Typography>Normal Price</Typography>
                                    <Typography variant="h5">{moneyFormatter(itemPrice)}</Typography></div>)}
                                {/*<div style={{display:(discountAmount > 0 ? "block" : "none")}}>
                                        <Typography>Discount</Typography>
                                        <Typography variant="h6">-${discountAmount.toFixed(2)}</Typography>
                                    </div>*/}
                                {/* {discountAmount > 0 ? <Typography className="kt-font-danger">Premium Member Price</Typography> : <Typography>Total Cost</Typography>}
                                <Typography variant="h5" className={discountAmount > 0 ? "kt-font-danger" : ""}>${calculatedPrice.toFixed(2)}</Typography> */}

                                <Typography>Total Cost</Typography>
                                <Typography variant="h5" className="">{moneyFormatter(calculatedPrice)}</Typography>

                                {/* {! isPremiumMember && discountAmount == 0 ? <Typography className="kt-font-danger">Premium Member Price</Typography> : ""}
                                {! isPremiumMember && discountAmount == 0 ? <Typography variant="h5" className={maxPremiumPrice > 0 ? "kt-font-danger" : ""}>${maxPremiumPrice.toFixed(2)}</Typography> : ""}
                                {! isPremiumMember && discountAmount == 0 ? <Link className="d-block" to="/packages/marketplace-access">Upgrade to Premium</Link> : ""} */}

                                <div className="kt-margin-b-15" />
                                {/* <Button className="btn-success kt-mb-10" style={{minWidth:"170px"}} size="lg" variant="primary" onClick={() => {
                                    addToCart(true);
                                }} disabled={calculatedPrice === 0}>Order Now</Button> */}
                                <Button onClick={() => {
                                    addToCart();
                                }} style={{minWidth:"170px"}} size="lg" disabled={calculatedPrice === 0}>Add to Cart</Button>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ auth: { user }, pagedata: {pageData}, cart: {userDiscountPercent}, packages: currentPackage }) => ({
    user, pageData, currentPackage, userDiscountPercent
});

export default injectIntl(connect(
    mapStateToProps,
    {...cart.actions, ...pagedata.actions, ...packages.actions}
)(LinkCleanUp));
