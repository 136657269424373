import React from "react";
import {Route, Switch} from "react-router-dom";
import AITools from "./AITools";
import SeoAuditorTool from "./SeoAuditorTool";

export default function ToolsPage() {
    return (
        <Switch>
            <Route
                path="/tools/ai-tools"
                component={AITools}
            />
            <Route
                path="/tools/seo-auditor-tool"
                component={SeoAuditorTool}
            />
        </Switch>
    );
}
