import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as financials from "../../store/ducks/admin/financials.duck";
import { getFinancials, exportFinancials, getMonthlyExpenseDetails } from "../../crud/admin/financials.crud";
import {Spinner} from "reactstrap";
import {Table, Badge} from "react-bootstrap";
import {Portlet, PortletBody, PortletHeader} from "../../partials/content/Portlet";
import UPagination from "../../partials/content/Pagination";
import {Button} from "react-bootstrap";
import FinancialsMonthlyExpenseDetails from "./FinancialsMonthlyExpenseDetails";
import { Link } from 'react-router-dom';

class FinancialsAdmin extends Component {
     constructor(props) {
        super(props);

        let sending = false;
        let month = null, year = null;

        this.state = {
            sending: sending,
            expensePeriod: {
                month: month,
                year: year,
            },
            expenseLineItems: [],
        };

    }

    componentDidMount() {
        this.props.initFinancials(0);
        let sending = false;
        
        this.state = {
            sending: sending
        };
    }

    render() {
        const { financials } = this.props;
        let sending = this.props.sending;

        if (!financials) {
            return <Spinner/>;
        }

     //    this.handleRejectWriter = (e) => {
	    // 	e.preventDefault();
	    // 	let user_id = e.target.name;
	    // 	this.setState({
     //            ...this.state,
     //    		sending: true
     //    	});
	    // 	rejectWriter(
     //            user_id
     //        )
     //        .then((res) => {
     //            this.setState({
	    //             ...this.state,
	    //     		sending: false
	    //     	});
	    //     	this.props.initWriters();
     //        })
     //        .catch(e => {
     //        	this.setState({
	    //             ...this.state,
	    //     		sending: false
	    //     	});
	    //     	this.props.initWriters();
     //            if(e && e.response && e.response.data) {
     //                // setStatus(
     //                //     e.response.data.message
     //                // );
     //            }
     //        });
	    // };

	    // this.handleApproveWriter = (e) => {
	    // 	e.preventDefault();
	    // 	let user_id = e.target.name;
	    // 	this.setState({
     //            ...this.state,
     //    		sending: true
     //    	});
	    // 	approveWriter(
     //            user_id
     //        )
     //        .then((res) => {
     //            this.setState({
	    //             ...this.state,
	    //     		sending: false
	    //     	});
	    //     	this.props.initWriters();
     //        })
     //        .catch(e => {
     //        	this.setState({
	    //             ...this.state,
	    //     		sending: false
	    //     	});
     //            if(e && e.response && e.response.data) {
     //                // setStatus(
     //                //     e.response.data.message
     //                // );
     //            }
     //        });
	    // };

        this.handleMonthlyExpenseDetailsLookup = (e) => {
            const month = e.target.parentNode.dataset.month;
            const year = e.target.parentNode.dataset.year;

            getMonthlyExpenseDetails(month, year).then((res) => {


console.log("expense line items");
console.log(res.data);
                if (res.data.length) {
                    this.setState({
                        ...this.state,
                        expensePeriod: {
                            month: month,
                            year: year,
                        },
                        expenseLineItems: res.data
                    });
                } else {

                }               
            });
            
        }

        this.viewMonthlyOrderDetails = (e) => {
            const month = e.target.parentNode.dataset.month;
            const year = e.target.parentNode.dataset.year;

            getMonthlyExpenseDetails(month, year).then((res) => {

                if (res.data.length) {
                    this.setState({
                        ...this.state,
                        expensePeriod: {
                            month: month,
                            year: year,
                        },
                        expenseLineItems: res.data
                    });
                } else {

                }               
            });
            
        }

        this.handleExportCSV = (e) => {

         e.preventDefault();

         let user_id = e.target.name;
         this.setState({
                ...this.state,
             sending: true
         });

         exportFinancials()
            .then((res) => {

                this.setState({
                    ...this.state,
                    sending: false
                });

                var blob = new Blob([res.data], { type:'text/csv' });

                // alert(URL.createObjectURL(blob));
                // window.location.href=URL.createObjectURL(blob);

                var a = document.createElement('a'), url = URL.createObjectURL(blob);

                document.body.appendChild(a);
                a.innerHTML = 'download';
                a.href = url;   
                a.download = 'financials.csv';
                a.click();
                a.remove();

            })
            .catch(e => {
                this.setState({
                    ...this.state,
                    sending: false
                });
                alert("Unexpected error with exporting financials csv.")
            });
        };

        this.handleUpdateFinancialOverview = (e) => {
            this.props.initFinancials(2);
        };

        var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });

        const rows = [];

        if (financials) {
            console.log(financials);
            console.log(typeof financials);
            if (financials.length) {
                for (const [index] of financials.entries()) {
                    if (financials[index].year == null || financials[index].month == null ) {
                        continue;
                    }
                    rows.push(<tr key={index} data-month={financials[index].month} data-year={financials[index].year}>
                        <td>{financials[index].month + "/" + financials[index].year}</td>
                        <td>{formatter.format(financials[index].total_revenues_for_month)}
                            <span className="kt-header__topbar-item align-self-center kt-header__upgrade-link d-none d-sm-block">
                                <Link className="btn btn-success" to={"/admin/orders-filtered?month="+financials[index].month+"&year="+financials[index].year + "&page=1"} target="_blank" rel="noopener noreferrer">View Orders</Link>
                            </span>
                        </td>
                        <td onClick={(e) => this.handleMonthlyExpenseDetailsLookup(e)} style={{height: financials[index].month == this.state.expensePeriod.month && financials[index].year == this.state.expensePeriod.year ? "300px" : "30px", overflowY: 'hidden'}}>{formatter.format(financials[index].total_expenses_for_month)}
                        { financials[index].month == this.state.expensePeriod.month && financials[index].year == this.state.expensePeriod.year ?
                            <FinancialsMonthlyExpenseDetails expense_line_items={this.state.expenseLineItems} />
                        : null }
                        </td>
                        <td>{formatter.format(financials[index].total_profits_for_month)}</td>
                    </tr>)
                }
            }
        }

        const handleNextPage = (number) => {
            this.props.initWriters(number);
        };

        return (
            <Portlet>
                <PortletHeader title="Financials" />
                <PortletBody className="pt-0">
                    <div className="mt-2 mb-2">
                        <Button disabled={this.state.sending} className="btn-sm kt-mr-15" onClick={(e) => this.handleExportCSV(e)}>Export CSV</Button>
                    </div>
                    <div className="mt-2 mb-2">
                        <Button disabled={this.state.sending} className="btn-sm kt-mr-15" onClick={(e) => this.handleUpdateFinancialOverview(e)}>Update Financial Overview</Button>
                    </div>
                    <Table striped bordered hover responsive>
                        <thead>
                        <tr>
                            <th>Period</th>
                            <th>Revenues for Month</th>
                            <th>Expenses for Month</th>
                         	<th>Profits for Month</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </Table>
                    {financials.meta && financials.meta.last_page > 1 && <UPagination currentPage={financials.meta.current_page} totalPages={financials.meta.last_page} onChange={handleNextPage} />}
                </PortletBody>
            </Portlet>
        );
    }
}


const mapStateToProps = ({ financialsAdmin: { financials } }) => ({
    financials
});

export default injectIntl(connect(
    mapStateToProps,
    financials.actions
)(FinancialsAdmin));
