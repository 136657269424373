import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as guestposts from "../../store/ducks/admin/guestPosts.duck";
import { approveBlog, rejectBlog } from "../../crud/admin/orders.crud";
import {Spinner} from "reactstrap";
import {Table, Badge} from "react-bootstrap";
import {Portlet, PortletBody, PortletHeader} from "../../partials/content/Portlet";
import UPagination from "../../partials/content/Pagination";
import {Button} from "react-bootstrap";
import {displayError} from "../../widgets/AlertError";

class BlogsAdmin extends Component {
     constructor(props) {
        super(props);

        let sending = false;

        this.state = {
            sending: sending
        };

    }

    componentDidMount() {
        this.props.initBlogs(0);
        let sending = false;

        this.state = {
            sending: sending
        };
    }

    render() {
        const { guestPosts } = this.props;
        let sending = this.props.sending;

        if (!guestPosts) {
            return <Spinner/>;
        }

        this.handleRejectBlog = (e) => {
	    	e.preventDefault();
	    	let blog_id = e.target.name;
	    	this.setState({
                ...this.state,
        		sending: true
        	});
	    	rejectBlog(
                blog_id
            )
            .then((res) => {
                this.setState({
	                ...this.state,
	        		sending: false
	        	});
	        	this.props.initBlogs(guestPosts.meta.current_page);
            })
            .catch(e => {
            	this.setState({
	                ...this.state,
	        		sending: false
	        	});
	        	this.props.initBlogs(guestPosts.meta.current_page);
                if(e && e.response && e.response.data) {
                    // setStatus(
                    //     e.response.data.message
                    // );
                }
            });
	    };

	    this.handleApproveBlog = (e) => {
	    	e.preventDefault();
	    	let blog_id = e.target.name;
	    	this.setState({
                ...this.state,
        		sending: true
        	});
	    	approveBlog(
                blog_id
            )
            .then((res) => {
                this.setState({
	                ...this.state,
	        		sending: false
	        	});
	        	this.props.initBlogs(guestPosts.meta.current_page);
            })
            .catch(e => {
            	this.setState({
	                ...this.state,
	        		sending: false
	        	});

                if(e.response && e.response.data && e.response.data.errorMessage) {
                    displayError(e.response.data.errorMessage);
                }
            });
	    };

        const rows = [];

        if (guestPosts.data) {
            if (guestPosts.data.length) {
                for (const [index] of guestPosts.data.entries()) {
                	const date = new Date(guestPosts.data[index].created_at);

                	if(guestPosts.data[index].status.name == "Pending Approval") {
	                    rows.push(<tr key={index}>
	                        <td>{guestPosts.data[index].id}</td>
	                        <td>{guestPosts.data[index].owner.firstname} {guestPosts.data[index].owner.lastname}</td>
	                        <td>{guestPosts.data[index].owner.email}</td>
	                        <td>{date.toLocaleString()}</td>
	                        <td>{guestPosts.data[index].url}</td>
	                        <td>{guestPosts.data[index].category_label}</td>
                            <td>{guestPosts.data[index].organic_traffic != null ? guestPosts.data[index].organic_traffic : 0}</td>
	                        <td>{guestPosts.data[index].da != null ? guestPosts.data[index].da : 0}</td>
	                        <td>${guestPosts.data[index].price.toFixed(2)}</td>
	                        <td><Badge pill variant={guestPosts.data[index].status.status_color}>{guestPosts.data[index].status.name}</Badge></td>
	                        <td>
	                        	<Button disabled={this.state.sending} className="btn-sm kt-mr-15" name={guestPosts.data[index].id} onClick={(e) => this.handleApproveBlog(e)}>Approve</Button>
								<Button disabled={this.state.sending} className="btn-danger btn-sm" name={guestPosts.data[index].id} onClick={(e) => this.handleRejectBlog(e)}>Reject</Button>
	                        </td>
	                    </tr>)
	                } else {
	                	rows.push(<tr key={index}>
	                        <td>{guestPosts.data[index].id}</td>
	                        <td>{guestPosts.data[index].owner.firsname} {guestPosts.data[index].owner.lastname}</td>
	                        <td>{guestPosts.data[index].owner.email}</td>
	                        <td>{date.toLocaleString()}</td>
	                        <td>{guestPosts.data[index].url}</td>
                            <td>{guestPosts.data[index].category_label}</td>
                            <td>{guestPosts.data[index].organic_traffic != null ? guestPosts.data[index].organic_traffic : 0}</td>
	                        <td>{guestPosts.data[index].da != null ? guestPosts.data[index].da : 0}</td>
	                        <td>${guestPosts.data[index].price.toFixed(2)}</td>
	                        <td><Badge pill variant={guestPosts.data[index].status.status_color}>{guestPosts.data[index].status.name}</Badge></td>
	                        <td>
	                        </td>
	                    </tr>)
	                }
                }
            }
        }

        const handleNextPage = (number) => {
            this.props.initBlogs(number);
        };

        return (
            <Portlet>
                <PortletHeader title="Blogs" />
                <PortletBody className="pt-0">
                    <Table striped bordered hover responsive>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Customer</th>
                            <th>Customer Email</th>
                         	<th>Date Added</th>
                            <th>Site</th>
                            <th>Category</th>
                            <th>Traffic</th>
                            <th>DA</th>
                            <th>Price</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </Table>
                    {guestPosts.meta && guestPosts.meta.last_page > 1 && <UPagination currentPage={guestPosts.meta.current_page} totalPages={guestPosts.meta.last_page} onChange={handleNextPage} />}
                </PortletBody>
            </Portlet>
        );
    }
}


const mapStateToProps = ({ guestpostsAdmin: { guestPosts } }) => ({
    guestPosts
});

export default injectIntl(connect(
    mapStateToProps,
    guestposts.actions
)(BlogsAdmin));
