import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as guestposts from "../../store/ducks/admin/guestPosts.duck";
import {Spinner} from "reactstrap";
import {Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Portlet, PortletBody} from "../../partials/content/Portlet";
import {Typography, Breadcrumbs} from "@material-ui/core";
import UPagination from "../../partials/content/Pagination";
import * as orders from "../../store/ducks/admin/orders.duck";

class GuestPostsAdmin extends Component {
    componentDidMount() {
        const { orderId } = this.props.match.params;
        this.props.initGuestPosts(orderId);
        this.props.requestOrder(orderId);
    }

    render() {
        const { orderId } = this.props.match.params;
        const {guestPosts} = this.props;
        const orderNumber = this.props.currentOrder && this.props.currentOrder.data ? this.props.currentOrder.data.order_number : "";

        if (!guestPosts) {
            return <Spinner/>;
        }
        else if(!guestPosts.data) {
            return (
                <>
                    <h1>Guest Posts not found!</h1>
                </>
            );
        }

        let the_current_page = null;
        const getPage = new URLSearchParams(this.props.location.search).get("page");
        if (getPage != null) {
            the_current_page = getPage;
        }

        const rows = [];

        if (guestPosts.data) {
            let active = guestPosts.current_page ;
            let perPage = guestPosts.per_page;

            if (guestPosts.data.length) {
                for (const [index] of guestPosts.data.entries()) {
                    let url = "/admin/orders/guest-post/" + guestPosts.data[index].id;
                    if (the_current_page != null) {
                        url = "/admin/orders/guest-post/" + guestPosts.data[index].id+"?page="+the_current_page;
                    }
                    rows.push(<tr key={index}>
                        <td className="align-middle">{perPage * (active - 1) + index + 1}</td>
                        <td className="align-middle">{guestPosts.data[index].website}</td>
                        <td className="align-middle">{guestPosts.data[index].url}</td>
                        <td className="align-middle">{guestPosts.data[index].anchor}</td>
                        <td className="align-middle">{guestPosts.data[index].da}</td>
                        <td className="align-middle text-center">
                            <Link className="btn btn-success" to={url}>Edit</Link>
                        </td>
                    </tr>);
                }
            }
        }

        const handleNextPage = (number) => {
            this.props.initGuestPosts(orderId, number);
        };

        return(
            <>
                <Portlet>
                    <PortletBody>
                        <Breadcrumbs aria-label="Breadcrumb" className="kt-margin-b-15">
                            {the_current_page != null ?
                            <Link color="inherit" to={"/admin/orders?page="+the_current_page}>
                                Orders
                            </Link>
                            :
                            <Link color="inherit" to="/admin/orders">
                                Orders
                            </Link>}
                            {the_current_page != null ?
                            <Link color="inherit" to={"/admin/orders/" + orderId + "/progress?page="+the_current_page}>
                                Order #{orderNumber}
                            </Link>
                            :
                            <Link color="inherit" to={"/admin/orders/" + orderId + "/progress"}>
                                Order #{orderNumber}
                            </Link>}
                            <Typography color="textPrimary">Guest Posts</Typography>
                        </Breadcrumbs>
                        <Typography variant="h5">Guest Posts (Order #{orderNumber})</Typography>
                        <div className="row">
                            <div className="col-xl-6 col-lg-9">
                                <Table striped bordered hover responsive className="kt-mt-10">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Website</th>
                                        <th>URL</th>
                                        <th>Anchor</th>
                                        <th>DA</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {rows}
                                    </tbody>
                                </Table>
                                {guestPosts.last_page > 1 && <UPagination currentPage={guestPosts.current_page} totalPages={guestPosts.last_page} onChange={handleNextPage} />}
                            </div>
                        </div>
                    </PortletBody>
                </Portlet>
            </>
        );
    }
}


const mapStateToProps = ({ guestpostsAdmin: { guestPosts }, ordersAdmin: {currentOrder} }) => ({
    guestPosts, currentOrder
});

export default injectIntl(connect(
    mapStateToProps,
    {...guestposts.actions, ...orders.actions}
)(GuestPostsAdmin));
