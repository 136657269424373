import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getGuestPost, getGuestPosts, getBloggerSales } from "../../crud/orders.crud";

export const actionTypes = {
    GuestPostsInit: "[Init Guest Posts] Action",
    GuestPostsRequested: "[Request Guest Posts] Action",
    GuestPostsLoaded: "[Load Guest Posts] Orders API",
    GuestPostRequested: "[Request Guest Post] Action",
    GuestPostLoaded: "[Load Guest Post] Orders API",

    BloggerSalesInit:  "[Init Blogger Sales] Action",
    BloggerSalesRequested:  "[Request Blogger Sales] Action",
    BloggerSalesLoaded:  "[Load Blogger Sales] Action",
};

const initialGuestPostsState = {
    guestPosts: undefined,
    currentGuestPost: undefined
};

export const reducer = persistReducer(
    { storage, key: "demo3-orders", whitelist: ["guestPosts", "currentGuestPost"] },
    (state = initialGuestPostsState, action) => {
        switch (action.type) {
            case actionTypes.GuestPostsInit: {
                return { guestPosts: undefined, currentGuestPost: undefined };
            }

            case actionTypes.GuestPostsLoaded: {
                const { guestPosts } = action.payload;

                return { ...state, guestPosts };
            }

            case actionTypes.GuestPostRequested: {
                return { ...state, currentGuestPost: undefined };
            }

            case actionTypes.GuestPostLoaded: {
                const { currentGuestPost } = action.payload;

                return { ...state, currentGuestPost };
            }




            case actionTypes.BloggerSalesInit: {
                return { guestPosts: undefined, currentGuestPost: undefined };
            }

            case actionTypes.BloggerSalesRequested: {
                return { ...state, guestPosts: undefined };
            }

            case actionTypes.BloggerSalesLoaded: {
                const { guestPosts } = action.payload;

                return { ...state, guestPosts };
            }




            default:
                return state;
        }
    }
);

export const actions = {
    initGuestPosts: (orderId, page, params) => ({ type: actionTypes.GuestPostsInit, payload: { orderId, page, params } }),
    requestGuestPosts: (orderId, page, params) => ({ type: actionTypes.GuestPostsRequested, payload: { orderId, page, params } }),
    fulfillGuestPosts: guestPosts => ({ type: actionTypes.GuestPostsLoaded, payload: { guestPosts } }),
    requestGuestPost: id => ({ type: actionTypes.GuestPostRequested, payload: { id } }),
    fulfillGuestPost: currentGuestPost => ({ type: actionTypes.GuestPostLoaded, payload: { currentGuestPost } }),

    initBloggerSales: (page, params) => ({ type: actionTypes.BloggerSalesInit, payload: { page, params } }),
    requestBloggerSales: (page, params) => ({ type: actionTypes.BloggerSalesRequested, payload: { page, params } }),
    fulfillBloggerSales: guestPosts => ({ type: actionTypes.BloggerSalesLoaded, payload: { guestPosts } }),
};

export function* saga() {

    yield takeLatest(actionTypes.BloggerSalesInit, function* initBloggerSalesSaga(action) {
        const { page, params } = action.payload;
        yield put(actions.requestBloggerSales(page, params));
    });

    yield takeLatest(actionTypes.BloggerSalesRequested, function* bloggerSalesRequested(action) {
        try {
            const { page, params } = action.payload;
            const {data: guestPosts} = yield getBloggerSales(page, params);

            yield put(actions.fulfillBloggerSales(guestPosts));
        }
        catch (e) {
            yield put(actions.fulfillBloggerSales({data: undefined}));
        }
    });





    yield takeLatest(actionTypes.GuestPostsInit, function* initGuestPostsSaga(action) {
        const { orderId, page, params } = action.payload;
        yield put(actions.requestGuestPosts(orderId, page, params));
    });

    yield takeLatest(actionTypes.GuestPostsRequested, function* guestPostsRequested(action) {
        try {
            const { orderId, page, params } = action.payload;
            const {data: guestPosts} = yield getGuestPosts(orderId, page, params);

            yield put(actions.fulfillGuestPosts(guestPosts));
        }
        catch (e) {
            yield put(actions.fulfillGuestPosts({data: undefined}));
        }
    });

    yield takeLatest(actionTypes.GuestPostRequested, function* guestPostRequested(action) {
        try {
            const { id } = action.payload;
            const {data: currentGuestPost} = yield getGuestPost(id);

            yield put(actions.fulfillGuestPost(currentGuestPost));
        }
        catch (e) {
            yield put(actions.fulfillGuestPost({data: undefined}));
        }
    });
}
