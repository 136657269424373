import React from 'react';
import {BottomNavigation, BottomNavigationAction, withStyles} from '@material-ui/core';
import { toAbsoluteUrl } from "../../utils/utils";
import { withRouter } from "react-router-dom";
import MarketplaceSortDialog from '../../../app/includes/MarketplaceSortDialog';
import MarketplaceFilterDialog from '../../../app/includes/MarketplaceFilterDialog';

const styles = theme => ({
    'bottom-navigation': {
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        height: "80px",
        zIndex: 9,
        boxShadow: "0 1px 9px 3px rgb(0 0 0 / 10%)",
        '& .MuiBottomNavigationAction-root': {
            paddingLeft: 2,
            paddingRight: 2,
        },
        '& .MuiBottomNavigationAction-root.Mui-selected': {
            backgroundColor: "#75DDD2",
        },
    },
    'menu-link-icon': {
        height: "29px",
    },
    'bn-item': {
        fontSize: "0.875rem",
        lineHeight: 1,
        display: "block",
        paddingTop: "5px",
        color: "#000",
    },
    'paper': {
        maxHeight: 435,
    },
});
const navItemStyles = {
    minWidth:"auto",
};

class AppBottomNavigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.location && this.props.location.pathname ? this.props.location.pathname : "",
            openSortDialog: false,
            openFilterDialog: false,
        };
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.location && this.props.location.pathname && prevProps.location && prevProps.location.pathname) {
            if(prevProps.location.pathname !== this.props.location.pathname) {
                this.setState({...this.state, value: this.props.location.pathname});
            }
        }
    }

    render() {
        const { classes } = this.props;
        const isMarketplace = this.props.location && this.props.location.pathname === "/marketplace";

        const handleSortClose = (value = null) => {
            this.setState({...this.state, openSortDialog: false});
        };

        const handleFilterClose = (value = null) => {
            this.setState({...this.state, openFilterDialog: false});
        };

        return (
            <>
                <MarketplaceSortDialog className={classes['paper']} open={this.state.openSortDialog} onClose={handleSortClose} />
                <MarketplaceFilterDialog open={this.state.openFilterDialog} onClose={handleFilterClose} />
                <BottomNavigation
                    value={this.state.value}
                    onChange={(event, newValue) => {
                        if(newValue.indexOf("#") === -1) {
                            this.props.history.push({
                                pathname: newValue
                            });
                            this.setState({...this.state, value: newValue});
                        }
                        else if(newValue === "#sort") {
                            this.setState({...this.state, openSortDialog: true, value: newValue});
                        }
                        else if(newValue === "#filter") {
                            this.setState({...this.state, openFilterDialog: true, value: newValue});
                        }
                    }}
                    showLabels
                    className={classes["bottom-navigation"] + " kt-hidden-desktop"}
                >
                    <BottomNavigationAction style={navItemStyles} className={classes["nav-action"]} value="/projects" label={<span className={classes["bn-item"]}>Projects</span>} icon={<img className={classes["menu-link-icon"]} alt="Projects" src={toAbsoluteUrl("/media/icons/menu/projects.png")} />}/>
                    <BottomNavigationAction style={navItemStyles} className={classes["nav-action"]} value="/orders" label={<span className={classes["bn-item"]}>Orders</span>} icon={<img className={classes["menu-link-icon"]} alt="Orders" src={toAbsoluteUrl("/media/icons/menu/orders.png")} />}/>
                    {!isMarketplace && <BottomNavigationAction style={navItemStyles} className={classes["nav-action"]} value="/marketplace" label={<span className={classes["bn-item"]}>Marketplace</span>} icon={<img className={classes["menu-link-icon"]} alt="Marketplace" src={toAbsoluteUrl("/media/icons/menu/marketplace.png")} />}/>}
                    {!isMarketplace && <BottomNavigationAction style={navItemStyles} className={classes["nav-action"]} value="/seo-services" label={<span className={classes["bn-item"]}>Services</span>} icon={<img className={classes["menu-link-icon"]} alt="SEO Services" src={toAbsoluteUrl("/media/icons/menu/services.png")} />}/>}
                    {isMarketplace && <BottomNavigationAction style={navItemStyles} className={classes["nav-action"]} value="#sort" label={<span className={classes["bn-item"]}>Sort</span>} icon={<img className={classes["menu-link-icon"]} alt="Sort" src={toAbsoluteUrl("/media/icons/menu/sort.png")} />}/>}
                    {isMarketplace && <BottomNavigationAction style={navItemStyles} className={classes["nav-action"]} value="#filter" label={<span className={classes["bn-item"]}>Filters</span>} icon={<img className={classes["menu-link-icon"]} alt="Filters" src={toAbsoluteUrl("/media/icons/menu/filter.png")} />}/>}
                    <BottomNavigationAction style={navItemStyles} className={classes["nav-action"]} value="/profile" label={<span className={classes["bn-item"]}>Account</span>} icon={<img className={classes["menu-link-icon"]} alt="Account" src={toAbsoluteUrl("/media/icons/menu/profile.png")} />}/>
                </BottomNavigation>
            </>
        );
    }
}

export default withStyles(styles)(withRouter(AppBottomNavigation));
