import React, {useEffect} from "react";
import {Portlet, PortletBody, PortletHeader} from "../../../partials/content/Portlet";
import {
    Slider,
    Typography,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Checkbox,
    List,
    ListItem,
    ListItemText,
    Stepper, Step, StepLabel
} from "@material-ui/core";
import {Card, Button, Form} from "react-bootstrap";
import { useFormik } from "formik";
import { useHistory, Link } from 'react-router-dom';
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as cart from "../../../store/ducks/cart.duck";
import ReactGA from "react-ga4";
import * as pagedata from "../../../store/ducks/page.duck";
import * as packages from "../../../store/ducks/packages.duck"
import {Spinner} from "reactstrap";
import {calculateCartDiscountAmount} from "../../../custom_functions/guest_posts";
import GuestPostsInfo from "../../../includes/GuestPostsInfo";

const minQ = 50;
const maxQ = 200;
const stepQ = 1;

const marksQ = [
    {
        value: minQ,
        label: minQ.toString(),
    },
    {
        value: maxQ,
        label: maxQ.toString(),
    },
];

function valuetextQ(value) {
    return `${value} citations`;
}

function Citation(props) {
    const history = useHistory();
    const [googleBusiness, setGoogleBusiness] = React.useState(false);
    const [valueQ, setValueQ] = React.useState(minQ);
    const [valueP, setValueP] = React.useState("purchase");
    const [price, setPrice] = React.useState(0);
    useEffect(() => {
        const pageName = 'citation';
        const type = 'price_package_citation';
        props.requestPageData(pageName);
        props.requestUserDiscount();
        props.requestPackage(type);
        props.requestPackagePreimumMembership("marketplace_guest_post_access");
    }, []);

    const {pageData, userDiscountPercent: userDiscount, currentPackage: currentPackage} = props;

    let premiumMembershipPackage = undefined;
    if (currentPackage.premiumMembershipPackage) {
        premiumMembershipPackage = currentPackage.premiumMembershipPackage;
    }

    const handleGoogleBusiness = (event) => {
        setGoogleBusiness(event.target.checked);
        setPrice(calcPrice().calculatedPrice);
    };
    const handleSliderChangeQ = (event, newValue) => {
        setValueQ(newValue);
        setPrice(calcPrice().calculatedPrice);
    };
    const handleChangeP = (event) => {
        setValueP(event.target.value);
        setPrice(calcPrice().calculatedPrice);
    };
    const calcPrice = () => {
        let newPrice = 0;
        let itemPrice = 0;
        let discount = 0;
        let discountAmount = 0;
        let extraPrice = 0;

        if(currentPackage && currentPackage.currentPackage) {
            if (googleBusiness) {
                extraPrice += currentPackage.currentPackage["google_business"];
            }
            newPrice += currentPackage.currentPackage["base"];
        }

        newPrice *= valueQ;

        newPrice += extraPrice;
        itemPrice = newPrice;

        const item = {
            orderData: {
                subscription: (valueP === "subscription" ? 1 : 0),
                quantity: 1,
                type: "citation",
                options: {
                    google_business: googleBusiness ? "yes" : "no",
                    amount: valueQ,
                },
            },
            price: itemPrice
        };

        discountAmount = calculateCartDiscountAmount([item], userDiscount);
        newPrice -= discountAmount;

        return {itemPrice: itemPrice, calculatedPrice: newPrice, discountAmount: discountAmount};
    };

    const {itemPrice, calculatedPrice, discountAmount} = calcPrice();

    const formik = useFormik({
        initialValues: {
            add_to_cart: 0
        },
        validate: values => {
            const errors = {};

            return errors;
        },
        onSubmit: (values, { setStatus, setSubmitting }) => {
            let orderData = {};
            orderData[0] = {
                subscription: (valueP === "subscription" ? 1 : 0),
                quantity: 1,
                type: "citation",
                options: {
                    google_business: googleBusiness ? "yes" : "no",
                    amount: valueQ,
                },
            };

            const key = "c" + valueQ + (googleBusiness ? "gb" : "") + (valueP === "subscription" ? "s" : "");
            const item = {
                key: "li_" + key,
                price: itemPrice.toFixed(2),
                name: "Citations",
                description: "Amount: " + valueQ,
                orderData: orderData[0]
            };

            props.addToCart(item);

            if(values.add_to_cart === 0) {
                props.hideCart();
                props.openCheckout();
            }

            ReactGA.event({
                category: 'Cart',
                action: 'add_to_cart',
                label: 'Add to Cart',
                nonInteraction: false
            });

            setSubmitting(false);
        }
    });

    if(!currentPackage) {
        return <Spinner />;
    }
    else if(currentPackage.currentPackage && !currentPackage.currentPackage.type) {
        return <h1>Package not found!</h1>;
    }

    let isPremiumMember = false;
    let currentPremiumLevel = "Free";

    if (typeof premiumMembershipPackage !== 'undefined') {
        // console.log(premiumMembershipPackage);
        if (typeof premiumMembershipPackage.activePackage !== 'undefined') {
            // console.log(premiumMembershipPackage.activePackage);
            // console.log(premiumMembershipPackage.activePackage.name);
            if (premiumMembershipPackage.activePackage.name != "Free") {
                isPremiumMember = true;
                currentPremiumLevel = premiumMembershipPackage.activePackage.name;
            }
        }
    }

    let maxDiscountPercent = 0.15;
    let maxDiscountAmount = maxDiscountPercent * calculatedPrice;
    let maxPremiumPrice = calculatedPrice - maxDiscountAmount;

    return (
        <>
            <div className="row pt-2" style={{marginLeft:0,marginRight:0}}>
                <div className="col-lg-6 col-md-9" style={{paddingLeft:0,paddingRight:0}}>
                    <Portlet>
                        <PortletBody className="mw-position-orders-area-wide" style={{padding:"40px"}}>
                            <h4 className="mb-3">Get Started with Citations</h4>
                            <p dangerouslySetInnerHTML={{ __html: pageData ? pageData.top_text : "" }} />
                            <h5 className="mb-0 mt-2">How it works</h5>
                            <Stepper orientation="vertical">
                                <Step active={true} key="step1">
                                    <StepLabel>
                                        <h6 className="mt-2 ml-2">Choose Amount of Citations</h6>
                                    </StepLabel>
                                </Step>
                                <Step active={true} key="step2">
                                    <StepLabel>
                                        <h6 className="mt-2 ml-2">Submit Your Company Details After Purchase</h6>
                                    </StepLabel>
                                </Step>
                                <Step active={true} key="step3">
                                    <StepLabel>
                                        <h6 className="mt-2 ml-2">Start Citation Building</h6>
                                    </StepLabel>
                                </Step>
                                <Step active={true} key="step4">
                                    <StepLabel>
                                        <h6 className="mt-2 ml-2">Get Your Citations Indexed in 30 Days or Less</h6>
                                    </StepLabel>
                                </Step>
                            </Stepper>
                            <Form noValidate onSubmit={formik.handleSubmit}>
                                <div className="row mt-2">
                                    <div className="col-12 text-center mt-4">
                                        <FormControl component="fieldset" className="kt-margin-b-15">
                                            <Typography className="font-weight-bold" gutterBottom>Choose Your Plan</Typography>
                                            <RadioGroup
                                                aria-label="plan"
                                                name="payment_type"
                                                value={valueP}
                                                onChange={handleChangeP}
                                            >
                                                <FormControlLabel
                                                    value="purchase"
                                                    control={<Radio color="primary" />}
                                                    label="One-time purchase"
                                                    className="kt-margin-b-0"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center mt-4 mw-position-orders-area">
                                        <Typography className="font-weight-bold">Google my Business – SEO Optimization</Typography>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={googleBusiness}
                                                    onChange={handleGoogleBusiness}
                                                    value="Google my Business – SEO Optimization"
                                                    color="primary"
                                                />
                                            }
                                            label={"Yes, I want Google my Business – SEO Optimization (+$" + (currentPackage.currentPackage && currentPackage.currentPackage["google_business"] ? currentPackage.currentPackage["google_business"].toFixed(2) : 0) + ")"}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center mt-4 mw-position-orders-area">
                                        <Typography id="post-quantity-label" className="font-weight-bold" gutterBottom>
                                            Amount of Citations
                                        </Typography>
                                        {/* <p>
                                            Choose the number of citations.
                                        </p> */}
                                        <div className="text-right"><strong style={{fontSize:"15px"}}>{valueQ}</strong> citations</div>
                                        <Slider
                                            defaultValue={minQ}
                                            getAriaValueText={valuetextQ}
                                            aria-labelledby="post-quantity-label"
                                            valueLabelDisplay="auto"
                                            step={stepQ}
                                            marks={marksQ}
                                            min={minQ}
                                            max={maxQ}
                                            onChange={handleSliderChangeQ}
                                        />
                                    </div>
                                </div>
                            </Form>
                        </PortletBody>
                    </Portlet>
                </div>

                <div class="col-lg-3 col-md-3 pl-4">
                    <Card style={{maxWidth:"250px",padding:"10px"}}>
                        <Card.Body>
                            <Card.Title className="mb-4">You've selected</Card.Title>
                            <strong>Citation</strong>
                            <List>
                                <ListItem>
                                    <ListItemText
                                        primary={valuetextQ(valueQ)}
                                    />
                                </ListItem>
                                {googleBusiness && (<ListItem>
                                    <ListItemText
                                        primary="Google my Business – SEO Optimization"
                                    />
                                </ListItem>)}
                            </List>
                            <hr/>
                            {/* {discountAmount > 0 && (<div><Typography>Normal Price</Typography>
                                <Typography variant="h5">${itemPrice.toFixed(2)}</Typography></div>)} */}
                            {/*<div style={{display:(discountAmount > 0 ? "block" : "none")}}>
                                    <Typography>Discount</Typography>
                                    <Typography variant="h6">-${discountAmount.toFixed(2)}</Typography>
                                </div>*/}
                            {/* {discountAmount > 0 ? <Typography className="kt-font-danger">Premium Member Price</Typography> : <Typography>Total Cost</Typography>}
                            <Typography variant="h5" className={discountAmount > 0 ? "kt-font-danger" : ""}>${calculatedPrice.toFixed(2)}</Typography> */}

                            {/* {! isPremiumMember && discountAmount == 0 ? <Typography className="kt-font-danger">Premium Member Price</Typography> : ""}
                            {! isPremiumMember && discountAmount == 0 ? <Typography variant="h5" className={maxPremiumPrice > 0 ? "kt-font-danger" : ""}>${maxPremiumPrice.toFixed(2)}</Typography> : ""}
                            {! isPremiumMember && discountAmount == 0 ? <Link className="d-block" to="/packages/marketplace-access">Upgrade to Premium</Link> : ""} */}

                            {isPremiumMember && discountAmount > 0 ? <Typography>Your Investment</Typography> : <Typography>Your Investment</Typography>}
                            <Typography variant="h5">${calculatedPrice.toFixed(2)}</Typography>

                            <div className="kt-margin-b-15" />
                            {/* <Button className="btn-success kt-mb-10" style={{minWidth:"150px"}} size="lg" variant="primary" onClick={() => {
                                formik.setFieldValue('add_to_cart', 0, false);
                                formik.handleSubmit();
                            }} disabled={formik.isSubmitting || calculatedPrice === 0}>Checkout</Button> */}
                            <Button onClick={() => {
                                formik.setFieldValue('add_to_cart', 1, false);
                                formik.handleSubmit();
                            }} style={{minWidth:"150px"}} size="lg" disabled={formik.isSubmitting || calculatedPrice === 0}>Add to Cart</Button>
                        </Card.Body>
                    </Card>
                </div>
            </div>

            {/* <GuestPostsInfo /> */}
        </>
    );
}

const mapStateToProps = ({ pagedata: {pageData}, cart: {userDiscountPercent}, packages: currentPackage }) => ({
    pageData, currentPackage, userDiscountPercent, packages
});

export default injectIntl(
    connect(
        mapStateToProps,
        {...cart.actions, ...pagedata.actions, ...packages.actions}
    )(Citation)
);
