import React, {Component} from "react";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import { Col, Row, } from "react-bootstrap";
import {Spinner} from "reactstrap";
import {getAnalytic} from "../../../../crud/analytics.crud";
import {IconButton, Paper} from '@material-ui/core';
import {Autorenew} from '@material-ui/icons';

class Tallies extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            load: true,
            data: undefined,
            data2: undefined
        };

    }

    getAnalyticsData = (date = false, dataInState = 'data') => {

        if(!this.props.currentProject){
            return false;
        }

        let params = {
            type: this.props.type,
            date: (date) ? date : 'last-'+this.props.days+'-days',
        };

        this.setState({load: true});


        getAnalytic(
            this.props.currentProject.id,
            params
        )
            .then((res) => {

                let result = res.data[params.type];
                result = result[Object.keys(result)[0]][0].value;

                if (typeof(result) == "undefined") {
                    this.setState({active: false, load: false});
                    return false;
                }

                this.setState({[dataInState]: +result});

                this.setState({active: true, load: false});

            })
            .catch(() => {
                  this.setState({active: false, load: false});
                  console.log('Error - getAnalytic')
            });

        /*if (typeof(this.state.data2) == "undefined") {
            date = new Date();
            date.setDate(date.getDate() - this.props.days);
            var formated_dateTo = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
            date.setDate(date.getDate() - this.props.days);
            var formated_dateFrom = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
            //console.log(formated_dateFrom +','+formated_dateTo);
            date = formated_dateFrom +','+formated_dateTo;
            dataInState = 'data2';
            params = {
                type: this.props.type,
                date: (date) ? date : 'last-'+this.props.days+'-days',
            };
            getAnalytic(
                this.props.currentProject.id,
                params
            )
                .then((res) => {

                    let result = res.data[params.type];
                    result = result[Object.keys(result)[0]][0].value;


                    if (typeof(result) == "undefined") {
                        this.setState({active: false, load: false});
                        return false;
                    }

                    this.setState({[dataInState]: +result});

                    this.setState({active: true, load: false});

                })
                .catch(() => {
                    this.setState({active: false, load: false});
                    console.log('Error - getAnalytic')
                });
        }*/
    }


    componentDidMount() {
        this.getAnalyticsData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.currentProject.id !== prevProps.currentProject.id) {
            this.getAnalyticsData();
        }
    }

    render() {

        const {active, load, data, data2} = this.state;
        const data2Percents = (data2>0 && data>0) ?  Math.round((data-data2)/data2*100) : false;
        const resClass = (data2 < data) ? 'up' : 'down';


        //console.log('this.state', this.state);

        const RefreshData = (event) => {
            this.getAnalyticsData();
        };

        if (load) {
            return (
                <div className="metric follower-growth">
                    <div className="metric-wrap">
                        <Spinner/>
                    </div>
                </div>
            );
        }


        if (!active) {
            return (
                <>
                <span className="no-data">No data</span>&nbsp;
                <IconButton onClick={RefreshData}>
                    <Autorenew />
                </IconButton>
                </>
            );
        }


        return (
            <Paper elevation={0} >
            <div className="metric follower-growth">
                <div className="metric-wrap">
                    <div className="metric-head">
                        <div className="metric-title">
                        </div>
                    </div>
                    <div className="metric-body">
                        <div className="metric-data">
                            {load && ( <Spinner/> )}
                            <div className="metric-label">{data}</div>
                            <div className="metric-info"></div>
                        </div>
                        <div className="metric-description">
                            {this.props.title} (last {this.props.days} days)
                        </div>
                        <Row className="growth-prev-period">
                            <Col xs={2} md={1}>
                                {data2Percents &&
                                    <div className={resClass + " arrow"}>&#10095;</div>
                                }
                            </Col>
                            <Col xs={10} md={11}>
                                <div className="result-text">
                                    {data2Percents &&
                                        <>You have a <span className={resClass}>{data2Percents}% growth</span></>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="metric-footer">
                    </div>
                </div>
            </div>
            </Paper>
        );
    }
}

export default injectIntl(connect(
    null,
    null,
)(Tallies));
