import axios from "axios";

export const base = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL : "";
export const API_PROJECT_URL = base + "/api/projects";
export const API_PROJECT_EDIT_URL = base + "/api/project/edit";
export const API_PROJECT_OPTIONS_URL = base + "/api/project-options";
export const API_PROJECT_CATEGORIES_URL = base + "/api/project-categories";

export function addProject(url, keywords = "", niche = null, related_keywords = "") {
    return axios.post(API_PROJECT_URL, { url, keywords, niche,related_keywords });
}

export function editProject(project_id, url, keywords = "", niche = null, related_keywords = "") {
    return axios.post(API_PROJECT_EDIT_URL, { project_id, url, keywords, niche, related_keywords });
}

export function getProjects(page = null) {
    return axios.get(API_PROJECT_URL + (page > 1 ? "?page=" + page : ""));
}

export function getProject(id) {
    return axios.get(API_PROJECT_URL + "/" + id);
}

export function deleteProject(id) {
    return axios.delete(API_PROJECT_URL + "/" + id);
}

export function getProjectOptions(projectId) {
    return axios.post(API_PROJECT_OPTIONS_URL + "/get", {project_id: projectId});
}

export function saveProjectOptions(data) {
    return axios.post(API_PROJECT_OPTIONS_URL + "/save", data);
}

export function getProjectCategories() {
    return axios.get(API_PROJECT_CATEGORIES_URL);
}
