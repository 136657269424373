import React, { Component } from "react";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import * as profile from "../../../store/ducks/profile.duck";
import {Spinner} from "reactstrap";
import {FormControl} from "@material-ui/core";
import Select from "react-select";
import {Formik} from "formik";
import {saveUserOptions} from "../../../crud/user.crud";

const selectStyles = { menu: styles => ({ ...styles, zIndex: 999, position: "relative" }) };

class ProfileSettings extends Component {
    componentDidMount() {
        this.props.requestUserOptions();
    }
    render() {
        const freqOptions = [
            {value:"daily", label:"Daily"},
            {value:"every_two_days", label:"Every 2 days"},
            {value:"every_four_days", label:"Every 4 days"},
            {value:"weekly", label:"Weekly"},
            {value:"every_two_weeks", label:"Every 2 weeks"},
            {value:"monthly", label:"Monthly"},
            {value:"never", label:"Never"},
        ];
        const {userOptions} = this.props;
        let selectedFrequency = null;

        if (!userOptions)
        {
            return <Spinner />;
        }

        if(userOptions.mgp_email_frequency) {
            for (let i = 0; i < freqOptions.length; i++) {
                if (freqOptions[i].value === userOptions.mgp_email_frequency) {
                    selectedFrequency = {value:freqOptions[i].value, label:freqOptions[i].label};
                }
            }
        }

        return (<Formik
            initialValues={{
                mgp_email_frequency: userOptions.mgp_email_frequency ? userOptions.mgp_email_frequency : "",
            }}
            validate={values => {
                const errors = {};

                return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
                const data = {
                    options: [
                        {option: "mgp_email_frequency", value: values.mgp_email_frequency}
                    ],
                };
                saveUserOptions(data)
                    .then(() => {
                        setSubmitting(false);
                        setStatus(
                            "Saved."
                        );
                        this.props.fulfillUserOptions(data);
                    })
                    .catch(() => {
                        setSubmitting(false);
                        setStatus(
                            "Error! Data is not updated."
                        );
                    });
            }}
        >
            {({
                  values,
                  status,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting
              }) => (
                <form onSubmit={handleSubmit} >
                    {status && !isSubmitting && (
                        <div role="alert" className={'alert ' + (status === 'Saved.' ? 'alert-success' : 'alert-danger')}>
                            <div className="alert-text">{status}</div>
                        </div>
                    )}

                    <div className="form-group mb-3">
                        <FormControl fullWidth>
                            <label
                                className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled"
                                data-shrink="true">Email Notifications Frequency</label>
                            <div
                                className="MuiInputBase-root MuiInput-root jss5 MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl">
                                <Select
                                    options={freqOptions}
                                    defaultValue={selectedFrequency}
                                    onChange={selectedOption => {
                                        handleChange("mgp_email_frequency")(selectedOption.value);
                                    }}
                                    noOptionsMessage={()=>{return !freqOptions ? "Loading..." : "No options";}}
                                    styles={selectStyles}
                                    className="w-100"
                                />
                            </div>
                            <div className="invalid-feedback" style={{display:!!errors.country ? "block" : "none"}}>{errors.country}</div>
                        </FormControl>
                    </div>

                    <div className="kt-login__actions">
                        <button
                            type="submit"
                            className="btn btn-primary btn-elevate kt-login__btn-primary"
                            disabled={isSubmitting}
                        >
                            Submit
                        </button>
                    </div>
                </form>
            )}
        </Formik>);
    }
}

const mapStateToProps = ({ profile: { userOptions } }) => ({
    userOptions
});

export default injectIntl(connect(
    mapStateToProps,
    profile.actions
)(ProfileSettings));
