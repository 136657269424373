import React, {useState} from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import * as auth from "../../store/ducks/auth.duck";
import { resetPassword, findPassword } from "../../crud/auth.crud";
import clsx from "clsx";

function Reset(props) {
    const { intl } = props;
    const [loading, setLoading] = useState(false);
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
        paddingRight: "2.5rem"
    });

    const enableLoading = () => {
        setLoading(true);
        setLoadingButtonStyle({ paddingRight: "3.5rem" });
    };

    const disableLoading = () => {
        setLoading(false);
        setLoadingButtonStyle({ paddingRight: "2.5rem" });
    };

    const { token } = useParams();
    const [tokenStatus, setTokenStatus] = useState(false);

    if(!tokenStatus) {
        findPassword(token).catch(() => {
            setTokenStatus("This password reset link is invalid or expired.");
        });
    }

    return (
        <div className="kt-login__body">
            <div className="kt-login__form">
                <div className="kt-login__title">
                    <h3>
                        <FormattedMessage id="AUTH.FORGOT.RESET" />
                    </h3>
                </div>

                <Formik
                    initialValues={{
                        email: "",
                        password: "",
                        confirmPassword: "",
                        token: token
                    }}
                    validateOnMount
                    validate={values => {
                        const errors = {};

                        if (!values.email) {
                            errors.email = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                        ) {
                            errors.email = intl.formatMessage({
                                id: "AUTH.VALIDATION.INVALID_FIELD"
                            });
                        }

                        if (!values.password) {
                            errors.password = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        }
                        else if(values.password.length < 6) {
                            errors.password = "Password must be at least 6 characters.";
                        }
                        else if(!/[a-zA-z]/.test(values.password)) {
                            errors.password = "Password must contain at least one letter.";
                        }
                        else if(!/[0-9]/.test(values.password)) {
                            errors.password = "Password must contain at least one digit.";
                        }

                        if (!values.confirmPassword) {
                            errors.confirmPassword = intl.formatMessage({
                                id: "AUTH.VALIDATION.REQUIRED_FIELD"
                            });
                        } else if (values.password !== values.confirmPassword) {
                            errors.confirmPassword =
                                "Password and Confirm Password didn't match.";
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        enableLoading();
                        resetPassword(
                            values.email,
                            values.password,
                            values.confirmPassword,
                            values.token
                        )
                            .then(({ data: { access_token } }) => {
                                disableLoading();
                                props.login(access_token);
                            })
                            .catch(() => {
                                disableLoading();
                                setSubmitting(false);
                                setStatus(
                                    intl.formatMessage({
                                        id: "AUTH.VALIDATION.INVALID_LOGIN"
                                    })
                                );
                            });
                    }}
                >
                    {({
                          values,
                          status,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting
                      }) => (
                        <form onSubmit={handleSubmit} noValidate autoComplete="off">
                            {(!tokenStatus || loading) ? (status && (
                                <div role="alert" className="alert alert-danger">
                                    <div className="alert-text">{status}</div>
                                </div>
                            )) :
                            (
                                <div role="alert" className="alert alert-danger">
                                    <div className="alert-text">{tokenStatus}</div>
                                </div>
                            )}

                            <div className="form-group mb-0">
                                <TextField
                                    label="Email"
                                    margin="normal"
                                    className="kt-width-full"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                    helperText={touched.email && errors.email}
                                    error={Boolean(touched.email && errors.email)}
                                />
                            </div>

                            <div className="form-group mb-0">
                                <TextField
                                    type="password"
                                    margin="normal"
                                    label="Password"
                                    className="kt-width-full"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                    helperText={touched.password && errors.password}
                                    error={Boolean(touched.password && errors.password)}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    type="password"
                                    margin="normal"
                                    label="Confirm Password"
                                    className="kt-width-full"
                                    name="confirmPassword"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.confirmPassword}
                                    helperText={touched.confirmPassword && errors.confirmPassword}
                                    error={Boolean(
                                        touched.confirmPassword && errors.confirmPassword
                                    )}
                                />
                            </div>

                            <div className="kt-login__actions">
                                <Link to="/auth">
                                    <button type="button" className="btn btn-secondary btn-elevate kt-login__btn-secondary">
                                        Back
                                    </button>
                                </Link>

                                <button
                                    type="submit"
                                    disabled={isSubmitting || (tokenStatus)}
                                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                                        {
                                            "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                                        }
                                    )}`}
                                    style={loadingButtonStyle}
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
}

export default injectIntl(
    connect(
        null,
        auth.actions
    )(Reset)
);
