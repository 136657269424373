import React, {useState} from "react";
import { useEffect } from 'react';
import introJs from 'intro.js';
import "intro.js/introjs.css";
import {saveUserOptions} from "../../crud/user.crud";
import {Button} from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";

function UpgradeToPremium(props) {

	const [path, setPath] = useState(null);

    if(path == "/packages/marketplace-access") {
        return "";
    }

    return(<>
        <Link className="kt-ml-10 btn btn-brand" to="/packages/marketplace-access">Upgrade to Premium</Link>
    </>);
}

export default withRouter(UpgradeToPremium);
