import React, { Component } from "react";
import {connect} from "react-redux";
import { injectIntl } from "react-intl";
import * as projects from "../../../store/ducks/projects.duck";
import * as onpage from "../../../store/ducks/onpage.duck";
import {Spinner} from "reactstrap";
import {Portlet, PortletBody, PortletHeader} from "../../../partials/content/Portlet";
import {Button, Table, Badge, Form, Col} from "react-bootstrap";
import {setAudit} from "../../../crud/onpage.crud";
import DoughnutChart from "../../../widgets/DoughnutChart";
import {Link} from "react-router-dom";
import AuditFilteredPages from "./AuditFilteredPages";
import objectHash from "object-hash";
import {ListGroup} from "react-bootstrap";
import UPagination from "../../../partials/content/Pagination";
import Select from "react-select";
import AnchorsWidget from "../../../widgets/AnchorsWidget";
import AuditProgress from "./AuditProgress";
import AuditStart from "./AuditStart";
import { withRouter } from 'react-router-dom';

const metricLabels = {
    "time_load_high":"Speed Performance",
    "duplicate_pages":"Duplicate Content",
    "duplicate_titles":"Duplicate Meta Titles",
    "duplicate_meta_descriptions":"Duplicate Meta Descriptions",
    "canonical_another":"Canonical Pages",
    "links_broken":"Broken Links",
    "absent_h1_tags":"Pages without H1 title",
    "title_empty":"Missing Titles",
    "meta_description_empty":"Missing Descriptions",
    "content_readability_bad":"Content Readability",
    "title_long":"Too long title length",
    "title_short":"Too short title length",
    "have_robots":"Have Robots",
    "have_sitemap":"Have Sitemap",
    "pages_https":"Pages https://",
    "pages_http":"Pages http://",
    "links_internal":"Links internal",
    "links_external":"Links external",
    "absent_doctype":" Absent Doctype",
    "absent_encoding_meta_tag":"Absent Encoding Meta Tag",
    "images_invalid_alt":"Invalid Image Alt",
    "images_invalid_title":"Invalid Image Title",
    "duplicate_h1":"Duplicate H1 Titles",
    "meta_description_long":"Too long meta descriptions",
    "meta_description_short":"Too short meta descriptions",
};

const criticalMetrics = [
    "time_load_high",
    "duplicate_pages",
    "duplicate_titles",
    "duplicate_meta_descriptions",
    "links_broken",
    "duplicate_h1",
];
const warningMetrics = [
    "absent_h1_tags",
    "title_empty",
    "meta_description_empty",
    "content_readability_bad",
    "title_long",
    "title_short",
    "meta_description_long",
    "meta_description_short",
];
const otherWarnings = [
    "absent_doctype",
    "absent_encoding_meta_tag",
    "images_invalid_alt",
    "images_invalid_title",
];
const infoMetrics = [
    "canonical_another",
    "links_external",
    "links_internal",
    "pages_http",
    "pages_https",
    "have_robots",
    "have_sitemap",
];
const pageCriticalMetrics = [
    "time_total_load",
    "have_page_duplicates",
    "have_title_duplicates",
    "have_meta_description_duplicates",
    "links_broken",
    "have_h1_duplicates"
];
const pageWarningMetrics = [
    "absent_h1_tags",
    "title_empty",
    "title_long",
    "title_short",
    "meta_description_empty",
    "content_readability_flesh_kincaid",
    "meta_description_long",
    "meta_description_short",
];
const pageOtherWarnings = [
    "have_doctype",
    "have_enc_meta_tag",
    "images_invalid_alt",
    "images_invalid_title",
];
const filterMetric = {
    "have_page_duplicates":"duplicate_pages",
    "have_title_duplicates":"duplicate_titles",
    "have_meta_description_duplicates":"duplicate_meta_descriptions",
    "have_h1_duplicates":"duplicate_h1",
    "content_readability_flesh_kincaid":"content_readability_bad",
    "have_doctype":"absent_doctype",
    "have_enc_meta_tag":"absent_encoding_meta_tag",
};

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        minHeight: '47px',
    }),
};

class AuditSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: undefined,
            filterKey: undefined,
            selectedLinkType: undefined,
            linkPageFrom: undefined,
            linkPageFromInvalid: false,
            linkKeyword: undefined,
            linkKeywordInvalid: false,
        };
        this.myRef = React.createRef();
        this.linksRef = React.createRef();
    }
    componentDidMount() {
        let projectId = null;

        if(this.props.match && this.props.match.params && this.props.match.params.projectId) {
            projectId = this.props.match.params.projectId;
        }
        else {
            projectId = this.props.currentProjectId;
        }

        if(projectId > 0) {
            this.props.requestProject(projectId);
            this.props.initSummary(projectId);
            this.props.requestLinks(projectId);
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const {currentProjectId} = this.props;
        const page = 1;
        if(this.state.selectedLinkType !== prevState.selectedLinkType ||
            (this.state.linkPageFrom !== prevState.linkPageFrom && !this.state.linkPageFromInvalid) ||
            (this.state.linkKeyword !== prevState.linkKeyword && !this.state.linkKeywordInvalid)
        ) {
            this.props.requestLinks(currentProjectId, page, this.state.selectedLinkType, this.state.linkPageFrom, this.state.linkKeyword);
        }
        if(this.props.currentProjectId > 0 && this.props.currentProjectId !== prevProps.currentProjectId) {
            this.props.initSummary(this.props.currentProjectId);
            this.props.requestLinks(this.props.currentProjectId);
        }
    }

    render() {
        const project = this.props.currentProject;
        const audit = this.props.summary;
        const { currentProjectId, links } = this.props;

        if(!currentProjectId) {
            return (
                <Portlet>
                    <PortletBody>
                        <h1>OnPage SEO Audit</h1>
                        <p>Choose a project here: <Link to="/projects">Projects Page</Link></p>
                    </PortletBody>
                </Portlet>
            );
        }
        else if (!project) {
            return <Spinner/>;
        }
        else if(!project.data) {
            return (
                <>
                    <h1>Project not found!</h1>
                </>
            );
        }

        if(!audit) {
            return <Spinner/>;
        }
        else if(audit.in_queue) {
            return (
                <>
                    {/*<h1>OnPage Audit is not ready</h1>
                    <br/>
                    <Button onClick={()=>{this.props.initSummary(project.data.id);}}>Refresh</Button>*/}
                    <AuditProgress compact={this.props.loadMainWidgets ? true : false} />
                </>
            );
        }
        else if(!audit.data){
            return (
               <AuditStart projectId={project.data.id} compact={this.props.loadMainWidgets ? true : false} />
            );
        }

        const scrollToMyRef = () => {window.scrollTo(0, this.myRef.current.offsetTop);}
        const scrollToLinksRef = () => {window.scrollTo(0, this.linksRef.current.offsetTop);}

        const summary = audit.data.summary ? audit.data.summary[0] : null;
        const criticalRows = [];
        const warningRows = [];
        const otherWarningRows = [];
        const infoRows = [];
        let criticalErrorsCnt = 0;
        let warningsCnt = 0;
        let passedCnt = 0;
        let issuesPointsSum = 0;
        //const otherRows = [];
        const lgItemsCritical = [];
        const lgItemsWarning = [];
        const lgItemsOtherWarning = [];
        const lgItemsInfo = [];
        const lgItemsMetrics = [];
        if(summary && summary.length !== 0) {
            for (let [key, value] of Object.entries(summary)) {
                if (criticalMetrics.indexOf(key) !== -1) {
                    if(value > 0) {
                        criticalErrorsCnt++;

                        //list group item
                        const item = <ListGroup.Item key={"lg" + key} action variant="danger" onClick={(e)=>{e.preventDefault();changeMetric(key);scrollToMyRef();}}>
                            {metricLabels[key] ? metricLabels[key] : key}: <strong>{value}</strong> {value === 1 ? "page" : "pages"}
                        </ListGroup.Item>;
                        lgItemsCritical.push(item);
                    }
                    else {
                        passedCnt++;

                        //list group item
                        const item = <ListGroup.Item key={"lg" + key} action variant="info">
                            {metricLabels[key] ? metricLabels[key] : key}: <strong>Passed</strong>
                        </ListGroup.Item>;
                        lgItemsInfo.push(item);
                    }
                    issuesPointsSum += 2;
                    criticalRows.push(<tr key={key}>
                        <td>{metricLabels[key] ? metricLabels[key] : key}</td>
                        <td>{value > 0 ? <Badge pill variant="danger">Critical</Badge> : <Badge pill variant="info">Passed</Badge>}</td>
                        <td>{value > 0 ? <a href="#" role="button" onClick={(e)=>{e.preventDefault();changeMetric(key);}}>{value}</a> : value}</td>
                    </tr>);
                }
                else if(warningMetrics.indexOf(key) !== -1) {
                    if(value > 0) {
                        warningsCnt++;

                        //list group item
                        const item = <ListGroup.Item key={"lg" + key} action variant="warning" onClick={(e)=>{e.preventDefault();changeMetric(key);scrollToMyRef();}}>
                            {metricLabels[key] ? metricLabels[key] : key}: <strong>{value}</strong> {value === 1 ? "page" : "pages"}
                        </ListGroup.Item>;
                        lgItemsWarning.push(item);
                    }
                    else {
                        passedCnt++;

                        //list group item
                        const item = <ListGroup.Item key={"lg" + key} action variant="info">
                            {metricLabels[key] ? metricLabels[key] : key}: <strong>Passed</strong>
                        </ListGroup.Item>;
                        lgItemsInfo.push(item);
                    }
                    issuesPointsSum++;
                    warningRows.push(<tr key={key}>
                        <td>{metricLabels[key] ? metricLabels[key] : key}</td>
                        <td>{value > 0 ? <Badge pill variant="warning">Warning</Badge> : <Badge pill variant="info">Passed</Badge>}</td>
                        <td>{value > 0 ? <a href="#" role="button" onClick={(e)=>{e.preventDefault();changeMetric(key);}}>{value}</a> : value}</td>
                    </tr>);
                }
                else if(otherWarnings.indexOf(key) !== -1) {
                    if(value > 0) {
                        warningsCnt++;

                        //list group item
                        const item = <ListGroup.Item key={"lg" + key} action variant="warning" onClick={(e)=>{e.preventDefault();changeMetric(key);scrollToMyRef();}}>
                            {metricLabels[key] ? metricLabels[key] : key}: <strong>{value}</strong> {value === 1 ? "page" : "pages"}
                        </ListGroup.Item>;
                        lgItemsOtherWarning.push(item);
                    }
                    else {
                        passedCnt++;

                        //list group item
                        const item = <ListGroup.Item key={"lg" + key} action variant="info">
                            {metricLabels[key] ? metricLabels[key] : key}: <strong>Passed</strong>
                        </ListGroup.Item>;
                        lgItemsInfo.push(item);
                    }
                    issuesPointsSum++;
                    otherWarningRows.push(<tr key={key}>
                        <td>{metricLabels[key] ? metricLabels[key] : key}</td>
                        <td>{value > 0 ? <Badge pill variant="warning">Warning</Badge> : <Badge pill variant="info">Passed</Badge>}</td>
                        <td>{value > 0 ? <a href="#" role="button" onClick={(e)=>{e.preventDefault();changeMetric(key);}}>{value}</a> : value}</td>
                    </tr>);
                }
                else if(infoMetrics.indexOf(key) !== -1) {
                    let val = "";
                    if(value === true) {
                        val = "✔";
                    }
                    else if(value === false) {
                        val = "×";
                    }
                    infoRows.push(<tr key={key}>
                        <td>{metricLabels[key] ? metricLabels[key] : key}</td>
                        <td>{val ? val : value}</td>
                    </tr>);

                    //list group item
                    const item = <ListGroup.Item key={"lg" + key} action variant="secondary">
                        {metricLabels[key] ? metricLabels[key] : key}: <strong>{val ? val : value}</strong>
                    </ListGroup.Item>;
                    lgItemsMetrics.push(item);
                }
                /*else {
                    otherRows.push(<tr key={key}>
                        <td>{metricLabels[key] ? metricLabels[key] : key}</td>
                        <td>{value === true ? "+" : value}</td>
                    </tr>);
                }*/
            }
        }

        const total_errors = criticalErrorsCnt + warningsCnt;
        const siteScore = (1 - (criticalErrorsCnt * 2 + warningsCnt) / (issuesPointsSum !== 0 ? issuesPointsSum : 1) ).toFixed(2);
        let scoreColor = 0;
        if(siteScore > 0.45 && siteScore <= 0.80) {
            scoreColor = 1;
        }
        else if(siteScore > 0.80 && siteScore <= 0.90)
        {
            scoreColor = 3;
        }
        else if(siteScore > 0.90) {
            scoreColor = 5;
        }

        const changeMetric = (filterKey) => {
            const pageFilter = [];
            let metric = filterKey;
            if(filterMetric) {
                for (let [key, value] of Object.entries(filterMetric)) {
                    if(value === filterKey) {
                        metric = key;
                        break;
                    }
                }
            }
            pageFilter.push(metric);
            this.setState({filterKey: metric, filter: pageFilter});
            scrollToMyRef();
        };

        const handleMetricChange = (value) => {
            let pageFilter = [];
            let filterKey = null;
            if(value && value.length !== 0) {
                if(value.length === 1) {
                    pageFilter.push(value[0].value);
                    filterKey = value[0].value;
                }
                else {
                    let selectedItems = [];
                    for (let i = 0; i < value.length; i++) {
                        selectedItems.push(value[i].value);
                    }
                    pageFilter = selectedItems;
                    filterKey = objectHash(selectedItems);
                }
            }
            scrollToMyRef();
            this.setState({filterKey: filterKey, filter: pageFilter});
        };

        const filterByErrorType = (type) => {
            if(this.props.loadMainWidgets) {
                this.props.history.push({
                    pathname: "/onpage-audit"
                });
            }
            else {
                const metrics = [];
                if (type === "error") {
                    for (let i = 0; i < pageCriticalMetrics.length; i++) {
                        const metric = filterMetric[pageCriticalMetrics[i]] ? filterMetric[pageCriticalMetrics[i]] : pageCriticalMetrics[i];
                        if (summary[metric] > 0) {
                            metrics.push({value: pageCriticalMetrics[i]});
                        }
                    }
                    handleMetricChange(metrics);
                } else if (type === "warning") {
                    for (let i = 0; i < pageWarningMetrics.length; i++) {
                        const metric = filterMetric[pageWarningMetrics[i]] ? filterMetric[pageWarningMetrics[i]] : pageWarningMetrics[i];
                        if (summary[metric] > 0) {
                            metrics.push({value: pageWarningMetrics[i]});
                        }
                    }
                    for (let i = 0; i < pageOtherWarnings.length; i++) {
                        const metric = filterMetric[pageOtherWarnings[i]] ? filterMetric[pageOtherWarnings[i]] : pageOtherWarnings[i];
                        if (summary[metric] > 0) {
                            metrics.push({value: pageOtherWarnings[i]});
                        }
                    }
                    handleMetricChange(metrics);
                } else if (type === "passed") {
                    //handleMetricChange(metrics);
                }
            }
        };

        const handleLinkTypeChange = (value) => {
            this.setState({selectedLinkType: value.value});
        };
        const filterLinksByType = (type) => {
            handleLinkTypeChange({value:type});
            scrollToLinksRef();
        };

        const linksRows = [];
        if(links && links.data) {
            if (links.data.data && links.data.data.length) {
                for (const [index] of links.data.data.entries()) {
                    linksRows.push(<tr key={"l" + index}>
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <td><a href={links.data.data[index].link_to} target="_blank">{links.data.data[index].link_to}</a></td>
                        <td>{links.data.data[index].anchor}</td>
                        <td>{links.data.data[index].nofollow ? "nofollow" : "dofollow"}</td>
                        <td><a href={links.data.data[index].link_from} target="_blank">{links.data.data[index].link_from}</a></td>
                    </tr>);
                }
            }
        }
        const handleNextLinkPage = (activePage) => {
            this.props.requestLinks(currentProjectId, activePage, this.state.selectedLinkType, this.state.linkPageFrom, this.state.linkKeyword);
        };

        const changeLinkPageFrom = (val) => {
            /*const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ //port
                '(\\?[;&amp;a-z\\d%_.~+=-]*)?'+ // query string
                '(\\#[-a-z\\d_]*)?$','i');*/

            if(/*!pattern.test(val) && */val !== "") {
                this.setState({linkPageFromInvalid: true, linkPageFrom: val});
            }
            else {
                this.setState({linkPageFromInvalid: false, linkPageFrom: val});
            }
        };
        const handleLinkPageFrom = (e) => {
            const val = e.target.value;
            changeLinkPageFrom(val);
        };
        const showLinksForPage = (val) => {
            changeLinkPageFrom(val);
            scrollToLinksRef();
        };
        const handleLinkKeyword = (e) => {
            const val = e.target.value;
            if(val.length !== 0 && (val.length < 3 || val.length > 150)) {
                this.setState({linkKeywordInvalid: true, linkKeyword: val});
            }
            else {
                this.setState({linkKeywordInvalid: false, linkKeyword: val});
            }
        };

        if(this.props.loadMainWidgets) {
            const redirectToAuditPage = () => {
                this.props.history.push({
                    pathname: "/onpage-audit"
                });
            };

            return (
                <>
                    <div className="row">
                        <div className="col-sm-12">
                            <Portlet className="kt-portlet--height-fluid kt-portlet--border-bottom-brand" onClick={redirectToAuditPage} style={{cursor:"pointer"}}>
                                <PortletHeader
                                    title="Site Score"
                                />

                                <div className="d-sm-flex flex-sm-row justify-content-center audit-main-widgets">
                                <div>
                                <DoughnutChart
                                    value={(siteScore * 100).toFixed(0) + "%"}
                                    desc="Calculated Site Score"
                                    data={[(siteScore * 100).toFixed(0), ((1 - siteScore) * 100).toFixed(0)]}
                                    labels={["", ""]}
                                    colorNames={[scoreColor]}
                                    hideLink={true}
                                />
                                </div>
                                <div>
                                <DoughnutChart
                                    value={total_errors}
                                    desc="The total amount of on-site SEO errors"
                                    data={[criticalErrorsCnt, warningsCnt, passedCnt]}
                                    labels={["Critical Errors", "Warnings", "Passed"]}
                                    hideLink={true}
                                    clickCallback={filterByErrorType}
                                    clickParams={["error", "warning"]}
                                    mainWidget={this.props.loadMainWidgets}
                                />
                                </div>
                                </div>

                            </Portlet>
                        </div>
                    </div>
                </>
            );
        }

        return (
            <>
                <div className="row">
                    {/*<div className="col-xl-2 d-xl-block d-none" />*/}
                    <div className="col-xl-2 col-lg-6 col-sm-6">
                        <Portlet className="kt-portlet--height-fluid kt-portlet--border-bottom-brand">
                            <PortletHeader
                                title="Site Score"
                            />

                            <DoughnutChart
                                value={(siteScore * 100).toFixed(0) + "%"}
                                desc="Calculated Site Score"
                                data={[(siteScore * 100).toFixed(0), ((1 - siteScore) * 100).toFixed(0)]}
                                labels={["", ""]}
                                colorNames={[scoreColor]}
                                hideLink={true}
                            />
                        </Portlet>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-sm-6">
                        <Portlet className="kt-portlet--height-fluid kt-portlet--border-bottom-brand">
                            <PortletHeader
                                title="Technical SEO Errors"
                            />

                            <DoughnutChart
                                value={total_errors}
                                desc="The total amount of on-site SEO errors"
                                data={[criticalErrorsCnt, warningsCnt, passedCnt]}
                                labels={["Critical Errors", "Warnings", "Passed"]}
                                hideLink={true}
                                clickCallback={filterByErrorType}
                                clickParams={["error", "warning"]}
                            />
                        </Portlet>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-sm-6">
                        {/*<Portlet className="kt-portlet--height-fluid kt-portlet--border-bottom-brand">
                            <PortletHeader
                                title="Status Codes"
                            />

                            <DoughnutChart
                                value={summary.response_code_1xx + summary.response_code_2xx + summary.response_code_3xx + summary.response_code_4xx + summary.response_code_5xx + summary.response_code_other}
                                desc="HTTP Status Codes for all pages"
                                data={[summary.response_code_1xx, summary.response_code_2xx, summary.response_code_3xx, summary.response_code_4xx, summary.response_code_5xx, summary.response_code_other ]}
                                labels={["HTTP 1xx", "HTTP 2xx", "HTTP 3xx", "HTTP 4xx", "HTTP 5xx", "Other"]}
                                colorNames={[5, 2, 1, 0, 7, 3]}
                                hideLink={true}
                                hideZeroValues={true}
                            />
                        </Portlet>*/}
                        <AnchorsWidget projectId={project.data.id} />
                    </div>
                    <div className="col-xl-4 col-lg-6 col-sm-6">
                        <Portlet className="kt-portlet--height-fluid kt-portlet--border-bottom-brand">
                            <PortletHeader
                                title="Internal Link Structure"
                            />

                            {summary && <DoughnutChart
                                value={summary.links_internal + summary.links_external}
                                desc="The total amount of internal links"
                                data={[summary.links_internal, summary.links_external]}
                                labels={["Internal Links", "External Links"]}
                                colorNames={[5, 4]}
                                hideLink={true}
                                clickCallback={filterLinksByType}
                                clickParams={["links_internal", "links_external"]}
                            />}
                        </Portlet>
                    </div>
                    {/*<div className="col-xl-2 d-xl-block d-none" />*/}
                </div>

                <Portlet>
                    <PortletHeader title={"OnPage SEO Audit: " + project.data.url} />
                    <PortletBody>
                        <div className="kt-mb-10">
                            <strong>Domain:</strong> {summary && summary.domain}<br/>
                            <strong>Crawl Date:</strong> {summary && (new Date(summary.crawl_start)).toLocaleString()} <Link to={"/onpage-audit/" + project.data.id + "/new"}>Rescan</Link>
                        </div>
                        <div className="row">
                            {(lgItemsCritical || lgItemsWarning || lgItemsOtherWarning) && <div className="col-lg-4">
                                <ListGroup>
                                    {lgItemsCritical}
                                    {lgItemsWarning}
                                    {lgItemsOtherWarning}
                                </ListGroup>
                            </div>}
                            {lgItemsInfo && <div className="col-lg-4">
                                <ListGroup>
                                    {lgItemsInfo}
                                </ListGroup>
                            </div>}
                            {lgItemsMetrics && <div className="col-lg-4">
                                <ListGroup>
                                    {lgItemsMetrics}
                                </ListGroup>
                            </div>}
                        </div>
                    </PortletBody>
                </Portlet>
                <div ref={this.myRef} />
                <AuditFilteredPages projectId={project.data.id} filterKey={this.state.filterKey} filter={this.state.filter} handleMetricChange={handleMetricChange} showLinksForPage={showLinksForPage} />
                <div ref={this.linksRef} />
                <Portlet>
                    <PortletHeader
                        title="Links"
                    />
                    <PortletBody>
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col} className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12" controlId="formLinkType">
                                    <Select
                                        options={[{value:"", label:"All links"}, {value:"links_internal", label:metricLabels["links_internal"]}, {value:"links_external", label:metricLabels["links_external"]}]}
                                        value={this.state.selectedLinkType ? {value:this.state.selectedLinkType, label:metricLabels[this.state.selectedLinkType]} : ""}
                                        onChange={handleLinkTypeChange}
                                        placeholder="Select Link Type..."
                                        styles={customStyles}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} className="col-xl-4 col-lg-6 col-md-8 col-sm-6 col-12" controlId="formPageUrl">
                                    <Form.Control
                                        type="search"
                                        value={this.state.linkPageFrom ? this.state.linkPageFrom : ""}
                                        placeholder="Enter Page URL..."
                                        onChange={handleLinkPageFrom}
                                        isInvalid={this.state.linkPageFromInvalid}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Invalid URL format
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} className="col-xl-4 col-lg-6 col-md-8 col-sm-6 col-12" controlId="formLinkKeyword">
                                    <Form.Control
                                        type="search"
                                        value={this.state.linkKeyword ? this.state.linkKeyword : ""}
                                        placeholder="Find link by keyword..."
                                        onChange={handleLinkKeyword}
                                        isInvalid={this.state.linkKeywordInvalid}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Keyword too short or too long
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                        <Table responsive>
                            <thead>
                            <tr>
                                <th>Link</th><th>Anchor</th><th>Link Tag</th><th>Page</th>
                            </tr>
                            </thead>
                            <tbody>
                            {links ? linksRows : <tr><td colSpan={2}><Spinner /></td></tr>}
                            </tbody>
                        </Table>
                        {(links && links.data && links.data.last_page > 1) && <UPagination currentPage={links.data.current_page} totalPages={links.data.last_page} onChange={handleNextLinkPage} />}
                    </PortletBody>
                </Portlet>
                {/*<Portlet>
                    <PortletHeader title={"OnPage SEO Audit: " + project.data.url} />
                    <PortletBody>
                        <div>
                            <strong>Domain:</strong> {summary.domain}
                        </div>
                        <div>
                            <strong>Crawl Date:</strong> {(new Date(summary.crawl_start)).toLocaleString()}
                        </div>
                        <div className="kt-space-20" />
                        <div className="row">
                            <div className="col-lg-6">
                                <Table responsive>
                                <thead>
                                <tr>
                                    <th>Metric</th>
                                    <th>Status</th>
                                    <th>Number</th>
                                </tr>
                                </thead>
                                <tbody>
                                {criticalRows}
                                {warningRows}
                                {otherWarningRows}
                                </tbody>
                            </Table>
                            </div>
                            <div className="col-lg-3">
                                <Table responsive>
                                    <thead>
                                    <tr>
                                        <th>Metric</th>
                                        <th>Value</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {infoRows}
                                    </tbody>
                                </Table>
                                <Table responsive>
                                    <thead>
                                    <tr>
                                        <th>Metric</th>
                                        <th>Value</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {otherRows}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </PortletBody>
                </Portlet>*/}
            </>
        );
    }
}

const mapStateToProps = ({ projects: { currentProject, currentProjectId }, onpage: { summary, links } }) => ({
    currentProject, currentProjectId, summary, links
});

export default withRouter(injectIntl(connect(
    mapStateToProps,
    {...projects.actions, ...onpage.actions}
)(AuditSummary)));
