import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {getProjects, getProject, getProjectOptions, getProjectCategories} from "../../crud/projects.crud";

export const actionTypes = {
    ProjectsInit: "[Init Projects] Action",
    ProjectsRequested: "[Request Projects] Action",
    ProjectsLoaded: "[Load Projects] Projects API",
    ProjectRequested: "[Request Project] Action",
    ProjectLoaded: "[Load Project] Projects API",
    ProjectsReload: "[Reload Projects] Action",
    ProjectOptionsRequested: "[Request Project Options] Action",
    ProjectOptionsLoaded: "[Load Project Options] Projects API",
    ProjectCategoriesRequested: "[Request Project Categories] Action",
    ProjectCategoriesLoaded: "[Load Project Categories] Projects API",
};

const initialProjectsState = {
    projects: undefined,
    currentProject: undefined,
    currentProjectId: undefined,
    projectOptions: {},
    projectCategories:undefined,
};

export const reducer = persistReducer(
    { storage, key: "demo3-projects", whitelist: ["projects", "currentProject", "currentProjectId", "projectOptions", "projectCategories"] },
    (state = initialProjectsState, action) => {
        switch (action.type) {
            case actionTypes.ProjectsReload: {
                return { ...state, projects: undefined, currentProject: undefined, currentProjectId: undefined };
            }

            case actionTypes.ProjectsInit: {
                return { ...state, projects: undefined, currentProject: undefined };
            }

            case actionTypes.ProjectsLoaded: {
                const { projects } = action.payload;

                let currentProjectId = state.currentProjectId;
                if(!currentProjectId && projects && projects.data && projects.data[0]) {
                    currentProjectId = projects.data[0].id;
                }

                return { ...state, projects, currentProjectId };
            }

            case actionTypes.ProjectRequested: {
                const {id} = action.payload;

                return { ...state, currentProject: undefined, currentProjectId: id };
            }

            case actionTypes.ProjectLoaded: {
                const { currentProject } = action.payload;

                return { ...state, currentProject };
            }

            case actionTypes.ProjectOptionsRequested: {
                const {projectId} = action.payload;

                return { ...state, projectOptions: {[projectId]: undefined} };
            }

            case actionTypes.ProjectOptionsLoaded: {
                const { projectOptions, projectId } = action.payload;

                return { ...state, projectOptions: {[projectId]: projectOptions} };
            }

            case actionTypes.ProjectCategoriesRequested: {
                return { ...state, projectCategories: undefined };
            }

            case actionTypes.ProjectCategoriesLoaded: {
                const { projectCategories } = action.payload;

                return { ...state, projectCategories: projectCategories };
            }

            default:
                return state;
        }
    }
);

export const actions = {
    initProjects: page => ({ type: actionTypes.ProjectsInit, payload: { page } }),
    requestProjects: page => ({ type: actionTypes.ProjectsRequested, payload: { page } }),
    fulfillProjects: projects => ({ type: actionTypes.ProjectsLoaded, payload: { projects } }),
    requestProject: id => ({ type: actionTypes.ProjectRequested, payload: { id } }),
    fulfillProject: currentProject => ({ type: actionTypes.ProjectLoaded, payload: { currentProject } }),
    reloadProjects: () => ({ type: actionTypes.ProjectsReload }),
    requestProjectOptions: (projectId) => ({ type: actionTypes.ProjectOptionsRequested, payload: { projectId } }),
    fulfillProjectOptions: (projectOptions, projectId) => ({ type: actionTypes.ProjectOptionsLoaded, payload: { projectOptions, projectId } }),
    requestProjectCategories: () => ({ type: actionTypes.ProjectCategoriesRequested }),
    fulfillProjectCategories: (projectCategories) => ({ type: actionTypes.ProjectCategoriesLoaded, payload: { projectCategories } }),
};

export function* saga() {
    yield takeLatest(actionTypes.ProjectsInit, function* initProjectsSaga(action) {
        const { page } = action.payload;
        yield put(actions.requestProjects(page));
    });

    yield takeLatest(actionTypes.ProjectsRequested, function* projectsRequested(action) {
        const { page } = action.payload;
        const {data: projects} = yield getProjects(page);

        yield put(actions.fulfillProjects(projects));
    });


    yield takeLatest(actionTypes.ProjectRequested, function* projectRequested(action) {
        try {
            const { id } = action.payload;
            const {data: currentProject} = yield getProject(id);

            yield put(actions.fulfillProject(currentProject));
        }
        catch (e) {
            yield put(actions.fulfillProject({data: undefined}));
        }
    });

    yield takeLatest(actionTypes.ProjectsReload, function* reloadProjectsSaga(action) {
        yield put(actions.requestProjects());
    });

    yield takeLatest(actionTypes.ProjectOptionsRequested, function* projectOptionsRequested(action) {
        const { projectId } = action.payload;
        try {
            const {data: projectOptions} = yield getProjectOptions(projectId);

            yield put(actions.fulfillProjectOptions(projectOptions, projectId));
        }
        catch (e) {
            yield put(actions.fulfillProjectOptions({data: undefined}, projectId));
        }
    });

    yield takeLatest(actionTypes.ProjectCategoriesRequested, function* projectCategoriesRequested(action) {
        try {
            const {data: projectCategories} = yield getProjectCategories();

            yield put(actions.fulfillProjectCategories(projectCategories));
        }
        catch (e) {
            yield put(actions.fulfillProjectCategories({data: undefined}));
        }
    });
}
