import React, { Component } from "react";
import AnchorsChart from "../pages/home/onpage-audit/AnchorsChart";
import AnchorsModal from "../pages/home/onpage-audit/AnchorsModal";

export default class AnchorsWidget extends Component {
    constructor(props) {
        super(props);
        this.state = { modalShow: false };
    }
    render() {
        const {projectId} = this.props;

        const modalClose = () => {this.setState({ modalShow: false })};
        const openAnchorsDetails = () => {
            this.setState({ modalShow: true });
        };

        return (
            <>
                <AnchorsChart projectId={projectId} openAnchorsDetails={openAnchorsDetails} />
                {this.state.modalShow && <AnchorsModal projectId={projectId} show={this.state.modalShow} onHide={modalClose} />}
            </>
        );
    }
}
