import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as guestposts from "../../store/ducks/admin/guestPosts.duck";
import { approveWriter, rejectWriter } from "../../crud/admin/orders.crud";
import {Spinner} from "reactstrap";
import {Table, Badge} from "react-bootstrap";
import {Portlet, PortletBody, PortletHeader} from "../../partials/content/Portlet";
import UPagination from "../../partials/content/Pagination";
import {Button} from "react-bootstrap";

class WritersAdmin extends Component {
     constructor(props) {
        super(props);

        let sending = false;

        this.state = {
            sending: sending
        };

    }

    componentDidMount() {
        this.props.initWriters(0);
        let sending = false;
        
        this.state = {
            sending: sending
        };
    }

    render() {

        const { writers } = this.props;
        let sending = this.props.sending;

        if (!writers) {
            return <Spinner/>;
        }

        this.handleRejectWriter = (e) => {
	    	e.preventDefault();
	    	let user_id = e.target.name;
	    	this.setState({
                ...this.state,
        		sending: true
        	});
	    	rejectWriter(
                user_id
            )
            .then((res) => {
                this.setState({
	                ...this.state,
	        		sending: false
	        	});
	        	this.props.initWriters();
            })
            .catch(e => {
            	this.setState({
	                ...this.state,
	        		sending: false
	        	});
	        	this.props.initWriters();
                if(e && e.response && e.response.data) {
                    // setStatus(
                    //     e.response.data.message
                    // );
                }
            });
	    };

	    this.handleApproveWriter = (e) => {
	    	e.preventDefault();
	    	let user_id = e.target.name;
	    	this.setState({
                ...this.state,
        		sending: true
        	});
	    	approveWriter(
                user_id
            )
            .then((res) => {
                this.setState({
	                ...this.state,
	        		sending: false
	        	});
	        	this.props.initWriters();
            })
            .catch(e => {
            	this.setState({
	                ...this.state,
	        		sending: false
	        	});
                if(e && e.response && e.response.data) {
                    // setStatus(
                    //     e.response.data.message
                    // );
                }
            });
	    };

        const rows = [];

        if (writers.data) {
            if (writers.data.length) {
                for (const [index] of writers.data.entries()) {
                	const date = new Date(writers.data[index].created_at);

                	let categories_as_string = '';
                	for(let i = 0; i < writers.data[index].categories.length; i++) {
                		let xCategory = writers.data[index].categories[i];

                		if (categories_as_string == '') {
                			categories_as_string = xCategory;
                		} else {
                			categories_as_string = categories_as_string+', '+xCategory;
                		}
                	}

                	if(writers.data[index].status_title.name == "Pending Approval") {
	                    rows.push(<tr key={index}>
	                        <td>{writers.data[index].user_id}</td>
	                        <td>{writers.data[index].user.firstname} {writers.data[index].user.lastname}</td>
	                        <td>{writers.data[index].user.email}</td>
	                        <td>{date.toLocaleString()}</td>
	                        <td>{writers.data[index].portfolio_url}</td>
	                        <td>{categories_as_string}</td>
	                        <td>${writers.data[index].content_price}</td>
	                        <td><Badge pill variant={writers.data[index].status_title.status_color}>{writers.data[index].status_title.name}</Badge></td>
	                        <td>
	                        	<Button disabled={this.state.sending} className="btn-sm kt-mr-15" name={writers.data[index].user_id} onClick={(e) => this.handleApproveWriter(e)}>Approve</Button>
								<Button disabled={this.state.sending} className="btn-danger btn-sm" name={writers.data[index].user_id} onClick={(e) => this.handleRejectWriter(e)}>Reject</Button>
	                        </td>
	                    </tr>)
	                } else {
	                	rows.push(<tr key={index}>
	                        <td>{writers.data[index].user_id}</td>
	                        <td>{writers.data[index].user.firsname} {writers.data[index].user.lastname}</td>
	                        <td>{writers.data[index].user.email}</td>
	                        <td>{date.toLocaleString()}</td>
	                        <td>{writers.data[index].portfolio_url}</td>
	                        <td>{categories_as_string}</td>
	                        <td>${writers.data[index].content_price}</td>
	                        <td><Badge pill variant={writers.data[index].status_title.status_color}>{writers.data[index].status_title.name}</Badge></td>
	                        <td>
	                        </td>
	                    </tr>)
	                }
                }
            }
        }

        const handleNextPage = (number) => {
            this.props.initWriters(number);
        };

        return (
            <Portlet>
                <PortletHeader title="Writers" />
                <PortletBody className="pt-0">
                    <Table striped bordered hover responsive>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Writer</th>
                            <th>Email</th>
                         	<th>Date Applied</th>
                            <th>Portfolio URL</th>
                            <th>Writing Topics</th>
                            <th>Price per 100 words?</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </Table>
                    {writers.meta && writers.meta.last_page > 1 && <UPagination currentPage={writers.meta.current_page} totalPages={writers.meta.last_page} onChange={handleNextPage} />}
                </PortletBody>
            </Portlet>
        );
    }
}


const mapStateToProps = ({ guestpostsAdmin: { writers } }) => ({
    writers
});

export default injectIntl(connect(
    mapStateToProps,
    guestposts.actions
)(WritersAdmin));
