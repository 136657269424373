import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as services from "../../store/ducks/admin/services.duck";
import {Spinner} from "reactstrap";
import {Portlet, PortletBody} from "../../partials/content/Portlet";
import {Button, Form} from "react-bootstrap";
import { Formik } from "formik";
import {Typography, TextField, Breadcrumbs, FormControlLabel, Checkbox} from "@material-ui/core";
import {editLinkCleanUpService} from "../../crud/admin/orders.crud";
import {Link} from 'react-router-dom';

class LinkCleanUpAdmin extends Component {
    state = {nextId: null};

    componentDidMount() {
        const { id } = this.props.match.params;
        const key = "link_clean_up";
        this.props.requestService(id, key);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const nextId = this.state.nextId;
        const key = "link_clean_up";
        if (nextId && (prevProps.currentService && prevProps.currentService[key] && prevProps.currentService[key].id !== nextId)) {
            this.props.requestService(nextId, key);
        }
    }

    render() {
        const seoService = this.props.currentService ? this.props.currentService["link_clean_up"] : null;
        const orderNumber = seoService ? seoService.order_number : "";

        if (!seoService) {
            return <Spinner/>;
        }
        else if(!seoService.data) {
            return (
                <>
                    <h1>Seo Service not found!</h1>
                </>
            );
        }

        return (
            <>
                <Portlet>
                    <PortletBody>
                        <Breadcrumbs aria-label="Breadcrumb" className="kt-margin-b-15">
                            <Link color="inherit" to="/admin/orders">
                                Orders
                            </Link>
                            <Link color="inherit" to={"/admin/orders/" + seoService.data.order_id + "/progress"}>
                                Order #{orderNumber}
                            </Link>
                            <Typography color="textPrimary">Link Clean-Up #{seoService.data.id}</Typography>
                        </Breadcrumbs>
                        <Typography variant="h5">Link Clean-Up #{seoService.data.id} (Order #{orderNumber})</Typography>
                        <ul className="kt-margin-t-10">
                            <li>Referring Domains: <strong>{seoService.data.domains + seoService.data.extra_domains}</strong></li>
                            {seoService.data.manual_removal === 1 && <li>Manual Action Removal</li>}
                            <li>Submit Disavow File - {parseInt(seoService.data.submit_disavow_file) === 1 ? "Yes" : "No"}</li>
                            {seoService.data.notes && <li><strong>Notes:</strong> {seoService.data.notes}</li>}
                        </ul>
                        <div className="kt-margin-b-30" />

                        <Formik
                            initialValues={{
                                url: seoService.data.url ? seoService.data.url : "",
                                email: seoService.data.email ? seoService.data.email : "",
                                //user_added: seoService.data.user_added ? seoService.user_added.notes : "",
                                done: seoService.data.done,
                            }}
                            validate={values => {
                                const errors = {};

                                /*var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
                                    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // ip (v4) address
                                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ //port
                                    '(\\?[;&amp;a-z\\d%_.~+=-]*)?'+ // query string
                                    '(\\#[-a-z\\d_]*)?$','i');*/

                                if (!values.url/* || !pattern.test(values.url)*/) {
                                    errors.url = "Please provide valid page url.";
                                }

                                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                                    errors.email = "Please provide valid email.";
                                }

                                return errors;
                            }}
                            onSubmit={(values, { setStatus, setSubmitting }) => {
                                let seoServiceData = {
                                    url: values.url,
                                    email: values.email,
                                    done: values.done ? 1 : 0
                                };
                                editLinkCleanUpService(seoService.data.id, seoServiceData)
                                    .then((res) => {
                                        setStatus(
                                            "Saved."
                                        );
                                        seoService.data = res.data;
                                        setSubmitting(false);
                                    })
                                    .catch((e) => {
                                        setSubmitting(false);
                                        let errorMsg = "Error! Data is not updated.";
                                        if(e.response.data) {
                                            if(e.response.data.error) {
                                                errorMsg = e.response.data.error;
                                            }
                                        }
                                        setStatus(
                                            errorMsg
                                        );
                                    });
                            }}
                        >
                            {({
                                  values,
                                  status,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting
                              }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    {status && !isSubmitting && (
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <div role="alert" className={'alert ' + (status === 'Saved.' ? 'alert-success' : 'alert-danger')}>
                                                    <div className="alert-text">{status}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <Typography>Page URL</Typography>
                                            <TextField
                                                type="url"
                                                name="url"
                                                className="kt-margin-b-20"
                                                value={values.url}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                margin="normal"
                                                variant="outlined"
                                                helperText={touched.url && errors.url}
                                                error={Boolean(touched.url && errors.url)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <Typography>Email</Typography>
                                            <TextField
                                                name="email"
                                                className="kt-margin-b-20"
                                                value={values.email}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                margin="normal"
                                                variant="outlined"
                                                helperText={touched.email && errors.email}
                                                error={Boolean(touched.email && errors.email)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <Typography>Done</Typography>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.done}
                                                        onChange={handleChange}
                                                        name="done"
                                                        value={values.done}
                                                        color="primary"
                                                    />
                                                }
                                                label=""
                                            />
                                        </div>
                                    </div>
                                    <Button className="kt-mr-5" type="submit" variant="primary" onClick={handleSubmit} disabled={isSubmitting}>
                                        {!isSubmitting ? 'Submit' : (<Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />)}
                                    </Button>
                                    <Link to={"/admin/orders/" + seoService.data.order_id + "/progress"}>
                                        <Button variant="secondary">Back</Button>
                                    </Link>
                                </Form>
                            )}
                        </Formik>
                    </PortletBody>
                </Portlet>
            </>
        );
    }
}

const mapStateToProps = ({ servicesAdmin: { currentService } }) => ({
    currentService
});

export default injectIntl(connect(
    mapStateToProps,
    services.actions
)(LinkCleanUpAdmin));
