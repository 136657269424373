import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as guestposts from "../../store/ducks/admin/guestPosts.duck";
import {Spinner} from "reactstrap";
import {Portlet, PortletBody} from "../../partials/content/Portlet";
import {Button, Form} from "react-bootstrap";
import { Formik } from "formik";
import {Typography, TextField, Breadcrumbs, FormControlLabel, Checkbox, Radio, RadioGroup} from "@material-ui/core";
import { editGuestPost, editGuestPostStatus, editGuestPostWriter  } from "../../crud/admin/orders.crud";
import {Link} from 'react-router-dom';
import {Icon} from "@material-ui/core";
import {downloadSalePdfInvoice} from "../../crud/admin/orders.crud";
import ActiveUserContentWriterSelect from './ActiveUserContentWriterSelect'



class GuestPostAdmin extends Component {
    state = { nextId: null };

    constructor(props) {
        super(props);

        let sending = false;
        let dynamic_submit_status = '';
        let edit_notes = "";
        let content_writer_id = "";

        this.state = {
            sending: sending,
            dynamic_submit_status: dynamic_submit_status,
            edit_notes: edit_notes,
            content_writer_id: content_writer_id
        };

    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.props.requestGuestPost(id);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const nextId = this.state.nextId;
        if (nextId && (prevProps.currentGuestPost && prevProps.currentGuestPost.id !== nextId)) {
            this.props.requestGuestPost(nextId);
        }
    }

    onContentWriterChange = (newContentWriterId) => {
        this.setState({
            content_writer_id: newContentWriterId
        })
    }

    render() {
        const guestPost = this.props.currentGuestPost;
        const currentGuestPost = guestPost;
        const orderNumber = guestPost ? guestPost.order_number : "";

        if (!guestPost) {
            return <Spinner/>;
        }
        else if(!guestPost.data) {
            return (
                <>
                    <h1>Guest Post not found!</h1>
                </>
            );
        }

        let the_current_page = null;
        const getPage = new URLSearchParams(this.props.location.search).get("page");
        if (getPage != null) {
            the_current_page = getPage;
        }

        let form_holder = ``;

        let order_line_status_radio = undefined;
        const handleRadioStatusChange = (event) => {
            order_line_status_radio = event.target.value;
        };

        if (this.state.content_writer_id == "") {
            this.state.content_writer_id = this.props.currentGuestPost.data.content_writer_user_id;
        }

        const setEditNotes = (edit_notes) => {
            this.setState({
                ...this.state,
                edit_notes: edit_notes
            });
        };

        this.handleWriterStatusAction = (e) => {
            e.preventDefault();
            let new_status = e.target.name;
            this.setState({
                ...this.state,
                sending: true
            });

            let postData = {
                new_status: new_status,
            };

            if (new_status == "edits_requested") {
                postData = {
                    new_status: new_status,
                    edit_notes: this.state.edit_notes,
                };
                if (this.state.edit_notes == "") {
                    this.setState({
                        ...this.state,
                        sending: false,
                        dynamic_submit_status: "Edit notes are required when requesting edits.",
                    });
                    return false;
                }
            } // if (new_status == "edits_requested") {

            editGuestPostWriter(
                guestPost.data.id,
                postData
            )
            .then((res) => {
                let success_message = '';
                if (new_status == "declined") {
                    success_message = "Successfully Declined";
                } else if (new_status == "edits_requested") {
                    success_message = "Successfully Sent Edit Request";
                } else if (new_status == "approved") {
                    // success_message = "Approved, now check done below after posting the guest post to mark completed and send for client approval.";
                    success_message = "Approved, now check done below after posting the guest post to complete the transaction.";
                }
                guestPost.data = res.data;
                this.setState({
                    ...this.state,
                    sending: false,
                    dynamic_submit_status: success_message,
                });
                // this.props.requestGuestPost(guestPost.data.id);
            })
            .catch(e => {
                let error_message = '';
                if(e && e.response && e.response.data) {
                    error_message = e.response.data.message;
                }
                this.setState({
                    ...this.state,
                    sending: false,
                    dynamic_submit_status: error_message
                });
            });

        };





        if (parseInt(currentGuestPost.data.order_line_status) == 0) {
            form_holder = (<Formik
                initialValues={{
                    website: guestPost.data.website ? guestPost.data.website : "",
                    anchor: guestPost.data.anchor ? guestPost.data.anchor : "",
                    build: parseInt(guestPost.data.build) === 1
                }}
                validate={values => {
                    const errors = {};

                    /*var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
                        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // ip (v4) address
                        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ //port
                        '(\\?[;&amp;a-z\\d%_.~+=-]*)?'+ // query string
                        '(\\#[-a-z\\d_]*)?$','i');*/

                    if (!values.website/* || !pattern.test(values.website)*/) {
                        errors.website = "Please provide valid website url.";
                    }

                    return errors;
                }}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    let guestPostData = {
                        website: values.website,
                        anchor: values.anchor,
                        order_line_status: order_line_status_radio,
                        requested_topic: values.requested_topic,
                        // new_content_writer: 
                    };
                    editGuestPostStatus(currentGuestPost.data.id, guestPostData)
                        .then((res) => {
                            setStatus(
                                "Saved."
                            );
                            guestPost.data = res.data;
                            setSubmitting(false);
                        })
                        .catch((e) => {
                            setSubmitting(false);
                            let errorMsg = "You need to select Accept or Reject.";
                            if(e.response.data) {
                                if(e.response.data.error) {
                                    errorMsg = e.response.data.error;
                                }
                            }
                            setStatus(
                                errorMsg
                            );
                        });
                }}
            >
                {({
                      values,
                      status,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        {status && !isSubmitting && (
                            <div className="row">
                                <div className="col-xl-6">
                                    <div role="alert" className={'alert ' + (status === 'Saved.' ? 'alert-success' : 'alert-danger')}>
                                        <div className="alert-text">{status}</div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {this.state.dynamic_submit_status != '' && ! this.state.sending && (
                            <div className="row">
                                <div className="col-xl-6">
                                    <div role="alert" className={'alert ' + ((this.state.dynamic_submit_status.includes('Successfully') || this.state.dynamic_submit_status.includes('Approved')) ? 'alert-success' : 'alert-danger')}>
                                        <div className="alert-text">{this.state.dynamic_submit_status}</div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-xl-6">
                                <Typography>Website URL</Typography>
                                <TextField
                                    type="url"
                                    name="website"
                                    className="kt-margin-b-20"
                                    value={values.website}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    margin="normal"
                                    variant="outlined"
                                    helperText={touched.website && errors.website}
                                    error={Boolean(touched.website && errors.website)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-6">
                                <Typography>Anchor Text</Typography>
                                <TextField
                                    name="anchor"
                                    className="kt-margin-b-20"
                                    value={values.anchor}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-6">
                                <Typography>Request a Topic</Typography>
                                <TextField
                                    name="requested_topic"
                                    className="kt-margin-b-20"
                                    value={values.requested_topic}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-6">
                                 <RadioGroup
                                    name="order_line_status_radio_group">
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                onChange={handleRadioStatusChange}
                                                name="order_line_status_radio"
                                                value="1"
                                                color="primary"
                                            />
                                        }
                                        label="Accept"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                onChange={handleRadioStatusChange}
                                                name="order_line_status_radio"
                                                value="2"
                                                color="primary"
                                            />
                                        }
                                        label="Reject"
                                    />
                                </RadioGroup>
                            </div>
                        </div>
                        <Button className="kt-mr-5" type="submit" variant="primary" onClick={handleSubmit} disabled={isSubmitting}>
                            {!isSubmitting ? 'Submit' : (<Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />)}
                        </Button>
                        {the_current_page != null ?
                        <Link to={"/admin/orders?page="+the_current_page}>
                            <Button variant="secondary">Back</Button>
                        </Link>
                        :
                        <Link to={"/admin/orders"}>
                            <Button variant="secondary">Back</Button>
                        </Link>}
                    </Form>
                )}
            </Formik>);






        } else if (parseInt(currentGuestPost.data.order_line_status) == 1) {
            form_holder = (<Formik
                initialValues={{
                    website: guestPost.data.website ? guestPost.data.website : "",
                    anchor: guestPost.data.anchor ? guestPost.data.anchor : "",
                    build: parseInt(guestPost.data.build) === 1,
                    article: "",
                }}
                validate={values => {
                    const errors = {};

                    /*var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
                        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // ip (v4) address
                        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ //port
                        '(\\?[;&amp;a-z\\d%_.~+=-]*)?'+ // query string
                        '(\\#[-a-z\\d_]*)?$','i');*/

                    if (!values.website/* || !pattern.test(values.website)*/) {
                        errors.website = "Please provide valid website url.";
                    }

                    return errors;
                }}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                    let is_upload = 'no';

                    let postData = {
                        website: values.website,
                        anchor: values.anchor,
                        build: values.build ? 1 : 0
                    };

                    if (this.props.currentGuestPost.data.content_writer_user_id != this.state.content_writer_id) {
                        postData.new_content_writer_user_id = this.state.content_writer_id;
                        postData.guest_post_id = this.props.currentGuestPost.data.id;
                    }

                    let guestPostData = postData;

                    if(values.article) {
                        is_upload = 'yes';
                        guestPostData = new FormData();
                        for (const [key] of Object.entries(postData)) {
                            guestPostData.append(key, postData[key]);
                        }
                        guestPostData.append('is_upload', 'yes');
                        guestPostData.append("article", values.article);
                    }
                    editGuestPost(guestPost.data.id, guestPostData)
                        .then((res) => {
                            setStatus(
                                "Saved."
                            );

                            guestPost.data = res.data;
                            this.setState({ ...this.state, content_writer_id: res.data.guest_post_content_writer.user.id})
                            setSubmitting(false);

                            // // wasn't here before
                            // const { id } = this.props.match.params;
                            // this.props.requestGuestPost(id);
                        })
                        .catch((e) => {
                            setSubmitting(false);
                            let errorMsg = "Error! Data is not updated.";
                            if (is_upload == 'yes') {
                                errorMsg = "The article must be a file of type: doc, docx, txt, pdf, rtf, rtfd.";
                                if(typeof e.message != 'undefined') {
                                    if(typeof e.message.errors != 'undefined') {
                                        if(typeof e.message.errors.article != 'undefined') {
                                            errorMsg = e.message.errors.article;
                                        }
                                    }
                                }
                            } else {
                                if(e.response.data) {
                                    if(e.response.data.error) {
                                        errorMsg = e.response.data.error;
                                    }
                                }
                            }
                            setStatus(
                                errorMsg
                            );
                        });
                }}
            >
                {({
                      values,
                      status,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        {status && !isSubmitting && (
                            <div className="row">
                                <div className="col-xl-6">
                                    <div role="alert" className={'alert ' + (status === 'Saved.' ? 'alert-success' : 'alert-danger')}>
                                        <div className="alert-text">{status}</div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {this.state.dynamic_submit_status != '' && ! this.state.sending && (
                            <div className="row">
                                <div className="col-xl-6">
                                    <div role="alert" className={'alert ' + ((this.state.dynamic_submit_status.includes('Successfully') || this.state.dynamic_submit_status.includes('Approved')) ? 'alert-success' : 'alert-danger')}>
                                        <div className="alert-text">{this.state.dynamic_submit_status}</div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-xl-6">
                                <Typography>Website URL</Typography>
                                <TextField
                                    type="url"
                                    name="website"
                                    className="kt-margin-b-20"
                                    value={values.website}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    margin="normal"
                                    variant="outlined"
                                    helperText={touched.website && errors.website}
                                    error={Boolean(touched.website && errors.website)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-6">
                                <Typography>Anchor Text</Typography>
                                <TextField
                                    name="anchor"
                                    className="kt-margin-b-20"
                                    value={values.anchor}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>
                        </div>
                        { (guestPost.data.guest_post_content_writer_status == 5 || guestPost.data.guest_post_content_writer_status == 7) ?
                            <>
                                <div className="row">
                                    <div className="col-xl-6">
                                        <h5>Review Guest Post Article</h5>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-6">
                                        <Typography>Request Edit Notes</Typography>
                                        <TextField
                                            multiline
                                            rows="2"
                                            onChange={(e) => setEditNotes(e.target.value)}
                                            name="edit_notes"
                                            className="kt-margin-b-20"
                                            margin="normal"
                                            variant="outlined"
                                            defaultValue=""
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-6">
                                        <Button disabled={this.state.sending} className="btn-sm kt-mr-15" name="approved" onClick={(e) => this.handleWriterStatusAction(e)}>Approve Article</Button>
                                        <Button disabled={this.state.sending} className="btn-warning btn-sm" name="edits_requested" onClick={(e) => this.handleWriterStatusAction(e)}>Request Edits</Button>
                                    </div>
                                </div>
                            </>
                        : 
                        <>
                            <div className="row">
                                <div className="col-xl-6">
                                    <Typography>Article</Typography>
                                    <div className="kt-mt-10 kt-mb-15">
                                        {errors.article && <div className="text-danger">
                                            {errors.article}
                                        </div>}
                                        <div>{guestPost.data && guestPost.data.article ? guestPost.data.article : ""}</div>
                                        <input type="file" name="article" onChange={(event) => {setFieldValue("article", event.currentTarget.files[0]);}} />
                                    </div>
                                </div>
                            </div>
                            {/*<div className="row">
                                <div className="col-xl-6">
                                    <Typography>Link to Guest Post</Typography>
                                    <TextField
                                        type="url"
                                        name="article_link"
                                        className="kt-margin-b-20"
                                        value={values.article_link}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        margin="normal"
                                        variant="outlined"
                                        helperText={touched.article_link && errors.article_link}
                                        error={Boolean(touched.article_link && errors.article_link)}
                                    />
                                </div>
                            </div>*/}
                            <div className="row">
                                <div className="col-xl-6">
                                    {/*<Typography>Mark as Completed for Client Approval</Typography>*/}
                                    <Typography>Done</Typography>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.build}
                                                onChange={handleChange}
                                                name="build"
                                                value={values.build}
                                                color="primary"
                                            />
                                        }
                                        label=""
                                    />
                                </div>
                            </div>
                            <Button className="kt-mr-5" type="submit" variant="primary" onClick={handleSubmit} disabled={isSubmitting}>
                                {!isSubmitting ? 'Submit' : (<Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />)}
                            </Button>
                            {the_current_page != null ?
                            <Link to={"/admin/orders?page="+the_current_page}>
                                <Button variant="secondary">Back</Button>
                            </Link>
                            :
                            <Link to={"/admin/orders"}>
                                <Button variant="secondary">Back</Button>
                            </Link>}
                        </>}
                        {guestPost.data.build == 1 ?
                            <div className="row">
                                <div className="col-xl-6">
                                    <p>Guest Post Complete.</p>
                                </div>
                            </div>
                        : ""}
                    </Form>
                )}
            </Formik>);
        }


        /* START : generate invoice link */
//         let statusVariant = "secondary";
//         let statusLabel = "";
// console.log(guestPost.data)
//         if (guestPost.data.url == "") {
//             statusLabel = "Pending Order Details";
//         } else if (guestPost.data.url != "" && guestPost.data.order_line_status == 0) {
//             statusVariant = "primary";
//             statusLabel = "Waiting for Approval";

//         } else if (guestPost.data.url != "" && guestPost.data.order_line_status == 2) {
//             statusVariant = "danger";
//             statusLabel = "Rejected";

//         } else if (guestPost.data.url != "" && guestPost.data.order_line_status == 1) {
//             statusVariant = "primary";
//             statusLabel = "Accepted";

//         } else if (guestPost.data.url != "" && guestPost.data.order_line_status == 3) {
//             statusVariant = "success";
//             statusLabel = "Complete";
//         }

        /* END : generate invoice link */


        const addOnRows = [];
        if (guestPost != null && guestPost.data != null && guestPost.data.addOns != null) {
            if (guestPost.data.addOns.length > 0) {
                for(let i = 0; i < guestPost.data.addOns.length; i++) {
                    let addOnItem = guestPost.data.addOns[i];
                    addOnRows.push(
                        <li>
                            {addOnItem.section_name} - {addOnItem.add_on_name}
                        </li>
                    );
                }
            }
        }

        return (
            <>
                <Portlet>
                    <PortletBody>
                        <Breadcrumbs aria-label="Breadcrumb" className="kt-margin-b-15">

                            {the_current_page != null ?
                            <Link color="inherit" to={"/admin/orders?page="+the_current_page}>
                                Sales
                            </Link>
                            :
                            <Link color="inherit" to={"/admin/orders"}>
                                Sales
                            </Link>}

                            {the_current_page != null ?
                            <Link color="inherit" to={"/admin/orders/"+guestPost.data.order_id+"/guest-posts?page="+the_current_page}>
                                Sale #{guestPost.data.order_line_number}
                            </Link>
                            :
                            <Link color="inherit" to={"/admin/orders/"+guestPost.data.order_id+"/guest-posts"}>
                                Sale #{guestPost.data.order_line_number}
                            </Link>}

                            <Typography color="textPrimary">{/*guestPost.data.language || guestPost.data.tld ? 'Foreign ' : ''*/}Guest Post #{guestPost.data.id}</Typography>
                        </Breadcrumbs>
                        <Typography variant="h5">{/*guestPost.data.language || guestPost.data.tld ? 'Foreign ' : ''*/}Guest Post #{guestPost.data.id} (Sale #{guestPost.data.order_line_number})</Typography>
                        <ul className="kt-margin-t-10 kt-margin-b-30">
                            <li className={guestPost.data.url ? "" : "d-none"}>User Website: <strong>{guestPost.data.url}</strong></li>
                            <li>DA: <strong>{guestPost.data.da}</strong></li>
                            <li className={guestPost.data.word_count > 0 ? "" : "d-none"}>Word Count: <strong>{guestPost.data.word_count}</strong></li>

                            <li className={guestPost.data.language ? "" : "d-none"}>Language: <strong>{guestPost.data.language}</strong></li>
                            <li className={guestPost.data.tld ? "" : "d-none"}>TLD: <strong>{guestPost.data.tld}</strong></li>

                            <li>Organic Traffic: <strong>{guestPost.data.organic_traffic} visitors</strong></li>
                            { guestPost.data.guest_post_content_writer ? 
                                <>
                                <li>Content Writer Info
                                    <ul>
                                        <li>Name: <strong>{guestPost.data.guest_post_content_writer.user.firstname + " " + guestPost.data.guest_post_content_writer.user.lastname}</strong></li>
                                        <li>Email: {guestPost.data.guest_post_content_writer.user.email}</li>
                                        <li>Rate: ${guestPost.data.guest_post_content_writer.content_price}</li>
                                    </ul>
                                </li>
                                </>
                            : null}
                            { guestPost.data.guest_post_content_writer_status == 0 ? 
                                <li>Change Content Writer:<ActiveUserContentWriterSelect handleContentWriterChange={this.onContentWriterChange} currentContentWriterId={this.state.content_writer_id} /></li>
                            : null }
                            { guestPost.data.guest_post_content_writer_status == 8 ?
                            <li className="datatable-cell" style={{width:"50%"}}>Content Writer Invoice: <span>
                                <Link to={"/admin/orders/" + guestPost.data.order_line_number + "/salesinvoice"}><Icon className="fas fa-eye" style={{fontSize: "11px", width: "auto"}} /> View</Link>
                            </span></li>
                            : null }
                            <li className={guestPost.data.add_dfy_anchor_text ? "" : "d-none"}>Add DFY Anchor Text: <strong>{guestPost.data.add_dfy_anchor_text ? "✔": "no"}</strong></li>
                            <li className={guestPost.data.blog_approval ? "" : "d-none"}>Blog Approval: <strong>{guestPost.data.blog_approval ? "✔": "no"}</strong></li>
                            <li className={guestPost.data.advanced_niche ? "" : "d-none"}>Advanced Niche: <strong>{guestPost.data.advanced_niche ? "✔": "no"}</strong></li>
                            {guestPost.data.article ? <li>Article Link: <a href={guestPost.data.article} target="_blank">{guestPost.data.article}</a></li> : ""}
                            <li className={guestPost.data.notes ? "" : "d-none"}>Notes: {guestPost.data.notes}</li>
                            {guestPost.data.requested_topic ? <li><u>Requested Topic:</u> {guestPost.data.requested_topic}</li> : ""}
                            {addOnRows.length > 0 ? 
                                <ul><li style={{fontWeight:"bold"}}>Add Ons:</li>{addOnRows}</ul> : ''}
                        </ul>
                        {form_holder}
                    </PortletBody>
                </Portlet>
            </>
        );
    }
}

const mapStateToProps = ({ guestpostsAdmin: { currentGuestPost } }) => ({
    currentGuestPost
});

export default injectIntl(connect(
    mapStateToProps,
    guestposts.actions
)(GuestPostAdmin));
