import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as services from "../../store/ducks/admin/services.duck";
import {Spinner} from "reactstrap";
import {Portlet, PortletBody} from "../../partials/content/Portlet";
import {Button, Form} from "react-bootstrap";
import { Formik } from "formik";
import {Typography, TextField, Breadcrumbs, FormControlLabel, Checkbox} from "@material-ui/core";
import {editGPBService, editPressReleaseService} from "../../crud/admin/orders.crud";
import {Link} from 'react-router-dom';
import {displayError} from "../../widgets/AlertError";
import MenuItemText from "../../../_metronic/layout/aside/MenuItemText";

class ServicePageAdmin extends Component {
    state = { nextId: null };

    componentDidMount() {
        const { service, id } = this.props.match.params;
        this.props.requestService(id, service);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { service } = this.props.match.params;
        const nextId = this.state.nextId;

        if (nextId && (prevProps.currentService && prevProps.currentService[service] && prevProps.currentService[service].data && prevProps.currentService[service].data.id !== nextId)) {
            this.props.requestService(nextId, service);
        }
    }

    render() {
        const { service } = this.props.match.params;
        const seoService = this.props.currentService ? this.props.currentService[service] : null;
        const orderNumber = seoService ? seoService.order_number : "";

        if (!seoService) {
            return <Spinner/>;
        } else if(!seoService.data) {
            return (
                <>
                    <h1>Service not found!</h1>
                </>
            );
        }

        const pageName = (() => {
            switch (service) {
                case 'gpb':
                    return 'GPB Optimization';
                case 'press-release':
                    return 'Press Release';
                default:
                    return 'Service Page';
            }
        })();

        const editFunction = (() => {
            // eslint-disable-next-line default-case
            switch (service) {
                case 'gpb':
                    return editGPBService;
                case 'press-release':
                    return editPressReleaseService;
            }
        })();

        return (
            <>
                <Portlet>
                    <PortletBody>
                        <Breadcrumbs aria-label="Breadcrumb" className="kt-margin-b-15">
                            <Link color="inherit" to="/admin/orders">
                                Orders
                            </Link>
                            <Link color="inherit" to={"/admin/orders/" + seoService.data.order_id + "/progress"}>
                                Order #{orderNumber}
                            </Link>
                            <Link color="inherit" to={"/admin/orders/" + seoService.data.order_id + "/service-page/" + service}>
                                {pageName}
                            </Link>
                            <Typography color="textPrimary">#{seoService.data.id}</Typography>
                        </Breadcrumbs>

                        <Typography variant="h5">{pageName} #{seoService.data.id} (Order #{orderNumber})</Typography>
                        <ul className="kt-margin-t-10 kt-margin-b-30">
                            <li className={seoService.data.package ? "" : "d-none"}>Package: <strong>{seoService.data?.package.charAt(0).toUpperCase() + seoService.data?.package.slice(1)}</strong></li>
                        </ul>

                        <Formik
                            initialValues={{
                                done: seoService.data.done,
                                url: seoService.data.url ? seoService.data.url : "",
                                targeting_location: seoService.data.targeting_location ? seoService.data.targeting_location : "",
                                company_description: seoService.data.company_description ? seoService.data.company_description : "",
                                main_products: seoService.data.main_products ? seoService.data.main_products : "",
                                areas: seoService.data.areas ? seoService.data.areas : "",
                                owner_name: seoService.data.owner_name ? seoService.data.owner_name : "",
                                nap: seoService.data.nap ? seoService.data.nap : "",
                                company_email: seoService.data.company_email ? seoService.data.company_email : "",
                                company_hours: seoService.data.company_hours ? seoService.data.company_hours : "",
                                logo_url: seoService.data.logo_url ? seoService.data.logo_url : "",
                                image:  seoService.data?.image_urls?.[0] ? seoService.data.image_urls[0] : "",
                                image2: seoService.data?.image_urls?.[1] ? seoService.data.image_urls[1] : "",
                                image3: seoService.data?.image_urls?.[2] ? seoService.data.image_urls[2] : "",
                                image4: seoService.data?.image_urls?.[3] ? seoService.data.image_urls[3] : "",
                                image5: seoService.data?.image_urls?.[4] ? seoService.data.image_urls[4] : "",
                                youtube_url: seoService.data.youtube_url ? seoService.data.youtube_url : "",
                                file_url: "",
                            }}
                            validate={values => {
                                const errors = {};
                                const requiredField = [
                                    'url',
                                    'targeting_location',
                                    'nap',
                                    'company_description',
                                    'main_products',
                                    'areas',
                                    'owner_name',
                                    'image',
                                    'company_email',
                                    'company_hours',
                                    'logo_url',
                                    'youtube_url',
                                ];

                                requiredField.forEach(field => {
                                    if (!values[field]) {
                                        errors[field] = "This field is required.";
                                    }
                                });

                                return errors;
                            }}
                            onSubmit={(values, { setStatus, setSubmitting }) => {
                                let editData = {
                                    done: values.done ? 1 : 0,
                                    url: values.url,
                                    targeting_location: values.targeting_location,
                                    company_description: values.company_description,
                                    main_products: values.main_products,
                                    areas: values.areas,
                                    owner_name: values.owner_name,
                                    nap: values.nap,
                                    image: values.image,
                                    image2: values.image2,
                                    image3: values.image3,
                                    image4: values.image4,
                                    image5: values.image5,
                                    company_email: values.company_email,
                                    company_hours: values.company_hours,
                                    logo_url: values.logo_url,
                                    youtube_url: values.youtube_url,
                                };

                                let formData = new FormData();

                                for (let key in editData) {
                                    if (editData.hasOwnProperty(key) && editData[key] !== undefined) {
                                        formData.append(key, editData[key]);
                                    }
                                }

                                if(values.file_url) {
                                    formData.append("file_url", values.file_url);
                                }

                                formData.append("id", seoService.data.id);

                                editFunction(seoService.data.id, formData)
                                    .then((res) => {
                                        setStatus(
                                            "Saved."
                                        );
                                        seoService.data = res.data;
                                        setSubmitting(false);
                                        if(res.data.nextId > 0)
                                        {
                                            this.setState({ nextId: res.data.nextId });
                                        }
                                        else
                                        {
                                            if(seoService.data.order_id > 0) {
                                                this.props.history.push({
                                                    pathname: "/orders/" + seoService.data.order_id + "/progress"
                                                });
                                            }
                                        }
                                    })
                                    .catch((e) => {
                                        setSubmitting(false);
                                        let errorMsg = "Error! Data is not updated.";

                                        if(e.response.data && e.response.data.error) {
                                            errorMsg = e.response.data.error;
                                        }

                                        if(e.response && e.response.data && e.response.data.errors) {
                                            for (const key in e.response.data.errors) {
                                                if (Array.isArray(e.response.data.errors[key]) && e.response.data.errors[key].length > 0) {
                                                    errorMsg = e.response.data.errors[key][0];
                                                    break;
                                                }
                                            }
                                        }

                                        displayError(errorMsg);
                                    });
                            }}
                        >
                            {({
                                  values,
                                  status,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue
                              }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    {status && !isSubmitting && (
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <div role="alert"
                                                     className={'alert ' + (status === 'Saved.' ? 'alert-success' : 'alert-danger')}>
                                                    <div className="alert-text">{status}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <Typography>Website URL</Typography>
                                            <TextField
                                                type="url"
                                                name="url"
                                                className="kt-margin-b-20"
                                                value={values.url}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                margin="normal"
                                                variant="outlined"
                                                helperText={touched.url && errors.url}
                                                error={Boolean(touched.url && errors.url)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <Typography>Targeting Location</Typography>
                                            <TextField
                                                name="targeting_location"
                                                className="kt-margin-b-20"
                                                value={values.targeting_location}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                margin="normal"
                                                variant="outlined"
                                                helperText={touched.targeting_location && errors.targeting_location}
                                                error={Boolean(touched.targeting_location && errors.targeting_location)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <Typography>Mention Your NAP</Typography>
                                            <TextField
                                                name="nap"
                                                className="kt-margin-b-20"
                                                value={values.nap}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                margin="normal"
                                                variant="outlined"
                                                helperText={touched.nap && errors.nap}
                                                error={Boolean(touched.nap && errors.nap)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <Typography>Company Description</Typography>
                                            <TextField
                                                multiline
                                                rows="3"
                                                name="company_description"
                                                className="kt-margin-b-20"
                                                value={values.company_description}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                margin="normal"
                                                variant="outlined"
                                                helperText={touched.company_description && errors.company_description}
                                                error={Boolean(touched.company_description && errors.company_description)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <Typography>Main Products or Service Offered</Typography>
                                            <TextField
                                                multiline
                                                rows="3"
                                                name="main_products"
                                                className="kt-margin-b-20"
                                                value={values.main_products}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                margin="normal"
                                                variant="outlined"
                                                helperText={touched.main_products && errors.main_products}
                                                error={Boolean(touched.main_products && errors.main_products)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <Typography>Areas</Typography>
                                            <TextField
                                                multiline
                                                rows="3"
                                                name="areas"
                                                className="kt-margin-b-20"
                                                value={values.areas}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                margin="normal"
                                                variant="outlined"
                                                helperText={touched.areas && errors.areas}
                                                error={Boolean(touched.areas && errors.areas)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <Typography>Owner Name</Typography>
                                            <TextField
                                                name="owner_name"
                                                className="kt-margin-b-20"
                                                value={values.owner_name}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                margin="normal"
                                                variant="outlined"
                                                helperText={touched.owner_name && errors.owner_name}
                                                error={Boolean(touched.owner_name && errors.owner_name)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <Typography>Company Email</Typography>
                                            <TextField
                                                type="email"
                                                name="company_email"
                                                className="kt-margin-b-20"
                                                value={values.company_email}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                margin="normal"
                                                variant="outlined"
                                                helperText={touched.company_email && errors.company_email}
                                                error={Boolean(touched.company_email && errors.company_email)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <Typography>Company Hours</Typography>
                                            <TextField
                                                name="company_hours"
                                                className="kt-margin-b-20"
                                                value={values.company_hours}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                margin="normal"
                                                variant="outlined"
                                                helperText={touched.company_hours && errors.company_hours}
                                                error={Boolean(touched.company_hours && errors.company_hours)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <Typography>Logo Url</Typography>
                                            <TextField
                                                name="logo_url"
                                                className="kt-margin-b-20"
                                                value={values.logo_url}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                margin="normal"
                                                variant="outlined"
                                                helperText={touched.logo_url && errors.logo_url}
                                                error={Boolean(touched.logo_url && errors.logo_url)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-border-group kt-margin-b-20 col-xl-6">
                                            <h4>Please provide up to 5 image Url</h4>
                                            <div className="col-xl-12">
                                                <Typography>Image URL</Typography>
                                                <TextField
                                                    name="image"
                                                    className="kt-margin-b-20"
                                                    value={values.image}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    margin="normal"
                                                    variant="outlined"
                                                    helperText={touched.image && errors.image}
                                                    error={Boolean(touched.image && errors.image)}
                                                />
                                            </div>
                                            <div className="col-xl-12">
                                                <Typography>Image URL (Optional)</Typography>
                                                <TextField
                                                    name="image2"
                                                    className="kt-margin-b-20"
                                                    value={values.image2}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </div>
                                            <div className="col-xl-12">
                                                <Typography>Image URL (Optional)</Typography>
                                                <TextField
                                                    name="image3"
                                                    className="kt-margin-b-20"
                                                    value={values.image3}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </div>
                                            <div className="col-xl-12">
                                                <Typography>Image URL (Optional)</Typography>
                                                <TextField
                                                    name="image4"
                                                    className="kt-margin-b-20"
                                                    value={values.image4}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </div>
                                            <div className="col-xl-12">
                                                <Typography>Image URL (Optional)</Typography>
                                                <TextField
                                                    name="image5"
                                                    className="kt-margin-b-20"
                                                    value={values.image5}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    margin="normal"
                                                    variant="outlined"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <Typography>Youtube Url</Typography>
                                            <TextField
                                                name="youtube_url"
                                                className="kt-margin-b-20"
                                                value={values.youtube_url}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                margin="normal"
                                                variant="outlined"
                                                helperText={touched.youtube_url && errors.youtube_url}
                                                error={Boolean(touched.youtube_url && errors.youtube_url)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <Typography>Attach File {seoService.data && seoService.data.file_url &&
                                                <><span> - </span><Link to={{ pathname: seoService.data.file_url }} target="_blank">
                                                Link to attached file
                                                </Link></>}</Typography>
                                            <div className="kt-mt-10 kt-mb-15">
                                                <input type="file" name="file_url" onChange={(event) => {
                                                    setFieldValue("file_url", event.currentTarget.files[0]);
                                                }}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <Typography>Done</Typography>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.done}
                                                        onChange={handleChange}
                                                        name="done"
                                                        value={values.done}
                                                        color="primary"
                                                    />
                                                }
                                                label=""
                                            />
                                        </div>
                                    </div>
                                    {(!isSubmitting && seoService.data.url && seoService.data.nextId)
                                        ? (<Button className="kt-mr-5" variant="primary" onClick={() => {
                                            this.setState({nextId: seoService.data.nextId});
                                        }}>Next</Button>)
                                        : (<Button className="kt-mr-5" type="submit" variant="primary"
                                                   onClick={handleSubmit}
                                                   disabled={isSubmitting}>Submit</Button>)
                                    }
                                    <Link to={"/admin/orders/" + seoService.data.order_id + "/service-page/" + service}>
                                        <Button variant="secondary">Back</Button>
                                    </Link>
                                </Form>
                            )}
                        </Formik>
                    </PortletBody>
                </Portlet>
            </>
        );
    }
}

const mapStateToProps = ({servicesAdmin: {currentService}}) => ({
    currentService
});

export default injectIntl(connect(
    mapStateToProps,
    {...services.actions}
)(ServicePageAdmin));
