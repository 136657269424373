import axios from "axios";

export const base = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL : "";
export const API_MARKETPLACE_OFFER_URL = base + "/api/offer/make";
export const API_GET_OFFER_URL = base + "/api/offer/getMany";
export const API_OFFER_URL = base + "/api/offer/getOne";

export function makeOffer(guestPostId, userOffer) {
    return axios.post(API_MARKETPLACE_OFFER_URL, { guestPostId, userOffer });
}

export function getOffers(page = null) {
    return axios.get(API_GET_OFFER_URL + (page > 1 ? "?page=" + page : ""));
}

export function getOffer(offer_id) {
    return axios.post(API_OFFER_URL, { offer_id });
}