import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as analytics from "../../../../store/ducks/analytics.duck";
import {Spinner} from "reactstrap";
import {Table} from "react-bootstrap";
import UPagination from "../../../../partials/content/Pagination";

class Pages extends Component {
    render() {
        const type = "pages";
        const {currentProjectId, siteData} = this.props;

        if(!siteData) {
            return <Spinner />;
        }
        if(siteData && !siteData.data) {
            return <p>Data not found.</p>;
        }

        const pageRows = [];
        if(siteData.data[type] && siteData.data[type].data && siteData.data[type].data.length !== 0) {
            for (const [index] of siteData.data[type].data.entries())
            {
                pageRows.push(<tr key={type + index}>
                    <td>{siteData.data[type].data[index].date}</td>
                    <td>{siteData.data[type].data[index].url && siteData.data[type].data[index].url.indexOf("http") !== -1 ? <a href={siteData.data[type].data[index].url} target="_blank">{siteData.data[type].data[index].url}</a> : siteData.data[type].data[index].url}</td>
                    <td>{siteData.data[type].data[index].value}</td>
                    <td>{siteData.data[type].data[index].value_percent}</td>
                    <td>{siteData.data[type].data[index].title ? siteData.data[type].data[index].title.replaceAll('&amp;', '&') : ""}</td>
                </tr>);
            }
        }

        const handleNextPage = (activePage) => {
            this.props.requestSiteData(currentProjectId, type, activePage);
        };

        return (
            <>
                <Table hover responsive size="sm" className="table">
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Url</th>
                        <th>Visitors</th>
                        <th>%</th>
                        <th>Title</th>
                    </tr>
                    </thead>
                    <tbody>
                        {pageRows}
                    </tbody>
                </Table>
                {(siteData.data[type] && siteData.data[type].last_page > 1) && <UPagination currentPage={siteData.data[type].current_page} totalPages={siteData.data[type].last_page} onChange={handleNextPage} />}
            </>
        );

    }
}

const mapStateToProps = ({ analytics: { siteData }, projects: {currentProjectId} }) => ({
    siteData, currentProjectId
});

export default injectIntl(connect(
    mapStateToProps,
    analytics.actions
)(Pages));
