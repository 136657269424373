import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Button} from "react-bootstrap";
import * as projects from "../../../store/ducks/projects.duck";
import * as marketplace from "../../../store/ducks/marketplace.duck";

class TextInfoModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            //
        };
    }

    componentDidMount() {
        //
  	}

    render() {
        const { open, onClose, packageType, description } = this.props;

        const handleClose = () => {
            onClose();
        };

        return (<Dialog open={open} onClose={handleClose} fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle style={{textAlign:'center'}}>{packageType}</DialogTitle>
            <DialogContent dividers style={{borderTop: '0', paddingTop: '4px'}}>
                <div dangerouslySetInnerHTML={{__html: description}}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>);
    }
}

TextInfoModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    packageType: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};
export default TextInfoModal;
