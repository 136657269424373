import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import { Form, Button, ButtonToolbar, Alert } from "react-bootstrap";
import {Spinner} from "reactstrap";
import ReactGA from 'react-ga4';
import {addOrder, calculateVat, stripePay, validateVat, addWithdrawRequest} from "../../../crud/orders.crud";
import { Formik } from "formik";
import * as marketplace from "../../../store/ducks/marketplace.duck";
import { PayPalButton } from "react-paypal-button-v2";
import {loadStripe} from '@stripe/stripe-js';
import {CardElement, Elements, ElementsConsumer} from "@stripe/react-stripe-js";
import {Link} from "react-router-dom";

const stripePromise = loadStripe(window.stripePublicKey ? window.stripePublicKey : 'pk_live_nO3uxbbzQCnrcERWxIS5i43t');

class WithdrawBalanceModal extends React.Component {
    constructor(props) {
        super(props);

        let withdrawAmount = 0.00;
        let sending = false;

        this.state = {
            sending: sending,
            withdrawAmount: withdrawAmount,
            showPaymentBtn: false,
            payWithStripe: false,
            payWithPaypal: false,
            paypalBtnReady: false,
            paypalProcessing: false,
            payment_method: "",
        };
     	this.initialValues = null;
        this.paypalProcessing = React.createRef();
        this.paypalButtonToolbar = React.createRef();
    }

    render() {
        const {open, onClose, withdrawAmount, formikSubmit, setFieldValue, intl, withdrawableBalance, paypalEmail} = this.props;

        // if(!this.initialValues) {
        //     this.initialValues = {
        //         payment_method: "",
        //         payment_order_id: "",
        //     }
        // }

        const handleClose = () => {
        	setWithdrawAmount(0);
            onClose();
        };

        const setWithdrawAmount = (amount) => {
        	this.setState({
                ...this.state,
        		withdrawAmount: amount
        	});
        };

        const handleSubmitTop = async () => {

            console.log("TOP TOP handleSubmit......");

            this.setState({
                ...this.state,
        		isSubmitting: true
        	});

            addWithdrawRequest(this.state.withdrawAmount)
                .then((res) => {
                	alert("Withdraw Request Successful.");
                	window.location.reload();
              	})
                .catch(e => {
                	this.setState({
		                ...this.state,
		        		isSubmitting: false
		        	});
                	let errorMessage = "";
                    if (e.response) {
                        alert(e.response.data.message);
                        errorMessage = e.response.data.message;
                    } else {
                        alert("Error! Try again later.");
                        errorMessage = "Something went wrong. Please contact support or refresh and try again.";
                    }
            });
        }

        return (<Dialog open={open} onClose={handleClose} fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle>Withdraw</DialogTitle>
            <DialogContent dividers>
            	<Formik
                    validate={async values => {
                        const errors = {};
                        if (! this.state.withdrawAmount || this.state.withdrawAmount < 50) {
                            errors.errorMessage = "Minimum withdraw is $50.";
                        } else if (withdrawableBalance < this.state.withdrawAmount) {
                            errors.errorMessage = "You are trying to withdraw more than is available.";
                        } else if (! paypalEmail || paypalEmail == "") {
                        	errors.errorMessage = "You need to add a paypal email in order to withdraw.";
                        }
                       return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                    	
		         	}}
                        >
                    {({
                          values,
                          status,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue,
                          validateForm
                      }) => (
                  		<Form noValidate onSubmit={handleSubmit}>
                  			<Form.Group className="col-md-12 kt-mb-10" controlId="formDepositAmount">
                  				{! paypalEmail || paypalEmail == "" ?
                  					<Alert variant="danger" className="d-block">
		                            	<div className="d-block">You need to add a paypal email in order to withdraw.</div>
		                            	<a onClick={handleClose} eventkey="billing" className="mt-2 d-block btn btn-brand" href="/profile#billing">Add Paypal Email</a>
									</Alert>
									: ""}
                  				<div className="input-group mb-2 d-block">
                  					<label>Withdrawable Balance</label>
                  					<div style={{float:"right"}}>
                  						${ withdrawableBalance ?
                  							withdrawableBalance.toFixed(2) : ""}
              						</div>
              					</div>
	                          	<div className="input-group mb-3">
								  	<div className="input-group-prepend">
								    	<span className="input-group-text" id="basic-addon1">$</span>
								  	</div>
						            <Form.Control
						                autoFocus
						                onChange={(e) => setWithdrawAmount(e.target.value)}
						                placeholder="Withdraw amount"
						                type="number"
						                step="0.01"
						                className="form-control"
						                name="withdrawAmount"
						                isInvalid={!!errors.errorMessage}
						                disabled={isSubmitting}
						               	/>
						            <Form.Control.Feedback type="invalid">
	                                    {errors.errorMessage}
	                                </Form.Control.Feedback>
					            </div>
					        </Form.Group>

					        <ButtonToolbar className="mt-2">
                                <Button onClick={handleClose} color="primary">
                                    Close
                                </Button>
    				        		<Button className="ml-auto" variant="primary" onClick={() => validateForm().then((errors) => {
    				        			if (typeof errors.errorMessage == 'undefined') {
    				        				handleSubmitTop();
    				        			}
    	                            })} disabled={isSubmitting}>
                                    	Withdraw
                                	</Button>
                            </ButtonToolbar>

                            <div className="justify-content-center text-center" style={{display:"none"}} ref={this.paypalProcessing}>
                                <p className="text-bold">Payment is processing, please wait...</p>
                                <Spinner size="lg" />
                            </div>
			        	</Form>   	
                    )}
                </Formik>
            </DialogContent>
        </Dialog>);

    }
}

WithdrawBalanceModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ marketplace: { filter, guestPosts } }) => ({
    filter, guestPosts
});

export default injectIntl(connect(
    mapStateToProps,
    marketplace.actions,
)(WithdrawBalanceModal));
