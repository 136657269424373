import React from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import * as auth from "../../../store/ducks/auth.duck";
import * as projects from "../../../store/ducks/projects.duck";
import { registerBlogger } from "../../../crud/auth.crud";
import Select from "react-select";
import {Form} from "react-bootstrap";

import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

class Registration extends React.Component {

  constructor(props) {
        super(props);
  }

  componentDidMount() {
    const {projects} = this.props;
    this.props.requestProjectCategories();
  }

  render() {
    const { intl, auth, projectCategories } = this.props;

    const selectStyles = {
        menu: styles => ({ ...styles, zIndex: 999 }),
        control: (provided, state) => ({
            ...provided,
            minHeight: '47px',
        }),
    };

    const categoryOptions = [];
    let selectedCategory = 0;

    const handleCategoryChange = (value) => {
        selectedCategory = value.value;
    };

    if (typeof projectCategories !== 'undefined') {
      if (projectCategories.data && projectCategories.data.length !== 0) {
        for (let index = 0; index < projectCategories.data.length; index++) {
            let item = {value: projectCategories.data[index].id, label: projectCategories.data[index].name};
            categoryOptions.push(item);
        }
      }
    }

    //
    const solutionsOptions = [];
    let selectedSolution = '';

    let item1 = {value: 'Buy links for my website', label: 'Buy links for my website'};
    solutionsOptions.push(item1);
    
    let item2 = {value: 'Buy links for my clients', label: 'Buy links for my clients'};
    solutionsOptions.push(item2);

    let item3 = {value: 'Buy aged domains', label: 'Buy aged domains'};
    solutionsOptions.push(item3);

    let item4 = {value: 'Sell links on my blog', label: 'Sell links on my blog'};
    solutionsOptions.push(item4);

    let item5 = {value: 'Scale my SEO agency', label: 'Scale my SEO agency'};
    solutionsOptions.push(item5);

    const handleSolutionsChange = (value) => {
        selectedSolution = value.value;
    };
    //

    return (
      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>
              <FormattedMessage id="AUTH.REGISTER.BLOGGER_TITLE" />
            </h3>
            <p>Not a blogger? <Link to="/auth/registration" className="kt-link">Sign up here!</Link></p>
          </div>

          <Formik
            initialValues={{
              email: "",
              firstname: "",
              lastname: "",
              password: "",
              company: "",
              paypal_email: "",
              //phone: "",
              reason: "",
              blog_url: "",
              category_id: "",
              blog_price: "",
              acceptTerms: true,
              confirmPassword: "",
              solutions_looking_for: "",
              how_did_you_hear_about_us: ""
            }}
            validate={values => {
              const errors = {};

              if (!values.email) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_FIELD"
                });
              }

               if (!values.paypal_email) {
                errors.paypal_email = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.paypal_email)
              ) {
                errors.paypal_email = intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_FIELD"
                });
              }

              if (!values.firstname) {
                errors.firstname = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }

              if (!values.lastname) {
                errors.lastname = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }

              if (!values.blog_url) {
                errors.blog_url = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }

              if (!values.blog_price) {
                errors.blog_price = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }

              if (!selectedCategory || selectedCategory == 0) {
                errors.category_id = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }

              if (!values.password) {
                errors.password = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }
              else if(values.password.length < 6) {
                errors.password = "Password must be at least 6 characters.";
              }
              else if(!/[a-zA-z]/.test(values.password)) {
                  errors.password = "Password must contain at least one letter.";
              }
              else if(!/[0-9]/.test(values.password)) {
                  errors.password = "Password must contain at least one digit.";
              }

              // if(values.phone && values.phone.trim().length < 5) {
              //     errors.phone = "Too short phone number";
              // }
              // if(values.phone && values.phone.trim().length >= 20) {
              //     errors.phone = "Too long phone number";
              // }
              // if(values.phone && /[a-zA-Z]/.test(values.phone)) {
              //     errors.phone = "Incorrect phone number format";
              // }

              // if (!values.confirmPassword) {
              //   errors.confirmPassword = intl.formatMessage({
              //     id: "AUTH.VALIDATION.REQUIRED_FIELD"
              //   });
              // } else if (values.password !== values.confirmPassword) {
              //   errors.confirmPassword =
              //     "Password and Confirm Password didn't match.";
              // }

              if (!values.acceptTerms) {
                errors.acceptTerms = "Accept Terms";
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              registerBlogger(
                values.email,
                values.firstname,
                values.lastname,
                values.password,
                values.confirmPassword,
                values.company,
                values.paypal_email,
                values.reason,
                values.blog_url,
                values.blog_price,
                selectedCategory,
                selectedSolution,
                values.how_did_you_hear_about_us,
              )
                .then(({ data: { access_token } }) => {

                  ReactGA.event({
                      category: 'User',
                      action: 'sign_up',
                      label: 'New Sign Up',
                      nonInteraction: false
                  });
                  ReactPixel.track("sign_up", {value: values.email});
                  // ReactPixel.track("sign_up", values.email);
                  // ReactPixel.track("Lead");

                  this.props.register(access_token);
                })
                .catch(e => {
                  setSubmitting(false);
                  let str = '';
                  let obj = e.response.data.errors;
                  for (var p in obj) {
                    if (obj.hasOwnProperty(p)) {
                      str += obj[p] + '\n';
                    }
                  }
                  setStatus(
                    str
                  );
                });
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}

                <div className="row">
                    <div className="col-sm-6 col-12 form-group mb-0">
                      <TextField
                        margin="normal"
                        label="First Name"
                        className="kt-width-full"
                        name="firstname"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstname}
                        helperText={touched.firstname && errors.firstname}
                        error={Boolean(touched.firstname && errors.firstname)}
                      />
                    </div>

                    <div className="col-sm-6 col-12 form-group mb-0">
                      <TextField
                        margin="normal"
                        label="Last Name"
                        className="kt-width-full"
                        name="lastname"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastname}
                        helperText={touched.lastname && errors.lastname}
                        error={Boolean(touched.lastname && errors.lastname)}
                      />
                    </div>
                </div>

                <div className="form-group mb-0">
                  <TextField
                    label="Email"
                    margin="normal"
                    className="kt-width-full"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                  />
                </div>

                <div className="form-group mb-0">
                  <TextField
                    margin="normal"
                    label="Company Name"
                    className="kt-width-full"
                    name="company"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.company}
                    helperText={touched.company && errors.company}
                    error={Boolean(touched.company && errors.company)}
                  />
                </div>

                <div className="form-group mb-0">
                  <TextField
                    label="Paypal Email (for payments)"
                    margin="normal"
                    className="kt-width-full"
                    name="paypal_email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.paypal_email}
                    helperText={touched.paypal_email && errors.paypal_email}
                    error={Boolean(touched.paypal_email && errors.paypal_email)}
                  />
                </div>

                <div className="form-group mb-0">
                  <TextField
                    margin="normal"
                    label="What's the main reason you're signing up?"
                    className="kt-width-full"
                    name="reason"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.reason}
                    helperText={touched.reason && errors.reason}
                    error={Boolean(touched.reason && errors.reason)}
                  />
                </div>

                <div className="form-group mb-0">
                  <TextField
                    margin="normal"
                    label="What is your blogs URL?"
                    className="kt-width-full"
                    name="blog_url"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.blog_url}
                    helperText={touched.blog_url && errors.blog_url}
                    error={Boolean(touched.blog_url && errors.blog_url)}
                  />
                </div>

                <div className="form-group mb-0">
                  <TextField
                    margin="normal"
                    label="What is the price for a sponsored blog post?"
                    className="kt-width-full"
                    name="blog_price"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.blog_price}
                    helperText={touched.blog_price && errors.blog_price}
                    error={Boolean(touched.blog_price && errors.blog_price)}
                  />
                </div>

                <div className="form-group mb-2 mt-3">
                  <label className="MuiFormLabel-root">Blog Category</label>
                  <Select
                    name="category_id"
                    className="mb-0"
                    options={categoryOptions}
                    onChange={handleCategoryChange}
                    styles={selectStyles}
                  />
                </div>

                <div className="form-group mb-0">
                  <TextField
                    type="password"
                    margin="normal"
                    label="Password"
                    className="kt-width-full"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                </div>

                {/* <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label="Confirm Password"
                    className="kt-width-full"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmPassword}
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    error={Boolean(
                      touched.confirmPassword && errors.confirmPassword
                    )}
                  />
                </div> */}
                {/* <div className="form-group">
                  <TextField
                    margin="normal"
                    label="Phone Number (Optional)"
                    className="kt-width-full"
                    name="phone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    helperText={touched.phone && errors.phone}
                    error={Boolean(touched.phone && errors.phone)}
                  />
                </div> */}

              <div className="form-group mb-2 mt-3">
                  <label className="MuiFormLabel-root">What solution are you looking for?</label>
                  <Select
                    name="solutions_looking_for"
                    className="mb-0"
                    options={solutionsOptions}
                    onChange={handleSolutionsChange}
                    styles={selectStyles}
                  />
              </div>

              <div className="form-group mb-0">
                <TextField
                  margin="normal"
                  label="How did you hear about us?"
                  className="kt-width-full"
                  name="how_did_you_hear_about_us"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.how_did_you_hear_about_us}
                  helperText={touched.how_did_you_hear_about_us && errors.how_did_you_hear_about_us}
                  error={Boolean(touched.how_did_you_hear_about_us && errors.how_did_you_hear_about_us)}
                />
              </div>

                <div className="form-group mb-0 mt-4">
                  <FormControlLabel
                    label={
                      <>
                        I Agree to the{" "}
                        <Link
                          to="/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms & Conditions
                        </Link>,{" "}
                        <Link
                          to="/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Cookie Policy
                        </Link>,{" "}
                        and the{" "}
                        <Link
                          to="/terms"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Privacy Policy
                        </Link>.
                      </>
                    }
                    control={
                      <Checkbox
                        color="primary"
                        name="acceptTerms"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        checked={values.acceptTerms}
                      />
                    }
                  />
                </div>

                <div className="kt-login__actions">
                    {/*<Link
                    to="/auth/forgot-password"
                    className="kt-link kt-login__link-forgot"
                  >
                    <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                  </Link>*/}

                  <Link to="/auth">
                    <button type="button" className="btn btn-secondary btn-elevate kt-login__btn-secondary btn_signin">
                      Sign In
                    </button>
                  </Link>

                  <button
                    disabled={isSubmitting || !values.acceptTerms}
                    className="btn btn-primary btn-elevate kt-login__btn-primary"
                  >
                    Create Account Now
                  </button>

                  <Link to="/auth">
                    <button type="button" className="btn btn-secondary btn-elevate kt-login__btn-secondary btn_signin_mobile">
                      Sign In
                    </button>
                  </Link>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ projects: {projectCategories} }) => ({
    projectCategories
});

export default injectIntl(
  connect(
    mapStateToProps,
    {...auth.actions, ...projects.actions}
  )(Registration)
);
