/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {Dropdown, Nav, Tab} from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import {ReactComponent as CompilingIcon} from "../../../_metronic/layout/assets/layout-svg-icons/Compiling.svg";
import * as notifications from "../../store/ducks/notifications.duck";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {Link} from "react-router-dom";
import {readNotification} from "../../crud/user.crud";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false
};

class UserNotifications extends React.Component {
  state = { key: "All", show: false };

  componentDidMount() {
    this.props.initNotifications();
  }

  getHeaderTopBarCssClassList = () => {
    let result = "kt-header__topbar-icon ";
    if (this.props.pulse) {
      result += "kt-pulse kt-pulse--brand ";
    }

    const { iconType } = this.props;
    if (iconType) {
      result += `kt-header__topbar-icon--${iconType}`;
    }

    return result;
  };

  getSvgCssClassList = () => {
    let result = "kt-svg-icon ";
    const { iconType } = this.props;
    if (iconType) {
      result += `kt-svg-icon--${iconType}`;
    }

    return result;
  };

  getHetBackGroundCssClassList = () => {
    let result = "kt-head ";
    if (this.props.skin) {
      result += `kt-head--skin-${this.props.skin} `;
    }

    result += "kt-head--fit-x kt-head--fit-b";
    return result;
  };

  backGroundStyle = () => {
    if (!this.props.bgImage) {
      return "none";
    }

    return "url(" + this.props.bgImage + ")";
  };

  userNotificationsButtonCssClassList = () => {
    let result = "btn ";
    if (this.props.type) {
      result += `btn-label-${this.props.type} `;
    }

    result += "btn-sm btn-bold btn-font-md";
    return result;
  };

  ulTabsClassList = () => {
    let result = "nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x  ";
    if (this.props.type) {
      result += `nav-tabs-line-${this.props.type} `;
    }

    result += "kt-notification-item-padding-x";
    return result;
  };

  markAsRead = (id) => {
    this.setState({
      ...this.state,
      show: false
    });
    readNotification(id).then(res => {
      this.props.requestNotifications();
    });
  };

  onToggle = (open) => {
    if(open) {
      this.setState({
        ...this.state,
        show: true
      });
    }
    else {
      this.setState({
        ...this.state,
        show: false
      });
    }
  };

  formNotification = (item) => {
      const date = new Date(item.created_at);
      return <Link key={item.id} to={item.data.link ? item.data.link : "#"} onClick={() => {
          this.markAsRead(item.id)
      }} className={"kt-notification__item" + (item.read_at ? " kt-notification__item--read" : "")}>
          <div className="kt-notification__item-icon">
              {item.data.status === "new" && <i className="flaticon2-box-1 kt-font-warning"/>}
              {item.data.status === "processing" &&
              <i className="flaticon2-line-chart kt-font-brand"/>}
              {item.data.status === "completed" &&
              <i className="flaticon2-image-file kt-font-success"/>}
          </div>
          <div className="kt-notification__item-details">
              <div className="kt-notification__item-title">
                  {item.data.text}
              </div>
              <div className="kt-notification__item-time">
                  {date.toLocaleString()}
              </div>
          </div>
      </Link>;
  };

  render() {
    // eslint-disable-next-line no-unused-vars
    const { key } = this.state;
    const { useSVG, icon, pulse } = this.props;
    const { notifications } = this.props;

    const unreadMsg = [];
    const allMsg = [];
    if(notifications && notifications.data) {
        if(notifications.data.length !== 0) {
            for (let i = 0; i < notifications.data.length; i++) {
                const item = this.formNotification(notifications.data[i]);
                allMsg.push(item);
            }
        }
        if(notifications.unread_items && notifications.unread_items.length !== 0) {
            for (let i = 0; i < notifications.unread_items.length; i++) {
                const item = this.formNotification(notifications.unread_items[i]);
                unreadMsg.push(item);
            }
        }
    }

    return (
      <Dropdown className="kt-header__topbar-item" drop="down" alignRight show={this.state.show} onToggle={this.onToggle}>
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-user-notifications"
        >
          <span className={this.getHeaderTopBarCssClassList()}>
            {!useSVG && <i className={icon} />}

            {useSVG && (
              <span className={this.getSvgCssClassList()}>
                <CompilingIcon />
              </span>
            )}

            {/*<span className="kt-pulse__ring" hidden={!pulse} />
            <span className="kt-hidden kt-badge kt-badge--dot kt-badge--notify kt-badge--sm"></span>*/}
            {notifications && notifications.count > 0 && (<span className="kt-badge kt-badge--notify kt-badge--success">{notifications.count}</span>)}
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
          <form>
            {/** Head */}
            <div
              className={this.getHetBackGroundCssClassList()}
              style={{ backgroundImage: this.backGroundStyle() }}
            >
              <h3 className="kt-head__title">
                User Notifications{' '} {' '}
                <span className={this.userNotificationsButtonCssClassList()}>
                  {notifications && notifications.count ? notifications.count : 0} new
                </span>
              </h3>

              <Tab.Container
                defaultActiveKey={key}
                className={this.ulTabsClassList()}
              >
                <Nav
                  className={this.ulTabsClassList()}
                  onSelect={_key => this.setState({ key: _key })}
                >
                  <Nav.Item className="nav-item">
                    <Nav.Link eventKey="All" className="nav-link show">
                      All
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="New" className="nav-link show">
                      New
                    </Nav.Link>
                  </Nav.Item>
                    {/*<Nav.Item>
                    <Nav.Link eventKey="Logs" className="nav-link show">
                      Logs
                    </Nav.Link>
                  </Nav.Item>*/}
                </Nav>

                <Tab.Content>
                  <Tab.Pane eventKey="All">
                    <PerfectScrollbar
                      options={perfectScrollbarOptions}
                      style={{ maxHeight: "100vh", position: "relative" }}
                    >
                      <div
                        className="kt-notification kt-margin-t-10 kt-margin-b-10"
                        style={{ maxHeight: "40vh", position: "relative" }}
                      >
                        <div
                          className="kt-notification kt-margin-t-10 kt-margin-b-10 kt-scroll"
                          data-scroll="true"
                          data-height="300"
                          data-mobile-height="200"
                        >
                            {allMsg.length !== 0 ? allMsg : (
                                <div
                                    className="kt-grid kt-grid--ver"
                                    style={{ minHeight: "200px" }}
                                >
                                    <div className="kt-grid kt-grid--hor kt-grid__item kt-grid__item--fluid kt-grid__item--middle">
                                        <div className="kt-grid__item kt-grid__item--middle kt-align-center">
                                            All caught up!
                                            <br />
                                            No new notifications.
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                      </div>
                    </PerfectScrollbar>
                  </Tab.Pane>
                  <Tab.Pane eventKey="New">
                    <PerfectScrollbar
                      options={perfectScrollbarOptions}
                      style={{ maxHeight: "100vh", position: "relative" }}
                    >
                      <div
                        className="kt-notification kt-margin-t-10 kt-margin-b-10"
                        style={{ maxHeight: "40vh", position: "relative" }}
                      >
                        <div
                          className="kt-notification kt-margin-t-10 kt-margin-b-10 kt-scroll"
                          data-scroll="true"
                          data-height="300"
                          data-mobile-height="200"
                        >
                            {unreadMsg.length !== 0 ? unreadMsg : (
                                <div
                                    className="kt-grid kt-grid--ver"
                                    style={{ minHeight: "200px" }}
                                >
                                    <div className="kt-grid kt-grid--hor kt-grid__item kt-grid__item--fluid kt-grid__item--middle">
                                        <div className="kt-grid__item kt-grid__item--middle kt-align-center">
                                            All caught up!
                                            <br />
                                            No new notifications.
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                      </div>
                    </PerfectScrollbar>
                  </Tab.Pane>
                    {/*<Tab.Pane eventKey="Logs">
                    <div
                      className="kt-grid kt-grid--ver"
                      style={{ minHeight: "200px" }}
                    >
                      <div className="kt-grid kt-grid--hor kt-grid__item kt-grid__item--fluid kt-grid__item--middle">
                        <div className="kt-grid__item kt-grid__item--middle kt-align-center">
                          All caught up!
                          <br />
                          No new notifications.
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>*/}
                </Tab.Content>
              </Tab.Container>
            </div>
          </form>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = ({ notifications: { notifications } }) => ({
    notifications
});

export default injectIntl(connect(
    mapStateToProps,
    notifications.actions
)(UserNotifications));
