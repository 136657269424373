import axios from "axios";

export const base = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL : "";
export const API_ONPAGE_URL = base + "/api/onpage";

export function setAudit(projectId) {
    return axios.post(API_ONPAGE_URL + "/set_audit", { project_id: projectId });
}

export function getSummary(projectId) {
    return axios.get(API_ONPAGE_URL + "/get_summary?project_id=" + projectId);
}

export function getFilteredPages(projectId, page, filterKey, filter) {
    return axios.post(API_ONPAGE_URL + "/get_pages?project_id=" + projectId + (page > 1 ? "&page=" + page : ""), { filterKey, filter });
}

export function getRelatedPages(projectId, page, filterKey, pageId) {
    return axios.post(API_ONPAGE_URL + "/get_related_pages?project_id=" + projectId + (page > 1 ? "&page=" + page : ""), { filterKey, pageId });
}

export function getLinks(projectId, page, type, pageFrom, keyword) {
    return axios.post(API_ONPAGE_URL + "/get_links?project_id=" + projectId + (page > 1 ? "&page=" + page : ""),{link_type: type, page_from: pageFrom, keyword});
}

export function getAnchorsChartData(projectId) {
    return axios.get(API_ONPAGE_URL + "/get_anchors_data?project_id=" + projectId);
}

export function getAnchorsData(projectId, page) {
    return axios.get(API_ONPAGE_URL + "/get_anchors_data?project_id=" + projectId + "&detail=true" + (page > 1 ? "&page=" + page : ""));
}

export function getPageLimits() {
    return axios.get(API_ONPAGE_URL + "/get_limits");
}
