import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField, FormControl, Icon, IconButton, InputAdornment } from "@material-ui/core";
import { injectIntl } from "react-intl";
import * as profile from "../../../store/ducks/profile.duck";
import { editBillingInfo } from "../../../crud/user.crud";
import {Spinner} from "reactstrap";
import Select from "react-select";
import { Badge } from "react-bootstrap";
import { editWriter, writerApplication } from "../../../crud/auth.crud";
import * as projects from "../../../store/ducks/projects.duck";

const selectStyles = { menu: styles => ({ ...styles, zIndex: 999, position: "relative" }) };

class ContentWriters extends Component {

	constructor(props) {
        super(props);

        let sending = false;
     	let firstLoad = true;
        let writingTopicTags = [];

        this.state = {
        	firstLoad: firstLoad,
            sending: sending,
            writingTopicTags: writingTopicTags,
            tagField: "",
        };

    }

    componentDidMount() {
    	const {projects} = this.props;
    	// this.props.requestProjectCategories();
        this.props.initBillingInfo();
    }

    render() {
        const { intl, billingInfo, projectCategories } = this.props;

        const selectStyles = {
	        menu: styles => ({ ...styles, zIndex: 999 }),
	        control: (provided, state) => ({
	            ...provided,
	            minHeight: '47px',
	        }),
		};

		// const categoryOptions = [];
    	let selected_categories = this.state.writingTopicTags;
    	let defaultCategories = [];  //this.state.writingTopicTags;

	   	// const handleCategoryChange = (value) => {
	    //     if(value && value.length !== 0) {
	    //         let selectedText = [];
	    //         selected_categories = [];
	    //         for(let i = 0; i < value.length; i++) {
	    //             selectedText.push(value[i].value);
	    //         }
	    //         selected_categories = value;
	    //     } else {
	    //         selected_categories = [];
	    //     }
	    // };

	    // if (typeof projectCategories !== 'undefined') {
	    //   if (projectCategories.data && projectCategories.data.length !== 0) {
	    //     for (let index = 0; index < projectCategories.data.length; index++) {
	    //         let item = {value: projectCategories.data[index].id, label: projectCategories.data[index].name};
	    //         categoryOptions.push(item);
	    //     }
	    //   }
	    // }

	    const addTags = (event) => {
	    	if (event.target.value == ",") {
	    		event.preventDefault();
			} else if (event.target.value !== "") {
			    let tag = this.state.tagField.trim();
			    let newTags = this.state.writingTopicTags;
			    if (tag && !this.state.writingTopicTags.includes(tag)) {
			      newTags.push(tag);
			    }
			    event.target.value = "";
			    event.key = '';
			    this.setState({ tagField: "", writingTopicTags: newTags });
			    event.preventDefault();
			}
		}

		const handleKeyPress = (event) => {
			if (event.target.value == ",") {

			} else if (event.target.value !== "") {
			    if (event.key == ',') {
			      	addTags(event);
			      	event.target.value = "";
			    }
			}
		}

		const removeTags = indexToRemove => {
			let newTags = this.state.writingTopicTags.filter((_, index) => index !== indexToRemove);
			this.setState({ tagField: "", writingTopicTags: newTags });
		}

        if (!billingInfo)
        {
            return <Spinner />;
        }

        if (billingInfo && billingInfo.content_writer) {
        	if (typeof billingInfo.content_writer.categories !== 'undefined' && defaultCategories.length == 0 && this.state.writingTopicTags.length == 0 && this.state.firstLoad) {
		      	let tifs = billingInfo.content_writer.categories;
		      	Object.keys(tifs).forEach(function(key) {
		            defaultCategories.push(tifs[key]);
		        });
	    	}

	    	if (defaultCategories.length > 0) {
	    		this.setState({ tagField: "", writingTopicTags: defaultCategories, firstLoad: false, });
	    	}

        	return (
        		<>
		        	<div className="row">
		        		<div lassName="col-12">
		        			<h5 className="mb-1">Content Writer Status</h5>
		        			<Badge pill variant={billingInfo.content_writer.status.status_color}>{billingInfo.content_writer.status.name}</Badge>
		        		</div>
		        	</div>
		        	<Formik
			            initialValues={{
			              portfolio_url: billingInfo.content_writer.portfolio_url,
			              selected_categories: defaultCategories,
			              content_price: billingInfo.content_writer.content_price,
			            }}
			            validate={values => {
			              const errors = {};

			              // if (!values.portfolio_url) {
			              //   errors.portfolio_url = intl.formatMessage({
			              //     id: "AUTH.VALIDATION.REQUIRED_FIELD"
			              //   });
			              // }

			              if (!values.content_price) {
			                errors.content_price = intl.formatMessage({
			                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
			                });
			              }

			              // if (!selectedCategory || selectedCategory == 0) {
			              //   errors.category_id = intl.formatMessage({
			              //     id: "AUTH.VALIDATION.REQUIRED_FIELD"
			              //   });
			              // }

			              return errors;
			            }}
			            onSubmit={(values, { setStatus, setSubmitting }) => {
			              editWriter(
			                values.portfolio_url,
			                values.content_price,
			                selected_categories
			              )
			                .then(({ data: { access_token } }) => {
			                  	setSubmitting(false);
	                            setStatus(
	                                "Saved."
	                            );
			                })
			                .catch(e => {
			                  setSubmitting(false);
			                  let str = '';
			                  let obj = e.response.data.errors;
			                  for (var p in obj) {
			                    if (obj.hasOwnProperty(p)) {
			                      str += obj[p] + '\n';
			                    }
			                  }
			                  setStatus(
			                    str
			                  );
			                });
			            }}
			          >
			            {({
			              values,
			              status,
			              errors,
			              touched,
			              handleChange,
			              handleBlur,
			              handleSubmit,
			              isSubmitting
			            }) => (
			              	<form onSubmit={handleSubmit} noValidate autoComplete="off">
			                	{status && !isSubmitting && (
		                            <div role="alert" className={'mt-3 alert ' + (status === 'Saved.' ? 'alert-success' : 'alert-danger')}>
		                                <div className="alert-text">{status}</div>
		                            </div>
		                        )}

			                	<div className="form-group mb-0">
			                  		<TextField
					                    margin="normal"
					                    label="Portfolio URL"
					                    className="kt-width-full"
					                    name="portfolio_url"
					                    onBlur={handleBlur}
					                    onChange={handleChange}
					                    value={values.portfolio_url}
					                    helperText={touched.portfolio_url && errors.portfolio_url}
					                    error={Boolean(touched.portfolio_url && errors.portfolio_url)}
					                  />
			                	</div>

			                	<div className="form-group mb-0">
				                  	<TextField
					                    margin="normal"
					                    label="Price per 100 words?"
					                    className="kt-width-full"
					                    name="content_price"
					                    onBlur={handleBlur}
					                    onChange={handleChange}
					                    value={values.content_price}
					                    helperText={touched.content_price && errors.content_price}
					                    error={Boolean(touched.content_price && errors.content_price)}
					                  />
			                	</div>

			                	<div className="form-group mb-3 mt-3">
			                		<label className="d-block MuiFormLabel-root">Writing Topics</label>
				                	<div className="tags-input">
										<ul id="tags">
											{this.state.writingTopicTags.map((tag, index) => (
												<li key={index} className="tag">
													<span className='tag-title'>{tag}</span>
													<span className='tag-close-icon'
														onClick={() => removeTags(index)}
													>
														x
													</span>
												</li>
											))}
										</ul>
										<input
											type="text"
											// onKeyUp={event => event.key === "Enter" ? addTags(event) : null}
											onKeyPress={handleKeyPress}
											onChange={(e) => this.setState({ tagField: e.target.value })}
											value={this.state.tagField}
											placeholder="Separate writing topics with commas"
										/>
									</div>
								</div>

			                	{/*<div className="form-group mb-3 mt-3">
					                <label className="d-block MuiFormLabel-root">Writing Topics</label>
				                	<TextField
						                placeholder="Add Writing Topic"
						                className=""
						                value={this.state.tagField}
						                onChange={(e) => this.setState({ tagField: e.target.value })}
						                onKeyPress={handleKeyPress}		               
						                InputProps={{
						                  	// startAdornment: (
							                  //   <InputAdornment position="start">
							                  //     	<Icon>label</Icon>
							                  //   </InputAdornment>
						                  	// ),
						                  	endAdornment: (
							                    <InputAdornment position="end">
							                        <IconButton
							                          aria-label="add tag"
							                          onClick={() => addWritingTopicTag()}
							                          //onMouseDown={() => addWritingTopicTag()}
							                        >
							                          <Icon>add</Icon>
							                        </IconButton>
							                    </InputAdornment>
						                  	),
						                }}
						                inputProps={{ maxLength: 30 }}
						              />
					           </div>

			                	<div className="form-group mb-3 mt-3">
					                <label className="MuiFormLabel-root">Writing Topics</label>
					                <Select
					                  	isMulti
					                  	name="category_id_array"
					                    className="mb-0 mt-1"
					                    options={categoryOptions}
					                    onChange={handleCategoryChange}
					                    styles={selectStyles}
					                    defaultValue={defaultCategories}
					                  />
			                	</div>*/}

			                	<div className="kt-login__actions">
			                 	 	<button
			                    		disabled={isSubmitting}
			                    		className="btn btn-primary btn-elevate kt-login__btn-primary"
			                  			>
			                    		Update Writer Profile
			                  		</button>
			                	</div>
			              	</form>
			            )}
		          	</Formik>
		        </>
        	);


        } else {

	        return (
	            <>
		        	<div className="row">
		        		<div className="col-12">
		        			<h5 className="mb-1">Apply to be a Content Writer</h5>
		        		</div>
		        	</div>
		        	<Formik
			            initialValues={{
			              portfolio_url: "",
			              selected_categories: [],
			              content_price: 0,
			            }}
			            validate={values => {
			              const errors = {};

			              // if (!values.portfolio_url) {
			              //   errors.portfolio_url = intl.formatMessage({
			              //     id: "AUTH.VALIDATION.REQUIRED_FIELD"
			              //   });
			              // }

			              if (!values.content_price) {
			                errors.content_price = intl.formatMessage({
			                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
			                });
			              }

			              // if (!selectedCategory || selectedCategory == 0) {
			              //   errors.category_id = intl.formatMessage({
			              //     id: "AUTH.VALIDATION.REQUIRED_FIELD"
			              //   });
			              // }

			              return errors;
			            }}
			            onSubmit={(values, { setStatus, setSubmitting }) => {
			              writerApplication(
			                values.portfolio_url,
			                values.content_price,
			                selected_categories
			              )
			                .then(({ data: { access_token } }) => {
			                  	setSubmitting(false);
	                            setStatus(
	                                "Your application has been sent and will be reviewed shortly."
	                            );
			                })
			                .catch(e => {
			                  setSubmitting(false);
			                  let str = '';
			                  let obj = e.response.data.errors;
			                  for (var p in obj) {
			                    if (obj.hasOwnProperty(p)) {
			                      str += obj[p] + '\n';
			                    }
			                  }
			                  setStatus(
			                    str
			                  );
			                });
			            }}
			          >
			            {({
			              values,
			              status,
			              errors,
			              touched,
			              handleChange,
			              handleBlur,
			              handleSubmit,
			              isSubmitting
			            }) => (
			              	<form onSubmit={handleSubmit} noValidate autoComplete="off">
			                	{status && !isSubmitting && (
		                            <div role="alert" className={'mt-3 alert ' + (status === 'Your application has been sent and will be reviewed shortly.' ? 'alert-success' : 'alert-danger')}>
		                                <div className="alert-text">{status}</div>
		                            </div>
		                        )}

			                	<div className="form-group mb-0">
			                  		<TextField
					                    margin="normal"
					                    label="Portfolio URL"
					                    className="kt-width-full"
					                    name="portfolio_url"
					                    onBlur={handleBlur}
					                    onChange={handleChange}
					                    value={values.portfolio_url}
					                    helperText={touched.portfolio_url && errors.portfolio_url}
					                    error={Boolean(touched.portfolio_url && errors.portfolio_url)}
					                  />
			                	</div>

			                	<div className="form-group mb-0">
				                  	<TextField
					                    margin="normal"
					                    label="Price per 100 words?"
					                    className="kt-width-full"
					                    name="content_price"
					                    onBlur={handleBlur}
					                    onChange={handleChange}
					                    value={values.content_price}
					                    helperText={touched.content_price && errors.content_price}
					                    error={Boolean(touched.content_price && errors.content_price)}
					                  />
			                	</div>

			                	<div className="form-group mb-3 mt-3">
			                		<label className="d-block MuiFormLabel-root">Writing Topics</label>
				                	<div className="tags-input">
										<ul id="tags">
											{this.state.writingTopicTags.map((tag, index) => (
												<li key={index} className="tag">
													<span className='tag-title'>{tag}</span>
													<span className='tag-close-icon'
														onClick={() => removeTags(index)}
													>
														x
													</span>
												</li>
											))}
										</ul>
										<input
											type="text"
											// onKeyUp={event => event.key === "Enter" ? addTags(event) : null}
											onKeyPress={handleKeyPress}
											onChange={(e) => this.setState({ tagField: e.target.value })}
											value={this.state.tagField}
											placeholder="Separate writing topics with commas"
										/>
									</div>
								</div>

			                	{/*<div className="form-group mb-3 mt-3">
					                <label className="MuiFormLabel-root">Writing Topics</label>
					                <Select
					                  	isMulti
					                  	name="category_id_array"
					                    className="mb-0 mt-1"
					                    options={categoryOptions}
					                    onChange={handleCategoryChange}
					                    styles={selectStyles}
					                    defaultValue={defaultCategories}
					                  />
			                	</div>*/}

			                	<div className="kt-login__actions">
			                 	 	<button
			                    		disabled={isSubmitting}
			                    		className="btn btn-primary btn-elevate kt-login__btn-primary"
			                  			>
			                    		Apply
			                  		</button>
			                	</div>
			              	</form>
			            )}
		          	</Formik>
		        </>
	        );
		} // if (billingInfo && billingInfo.content_writer) {
    }
}

const mapStateToProps = ({ profile: { billingInfo }, projects: {projectCategories} }) => ({
    billingInfo, projectCategories
});

export default injectIntl(connect(
    mapStateToProps,
    {...profile.actions, ...projects.actions}
)(ContentWriters));
