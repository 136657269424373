import React, {Component} from "react";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {Spinner} from "reactstrap";
import {Link} from "react-router-dom";
import {Portlet, PortletBody} from "../../partials/content/Portlet";
import { Alert, AlertTitle } from '@material-ui/lab';
import {Input} from '@material-ui/icons';
import "../../../_metronic/_assets/sass/custom/analytics.scss";
import * as analytics from "../../store/ducks/analytics.duck";
import * as projects from "../../store/ducks/projects.duck";
import AnalyticsDetail from "./analytics-components/AnalyticsDetail";

class Analytics extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }


    componentDidMount() {
        if (this.props.currentProject && this.props.currentProject.data){
            this.props.requestAnalyticsSite(this.props.currentProject.data.id);
            this.props.requestAnalyticsNotes(this.props.currentProject.data.id);
            this.props.requestAnalyticsGoogleUpdates(this.props.currentProject.data.id);
            this.props.requestSiteData(this.props.currentProject.data.id);
        }
        else {
            const currentProjectId = this.props.currentProjectId;
            if(currentProjectId > 0) {
                this.props.requestProject(currentProjectId);
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.currentProjectId !== prevProps.currentProjectId) {
            this.props.requestAnalyticsSite(this.props.currentProject.data.id);
            this.props.requestAnalyticsNotes(this.props.currentProject.data.id);
            this.props.requestAnalyticsGoogleUpdates(this.props.currentProject.data.id);
        }
    }


    render() {

        const {currentProject, currentProjectId, site} = this.props;

        if(!site && currentProjectId > 0) {
            return <Spinner />;
        }

        if (!currentProject) {
            return (
            <Portlet>
                <PortletBody>
                    <Alert severity="error">
                        <AlertTitle>Project not found</AlertTitle>
                        Add new project to Seo campaign. <Link to="/projects/">Go!</Link>
                    </Alert>
                </PortletBody>
            </Portlet>
            );
        }

        if(currentProject && !currentProject.data) {
            return <p>Project not loaded.</p>;
        }

        const title = currentProject && currentProject.data ? currentProject.data.url : "";

        /*if (site === null) {
            return (
                <Portlet>
                    <PortletBody>
                        <Spinner mx="auto"/>
                    </PortletBody>
                </Portlet>
            );
        }*/

        if (!site || !currentProject.data.tracking_code_added) {
            return (
                <Portlet>
                    <PortletBody>
                        <Alert severity="warning">
                            <AlertTitle>{title} - This project is not added to traffic analytics.</AlertTitle>
                            You must include this tracking code to use our traffic analytics features. <Link to="/projects/">Go!</Link>
                        </Alert>
                    </PortletBody>
                </Portlet>
            );
        }


        //console.log('root-state', this.state); console.log('root-props', this.props);

        if (site && currentProject.data.tracking_code_added) {
            return (
                <div className="analytics">
                    <div className="kt-space-20"/>
                    <h4 className="text-center">{title} <a target="_blank" href={title}><Input /></a></h4>
                    <br/>
                    <AnalyticsDetail
                        currentProject={currentProject.data}
                    />
                </div>
            );
        }
    }
}


const mapStateToProps = ({
        //auth: { user: auth },
        //projects: { currentProject: {data: currentProject }},
        projects: { currentProject, currentProjectId},
        //analytics: { analytic, site, user, notes}
        analytics: {site}
    }) => ({
        currentProject, currentProjectId, site //auth, site, user, analytic, notes,
    });

export default injectIntl(connect(
    mapStateToProps,
    {...analytics.actions, ...projects.actions}
)(Analytics));
