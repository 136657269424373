import React, {Component} from "react";
import {Portlet, PortletBody, PortletHeader} from "../../../../partials/content/Portlet";
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default class AuditPerPageInfo extends Component {
    render() {
        return (<>
            <Portlet>
                <PortletHeader title="Frequently Asked Questions" />
                <PortletBody className="p-0">
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Typography style={{fontSize:16,fontWeight:"bold"}}>What is the turnaround time?</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <div>
                                <p>Anywhere between 2 - 4 weeks, depending on the size of your order.</p>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </PortletBody>
            </Portlet>
        </>);
    }
}
