import axios from "axios";

export const base = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL : "";
export const API_ORDER_URL = base + "/api/orders";
export const API_ORDER_BACKLINKS = base + "/api/backlinks";
export const API_GUEST_POST_URL = base + "/api/guestpost";
export const API_SEO_SERVICE_URL = base + "/api/seo-service";
export const API_LINK_CLEAN_UP_URL = base + "/api/link-clean-up";
export const API_LINK_INSERTION_URL = base + "/api/link-insertion";
export const API_PRESS_RELEASE_URL = base + "/api/press-release";
export const API_GPB_URL = base + "/api/gpb";
export const API_CITATION_URL = base + "/api/citation";
export const API_ARTICLE_URL = base + "/api/article";
export const API_DEPOSIT_URL = base + "/api/billing/deposit";
export const API_SALES_URL = base + "/api/sales";
export const API_WITHDRAW_URL = base + "/api/billing/withdraw";

export function addDeposit(deposit_amount, payment_order_id, payment_method, address, country, city, postal_code, state, billing_firstname, billing_lastname) {
    return axios.post(API_DEPOSIT_URL, { deposit_amount, payment_order_id, payment_method, address, country, city, postal_code, state, billing_firstname, billing_lastname });
}

export function addWithdrawRequest(withdraw_amount) {
    return axios.post(API_WITHDRAW_URL, { withdraw_amount });
}

export function addOrder(order_data, billing_info, use_remaining_balance, project_id) {
    return axios.post(API_ORDER_URL, { order_data, billing_info, use_remaining_balance, project_id });
}

export function updateOrderProject(updateType, orderId, orderNumber, orderProject) {
    return axios.put(API_ORDER_URL+'/'+orderId, { updateType, orderId, orderNumber, orderProject});
}

export function getOrders(project_id, page = null) {
    return axios.get(API_ORDER_URL + "?project_id=" + project_id + (page > 1 ? "&page=" + page : ""));
}

export function getBacklinks(project_id, page = null, search_query = '') {
    // return axios.get(API_ORDER_BACKLINKS + "?project_id=" + project_id + (page > 1 ? "&page=" + page : ""));
    return axios.get(API_ORDER_URL + "?backlinks=yes&project_id=" + project_id + (page > 1 ? "&page=" + page : "") + (search_query != '' ? "&search_query=" + search_query : ""));
}

export function getOrder(id) {
    return axios.get(API_ORDER_URL + "/" + id);
}

export function getGuestPosts(orderId, page, params) {
    return axios.get(API_GUEST_POST_URL + "/order/" + orderId + (page > 1 ? "?page=" + page : "") + (params && params.build ? (page > 1 ? "&" : "?") + "build=" + parseInt(params.build) : ""));
}

export function getGuestPost(id) {
    return axios.get(API_GUEST_POST_URL + "/info/" + id);
}

export function editGuestPost(id, data) {
    return axios.post(API_GUEST_POST_URL + "/edit/" + id, data);
}

export function editGuestPostWriter(id, data) {
    return axios.post(API_GUEST_POST_URL + "/writer/edit/" + id, data);
}

export function stripePay(data) {
    return axios.post(base + "/api/stripe/pay", data);
}

export function paypalInitCheckout(data) {
    data.site_url = base;
    return axios.post(base + "/api/paypal/checkout", data);
}
export function paypalCheckoutConfirm(data) {
    data.site_url = base;
    return axios.post(base + "/api/paypal/checkout-confirm", data);
}

export function stripeInitCheckoutSession(data) {
    data.site_url = base;
    return axios.post(base + "/api/stripe/checkout", data);
}
export function stripeCheckoutConfirm(data) {
    data.site_url = base;
    return axios.post(base + "/api/stripe/checkout-confirm", data);
}

export function validateVat(data) {
    return axios.post(base + "/api/vat/validate", data);
}

export function calculateVat(data) {
    return axios.post(base + "/api/vat/tax_rate", data);
}

export function downloadPdfInvoice(orderId) {
    return axios.get(base + "/api/invoice/" + orderId + "/pdf", {responseType: 'blob'});
}
export function downloadBacklinksCsv(project_id, search_query = '') {
    return axios.get(base + "/api/backlinks/csv?project_id="+project_id+(search_query != '' ? "&search_query=" + search_query : ""), {responseType: 'blob'});
}

export function downloadSalePdfInvoice(order_line_number) {
    return axios.get(base + "/api/invoice/" + order_line_number + "/pdf", {responseType: 'blob'});
}

export function getSaleInvoice(order_line_number) {
    return axios.get(base + "/api/invoice/" + order_line_number);
}

export function getInvoice(orderId) {
    return axios.get(base + "/api/invoice/" + orderId);
}

export function getDiscountAmount(data) {
    return axios.post(base + "/api/discount/calculate", data);
}

export function getUserDiscount() {
    return axios.get(base + "/api/discount/user_discount");
}

export function getSeoServices(orderId, page, params) {
    return axios.get(API_SEO_SERVICE_URL + "/order/" + orderId + (page > 1 ? "?page=" + page : "") + (params && params.done ? (page > 1 ? "&" : "?") + "done=" + parseInt(params.done) : ""));
}

export function getSeoService(id) {
    return axios.get(API_SEO_SERVICE_URL + "/info/" + id);
}

export function editSeoService(id, data) {
    return axios.post(API_SEO_SERVICE_URL + "/edit/" + id, data);
}

export function getLinkCleanUpService(id) {
    return axios.get(API_LINK_CLEAN_UP_URL + "/info/" + id);
}

export function getLinkCleanUpServices(orderId, page, params) {
    return axios.get(API_LINK_CLEAN_UP_URL + "/order/" + orderId + (page > 1 ? "?page=" + page : "") + (params && params.done ? (page > 1 ? "&" : "?") + "done=" + parseInt(params.done) : ""));
}

export function editLinkCleanUpService(id, data) {
    return axios.post(API_LINK_CLEAN_UP_URL + "/edit/" + id, data);
}

export function getLinkInsertionService(id) {
    return axios.get(API_LINK_INSERTION_URL + "/info/" + id);
}

export function getLinkInsertionServices(orderId, page, params) {
    return axios.get(API_LINK_INSERTION_URL + "/order/" + orderId + (page > 1 ? "?page=" + page : "") + (params && params.done ? (page > 1 ? "&" : "?") + "done=" + parseInt(params.done) : ""));
}

export function editLinkInsertionService(id, data) {
    return axios.post(API_LINK_INSERTION_URL + "/edit/" + id, data);
}

export function getPressReleaseService(id) {
    return axios.get(API_PRESS_RELEASE_URL + "/info/" + id);
}

export function getPressReleaseServices(orderId, page, params) {
    return axios.get(API_PRESS_RELEASE_URL + "/order/" + orderId + (page > 1 ? "?page=" + page : "") + (params && params.done ? (page > 1 ? "&" : "?") + "done=" + parseInt(params.done) : ""));
}

export function editPressReleaseService(id, data) {
    return axios.post(API_PRESS_RELEASE_URL + "/edit/" + id, data);
}

export function getGPBService(id) {
    return axios.get(API_GPB_URL + "/info/" + id);
}

export function getGPBServices(orderId, page, params) {
    return axios.get(API_GPB_URL + "/order/" + orderId + (page > 1 ? "?page=" + page : "") + (params && params.done ? (page > 1 ? "&" : "?") + "done=" + parseInt(params.done) : ""));
}

export function editGPBService(id, data) {
    return axios.post(API_GPB_URL + "/edit/" + id, data);
}

export function getCitationService(id) {
    return axios.get(API_CITATION_URL + "/info/" + id);
}

export function getCitationServices(orderId, page, params) {
    return axios.get(API_CITATION_URL + "/order/" + orderId + (page > 1 ? "?page=" + page : "") + (params && params.done ? (page > 1 ? "&" : "?") + "done=" + parseInt(params.done) : ""));
}

export function editCitationService(id, data) {
    return axios.post(API_CITATION_URL + "/edit/" + id, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}

export function getArticleService(id) {
    return axios.get(API_ARTICLE_URL + "/info/" + id);
}

export function getArticleServices(orderId, page, params) {
    return axios.get(API_ARTICLE_URL + "/order/" + orderId + (page > 1 ? "?page=" + page : "") + (params && params.build ? (page > 1 ? "&" : "?") + "build=" + parseInt(params.build) : ""));
}

export function editArticleService(id, data) {
    return axios.post(API_ARTICLE_URL + "/edit/" + id, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}



export function getBloggerSales(page, params) {
    return axios.get(API_SALES_URL + (page > 1 ? "?page=" + page : "") + (params && params.build ? (page > 1 ? "&" : "?") + "build=" + parseInt(params.build) : ""));
}

export function editGuestPostStatus(id, data) {
    return axios.post(API_GUEST_POST_URL + "/status/edit/" + id, data);
}

