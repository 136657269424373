import React, {useState} from "react";
import { useEffect } from 'react';
import introJs from 'intro.js';
import "intro.js/introjs.css";
import {saveUserOptions} from "../../crud/user.crud";
import {Button} from "react-bootstrap";
import { withRouter } from "react-router-dom";

function StartTutorial(props) {
    const [showIntro, setShowIntro] = React.useState(false);

    const [stepsEnabled, setStepsEnabled] = React.useState(false);
    const [steps] = React.useState([
        {
            intro: "Welcome to the SERP Pro. Get a quick tour of our platform by clicking next or skip it and do the tour at a later time."
        },
        {
            selector: "#kt-aside",
            title: "Menu",
            element: document.querySelectorAll('#kt-aside')[0],
            intro: "This is your navigation menu. You can view your orders, use our tools and SEO services by clicking on any of the menu items."
        },
        /*{
            selector: "#traffic-analytics-widget",
            title: "Traffic Analytics",
            element: document.querySelectorAll('#traffic-analytics-widget')[0],
            intro: "Track your organic growth with our traffic analytics module. Use our filters to monitor Google Updates or impact of your SEO tasks."
        },*/
        {
            selector: "#my-orders-widget",
            title: "My Orders",
            element: document.querySelectorAll('#my-orders-widget')[0],
            intro: "Quickly view your orders, invoices and keep track of your order progress."
        },
        /*{
            selector: "#tm-gu-widget",
            element: document.querySelectorAll('#tm-gu-widget')[0],
            intro: "Keep a log of your tasks to gain oversight on your campaigns. You can also monitor for upcoming Google updates and algorithm changes."
        },*/
        /*{
            selector: "#onpage-audit-widget",
            title: "Technical SEO Audit",
            element: document.querySelectorAll('#onpage-audit-widget')[0],
            intro: "Start scanning your website for technical SEO errors."
        },*/
    ]);

    useEffect(() => {
      if(showIntro && !stepsEnabled) {
          try {
              const intro = introJs();
              //intro.exit();
              window.setTimeout(()=> {
                  intro.onbeforechange(function (targetElement) {
                      if (intro._options.steps.length !== 0) {
                          for (let i = 0; i < intro._options.steps.length; i++) {
                              const selector = intro._options.steps[i].selector;
                              if(selector) {
                                  const element = document.querySelectorAll(selector)[0];

                                  if (element) {
                                      intro._introItems[i].element = element;
                                      intro._introItems[i].position = intro._options.steps[i].position || 'auto';
                                  }
                              }
                          }
                      }
                  }).oncomplete(function () {
                      const data = {
                          options: [
                              {option: "show_intro_dashboard", value: 0}
                          ],
                      };
                      saveUserOptions(data)
                          .then(() => {
                              props.fulfillUserOptions(data);
                          })
                          .catch(() => {
                              //
                          });
                  }).onexit(function () {
                      setStepsEnabled(false);
                      setShowIntro(false);
                  }).setOptions({
                      'showBullets': false,
                      'skipLabel': "Skip",
                  }).addSteps(steps);
                  intro.start();
              }, 500);
          } catch (e) {
              console.log(e);
          }
          setStepsEnabled(showIntro);
      }
    }, [showIntro]);

    const [path, setPath] = useState(null);

    useEffect(() => {
        if(props.location && path !== props.location.pathname) {
            setPath(props.location.pathname);
            if(props.location.search === "?start_tutorial") {
                setShowIntro(true);
            }
        }
    }, [props.location]);

    if(path == "/projects" || path == "/dashboard") {
        return(<>
            {/*<Button className="kt-ml-10" onClick={()=>{setShowIntro(true);}}>Start Tutorial2</Button>*/}
            <Button href="https://dashboard.serppro.io/projects?__ug__=50751" className="">How it Works</Button>
        </>);
    } else if (path == '/orders/guest-post-dfy') {
        return(<>
            <Button href="https://dashboard.serppro.io/orders/guest-post-dfy?__ug__=50758" className="">How it Works</Button>
        </>);
    } else if (path == '/seo-services/link-insertion') {
        return(<>
            <Button href="https://dashboard.serppro.io/seo-services/link-insertion?__ug__=50758" className="">How it Works</Button>
        </>);
    } else {
        return "";
    }
}

export default withRouter(StartTutorial);
