import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js";
import { useSelector } from "react-redux";
import { metronic } from "../../_metronic";
import {Link} from "react-router-dom";

export default function DoughnutChart({
  value,
  desc,

  data,
  labels,
  colors,
  hideLink,
  colorNames,
  hideZeroValues,
  clickCallback,
  clickParams,
  showPercentage,
  mainWidget= false
}) {
  const canvasRef = useRef();

    const { brandColor, dangerColor, successColor, primaryColor, infoColor, secondaryColor, warningColor, lightColor, darkColor } = useSelector(
        state => ({
            brandColor: metronic.builder.selectors.getConfig(
                state,
                "colors.state.brand"
            ),
            dangerColor: metronic.builder.selectors.getConfig(
                state,
                "colors.state.danger"
            ),
            successColor: metronic.builder.selectors.getConfig(
                state,
                "colors.state.success"
            ),
            primaryColor: metronic.builder.selectors.getConfig(
                state,
                "colors.state.primary"
            ),
            infoColor: metronic.builder.selectors.getConfig(
                state,
                "colors.state.info"
            ),
            secondaryColor: metronic.builder.selectors.getConfig(
                state,
                "colors.state.secondary"
            ),
            warningColor: metronic.builder.selectors.getConfig(
                state,
                "colors.state.warning"
            ),
            lightColor: metronic.builder.selectors.getConfig(
                state,
                "colors.state.light"
            ),
            darkColor: metronic.builder.selectors.getConfig(
                state,
                "colors.state.dark"
            ),
        })
    );
    const defaultColors = [dangerColor, warningColor, brandColor, infoColor, successColor, primaryColor, lightColor, darkColor, secondaryColor ];
    if(!colors) {
        if(colorNames && colorNames.length !== 0) {
            colors = [];
            for(let i = 0; i < colorNames.length; i++)
            {
                colors.push(defaultColors[colorNames[i]]);
            }
        }
        else {
            colors = defaultColors;
        }
    }

  useEffect(() => {
    const config = {
      type: "doughnut",
      data: {
        labels: labels,
        datasets: [
          {
            backgroundColor: colors,
            label: '',
            data: data
          }
        ]
      },
      options:{
            cutoutPercentage:75,
            responsive:true,
            maintainAspectRatio:false,
            legend:{
              display:false,
              position:"top"
            },
            title:{
              display:false,
              text:""
            },
            animation:{
              animateScale:true,
              animateRotate:true
            },
            tooltips:{
                enabled:true,
                intersect:false,
                mode:"nearest",
                bodySpacing:5,
                yPadding:10,
                xPadding:10,
                caretPadding:0,
                displayColors:false,
                backgroundColor:brandColor,
                titleFontColor:"#ffffff",
                cornerRadius:4,
                footerSpacing:0,
                titleSpacing:0
          }
      }
    };

    const chart = new Chart(canvasRef.current, config);

    return () => {
      chart.destroy();
    };
  }, [data, colors, labels, brandColor]);

    const legend = [];

    for (const [index] of data.entries()) {
        if(labels[index] && !(hideZeroValues && data[index] === 0)) {
            legend.push(<div key={index} className="kt-widget14__legend" style={{cursor:(clickCallback && clickParams[index] ? "pointer" : "inherit")}} onClick={()=>{if(clickCallback && clickParams[index]){
                clickCallback(clickParams[index]);
            }
            }}>
                <span className="kt-widget14__bullet" style={{backgroundColor: colors[index]}}/>
                <span className="kt-widget14__stats">{data[index] + (showPercentage ? "%" : "")} {labels[index]}</span>
            </div>)
        }
    }

  return (
    <div className="kt-widget14">
        {desc !== false && <div className="kt-widget14__header">
            <span className="kt-widget14__desc">
				{desc}
			</span>
        </div>}
        <div className={"kt-widget14__content" + (mainWidget ? " d-xl-block seo-errors" : "")}>
            <div className={"kt-widget14__chart" + (mainWidget ? " kt-mb-15" : "")}>
                <div className="kt-widget14__stat">{value}</div>
                <canvas ref={canvasRef} id="kt_chart_profit_share" style={{ width: "140px", height: "140px" }} />
            </div>
            <div className="kt-widget14__legends">
                {legend}
            </div>
        </div>
        {!hideLink && <div className="text-center kt-margin-5">
            <Link to="/dashboard">View All</Link>
        </div>}
    </div>
  );
}
