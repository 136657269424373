import React from "react";
import {Route, Switch} from "react-router-dom";
import SeoAgreementTemplate from "./SeoAgreementTemplate";

export default function MorePage() {
    return (
        <Switch>
            <Route
                path="/more/seo-agreement-template"
                component={SeoAgreementTemplate}
            />
        </Switch>
    );
}
