import axios from "axios";

export const base = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL : "";
export const API_OFFER_URL = base + "/api/admin/offers";
export const API_OFFER_ACCEPT_URL = base + "/api/admin/offer/accept";
export const API_OFFER_REJECT_URL = base + "/api/admin/offer/reject";
export const API_OFFER_COUNTER_URL = base + "/api/admin/offer/counter";
export const API_GET_OFFER_URL = base + "/api/admin/offer";

export function getOffers(page = null) {
    return axios.get(API_OFFER_URL + (page > 1 ? "?page=" + page : ""));
}

export function rejectOffer(offer_id) {
    return axios.post(API_OFFER_REJECT_URL, { offer_id });
}

export function acceptOffer(offer_id) {
    return axios.post(API_OFFER_ACCEPT_URL, { offer_id });
}

export function counterOffer(offer_id, counter_amount) {
    return axios.post(API_OFFER_COUNTER_URL, { offer_id, counter_amount });
}

export function getOffer(offer_id) {
    return axios.post(API_GET_OFFER_URL, { offer_id });
}