import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {Form, Button} from "react-bootstrap";
import {Spinner} from "reactstrap";
import Select from "react-select";

class VideoTutorialModal extends React.Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {

  	}

    render() {
        const { open, onClose } = this.props;

        const selectStyles = {
	        menu: styles => ({ ...styles, zIndex: 999 }),
	        control: (provided, state) => ({
	            ...provided,
	            minHeight: '47px',
	        }),
	    };

        const handleClose = () => {
            onClose();
        };

       
        // {<meta name="serppro-verification" content="d62589086a8469d043053d1574f3d7f3">}
        return (<Dialog open={open} onClose={handleClose} fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle>Video Tutorial</DialogTitle>
            <DialogContent dividers>
                <div
                    dangerouslySetInnerHTML={{
                        __html:'<iframe style="max-width: 100%; margin: 0 auto 5px; display:block;" width="100%" height="400" src="https://www.youtube.com/embed/SPxgDkbAlfI" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>'
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
                
            </DialogActions>
        </Dialog>);
    }
}

VideoTutorialModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default injectIntl(connect(

)(VideoTutorialModal));
