import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {getPageData} from "../../crud/page.crud";

export const actionTypes = {
    PageDataRequested: "[Request PageData] Action",
    PageDataLoaded: "[Load PageData] Projects API",
};

const initialPageDataState = {
    pageData: undefined
};

export const reducer = persistReducer(
    { storage, key: "demo3-page", whitelist: ["pageData"] },
    (state = initialPageDataState, action) => {
        switch (action.type) {
            case actionTypes.PageDataRequested: {
                return {...state, pageData: undefined};
            }

            case actionTypes.PageDataLoaded: {
                const {pageData} = action.payload;

                return {...state, pageData};
            }

            default:
                return state;
        }
    }
);

export const actions = {
    requestPageData: page => ({ type: actionTypes.PageDataRequested, payload: { page } }),
    fulfillPageData: pageData => ({ type: actionTypes.PageDataLoaded, payload: { pageData } }),
};

export function* saga() {
    yield takeLatest(actionTypes.PageDataRequested, function* pageDataRequested(action) {
        const { page } = action.payload;
        try {
            const {data: pageData} = yield getPageData(page);

            yield put(actions.fulfillPageData({...pageData, name: page}));
        }
        catch (e) {
            yield put(actions.fulfillPageData({name: page}));
        }
    });
}
