import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {
    getFilteredPages,
    getSummary,
    getRelatedPages,
    getLinks,
    getAnchorsData,
    getPageLimits
} from "../../crud/onpage.crud";

export const actionTypes = {
    SummaryInit: "[Init Summary] Action",
    SummaryRequested: "[Request Summary] Action",
    SummaryLoaded: "[Load Summary] OnPage API",
    FilteredPagesRequested: "[Filter Pages] Action",
    FilteredPagesLoaded: "[Filter Pages] OnPage API",
    RelatedPagesRequested: "[Related Pages] Action",
    RelatedPagesLoaded: "[Related Pages] OnPage API",
    LinksRequested: "[Links] Action",
    LinksLoaded: "[Links] OnPage API",
    AnchorsRequested: "[Anchors] Action",
    AnchorsLoaded: "[Anchors] OnPage API",
    PageLimitsRequested: "[Page Limits] Action",
    PageLimitsLoaded: "[Page Limits] OnPage API",
};

const initialAuditState = {
    summary: undefined,
    filteredPages: undefined,
    relatedPages: undefined,
    links: undefined,
    anchors: undefined,
    pageLimits: undefined,
};

export const reducer = persistReducer(
    { storage, key: "demo3-audit", whitelist: ["summary", "filteredPages", "relatedPages", "links", "anchors", "pageLimits"] },
    (state = initialAuditState, action) => {
        switch (action.type) {
            case actionTypes.SummaryInit: {
                return { ...state, summary: undefined, filteredPages: {}, relatedPages: undefined, links: undefined, anchors: undefined };
            }

            case actionTypes.SummaryLoaded: {
                const { summary } = action.payload;

                return { ...state, summary };
            }

            case actionTypes.FilteredPagesRequested: {
                const { key } = action.payload;

                return { ...state, filteredPages: {...state.filteredPages, [key]: undefined} };
            }

            case actionTypes.FilteredPagesLoaded: {
                const { key, item } = action.payload;

                return { ...state, filteredPages: {...state.filteredPages, [key]: item} };
            }

            case actionTypes.RelatedPagesRequested: {
                return { ...state, relatedPages: undefined };
            }

            case actionTypes.RelatedPagesLoaded: {
                const { item } = action.payload;

                return { ...state, relatedPages: item };
            }

            case actionTypes.LinksRequested: {
                return { ...state, links: undefined };
            }

            case actionTypes.LinksLoaded: {
                const { links } = action.payload;

                return { ...state, links: links };
            }

            case actionTypes.AnchorsRequested: {
                return { ...state, anchors: undefined };
            }

            case actionTypes.AnchorsLoaded: {
                const { anchors } = action.payload;

                return { ...state, anchors: anchors };
            }

            case actionTypes.PageLimitsRequested: {
                return { ...state, pageLimits: undefined };
            }

            case actionTypes.PageLimitsLoaded: {
                const { pageLimits } = action.payload;

                return { ...state, pageLimits: pageLimits };
            }

            default:
                return state;
        }
    }
);

export const actions = {
    initSummary: projectId => ({ type: actionTypes.SummaryInit, payload: { projectId } }),
    requestSummary: projectId => ({ type: actionTypes.SummaryRequested, payload: { projectId } }),
    fulfillSummary: summary => ({ type: actionTypes.SummaryLoaded, payload: { summary } }),
    requestFilteredPages: (projectId, page, key, filter) => ({ type: actionTypes.FilteredPagesRequested, payload: { projectId, page, key, filter } }),
    fulfillFilteredPages: (key, item) => ({ type: actionTypes.FilteredPagesLoaded, payload: { key, item } }),
    requestRelatedPages: (projectId, page, key, pageId) => ({ type: actionTypes.RelatedPagesRequested, payload: { projectId, page, key, pageId } }),
    fulfillRelatedPages: (item) => ({ type: actionTypes.RelatedPagesLoaded, payload: { item } }),
    requestLinks: (projectId, page, type, pageFrom, keyword) => ({ type: actionTypes.LinksRequested, payload: { projectId, page, type, pageFrom, keyword } }),
    fulfillLinks: links => ({ type: actionTypes.LinksLoaded, payload: { links } }),
    requestAnchors: (projectId, page) => ({ type: actionTypes.AnchorsRequested, payload: { projectId, page } }),
    fulfillAnchors: anchors => ({ type: actionTypes.AnchorsLoaded, payload: { anchors } }),
    requestPageLimits: () => ({ type: actionTypes.PageLimitsRequested }),
    fulfillPageLimits: pageLimits => ({ type: actionTypes.PageLimitsLoaded, payload: { pageLimits } }),
};

export function* saga() {
    yield takeLatest(actionTypes.SummaryInit, function* initSummarySaga(action) {
        const { projectId } = action.payload;
        yield put(actions.requestSummary(projectId));
    });

    yield takeLatest(actionTypes.SummaryRequested, function* summaryRequested(action) {
        try {
            const { projectId } = action.payload;
            const { data: summary } = yield getSummary(projectId);

            yield put(actions.fulfillSummary(summary));
        }
        catch (e) {
            yield put(actions.fulfillSummary({data: undefined}));
        }
    });

    yield takeLatest(actionTypes.FilteredPagesRequested, function* filteredPagesRequested(action) {
        const { key, projectId, page, filter } = action.payload;
        try {
            const { data: pages } = yield getFilteredPages(projectId, page, key, filter);

            yield put(actions.fulfillFilteredPages(key, pages));
        }
        catch (e) {
            yield put(actions.fulfillFilteredPages(key, {data: undefined}));
        }
    });

    yield takeLatest(actionTypes.RelatedPagesRequested, function* relatedPagesRequested(action) {
        const { key, projectId, page, pageId } = action.payload;
        try {
            const { data: pages } = yield getRelatedPages(projectId, page, key, pageId);

            yield put(actions.fulfillRelatedPages(pages));
        }
        catch (e) {
            yield put(actions.fulfillRelatedPages({data: undefined}));
        }
    });

    yield takeLatest(actionTypes.LinksRequested, function* linksRequested(action) {
        const { projectId, page, type, pageFrom, keyword } = action.payload;
        const {data: links} = yield getLinks(projectId, page, type, pageFrom, keyword);

        yield put(actions.fulfillLinks(links));
    });

    yield takeLatest(actionTypes.AnchorsRequested, function* anchorsRequested(action) {
        const { projectId, page } = action.payload;
        const {data: anchors} = yield getAnchorsData(projectId, page);

        yield put(actions.fulfillAnchors(anchors));
    });

    yield takeLatest(actionTypes.PageLimitsRequested, function* pageLimitsRequested(action) {
        const {data: pageLimits} = yield getPageLimits();

        yield put(actions.fulfillPageLimits(pageLimits));
    });
}
