import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import {Portlet, PortletBody} from "../../partials/content/Portlet";
//import CreateProject from "./projects-components/CreateProject";
//import ProjectsList from "./projects-components/ProjectsList";
import ProjectSelect from "./projects-components/ProjectSelect";
import ProjectDetails from "./projects-components/ProjectDetails";
import "../../../_metronic/_assets/sass/custom/projects.scss";
import {Link, Redirect} from "react-router-dom";
import * as pagedata from "../../store/ducks/page.duck";
// import * as projects from "../../store/ducks/projects.duck";
import * as utils from "../../../_metronic/utils/utils";

class Projects extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
        const pageName = 'dashboard';
        this.props.requestPageData(pageName);

        // var voiceflow_script = document.getElementById('voiceflow_script');
        // if (voiceflow_script == null) {
        //     let script = document.createElement("script");
        //     script.src = "https://cdn.voiceflow.com/widget/bundle.mjs";
        //     // script.defer = true;
        //     script.id = "voiceflow_script";
        //     document.body.appendChild(script);
        //     script.onload = function() {
        //         var already_run;
        //         if (!already_run) {
        //             window.voiceflow.chat.load({
        //                 verify: { projectID: '64c97bede9b8e900077093cd' },
        //                 url: 'https://general-runtime.voiceflow.com',
        //                 versionID: 'production'
        //             });
        //             already_run = true;
        //         }
        //     }
        // }
    }

    render() {
        // const { user, showHi, showAvatar, showBadge, pageData, projects } = this.props;
        const { user, showHi, showAvatar, showBadge, pageData } = this.props;

        const action = this.props.match && this.props.match.params ? this.props.match.params.action : "";

        const announcement_rows = [];
        if (user.announcements) {
            for (const [index] of user.announcements.entries()) {
                let xAnnouncement = user.announcements[index];
                announcement_rows.push(<Link to={xAnnouncement.link_to} style={{textAlign:"center", color:"#000", borderTop:"2px solid #e9e9e9", marginLeft:"-25px", marginRight:"-25px", paddingLeft:"40px", paddingRight:"40px", paddingTop:"40px", paddingBottom:"40px", fontSize:"18px"}}>{xAnnouncement.title}</Link>);
            }
        }

        let youtube_video = "";
		if (user.youtube_video) {
			youtube_video = user.youtube_video;
		}

        // const scriptCode = `
        // <script type="text/javascript">
        //     (function(d, t) {
        //         var v = d.createElement(t), s = d.getElementsByTagName(t)[0];
        //         v.onload = function() {
        //             window.voiceflow.chat.load({
        //             verify: { projectID: '64c97bede9b8e900077093cd' },
        //             url: 'https://general-runtime.voiceflow.com',
        //             versionID: 'production'
        //             });
        //         }
        //         v.src = "https://cdn.voiceflow.com/widget/bundle.mjs"; v.type = "text/javascript"; s.parentNode.insertBefore(v, s);
        //     })(document, 'script');
        // </script>`;

        // console.log('projects',projects);
        // if (typeof projects !== 'undefined' && typeof projects.meta !== 'undefined') {
        //     console.log('PROJECT COUNT',projects.meta.total);
        //     if (projects && projects.meta && projects.meta.total === 0) {
        //         return <Redirect to="/project/add" />;
        //     }
        // }

        // console.log('just_logged_in',utils.getStorage('just_logged_in'));
        // console.log('jli_redirect_to',utils.getStorage('jli_redirect_to'));
        if (utils.getStorage('just_logged_in') == 'yes' && utils.getStorage('jli_redirect_to') == '/project/add') {
            utils.removeStorage('just_logged_in');
            utils.removeStorage('jli_redirect_to');
            return <Redirect to="/project/add" />;
        }


        return (
            <>
                <div className="row kt-mt-10">
                    <div className="col-12">
                        <div className="progress progressBar TmoProgressBar" style={{marginTop:"8px"}}>
                            <div className="progress-bar" role="progressbar" aria-valuenow={(user.tmo_amount ? user.tmo_amount : 0)} aria-valuemin="0" aria-valuemax={(user.tmo_progress_bar_total ? user.tmo_progress_bar_total : 10000)} style={{width:(user.tmo_percentage ? user.tmo_percentage : 0)+'%'}}>
                                <span class="progress-label">{(user.tmo_amount ? '$'+user.tmo_amount.toLocaleString(undefined, {maximumFractionDigits:2}) : '$0.00')}</span>
                            </div>
                            <div class="first_label">This Month's Spend</div>
                            <div class="second_label">
                                <div class="vertical_line">|</div>
                                $1,000
                            </div>
                            <div class="third_label">
                                <div class="vertical_line">|</div>
                                $5,000
                            </div>
                            <div class="fourth_label">
                                <div class="vertical_line">|</div>
                                $10,000
                            </div>
                        </div>
                    </div>

                    {(typeof pageData !== 'undefined' && typeof pageData.top_left_text !== 'undefined' && typeof pageData.top_right_text !== 'undefined' && pageData.top_left_text != '' && pageData.top_right_text != '' ? 
                        <div className="col-12 mt-2 mb-4">
                            <Portlet className="" style={{background:"#f2f3f8", boxShadow:"none"}}>
                                <div className="row" style={{marginLeft:0,marginRight:0}}>
                                    <div className="col-lg-9 col-12" style={{paddingLeft:0,paddingRight:0}}>
                                        <Portlet className="h-100 kt-mb-0 marketplace_left_blue" style={{background:"#ecf3fe"}}>
                                            <PortletBody dangerouslySetInnerHTML={{ __html: pageData ? pageData.top_left_text : "" }} />
                                        </Portlet>
                                    </div>
                                    <div className="col-lg-3 col-12" style={{paddingLeft:0,paddingRight:0}}>
                                        <Portlet className="h-100 kt-mb-0 marketplace_right_green" style={{background:"#e7eff3"}}>
                                            <PortletBody dangerouslySetInnerHTML={{ __html: pageData ? pageData.top_right_text : "" }} />
                                        </Portlet>
                                    </div>
                                </div>
                            </Portlet>
                        </div>
                    : '')}

                    <div className="col-lg-6 col-md-6 col-sm-12">
                        {(youtube_video != "" ? 
                            <div className="mb-4">
                                <h1 className="custom-header kt-mb-25">Hello {user.firstname}</h1>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html:'<iframe style="max-width: 100%; margin: 0 auto 5px; display:block;" width="100%" height="400" src="' + youtube_video + '" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>'
                                    }}
                                />
                                <div style={{fontWeight: "600", textAlign: "center"}}>
                                    {'\u261d'} Hey {user.firstname}, can I give you a tour?
                                </div>
                            </div>
                        : "")}
                        {/*<h1 className="custom-header kt-mb-25 kt-mt-25">&nbsp;</h1>*/}
                        <Portlet>
                            <PortletBody>
                                <h2 className="custom-header gray kt-mb-25 text-center">Create or Choose A SEO Campaign</h2>
                                <div className="">
                                    <ProjectSelect action={action} history={this.props.history} hideTitle={true} />
                                    {/* <div className="row justify-content-between kt-mb-15">
                                        <div className="col-sm-6 kt-mb-5"><Link className="kt-visible-desktop col btn btn-success kt-padding-15-desktop" to="/marketplace">Connect with Bloggers</Link></div>
                                        <div className="col-sm-6 kt-mb-5"><Link className="kt-visible-desktop col btn btn-outline-success btn-font-dark kt-padding-15-desktop" to="/orders/new">Start Campaign Wizard</Link></div>
                                        <div className="col-sm-6 kt-mb-5">
                                            <Link className="kt-visible-desktop col btn btn-outline-success btn-font-dark kt-padding-15-desktop" to={{ pathname: "https://serppro.io/help-center" }} target="_blank">Help Center</Link>
                                        </div>
                                    </div> */}
                                </div>
                                <ProjectDetails />
                            </PortletBody>
                        </Portlet>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <Portlet>
                            <PortletBody style={{paddingBottom:"0px"}}>
                                <h2 className="custom-header gray kt-mb-25 text-center">Announcements</h2>
                                {announcement_rows}
                            </PortletBody>
                        </Portlet>
                    </div>
                </div>
                {/* <div dangerouslySetInnerHTML={{ __html:scriptCode }} /> */}
            </>
        );
    }
}

// const mapStateToProps = ({ auth: { user }, pagedata: {pageData}, projects: { projects } }) => ({
//   user, pageData, projects
// });

// export default injectIntl(connect(
//     mapStateToProps,
//     {...pagedata.actions, ...projects.actions}
// )(Projects));
const mapStateToProps = ({ auth: { user }, pagedata: {pageData} }) => ({
    user, pageData
  });
  
  export default injectIntl(connect(
      mapStateToProps,
      {...pagedata.actions}
  )(Projects));
// export default connect(mapStateToProps)(Projects);