import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import {Portlet, PortletBody, PortletHeader} from "../../partials/content/Portlet";
import {Table, Badge} from "react-bootstrap";
import {Button} from "react-bootstrap";
import * as marketplace from "../../store/ducks/marketplace.duck";
import {Spinner} from "reactstrap";
import * as cart from "../../store/ducks/cart.duck";
import * as pagedata from "../../store/ducks/page.duck";
import AddOnModal from "./AddOnModal"
import UPagination from "../../partials/content/Pagination";
// import {removeWatchlistItem} from "../../crud/user.crud";

class Favorites extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            openAddOns: false,
            addOnItems: "",
            addedToCartItemHolder: "",
        }
    }

    componentDidMount() {
        const page = 1;
        this.props.requestMyWatchlist(page);
        this.props.requestPageData('marketplace');
    }

    addToCartById(e, type, guestPost) {
        // let guestPost = this.props.guestPostItem && this.props.guestPostItem["dns" + id] && this.props.guestPostItem["dns" + id].data ? this.props.guestPostItem["dns" + id].data : {};

        if (type == 'domain') {
            if (guestPost && guestPost.id) {
                const inCart = this.props.cartItems.filter(item => item.key === "dns_" + guestPost.id).length !== 0;

                if(!inCart) {
                    const item = {
                        key: "dns_" + guestPost.id,
                        price: Number(guestPost.binPrice),
                        name: "Domain Marketplace",
                        description: "Domain: " + guestPost.domainName,
                        orderData: {
                            subscription: 0,
                            quantity: 1,
                            type: "domain_name_sale",
                            options: {
                                price: Number(guestPost.binPrice),
                                site_id: guestPost.id,
                                domain_name: guestPost.domainName
                            }
                        },
                        add_ons: [
                        ]
                    };

                    this.props.addToCart(item);

                    // ReactGA.event({
                    //     category: 'Cart',
                    //     action: 'AddToCart'
                    // });
                }
            }
        } else if (type == 'backlink') {
            // let guestPost = {};
            // for (const [index] of guestPosts.data.entries()) {
            //     if(guestPosts.data[index].id === parseInt(id)) {
            //         guestPost = guestPosts.data[index];
            //     }
            // }

            // const categoryName = categories["c" + guestPost.category_id];

            const item = {
                key: "mgp_" + guestPost.id,
                price: Number(guestPost.price),
                name: "Marketplace Guest Post",
                description: "DA: " + guestPost.da,
                orderData: {
                    subscription: 0,
                    quantity: 1,
                    type: "marketplace_guest_post",
                    options: {
                        site_id: guestPost.id,
                        marketplace_article: 1,
                    },
                    add_ons: [
                    //     {
                    //         add_on_id: add_on_id,
                    //         add_on_name: add_on_name,
                    //         add_on_price: add_on_price
                    //     }
                    ],
                }
            };

            // this.props.addToCart(item);
            this.setState({...this.state, openAddOns: true, addOnItems: this.addOnItems, addedToCartItemHolder: item});
        }
    }

    render() {
        const { user, watchlistArray, cartItems, userDiscountPercent, discountDate, currentDate, pageData} = this.props;

        if (pageData != null) {
            if (pageData.add_ons != null) {
                this.addOnItems = pageData.add_ons;
            }
        }

        const addOnsClose = (e) => {
            this.setState({...this.state, openAddOns: false});
        }

        const handleNextPage = (number) => {
            this.props.requestMyWatchlist(number);
            this.setState({
                ...this.state,
                selected: []
            });
        };

        if (!watchlistArray) {
            return <Spinner/>;
        }

        const rows = [];
        if (watchlistArray) {

            if (watchlistArray?.data.length) {

                for (const [index] of watchlistArray.data.entries()) {

                    let inCart = false;
                    let find_url = '';
                    let type = '';
                    if (typeof watchlistArray.data[index].domainName !== 'undefined') {
                        find_url = watchlistArray.data[index].domainName;
                        type = 'domain';
                        if(cartItems) {
                            inCart = cartItems.filter(item => item.key === "dns_" + watchlistArray.data[index].id).length !== 0;
                        }

                    } else if (typeof watchlistArray.data[index].url !== 'undefined') {
                        find_url = watchlistArray.data[index].url;
                        type = 'backlink';

                        if(cartItems) {
                            inCart = cartItems.filter(item => item.key === "mgp_" + watchlistArray.data[index].id).length !== 0;
                        }
                    }

                    rows.push(<tr key={index}>
                        <td>{watchlistArray.data[index].type}</td>
                        <td>{find_url}</td>
                        <td>
                            <Button disabled={inCart} className="btn-sm" onClick={(e) => this.addToCartById(e, type, watchlistArray.data[index])}>
                                {inCart == true ? 'Added to Cart' : 'Add to Cart'}
                            </Button>
                        </td>
                    </tr>);
                }
            }
        }

        return (
        <>
            <Portlet>
                <PortletHeader title="My Favorites" />
                <PortletBody className="">
                    <Table striped bordered hover responsive>
                        <thead>
                        <tr>
                            <th>Type</th>
                            <th>Site</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        { rows }
                        </tbody>
                    </Table>
                    { watchlistArray && watchlistArray?.last_page > 1 && <UPagination currentPage={watchlistArray.current_page} totalPages={watchlistArray.last_page} onChange={handleNextPage} />}
                </PortletBody>
            </Portlet>
            <AddOnModal open={this.state.openAddOns} onClose={addOnsClose} addOnItems={this.state.addOnItems} item={this.state.addedToCartItemHolder} />
        </>
        );
    }
}

const mapStateToProps = ({ auth: { user }, marketplace: { watchlistArray }, cart: {cartItems, userDiscountPercent, discountDate, currentDate}, pagedata: {pageData} }) => ({
  user, watchlistArray, cartItems, userDiscountPercent, discountDate, currentDate, pageData
});

export default injectIntl(connect(
    mapStateToProps,
    {...cart.actions, ...marketplace.actions, ...pagedata.actions}
)(Favorites));
