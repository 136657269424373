import React, {useEffect} from "react";
import {Portlet, PortletBody, PortletHeader} from "../../../partials/content/Portlet";
import {
    Slider,
    Typography,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Checkbox,
    List,
    ListItem,
    ListItemText,
    Stepper, Step, StepLabel
} from "@material-ui/core";
import {Card, Button, Form} from "react-bootstrap";
import { useFormik } from "formik";
import { useHistory, Link } from 'react-router-dom';
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as cart from "../../../store/ducks/cart.duck";
import * as pagedata from "../../../store/ducks/page.duck";
import * as packages from "../../../store/ducks/packages.duck";
import {Spinner} from "reactstrap";
import CheckoutProductPackageModal from "../../../includes/CheckoutProductPackageModal";
import {calculateCartDiscountAmount} from "../../../custom_functions/guest_posts";
import GuestPostsInfo from "../../../includes/GuestPostsInfo";
import AddOnModal from "../AddOnModal"
import Select from "react-select";

import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';


const selectStyles = { menu: styles => ({ ...styles, zIndex: 999, position: "relative", textAlign:'left' }) };


const minDA = 30;
const maxDA = 80;
const stepDA = 10;
const minOT = 1;
const maxOT = 6;
const minQ = 1;
const maxQ = 100;
const stepQ = 1;
const defaultLanguage = "English";
const defaultTld = ".nl";

const marksDA = [
    {
        value: minDA,
        label: minDA.toString(),
    },
    {
        value: maxDA,
        label: maxDA.toString(),
    },
];
const marksOT = [
    {
        value: 1,
        label: "1,000+"
    },
    {
        value: 2,
        label: "10,000+",
    },
    {
        value: 3,
        label: "50,000+",
    },
    {
        value: 4,
        label: "100,000+",
    },
    {
        value: 5,
        label: "500,000+",
    },
    {
        value: 6,
        label: "1,000,000+",
    },
];
const marksQ = [
    {
        value: minQ,
        label: minQ.toString(),
    },
    {
        value: maxQ,
        label: maxQ.toString(),
    },
];

// ALTER TABLE `link_insertions` ADD `language` VARCHAR(20) NULL AFTER `add_dfy_anchor_text`, ADD `tld` VARCHAR(10) NULL AFTER `language`

const languageOptions = [
    {value:"English", label:"English"},
    {value:"Dutch", label:"Dutch"},
    {value:"Spanish", label:"Spanish"},
    {value:"French", label:"French"},
    {value:"German", label:"German"},
    {value:"Portuguese", label:"Portuguese"},
    {value:"Italian", label:"Italian"},
];
let defaultLanguageOption = {value:defaultLanguage, label:defaultLanguage};

const tldOptions = [
    {value:".nl", label:".nl"},
    {value:".es", label:".es"},
    {value:".fr", label:".fr"},
    {value:".de", label:".de"},
    {value:".pt", label:".pt"},
    {value:".br", label:".br"},
    {value:".it", label:".it"},
    {value:".uk", label:".uk"},
    {value:".co.au", label:".co.au"},
    {value:".ca", label:".ca"},
];
let defaultTldOption = {value:defaultTld, label:defaultTld};

function valuetextDA(value) {
    return `${value}+ DR`;
}
function valuetextOT(value, ending_text_only = false) {
    var ending_string = 'Monthly Domain Traffic';
    if (ending_text_only == true) ending_string = '';
    if (value==1) return marksOT[value-1].label+' '+ending_string;
    if (value==2) return marksOT[value-1].label+' '+ending_string;
    if (value==3) return marksOT[value-1].label+' '+ending_string;
    if (value==4) return marksOT[value-1].label+' '+ending_string;
    if (value==5) return marksOT[value-1].label+' '+ending_string;
    if (value==6) return marksOT[value-1].label+' '+ending_string;
    if(!value) {
        return '1,000'+' '+ending_string;
    }
    return `${value} Monthly Domain Traffic`;
}
function valuetextQ(value) {
    return `${value} posts`;
}
function getValueTld(value) {
    return `${value} TLD`;
}
function getValueLangeuage(value) {
    return `${value}`;
}

function ForeignLinkInsertion(props) {
    const history = useHistory();
    const [dfyAnchorText, setDfyAnchorText] = React.useState(false);
    const [advancedNiche, setAdvancedNiche] = React.useState(false);
    const [valueDA, setValueDA] = React.useState(minDA);
    const [valueLanguage, setValueLanguage] = React.useState(defaultLanguage);
    const [valueTld, setValueTld] = React.useState(defaultTld);
    const [valueOT, setValueOT] = React.useState(minOT);
    const [valueQ, setValueQ] = React.useState(minQ);
    const [valueP, setValueP] = React.useState("purchase");
    const [price, setPrice] = React.useState(0);
    const [showCheckoutPackage, setShowCheckoutPackage] = React.useState(false);
    const [valueType, setValueType] = React.useState("ot");

    const [openAddOns, setOpenAddOns] = React.useState(false);
    const [addedToCartItemHolder, setAddedToCartItemHolder] = React.useState("");

    useEffect(() => {
        const pageName = 'international_link_insertions';
        const type = 'price_package_link_insertion';
        props.requestPageData(pageName);
        props.requestUserDiscount();
        props.requestPackage(type);
        props.requestPackagePreimumMembership("marketplace_guest_post_access");
    }, []);
    const {user, pageData, userDiscountPercent: userDiscount, currentPackage} = props;

    let addOnItems = "";
    if (pageData != null) {
        if (pageData.add_ons != null) {
            addOnItems = pageData.add_ons;
        }
    }
    const addOnsClose = (e) => {
        setOpenAddOns(false);
    }

    let premiumMembershipPackage = undefined;
    if (currentPackage.premiumMembershipPackage) {
        premiumMembershipPackage = currentPackage.premiumMembershipPackage;
    }

    const handleChangeLanguage = (newValue) => {
        setValueLanguage(newValue);
    }
    const handleChangeTld = (newValue) => {
        setValueTld(newValue);
    }
    const handleDfyAnchorText = (event) => {
        setDfyAnchorText(event.target.checked);
        setPrice(calcPrice().calculatedPrice);
    };
    const handleAdvancedNiche = (event) => {
        setAdvancedNiche(event.target.checked);
        setPrice(calcPrice().calculatedPrice);
    };
    const handleSliderChangeDA = (event, newValue) => {
        setValueDA(newValue);
        setPrice(calcPrice().calculatedPrice);
    };
    const handleSliderChangeOT = (event, newValue) => {
        setValueOT(newValue);
        setPrice(calcPrice().calculatedPrice);
    };
    const handleSliderChangeQ = (event, newValue) => {
        setValueQ(newValue);
        setPrice(calcPrice().calculatedPrice);
    };
    const handleChangeP = (event) => {
        setValueP(event.target.value);
        setPrice(calcPrice().calculatedPrice);
    };
    const calcPrice = () => {
        let newPrice = 0;
        let itemPrice = 0;
        let discount = 0;
        let discountAmount = 0;

        if(currentPackage && currentPackage.currentPackage) {
            if (dfyAnchorText) {
                newPrice += currentPackage.currentPackage["dfy_anchor_text"];
            }
            if (advancedNiche) {
                newPrice += currentPackage.currentPackage["advanced_niche"];
            }
            if (typeof currentPackage.currentPackage["language" + valueLanguage] !== 'undefined') {
                newPrice += currentPackage.currentPackage["language" + valueLanguage];
            }
            if (typeof currentPackage.currentPackage["tld" + valueTld] !== 'undefined') {
                newPrice += currentPackage.currentPackage["tld" + valueTld];
            }

            if (valueType == 'ot') {
                newPrice += currentPackage.currentPackage["ot" + valueOT];
            } else if (valueType == 'da') {
                newPrice += currentPackage.currentPackage["da" + valueDA];
            }
        }

        itemPrice = newPrice;


        //////////////////////////////////////////////
        // START -> 5% subscription discount
        let isPremiumMemberCheck = false;
        if (typeof premiumMembershipPackage !== 'undefined') {
            if (typeof premiumMembershipPackage.activePackage !== 'undefined') {
                if (premiumMembershipPackage.activePackage.name != "Free") {
                    isPremiumMemberCheck = true;
                }
            }
        }
        if (valueP === "subscription") {
            newPrice = (parseFloat(itemPrice) * parseFloat(0.95));
        }
        // END -> 5% subscription discount
        //////////////////////////////////////////////


        newPrice *= valueQ;

        if (valueType == 'ot') {
            const item = {
                orderData: {
                    subscription: (valueP === "subscription" ? 1 : 0),
                    quantity: valueQ,
                    type: "link_insertion",
                    options: {
                        ot: valueOT,
                        language: valueLanguage,
                        tld: valueTld,
                        advanced_niche: advancedNiche ? "yes" : "no",
                        dfy_anchor_text: dfyAnchorText ? "yes" : "no"
                    },
                    add_ons: [],
                },
                price: itemPrice
            };

            discountAmount = calculateCartDiscountAmount([item], userDiscount);
            newPrice -= discountAmount;

        } else if (valueType == 'da') {
            const item = {
                orderData: {
                    subscription: (valueP === "subscription" ? 1 : 0),
                    quantity: valueQ,
                    type: "link_insertion",
                    options: {
                        da: valueDA,
                        language: valueLanguage,
                        tld: valueTld,
                        advanced_niche: advancedNiche ? "yes" : "no",
                        dfy_anchor_text: dfyAnchorText ? "yes" : "no"
                    },
                    add_ons: [],
                },
                price: itemPrice
            };

            discountAmount = calculateCartDiscountAmount([item], userDiscount);
            newPrice -= discountAmount;
        }

        return {itemPrice: itemPrice, calculatedPrice: newPrice, discountAmount: discountAmount};
    };

    const {itemPrice, calculatedPrice, discountAmount} = calcPrice();

    const formik = useFormik({
        initialValues: {
            add_to_cart: 0
        },
        validate: values => {
            const errors = {};

            return errors;
        },
        onSubmit: (values, { setStatus, setSubmitting }) => {

            if (valueType == 'ot') {
                let orderData = {};
                orderData[0] = {
                    subscription: (valueP === "subscription" ? 1 : 0),
                    quantity: valueQ,
                    type: "link_insertion",
                    options: {
                        ot: valueOT,
                        language: valueLanguage,
                        tld: valueTld,
                        advanced_niche: advancedNiche ? "yes" : "no",
                        dfy_anchor_text: dfyAnchorText ? "yes" : "no"
                    },
                    add_ons: [],
                };

                const key = "ot" + valueOT + (advancedNiche ? "an" : "") + (dfyAnchorText ? "at" : "") + (valueP === "subscription" ? "s" : "");
                const item = {
                    key: "li_" + key,
                    price: itemPrice.toFixed(2),
                    name: "Foreign Link Inserts",
                    description: valuetextOT(valueOT) + ", TLD: "+valueTld+", LANG:"+valueLanguage,
                    orderData: orderData[0]
                };

                setAddedToCartItemHolder(item);
                setOpenAddOns(true);
                setSubmitting(false);

                ReactGA.event({
                    category: 'Cart',
                    action: 'add_to_cart',
                    label: 'Add to Cart',
                    nonInteraction: false
                });
                ReactPixel.track("Add_to_cart", item);

            } else if (valueType == 'da') {
                let orderData = {};
                orderData[0] = {
                    subscription: (valueP === "subscription" ? 1 : 0),
                    quantity: valueQ,
                    type: "link_insertion",
                    options: {
                        da: valueDA,
                        language: valueLanguage,
                        tld: valueTld,
                        advanced_niche: advancedNiche ? "yes" : "no",
                        dfy_anchor_text: dfyAnchorText ? "yes" : "no"
                    },
                    add_ons: [],
                };

                const key = "da" + valueDA + (advancedNiche ? "an" : "") + (dfyAnchorText ? "at" : "") + (valueP === "subscription" ? "s" : "");
                const item = {
                    key: "li_" + key,
                    price: itemPrice.toFixed(2),
                    name: "Foreign Link Inserts",
                    description: valuetextDA(valueDA) + ", TLD: "+valueTld+", LANG:"+valueLanguage,
                    orderData: orderData[0]
                };

                setAddedToCartItemHolder(item);
                setOpenAddOns(true);
                setSubmitting(false);

                ReactGA.event({
                    category: 'Cart',
                    action: 'add_to_cart',
                    label: 'Add to Cart',
                    nonInteraction: false
                });
                ReactPixel.track("Add_to_cart", item);
            }
        }
    });

    const getSubscriptionItem = () => {
        if (valueType == 'ot') {
            let orderData = {};
            orderData[0] = {
                subscription: (valueP === "subscription" ? 1 : 0),
                quantity: 1,//valueQ,
                type: "link_insertion_package",
                options: {
                    ot: valueOT,
                    advanced_niche: advancedNiche ? "yes" : "no",
                    dfy_anchor_text: dfyAnchorText ? "yes" : "no",
                    package_quantity: valueQ,
                },
                add_ons: [],
            };

            const key = "ot" + valueOT + (advancedNiche ? "an" : "") + (dfyAnchorText ? "at" : "") + (valueP === "subscription" ? "s" : "");
            const item = {
                key: "li_" + key,
                price: calculatedPrice.toFixed(2),
                name: "International Link Insertion",
                description: "OT: " + valueOT + ", TLD: "+valueTld+", LANG:"+valueLanguage,
                orderData: orderData[0]
            };

            return item;

        } else if (valueType == 'da') {
            let orderData = {};
            orderData[0] = {
                subscription: (valueP === "subscription" ? 1 : 0),
                quantity: 1,//valueQ,
                type: "link_insertion_package",
                options: {
                    da: valueDA,
                    advanced_niche: advancedNiche ? "yes" : "no",
                    dfy_anchor_text: dfyAnchorText ? "yes" : "no",
                    package_quantity: valueQ,
                },
                add_ons: [],
            };

            const key = "da" + valueDA + (advancedNiche ? "an" : "") + (dfyAnchorText ? "at" : "") + (valueP === "subscription" ? "s" : "");
            const item = {
                key: "li_" + key,
                price: calculatedPrice.toFixed(2),
                name: "International Link Insertion",
                description: "DR: " + valueDA + ", TLD: "+valueTld+", LANG:"+valueLanguage,
                orderData: orderData[0]
            };

            return item;
        }
    };

    const checkoutPackageClose = () => {
        setShowCheckoutPackage(false);
    };

    if(!currentPackage) {
        return <Spinner />;
    }
    else if(currentPackage.currentPackage && !currentPackage.currentPackage.type) {
        return <h1>Package not found!</h1>;
    }

    let isPremiumMember = false;
    let currentPremiumLevel = "Free";

    if (typeof premiumMembershipPackage !== 'undefined') {
        // console.log(premiumMembershipPackage);
        if (typeof premiumMembershipPackage.activePackage !== 'undefined') {
            // console.log(premiumMembershipPackage.activePackage);
            // console.log(premiumMembershipPackage.activePackage.name);
            if (premiumMembershipPackage.activePackage.name != "Free") {
                isPremiumMember = true;
                currentPremiumLevel = premiumMembershipPackage.activePackage.name;
            }
        }
    }

    let maxDiscountPercent = 0.15;
    let maxDiscountAmount = maxDiscountPercent * calculatedPrice;
    let maxPremiumPrice = calculatedPrice - maxDiscountAmount;

    //////////////////////////////////////////////
    // START -> 5% subscription discount, subscribe price holder when not selected
    let subscriberPriceHolder = calculatedPrice;
    if (valueP != "subscription") {
        subscriberPriceHolder = (parseFloat(calculatedPrice) * parseFloat(0.95));
    }
    // END -> 5% subscription discount, subscribe price holder when not selected
    //////////////////////////////////////////////

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="progress progressBar TmoProgressBar">
                        <div className="progress-bar" role="progressbar" aria-valuenow={(user.tmo_amount ? user.tmo_amount : 0)} aria-valuemin="0" aria-valuemax={(user.tmo_progress_bar_total ? user.tmo_progress_bar_total : 10000)} style={{width:(user.tmo_percentage ? user.tmo_percentage : 0)+'%'}}>
                            <span class="progress-label">{(user.tmo_amount ? '$'+user.tmo_amount.toLocaleString(undefined, {maximumFractionDigits:2}) : '$0.00')}</span>
                        </div>
                        <div class="first_label">This Month's Spend</div>
                        <div class="second_label">
                            <div class="vertical_line">|</div>
                            $1,000
                        </div>
                        <div class="third_label">
                            <div class="vertical_line">|</div>
                            $5,000
                        </div>
                        <div class="fourth_label">
                            <div class="vertical_line">|</div>
                            $10,000
                        </div>
                    </div>
                </div>
            </div>
            <div className="row pt-2" style={{marginLeft:0,marginRight:0}}>
                <div className="col-lg-6 col-md-12 col-sm-12" style={{paddingLeft:0,paddingRight:0}}>
                    <Portlet className="" style={{background:"#f2f3f8"}}>
                        <div className="row" style={{marginLeft:0,marginRight:0}}>
                            <div className="col-12" style={{paddingLeft:0,paddingRight:0}}>
                                <Portlet className="h-100 kt-mb-0" style={{background:"#ecf3fe"}}>
                                    <PortletBody dangerouslySetInnerHTML={{ __html: pageData ? pageData.top_left_text : "" }} />
                                </Portlet>
                            </div>
                        </div>
                    </Portlet>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 youve_selected_right_menu kt-mb-20">
                    <Portlet className="h-100 " style={{background:"#f2f3f8"}}>
                        <div className="row h-100" style={{marginLeft:0,marginRight:0}}>
                            <div className="col-12 h-100" style={{paddingLeft:0,paddingRight:0}}>
                                <Portlet className="h-100 kt-mb-0" style={{background:"#e7eff3"}}>
                                    <PortletBody dangerouslySetInnerHTML={{ __html: pageData ? pageData.top_right_text : "" }} />
                                </Portlet>
                            </div>
                        </div>
                    </Portlet>
                </div>
            </div>

            <div className="row" style={{marginLeft:0,marginRight:0}}>
                <div className="col-lg-6 col-md-9" style={{paddingLeft:0,paddingRight:0}}>
                    {/* <Portlet className="kt-visible-desktop" style={{background:"#f2f3f8"}}>
                        <div className="row" style={{marginLeft:0,marginRight:0}}>
                            <div className="col-12" style={{paddingLeft:0,paddingRight:0}}>
                                <Portlet className="h-100 kt-mb-0" style={{background:"#ecf3fe"}}>
                                    <PortletBody dangerouslySetInnerHTML={{ __html: pageData ? pageData.top_left_text : "" }} />
                                </Portlet>
                            </div>
                        </div>
                    </Portlet> */}

                    <Portlet>
                        <PortletBody className="mw-position-orders-area-wide" style={{padding:"40px"}}>
                            {/* <h4 className="mb-3">Get Started with International Link Insertions</h4>
                            <p dangerouslySetInnerHTML={{ __html: pageData ? pageData.top_text : "" }} />
                            <h5 className="mb-0 mt-2">How it works</h5>
                            <Stepper orientation="vertical">
                                <Step active={true} key="step1">
                                    <StepLabel>
                                        <h6 className="mt-2 ml-2">Choose Language</h6>
                                    </StepLabel>
                                </Step>
                                <Step active={true} key="step2">
                                    <StepLabel>
                                        <h6 className="mt-2 ml-2">Choose TLD</h6>
                                    </StepLabel>
                                </Step>
                                <Step active={true} key="step3">
                                    <StepLabel>
                                        <h6 className="mt-2 ml-2">Choose Domain Rating (30-100)</h6>
                                    </StepLabel>
                                </Step>
                                <Step active={true} key="step4">
                                    <StepLabel>
                                        <h6 className="mt-2 ml-2">Submit Your URL After Purchase</h6>
                                    </StepLabel>
                                </Step>
                                <Step active={true} key="step5">
                                    <StepLabel>
                                        <h6 className="mt-2 ml-2">Start Outreach</h6>
                                    </StepLabel>
                                </Step>
                                <Step active={true} key="step6">
                                    <StepLabel>
                                        <h6 className="mt-2 ml-2">Get Your Link In 30 Days or Less</h6>
                                    </StepLabel>
                                </Step>
                            </Stepper> */}
                            <Form noValidate onSubmit={formik.handleSubmit}>
                                <div className="row mt-2">
                                    <div className="col-12 text-center">
                                        <FormControl component="fieldset" className="kt-margin-b-15">
                                            <Typography className="font-weight-bold" gutterBottom>Choose Your Plan</Typography>
                                            <RadioGroup
                                                aria-label="plan"
                                                name="payment_type"
                                                value={valueP}
                                                onChange={handleChangeP}
                                            >
                                                <FormControlLabel
                                                    value="purchase"
                                                    control={<Radio color="primary" />}
                                                    label="One-time purchase"
                                                    className="kt-margin-b-0"
                                                />
                                                <FormControlLabel
                                                    value="subscription"
                                                    control={<Radio color="primary" />}
                                                    label={<Typography className="text-left">Subscribe for ${subscriberPriceHolder.toFixed(2)} <span className='kt-font-success'>(Save 5% with this plan)</span></Typography>}
                                                    // label={"Subscribe for $" + calculatedPrice.toFixed(2) + " / month"}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center mt-4 mw-position-orders-area">
                                        <Typography id="language-label" className="font-weight-bold" gutterBottom>
                                            Language
                                        </Typography>
                                        <Select
                                            options={languageOptions}
                                            defaultValue={defaultLanguageOption}
                                            onChange={selectedOption => {
                                                handleChangeLanguage(selectedOption.value);
                                            }}
                                            noOptionsMessage={()=>{return !languageOptions ? "Loading..." : "No options";}}
                                            styles={selectStyles}
                                            className="w-100"
                                            aria-labelledby="language-label"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 text-center mt-4 mw-position-orders-area">
                                        <Typography id="tld-label" className="font-weight-bold" gutterBottom>
                                            Preferred TLD
                                        </Typography>
                                        <Select
                                            options={tldOptions}
                                            defaultValue={defaultTldOption}
                                            onChange={selectedOption => {
                                                handleChangeTld(selectedOption.value);
                                            }}
                                            noOptionsMessage={()=>{return !tldOptions ? "Loading..." : "No options";}}
                                            styles={selectStyles}
                                            className="w-100"
                                            aria-labelledby="tld-label"
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 text-center mt-4 mb-4 mw-position-orders-area">
                                        {valueType == 'ot' ? 
                                            <>
                                                <div className="btn btn-primary" style={{borderTopRightRadius:'0',borderBottomRightRadius:'0'}}>Organic Traffic</div>
                                                <div className="btn btn-secondary" style={{borderTopLeftRadius:'0',borderBottomLeftRadius:'0'}} onClick={() => {
                                                    setValueType('da');
                                                    setValueDA(minDA);
                                                }}>Domain Rating</div>
                                            </>
                                        :
                                            <>
                                                <div className="btn btn-secondary" style={{borderTopRightRadius:'0',borderBottomRightRadius:'0'}} onClick={() => {
                                                    setValueType('ot');
                                                    setValueOT(minOT);
                                                }}>Organic Traffic</div>
                                                <div className="btn btn-primary" style={{borderTopLeftRadius:'0',borderBottomLeftRadius:'0'}}>Domain Rating</div>
                                            </>
                                        }
                                    </div>
                                </div>

                                {valueType == 'da' ? 
                                    <div className="row">
                                        <div className="col-12 text-center mt-4 mw-position-orders-area">
                                            <Typography className="font-weight-bold" id="da-strength-label" gutterBottom>
                                                DR Strength
                                            </Typography>
                                            <p>
                                                The higher the number the more powerful the website
                                            </p>
                                            <div className="text-right"><strong style={{fontSize:"15px"}}>{valueDA}</strong>+ DR</div>
                                            <Slider
                                                defaultValue={minDA}
                                                getAriaValueText={valuetextDA}
                                                aria-labelledby="da-strength-label"
                                                valueLabelDisplay="auto"
                                                step={stepDA}
                                                marks={marksDA}
                                                min={minDA}
                                                max={maxDA}
                                                onChange={handleSliderChangeDA}
                                            />
                                        </div>
                                    </div>
                                :
                                    <div className="row">
                                        <div className="col-12 text-center mt-4 mw-position-orders-area">
                                            <Typography className="font-weight-bold" id="organic-traffic-label" gutterBottom>
                                                Organic Traffic
                                            </Typography>
                                            <div className="text-right"><strong style={{fontSize:"15px"}}>{valuetextOT(valueOT, true) ? valuetextOT(valueOT, true) : valuetextOT(1, true)}</strong> Monthly Domain Traffic</div>
                                            <Slider
                                                defaultValue={minOT}
                                                getAriaValueText={valuetextOT}
                                                aria-labelledby="organic-traffic-label"
                                                valueLabelDisplay="auto"
                                                step={null}
                                                marks={marksOT}
                                                min={minOT}
                                                max={maxOT}
                                                onChange={handleSliderChangeOT}
                                            />
                                        </div>
                                    </div>
                                }
                                {/* <div className="row">
                                    <div className="col-12 text-center mt-4 mw-position-orders-area">
                                        <Typography className="font-weight-bold">Add DFY Anchor Text</Typography>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={dfyAnchorText}
                                                    onChange={handleDfyAnchorText}
                                                    value="Add DFY Anchor Text"
                                                    color="primary"
                                                />
                                            }
                                            label={"Done-For-You Anchor Text based on Competitor Research (+$" + (currentPackage.currentPackage && currentPackage.currentPackage["dfy_anchor_text"] ? currentPackage.currentPackage["dfy_anchor_text"].toFixed(2) : 0) + ")"}
                                        />
                                    </div>
                                </div> */}
                                {/* <div className="row">
                                    <div className="col-12 text-center mt-4 mw-position-orders-area">
                                        <Typography className="font-weight-bold">Advanced Niche</Typography>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={advancedNiche}
                                                    onChange={handleAdvancedNiche}
                                                    value="Advanced Niche"
                                                    color="primary"
                                                />
                                            }
                                            label={"Add this upgrade if you're in the Adult, Casino, CBD and Dating niche. (+$" + (currentPackage.currentPackage && currentPackage.currentPackage["advanced_niche"] ? currentPackage.currentPackage["advanced_niche"].toFixed(2) : 0) + ")"}
                                        />
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-12 text-center mt-4 mw-position-orders-area">
                                        <Typography className="font-weight-bold" id="post-quantity-label" gutterBottom>
                                            Quantity
                                        </Typography>
                                        {/* <p>
                                            Choose the number of posts.
                                        </p> */}
                                        <div className="text-right"><strong style={{fontSize:"15px"}}>{valueQ}</strong> posts</div>
                                        <Slider
                                            defaultValue={minQ}
                                            getAriaValueText={valuetextQ}
                                            aria-labelledby="post-quantity-label"
                                            valueLabelDisplay="auto"
                                            step={stepQ}
                                            marks={marksQ}
                                            min={minQ}
                                            max={maxQ}
                                            onChange={handleSliderChangeQ}
                                        />
                                    </div>
                                </div>
                            </Form>
                        </PortletBody>
                    </Portlet>
                </div>

                <div class="col-lg-4 col-md-3 col-sm-12 youve_selected_right_menu">
                    {/* <Portlet className="kt-visible-desktop" style={{background:"#f2f3f8"}}>
                        <div className="row" style={{marginLeft:0,marginRight:0}}>
                            <div className="col-12" style={{paddingLeft:0,paddingRight:0}}>
                                <Portlet className="h-100 kt-mb-0" style={{background:"#e7eff3"}}>
                                    <PortletBody dangerouslySetInnerHTML={{ __html: pageData ? pageData.top_right_text : "" }} />
                                </Portlet>
                            </div>
                        </div>
                    </Portlet> */}

                    <Card>
                        <Card.Body>
                            <div style={{maxWidth:"300px", margin:"0 auto", padding:"1.5rem 0"}}>
                                <Card.Title className="mb-4">Order Form</Card.Title>
                                <strong>International Link Insertion</strong>
                                <List>
                                    <ListItem>
                                        <ListItemText
                                            primary={getValueLangeuage(valueLanguage)}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary={getValueTld(valueTld)}
                                        />
                                    </ListItem>
                                    {valueType == 'da' ? 
                                        <ListItem>
                                            <ListItemText
                                                primary={valuetextDA(valueDA)}
                                            />
                                        </ListItem>
                                    :
                                        <ListItem>
                                            <ListItemText
                                                primary={valuetextOT(valueOT)}
                                            />
                                        </ListItem>
                                    }
                                    <ListItem>
                                        <ListItemText
                                            primary={valuetextQ(valueQ)}
                                        />
                                    </ListItem>
                                    {dfyAnchorText && (<ListItem>
                                        <ListItemText
                                            primary="Add DFY Anchor Text"
                                        />
                                    </ListItem>)}
                                    {advancedNiche && (<ListItem>
                                        <ListItemText
                                            primary="Advanced Niche"
                                        />
                                    </ListItem>)}
                                </List>
                                <hr/>
                                {discountAmount > 0 && (<div><Typography>Normal Price</Typography>
                                    <Typography variant="h5">${(itemPrice * valueQ).toFixed(2)}</Typography></div>)}
                                <div style={{display:(discountAmount > 0 ? "block" : "none")}}>
                                    <Typography className="kt-font-danger">Discount</Typography>
                                    <Typography className="kt-font-danger" variant="h5">-${discountAmount.toFixed(2)}</Typography>
                                </div>
                                {/* {discountAmount > 0 ? <Typography className="kt-font-danger">Premium Member Price</Typography> : <Typography>Total Cost</Typography>}
                                <Typography variant="h5" className={discountAmount > 0 ? "kt-font-danger" : ""}>${calculatedPrice.toFixed(2)}</Typography> */}

                                {/* {! isPremiumMember && discountAmount == 0 ? <Typography className="kt-font-danger">Premium Member Price</Typography> : ""}
                                {! isPremiumMember && discountAmount == 0 ? <Typography variant="h5" className={maxPremiumPrice > 0 ? "kt-font-danger" : ""}>${maxPremiumPrice.toFixed(2)}</Typography> : ""}
                                {! isPremiumMember && discountAmount == 0 ? <Link className="d-block" to="/packages/marketplace-access">Upgrade to Premium</Link> : ""} */}

                                {isPremiumMember && discountAmount > 0 ? <Typography>Your Investment</Typography> : <Typography>Your Investment</Typography>}
                                <Typography variant="h5">${calculatedPrice.toFixed(2)}</Typography>
                                
                                <div className="kt-margin-b-15" />
                                {valueP !== "subscription" ? (<div>
                                    {/* <Button className="btn-success kt-mb-10" style={{minWidth:"150px"}} size="lg" variant="primary" onClick={() => {
                                    formik.setFieldValue('add_to_cart', 0, false);
                                    formik.handleSubmit();
                                    }} disabled={formik.isSubmitting || calculatedPrice === 0}>Checkout</Button> */}
                                    <Button onClick={() => {
                                        formik.setFieldValue('add_to_cart', 1, false);
                                        formik.handleSubmit();
                                    }} className="btn-block" size="lg" variant="warning" disabled={formik.isSubmitting || calculatedPrice === 0}>Add to Cart</Button></div>) : (<div>
                                    <Button className="btn-block btn-success" size="lg" variant="primary" onClick={() => {
                                    setShowCheckoutPackage(true);
                                    }} disabled={calculatedPrice === 0}>Subscribe Now</Button>
                                    <CheckoutProductPackageModal
                                        show={showCheckoutPackage}
                                        onHide={checkoutPackageClose}
                                        item={
                                            getSubscriptionItem()
                                        }
                                    />
                                </div>)}
                            </div>
                        </Card.Body>
                    </Card>

                    <img className=" mt-4" src="https://serppro.nyc3.cdn.digitaloceanspaces.com/public/secure%20badge.png" alt="" style={{maxWidth:"255px"}} />

                    {/* <h5 className="mt-5" style={{fontSize:"15px"}}>Client Reviews</h5>

                    <div class="mt-4 row">
                        <div class="col-12">
                            <div style={{fontSize:"14px"}}>
                                "<span className="font-weight-bold">Massive ranking</span> improvements in a super competitive niche"
                            </div>
                            <div class="d-flex mt-1">
                                <div>
                                    <img className="" src="https://serppro.nyc3.cdn.digitaloceanspaces.com/public/Matt%20W.png" alt="" style={{maxWidth:"40px", borderRadius:"20px"}} />
                                </div>
                                <div style={{fontSize:"13px", paddingLeft:"10px"}}>
                                    <div class="d-block">
                                        Matthew Woodley
                                    </div>
                                    <div class="d-block">
                                        SEO Specialist
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 row">
                        <div class="col-12">
                            <div style={{fontSize:"14px"}}>
                                "Delivers high end campaigns for our clients!"
                            </div>
                            <div class="d-flex mt-1">
                                <div>
                                    <img className="" src="https://serppro.nyc3.cdn.digitaloceanspaces.com/public/Therrol%202.png" alt="" style={{maxWidth:"40px", borderRadius:"20px"}} />
                                </div>
                                <div style={{fontSize:"13px", paddingLeft:"10px"}}>
                                    <div class="d-block">
                                        Therrol Jezierski
                                    </div>
                                    <div class="d-block">
                                        SEO Marketer
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 row">
                        <div class="col-12">
                            <div style={{fontSize:"14px"}}>
                                "<span className="font-weight-bold">Noticed improvement</span> in our Google ranking"
                            </div>
                            <div class="d-flex mt-1">
                                <div>
                                    <img className="" src="https://serppro.nyc3.cdn.digitaloceanspaces.com/public/Simon%20S.jpg" alt="" style={{maxWidth:"40px", borderRadius:"20px"}} />
                                </div>
                                <div style={{fontSize:"13px", paddingLeft:"10px"}}>
                                    <div class="d-block">
                                        Simon Schmid
                                    </div>
                                    <div class="d-block">
                                        SEO Specialist
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 row">
                        <div class="col-12">
                            <div style={{fontSize:"14px"}}>
                                "<span className="font-weight-bold">Excellent</span> communication from the start"
                            </div>
                            <div class="d-flex mt-1">
                                <div>
                                    <img className="" src="https://serppro.nyc3.cdn.digitaloceanspaces.com/public/Clare.jpg" alt="" style={{maxWidth:"40px", borderRadius:"20px"}} />
                                </div>
                                <div style={{fontSize:"13px", paddingLeft:"10px"}}>
                                    <div class="d-block">
                                        Clare Nicholas
                                    </div>
                                    <div class="d-block">
                                        Blogger
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 row">
                        <div class="col-12">
                            <Link className="" to={{ pathname: "https://www.serppro.io/testimonials" }} target="_blank">See more reviews</Link>
                        </div>
                    </div> */}

                </div>
            </div>
            <AddOnModal open={openAddOns} onClose={addOnsClose} addOnItems={addOnItems} item={addedToCartItemHolder} />
            {/* <GuestPostsInfo /> */}
        </>
    );
}

const mapStateToProps = ({ auth: { user }, pagedata: {pageData}, cart: {userDiscountPercent}, packages: currentPackage }) => ({
    user, pageData, currentPackage, userDiscountPercent
});

export default injectIntl(
    connect(
        mapStateToProps,
        {...cart.actions, ...pagedata.actions, ...packages.actions}
    )(ForeignLinkInsertion)
);
