import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { getGoogleUpdates } from "../../../crud/analytics.crud";
import {Spinner} from "reactstrap";
import {Pagination, Table} from "react-bootstrap";
import {IconButton, Button} from '@material-ui/core';
import {Autorenew} from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import HyperText from "./HyperText";


class GoogleUpdateList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notesModalShow: false,
            notesModalAction: 'add',
            notes: [],
            paginatePage: 1,
            noteId: null,
            noteDate: false,
            load: false,
            active: false,
        };
    }


    getGoogleUpdates = (paginate=0, page=1) => {

        this.setState({load: true});

        getGoogleUpdates(paginate, page).then((res) => {

            let result = res.data;

            if (!result) {
                this.setState({active: false, load: false, error: res.toString()});
                return false;
            }

            this.setState({
                notes: result,
                paginatePage: page,
                active: true,
                load: false
            });

        })
            .catch((e) => {
                this.setState({active: false, load: false, error: e.toString()});
                console.log('Error - GoogleUpdateList')
            });

    };


    componentDidMount() {
        this.getGoogleUpdates(6, this.state.paginatePage);
    }

    render() {

        const {load, active, notes, paginatePage } = this.state;

        const RefreshData = () => {
            this.getGoogleUpdates();
        };


        if (load) {
            return (
                <>
                    <Skeleton variant="rect" height={400}><Spinner mx="auto"/></Skeleton>
                </>
            );
        }

        if (!notes) {
            return (
                <>
                    <Skeleton variant="rect" height={400}>
                        <span className="no-data">No data</span>&nbsp;
                        <IconButton onClick={RefreshData}>
                            <Autorenew />
                        </IconButton>
                    </Skeleton>
                </>
            );
        }

        const rows = [];
        const paginationItems = [];


        if (notes.data) {

            if (notes.data.length) {
                for (const [index] of notes.data.entries()) {

                    rows.push(
                        <tr key={index}>
                            <td>{notes.data[index].date}</td>
                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                            <td>{notes.data[index].description && notes.data[index].description.indexOf("http") === 0 ? <a href={notes.data[index].description} target="_blank">{notes.data[index].name}</a> : notes.data[index].name}</td>
                            <td>{notes.data[index].volatility}</td>
                        </tr>
                    )
                }
            }

            for (let number = 1; number <= notes.last_page; number++) {
                paginationItems.push(
                    <Pagination.Item key={number} active={number === paginatePage} onClick={() => {
                        this.getGoogleUpdates(6, number);
                    }}>
                        {number}
                    </Pagination.Item>,
                );
            }
        }

        return (
                <>
                <div>
                    <Table hover responsive size="sm" id="analyticsGoogleUpdates" className="table">
                        <thead className="">
                        <tr>
                            <th style={{width: '20%'}}>Date</th>
                            <th style={{width: '20%'}}>Name</th>
                            <th style={{width: '10%'}}>Volatility</th>
                          </tr>
                        </thead>
                        <tbody>
                        {rows}
                        </tbody>
                    </Table>
                </div>
                    <Pagination>{paginationItems}</Pagination>

                </>
        );
    }
}


export default injectIntl(connect(
    null,
    null
)(GoogleUpdateList));
