import React, { Component } from "react";
import Deals from "./Deals";

export default class LinkInsertion extends Component {
    render() {
        return (<>
            <Deals type="link_insertion_service_package" key="lis" description="Niche Edit Service" title="Choose Niche Edit Package to Get Started" />
        </>);
    }
}
