import axios from "axios";

export const base = process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL : "";
export const LOGIN_URL = base + "/api/auth/login";
export const REGISTER_URL = base + "/api/auth/signup";
export const REQUEST_PASSWORD_URL = base + "/api/password/create";
export const RESET_PASSWORD_URL = base + "/api/password/reset";
export const FIND_PASSWORD_URL = base + "/api/password/find/";
export const INSTANT_URL = base + "/api/auth/instant";
export const ME_URL = base + "/api/auth/user";
export const ME_USER_TASKS = base + "/api/auth/user/tasks";
export const UPDATE_WRITER = base + "/api/user/writer";
export const WRITER_APPLICATION = base + "/api/user/writer-application";

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(email, firstname, lastname, password, solutions_looking_for, how_did_you_hear_about_us) {
  return axios.post(REGISTER_URL, { email, firstname, lastname, password, solutions_looking_for, how_did_you_hear_about_us });
}

export function registerBlogger(email, firstname, lastname, password, password_confirmation, company, paypal_email, reason, blog_url, blog_price, category_id, solutions_looking_for, how_did_you_hear_about_us) {
  return axios.post(REGISTER_URL, { email, firstname, lastname, password, password_confirmation, company, paypal_email, reason, blog_url, blog_price, category_id, solutions_looking_for, how_did_you_hear_about_us });
}

export function editWriter(portfolio_url, content_price, selected_categories) {
  return axios.post(UPDATE_WRITER, { portfolio_url, content_price, selected_categories });
}

export function writerApplication(portfolio_url, content_price, selected_categories) {
  return axios.post(WRITER_APPLICATION, { portfolio_url, content_price, selected_categories });
}

export function registerWriter(email, firstname, lastname, password, password_confirmation, company, paypal_email, reason, portfolio_url, content_price, selected_categories, solutions_looking_for, how_did_you_hear_about_us) {
  return axios.post(REGISTER_URL, { email, firstname, lastname, password, password_confirmation, company, paypal_email, reason, portfolio_url, content_price, selected_categories, solutions_looking_for, how_did_you_hear_about_us });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function resetPassword(email, password, password_confirmation, token) {
    return axios.post(RESET_PASSWORD_URL, { email, password, password_confirmation, token });
}

export function findPassword(token) {
    return axios.get(FIND_PASSWORD_URL + token);
}

export function userIntoTasks(watched_intro_video) {
  return axios.post(ME_USER_TASKS, { watched_intro_video });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function instant(email) {
    return axios.post(INSTANT_URL, { email });
}
