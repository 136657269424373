import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {getSeoService, getSeoServices} from "../../crud/orders.crud";

export const actionTypes = {
    SeoServicesInit: "[Init Seo Services] Action",
    SeoServicesRequested: "[Request Seo Services] Action",
    SeoServicesLoaded: "[Load Seo Services] Orders API",
    SeoServiceRequested: "[Request Seo Service] Action",
    SeoServiceLoaded: "[Load Seo Service] Orders API"
};

const initialSeoServicesState = {
    seoServices: undefined,
    currentSeoService: undefined
};

export const reducer = persistReducer(
    { storage, key: "demo3-orders", whitelist: ["seoServices", "currentSeoService"] },
    (state = initialSeoServicesState, action) => {
        switch (action.type) {
            case actionTypes.SeoServicesInit: {
                return { seoServices: undefined, currentSeoService: undefined };
            }

            case actionTypes.SeoServicesLoaded: {
                const { seoServices } = action.payload;

                return { ...state, seoServices };
            }

            case actionTypes.SeoServiceRequested: {
                return { ...state, currentSeoService: undefined };
            }

            case actionTypes.SeoServiceLoaded: {
                const { currentSeoService } = action.payload;

                return { ...state, currentSeoService };
            }

            default:
                return state;
        }
    }
);

export const actions = {
    initSeoServices: (orderId, page, params) => ({ type: actionTypes.SeoServicesInit, payload: { orderId, page, params } }),
    requestSeoServices: (orderId, page, params) => ({ type: actionTypes.SeoServicesRequested, payload: { orderId, page, params } }),
    fulfillSeoServices: seoServices => ({ type: actionTypes.SeoServicesLoaded, payload: { seoServices } }),
    requestSeoService: id => ({ type: actionTypes.SeoServiceRequested, payload: { id } }),
    fulfillSeoService: currentSeoService => ({ type: actionTypes.SeoServiceLoaded, payload: { currentSeoService } })
};

export function* saga() {
    yield takeLatest(actionTypes.SeoServicesInit, function* initSeoServicesSaga(action) {
        const { orderId, page, params } = action.payload;
        yield put(actions.requestSeoServices(orderId, page, params));
    });

    yield takeLatest(actionTypes.SeoServicesRequested, function* seoServicesRequested(action) {
        try {
            const { orderId, page, params } = action.payload;
            const {data: seoServices} = yield getSeoServices(orderId, page, params);

            yield put(actions.fulfillSeoServices(seoServices));
        }
        catch (e) {
            yield put(actions.fulfillSeoServices({data: undefined}));
        }
    });

    yield takeLatest(actionTypes.SeoServiceRequested, function* seoServiceRequested(action) {
        try {
            const { id } = action.payload;
            const {data: currentSeoService} = yield getSeoService(id);

            yield put(actions.fulfillSeoService(currentSeoService));
        }
        catch (e) {
            yield put(actions.fulfillSeoService({data: undefined}));
        }
    });
}
