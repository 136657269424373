import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as marketplace from "../../../store/ducks/marketplace.duck";
import {Spinner} from "reactstrap";
import {Button, Form, Col} from "react-bootstrap";
import {Portlet, PortletBody, PortletHeader} from "../../../partials/content/Portlet";
import Select from "react-select";
import * as cart from "../../../store/ducks/cart.duck";
import "../../../../_metronic/_assets/sass/custom/datatable.scss";
import UPagination from "../../../partials/content/Pagination";
import {Stepper, Step, StepLabel, Popover, Icon} from "@material-ui/core";
import * as pagedata from "../../../store/ducks/page.duck";

import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';

import MarketplaceSubscriptionInfo from "../../../includes/MarketplaceSubscriptionInfo";
import { Redirect, Link, useHistory } from "react-router-dom";
import "../../../../_metronic/_assets/sass/custom/marketplace_mobile.scss";
import "../../../../_metronic/_assets/sass/custom/marketplace.scss";
import {toAbsoluteUrl} from "../../../../_metronic";
// import SiteBlackListModal from "./SiteBlackListModal";
import {getQueryParam} from "../../../custom_functions/get_query_param";
import DomainInfo from "./DomainInfo";
import * as profile from "../../../store/ducks/profile.duck";
//import { Steps } from "intro.js-react";
import introJs from 'intro.js';
import "intro.js/introjs.css";
import {saveUserOptions,saveWatchlistItem,removeWatchlistItem} from "../../../crud/user.crud";
import AddOnModal from "../AddOnModal"
import * as packages from "../../../store/ducks/packages.duck";
// import {takeLatest} from "../../../crud/packages.crud";

const selectStyles = {
    menu: styles => ({ ...styles, zIndex: 999 }),
    control: (provided, state) => ({
        ...provided,
        minHeight: '47px',
    }),
};

//    {key:'', value:'Default'},
const sortOptions = [
    {key:'id-desc', value:'Recently Added'},
    {key:'category_id-asc', value:'Category (↑)'},
    {key:'category_id-desc', value:'Category (↓)'},
    {key:'da-asc', value:'DA (↑)'},
    {key:'da-desc', value:'DA (↓)'},
    // {key:'dr-asc', value:'DR (↑)'},
    // {key:'dr-desc', value:'DR (↓)'},
    {key:'rd-asc', value:'RD (↑)'},
    {key:'rd-desc', value:'RD (↓)'},
    // {key:'organic_traffic-asc', value:'Organic Traffic (↑)'},
    // {key:'organic_traffic-desc', value:'Organic Traffic (↓)'},
    {key:'price-asc', value:'Price (↑)'},
    {key:'price-desc', value:'Price (↓)'},
    {key:'id-asc', value:'Site Id (↑)'},
    {key:'id-desc', value:'Site Id (↓)'},
];

class GuestPosts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            blacklist: "",
            openBlackList: false,
            openMakeOffer: false,
            makeOfferItem: "",
            openAddOns: false,
            addOnItems: "",
            addedToCartItemHolder: "",
            viewType: "grid",
            diPopoverId: undefined,
            domainInfoOpen: false,
            anchorEl: null,
            stepsEnabled: false,
            arrayWatchlist: null,
            steps: [
                {
                    selector: "#filters-block",
                    element: document.querySelectorAll('#filters-block')[0],
                    title: "Filters",
                    intro: "Use these filters to find websites that match your criteria."
                },
                {
                    selector: "#blacklist-button",
                    element: document.querySelectorAll('#blacklist-button')[0],
                    title: "Blacklist",
                    intro: "Add sites to our blacklist, and we'll remove any potential unwanted site from our marketplace."
                },
                {
                    selector: "#ms-item-0",
                    element: document.querySelectorAll('#ms-item-0')[0],
                    intro: "You can view this site's niche and more real-time SEO metrics by clicking on the i icon."
                },
            ],
            //initialStep: 0,
        };
        //this.steps = null;
    }

    componentDidMount() {
        const pageName = 'domain_marketplace';
        const page = 1;
        const {filter, currentProjectId} = this.props;
        const queryKeyword = getQueryParam(window.location.search, "keyword");
        filter['project_id'] = currentProjectId;
        filter['keyword'] = queryKeyword ? queryKeyword : "";
        filter['widget'] = false;
        this.props.initDomainMarketplace(page, filter);
        this.props.requestUserDiscount();
        this.props.requestMyWatchlistDomains();
        this.props.requestPageData(pageName);
        this.props.requestPackage("marketplace_guest_post_access");

        this.state.arrayWatchlist = null;

        const addToCartId = getQueryParam(window.location.search, "add_to_cart");
        if(addToCartId && parseInt(addToCartId) > 0) {
            this.props.requestGuestPost(addToCartId);
        }

        //this.props.requestUserOptions();
    }

    addToCartById(id) {
        if(id > 0) {
            let guestPost = this.props.guestPostItem && this.props.guestPostItem["dns" + id] && this.props.guestPostItem["dns" + id].data ? this.props.guestPostItem["dns" + id].data : {};

            if(guestPost && guestPost.id) {
                const inCart = this.props.cartItems.filter(item => item.key === "dns_" + id).length !== 0;

                if(!inCart) {
                    const item = {
                        key: "dns_" + id,
                        price: Number(guestPost.binPrice),
                        name: "Domain Marketplace",
                        description: "Domain: " + guestPost.domainName,
                        orderData: {
                            subscription: 0,
                            quantity: 1,
                            type: "domain_name_sale",
                            options: {
                                price: Number(guestPost.binPrice),
                                site_id: id,
                                domain_name: guestPost.domainName
                            }
                        },
                        add_ons: [
                        ]
                    };

                    this.props.addToCart(item);

                    ReactGA.event({
                        category: 'Cart',
                        action: 'add_to_cart',
                        label: 'Add to Cart',
                        nonInteraction: false
                    });
                    ReactPixel.track("Add_to_cart", item);
                }
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const page = 1;
        const {filter} = this.props;
        const queryKeyword = getQueryParam(window.location.search, "keyword");
        if(!queryKeyword && this.props.guestPosts && this.props.guestPosts.marketplace_category && this.props.guestPosts.marketplace_category !== filter['keyword']) {
            filter['keyword'] = this.props.guestPosts.marketplace_category;
            this.props.initDomainMarketplace(page, filter);
            this.setState({
                ...this.state,
                selected: []
            });
        }
        const addToCartId = getQueryParam(window.location.search, "add_to_cart");
        if(addToCartId > 0) {
            if(this.props.guestPostItem && this.props.guestPostItem["dns_" + addToCartId] && !prevProps.guestPostItem) {
                this.addToCartById(addToCartId);
            }
        }
        /*try {
            const {userOptions} = this.props;
            const intro = introJs();
            //intro.exit();
            if (!this.state.stepsEnabled && prevProps.userOptions !== userOptions && userOptions && userOptions["show_intro_marketplace"] && parseInt(userOptions["show_intro_marketplace"]) === 1) {
                window.setTimeout(()=> {
                    intro.onbeforechange(function (targetElement) {
                        if (intro._options.steps.length !== 0) {
                            for (let i = 0; i < intro._options.steps.length; i++) {
                                const selector = intro._options.steps[i].selector;
                                if(selector) {
                                    const element = document.querySelectorAll(selector)[0];

                                    if (element) {
                                        intro._introItems[i].element = element;
                                        intro._introItems[i].position = intro._options.steps[i].position || 'auto';
                                    }
                                }
                            }
                        }
                    }).oncomplete(function () {
                        const data = {
                            options: [
                                {option: "show_intro_marketplace", value: 0}
                            ],
                        };
                        saveUserOptions(data)
                            .then(() => {
                                this.props.fulfillUserOptions(data);
                            })
                            .catch(() => {
                                //
                            });
                    }).setOptions({
                        'showBullets': false,
                        'skipLabel': "Skip",
                    }).addSteps(this.state.steps);
                    intro.start();
                }, 1000);
                this.setState({...this.state, stepsEnabled: true});
            }
        } catch (e) {
            console.log(e);
        }*/
    }

    render() {
        const {user, guestPosts, watchlistArray, filter, limit, cartItems, userDiscountPercent, discountDate, currentDate, pageData, packages, currentPackage} = this.props;
        let defaultCategories = [];
        const categories = [];
        /*const discountDateObj = discountDate && discountDate["domain_marketplace"] ? Date.parse(discountDate["domain_marketplace"]) : "";
        const currentDateObj = currentDate ? Date.parse(currentDate) : "";
        let dateDiff = 0;
        if(discountDateObj > currentDateObj) {
            dateDiff = discountDateObj - currentDateObj;
        }*/

        // console.log('userDiscountPercent', userDiscountPercent);
        // console.log('userDiscountPercent.domain_marketplace', userDiscountPercent.domain_marketplace);


        // <!-- IS PREMIUM MEMBERSHP?
        // console.log("currentPackage");
        // console.log(currentPackage);
        let isPremiumMember = false;
        let isAgencyMember = false;
        let currentPremiumLevel = "Free";

        if (typeof currentPackage !== 'undefined') {
            if (typeof currentPackage.activePackage !== 'undefined') {
                // console.log(currentPackage.activePackage);
                // console.log(currentPackage.activePackage.name);
                if (currentPackage.activePackage.name != "Free") {
                    isPremiumMember = true;
                    currentPremiumLevel = currentPackage.activePackage.name;
                }
                if (currentPackage.activePackage.name == "Agency") {
                    isAgencyMember = true;
                }
            }
        }
        // -->


        // console.log('******** guestPosts',guestPosts);

        if (pageData != null) {
            if (pageData.add_ons != null) {
                // console.log(pageData.add_ons);
                // NOTICE .. THIS DISABLES add_ons ... UNCOMMENT BELOW TO RE-ADD THEM
                // this.addOnItems = pageData.add_ons;
            }
        }



        if(filter && filter["keyword"] === "[empty]") {
            filter["keyword"] = "";
        }

        const limitInfo = guestPosts && guestPosts.limit ? guestPosts.limit : {};


        const handleInputChange = (event) => {
            filter[event.target.name] = event.target.value;
            this.props.initDomainMarketplace(1, filter);
            this.setState({
                ...this.state,
                selected: []
            });
        };

        const handleCategoryChange = (value) => {
            if(value && value.length !== 0) {
                //let selectedIds = "";
                let selectedText = [];
                defaultCategories = [];
                for(let i = 0; i < value.length; i++) {
                    /*if(i !== 0)
                    {
                        selectedIds += ",";
                    }
                    selectedIds += value[i].value;*/
                    selectedText.push(value[i].value);
                }
                //filter["category_id"] = selectedIds;
                filter["keyword"] = selectedText;
                defaultCategories = value;
            }
            else {
                //filter["category_id"] = "";
                filter["keyword"] = ["[empty]"];
                defaultCategories = [];
            }
            this.props.initDomainMarketplace(1, filter);
            this.setState({
                ...this.state,
                selected: []
            });
        };

        const handleResize = () => {
            if (window.innerWidth < 767 && this.state.viewType !== "grid") {
                this.setState({
                    ...this.state,
                    viewType: 'grid'
                });
            }
        };

        window.addEventListener('resize', handleResize);

        const handleMakeOffer = (id) => {
            let guestPost = {};
            for (const [index] of guestPosts.data.entries()) {
                if(guestPosts.data[index].id === parseInt(id)) {
                    guestPost = guestPosts.data[index];
                }
            }

            const categoryName = categories["c" + guestPost.category_id];

            const item = {
                key: "dns_" + id,
                price: Number(guestPost.binPrice),
                name: "Domain Marketplace",
                description: "Domain: " + guestPost.domainName,
                orderData: {
                    subscription: 0,
                    quantity: 1,
                    type: "domain_name_sale",
                    options: {
                        price: Number(guestPost.binPrice),
                        site_id: id,
                        domain_name: guestPost.domainName
                    }
                },
                add_ons: [
                ]
            };

            this.setState({...this.state, openMakeOffer: true, makeOfferItem: item});

        };

        const handleAddToCartMobile = (id) => {
            // console.log('****** id='+id);
            let guestPost = {};
            for (const [index] of guestPosts.data.entries()) {
                // console.log('guestPost=',guestPosts.data[index]);
                if(parseInt(guestPosts.data[index].id) === parseInt(id)) {
                    guestPost = guestPosts.data[index];
                }
            }

            // const categoryName = categories["c" + guestPost.category_id];
            // console.log('guestPost=',guestPost);

            const item = {
                key: "dns_" + id,
                price: Number(guestPost.binPrice),
                name: "Domain Marketplace",
                description: "Domain: " + guestPost.domainName,
                orderData: {
                    subscription: 0,
                    quantity: 1,
                    type: "domain_name_sale",
                    options: {
                        price: Number(guestPost.binPrice),
                        site_id: id,
                        domain_name: guestPost.domainName
                    },
                    add_ons: [
                    //     {
                    //         add_on_id: add_on_id,
                    //         add_on_name: add_on_name,
                    //         add_on_price: add_on_price
                    //     }
                    ],
                }
            };

            // console.log("handleAddToCartMobile");
            // console.log(item);

            ReactGA.event({
                category: 'Cart',
                action: 'add_to_cart',
                label: 'Add to Cart',
                nonInteraction: false
            });
            ReactPixel.track("Add_to_cart", item);

            // this.props.addToCart(item);

            this.setState({...this.state, openAddOns: true, addOnItems: this.addOnItems, addedToCartItemHolder: item});
        };


        // START -> watchlist
        if (watchlistArray && this.state.arrayWatchlist == null) {
            this.state.arrayWatchlist = watchlistArray;
        }
        const handleWatch = (id) => {
            if (this.state.arrayWatchlist) {
                this.state.arrayWatchlist.push(parseInt(id));
            }

            let data = { watchlist_domain_id: id};
            saveWatchlistItem(data)
            .then(() => {
                // this.props.requestMyWatchlistDomains();
            })
            .catch(() => {
                //
            });

            this.setState({
                ...this.state,
                arrayWatchlist: this.state.arrayWatchlist
            });
        }
        const handleUnwatch = (id) => {
            if (this.state.arrayWatchlist) {
                this.state.arrayWatchlist = this.state.arrayWatchlist.filter(item => item !== parseInt(id))
            }

            let data = { watchlist_domain_id: id};
            removeWatchlistItem(data)
            .then(() => {
                // this.props.requestMyWatchlistDomains();
            })
            .catch(() => {
                //
            });

            this.setState({
                ...this.state,
                arrayWatchlist: this.state.arrayWatchlist
            });
        }
        // END -> watchlist


        const handleDIOpen = (e) => {
            e.preventDefault();
            const id = e.target.id ? e.target.id.substr(2) : null;
            if(id) {
                this.setState({...this.state, domainInfoOpen: true, anchorEl: e.currentTarget, diPopoverId: "di" + id});
            }
        };
        const handleDIClose = (e) => {
            e.preventDefault();
            this.setState({...this.state, domainInfoOpen: false, anchorEl: null, diPopoverId: undefined});
        };

        const rows = [];
        const mobileRows = [];
        const categoryOptions = [];

        if(!guestPosts) {
            rows.push(<div key="load1" className="text-center"><Spinner/></div>);
            mobileRows.push(<div key="load1" className="text-center"><Spinner/></div>);
        }
        else if(!guestPosts.data) {
            rows.push(<div key="err1" className="text-center">Domain not found!</div>);
            mobileRows.push(<div key="err1" className="text-center">Domain not found!</div>);
        }
        let mcItem = {};
        if (guestPosts && guestPosts.data) { // && guestPosts.meta) {
            // if(guestPosts.categories) {
            //     if(guestPosts.categories.length) {
            //         for (const [index] of guestPosts.categories.entries()) {
            //             //let item = {value: guestPosts.categories[index].id, label: guestPosts.categories[index].name};
            //             //categoryOptions.push(item);
            //             categories["c" + guestPosts.categories[index].id] = guestPosts.categories[index].name;
            //             /*if(filter["category_id"] && filter["category_id"].split(",").indexOf(item.value.toString()) !== -1) {
            //                 defaultCategories.push(item);
            //             }*/
            //         }
            //     }
            // }

            if(guestPosts.category) {
                mcItem = {value: this.props.guestPosts.category, label: this.props.guestPosts.category};
                categoryOptions.push(mcItem);
                defaultCategories.push(mcItem);
            }
            if(guestPosts.all_categories && guestPosts.all_categories.length) {
                for (const [index] of guestPosts.all_categories.entries()) {
                    // console.log('******** all_categories',guestPosts.all_categories[index]);
                    let item = {value: guestPosts.all_categories[index]['cate_id'], label: guestPosts.all_categories[index]['category']};
                    if(item.value !== mcItem.value) {
                        categoryOptions.push(item);
                        if(filter["keyword"] && filter["keyword"].indexOf(item.value.toString()) !== -1) {
                            defaultCategories.push(item);
                        }
                    }
                }
            }


            const kFormatter = (num) => {
                return Math.abs(num) > 9999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(0)) + 'k' : Math.sign(num)*Math.abs(num);
            }

            const moneyFormatter = (num) => {
                if (typeof num !== 'undefined') {
                    try {
                        var calc = parseFloat(num).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('.00','');
                        // var calc = num.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        return '$'+calc;
                    } catch (e) {
                        console.log('moneyFormatter',e);
                        return '-';
                    }
                }
                return '$'+num;
            }

            if (guestPosts.data.length) {
                for (const [index] of guestPosts.data.entries()) {
                    let inCart = false;
                    if(cartItems) {
                        inCart = cartItems.filter(item => item.key === "dns_" + guestPosts.data[index].id).length !== 0;
                    }

                    let hasOffer = false;
                    if(filter['offer_id_keys']) {
                        if (filter['offer_id_keys'].indexOf("dns_"+guestPosts.data[index].id) > -1) {
                            hasOffer = true;
                        }
                    }
                    if (guestPosts.offers) {
                        if (guestPosts.offers.indexOf(guestPosts.data[index].id) > -1) {
                            hasOffer = true;
                        }
                    }

                    let onWatchlist = false;
                    if (this.state.arrayWatchlist) {
                        onWatchlist = false;
                        for(let di = 0; di < this.state.arrayWatchlist.length; di++) {
                            if (parseInt(guestPosts.data[index].id) == parseInt(this.state.arrayWatchlist[di])) {
                                onWatchlist = true;
                            }
                        }
                    }

                    const isChecked = inCart || this.state.selected.indexOf(guestPosts.data[index].id) !== -1;
                    /*const checkBoxItem = <label
                            className={"checkbox kt-checkbox kt-checkbox--single kt-checkbox--solid" + (isChecked ? " kt-checkbox--brand" : "")}>
                            <input type="checkbox" checked={isChecked} onChange={()=>{domainClick(guestPosts.data[index].id);}} value={guestPosts.data[index].id} />
                            <span/>
                        </label>;*/

                    let domain_star = '*****';
                    let domain_url = '';
                    if (guestPosts.data[index] != "" && guestPosts.data[index]) {
                        if (guestPosts.data[index].domainName && guestPosts.data[index].domainName != "") {
                            let domain_splitter = guestPosts.data[index].domainName.split('.');
                            domain_star = domain_splitter[0].replace(/./g, '*');
                            domain_url = guestPosts.data[index].domainName;
                        }
                    }

                    let listing_width = '100%';
                    if (this.state.viewType !== "grid") {
                        listing_width = '160px';
                    }

                    let binPriceHolder = 0;
                    if (typeof guestPosts.data[index].binPrice !== 'undefined') {
                        binPriceHolder = guestPosts.data[index].binPrice.replace('.00','');
                    }

                    rows.push(<div key={index} className={"kt-mb-30" + (this.state.viewType === "grid" ? " col-lg-4 col-md-6 col-sm-12" : "")}>
                        <Portlet id={"ms-item-" + index}>
                        <PortletBody className={(this.state.viewType !== "grid" ? "mkt-list-row-height flex-sm-row justify-content-between" : "p-0")} style={{ height:"auto", padding:"15px" }}>

                        {/* {(this.state.viewType !== "grid" ? style={height:"auto"} : "")} */}
                        {/* style={(this.state.viewType !== "grid" ? {height:"auto"} : "")} */}


                            {/* <div style={{ height: "36px" }} className={"mkt-list-row-height ml-img-col d-flex align-items-center"}> */}
                                {/* <img className="ml-img"
                                 src={guestPosts.data[index].image ? guestPosts.data[index].image : toAbsoluteUrl("/media/company-logos/placeholder_website.png")}
                                 alt="" /> */}
                                {/* <div className="ml-category-label">{guestPosts.data[index].category}</div> */}
                                    {/* {categories["c" + guestPosts.data[index].category_id]}
                                </div> */}
                            {/* </div> */}

                            {(this.state.viewType == "grid" ?
                                <div style={{ height:"36px" }} className={"mkt-list-row-height ml-img-col d-flex align-items-center"}>
                                    <div className="ml-category-label" style={{ width:"100%", background:"#ecf3fe", color:"#000" }}>{guestPosts.data[index].category}</div>
                                </div>
                            : "")}

                            {(this.state.viewType == "grid" ?
                                <div className="text-center" style={{ fontSize: "24px", marginTop: "52px", fontWeight: "500" }}>
                                    {onWatchlist == true ? <i className="fa fa-heart watchlist_toggle" style={{color:"red"}} onClick={() => {handleUnwatch(guestPosts.data[index].id);}}></i> : <i className="far fa-heart watchlist_toggle" onClick={() => {handleWatch(guestPosts.data[index].id);}}></i>} {domain_url}
                                </div>
                            : "")}
                            {/* {(isPremiumMember && this.state.viewType == "grid" ?
                                <div className="text-center" style={{ fontSize: "120%", marginTop: "20px" }}>
                                    {domain_url}
                                </div>
                            : this.state.viewType == "grid" ?
                                <div className="text-center" style={{ fontSize: "120%", marginTop: "20px" }}>
                                   www.{domain_star}.{guestPosts.data[index].tld}
                                   <Link className="kt-ml-5-desktop kt-mr-15-desktop d-none d-sm-block" to="/packages/marketplace-access">Upgrade Plan to Reveal URL</Link>
                                </div>
                            : "")}  */}

                            {(this.state.viewType == "grid" ?
                                <div className="text-center" style={{marginTop:"11px"}}>
                                    {/* <Icon className="fa fa-info-circle" id={"gp" + guestPosts.data[index].id} aria-describedby={"di" + guestPosts.data[index].id} onClick={handleDIOpen} style={{fontSize: "1rem",cursor: "pointer"}} /> */}
                                    <div id={"gp" + guestPosts.data[index].id} aria-describedby={"di" + guestPosts.data[index].id} onClick={handleDIOpen} style={{fontSize:"14px",marginBottom:"42px",cursor:"pointer",color:"#2c77f4"}}>Reveal SEO Data</div>
                                </div>
                                : "")}
                            {/* : this.state.viewType == "grid" ?
                                <div className="text-center" style={{marginTop:"20px"}}>
                                    www.{domain_star}.{guestPosts.data[index].tld}
                                    <Link className="kt-ml-5-desktop kt-mr-15-desktop d-none d-sm-block" to="/packages/marketplace-access">Upgrade Plan to Reveal URL</Link>
                                </div>
                            : "")} */}

                            <div className={"ml-table-col d-flex align-items-center" + (this.state.viewType === "grid" ? " pt-2 pb-2" : "")}
                                 style={{ overflowX: "auto", width: "100%" }}
                            >
                            {/* <div className="ml-table-col d-flex align-items-center pt-2 pb-2" style={{fontSize: 18}}>

                                style={{tableLayout: "fixed"}}
                                 */}
                                <table id="marketplace-table" className={"table table-borderless ml-table text-center mb-0"}
                                       style={{ tableLayout: this.state.viewType !== "grid" ? "auto" : "" }}
                                >
                                    <tbody>
                                    <tr>
                                        {(this.state.viewType !== "grid" ?
                                            <td style={{ width:'30%' }}>URL</td>
                                        : "")}
                                        {(this.state.viewType !== "grid" ?
                                            <td style={{ width:'20%' }}>Category</td>
                                        : "")}
                                        <td style={{ width: this.state.viewType !== "grid" ? '60px': ''}}>DA</td>
                                        <td style={{ width: this.state.viewType !== "grid" ? '60px': ''}}>RD</td>
                                        <td style={{ width: this.state.viewType !== "grid" ? '60px': ''}}>TF</td>
                                        <td style={{ width: this.state.viewType !== "grid" ? '60px': ''}}>PA</td>
                                        <td style={{ width: this.state.viewType !== "grid" ? '60px': ''}}>TB</td>
                                        {/* <td style={{ width: this.state.viewType !== "grid" ? '80px': ''}}>Traffic</td> */}
                                        {/* <td style={{
                                            width: userDiscountPercent.domain_marketplace && userDiscountPercent.domain_marketplace > 0 && this.state.viewType === "grid" ? '25%': this.state.viewType === "grid"  ? '14%' : '',
                                            // paddingLeft: this.state.viewType === "grid" ? '5px': '',
                                        }}> */}
                                        <td style={{ width: this.state.viewType !== "grid" ? '80px': ''}}>
                                            {typeof userDiscountPercent !== 'undefined' && typeof userDiscountPercent.domain_marketplace !== 'undefined' && userDiscountPercent.domain_marketplace && userDiscountPercent.domain_marketplace > 0 ?
                                                'Sale Price'
                                                :
                                                'Price'
                                            }
                                        </td>
                                        {(this.state.viewType !== "grid" ?
                                            <td style={{ width:'100px' }}>SEO Data</td>
                                        : "")}
                                        {/* {userDiscountPercent.domain_marketplace && userDiscountPercent.domain_marketplace > 0 ?
                                            <td style={{
                                                width: this.state.viewType === "grid" ? '14%': '',
                                                // paddingLeft: this.state.viewType === "grid" ? '5px': '',
                                            }}>Sale</td>
                                        : ''} */}
                                        <td style={{ width: '0' }}></td>
                                    </tr>
                                    <tr className="no-wrap">
                                        {(this.state.viewType !== "grid" ?
                                            <td>{domain_url}</td>
                                        : "")}
                                        {(this.state.viewType !== "grid" ?
                                            <td>{guestPosts.data[index].category}</td>
                                        : "")}
                                         {/* {(isPremiumMember && this.state.viewType !== "grid" ?
                                            <td>{domain_url}</td>
                                        : this.state.viewType !== "grid" ?
                                            <div className="text-center">
                                                www.{domain_star}.{guestPosts.data[index].tld}
                                                <Link className="kt-ml-5-desktop kt-mr-15-desktop d-none d-sm-block" to="/packages/marketplace-access">Upgrade Plan to Reveal URL</Link>
                                            </div>
                                        : "")}    */}

                                        <td>{guestPosts.data[index].da}</td>
                                        <td>{guestPosts.data[index].rd}</td>
                                        <td>{guestPosts.data[index].majesticTF}</td>
                                        <td>{guestPosts.data[index].mozPA}</td>
                                        <td>{guestPosts.data[index].majesticLinks}</td>
                                        {/* <td>{kFormatter(guestPosts.data[index].monthlyTraffic)}</td> */}
                                        <td>
                                            {typeof userDiscountPercent !== 'undefined' && typeof userDiscountPercent.domain_marketplace !== 'undefined' && userDiscountPercent.domain_marketplace && userDiscountPercent.domain_marketplace > 0 ?
                                            <>
                                                {/* <span style={{textDecorationLine: 'line-through', textDecorationStyle: 'solid', color: 'red', fontSize: '20px', borderBottom:'2px solid red'}}> */}
                                                    <span style={{color: '#212529', fontSize: '18px'}}>
                                                        <span style={{borderBottom:'2px solid red', position:'relative', top:'-16px'}}>
                                                            <span style={{position:'relative', top:'13px'}}>
                                                                ${(binPriceHolder.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}
                                                            </span>
                                                        </span>
                                                    </span>
                                                {/* </span> */}
                                                <span style={{paddingLeft:'6px', fontSize: '20px', color:'black', position:'relative',top:'-3px'}}>
                                                    {/* ${( ((binPriceHolder) * (1-userDiscountPercent.domain_marketplace)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('.00','') )} */}
                                                    {moneyFormatter(binPriceHolder * (1-userDiscountPercent.domain_marketplace))}
                                                </span>
                                            </>
                                                :
                                            <>
                                                {/* ${( binPriceHolder.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('.00','') )} */}
                                                {moneyFormatter(binPriceHolder)}
                                            </>
                                            }
                                        </td>
                                        {/* {userDiscountPercent.domain_marketplace && userDiscountPercent.domain_marketplace > 0 ?
                                            <td>${(userDiscountPercent.domain_marketplace ? userDiscountPercent.domain_marketplace : binPriceHolder + article_price).toLocaleString("en")}</td>
                                        : ''} */}
                                        {(this.state.viewType !== "grid" ?
                                            <td>
                                                {/* <Icon className="fa fa-info-circle" id={"gp" + guestPosts.data[index].id} aria-describedby={"di" + guestPosts.data[index].id} onClick={handleDIOpen} style={{fontSize: "1rem",cursor: "pointer" }} /> */}
                                                <div id={"gp" + guestPosts.data[index].id} aria-describedby={"di" + guestPosts.data[index].id} onClick={handleDIOpen} style={{fontSize: "1rem",cursor:"pointer",color:"#2c77f4"}}>View</div>
                                            </td>
                                        : "")}
                                    </tr>
                                    {typeof userDiscountPercent !== 'undefined' && typeof userDiscountPercent.domain_marketplace !== 'undefined' && userDiscountPercent.domain_marketplace && userDiscountPercent.domain_marketplace > 0 ?
                                        <tr>
                                            <td colspan="7">
                                                <div style={{fontStyle: 'italic', textAlign:'right', fontSize:'14px'}}>Price will expire soon</div>
                                            </td>
                                        </tr>
                                    :''}
                                    </tbody>
                                </table>
                            </div>
                            {/* {(this.state.viewType == "grid" ?
                                <div style={{fontStyle: 'italic', textAlign:'right', fontSize:'14px', marginBottom:'0.75rem', paddingRight:'25px' }}>Price will expire soon</div>
                            : "")} */}
                            {(isAgencyMember && this.state.viewType !== "grid" ?
                                <div className="text-center my-auto justify-content-center ml-btn-col mr-3">
                                    <Button style={{ width: listing_width, fontSize: "17px" }} className="ml-add-to-cart" variant="warning" size={this.state.viewType !== "grid" ? "" : ""} onClick={() => {handleAddToCartMobile(guestPosts.data[index].id);}} disabled={isChecked}>Add to Cart</Button>
                                    <Button style={{ width: listing_width, fontSize: "17px" }} className="mt-2 ml-make-an-offer btn-success" size={this.state.viewType !== "grid" ? "" : ""} onClick={() => {handleMakeOffer(guestPosts.data[index].id);}} disabled={hasOffer}>{hasOffer ? "Offer Sent" : "Make an Offer"}</Button>
                                </div>
                            : isAgencyMember ?
                                <div className="text-center d-flex align-items-center justify-content-center ml-btn-col mr-3">
                                    <Button style={{ width: listing_width }} className="ml-add-to-cart" variant="warning" size={this.state.viewType !== "grid" ? "" : ""} onClick={() => {handleAddToCartMobile(guestPosts.data[index].id);}} disabled={isChecked}>Add to Cart</Button>
                                    <Button style={{ width: listing_width }} className="ml-make-an-offer btn-success" size={this.state.viewType !== "grid" ? "" : ""} onClick={() => {handleMakeOffer(guestPosts.data[index].id);}} disabled={hasOffer}>{hasOffer ? "Offer Sent" : "Make an Offer"}</Button>
                                </div>
                            : this.state.viewType == "grid" ?
                                <div className="text-center d-flex align-items-center justify-content-center ml-btn-col mr-3">
                                    <Button style={{ width: listing_width }} className="ml-add-to-cart" variant="warning" size={this.state.viewType !== "grid" ? "" : ""} onClick={() => {handleAddToCartMobile(guestPosts.data[index].id);}} disabled={isChecked}>Add to Cart</Button>
                                </div>
                            :
                                <div className="text-center d-flex align-items-center justify-content-center ml-btn-col mr-3" style={{ maxWidth: '150px'}}>
                                    <Button style={{ width: listing_width, fontSize: "17px" }} className="ml-add-to-cart" variant="warning" size="" onClick={() => {handleAddToCartMobile(guestPosts.data[index].id);}} disabled={isChecked}>Add to Cart</Button>
                                </div>
                            )}
                        </PortletBody>
                    </Portlet></div>);

                    mobileRows.push(<Portlet key={"mm" + index} className="kt-mb-30">
                        <PortletBody className="flex-sm-row justify-content-between">
                            <div className="mm-guestpost-item p-sm-2">
                                <div style={{ fontSize: "14px",height:"36px",marginBottom: "14px" }} className={"mkt-list-row-height ml-img-col d-flex align-items-center"}>
                                    <div className="ml-category-label" style={{ width:"100%", background:"#ecf3fe", color:"#000" }}>{guestPosts.data[index].category}</div>
                                </div>
                                <div className="text-center" style={{ fontSize: "16px",marginTop: "5px",marginBottom: "14px",fontWeight: "500" }}>{domain_url}</div>
                                <table>
                                    {/* <tr>
                                        <td colspan="7">{domain_url}</td>
                                    </tr> */}
                                    <thead>
                                        <tr>
                                            <th>DA</th>
                                            <th>RD</th>
                                            <th>TF</th>
                                            <th>PA</th>
                                            <th>TB</th>
                                            <th>Traffic</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{guestPosts.data[index].da}</td>
                                            <td>{guestPosts.data[index].rd}</td>
                                            <td>{guestPosts.data[index].majesticTF}</td>
                                            <td>{guestPosts.data[index].mozPA}</td>
                                            <td>{guestPosts.data[index].majesticLinks}</td>
                                            <td>{kFormatter(guestPosts.data[index].monthlyTraffic)}</td>
                                            <td>${(binPriceHolder.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="text-center p-sm-2">
                                <Button style={{ width: listing_width, fontSize: "17px" }} className="btn-success text-uppercase" onClick={() => {handleAddToCartMobile(guestPosts.data[index].id);}} disabled={isChecked}>Add to Cart</Button>
                               {/* {(isAgencyMember ?
                                    <Button style={{ width: listing_width, fontSize: "17px" }} className="mt-2 btn-success text-uppercase" onClick={() => {handleMakeOffer(guestPosts.data[index].id);}} disabled={hasOffer}>{hasOffer ? "Offer Sent" : "Make an Offer"}</Button>
                                : "")} */}
                            </div>
                        </PortletBody>
                    </Portlet>);
                }
            }
        }

        const makeAnOfferClose = (e) => {
            this.setState({...this.state, openMakeOffer: false});
        }

        const addOnsClose = (e) => {
            this.setState({...this.state, openAddOns: false});
        }

        const openBlacklist = (e) => {
            this.setState({...this.state, openBlackList: true});
        }

        const blacklistClose = (e) => {
            this.setState({...this.state, openBlackList: false});
        }

        const handleNextPage = (number) => {
            this.props.initDomainMarketplace(number, filter);
            this.setState({
                ...this.state,
                selected: []
            });
        };

        const stepLabels = [
            <span>Place Order<br/>& Submit Details</span>,
            <span>Blogger Approves<br/>Or Rejects Order</span>,
            <span>Content Creator<br/>Starts Writing</span>,
            <span>Article Placed<br/>On Selected Blog</span>,
        ];

        const startTutorial = () => {
            try {
                const intro = introJs();
                //intro.exit();
                if (!this.state.stepsEnabled) {
                    const self = this;
                    window.setTimeout(()=> {
                        intro.onbeforechange(function (targetElement) {
                            if (intro._options.steps.length !== 0) {
                                for (let i = 0; i < intro._options.steps.length; i++) {
                                    const selector = intro._options.steps[i].selector;
                                    if(selector) {
                                        const element = document.querySelectorAll(selector)[0];

                                        if (element) {
                                            intro._introItems[i].element = element;
                                            intro._introItems[i].position = intro._options.steps[i].position || 'auto';
                                        }
                                    }
                                }
                            }
                        }).onexit(function(){
                            self.setState({...self.state, stepsEnabled: false});
                        }).oncomplete(function () {
                            const data = {
                                options: [
                                    {option: "show_intro_marketplace", value: 0}
                                ],
                            };
                            saveUserOptions(data)
                                .then(() => {
                                    this.props.fulfillUserOptions(data);
                                })
                                .catch(() => {
                                    //
                                });
                        }).setOptions({
                            'showBullets': false,
                            'skipLabel': "Skip",
                        }).addSteps(this.state.steps);
                        intro.start();
                    }, 500);
                    this.setState({...this.state, stepsEnabled: true});
                }
            } catch (e) {
                console.log(e);
            }
        };

        return (
            <div style={{maxWidth:"1560px"}}>
                {/*<Steps
                    enabled={this.state.stepsEnabled}
                    steps={this.state.steps}
                    initialStep={this.state.initialStep}
                    onExit={onExit}
                    options={{
                        skipLabel:"Skip",
                        //hidePrev: true,
                        //showStepNumbers: false,
                        //showBullets: false,
                    }}
                    ref={steps => (this.steps = steps)}
                    onBeforeChange = {nextStepIndex => {
                        if (this.steps && nextStepIndex >= 0) {
                            this.steps.updateStepElement(nextStepIndex);
                        }
                    }}
                    onComplete={()=>{
                        const data = {
                            options: [
                                {option: "show_intro_marketplace", value: 0}
                            ],
                        };
                        saveUserOptions(data)
                            .then(() => {
                                this.props.fulfillUserOptions(data);
                            })
                            .catch(() => {
                                //
                            });
                    }}
                />*/}

                <div className="row">
                    <div className="col-12">
                        <div className="progress progressBar TmoProgressBar">
                            <div className="progress-bar" role="progressbar" aria-valuenow={(user.tmo_amount ? user.tmo_amount : 0)} aria-valuemin="0" aria-valuemax={(user.tmo_progress_bar_total ? user.tmo_progress_bar_total : 10000)} style={{width:(user.tmo_percentage ? user.tmo_percentage : 0)+'%'}}>
                                <span class="progress-label">{(user.tmo_amount ? '$'+user.tmo_amount.toLocaleString(undefined, {maximumFractionDigits:2}) : '$0.00')}</span>
                            </div>
                            <div class="first_label">This Month's Spend</div>
                            <div class="second_label">
                                <div class="vertical_line">|</div>
                                $1,000
                            </div>
                            <div class="third_label">
                                <div class="vertical_line">|</div>
                                $5,000
                            </div>
                            <div class="fourth_label">
                                <div class="vertical_line">|</div>
                                $10,000
                            </div>
                        </div>
                    </div>
                </div>

                <Portlet className="" style={{background:"#f2f3f8", boxShadow:"none"}}>
                    <div className="row" style={{marginLeft:0,marginRight:0}}>
                        <div className="col-lg-9 col-12" style={{paddingLeft:0,paddingRight:0}}>
                            <Portlet className="h-100 kt-mb-0 marketplace_left_blue" style={{background:"#ecf3fe"}}>
                                <PortletBody dangerouslySetInnerHTML={{ __html: pageData ? pageData.top_left_text : "" }} />
                            </Portlet>
                        </div>
                        <div className="col-lg-3 col-12" style={{paddingLeft:0,paddingRight:0}}>
                            <Portlet className="kt-mb-0 marketplace_right_green" style={{background:"#e7eff3"}}>
                                <PortletBody dangerouslySetInnerHTML={{ __html: pageData ? pageData.top_right_text : "" }} />
                            </Portlet>
                        </div>
                    </div>
                </Portlet>

                {filter && <Form id="filters-block" className="d-block kt-mt-10 kt-hidden-tablet kt-hidden-mobile">
                    <div className="">
                        <Form.Row className="mb-n3">
                            <Form.Group as={Col} className="col-md-3 col-sm-6 col-5" controlId="formGridSearch">
                                <Form.Label>Search</Form.Label>
                                <Form.Control type="text" name="search" placeholder="Enter keyword" min="0" defaultValue={filter && filter.search ? filter.search : ''} onChange={handleInputChange} />
                            </Form.Group>
                            <Form.Group as={Col} className="col-md-3 col-sm-6 col-5" controlId="formGridTLD">
                                <Form.Label>Site Category</Form.Label>
                                <Select
                                    isMulti
                                    options={categoryOptions}
                                    value={defaultCategories}
                                    onChange={handleCategoryChange}
                                    noOptionsMessage={()=>{return !guestPosts ? "Loading..." : "No options";}}
                                    styles={selectStyles}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} className="col-md-2 col-sm-4 col-3" controlId="formGridTLD">
                                <Form.Label>TLD</Form.Label>
                                <Form.Control type="text" name="tld" placeholder="TLD" min="0" defaultValue={filter && filter.tld ? filter.tld : ''} onChange={handleInputChange} />
                            </Form.Group>
                            <Form.Group as={Col} className="col-md-2 col-sm-4 col-3" controlId="formGridDA">
                                <Form.Label>DA</Form.Label>
                                <Form.Control type="number" name="da" placeholder="MIN" min="0" defaultValue={filter && filter.da ? filter.da : ''} onChange={handleInputChange} />
                            </Form.Group>
                            {/* <Form.Group as={Col} className="col-md-2 col-sm-4 col-6" controlId="formGridDR">
                                <Form.Label>DR</Form.Label>
                                <Form.Control type="number" name="dr" placeholder="MIN" min="0" defaultValue={filter && filter.dr ? filter.dr : ''} onChange={handleInputChange} />
                            </Form.Group> */}
                            <Form.Group as={Col} className="col-md-2 col-sm-4 col-6" controlId="formGridRD">
                                <Form.Label>RD</Form.Label>
                                <Form.Control type="number" name="rd" placeholder="MIN" min="0" defaultValue={filter && filter.rd ? filter.rd : ''} onChange={handleInputChange} />
                            </Form.Group>
                            {/* <Form.Group as={Col} className="col-md-2 col-sm-4 col-6" controlId="formGridOT">
                                <Form.Label>Organic Traffic</Form.Label>
                                <Form.Control type="number" name="organic_traffic" placeholder="MIN" min="0" defaultValue={filter && filter.organic_traffic ? filter.organic_traffic : ''} onChange={handleInputChange} />
                            </Form.Group> */}
                            <Form.Group as={Col} className="col-md-2 col-sm-4 col-6" controlId="formGridPrice">
                                <Form.Label>Price</Form.Label>
                                <Form.Control type="number" name="price" placeholder="MAX" min="0" defaultValue={filter && filter.price ? filter.price : ''} onChange={handleInputChange} />
                            </Form.Group>
                        </Form.Row>
                    </div>
                </Form>}
                <div className="d-flex justify-content-between kt-mb-30 kt-font-lg kt-hidden-tablet kt-hidden-mobile">
                    <div className="d-flex align-items-center">
                        <span className="mv-type">
                            <span className={"grid" + (this.state.viewType === "grid" ? " active" : "")} onClick={() => {
                                if (this.state.viewType !== "grid") {
                                    this.setState({...this.state, viewType: "grid"});
                                }
                            }}/>
                            <span className={"list" + (this.state.viewType === "list" ? " active" : "")} onClick={() => {
                            if (this.state.viewType !== "list") {
                                this.setState({...this.state, viewType: "list"});
                            }
                        }}/>
                        </span>
                        <label htmlFor="ml-sort" className="ml-sort-label d-inline-block">sort by:</label>
                        <select id="ml-sort" onChange={(e)=>{
                            const value = e.target.value;
                            const sort = value ? value.split('-')[0] : "";
                            const order = value && value.split('-')[1] ? value.split('-')[1] : "";
                            filter["sort"] = sort;
                            filter["order"] = order;
                            this.props.initDomainMarketplace(1, filter);
                        }}>
                            {sortOptions.map(option => (
                                <option value={option.key} key={option.key}>{option.value}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className={"mv-" + this.state.viewType + (this.state.viewType === "grid" ? " row" : "") + " mb-5"}>
                    {rows}
                    <Popover
                        id={this.state.diPopoverId}
                        open={this.state.domainInfoOpen}
                        anchorEl={this.state.anchorEl}
                        onClose={handleDIClose}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                    >
                        <DomainInfo id={this.state.diPopoverId ? this.state.diPopoverId.substr(2) : null} />
                    </Popover>
                    {guestPosts && guestPosts.meta && guestPosts.meta.last_page > 1 && <UPagination currentPage={guestPosts.meta.current_page} totalPages={guestPosts.meta.last_page} onChange={handleNextPage} />}
                </div>
                <AddOnModal open={this.state.openAddOns} onClose={addOnsClose} addOnItems={this.state.addOnItems} item={this.state.addedToCartItemHolder} />
                {/* <MarketplaceSubscriptionInfo /> */}
            </div>
        );
    }
}

const mapStateToProps = ({ auth: { user }, marketplace: { guestPosts, watchlistArray, filter, guestPostItem }, cart: {cartItems, userDiscountPercent, discountDate, currentDate}, projects: {currentProjectId}, pagedata: {pageData}, profile: {userOptions}, packages: {packages, currentPackage} }) => ({
    user, guestPosts, watchlistArray, filter, cartItems, currentProjectId, pageData, userDiscountPercent, discountDate, currentDate, guestPostItem, userOptions, packages, currentPackage
});

export default injectIntl(connect(
    mapStateToProps,
    {...marketplace.actions, ...cart.actions, ...pagedata.actions, ...profile.actions, ...packages.actions}
)(GuestPosts));
