import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getFinancials } from "../../../crud/admin/financials.crud";

export const actionTypes = {
    FinancialsInit: "[Init Financials Admin] Action",
    FinancialsRequested: "[Request Financials Admin] Action",
    FinancialsLoaded: "[Load Financials Admin] Financials API",
};

const initialFinancialsState = {
    financials: undefined,
};

export const reducer = persistReducer(
    { storage, key: "admin-financials", whitelist: ["financials"] },
    (state = initialFinancialsState, action) => {
        switch (action.type) {
            case actionTypes.FinancialsInit: {
                return { financials: undefined };
            }

            case actionTypes.FinancialsLoaded: {
                const { financials } = action.payload;

                return { ...state, financials };
            }

            case actionTypes.FinancialsRequested: {
                return { ...state, financials: undefined };
            }

            default:
                return state;
        }
    }
);

export const actions = {
    initFinancials: page => ({ type: actionTypes.FinancialsInit, payload: { page } }),
    requestFinancials: page => ({ type: actionTypes.FinancialsRequested, payload: { page } }),
    fulfillFinancials: financials => ({ type: actionTypes.FinancialsLoaded, payload: { financials } }),
};

export function* saga() {
    yield takeLatest(actionTypes.FinancialsInit, function* initFinancialsSaga(action) {
        const { page } = action.payload;
        yield put(actions.requestFinancials(page));
    });

    yield takeLatest(actionTypes.FinancialsRequested, function* financialsRequested(action) {
        const { page } = action.payload;
        const {data: financials} = yield getFinancials(page);

        yield put(actions.fulfillFinancials(financials));
    });
}
